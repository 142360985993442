<template>
  <v-dialog v-model="openDialog" @keydown.esc="openDialog = false"
            max-width="700px">
    <v-flex xs12>
      <h2>{{ $t('administration.keyword.keywordDocumentHeader') }}</h2>
      <div class="keyword-information-container">
        <h3> {{ $t('administration.keyword.keyword') }} :</h3>
        <span>{{ keywordInformation.keywordName }}</span>
        <h3> {{ $t('administration.keyword.level') }} :</h3>
        <span>{{ keywordInformation.keywordLevel }}</span>
      </div>
      <v-data-table
          :headers="[]"
          :items="documentList"
          hide-actions
          disable-initial-sort
          align-center
          justify-center>
        <template
            slot="headers"
            slot-scope="props">
          <th>{{ $t('document.label.documentId') }}</th>
          <th>{{ $t('document.label.title') }}</th>
          <th>{{ $t('document.label.version') }}</th>
        </template>
        <template slot="no-data">
          <td colspan="3"
              class="text-xs-center">
            {{ $t('administration.keyword.none') }}
          </td>
        </template>
        <template
            slot="items"
            slot-scope="props">
          <tr>
            <td>{{ props.item.documentId }}</td>
            <td>{{ props.item.documentName }}</td>
            <td>{{ props.item.documentVersion }}</td>
          </tr>
        </template>

      </v-data-table>
    </v-flex>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import EventBus from "@/utils/event-bus";
import ConstantEvent from '../../../shared/constant/constantEvent.js'
import BolBackButton from "@/components/shared/BolBackButton";

export default {
  name: 'DialogDocumentKeyword',
  components: {BolBackButton},
  data() {
    return {
      keywordId: 1730,
      keywordInformation:
          {
            keywordId: Number.MIN_VALUE,
            keywordName: "",
            keywordLevel: ""
          }
      ,
      documentList: [],
      openDialog: false
    }
  },
  mounted() {
    EventBus.$on(ConstantEvent.UPDATE_DOCUMENT_KEYWORD_DIALOG, id => this.updateDialog(id));
  },
  methods: {
    updateDialog(keywordId) {
      this.keywordId = keywordId;
      axios.get('administration/keyword/documents/' + keywordId).then(({data}) => {
        this.keywordInformation = data[0];
        if (data.length >= 1) {
          this.documentList = data.slice(1, data.length);
        } else {
          this.documentList = [];
        }
        this.openDialog = true
      })
    }
  }
}
</script>

<style scoped>
.v-dialog {
  padding: 30px;
  background-color: white;
  max-height: 70% !important;
}

.keyword-information-container * {
  margin-right: 5px;
}

.keyword-information-container h3 {
  display: inline;
}

.keyword-information-container span {
  color: rgba(0, 0, 0, .87);
}

.v-dialog table tbody tr td {
  text-align: center;
}
</style>