<template>
    <v-dialog v-model="openDialog" fullscreen persistent>
        <v-card>
            <v-layout row fill-height > 
                <v-flex class="filter_sizer" >
                    <document-filter v-if="!!documentHyperLink.hyperLinkType" :isLink="true" :hyperLinkType="documentHyperLink.hyperLinkType" />
                </v-flex>
                <v-layout column fill-height>
                    <v-flex style="margin-top:70px;">
                        <v-card :class="{ halfCard_linkType: !showNode}" v-show="!showNode">
                            <v-card-text>
                                <v-container fluid class="contain-radio">
                                    <span class="headline">
                                        {{$t('document.label.linkType')}}
                                    </span>
                                    <v-radio-group v-if="documentHyperLink.hyperLinkType == DOCUMENT_HYPERLINK_TYPE" v-model="linkType" column>
                                        <v-radio :label="rex" value="DOC_REX"></v-radio>
                                        <v-radio :label="cit" value="DOC_CIT"></v-radio>
                                    </v-radio-group>
                                    <v-radio-group v-if="documentHyperLink.hyperLinkType == CONTENT_HYPERLINK_TYPE" v-model="linkType" column>
                                        <v-radio :label="rex" value="ART_REX"></v-radio>
                                        <v-radio :label="cit" value="ART_CIT"></v-radio>
                                    </v-radio-group>
                                </v-container>
                                <div style="margin-top:-15px">
                                    <span class="backtodoc" slot="activator" @click="resetComponent" >{{ $t('global.action.backTodoc') }}</span>
                                </div>
                            </v-card-text>
                        </v-card>
                        <v-card :class="{ halfCard_node: !showNode }">
                            <v-card-title>
                                <div class="headline center">{{$t('document.label.context')}}</div>
                                <div class="grey--text center">{{sourceNode.selectedContent}}</div>
                            </v-card-title>
                            <v-card-text style="padding: 0 16px 0 16px;" v-show="!showNode">
                                <div>{{sourceNode.selectedNode ? sourceNode.selectedNode.substring(0,150) + "..."  : null }}</div>
                            </v-card-text>
                            <v-slide-y-transition>
                                <v-card-text v-show="showNode">{{sourceNode.selectedNode}}</v-card-text>
                            </v-slide-y-transition>
                            <v-card-actions>
                                <v-btn icon @click="showNode = !showNode" style="width: 100%">
                                    <v-icon>{{ showNode ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                    <v-flex class="document_hyperlink_sizer" >
                        <document-hyperlink-list :hyperLinkType="documentHyperLink.hyperLinkType" :documentId="documentId" @onSelectDocumentHyperlink="onSelectDocumentHyperlink"/>
                    </v-flex>
                </v-layout>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
import DocumentFilter           from '../../search/DocumentFilter';
import DocumentHyperlinkList    from '../DocumentHyperlinkList';
import ConstantHyperLink        from '../../../../shared/constant/constantDocumentHyperLink.js';
import ConstantEvent            from '../../../../shared/constant/constantEvent.js';
import ConstantDocumentNode     from '../../../../shared/constant/constantDocumentNode.js'
import ConstantNotification     from '../../../../shared/constant/constantNotification.js'
import EventBus                 from '../../../../utils/event-bus.js';
import { mapActions, mapState } from 'vuex';

import DocumentHyperLinkService from '../../../../service/document/documentHyperLinkService.js';
import domManipulatorService    from '../../../../service/html/domManipulatorService.js';

const UPDATE_DIALOG = 'update:dialog';
/**
 * 
 * Document search component.
 * @author Justin WILMET, SFEIR benelux
 * @version 1.0
 * @since 12/11/2018
 * 
 */
export default {
    name: "DialogDocumentHyperLink",
    props: {
        hyperLinkType: {
            default: null
        },
        dialog: {
            default: false
        },
        documentId: {
            default: null
        }
    },
    data() {
        return {
            referenceDocumentDestination: null,
            chosenTitleDocumentDestination: null,
            linkType: null,
            rex: this.$t('document.label.rex'),
            cit: this.$t('document.label.cit'),
            DOCUMENT_HYPERLINK_TYPE: ConstantHyperLink.DOCUMENT_HYPERLINK_TYPE,
            CONTENT_HYPERLINK_TYPE: ConstantHyperLink.CONTENT_HYPERLINK_TYPE,
            showNode: false
        }
    },
    computed:{
        ...mapState({
            documentHyperLink: state => state.hyperLinkStore.documentHyperLink,
            sourceNode: state => state.hyperLinkStore.sourceNode,
        }),
        openDialog: {
            get() {
                return this.dialog;
            },
            set(dialog) {
                if(!dialog) {
                    this.$emit(UPDATE_DIALOG, false);
                }
            }
        },
    },
    methods: {
        ...mapActions({
            setReferenceDocumentDestinationAndRelationType: 'hyperLinkStore/setReferenceDocumentDestinationAndRelationType',
            updateNodeContent: 'documentContent/modifyContent',
        }),
        /**
         * The document has been selected
         */
        onSelectDocumentHyperlink( {referenceDocument, chosenTitleDocument} ) {
            if( !!this.linkType ){
                this.setReferenceDocumentDestinationAndRelationType({ 
                    referenceDocumentDestination: referenceDocument, 
                    relationType: this.linkType
                });    
                if(this.documentHyperLink.hyperLinkType === ConstantHyperLink.DOCUMENT_HYPERLINK_TYPE){
                    let link = DocumentHyperLinkService.insertHyperLinkInContent(this.documentHyperLink, this.sourceNode);
                    let htmlContent = domManipulatorService.replaceTextByHTMLElementInNode(
                        this.sourceNode.caretPosition.clickedNode,
                        this.sourceNode.caretPosition.clickedNodeOffset,
                        this.sourceNode.caretPosition.clickedFocusNodeOffset,
                        link,
                        ConstantDocumentNode.TEXT_NODE_CLASS,
                        this.sourceNode.caretPosition.focusNode );
                    this.updateNodeContent({ id: this.sourceNode.id, content: htmlContent });
                    this.resetComponent();
                } else {
                    this.$emit('documentChosen', { 
                        referenceDocumentDestination: referenceDocument, 
                        chosenTitleDocumentDestination: chosenTitleDocument
                    });
                    this.resetComponent();
                }
            } else {
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    type: ConstantNotification.ERROR,
                    message: 'document.notification.hyperlink.typeMissing',
                });
            }
        },
        resetComponent(){
            this.referenceDocumentDestination = null;
            this.chosenTitleDocumentDestination = null;
            this.linkType = null;
            this.$emit(UPDATE_DIALOG, false);
            EventBus.$emit(ConstantEvent.RESET_FILTER_PAGINATION, {});
        }
    },
    watch: {
        dialog() {
            if(Number(this.hyperLinkType) == ConstantHyperLink.DOCUMENT_HYPERLINK_TYPE){
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, { message: 'document.notification.hyperlink.document' , type: 'INFO'});
            }else if(Number(this.hyperLinkType) == ConstantHyperLink.CONTENT_HYPERLINK_TYPE){
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, { message: 'document.notification.hyperlink.node' , type: 'INFO'});
            }
        }
    },
    components: {
        DocumentFilter,
        DocumentHyperlinkList
    }
}
</script>

<style scoped>
    .filter_sizer{
        min-width: 350px;
        max-width: 350px;
    }
    .document_hyperlink_sizer{
        width: 100%;
        height:100vh;
    }
    .btn-cancel{
        bottom:10px;
        right:50px;
        position:absolute;
    }
    .backtodoc{
        font-weight: bold;
        color:#a7372e;
        cursor: pointer;
    }
    .contain-radio{
        margin-top:-20px;
        transform: translate(40%,0);
        font-weight: bold;
    }
    .halfCard_linkType {
        width: 50%; 
        height: 200px;
        float: left;
    }
    .halfCard_node {
        width: 50%; 
        height: 200px;
        float: right;
        overflow: hidden;
    }
    .headline{
        color:#355B7F;
        font-weight: bold;
    }
    .center{width: 100%;text-align: center;}
</style>
