<template>
    <v-dialog v-model="openDialog" persistent max-width="350px">
        <v-card>
            <v-card-title class="headline">{{ $t('structure.node.action.importFiles') }}</v-card-title>
            <v-card-text>
                <resize-picture v-if="isResizing" @onChangeWidthHeight="changeWidthHeight" />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" flat @click.native="close();">{{$t('document.action.cancel')}}</v-btn>
                <v-btn color="primary" flat @click.native="submit();">{{$t('document.action.add')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ConstantEvent            from '../../../../shared/constant/constantEvent.js';
import EventBus                 from '../../../../utils/event-bus';
import ResizePicture            from '../components/ResizePicture.vue';

const UPDATE_DIALOG             = 'update:dialog';
/**
 * 
 * Dialog use to resize a picture in the document
 * @author Justin WILMET
 * @since 31/01/2019
 * @version 1.0
 * 
 */
export default {
    name: 'DialogChangePictureSize',
    props: {
        dialog: {
            default: false
        }
    },
    data(){
        return {
            width: null,
            height: null,
            isResizing: false,
        }
    },
    components:{
        ResizePicture
    },
    computed: {
        openDialog:{
            get(){
                /**
                 * Waiting for the component to be created
                 */
                if( this.dialog ){
                    this.$nextTick().then(() => {
                        this.isResizing = true;
                    })
                }
                return this.dialog;
            },
            set( dialog ){
                if( !dialog ){
                    this.$emit(UPDATE_DIALOG, false);
                }
            }
        }
    },
    methods: {
        /**
         * recover the new width and height
         * @param {Number} width, new width of the picture
         * @param {Number} height, new height of the picture
         */
        changeWidthHeight( {width, height} ){
            this.width = width;
            this.height = height;
        },
        /**
         * send the new dimension of the picture to the good method
         */
        submit(){
            this.$emit('updateWidthHeight', {width: this.width, height: this.height});
            this.close();
        },
        /**
         * Close the import dialog
         */
        close(){
            this.isResizing = false;
            this.$emit(UPDATE_DIALOG, false);
        }
    }

}
</script>