<template>
    <v-layout
        row
        wrap
    >
        <!-- TITLE OF THE PAGE-->
        <h2 class="custom_workspace">
            {{ $t('administration.workspace.search.mainTitle') }}
        </h2>

        <!-- FORM WITH RESEARCH BUTTON -->
        <v-layout
            row
            wrap
            justify-center
            class="layout_workspace"
        >
            <v-flex
                xs5
                class="sizer_flex"
            >
                <v-text-field
                    v-model="filter.name"
                    class="t_name"
                    dark
                    :label="$t('administration.workspace.label.name')"
                    clearable
                    @keyup.enter="filterWorkspaces(filter,pageable)"
                />
            </v-flex> 
            <v-flex
                xs1
                justify-end
            >
                <v-btn
                    class="t_search"
                    color="white"
                    small
                    fab
                >
                    <v-icon
                        color="primary"
                        dark
                        @keyup.enter="filterWorkspaces(filter,pageable)"
                        @click="filterWorkspaces(filter,pageable)"
                    >
                        search
                    </v-icon>
                </v-btn>
            </v-flex>
        </v-layout>    

        <!-- PROGRESS BAR -->
        <v-layout
            v-if="displaySpinner"
            align-center
            justify-center
            row
        >
            <v-progress-circular
                :size="70"
                :indeterminate="true"
                color="primary"
            />
        </v-layout>

        <!-- DATA TABLES -->
        <v-flex v-if="!displaySpinner">
            <v-data-table
                :headers="headers"
                :items="workspaceList"
                hide-actions
                disable-initial-sort
                align-center
                justify-center
                class="elevation-1"
            >
                <template
                    slot="headers"
                    slot-scope="props"
                >
                    <th>{{ $t('administration.workspace.label.name') }}</th>
                    <th>{{ $t('administration.workspace.label.abbreviation') }}</th>
                    <th>{{ $t('administration.workspace.label.description') }}</th>
                    <th>{{ $t('administration.workspace.label.visible') }}</th>
                    <th>{{ $t('administration.workspace.label.important') }}</th>
                    <th>{{ $t('administration.workspace.label.rank') }}</th>
                    <th>{{ $t('administration.workspace.label.market') }}</th>
                    <th>{{ $t('administration.workspace.label.actions') }}</th>
                </template>

                <template slot="no-data">
                    <td
                        colspan="6"
                        class="t_no_data text-xs-center"
                    >
                        {{ $t('administration.workspace.search.none') }}
                    </td>
                </template>

                <template
                    slot="items"
                    slot-scope="props"
                >
                    <td class="align-items">
                        {{ props.item.name }}
                    </td>
                    <td class="align-items">
                        {{ props.item.abbreviation }}
                    </td>
                    <td class="align-items">
                        {{ props.item.description }}
                    </td>
                    <td class="align-items">
                        <v-icon
                            v-if="props.item.visible"
                            color="primary"
                            dark
                        >
                            visibility
                        </v-icon>
                        <v-icon
                            v-else
                            color="primary"
                        >
                            visibility_off
                        </v-icon>
                    </td>
                    <td class="align-items">
                        <v-icon
                            v-if="props.item.important"
                            color="primary"
                            dark
                        >
                            done
                        </v-icon>
                    </td>
                    <td class="align-items">
                        {{ props.item.rank }}
                    </td>
                    <td class="align-items">
                        <v-chip
                            v-for="w in props.item.markets"
                            :key="id"
                            :class="'t_entry_market_'+w.code"
                        >
                            {{ w.code }}
                        </v-chip>
                    </td>
                    <td class="align-items">
                        <router-link
                            class="t_edit"
                            tag="button"
                            :to="{name: 'WorkspaceEdit', params: { id: props.item.id }}"
                        >
                            <v-icon class="edit-icon">
                                edit
                            </v-icon>
                        </router-link>
                        <v-icon
                            slot="activator"
                            class="t_delete edit-icon"
                            @click="openConfirmationDialog(props.item.id)"
                        >
                            delete
                        </v-icon>
                    </td>
                </template>
            </v-data-table>

            <v-dialog
                v-model="dialog"
                class="t_confirm_delete_dialog"
                persistent
                max-width="350"
                icon
            >
                <v-card>
                    <v-card-title class="headline">
                        {{ $t('administration.workspace.manage.delete.title') }}
                    </v-card-title>
                    <v-divider />
                    <v-card-text>{{ $t('administration.workspace.manage.delete.message') }}</v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            color="primary"
                            small
                            flat
                            @click.native="dialog = false"
                        >
                            {{ $t('administration.workspace.manage.delete.cancel') }}
                        </v-btn>
                        <v-btn
                            class="t_confirm_delete"
                            color="primary"
                            small
                            flat
                            @click.native="deleteWorkspace()"
                        >
                            {{ $t('administration.workspace.manage.delete.confirm') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-flex>

        <!-- PAGINATION -->
        <v-flex
            v-if="!!workspaceList && workspaceList.length > 0"
            xs12
            class="workspace-list"
        >
            <v-pagination
                :length="pageable.totalPages"
                :total-visible="5"
                :value="pageable.page"
                circle
                @input="changePage"
            />
        </v-flex>

        <!-- ADD NEW WORKSPACE -->
        <v-tooltip top>
            <v-btn
                slot="activator"
                color="primary"
                small
                fab
                dark
                icon
                class="t_add icon-add-custom"
                :to="{name: 'WorkspaceAdd'}"
            >
                <v-icon dark>
                    add
                </v-icon>
            </v-btn>
            <span>{{ $t('administration.workspace.search.action.add') }}</span>
        </v-tooltip>
    </v-layout>
</template>

<script>

import axios from 'axios';
import EventBus  from '../../../utils/event-bus.js';
import ConstantEvent    from '../../../shared/constant/constantEvent.js'
/**
 * 
 * Display a workspace after a search.
 * 
 * @author Cindy SWENNEN, SFEIR Benelux
 * @version 1.0
 * @since 18/06/2019
 */
export default {
    name:'Search',
    data () {
        return {
            headers: [],
            workspaceList: [],
            displaySpinner: true,
            pageable: {page: 1, size: 10,  direction: 'ASC', property: "rank"},
            filter: {},
            dialog: false
        }
    },
    created(){
        this.filterWorkspaces(this.filter, this.pageable);
    },
    methods:{
        /**
         * Search workspaces by filter
         * @param filter
         * @param pageable
         */
        filterWorkspaces(filter, pageable){
            this.displaySpinner = true;
            axios.post(
                'administration/workspace/filter',
                filter,
                {params: pageable}
            ).then( (result ) => {
                this.displaySpinner = false;
                this.workspaceList = result.data.content;
                this.pageable.totalElements = result.data.totalElements;
                this.pageable.totalPages = result.data.totalPages;
                this.pageable.page = result.data.page;
            });
        },
        /**
         * change page and navigate to the selected page
         * @param page
         */
        changePage(page){
            this.pageable.page = page;
            this.filterWorkspaces(this.filter, this.pageable);
        },
        openConfirmationDialog(workspaceId) {
            this.dialog = true;
            this.workspaceIdToDelete = workspaceId;
        },
        deleteWorkspace() {
            this.dialog = false;
            axios.delete('administration/workspace/' + this.workspaceIdToDelete ).then( () => {
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    message: 'administration.workspace.manage.delete.success',
                    type: 'SUCCEED'
                });
                if ( this.workspaceList.length <= 1 && this.pageable.page > 1) {
                    this.pageable.page = this.pageable.page - 1;
                }
                this.filterWorkspaces(this.filter, this.pageable);
            });
        }
    }
}
</script>

<style scoped>
    .custom_workspace{
        background-color: #355B7F;color:white;padding-top:25px;text-align: center;width:100vw;
    }
    .layout_workspace{
        background-color: #355B7F;color:white;padding-top:10px;
    }
    .sizer_flex{
        width:100vw;
    }
    .align-items{
        text-align: center;
    }
    .icon-customer{
        margin-left: 15px;
    }
    .workspace-list{
        transform: translate(45%,0);margin-top:15px;
    }
    .icon-add-custom{
        position: fixed; right: 50px; bottom: 0;
    }
</style>


