<template >
    <v-layout row wrap align-start justify-space-between>

        <v-container fluid grid-list-md>
            <v-layout row wrap >
                <v-flex v-for="pao in paoList" :key="pao.id">
                    <div v-if="pao.status !== updating">
                    <router-link :to="{name:'paoDetails' , params:{ id: pao.id ,title: pao.title}}" tag='div'>
                        <v-hover>
                            <v-card  slot-scope="{ hover }"
                                    :class="`elevation-${hover ? 12 : 2}`"
                                    class="mx-auto card"
                                    width="350px"
                                    height="190px"
                                    style="cursor: pointer;"
                                >
                                <v-card-title class="font-weight-bold">
                                    <span class="title_to_ellips"><div class="type_of_card" ></div>{{pao.title}}</span>
                                </v-card-title>
                                <v-card-text  class="text_of_card">{{ $t('pao.label.status')}} : <span class="font-weight-bold">  {{$t(`pao.status.label.${pao.status}`)}}</span></v-card-text>
                            </v-card>
                        </v-hover>
                    </router-link>
                    </div>
                    <div v-else>
                        <v-card  class="mx-auto invalid"
                                 width="350px"
                                 height="190px"
                                 disabled
                            >
                            <v-card-title class="font-weight-bold">
                                <span class="title_to_ellips"><div class="type_of_card" ></div>{{pao.title}}</span>
                            </v-card-title>
                            <v-card-text  class="text_of_card">{{ $t('pao.label.status')}} : <span class="font-weight-bold">  {{$t(`pao.status.label.${pao.status}`)}}</span></v-card-text>
                            <v-icon @click="openConfirmUpdateContent(pao.id)" class="stop-icon">stop</v-icon>
                        </v-card>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>

        <v-flex v-if="paoList.length > 0" xs12 text-xs-center>
            <v-pagination
                    :length="pageable.totalPages"
                    :total-visible="8"
                    @input="changePage"
                    :value="pageable.page"
                    circle/>
        </v-flex>
        <dialog-confirmation :dialog.sync="openConfirmation" :message="confirmationMessage" @confirm="stopRefresh"/>

    </v-layout>
</template>
<script>
import StoreModule      from '../../../shared/constant/store/module/namespace';
import { mapState }     from 'vuex';
import axios            from 'axios';
import DialogConfirmation  from '../../shared/DialogConfirmation';
/**
 * Display a pao list from a search, dispalyed  as cards.
 * 
 * @author Cédric de BOISVILLIERS, SFEIR benelux
 * @version 1.0
 * @since 17/05/2019
 */
export default {
    name:'PaoCardList',
    props: {
        paoList: {
            default: () => []
        }
    },
    data() {
        return {
            openConfirmation: false,
            confirmationMessage: 'pao.message.confirm-stop-update-content-pao',
            idpaoToStop:0
        }
    },
    computed: {
        ...mapState(StoreModule.PAO_FILTER, {
            pageable: state => state.pageable
        }),
        updating(){
            return 'UPDATE_IN_PROGRESS';
        },
    },
    methods:{
        /**
         * change page and navigate to the selected page
         * @param page
         */
        changePage(page){
            this.$emit('changePage', page);
        },
        stopRefresh(){
            axios.put(
                `pao/${this.idpaoToStop}/stopUpdateContent`
            ).then(() => {
                this.idpaoToStop = 0;
                this.changePage(1);
            });
        },
        openConfirmUpdateContent(idpao){
            this.idpaoToStop = idpao;
            this.openConfirmation = true;
        },
    },
    components:{
        DialogConfirmation
    }
}
</script>

<style scoped>
    .stop-icon{
        border-radius: 50px !important;
        position: absolute;
        bottom:10px;
        right:10px;
        background-color: white;
        width:50px;
        height:50px;
        color:#355B7F;
    }
    .stop-icon:hover{
        transform: scale(1.1);
        cursor: pointer;
    }
.type_of_card{
    display:inline-block;
    float:left;
    margin: 0 10px 0 0;
    width: 20px;
    height:20px;
    border: lightgrey 1px solid;
}
.text_of_card{
    font-size: 1.0em;
    padding-top: 0;
    padding-bottom: 0;
}

.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
}

@-webkit-keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }
}

@keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }
}

.card:hover{
    animation: pulse 1s;
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
}
    .invalid{
        cursor: default;
        background-color: lightgrey;
    }

</style>