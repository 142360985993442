<template>
    <v-app>
        <router-view />
    </v-app>
</template>

<script>
import Login      from './components/Login';
import Main       from './components/Main';

export default {
    name: 'App',
    components: {
        Login,
        Main
    }
}
</script>
<style src="../public/style/style.css">

</style>

