<template>

    <div >
        <div v-if="isDocumentLinkable" >
            <v-divider dark class="divider_doc"></v-divider>

            <v-layout  align-end justify-center column >
                <v-flex  class="actions" @click="goToPage(idDocument)">
                    <span class="text_actions">{{ $t('document.action.create-pao-link') }}</span>
                    <v-btn color="white" small fab dark slot="activator" >
                        <v-icon color="primary">link</v-icon>
                    </v-btn>
                </v-flex>
                <v-flex v-if="step === validation && status === available" class="actions">
                    <span class="text_actions">{{ $t('document.action.managePaoLinks') }}</span>
                    <button-to-editable-state @to-page="goToPageWithIdDocumentPath(`/document/${idDocument}/pao`)" :iconText="'insert_link'"></button-to-editable-state>
                </v-flex>
            </v-layout>
        </div>
    </div>
</template>

<script>
import ConstantDocumentType       from '../../../../shared/constant/constantDocumentType';
import { mapState }             from 'vuex';
import ConstantWorkflow         from '../../../../shared/constant/constantWorkflow.js';
import ButtonToEditableState    from '../../../shared/ButtonToEditableState';

/**
 * 
 * Manage the commands of a document link.
 * 
 * @author Sébastien DE SANTIS, SFEIR Benelux
 * @version 1.0
 * @since 17/01/2020
 */
export default {
    name: "ActionPaoLinkComponent",
    props: {
        idDocument:{
            default:null
        },
        dataSheet: {
            default: null
        },
    },
    components:{
        ButtonToEditableState
    },
    computed:{
        ...mapState({
            status: state => state.dataSheetStore.metaDocument.documentStatus,
            step: state => state.dataSheetStore.metaDocument.documentStep,
        }),
        edition(){
            return ConstantWorkflow.EDITION;
        },
        marking(){
            return ConstantWorkflow.MARKING;
        },
        qualityControl(){
            return ConstantWorkflow.QUALITY_CONTROL;
        },
        validation(){
            return ConstantWorkflow.VALIDATION;
        },
        available(){
            return ConstantWorkflow.AVAILABLE;
        },
        consolidation(){
            return ConstantWorkflow.CONSOLIDATION;
        }
    },
    methods: {
        /**
         * Return true if the document type exist
         * and if the document is JURISPRUDENCE / DOCTRINE / BIBLIOGRAPHY
         */
        isDocumentLinkable() {
            return !!this.dataSheet.documentType
                && (
                    this.dataSheet.documentType.code === ConstantDocumentType.JURISPRUDENCE
                    || this.dataSheet.documentType.code === ConstantDocumentType.DOCTRINE
                    || this.dataSheet.documentType.code === ConstantDocumentType.BIBLIOGRAPHY)
        },
        goToPage(idDocument) {
            this.$router.push({name: 'paolink', params: {idDocument: idDocument}});
        },
        goToPageWithIdDocumentPath(idDocumentPath) {
            this.$router.push({path: idDocumentPath});
        },
    }
}
</script>

<style scoped>
    .actions{
        margin-bottom:20px;
    }
    .text_actions{
        color:white;
    }
    .divider_doc{
        margin-bottom:20px;
    }
</style>