const DOCUMENT_CONTENT      = 'documentContent';
const BLOCKQUOTE            = 'blockquote';

/**
 * Names of different instance of a store
 * @author Justin WILMET
 * @since 12/06/2019
 * @version 1.0
 */
export default class ConstantStoreName {
    static get DOCUMENT_CONTENT(){
        return DOCUMENT_CONTENT;
    }
    static get BLOCKQUOTE(){
        return BLOCKQUOTE;
    }
}