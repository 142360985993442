import * as $               from 'jquery';
import ConstantDocumentNode from '../shared/constant/constantDocumentNode.js';
import ConstantStoreName    from '../shared/constant/constantStoreName.js';
import DocumentTreeService  from '../service/document/documentTreeService.js';

/**
 * Manage common behaviors for components displaying a document Node Content.
 * @author Cédric de BOISVILLIERS
 * @since 14/12/2018
 * @version 1.0
 */
export const nodeContentMixin = {
    methods: {
        /**
         * Find the document node clicked on
         * @param {MouseEvent} event 
         * @param {Document node} rootNode 
         */
        findClickedDocumentNode(event, rootNode){
            const contentNode = event.composedPath().find(htmlElement => {
                return !!htmlElement.className && htmlElement.className.includes(ConstantDocumentNode.NODE_CONTENT_CLASS);
            });
            let node;
            if(!!contentNode) {
                let nodeId = contentNode.id.substring(ConstantDocumentNode.NODE_CONTENT_ID_PREFIX.length);
                node = DocumentTreeService.findNodeById(rootNode, nodeId);
            }
            return node;
        },
        /**
         * Animate the scroll to focus on the selected node.
         * Here the selected node should be in the middle of a buffered list
         * @param {document Node} selectedNode 
         */
        scrollToSelectedNode(selectedNode) {
            if(selectedNode){
                let container = $(`#container-${ConstantStoreName.DOCUMENT_CONTENT}`);
                if( !!container && !!container.offset() ) {
                    var anchorReferenceElement = $( `#${ConstantDocumentNode.NODE_CONTENT_ID_PREFIX}${selectedNode.id}` );
                    if( !!anchorReferenceElement && !!anchorReferenceElement.offset()){
                        container.animate({
                            scrollTop: anchorReferenceElement.offset().top - container.offset().top + container.scrollTop()
                        });
                    }
                }
            }
        }
    }
}