const AUTHOR                        = 'author';
const AGE                           = 'age';
const ALTERNATIVE_TITLE             = 'alternativeTitle';
const BASE_ACT                      = 'baseAct';
const BASE_DOCUMENT                 = 'baseDocument';
const CODE                          = 'code';
const COMMERCIAL_COURT              = 'commercialCourt';
const COUNTRY                       = 'country';
const DAMAGE_TYPE                   = 'damageType';
const DATE_DECISION                 = 'dateDecision';
const DATE_DOCUMENT                 = 'dateDocument';
const DATE_END_APPLICABILITY        = 'endDateOfApplicability';
const DATE_ENTRY                    = 'entryDate';
const DATE_PUBLICATION              = 'datePublication';
const DATE_START_APPLICABILITY      = 'startDateOfApplicability';
const DISMISSAL_TYPE                = 'dismissalType'
const DOMAIN                        = 'domain';
const GENDER                        = 'gender';
const GIVEN                         = 'GIVEN';
const GUIDELINE_NATURE              = 'guidelineNature';
const GUIDELINE_SOURCE              = 'guidelineSource';
const IS_VISIBLE                    = 'isVisible';
const JUDICIAL_ORDER                = 'judicialOrder';
const JURISDICTION                  = 'jurisdiction';
const NATURE                        = 'nature';
const NONE                          = 'NONE';
const NUMBER                        = 'number';
const MONOGRAPHY                    = 'MON';
const MORAL_ALLOWANCE               = 'moralAllowance';
const MAGAZINE                      = 'REV';
const LEGITECH_CODE                 = 'LGC';
const PARTNER_ID                    = 'idPartner';
const PAGE                          = 'page';
const PHYSICAL_ALLOWANCE            = 'physicalAllowance';
const SALARY                        = 'salary';
const SEARCH_TAG                    = 'searchTag';
const SECTOR                        = 'sector';
const SENIORITY                     = 'seniority';
const SOURCE                        = 'source';
const SOURCE_TYPE                   = 'sourceType';
const SOURCE_TYPE_ARTICLES          = 'ART';
const STATUS                        = 'status';
const MARKET_ID                     = 'marketsId';
const AUTHOR_ID                     = 'authorsId';
const WORKSPACE_ID                  = 'idWorkspace';
const DOCUMENT_TYPE_ID              = 'idDocumentType';
const TITLE                         = 'title';
const INDETERMINATE                 = 'indeterminate';
const CAUSE_END_OF_APPLICABILITY    = 'causeEndOfApplicability';
const SOURCE_END_OF_APPLICABILITY   = 'sourceEndOfApplicability';
const OTHER_WORKSPACE_ID_LIST       = 'idOtherWorkspace';
const REMARK                        = 'remark';
const DOCUMENT_TYPE                 = 'documentType';
const TERRITORY                     = 'territory';
const LANGUAGE                      = 'language';

/**
 * All necessary fields for a datasheet
 * @author Cindy SWENNEN
 * @since 04/09/2019
 * @version 1.0
 */
export default class ConstantDatasheetField {
    
    static get DATE_START_APPLICABILITY(){
        return DATE_START_APPLICABILITY;
    }
    static get DATE_END_APPLICABILITY(){
        return DATE_END_APPLICABILITY;
    }
    static get DATE_DECISION(){
        return DATE_DECISION;
    }
    static get DATE_DOCUMENT(){
        return DATE_DOCUMENT;
    }
    static get DATE_ENTRY(){
        return DATE_ENTRY;
    }
    static get DATE_PUBLICATION(){
        return DATE_PUBLICATION;
    }
    static get DOMAIN(){
        return DOMAIN;
    }
    static get JURISDICTION(){
        return JURISDICTION;
    }
    static get NUMBER(){
        return NUMBER;
    }
    static get SOURCE_TYPE(){
        return SOURCE_TYPE;
    }
    static get MONOGRAPHY(){
        return MONOGRAPHY;
    }
    static get MAGAZINE(){
        return MAGAZINE;
    }
    static get PARTNER(){
        return {
            LEGITECH_CODE
        }
    }
    static get SOURCE_TYPE(){
        return{
            SOURCE_TYPE_ARTICLES
        }
    }
    static get CAUSE_END_OF_APPLICABILITY_VALUE(){
        return {
            GIVEN,
            NONE
        }
    }
    static get FIELD(){
        return {
            PARTNER_ID,
            WORKSPACE_ID,
            AUTHOR_ID,
            MARKET_ID,
            DOCUMENT_TYPE_ID,
            DOCUMENT_TYPE,
            TITLE,
            INDETERMINATE,
            CAUSE_END_OF_APPLICABILITY,
            SOURCE_END_OF_APPLICABILITY,
            OTHER_WORKSPACE_ID_LIST,
            REMARK,
            IS_VISIBLE
        }
    }
    static get ADDITIONAL_FIELD(){
        return {
            AUTHOR,
            AGE,
            ALTERNATIVE_TITLE,
            BASE_ACT,
            BASE_DOCUMENT,
            CODE,
            COMMERCIAL_COURT,
            COUNTRY,
            DAMAGE_TYPE,
            DATE_DECISION,
            DATE_DOCUMENT,
            DATE_ENTRY,
            DATE_PUBLICATION,
            DATE_START_APPLICABILITY,
            DISMISSAL_TYPE,
            DOMAIN,
            GENDER,
            GUIDELINE_NATURE,
            GUIDELINE_SOURCE,
            JUDICIAL_ORDER,
            JURISDICTION,
            MORAL_ALLOWANCE,
            NATURE,
            NUMBER,
            PAGE,
            PHYSICAL_ALLOWANCE,
            SALARY,
            SEARCH_TAG,
            SECTOR,
            SENIORITY,
            SOURCE,
            SOURCE_TYPE,
            STATUS,
            TERRITORY,
            LANGUAGE
        }
    }
}
