<template>
    <v-layout
        row
        wrap
        align-left
    >
        <v-flex>
            <v-container>
                <h1
                    v-if="$route.params.id == null"
                    class="bolded"
                >
                    {{ $t('administration.author.manage.add.title') }}
                </h1>
                <v-form
                    ref="formAuthor"
                    v-model="valid"
                    class="form-style"
                    lazy-validation
                >
                    <v-layout
                        column
                        justify-center
                    >
                        <v-flex md6>
                            <v-text-field
                                v-model="author.firstName"
                                :label="$t('administration.author.firstName')"
                                :rules="firstNameRules"
                                required
                                clearable
                                @keyup="toCapitalLetters"
                            />
                        </v-flex>
                        <v-flex md6>
                            <v-text-field
                                v-model="author.lastName"
                                :label="$t('administration.author.lastName')"
                                :rules="lastNameRules"
                                required
                                clearable
                                @keyup="toUpperCase"
                            />
                        </v-flex>
                        <!-- BUTTONS -->
                        <v-flex
                            xs3
                            justify-end
                        >
                            <v-tooltip
                                top
                                class="align-action-end"
                            >
                                <v-btn
                                    slot="activator"
                                    color="#355B7F"
                                    small
                                    fab
                                    :to="{ name: 'AuthorSearch' }"
                                >
                                    <v-icon color="white">
                                        cancel
                                    </v-icon>
                                </v-btn>
                                <span>{{ $t("administration.author.manage.action.cancel") }}</span>
                            </v-tooltip>
                            <v-tooltip
                                v-if="valid"
                                top
                                class="align-action-end"
                            >
                                <v-btn
                                    slot="activator"
                                    color="primary"
                                    small
                                    fab
                                    dark
                                    icon
                                    @click="manage(author)"
                                >
                                    <v-icon>save</v-icon>
                                </v-btn>
                                <span>{{ $t("administration.author.manage.action.add") }}</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-container>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from 'axios';
import EventBus from '../../../utils/event-bus.js';
import ConstantEvent from '../../../shared/constant/constantEvent.js'

export default {
    name: 'AuthorManagement',
    data() {
        return {
            valid: false,
            author: {
                id: null,
                firstName: null,
                lastName: null
            },
            firstNameRules: [
                v => !!v || this.$t("administration.author.manage.required.firstName"),
                v => !!v && v.length < 30 || this.$t("administration.author.manage.required.tooLong") + 30
            ],
            lastNameRules: [
                v => !!v || this.$t("administration.author.manage.required.lastName"),
                v => !!v && v.length < 30 || this.$t("administration.author.manage.required.tooLong") + 30
            ]
        }
    },
    mounted() {
        if (this.$route.params.id !== undefined) {
            this.loadAuthor(this.$route.params.id);
        }
    },
    methods: {
        toUpperCase() {
            this.author.lastName = this.author.lastName.toUpperCase();
        },
        toCapitalLetters() {
            const capitalize = (value) => {
                return value.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());
            }
            if(this.author.firstName){
                const namesArray = this.author.firstName.split('-');
                let firstName = capitalize(namesArray[0]); //first word
                for(let i = 1; i < namesArray.length; i++){
                    //starts from second word if it exists
                    firstName += '-' + capitalize(namesArray[i]);
                }
                this.author.firstName = firstName;
            }
        },

        manage(author) {
            if (this.$refs.formAuthor.validate()) {
                var method = null;
                if (!!this.$route.params.id) {
                    method = "PUT",
                    author.id = this.$route.params.id;
                } else {
                    method = "POST";
                }

                axios({
                    method: method,
                    url: "administration/author",
                    data: author
                }).then(() => {
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                        message: 'administration.author.manage.' + (!this.$route.params.id ? 'add' : 'edit') + '.success',
                        type: 'SUCCEED'
                    }
                    );
                    this.$router.push('/administration/author');
                });
            }
        },

        loadAuthor(id) {
            axios.get(
                `administration/author/${id}`
            ).then(result => {
                this.author = result.data;
            });
        },
    }
}
</script>
<style>
.form-style {
  margin-top: 50px;
}

.align-action-end {
  float: right;
}

.bolded {
  font-weight: bold;
  color: #355B7F;
}
</style>
