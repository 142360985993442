<template>
    <v-dialog v-model="openDialog" persistent max-width="700px">
        <v-card>
            <v-card-title class="headline">{{$t('document.node.label.delete')}}</v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="!notDeletable" align-center>{{ $t('document.node.action.deletionMessage')}}</v-card-text>
            <v-card-text v-if="notDeletable" align-center class="font-warning">
                {{ $t('document.node.action.impossibleToDelete')}}
                <v-list>
                    <v-list-tile
                        v-for="link in links"
                        :key="link.id"
                    >
                        <v-list-tile-content class="font-link-list" >
                            <span v-bind:class="link.referenceDocumentSource/link.uuidNodeDocumentSource">{{link.documentTitle}}</span>
                        </v-list-tile-content>
                    </v-list-tile>
                </v-list>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" flat :disabled="!valid" @click.native="close();">{{$t('document.action.cancel')}}</v-btn>
                <v-btn color="primary" v-if="!notDeletable" flat @click.native="deleteNode();">{{$t('document.action.delete')}}</v-btn>
                <v-btn color="primary" v-if="notDeletable" flat @click.native="close();">{{ $t('global.action.close')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios                    from 'axios';
import ConstantEvent            from '../../../../shared/constant/constantEvent.js';
import EventBus                 from '../../../../utils/event-bus.js';
import { mapState, mapActions } from 'vuex';

const UPDATE_DIALOG = 'update:dialog';
/**
 * 
 * Open a dialog to delete a node in a document content tree
 * @author Justin WILMET
 * @since 10/10/2018
 * @version 1.0
 * 
 */
export default {
    name: 'DialogDeleteNode',
    props: {
        storeName: {
            default: null
        },
        dialog: {
            default: false
        },
        documentId: {
            default: null
        }
    },
    data: function(){
        return{
            valid: true,
            NodesId: [],
            notDeletable: false,
            links: []
        }
    },
    computed: {
        selectedNode(){
            return this.$store.state[`${this.storeName}`].selectedNode;
        },
        openDialog: {
            get(){
                return this.dialog;
            },
            set( dialog ){
                if( !dialog ){
                    return this.$emit(UPDATE_DIALOG, false);
                }
            }
        }
    },
    methods:{
        /**
         * Extract each id from the selected node and all his children
         * @param {Node} node, the node to delete
         */
        $extractNodesId( node ){
            this.NodesId.push(node.id);
            if (node.children != null){
                for( let i=0; i < node.children.length; i++){
                    this.$extractNodesId(node.children[i]);
                }
            }
            return this.NodesId;
        },
        /**
         * Look if the node is the target of a link in others document, if it's the case
         * the node can't be deleted otherwise the node is deleted.
         */
        deleteNode() {
            this.$extractNodesId(this.selectedNode);
            axios.post(
                'link/document/nodes',
                { 
                    uuidNodesList: this.NodesId,
                    documentId: this.documentId
                }
            ).then( result => {
                if(result.data.length > 0){
                    this.links = result.data;
                    this.notDeletable = true;
                } else {
                    this.$store.dispatch(`${this.storeName}/deleteSelectedNode`, this.selectedNode);
                    this.close();
                }
            }).catch(error => {
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                                    type: ConstantNotification.ERROR,
                                    message: 'document.error.timeout.deleteNode',
                                })
                this.close();
            });;
            
        },
        /**
         * Reset all the form component and close the dialog
         */
        close() {
            this.notDeletable = false;
            this.links = [];
            this.NodesId = [];
            this.$emit(UPDATE_DIALOG, false);
        }
    }
}
</script>
<style scoped>
.font-warning {
    font-size: 18px;
}
.font-link-list {
    color: #1976d2;
    text-decoration: underline;
}
.spinner-circus{
    position:fixed;
    top:30px;
    right:20px;
}
</style>

