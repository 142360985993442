<template>
    <notification-card
        :notifications="notifications"
        @remove-notification="removeNotification"
    ></notification-card>
</template>

<script>
import EventBus         from '../../../utils/event-bus.js';
import ConstantEvent    from '../../../shared/constant/constantEvent.js';
import NotificationCard from './NotificationCard.vue';
/**
 * Manage a notification which can be triggered from anywhere.
 * To activate a notification you have to 
 * Create a structure like :
 * {
 *  type: [ConstantNotification],
 *  message:[i18n reference]
 * }
 * And call the event bus on ConstantEvent.ADD_NOTIFICATION
 * 
 * @author Cédric de BOISVILLIERS, SFEIR Benelux
 * @version 1.0
 * @since 30/11/2018
 */
export default {
    name: 'NotificationContainer',
    components: {
        NotificationCard
    },
    data: () => ({
        notifications: [],
        msgNbr: 0
    }),
    methods: {
        removeNotification (id) {
            const index = this.notifications.findIndex( notification => notification.id === id);
            if (index !== -1) {
                this.notifications.splice(index, 1);
            }
        }
    },
    created: function() {
        EventBus.$on( ConstantEvent.ADD_NOTIFICATION, notification => {
            notification.id = this.msgNbr++;
            this.notifications.push(notification);
        });
    },
    beforeDestroyed: function() {
        EventBus.$off( ConstantEvent.ADD_NOTIFICATION );
    }
}
</script>
