const EDITABLE_TABLE_CLASS              = 'bol-editable-table';
const CUSTOM_LIST_CLASS                 = 'bol-custom-list';
const CUSTOM_LIST_FOOTNOTE_INDEX_CLASS  = 'custom-footnote-index';
const CUSTOM_UPLOAD_FILE_CLASS          = 'bol-custom-upload_file';
const CUSTOM_UPLOAD_FILE_PADDING        = 'uploadFile-padding';
const CUSTOM_TABLE_FOOTNOTE_CLASS       = 'bol-custom-table-footnote';
const CUSTOM_TABLE_FOOTNOTE_INDEX_CLASS = 'bol-custom-table-footnote-index';
const CUSTOM_TABLE_FOOTNOTE_RANK_CLASS  = 'bol-custom-table-footnote-rank';
const CUSTOM_TABLE_FOOTNOTE_TFOOT_CLASS = 'bol-custom-table-tfoot-footnote';

export default class ConstantNodeContentElement{

    static get EDITABLE_TABLE_CLASS(){
        return EDITABLE_TABLE_CLASS;
    }
    static get CUSTOM_LIST_CLASS(){
        return CUSTOM_LIST_CLASS;
    }
    static get CUSTOM_LIST_FOOTNOTE_INDEX_CLASS(){
        return CUSTOM_LIST_FOOTNOTE_INDEX_CLASS;
    }
    static get CUSTOM_UPLOAD_FILE_CLASS(){
        return CUSTOM_UPLOAD_FILE_CLASS;
    }
    static get CUSTOM_TABLE_FOOTNOTE_CLASS(){
        return CUSTOM_TABLE_FOOTNOTE_CLASS;
    }
    static get CUSTOM_TABLE_FOOTNOTE_INDEX_CLASS(){
        return CUSTOM_TABLE_FOOTNOTE_INDEX_CLASS;
    }
    static get CUSTOM_TABLE_FOOTNOTE_RANK_CLASS(){
        return CUSTOM_TABLE_FOOTNOTE_RANK_CLASS;
    }
    static get CUSTOM_TABLE_FOOTNOTE_TFOOT_CLASS(){
        return CUSTOM_TABLE_FOOTNOTE_TFOOT_CLASS;
    }
    static get CUSTOM_UPLOAD_FILE_PADDING(){
        return CUSTOM_UPLOAD_FILE_PADDING;
    }
}