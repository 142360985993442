<template>
    <v-container>
        <v-layout
            v-if="displaySpinner"
            align-center
            justify-center
            row
        >
            <v-progress-circular
                :size="70"
                :indeterminate="true"
                color="primary"
            />
        </v-layout>
        <v-form
            v-if="!displaySpinner"
            ref="formCreateEditDocumentStepTwo"
            v-model="valid"
            lazy-validation
        >
            <!-- MAIN FORM -->
            <!-- TITLE -->
            <v-text-field
                v-if="datasheet.documentType.code === JURISPRUDENCE"
                :value="datasheet.title"
                :label="$t('document.create.title')"
                :rules="titleRules"
                required
                clearable
                @change="updateAdditionalField($event, DATASHEET_FIELD.TITLE)"
            />
            <!-- ALTERNATIVE TITLE -->
            <v-text-field
                v-if="isFieldOnForm(ADDITIONAL_FIELD.ALTERNATIVE_TITLE)"
                :value="alternativeTitleValue"
                :label="$t('document.create.alternative-title')"
                clearable
                @input="updateAdditionalField($event, ADDITIONAL_FIELD.ALTERNATIVE_TITLE)"
            />
            <!-- JURISDICTION -->
            <autocomplete-select
                v-if="isFieldOnForm(ADDITIONAL_FIELD.JURISDICTION)"
                :value="jurisdictionValue"
                :label="$t('document.create.jurisdiction')"
                :items="jurisdictionList"
                :rule="jurisdictionTypeRules"
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.JURISDICTION)"
            />
            <!-- BASE DOCUMENT -->
            <autocomplete-select
                v-if="isFieldOnForm(ADDITIONAL_FIELD.BASE_DOCUMENT)"
                :value="baseDocumentValue"
                :label="$t('document.create.basedocument')"
                :items="baseDocumentList"
                clearable
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.BASE_DOCUMENT)"
            />
            <!-- DOCUMENT DATE DATEPICKER -->
            <v-layout
                row
                wrap
                justify-start
            >
                <bol-date-picker
                    v-if="isFieldOnForm(ADDITIONAL_FIELD.DATE_DOCUMENT)"
                    :value="dateDocumentValueDate"
                    :label="$t('document.create.documentdate')"
                    @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.DATE_DOCUMENT, 'date')"
                />
                <!-- DATE OF PUBLICATION DATEPICKER -->
                <bol-date-picker
                    v-if="isFieldOnForm(ADDITIONAL_FIELD.DATE_PUBLICATION)"
                    :value="datePublicationValueDate"
                    :label="$t('document.create.dateofpublication')"
                    @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.DATE_PUBLICATION, 'date')"
                />
            </v-layout>
            <!-- COUNTRY -->
            <autocomplete-select
                v-if="isFieldOnForm(ADDITIONAL_FIELD.COUNTRY)"
                :value="countryValue"
                :label="$t('document.create.country')"
                :items="countryList"
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.COUNTRY)"
            />
            <!-- NUMBER -->
            <v-text-field
                v-if="isFieldOnForm(ADDITIONAL_FIELD.NUMBER)"
                :value="numberValue"
                :label="$t('document.create.number')"
                clearable
                @input="updateAdditionalField($event, ADDITIONAL_FIELD.NUMBER)"
            />
            <!-- DATE OF ENTRY INTO FORCE DATEPICKER -->
            <v-layout
                row
                wrap
                justify-center
            >
                <bol-date-picker
                    v-if="isFieldOnForm(ADDITIONAL_FIELD.DATE_ENTRY)"
                    :value="entryDateValueDate"
                    :label="$t('document.create.dateofentryintoforce')"
                    @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.DATE_ENTRY, 'date')"
                />
                <!-- START DATE OF APPLICABILITY DATEPICKER -->
                <bol-date-picker
                    v-if="isFieldOnForm(ADDITIONAL_FIELD.DATE_START_APPLICABILITY)"
                    :value="startDateOfApplicabilityValueDate"
                    :label="$t('document.create.startdateofapplicability')"
                    @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.DATE_START_APPLICABILITY, 'date')"
                />
            </v-layout>
            <!-- BASE ACT -->
            <v-flex>
                <v-checkbox
                    v-if="isFieldOnForm(ADDITIONAL_FIELD.BASE_ACT)"
                    :label="$t('document.create.baseact')"
                    color="primary"
                    :input-value="baseActValue"
                    @change="updateAdditionalField($event, ADDITIONAL_FIELD.BASE_ACT, 'boolean')"
                />
            </v-flex>
            <!-- AUTHORS --->
            <autocomplete-select
                v-if="isFieldOnForm(ADDITIONAL_FIELD.AUTHOR)"
                :value="authorsId"
                :label="$t('document.create.authors')"
                :multiple="true"
                :chips="true"
                :items="authorList"
                @onBindModel="setField($event, DATASHEET_FIELD.AUTHOR_ID)"
            />
            <!-- DOMAINS -->
            <!-- eslint-disable no-eval -->
            <autocomplete-select
                v-if="isFieldOnForm(ADDITIONAL_FIELD.DOMAIN)"
                :value="domainIds"
                :return-object="true"
                :label="$t('document.create.domains')"
                :multiple="true"
                :chips="true"
                :items="domainsInChosenMarkets"
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.DOMAIN, 'domain')"
            />
            <!-- CODE -->
            <autocomplete-select
                v-if="isFieldOnForm(ADDITIONAL_FIELD.CODE)"
                :value="codeValue"
                :label="$t('document.create.code')"
                :items="codeList"
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.CODE)"
            />
            <!-- NATURE -->
            <autocomplete-select
                v-if="isFieldOnForm(ADDITIONAL_FIELD.NATURE)"
                :value="natureValue"
                :label="$t('document.create.nature')"
                :items="natureList"
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.NATURE)"
            />
            <!-- GUIDELINE NATURE -->
            <autocomplete-select
                v-if="isFieldOnForm(ADDITIONAL_FIELD.GUIDELINE_NATURE)"
                :value="guidelineNatureValue"
                :label="$t('document.create.nature')"
                :items="guidelineNatureList"
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.GUIDELINE_NATURE)"
            />
            <!-- TERRITORY -->
            <autocomplete-select
                v-if="isFieldOnForm(ADDITIONAL_FIELD.TERRITORY)"
                :value="territoryValue"
                :label="$t('document.create.territory')"
                :items="territoryListInChosenMarket"
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.TERRITORY)"
            />
            <!-- LANGUAGE -->
            <autocomplete-select
                v-if="isFieldOnForm(ADDITIONAL_FIELD.LANGUAGE)"
                :value="languageValue"
                :label="$t('document.create.language')"
                :items="languageList"
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.LANGUAGE)"
            />
            <!-- DATE DECISION DATEPICKER -->
            <bol-date-picker
                v-if="isFieldOnForm(ADDITIONAL_FIELD.DATE_DECISION)"
                :value="dateDocumentValue"
                :label="$t('document.create.decisiondate')"
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.DATE_DECISION, 'date')"
            />
            <!-- JUDICIAL ORDER -->
            <autocomplete-select
                v-if="isFieldOnForm(ADDITIONAL_FIELD.JUDICIAL_ORDER)"
                :value="judicialOrderValue"
                :label="$t('document.create.judicialorder')"
                :items="judicialOrderList"
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.JUDICIAL_ORDER)"
            />
            <!-- COMMERCIAL COURT -->
            <autocomplete-select
                v-if="isFieldOnForm(ADDITIONAL_FIELD.COMMERCIAL_COURT)"
                :value="commercialCourtValue"
                :label="$t('document.create.commercialcourt')"
                :items="commercialCourtList"
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.COMMERCIAL_COURT)"
            />
            <!-- DISMISSAL TYPE -->
            <autocomplete-select
                v-if="isFieldOnForm(ADDITIONAL_FIELD.DISMISSAL_TYPE)"
                :value="dismissalTypeValue"
                :label="$t('document.create.dismissaltype')"
                :items="dismissalTypeList"
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.DISMISSAL_TYPE)"
            />
            <!-- DAMAGE TYPE -->
            <autocomplete-select
                v-if="isFieldOnForm(ADDITIONAL_FIELD.DAMAGE_TYPE)"
                :value="damageTypeValue"
                :label="$t('document.create.damagetype')"
                :items="damageTypeList"
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.DAMAGE_TYPE)"
            />
            <!-- MORAL ALLOWANCE -->
            <v-text-field
                v-if="isFieldOnForm(ADDITIONAL_FIELD.MORAL_ALLOWANCE)"
                :value="moralAllowanceValue"
                :label="$t('document.create.moralallowance')"
                type="number"
                clearable
                @change="updateAdditionalField($event, ADDITIONAL_FIELD.MORAL_ALLOWANCE)"
            />
            <!-- PHYSICAL_ALLOWANCE -->
            <v-text-field
                v-if="isFieldOnForm(ADDITIONAL_FIELD.PHYSICAL_ALLOWANCE)"
                :value="physicalAllowanceValue"
                :label="$t('document.create.allowanceperiod')"
                type="number"
                clearable
                @change="updateAdditionalField($event, ADDITIONAL_FIELD.PHYSICAL_ALLOWANCE)"
            />
            <!-- SENIORITY -->
            <v-text-field
                v-if="isFieldOnForm(ADDITIONAL_FIELD.SENIORITY)"
                :value="seniorityValue"
                :label="$t('document.create.seniority')"
                clearable
                @change="updateAdditionalField($event, ADDITIONAL_FIELD.SENIORITY)"
            />
            <!-- STATUS -->
            <autocomplete-select
                v-if="isFieldOnForm(ADDITIONAL_FIELD.STATUS)"
                :value="statusValue"
                :label="$t('document.create.status')"
                :items="statusList"
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.STATUS)"
            />
            <!-- SECTOR -->
            <autocomplete-select
                v-if="isFieldOnForm(ADDITIONAL_FIELD.SECTOR)"
                :value="sectorValue"
                :label="$t('document.create.sector')"
                :items="sectorList"
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.SECTOR)"
            />
            <!-- GENDER -->
            <autocomplete-select
                v-if="isFieldOnForm(ADDITIONAL_FIELD.GENDER)"
                :value="genderValue"
                :label="$t('document.create.gender')"
                :items="genderList"
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.GENDER)"
            />
            <!-- AGE -->
            <v-text-field
                v-if="isFieldOnForm(ADDITIONAL_FIELD.AGE)"
                :value="ageValue"
                :label="$t('document.create.age')"
                type="number"
                clearable
                @change="updateAdditionalField($event, ADDITIONAL_FIELD.AGE)"
            />
            <!-- SALARY -->
            <v-text-field
                v-if="isFieldOnForm(ADDITIONAL_FIELD.SALARY)"
                :value="salaryValue"
                :label="$t('document.create.salary')"
                type="number"
                clearable
                @change="updateAdditionalField($event, ADDITIONAL_FIELD.SALARY)"
            />
            <!-- PAGE -->
            <v-text-field
                v-if="isFieldOnForm(ADDITIONAL_FIELD.PAGE)"
                :value="pageValue"
                :label="$t('document.create.page')"
                clearable
                @change="updateAdditionalField($event, ADDITIONAL_FIELD.PAGE)"
            />
            <!-- SOURCE TYPE -->
            <autocomplete-select
                v-if="isFieldOnForm(ADDITIONAL_FIELD.SOURCE_TYPE)"
                :value="sourceTypeValue"
                :label="$t('document.create.sourceType')"
                :items="sourceTypeList"
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.SOURCE_TYPE)"
            />
            <!-- SOURCE -->
            <autocomplete-select
                v-if="datasheet.documentType.code !== GUIDELINE && sourceList.length > 0"
                :value="sourceValue"
                :label="$t('document.create.sourceDocument')"
                :items="sourceList"
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.SOURCE)"
            />
            <!-- GUIDELINE SOURCE -->
            <autocomplete-select
                v-if="datasheet.documentType.code === GUIDELINE && sourceList.length > 0 "
                :value="guidelineSourceValue"
                :label="$t('document.create.sourceDocument')"
                :items="sourceList"
                @onBindModel="updateAdditionalField($event, ADDITIONAL_FIELD.GUIDELINE_SOURCE)"
            />
            <!-- SEARCH TAG -->
            <v-text-field
                v-if="isFieldOnForm(ADDITIONAL_FIELD.SEARCH_TAG)"
                :value="searchTagValue"
                :label="$t('document.create.searchTag')"
                clearable
                @change="updateAdditionalField($event, ADDITIONAL_FIELD.SEARCH_TAG)"
            />
            <!-- DOCUMENT SOURCE -->
            <v-text-field
                v-if="!!sourceTypeValue && sourceList.length === 0"
                :label="$t('document.create.sourceDocument')"
                :value="$t('document.create.noSourceDocument')"
                disabled
            />
            <!-- ACTION -->
            <v-layout
                row
                wrap
                justify-end
            >
                <v-btn
                    color="error"
                    small
                    fab
                    dark
                    @click="$router.go(-1)"
                >
                    <v-icon>clear</v-icon>
                </v-btn>
                <v-btn
                    v-if="!!idDocument"
                    color="primary"
                    small
                    fab
                    dark
                    @click="clear"
                >
                    <v-icon>replay</v-icon>
                </v-btn>
                <v-btn
                    color="primary"
                    small
                    fab
                    dark
                    @click="save(datasheet)"
                >
                    <v-icon>save</v-icon>
                </v-btn>
            </v-layout>
        </v-form>
    </v-container>
</template>
<script>
import axios from 'axios';
import ConstantCustomEvent from '../../../../shared/constant/constantCustomEvent';
import ConstantDatasheetField from '../../../../shared/constant/constantDatasheetField'
import ConstantDocumentType from '../../../../shared/constant/constantDocumentType';
import AutocompleteSelect from '../../../shared/AutocompleteSelect.vue';
import querystring from 'querystring';
import BolDatePicker from '../../../shared/DatePicker';
import {buildDocumentTypeEmptyField} from "../../../../store/modules/dataSheetStore";
import {mapGetters, mapState} from "vuex";

export default {
    name: "DatasheetFormStepTwo",
    components: {
        AutocompleteSelect,
        BolDatePicker
    },
    props:{
        datasheet: Object,
        idDocument: Number
    },
    data() {
        return {
            valid: false,
            baseDocumentList: [],
            fieldList: [],
            codeList: [],
            commercialCourtList: [],
            countryList: [],
            damageTypeList: [],
            dismissalTypeList: [],
            domainList: [],
            authorList: [],
            genderList: [],
            homeCountryList: [],
            judicialOrderList: [],
            jurisdictionList: [],
            natureList: [],
            guidelineNatureList: [],
            sectorList: [],
            statusList: [],
            sourceTypeList: [],
            sourceList: [],
            displaySpinner: false,
            territoryList:[],
            jurisdictionTypeRules: [
                v => !!v || this.$t("document.create.required.jurisdiction")
            ],
            caseNumberTypeRules: [
                v => !!v || this.$t("document.create.required.caseNumber")
            ],
            titleRules: [
                v => !!v || this.$t("document.create.required.title")
            ]
        }
    },
    computed:{
        ...mapState({
            languageList: state => state.languageStore.langList
        }),
        ...mapGetters(
            {
                getLanguageById:  'languageStore/getLanguageById',
                getLanguageByCode: 'languageStore/getLanguageByCode'
            }
        ),
        GUIDELINE() {return ConstantDocumentType.GUIDELINE},
        JURISPRUDENCE() {return ConstantDocumentType.JURISPRUDENCE},
        DATASHEET_FIELD() {return ConstantDatasheetField.FIELD},
        ADDITIONAL_FIELD() {return ConstantDatasheetField.ADDITIONAL_FIELD},
        alternativeTitleValue: {
            get(){
                return !!this.datasheet.basicAdditionalField.alternativeTitle ? this.datasheet.basicAdditionalField.alternativeTitle.value : null;
            }
        },
        jurisdictionValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.jurisdiction ? Number.parseInt(this.datasheet.basicAdditionalField.jurisdiction.value) : '';
            }
        },
        baseDocumentValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.baseDocument ? Number.parseInt(this.datasheet.basicAdditionalField.baseDocument.value) : '';
            }
        },
        dateDocumentValueDate:{
            get(){
                return !!this.datasheet.basicAdditionalField.dateDocument ? this.datasheet.basicAdditionalField.dateDocument.valueDate : null;
            }
        },
        datePublicationValueDate:{
            get(){
                return !!this.datasheet.basicAdditionalField.datePublication ? this.datasheet.basicAdditionalField.datePublication.valueDate : null;
            }
        },
        countryValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.country ? Number.parseInt(this.datasheet.basicAdditionalField.country.value) : '';
            }
        },
        numberValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.number ? this.datasheet.basicAdditionalField.number.value : '';
            }
        },
        entryDateValueDate:{
            get(){
                return !!this.datasheet.basicAdditionalField.entryDate ? this.datasheet.basicAdditionalField.entryDate.valueDate : null;
            }
        },
        startDateOfApplicabilityValueDate:{
            get(){
                return !!this.datasheet.basicAdditionalField.startDateOfApplicability ? this.datasheet.basicAdditionalField.startDateOfApplicability.valueDate : null;
            }
        },
        baseActValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.baseAct ? this.datasheet.basicAdditionalField.baseAct.value : '';
            }
        },
        domainsInChosenMarkets: {
            get(){
                return this.domainList.filter(
                    (domain) => domain.markets.find(
                        market => this.datasheet.marketsId.includes(market.id)
                    ) != null
                );
            }
        },
        territoryListInChosenMarket: {
            get(){
                return this.territoryList.filter(
                    (territory) => territory.marketsId.find(
                        marketId => this.datasheet.marketsId.includes(marketId)
                    ) != null
                );
            }
        }, authorsId: {
            get(){
                return !!this.datasheet.authorsId ? this.datasheet.authorsId: [];
            }
        },
        domainIds:{
            get(){
                return !!this.datasheet.basicAdditionalField.domain ? this.datasheet.basicAdditionalField.domain.domainsId : [];
            }
        },
        codeValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.code ? Number.parseInt(this.datasheet.basicAdditionalField.code.value) : '';
            }
        },
        natureValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.nature ? Number.parseInt(this.datasheet.basicAdditionalField.nature.value) : '';
            }
        },
        guidelineNatureValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.guidelineNature ? Number.parseInt(this.datasheet.basicAdditionalField.guidelineNature.value) : '';
            }
        },
        territoryValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.territory ? Number.parseInt(this.datasheet.basicAdditionalField.territory.value) : '';
            }
        },
        languageValue:{
            get(){
                const language = this.datasheet.basicAdditionalField.language;
                return language && language.value ? this.getLanguageByCode(language.value).id : '';
            }
        },
        dateDocumentValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.dateDecision ? this.datasheet.basicAdditionalField.dateDecision.valueDate : null;
            }
        },
        judicialOrderValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.judicialOrder? Number.parseInt(this.datasheet.basicAdditionalField.judicialOrder.value) : '';
            }
        },
        commercialCourtValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.commercialCourt ? Number.parseInt(this.datasheet.basicAdditionalField.commercialCourt.value) : '';
            }
        },
        dismissalTypeValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.dismissalType ? Number.parseInt(this.datasheet.basicAdditionalField.dismissalType.value) : '';
            }
        },
        damageTypeValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.damageType ? Number.parseInt(this.datasheet.basicAdditionalField.damageType.value) : '';
            }
        },
        moralAllowanceValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.moralAllowance ? this.datasheet.basicAdditionalField.moralAllowance.value : '';
            }
        },
        physicalAllowanceValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.physicalAllowance ? this.datasheet.basicAdditionalField.physicalAllowance.value : '';
            }
        },
        seniorityValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.seniority ? this.datasheet.basicAdditionalField.seniority.value : '';
            }
        },
        statusValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.status ? Number.parseInt(this.datasheet.basicAdditionalField.status.value) : '';
            }
        },
        sectorValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.sector ? Number.parseInt(this.datasheet.basicAdditionalField.sector.value) : '';
            }
        },
        genderValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.gender ? Number.parseInt(this.datasheet.basicAdditionalField.gender.value) : '';
            }
        },
        ageValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.age ? this.datasheet.basicAdditionalField.age.value : '';
            }
        },
        salaryValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.salary ? this.datasheet.basicAdditionalField.salary.value : '';
            }
        },
        sourceTypeValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.sourceType ? Number.parseInt(this.datasheet.basicAdditionalField.sourceType.value) : '';
            }
        },
        sourceValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.source ? Number.parseInt(this.datasheet.basicAdditionalField.source.value) : '';
            }
        },
        guidelineSourceValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.guidelineSource ? Number.parseInt(this.datasheet.basicAdditionalField.guidelineSource.value) : '';
            }
        },searchTagValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.searchTag ? this.datasheet.basicAdditionalField.searchTag.value : '';
            }
        },
        pageValue:{
            get(){
                return !!this.datasheet.basicAdditionalField.page ? this.datasheet.basicAdditionalField.page.value : '';
            }
        }
    },
    created() {
        this.initCreate(this.datasheet);
    },
    methods: {
        /**
         * if the user chooses a jurisprudence the title will be automatically generate with some fields
         */
        generateTitle(){
            if(!!this.datasheet.basicAdditionalField.jurisdiction.value
                && !!this.datasheet.basicAdditionalField.dateDocument.valueDate
                && !!this.datasheet.basicAdditionalField.number.value ){

                var dateFormat = this.formatDateToCasualDate(this.datasheet.basicAdditionalField.dateDocument.valueDate);
                axios.post(
                    'datasheet/generateTitle',
                    {
                        idJurisdiction : this.datasheet.basicAdditionalField.jurisdiction.value,
                        dateDocument : dateFormat,
                        caseNumber : this.datasheet.basicAdditionalField.number.value
                    }
                ).then( ({data}) => {
                    this.$emit(ConstantCustomEvent.EVENT.SET_ADDITIONAL_FIELD, {
                        name: ConstantDatasheetField.FIELD.TITLE,
                        value: data
                    }
                    );
                });
            }
        },
        /**
         * Format the selected date to a casual data
         */
        formatDateToCasualDate( date ){
            return date.substring(8,10)+ "/" + date.substring(5,7)+ "/" + date.substring(0,4)
        },
        /**
         * Method will load all the lists for the second page of a document creation
         * Manage the setting of the default value.
         */
        initCreate( datasheet ) {
            if(!!datasheet) {
                this.displaySpinner = true;
                axios.get(
                    'datasheet/create/init/step2', {
                        'params': {documentTypeId: datasheet.documentType.id, workspaceId: [datasheet.idWorkspace, ...datasheet.idOtherWorkspace]},
                        'paramsSerializer': function(params) {
                            return querystring.stringify(params)
                        }
                    }).then( ({data}) => {
                    this.displaySpinner = false;
                    this.baseDocumentList = data.baseDocumentList;
                    this.fieldList = this.$tranformFieldList(data.fieldList);
                    this.codeList = data.codeList;
                    this.commercialCourtList = data.commercialCourtList;
                    this.countryList = data.countryList;
                    this.damageTypeList = data.damageTypeList;
                    this.dismissalTypeList = data.dismissalTypeList;
                    this.domainList = data.domainList;
                    this.genderList = data.genderList;
                    this.guidelineNatureList = data.guidelineNatureList;
                    this.homeCountryList = data.homeCountryList;
                    this.judicialOrderList = data.judicialOrderList;
                    this.jurisdictionList = data.jurisdictionList;
                    this.authorList = data.authorList;
                    this.natureList = data.natureList;
                    this.sectorList = data.sectorList;
                    this.statusList = data.statusList;
                    this.sourceTypeList = data.sourceTypeList;
                    this.territoryList = data.territoryList;
                    //If it's a doctrine and a new one, set the value to Articles by default.
                    if (this.isDoctrine() && !this.idDocument) {
                        let sourceType = this.sourceTypeList.find(sourceType => sourceType.code === ConstantDatasheetField.SOURCE_TYPE.SOURCE_TYPE_ARTICLES);
                        this.$emit(ConstantCustomEvent.EVENT.SET_ADDITIONAL_FIELD, {
                            value: sourceType.id,
                            name: ConstantDatasheetField.ADDITIONAL_FIELD.SOURCE_TYPE
                        })
                    }
                    this.loadSources();

                    // cleanup territories, if market was changed in previous step
                    const chosenTerritoryField = this.datasheet.basicAdditionalField.territory;
                    if(!!chosenTerritoryField && this.territoryListInChosenMarket.find((territory) => territory.id == chosenTerritoryField.value) == null)
                    {
                        this.datasheet.basicAdditionalField.territory = buildDocumentTypeEmptyField();
                    }

                    // cleanup domains, if market was changed in previous step
                    const domainField = this.datasheet.basicAdditionalField.domain;
                    if(!!domainField && !!domainField.domainsId)
                    {
                        domainField.domainsId = domainField.domainsId.filter(
                            (domainIdToFilter)=>this.domainsInChosenMarkets.find((domainInMarkets) => domainInMarkets.id === domainIdToFilter.id)
                        )
                    }
                });
            }
        },
        /**
         * Transform each element field name into a compliant name
         */
        $tranformFieldList(fieldList){
            return fieldList.map(element => {
                return element.field
                    .toLowerCase()
                    .replace(
                        /_([a-z])/gi,
                        g =>  g[1].toUpperCase());
            })
        },
        /**
         * Method use to check if the field is displayable, based on fieldList
         */
        isFieldOnForm( fieldName ) {
            return this.fieldList.some(element => element === fieldName);
        },
        /**
         * Show a warning if we choose a jurisprudence
         */
        isDoctrine(){
            return this.datasheet.documentType.code === ConstantDocumentType.DOCTRINE;
        },
        /**
         * Save the datasheet depending on if it's an update or a create
         */
        save( item ){
            if (item.basicAdditionalField.startDateOfApplicability !== null &&
                !!item.basicAdditionalField.startDateOfApplicability.valueDate){
                this.setApplicabilityDate();
            }

            if(!!this.idDocument) {
                if (this.$refs.formCreateEditDocumentStepTwo.validate()) {
                    axios.put(
                        'datasheet',
                        item,
                        {params: {idDocument: this.idDocument}}
                    ).then( (data) => {
                        this.$router.go(-1);
                    }, () => {
                        this.error= true;
                        this.message = this.$t('login.error.credentials.bad');
                    });
                }
            } else {
                if (this.$refs.formCreateEditDocumentStepTwo.validate()) {
                    axios.post(
                        'datasheet',
                        item
                    ).then( ({data}) => {
                        this.$router.push( { name:'details' , params: { reference: data.reference, title: item.title } });
                    }, () => {
                        this.error= true;
                        this.message = this.$t('login.error.credentials.bad');
                    });
                }
            }
        },
        /**
         * Reset the form
         */
        clear() {
            this.$refs.formCreateEditDocumentStepTwo.reset();
        },
        /** If the date of entry into force is set and the applicability date is null,
         *  applicability date is equal to date of entry into force
         */
        setApplicabilityDate() {
            if(!!this.datasheet.basicAdditionalField.startDateOfApplicability.valueDate || (
                !this.datasheet.basicAdditionalField.entryDate.valueDate &&
                !this.datasheet.basicAdditionalField.dateDocument.valueDate
            ))
            {
                return;
            }
            let applicabilityDate;
            if ( this.datasheet.documentType.code === ConstantDocumentType.LEGISLATION ){
                applicabilityDate = this.datasheet.basicAdditionalField.entryDate.valueDate;
            } else {
                applicabilityDate = this.datasheet.basicAdditionalField.dateDocument.valueDate;
            }
            this.updateAdditionalField( applicabilityDate, ConstantDatasheetField.ADDITIONAL_FIELD.DATE_START_APPLICABILITY, 'date');
        },
        setField(fieldValue, fieldName){
            this.$emit(ConstantCustomEvent.EVENT.SET_FIELD, { value: fieldValue, name:fieldName });
        },
        /**
         * Update an additional field value using the data store.
         * Manage some special case to convert the field with the correct type.
         * @param additionalFieldValue
         * @param additionalFieldName
         * @param fieldType
         */
        updateAdditionalField(additionalFieldValue, additionalFieldName, fieldType ){
            let additionalField = {};
            if ( fieldType === 'date' ){
                additionalField = {
                    name: additionalFieldName,
                    valueDate: additionalFieldValue,
                    type: fieldType};
            } else if ( fieldType === ConstantDatasheetField.DOMAIN ) {
                additionalField = {
                    name: additionalFieldName,
                    domainsId: additionalFieldValue,
                    type: 'ids'};
            } else if (additionalFieldName === ConstantDatasheetField.ADDITIONAL_FIELD.SOURCE_TYPE){
                additionalField = {
                    name: additionalFieldName,
                    value: Number(additionalFieldValue),
                    type: ''};
            }else if(additionalFieldName === ConstantDatasheetField.ADDITIONAL_FIELD.LANGUAGE){
                additionalField = {
                    name: additionalFieldName,
                    value: this.getLanguageById(additionalFieldValue).code,
                    type: fieldType
                }
            }
            else {
                additionalField = {
                    name: additionalFieldName,
                    value: additionalFieldValue,
                    type: fieldType};
            }
            this.$emit(ConstantCustomEvent.EVENT.SET_ADDITIONAL_FIELD, additionalField);

            if( this.datasheet.documentType.code === ConstantDocumentType.JURISPRUDENCE
                && ( additionalFieldName === ConstantDatasheetField.JURISDICTION
                    || additionalFieldName === ConstantDatasheetField.NUMBER
                    || additionalFieldName === ConstantDatasheetField.DATE_DOCUMENT )){
                this.generateTitle();
            }
            if(additionalFieldName !== ConstantDatasheetField.ADDITIONAL_FIELD.DATE_START_APPLICABILITY){
                this.setApplicabilityDate()
            }
            this.loadSources();
        },
        loadSources(){
            let sourceType = this.sourceTypeList.find( sourceType => {
                return sourceType.id === this.sourceTypeValue;
            });
            if (!!sourceType) {
                if ( sourceType.code === ConstantDatasheetField.MONOGRAPHY){
                    axios.get("administration/publication/book").then( (result) => {
                        this.sourceList = result.data;
                    });
                } else if (sourceType.code === ConstantDatasheetField.MAGAZINE) {
                    axios.get("administration/publication/magazine").then( (result) => {
                        this.sourceList = result.data;
                    });
                }
            } else if (this.datasheet.documentType.code === ConstantDocumentType.GUIDELINE) {
                axios.get("administration/guidelinesource").then( (result) => {
                    this.sourceList = result.data;
                });
            }
        }
    }
}
</script>
