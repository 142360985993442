import ConstantTag                  from '../../shared/constant/constantTag.js';
import ConstantNodeContentElement   from '../../shared/constant/constantNodeContentElement.js';
import ConstantDocumentNode         from '../../shared/constant/constantDocumentNode.js';

class CheckDocumentNodeSelectionService {

    constructor(){
        this.isNotCreatable = false;
    }
    
    /**
     * Check if a node is a PDF link
     * @param {Node} node the node to check
     */
    isPDFLink(node) {
        return node.nodeName === ConstantTag.A && node.className === ConstantNodeContentElement.CUSTOM_UPLOAD_FILE_CLASS;
    }

    /**
     * Check if a node is a custom list
     * @param {Node} node the node to check
     */
    isCustomList(node) {
        return !!node.className && node.className.includes(ConstantNodeContentElement.CUSTOM_LIST_CLASS);
    }

    /**
     * Check if a node is an editable table
     * @param {Node} node the node to check
     */
    isEditableTable(node) {
        return !!node.className && node.className.includes(ConstantNodeContentElement.EDITABLE_TABLE_CLASS);
    }

    /**
     * check if a node is text node(document node)
     * @param {Node} node the node to check
     */
    isDocumentTextNode(node) {
        return !!node.className && node.className.includes(ConstantDocumentNode.TEXT_NODE_CLASS);
    }

    /**
     * check if a node is a picture file
     * @param {Node} node the node to check
     */
    isPictureFile(node) {
        return node.nodeName === 'IMG' && node.className === 'document-image';
    }

    /**
     * check if a node is an HTML list
     * @param {Node} node the node to check
     */
    isHTMLList(node) {
        return !!node.tagName  && node.tagName.includes(ConstantTag.LI);
    }

    /**
     * check if a node is a blockquote (document content blockquote)
     * @param {Node} node the node to check
     */
    isBlockquote(node) {
        return !!node.nodeName && node.nodeName.includes(ConstantTag.BLOCKQUOTE);
    }

    /**
     * Check if an editable table has been clicked on
     * @param {Node} node the node to check
     */
    isTabClicked(node) {
        if(!node){
            return false;
        } else {
            if( !node.className
                || !(node.className.includes(ConstantDocumentNode.TEXT_NODE_CLASS)
                    || node.className.includes(ConstantDocumentNode.NODE_CONTENT_CLASS)
                    || node.className.includes(ConstantNodeContentElement.EDITABLE_TABLE_CLASS)) ) {
                node = node.parentNode;
                return this.isTabClicked(node)
            } else if( node.className.includes(ConstantNodeContentElement.EDITABLE_TABLE_CLASS) ) {
                return true;
            } else {
                return false;
            }
        }
    }

    /**
     * Check the possibility to select multiple text node on click
     * @param {Event} event, the click event
     * @param {Node} selectedNode, the first selected node
     * @param {Node} additionalNode, another to selecte with the selectedNode
     */
    isMultipleTextNodeSelectionAvailable(event, selectedNode, additionalNode) {
        return event.button === 0  && event.altKey
            && !!selectedNode
            && selectedNode.type === ConstantDocumentNode.TEXT_TYPE && additionalNode.type === ConstantDocumentNode.TEXT_TYPE;
    }

    /**
     * Check if the selection is on text that can be selected for action
     * @param {Event} click, mouse event trigger on click
     * @param {Selection} windowSelection, selected part in the navigator (can be empty)
     */
    isSelectionOnText(event, windowSelection){
        const path = event.composedPath();
        const anchorNode = windowSelection.anchorNode;
        if (!!anchorNode) {
            let pathIndex = 0;
            if (this.isCustomList(anchorNode)){
                return false;
            } else {
                while(pathIndex < path.length ) {
                    let node = path[pathIndex];
                    if ( this.isDocumentTextNode(node) ) {
                        return true;
                    } else if ( this.isCustomList(node) || this.isPDFLink(node)
                            || this.isPictureFile(node) || this.isHTMLList(node) || this.isBlockquote(node) ) {
                        return false;
                    }
                    pathIndex++;
                }
                return false;
            }
        }
    }

    /**
     * Force the selection on a word or whiteSpaces,
     * to be visible on Chrome application for Microsoft Windows while clicking.
     * Clicking on a word will put the whole word in the selection.
     * Clicking outside a word will:
     *      - put the whiteSpaces surrouding the click position in the selection if any whiteSpace directly surrounds it
     *      - put the first/last word if click position is before/after the whole text
     */
    forceSelectionOnText( windowSelection ) {
        const WHITESPACE_REGEX              = new RegExp(/\s/);
        const WORD_WHITESPACE_ENDED_REGEX   = new RegExp(/[^\s]*/);
        let selection = windowSelection;
        let anchorNode = selection.anchorNode;
        
        if (selection.isCollapsed && anchorNode.nodeType === Node.TEXT_NODE) {
            let offset = selection.anchorOffset;
            let textContent = anchorNode.textContent;
            //click at beginning of a DOM text node
            if (offset === 0) {
                selection.modify("extend", "forward", "word");
            } 
            //click at end of a DOM text node
            else if (offset === textContent.length) {
                selection.modify('extend', 'backward', 'word');
            } 
            //click on word/whiteSpace inside a DOM text node
            else {
                if ( WHITESPACE_REGEX.test(textContent.charAt(offset - 1)) || WHITESPACE_REGEX.test(textContent.charAt(offset)) ) {
                    let whiteSpacesStartIndex = offset;
                    let whiteSpacesEndIndex = offset;
                    while ( (whiteSpacesStartIndex >= 0) && (WHITESPACE_REGEX.test(textContent.charAt(whiteSpacesStartIndex - 1))) ) {
                        whiteSpacesStartIndex--;
                    }
                    while ( (whiteSpacesEndIndex <= textContent.length) && (WHITESPACE_REGEX.test(textContent.charAt(whiteSpacesEndIndex))) ) {
                        whiteSpacesEndIndex++;
                    }
                    selection.collapse(anchorNode, whiteSpacesStartIndex);//set the anchorOffset to the beginning of the whiteSpaces clicked on
                    selection.extend(selection.anchorNode, whiteSpacesEndIndex);//set the focusOffset to the end of the whiteSpaces clicked on
                } else {
                    selection.modify('move', 'backward', "word");//set the anchorOffset to the beginning of the word clicked on
                    offset += textContent.substring(offset).match(WORD_WHITESPACE_ENDED_REGEX)[0].length;
                    selection.modify('extend', 'forward', "word");//set the focusOffset to the end of the word clicked on
                }
            }
        }
    }

    /**
     * Expand the selection at all a dom node content
     * @param {Node} domNode, the node who need to be entirely selected
     * @param {Selection} windowSelection, a snapshot of the current selection in the browser
     */
    expandSelectionToAllTheDomNode(domNode, windowSelection){
        windowSelection.removeAllRanges();
        //Create a range for the specific dom node
        let range = document.createRange();
        range.selectNode(domNode);
        windowSelection.addRange(range);
        //Put the cursor at the end of the selection
        windowSelection.collapseToEnd();
        //Extend the selection to the begining of the dom node
        windowSelection.extend(domNode, 0);
    }

    /**
     * //TODO: change description of function or of "selection" argument,
     * a Selection Object is the result of window.getSelection() and is live, it can't be a snapshot.
     * 
     * Expand the selection over a list of items
     * @param {Node} blockquotes, the list of the selected nodes
     * @param {Selection} selection, a snapshot of the current selection in the browser 
     */
    expandSelectionToAllSelectedBlockquote( blockquotes, selection){
        let firstNode = blockquotes[0];
        let lastNode = blockquotes[blockquotes.length-1];
        selection.removeAllRanges();
        selection.setBaseAndExtent( firstNode  , 0 ,lastNode,  lastNode.childNodes.length );
    }

    /**
     * Return a DOM node if the DOM node in argument is owned by a BlockQuote dom node.
     * return false otherwise.
     * Check all the parent recursively. Stop if the parent is the TEXT Structural Node.
     * @param {Node} domNode, node selected by the user
     */
    extractParentBlockquoteIfExist( domNode ){            
        if(!domNode){
            return false;
        } else {
            if( domNode.tagName !== ConstantTag.BLOCKQUOTE 
                && ( !domNode.className || !domNode.className.includes(ConstantDocumentNode.NODE_CONTENT_CLASS) ) ){
                return this.extractParentBlockquoteIfExist(domNode.parentNode);
            } else if (domNode.tagName === ConstantTag.BLOCKQUOTE) {
                return domNode;
            } else {
                return false;
            }
        }
    }

    /**
     * Return a DOM node if the DOM node in argument is owned by a BlockQuote dom node.
     * return null otherwise.
     * Check all the parent recursively. Stop if the parent is different of a TEXT Node in the Blockquote.
     * @param {Node} domNode, node selected by the user
     */
    extractPartBlockquoteIfExist( domNode ){
        if(!domNode){
            return null;
        } else {
            if( domNode.tagName !== ConstantTag.BLOCKQUOTE  && ( !domNode.className || domNode.className.includes(ConstantDocumentNode.TEXT_TYPE) ) ){
                return this.extractPartBlockquoteIfExist(domNode.parentNode);
            } else if (domNode.tagName === ConstantTag.BLOCKQUOTE || domNode.tagName !== ConstantDocumentNode.TEXT_TYPE) {
                return domNode;
            } else {
                return null;
            }
        }
    }

    /**
     * Return true if the node is like one of the type below 
     * @param {Node} node, node where some text can be paste to create a specific structure
     */
    isANodeWhoCanReceiveText ( node ){
        if( !!node && (node.label === ConstantTag.ARTICLE 
            ||  node.label === ConstantTag.CONSIDS 
            ||  node.label === ConstantTag.ALINEAS 
            ||  node.label === ConstantTag.CONTENT 
            ||  node.label === ConstantTag.VISAS
            ||  node.label === ConstantTag.JUDGEMENT ) ){
            return true;
        } else {
            return false;
        }
    }

    /**
     * Return true if the node contains some keywords
     * @param {Node} node, the seleted node
     */
    isNodeContainingKeywords( node ){
        if(!!node && !!node.keywords && node.keywords.length > 0){
            return true;
        }else {
            return false;
        }
    }    

    /**
     * Set the real starting and ending offset for a selection in a element
     * @param {Number} stratingOffset, offset where the selection begin
     * @param {Number} endingOffset, offset where the selection end
     * @description:
     * This method is required because a selection is not always from top to bottom,
     * it may happens the user select a text from bottom to top. 
     */
    checkCaretPosition( stratingOffset, endingOffset ){
        if(endingOffset < stratingOffset){
            return { 
                anchorOffset: endingOffset,
                focusOffset: stratingOffset
            }
        } else {
            return { 
                anchorOffset: stratingOffset,
                focusOffset: endingOffset 
            }
        }
    }

    /**
     * Check the lowest offset in a selection
     * @param anchor, starting point of the selection
     * @param focus, ending point of the selection
     */
    checkStartCaretPosition( anchor, focus ){
        if( focus < anchor ) {
            return focus;
        } else {
            return anchor;
        }
    }

    /**
     * TODO: rename arguments or type the "@param" to explicit more what we are working with
     * Check the highest offset in a selection
     * @param anchor, starting point of the selection
     * @param focus, ending point of the selection
     */
    checkEndCaretPosition( anchor, focus ){
        if( focus < anchor ) {
            return anchor;
        } else {
            return focus;
        }
    }

    /**
     * Based on two node, will check their common parent, assuming they are at the same level
     * @param {Node} anchor, node concerned by the start of the selection
     * @param {Node} focus, node concerned by the end of the selection
     */
    checkCommonParentNode( anchor, focus ){
        while( anchor.parentNode !== focus.parentNode ){
            anchor = anchor.parentNode;
            focus  = focus.parentNode
        }
        return anchor.parentNode;
    }

    /**
     * Set the real starting and ending offset for a selection over several elements 
     * And the parent Node of those nodes
     * @param {Selection} windowSelection
     */
    checkCaretPositionOverElements( windowSelection ){
        let parentAnchorNode = windowSelection.anchorNode.parentNode;
        let parentFocusNode = windowSelection.focusNode.parentNode;
    
        while ( parentAnchorNode.className !== '' && !parentAnchorNode.className.includes( parentFocusNode.className )){
            parentFocusNode = parentFocusNode.parentNode;
            parentAnchorNode = parentAnchorNode.parentNode;
        }
        let parent = parentFocusNode.parentNode;

        let indexAnchor = Array.from(parent.childNodes).indexOf(windowSelection.anchorNode.parentNode);
        let indexFocus = Array.from(parent.childNodes).indexOf(windowSelection.focusNode.parentNode);

        let offset = this.checkCaretPosition( indexAnchor, indexFocus );
        
        return { 
            domNode: parent,
            anchorOffset: offset.anchorOffset,
            focusOffset: offset.focusOffset+1 
        }
    }

    /**
     * Looking on the selection, and 
     * - if the selected nodes are on the same parent (same node TEXT )
     * - if the selected nodes and their siblings between them does not contains a link
     * the selection is considered as viable to be formated / transform to a hyperlink / ... 
     * @param {*} selection 
     */
    isViabilitySelection( selection ){
        let checkedSelection = { isCreatable: false, isReversedSelection: false, commonParent: null };

        let anchorNode = selection.anchorNode;
        let focusNode = selection.focusNode;
        let childNodesList = selection.anchorNode.parentNode.childNodes;

        let indexAnchor = Array.from(childNodesList).indexOf(anchorNode);
        let indexFocus = Array.from(childNodesList).indexOf(focusNode);
        this.isNotCreatable = false;
        if (indexAnchor < 0 || indexFocus < 0){
            checkedSelection.isCreatable = false;
            return checkedSelection;
        } else {
            if( indexAnchor !== indexFocus){
                checkedSelection.commonParent = selection.anchorNode.parentNode;
                let temp = 0;
                // if the user select in reverse the index needs to be swap
                if(indexAnchor > indexFocus) { 
                    temp = indexAnchor;
                    indexAnchor = indexFocus;
                    indexFocus = temp;
                    checkedSelection.isReversedSelection = true;
                }
                this.$containLinkOnSelection(Array.from(childNodesList).slice(indexAnchor, indexFocus+1));
                if(this.isNotCreatable){
                    checkedSelection.isCreatable = false;
                    return checkedSelection;
                }else {
                    checkedSelection.isCreatable = true;
                    return checkedSelection;
                }
            }else {
                checkedSelection.isCreatable = true;
                return checkedSelection;
            }
        }
    }

    /**
     * if there is common Parent there is html in  the begining and the end of the selection
     * offset should be recalculated with the good start and the good end. keep in mind 
     * that the length of the html element between the selected part need to be added in the calcul.
     * @param {Selection} selection, the window selection obejct 
     * @param {Object} checkedSelection, metadata on the window selection give more information about the selection
     */
    calculatePosition( selection, checkedSelection ){
        let caretPosition = {};
        if( !!checkedSelection.commonParent ){
            let childNodesList = checkedSelection.commonParent.childNodes;
            if( checkedSelection.isReversedSelection ){
                caretPosition = this.$swapOffsetOverMultipleTag( selection.focusNode, selection.focusOffset, selection.anchorNode, selection.anchorOffset, childNodesList );
            } else {
                caretPosition = this.$swapOffsetOverMultipleTag( selection.anchorNode, selection.anchorOffset, selection.focusNode, selection.focusOffset, childNodesList );
            }
            caretPosition.isCrossSelection = true;
        } else {
            caretPosition.clickedNode = selection.anchorNode;
            caretPosition.clickedNodeOffset = selection.anchorOffset;
            caretPosition.clickedFocusNodeOffset = selection.focusOffset;
        }
        return caretPosition;
    }


    $swapOffsetOverMultipleTag( anchorNode, anchorOffset, focusNode, focusOffset, childNodesList){
        let indexs = this.calculateIndexOfChildren( anchorNode, focusNode, childNodesList );

        if( indexs.focusIndex < indexs.anchorIndex ){
            return {
                clickedNode: focusNode,
                clickedNodeOffset: focusOffset,
                clickedFocusNodeOffset: anchorOffset,
                focusNode: anchorNode
            }
        } else {
            return {
                clickedNode: anchorNode,
                clickedNodeOffset: anchorOffset,
                clickedFocusNodeOffset: focusOffset,
                focusNode: focusNode
            }
        }
    }

    /**
     * Recalculate the offset of the selection to give offset of the selection in the parent.
     * @param {*} anchorNode, node where the selection begins
     * @param {*} anchorOffset, offset where the selection begins in the anchorNode
     * @param {*} focusNode, node where the selection ends
     * @param {*} focusOffset, offset where the selection ends in the focusNode
     * @param {*} childNodesList, list of children for the common parent of the anchor and focus node
     */
    $calculateOffsetOverMultipleTag( anchorNode, anchorOffset, focusNode, focusOffset, childNodesList){
        let caretPosition = {};
        let indexs = this.calculateIndexOfChildren( anchorNode, focusNode, childNodesList );
        
        caretPosition.clickedNodeOffset = 0;
        caretPosition.clickedFocusNodeOffset = 0;

        if( indexs.anchorIndex > 0 ){
            let previousChild = Array.from(childNodesList);
            for(let i = 0; i < indexs.anchorIndex ; i++){
                caretPosition.clickedNodeOffset += previousChild[i].textContent.length;
                caretPosition.clickedFocusNodeOffset += previousChild[i].textContent.length;
            }
        }

        caretPosition.clickedNodeOffset += anchorOffset;
        caretPosition.clickedFocusNodeOffset += anchorNode.length;

        childNodesList = Array.from(childNodesList).slice(indexs.anchorIndex+1, indexs.focusIndex);
        

        childNodesList.forEach( childNode => {
            caretPosition.clickedFocusNodeOffset += childNode.textContent.length;
        });
    
        caretPosition.clickedFocusNodeOffset += focusOffset;
        caretPosition.clickedNode = anchorNode;
        caretPosition.focusNode = focusNode;
        return caretPosition;
    }

    /**
     * Calculate the position of the anchorNode and the focusNode in the children list of their common parent.
     * @param {*} anchorNode, node where the selection begins
     * @param {*} focusNode, node where the selection ends
     * @param {*} childNodesList, list of children for the common parent of the anchor and focus node
     */
    calculateIndexOfChildren( anchorNode, focusNode, childNodesList){
        let anchorIndex = Array.from(childNodesList).indexOf(anchorNode);
        let focusIndex = Array.from(childNodesList).indexOf(focusNode);

        return { anchorIndex: anchorIndex, focusIndex: focusIndex };
    }

    /**
     * look for each element and its children on the selection to see if there is a link
     * @param {HtmlCollection} childNodesList, childNodes of a node
     */
    $containLinkOnSelection(childNodesList){
        childNodesList.forEach( el => {
            if(el.tagName === ConstantTag.A){
                this.isNotCreatable = true;
            } else {
                this.$containLinkOnSelection(Array.from(el.childNodes));
            }
        });
    }
}

export default new CheckDocumentNodeSelectionService();