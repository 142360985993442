<template>
    <v-layout
        row
        wrap
    >
        <!-- TITLE OF THE PAGE-->
        <h2 class="custom_guideline">
            {{ $t('administration.guidelinesource.search.mainTitle') }}
        </h2>

        <!-- FORM WITH RESEARCH BUTTON -->
        <v-layout
            row
            wrap
            justify-center
            class="layout_guideline"
        >
            <v-flex
                xs5
                class="sizer_flex"
            >
                <v-text-field
                    v-model="filter.label"
                    class="t_name"
                    dark
                    :label="$t('administration.guidelinesource.label.name')"
                    clearable
                    @keyup.enter="filterGuidelines(filter,pageable)"
                />
            </v-flex>
            <v-flex
                xs1
                justify-end
            >
                <v-btn
                    class="t_search"
                    color="white"
                    small
                    fab
                >
                    <v-icon
                        color="primary"
                        dark
                        @keyup.enter="filterGuidelines(filter,pageable)"
                        @click="filterGuidelines(filter,pageable)"
                    >
                        search
                    </v-icon>
                </v-btn>
            </v-flex>
        </v-layout>

        <!-- PROGRESS BAR -->
        <v-layout
            v-if="displaySpinner"
            align-center
            justify-center
            row
        >
            <v-progress-circular
                :size="70"
                :indeterminate="true"
                color="primary"
            />
        </v-layout>

        <!-- DATA TABLES -->
        <v-flex v-if="!displaySpinner">
            <v-data-table
                :headers="headers"
                :items="guidelineList"
                hide-actions
                disable-initial-sort
                align-center
                justify-center
                class="elevation-1"
            >
                <template
                    slot="headers"
                    slot-scope="props"
                >
                    <th>{{ $t('administration.guidelinesource.label.code') }}</th>
                    <th>{{ $t('administration.guidelinesource.label.name') }}</th>
                    <th>{{ $t('administration.guidelinesource.label.market') }}</th>
                    <th>{{ $t('administration.guidelinesource.label.visible') }}</th>
                    <th>{{ $t('administration.guidelinesource.label.actions') }}</th>
                </template>

                <template slot="no-data">
                    <td
                        colspan="6"
                        class="t_no_data text-xs-center"
                    >
                        {{ $t('administration.guidelinesource.search.none') }}
                    </td>
                </template>

                <template
                    slot="items"
                    slot-scope="props"
                >
                    <td class="align-items">
                        {{ props.item.code }}
                    </td>
                    <td class="align-items">
                        {{ props.item.label }}
                    </td>
                    <td class="align-items">
                        <v-chip
                            v-for="m in props.item.markets"
                            :key="id"
                            :class="'t_entry_market_'+m.code"
                        >
                            {{ m.code }}
                        </v-chip>
                    </td>
                    <td class="align-items">
                        <v-icon
                            v-if="props.item.visible"
                            color="primary"
                            dark
                        >
                            visibility
                        </v-icon>
                        <v-icon
                            v-else
                            color="primary"
                        >
                            visibility_off
                        </v-icon>
                    </td>
                    <td class="align-items">
                        <router-link
                            class="t_edit"
                            tag="button"
                            :to="{name: 'GuidelineSourceEdit', params: { id: props.item.id }}"
                        >
                            <v-icon class="edit-icon">
                                edit
                            </v-icon>
                        </router-link>
                        <v-icon
                            slot="activator"
                            class="t_delete edit-icon"
                            @click="openConfirmationDialog(props.item.id)"
                        >
                            delete
                        </v-icon>
                    </td>
                </template>
            </v-data-table>

            <v-dialog
                v-model="dialog"
                class="t_confirm_delete_dialog"
                persistent
                max-width="350"
                icon
            >
                <v-card>
                    <v-card-title class="headline">
                        {{ $t('administration.guidelinesource.manage.delete.title') }}
                    </v-card-title>
                    <v-divider />
                    <v-card-text>{{ $t('administration.guidelinesource.manage.delete.message') }}</v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            color="primary"
                            small
                            flat
                            @click.native="dialog = false"
                        >
                            {{ $t('administration.guidelinesource.manage.delete.cancel') }}
                        </v-btn>
                        <v-btn
                            class="t_confirm_delete"
                            color="primary"
                            small
                            flat
                            @click.native="deleteGuideline()"
                        >
                            {{ $t('administration.guidelinesource.manage.delete.confirm') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-flex>

        <!-- PAGINATION -->
        <v-flex
            v-if="!!guidelineList && guidelineList.length > 0"
            xs12
            class="guideline-list"
        >
            <v-pagination
                :length="pageable.totalPages"
                :total-visible="5"
                :value="pageable.page"
                circle
                @input="changePage"
            />
        </v-flex>

        <!-- ADD NEW GUIDELINE -->
        <v-tooltip top>
            <v-btn
                slot="activator"
                color="primary"
                small
                fab
                dark
                icon
                class="t_add icon-add-custom"
                :to="{name: 'GuidelineSourceAdd'}"
            >
                <v-icon dark>
                    add
                </v-icon>
            </v-btn>
            <span>{{ $t('administration.guidelinesource.search.action.add') }}</span>
        </v-tooltip>
    </v-layout>
</template>

<script>

import axios from 'axios';
import EventBus from '../../../utils/event-bus.js';
import ConstantEvent from '../../../shared/constant/constantEvent.js'

export default {
    name: 'Search',
    data() {
        return {
            headers: [],
            guidelineList: [],
            displaySpinner: true,
            pageable: {page: 1, size: 10, direction: 'ASC', property: "code"},
            filter: {},
            dialog: false
        }
    },
    created() {
        console.log('COMPONENT CREATED!');
        setTimeout(()=>{
            this.filterGuidelines(this.filter, this.pageable);
        },1000) //wait while session authentication done. somehow
    },
    methods: {
        /**
         * Search guidelines sources by filter
         * @param filter
         * @param pageable
         */
        filterGuidelines(filter, pageable) {
            this.displaySpinner = true;
            const localFilter = JSON.stringify(filter);
            axios.post(
                'administration/guidelinesource/filter',
                filter,
                {params: pageable}
            ).then((result) => {
                if(result != null && JSON.stringify(this.filter) === localFilter){
                    this.guidelineList = result.data.content;
                    this.displaySpinner = false;
                    this.pageable.totalElements = result.data.totalElements;
                    this.pageable.totalPages = result.data.totalPages;
                    this.pageable.page = result.data.page;
                }
            });
        },
        /**
     * change page and navigate to the selected page
     * @param page
     */
        changePage(page) {
            this.pageable.page = page;
            this.filterGuidelines(this.filter, this.pageable);
        },
        openConfirmationDialog(guidelineId) {
            this.dialog = true;
            this.guidelineIdToDelete = guidelineId;
        },
        deleteGuideline() {
            this.dialog = false;
            axios.delete('administration/guidelinesource/' + this.guidelineIdToDelete).then(() => {
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    message: 'administration.guidelinesource.manage.delete.success',
                    type: 'SUCCEED'
                });
                if (this.guidelineList.length <= 1 && this.pageable.page > 1) {
                    this.pageable.page = this.pageable.page - 1;
                }
                this.filterGuidelines(this.filter, this.pageable);
            });
        }
    }
}
</script>

<style scoped>
.custom_guideline {
  background-color: #355B7F;
  color: white;
  padding-top: 25px;
  text-align: center;
  width: 100vw;
}

.layout_guideline {
  background-color: #355B7F;
  color: white;
  padding-top: 10px;
}

.sizer_flex {
  width: 100vw;
}

.align-items {
  text-align: center;
}

.icon-customer {
  margin-left: 15px;
}

.guideline-list {
  transform: translate(45%, 0);
  margin-top: 15px;
}

.icon-add-custom {
  position: fixed;
  right: 50px;
  bottom: 0;
}
</style>


