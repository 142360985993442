<template>
    <v-layout  row wrap >

        <v-layout v-if="displaySpinner" align-center justify-center row>
            <v-progress-circular
                :size="50"
                :indeterminate="true"
                color="primary"
                class="spinner_doc"></v-progress-circular>
        </v-layout>
        <v-container v-if="displayTab" ma-0 pa-0 fluid>
            <v-data-table
                :headers="[]"
                :items="documentList"
                disable-initial-sort
                hide-actions
                pagination.sync="pagination">
                <template slot="headers" slot-scope="props">
                    <th>{{ $t('document.label.reference') }}</th>
                    <th>{{ $t('document.label.title') }}</th>
                    <th>{{ $t('document.label.type') }}</th>
                    <th>{{ $t('document.label.documentDate') }}</th>
                    <th>{{ $t('document.label.actions') }}</th>
                </template>

                <template slot="items" slot-scope="props">
                    <td>{{props.item.reference}}</td>
                    <td>{{props.item.title}}</td>
                    <td>{{props.item.documentType}}</td>
                    <td>{{props.item.documentDate != null ? $d(new Date(props.item.documentDate)) : null}}</td>
                    <td class="justify-center layout px-0">
                        <v-icon
                            medium
                            @click="openDocumentToLink(props.item)">
                        pageview
                        </v-icon>
                    </td>
                </template>
            </v-data-table>
        </v-container>
        <v-flex v-if="documentList.length > 0" xs12  text-xs-center>
            <v-pagination
                    :length="pageable.totalPages"
                    :total-visible="8"
                    @input="changePage"
                    :value="pageable.page"
                    circle></v-pagination>
        </v-flex>
    </v-layout>
</template>
<script >
import EventBus             from '../../../utils/event-bus.js';
import ConstantEvent        from '../../../shared/constant/constantEvent.js';
import axios                from 'axios';
import { mapState }         from 'vuex';

const SELECT_DOCUMENT= 'onSelectDocument';

/**
 * 
 * Display a document list after a search.
 * 
 * @author Romain Gentilhomme, SFEIR Benelux
 * @version 1.0
 * @since 07/01/2020
 */
export default {
    name:'DocumentsToLink',
    data() {
        return {
            documentList: [],
            displayTab: false,
            displaySpinner: false,
            pageable: { page: 1, size: 10, direction: 'ASC', property: 'id' },
            searchFilter: {},
        }
    },
    computed: {
        ...mapState({
            filter: state => state.documentFilterStore.filter,
        })
    },
    created(){
        if( !!this.filter ){
            this.listener(this.filter);
        }else {
            this.listener(this.searchFilter);
        }
    },
    /**
     * Automatic search of document
     */
    created(){
        this.searchDocumentList(this.searchFilter, this.pageable);
    },
    /**
     * Listen the event document-list and send the search to the Backend
     */
    mounted(){
        EventBus.$on(ConstantEvent.DOCUMENT_LIST, this.listener);
    },
    /**
     * Destroy the event document-list before leaving the component
     */
    beforeDestroy(){
        EventBus.$off(ConstantEvent.DOCUMENT_LIST, this.listener);
    },
    methods:{
        /**
         * Receive the filters from the search component and request the back to get the corresponding documents
         * @param searchFilter
         */
        listener(searchFilter){
            this.searchFilter = searchFilter;
            this.searchDocumentList(this.searchFilter, this.pageable);
        },
        /**
         * Select the id of the document wanted
         * Open a modal to let the user choose a document
         * @param document
         */
       openDocumentToLink: function( document ){
                this.$emit(SELECT_DOCUMENT, { referenceDocument: document.reference, chosenTitleDocument: document.title } );
        },
        /**
         * search a document list with a search filter and a pageable object
         * @param searchFilter
         * @param pageable
         */
        searchDocumentList(searchFilter, pageable){
            this.displaySpinner = true;
            this.displayTab = false;
            axios.post(
                'document/filter',
                searchFilter,
                {params: pageable}
            ).then( result => {
                this.displaySpinner = false;
                this.displayTab = true;
                this.documentList = result.data.content;
                this.pageable.totalElements = result.data.totalElements;
                this.pageable.totalPages = result.data.totalPages;
                this.pageable.page = result.data.page;
            });
        },
        /**
         * change page and navigate to the selected page
         * @param page
         */
        changePage(page){
            this.pageable.page = page;
            this.searchDocumentList(this.searchFilter, this.pageable);
        }
    }
}
</script>
<style>
    .spinner_doc{
        position:absolute;
        top:calc(50vh - 65px);
        left:0;
        right:0;
        margin:auto;
    }
</style>

