<template>
    <v-dialog v-model="openDialog" fullscreen>
        <v-card>
            <v-card-title class="headline">
                {{$t('structure.footnotes.title') }}   
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-layout row wrap>
                     <v-flex xs7>
                        <v-textarea v-model="footnoteContent" :label="$t('structure.footnotes.typeFootnoteContent')"/>
                    </v-flex>
                    <v-flex xs1>
                        <v-btn color="primary" fab dark small @click="addFootnote()" :disabled="!footnoteContent">
                            <v-icon>add</v-icon>
                        </v-btn>
                    </v-flex>
                    <v-flex xs4>
                        <v-select
                            v-model="footnoteStyle"
                            :label="$t('structure.footnotes.indexType')"
                            :items="footnoteStyleList"
                            item-text="value"
                            item-value="key"
                            clearable>
                        </v-select>
                    </v-flex>
                </v-layout>
                <span class="headline">{{$t('structure.footnotes.toInsert')}}</span>
                <v-data-table
                    v-if="footnoteList"
                    :headers="headers"
                    :items="footnoteList"
                    disable-initial-sort
                    hide-actions
                >
                    <template slot="items" slot-scope="props">
                        <td><span v-if="props.item.rank != 0">{{props.item.rank}}</span></td>
                        <td v-html="props.item.content"></td>
                        <td>
                            <v-layout justify-space-around>
                                <v-tooltip top> 
                                    <v-btn slot="activator" v-if="!!position.caretPositionNode" small flat icon @click="link(props.item)">
                                        <v-icon>
                                            link
                                        </v-icon>
                                    </v-btn>
                                    <span>{{$t('structure.footnotes.action.link')}}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <v-btn slot="activator" v-if="!!position.caretPositionNode" small flat icon @click="unlink(props.item)">
                                        <v-icon>
                                            link_off
                                        </v-icon>
                                    </v-btn>
                                    <span>{{$t('structure.footnotes.action.unlink')}}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <v-btn slot="activator" small flat icon @click="destroy(props.item)">
                                        <v-icon>
                                        delete_forever
                                        </v-icon>
                                    </v-btn>
                                    <span>{{$t('structure.footnotes.action.delete')}}</span>
                                </v-tooltip>
                            </v-layout>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn color="error" flat @click.native="close();">{{$t('document.action.cancel')}}</v-btn>
                    <v-btn color="primary" flat @click.native="apply();">{{$t('structure.edit.action.apply')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import EventBus                 from '../../../../utils/event-bus.js';
import DocumentFootnoteService  from '../../../../service/document/documentFootnoteService.js';
import {mapState, mapActions}   from 'vuex';
import * as $                   from 'jquery';
import ConstantTag              from '../../../../shared/constant/constantTag.js';
import ConstantDocumentNode     from '../../../../shared/constant/constantDocumentNode.js';
import ConstantEvent            from '../../../../shared/constant/constantEvent.js';
import domManipulatorService    from '../../../../service/html/domManipulatorService';

const UPDATE_DIALOG             = 'update:dialog';
/**
 * 
 * Manage all the operation related to the footnote
 * 
 * @author Sébastien DE SANTIS
 * @since 24/10/2018
 * @version 1.0
 */

export default {
    name: 'DialogFootnote',
    props: {
        storeName: {
            default: null
        },
        dialog: {
            default: false
        },
        position: {
            caretPositionNode: null,
            caretPositionOffset: null
        }
    },
    data(){
        return {
            anchor: 0,
            footnoteList: [],
            footnoteContent: '',
            footnoteStyle: null,
            isEndOfNode: false,
            headers:[
                { text: this.$t('structure.footnotes.footnote.rank'), value: 'rank' },
                { text: this.$t('structure.footnotes.footnote.content'), value: 'content' },
                { text: this.$t('structure.footnotes.footnote.action'), value: 'action', sortable: false, width: '200px', align: 'right' },
            ],
            footnoteStyleList: [ {key: 1, value: '1, 2, 3, ...' },
                                 {key: 2, value: '1., 2., 3., ...' },
                                 {key: 3, value: '(1), (2), (3), ...' },
                                 {key: 4, value: '*, **, ***, ...' },
                                 {key: 5, value: '(*), (**), (***), ...'} ]
        }
    },
    computed:{
        flattedTree(){
            return this.$store.state[`${this.storeName}`].flattedTree;
        },
        selectedNode(){
            return this.$store.state[`${this.storeName}`].selectedNode;
        },
        openDialog: {
            get(){
                if( this.dialog ){
                    this.$nextTick(function(){
                        this.fillFootnoteList();
                    })
                }
                return this.dialog;
            },
            set( dialog ){
                if( !dialog ){
                    this.$emit(UPDATE_DIALOG, false);
                }
            }
        }
    },
    methods:{
        updateFootnoteList( footnotes ) {
            this.$store.dispatch(`${this.storeName}/updateFootnotes`, footnotes);
        },
        deleteFootnotes( footnotes ) {
            this.$store.dispatch(`${this.storeName}/deleteFootnotes`, footnotes);
        },
        unlinkFootnotes( footnotes ) {
            this.$store.dispatch(`${this.storeName}/unlinkFootnotes`, footnotes);
        },
        setSelectedNodeContent( nodeContent ) {
            this.$store.dispatch(`${this.storeName}/setSelectedNodeContent`, nodeContent);
        },
        /**
         * Close the dialog and remove the anchor inside the content node
         */
        close: function(){
            this.$emit(UPDATE_DIALOG, false);
        },
        /**
         * Set footnotelist and footnoteStyle
         */
        fillFootnoteList: function() {
            var footnodeListNode = this.flattedTree.find( element => {
                return element.label === ConstantTag.FOOTNOTES;
            })
            this.footnoteList = DocumentFootnoteService.extractFootnoteListFromNode(footnodeListNode);
           
            if(this.footnoteList.length > 0){
                let style = this.$extractFootNoteStyle();
                this.footnoteStyle = DocumentFootnoteService.setFootnoteStyle(style);
            }else {
                this.footnoteStyle = 1;
            }
        },
        
        $extractFootNoteStyle(){
            let style = '0'
            this.footnoteList.forEach( footnote => { 
                if( footnote.rank !== '0') return style = footnote.rank;
            });
            return style;
        },
        /**
         * Add a footnotes to the list
         */
        addFootnote: function(){
            var footnote = DocumentFootnoteService.createDocumentFootnote(this.footnoteContent);
            this.footnoteList.push(footnote);
            this.footnoteContent = '';
        },
        /**
         * Apply the modification and update the footnote list
         */
        apply: function(){
            this.updateFootnoteList( { footnoteList: this.footnoteList, footnoteStyle: this.footnoteStyle} );
            this.close();
        },
        /**
         * Link the modification and update the footnote list
         */
        link: function(footnote){
            var link = DocumentFootnoteService.createFootnoteLink(footnote).outerHTML;
            let htmlContent = domManipulatorService.insertHtmlInTextNode(this.position.caretPositionNode, this.position.caretPositionOffset, link, ConstantDocumentNode.TEXT_NODE_CLASS);
            this.setSelectedNodeContent(htmlContent);
            this.updateFootnoteList( { footnoteList: this.footnoteList, footnoteStyle: this.footnoteStyle} );
            this.close();
        },
        /**
         * Unlink the footnote of all is occurence in the document but keep it in the list of footnote
         */
        unlink: function(footnote){
            this.unlinkFootnotes( { footnoteList: this.footnoteList, footnoteId: footnote.id, footnoteStyle: this.footnoteStyle } );
            this.apply();
        },
        /**
         * Destroy the footnote and is occurence in the document
         */
        destroy: function(footnote){
            this.deleteFootnotes( { footnoteList: this.footnoteList, footnoteId: footnote.id, footnoteStyle: this.footnoteStyle } );
            if( !!this.position.caretPositionNode ){
                this.apply();
            }
        }
    }
}
</script>

