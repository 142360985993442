<template>
    <v-layout
        row
        wrap
        justify-start
        align-start
    >
        <!-- TITLE OF THE PAGE-->
        <v-flex class="bol_title_container">
            <h1 class="bol_title_header">
                {{ $t('document.type.jurisdiction') }}
            </h1>
            <v-layout
                column
                wrap
            >
                <v-flex>
                    <v-text-field
                        v-model="filter.code"
                        class="t_code"
                        dark
                        :label="$t('document.jurisdiction.code')"
                        clearable
                        @keyup.enter="filterDocumentJurisdictionWithPaginationReset()"
                    />
                </v-flex>
                <v-flex>
                    <v-text-field
                        v-model="filter.label"
                        dark
                        :label="$t('document.jurisdiction.label')"
                        clearable
                        @keyup.enter="filterDocumentJurisdictionWithPaginationReset()"
                    />
                </v-flex>
                <v-flex>
                    <v-text-field
                        v-model="filter.jurisprudenceType"
                        dark
                        :label="$t('document.jurisdiction.jurisprudenceType')"
                        clearable
                        @keyup.enter="filterDocumentJurisdictionWithPaginationReset()"
                    />
                </v-flex>
                <v-layout justify-end>
                    <v-flex xs1>
                        <v-btn
                            class="t_search"
                            color="white"
                            small
                            fab
                            dark
                        >
                            <v-icon
                                color="primary"
                                dark
                                @keyup.enter="filterDocumentJurisdictionWithPaginationReset()"
                                @click="filterDocumentJurisdictionWithPaginationReset()"
                            >
                                search
                            </v-icon>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-layout>
        </v-flex>
        <!-- PAGINATION -->

        <!-- PROGRESS BAR -->
        <v-layout
            v-if="displaySpinner"
            align-center
            justify-center
            row
        >
            <v-progress-circular
                :size="70"
                :indeterminate="true"
                color="primary"
            />
        </v-layout>
        <!-- DATA TABLES -->
        <v-flex v-if="!displaySpinner">
            <v-data-table
                :headers="[]"
                :items="documentJurisprudenceList"
                hide-actions
                disable-initial-sort
                class="elevation-1"
            >
                <template
                    slot="headers"
                    slot-scope="props"
                >
                    <th>{{ $t('document.label.code') }}</th>
                    <th>{{ $t('document.label.label') }}</th>
                    <th>{{ $t('document.label.jurisprudence') }}</th>
                    <th>{{ $t('document.label.description') }}</th>
                    <th>{{ $t('document.label.actions') }}</th>
                </template>
                <!-- TEMPLATE OF THE DATA TABLES -->
                <template slot="no-data">
                    <td
                        colspan="6"
                        class="t_no_data text-xs-center"
                    >
                        {{ $t('administration.jurisdiction.search.none') }}
                    </td>
                </template>
                <template
                    slot="items"
                    slot-scope="props"
                >
                    <td class="bol_table_row">
                        {{ props.item.code }}
                    </td>
                    <td class="bol_table_row">
                        {{ props.item.label }}
                    </td>
                    <td class="bol_table_row">
                        {{ props.item.jurisprudenceType }}
                    </td>
                    <td class="bol_table_row">
                        {{ props.item.description }}
                    </td>
                    <td class="align-items">
                        <v-chip
                            v-for="w in props.item.markets"
                            :class="'t_entry_market_'+w.code"
                        >
                            {{ w.code }}
                        </v-chip>
                    </td>
                    <td class="justify-center layout px-0">
                        <v-icon
                            class="t_edit edit_icon"
                            medium
                            @click="editItem(props.item)"
                        >
                            edit
                        </v-icon>
                        <v-icon
                            slot="activator"
                            class="t_delete edit_icon"
                            medium
                            @click="openModal(props.item)"
                        >
                            delete_forever
                        </v-icon>
                    </td>
                </template>
            </v-data-table>
            <v-dialog
                v-model="dialog"
                class="t_confirm_delete_dialog"
                persistent
                max-width="350"
                icon
            >
                <v-card>
                    <v-card-title class="headline">
                        {{ $t('document.jurisdiction.deletionTitle') }}
                    </v-card-title>
                    <v-divider />
                    <v-card-text>{{ $t('document.jurisdiction.deletionMessage') }}</v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            color="primary"
                            small
                            flat
                            @click.native="close"
                        >
                            {{
                                $t('document.jurisdiction.deleteCancel') }}
                        </v-btn>
                        <v-btn
                            class="t_confirm_delete"
                            color="primary"
                            small
                            flat
                            @click.native="deleteItem"
                        >
                            {{
                                $t('document.jurisdiction.deleteConfirm') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-layout class="bol_navigation_page">
                <v-flex v-if="documentJurisprudenceList.length > 0">
                    <v-pagination
                        :length="pageable.totalPages"
                        :total-visible="5"
                        :value="pageable.page"
                        class="display-pagination"
                        circle
                        @input="changePage"
                    />
                </v-flex>
            </v-layout>
        </v-flex>
        <span class="bol_add_text">{{ $t('document.label.newJurisdiction') }}</span>

        <v-tooltip class="bol_tooltip_add">
            <v-btn
                slot="activator"
                class="t_add"
                color="primary"
                small
                fab
                dark
                style="left: 105px;"
                @click="goToAdd()"
            >
                <v-icon dark>
                    add
                </v-icon>
            </v-btn>
        </v-tooltip>
    </v-layout>
</template>
<script>

import axios from 'axios';
import EventBus from '../../../utils/event-bus.js';
import ConstantEvent from '../../../shared/constant/constantEvent.js';
import {mapState} from 'vuex';

export default {
    name: 'DocumentJurisdiction',
    data() {
        return {
            documentJurisprudenceList: [],
            displaySpinner: false,
            pageable: {page: 1, size: 10, direction: 'ASC', property: "code"},
            filter: {},
            dialog: false,
            itemToDelete: null
        }
    },
    beforeMount(){
        /**
             * Charge a default list of jurisdiction
             */
        this.filterDocumentJurisdictionWithPaginationReset();
    },
    computed: {
        ...mapState({
            login: state => state.userStore.userPreferences.login,
        }),
    },
    watch: {
        login(newValue, oldValue) {
            this.filterDocumentJurisdiction();
        }
    },
    methods: {
        /**
             * Open the delete modal and save the item to delete
             * @param item
             */
        openModal(item) {
            this.itemToDelete = item.id;
            this.dialog = true;
        },
        /**
             * Close the dialog
             */
        close() {
            this.dialog = false;
            this.itemToDelete = null;
        },
        /**
             * Go to add new Jurisprudence page
             */
        goToAdd() {
            this.$router.push('/administration/add');
        },

        /**
             * Search Jurisdiction by filter, reset page index to 1
             */
        filterDocumentJurisdictionWithPaginationReset() {
            this.pageable.page = 1;
            this.filterDocumentJurisdiction();
        },
        /**
             * search a jurisdiction list
             */
        filterDocumentJurisdiction() {
            this.displaySpinner = true;
            axios.post('administration/jurisdiction/filter', this.filter, {params: this.pageable}).then(result => {
                if (result) {
                    this.documentJurisprudenceList = result.data.content;
                    this.pageable.totalElements = result.data.totalElements;
                    this.pageable.totalPages = result.data.totalPages;
                    this.pageable.page = result.data.page;
                    this.displaySpinner = false;
                }
            });
        },
        /**
             * change page and navigate to the selected page
             * @param page
             */
        changePage(page) {
            this.pageable.page = page;
            this.filterDocumentJurisdiction();
        },
        /**
             * delete a jurisdiction
             */
        deleteItem() {
            axios.delete('administration/jurisdiction/' + this.itemToDelete).then(() => {
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    message: 'document.jurisdiction.deleteSuccess',
                    type: 'SUCCEED'
                });
                if (this.documentJurisprudenceList.length <= 1 && this.pageable.page > 1) {
                    this.pageable.page = this.pageable.page - 1;
                }
                this.filterDocumentJurisdiction();
            });
            this.close();
        },
        /**
             * Go to the edit document page with the selected document
             * @param document
             */
        editItem(document) {
            this.$router.push('/administration/add/' + document.id);
        },
    }
}
</script>
<style scoped>
    .bol_title_container{
        background-color: #355B7F;
        padding:200px 30px 30px;
        height:100vh;
        max-width:450px !important;
    }
    .bol_table_row{
        text-align:center;
    }
    .bol_title_header{
        color:white;
    }
    .bol_navigation_page{
        transform: translate(40%,0);
    }
    .bol_add_text{
        position: fixed;
        bottom: 65px;
        left: 150px;
        color:white;
        font-weight: bold;
    }
    .bol_tooltip_add{
        position: fixed;
        bottom: 50px;
        left: 180px;
    }
    .edit_icon {
        margin: 12px;
    }

    .display_pagination {
        margin: 15px 30px 15px 15px;
    }
</style>


