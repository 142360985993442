<template>
        <v-layout align-start justify-start row fill-height class="background-layout">

            <div class="left-container">
                <v-text-field
                        v-model="paoToManage.title"
                        :label="$t('pao.label.title')"
                        :rules="fieldRules"
                        required
                        clearable
                        dark></v-text-field>
                <v-text-field
                        field
                        v-model="paoToManage.code"
                        :label="$t('pao.label.code')"
                        :rules="fieldRules"
                        required
                        clearable
                        dark></v-text-field>
                <v-text-field
                        v-model="paoToManage.description"
                        :label="$t('pao.label.description')"
                        dark
                        clearable ></v-text-field>
                <v-select
                        v-model="paoToManage.shortTitle"
                        :items="shortTitle"
                        :label="$t('pao.label.short-title')"
                        dark
                ></v-select>
                <v-text-field
                        v-model="paoToManage.edition"
                        :label="$t('pao.label.edition')"
                        dark
                        type="number"
                        clearable ></v-text-field>
                <v-text-field
                        v-model="paoToManage.version"
                        :label="$t('pao.label.version')"
                        dark
                        clearable ></v-text-field>

                <v-flex xs3 justify-end>
                    <!-- BUTTONS -->
                    <div @click="manage()" >
                       <bol-save-button v-if="!$route.params.id" :labelButton="$t('pao.manage.action.add')" class="save-btn"></bol-save-button>
                       <bol-save-button v-else :labelButton="$t('pao.manage.action.save')" class="save-btn"></bol-save-button>
                    </div>
                    <div  @click="$router.go(-1)">
                        <bol-back-button :textBackButton="$t('pao.summary.leave')" class="back-btn"> </bol-back-button>
                    </div>
                </v-flex>

            </div>
            <div class="right-container" >
                <v-layout align-center justify-center >
                <bol-date-picker
                        :value="paoToManage.publicationDate"
                        :label="$t('pao.label.publication-date')"
                        @onBindModel="updatePublicationDateFromDatePicker($event)"/>
                </v-layout>
                <v-flex v-if="!!paoToManage.structure" >
                        <pao-structure :structure="paoToManage.structure" class="pao-container" />
                </v-flex>

            </div>

        </v-layout>
</template>
<script>
import axios                            from 'axios';
import BolDatePicker                    from '../shared/DatePicker';
import Namespace                        from '../../shared/constant/store/module/namespace';
import PaoStructureStoreTypes           from '../../shared/constant/store/module/paoStructureTypes';
import PaoStructure                     from './structure/PaoStructure';
import { mapActions, mapState }         from 'vuex';
import BolBackButton                    from '../shared/BolBackButton';
import BolSaveButton                    from '../shared/BolSaveButton';
import {shortTitleList}                 from "../../shared/enum/shortTitleList";

/**
 * Pao management component.
 * 
 * @author Cédric de BOISVILLIERS, SFEIR benelux
 * @version 1.0
 * @since 09/07/2019
 */
export default {
    name: "PaoManagement",
    components: {
        PaoStructure,
        BolDatePicker,
        BolBackButton,
        BolSaveButton
    },
    data(){
        return {
            paoToManage: {},
            fieldRules: [
                value => !!value || this.$t("pao.manage.required")
            ],
            shortTitle: shortTitleList,

        }
    },
    created(){
        this.initManagement();
    },
    computed:{
        ...mapState({
            preferredLanguage: state => state.userStore.userPreferences.preferredLanguage
        })
    },
    methods:{
        ...mapActions(Namespace.PAO_STRUCTURE, {
            cleanNodes: PaoStructureStoreTypes.ACTION.CLEAN_STRUCTURE,
            initStructureStore: PaoStructureStoreTypes.ACTION.INIT,
            updateStructure: PaoStructureStoreTypes.ACTION.UPDATE_STRUCTURE
        }),
        /**
         * Update the date value from the date picker
         */
        updatePublicationDateFromDatePicker(date){
            this.paoToManage.publicationDate = date;
        },
        /**
         * Initialize the required data to manage a pao structure
         */
        initManagement(){
            this.paoToManage.id = parseInt(this.$route.params.id);
            this.loadPao(this.paoToManage.id).then( ({data}) => {
                this.paoToManage = data;
                this.initStructureStore(data.structure);
            });
        },
        /**
         * Load a pao with its metadata and its structure
         * @return a promise
         */
        loadPao(id){
            let url = `pao/${!id ? 'default/structure' : id+'/display' }`;
            return axios.get(url);
        },
        /**
         * Save a new or an existing pao(metadata and structure)
         */
        manage() {
            let method;
            this.cleanNodes();
            if(this.$route.params.id) {
                method = "PUT";
            } else {
                method = "POST";
            }
            axios({
                    method: method,
                    url: "pao",
                    data: this.paoToManage
            }).then( ({data}) => {
                this.paoToManage = data;
                this.updateStructure(this.paoToManage.structure);
                this.$router.replace({ path: `/pao/paoDisplay/${this.paoToManage.id}`});
            });
        }
    }
}
</script>
<style scoped>
    .background-layout{
        background-color: white;
    }
    .left-container{
        padding:100px 15px;
        min-width:350px;
        height:100%;
        margin-left:1px;
        background:#355B7F;
        color:#D39E4E;
        text-align:center;
    }
    .right-container{
        overflow-y: auto;
        height:calc(100vh - 48px);
        text-align: left;
        width:100%;
        padding:15px;
    }
    .pao-container{
        min-height:80vh;
    }
    .back-btn{
        width:300px;
        height:60px;
        cursor: pointer;
        position:absolute;
        left:80px;
        bottom:50px;
    }
    .save-btn{
        width:300px;
        height:60px;
        cursor: pointer;
        position:absolute;
        left:80px;
        bottom:130px;
    }

</style>