<template>
    <v-flex>
        <v-container>
            <v-text-field
                v-model="width"
                :label="$t('document.label.width')"
                type="number"
                clearable
                @blur="setPictureSize"
            />
            <v-text-field
                v-model="height"
                :label="$t('document.label.height')"
                type="number"
                clearable
                @blur="setPictureSize"
            />
        </v-container>
    </v-flex>
</template>

<script>
/**
 * 
 * Components use to get width and height (use for the picture in the first time)
 * @author Justin WILMET
 * @since 31/01/2019
 * @version 1.0
 * 
 */
export default {
    name: 'ResizePicture',
    data(){
        return{
            width: null,
            height: null
        }
    },
    methods: {
        setPictureSize(){
            let height, width;
            if(!!this.width && !this.height){
                height = 'auto';
                width = this.width;
            }else if(!!this.height && !this.width){
                height = this.height;
                width = 'auto';
            }else if(!this.width && !this.height){
                height = 'auto';
                width = 'auto';
            }else {
                height = this.height;
                width = this.width;
            }
            this.$emit('onChangeWidthHeight',{
                width: width,
                height: height
            });
        } 
    },
    beforeDestroy(){
        this.width = null;
        this.height = null;
    }
}
</script>