<template>
    <v-container class="container_list_documents">
        <v-layout
            row
            wrap
            align-start
            justify-space-between
        >
            <v-layout v-if="displaySpinner">
                <v-progress-circular
                    :size="50"
                    :indeterminate="true"
                    color="primary"
                    class="spinner_doc"
                />
            </v-layout>

            <v-container
                fluid
                grid-list-md
            >
                <v-layout
                    row
                    wrap
                >
                    <v-flex
                        v-for="card in documentList"
                        :key="card.id"
                    >
                        <router-link
                            :to="{name:'details' , params:{ reference: card.reference ,title: card.title}}"
                            tag="div"
                        >
                            <v-hover>
                                <v-card
                                    slot-scope="{ hover }"
                                    :class="`elevation-${hover ? 12 : 2}`"
                                    class="mx-auto"
                                    width="350px"
                                    height="210px"
                                    style="cursor: pointer;"
                                >
                                    <v-card-title class="font-weight-bold">
                                        <v-layout
                                            row
                                            wrap
                                        >
                                            <v-flex xs12>
                                                <v-tooltip
                                                    bottom
                                                    max-width="550"
                                                >
                                                    <template slot="activator">
                                                        <div
                                                            class="type_of_card"
                                                            :style="{backgroundColor:setBgColor(card.documentType)}"
                                                        />
                                                        <div class="over-flow-handler">
                                                            {{ card.title }}
                                                        </div>
                                                    </template>
                                                    <span class="title_to_ellips">{{ card.title }}</span>
                                                </v-tooltip>
                                            </v-flex>
                                        </v-layout>
                                    </v-card-title>
                                    <v-card-text class="text_of_card">
                                        {{ $t('document.label.reference') }} : <span class="font-weight-bold"> {{ card.reference }} </span>
                                    </v-card-text>
                                    <v-card-text class="text_of_card">
                                        {{ $t('document.label.type') }} : <span class="font-weight-bold">  {{ card.documentType }}</span>
                                    </v-card-text>
                                    <v-card-text class="text_of_card">
                                        {{ $t('document.label.status') }} : <span class="font-weight-bold">  {{ $t(`document.label.${card.status}`) }}</span>
                                    </v-card-text>
                                    <v-card-text class="text_of_card">
                                        {{ $t('document.label.step') }} : <span class="font-weight-bold">  {{ $t(`document.label.${card.step}`) }}</span>
                                    </v-card-text>
                                    <v-card-text class="text_of_card">
                                        {{ $t('document.label.documentDate') }} : <span class="font-weight-bold">  {{ card.documentDate != null ? $d(new Date(card.documentDate)) : null }}</span>
                                    </v-card-text>
                                    <v-card-text class="text_of_card">
                                        {{ $t('document.label.startDateOfApplicability') }} :  <span class="font-weight-bold">  {{ card.startApplicabilityDate != null ? $d(new Date(card.startApplicabilityDate)) : null }}</span>
                                    </v-card-text>
                                    <v-card-text class="text_of_card">
                                        {{ $t('document.label.disponibility') }} :  <span class="font-weight-bold">  {{ card.hasBeenPublishedOnce ? $t('document.dashboard.available'): $t('document.dashboard.unavailable') }}</span>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </router-link>
                    </v-flex>
                </v-layout>
                <v-card-text
                    v-if="!documentList || documentList.length === 0"
                    class="text-md-center"
                >
                    {{ $t('document.create.noMatchingResult') }}
                </v-card-text>
            </v-container>
            <v-flex
                v-if="documentList.length > 0"
                xs12
                text-xs-center
            >
                <v-pagination
                    :length="pageable.totalPages"
                    :total-visible="8"
                    :value="pageable.page"
                    circle
                    @input="changePage"
                />
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import EventBus             from '../../../utils/event-bus.js';
import ConstantEvent        from '../../../shared/constant/constantEvent.js';
import axios                from 'axios';
import { mapState }         from 'vuex';
/**
 * 
 * Display a document list after a search.
 * 
 * @author DE SANTIS Sébastien, SFEIR Benelux
 * @version 1.0
 * @since 14/09/2018
 */
export default {
    name:'DocumentList',
    data() {
        return {
            documentList: [],
            displaySpinner: false,
            pageable: { page: 1, size: 12,  direction: 'ASC', property: "id" },
            searchFilter: {}
        }
    },
    computed: {
        ...mapState({
            filter: state => state.documentFilterStore.filter,
            login: state => state.userStore.userPreferences.login
        })
    },
    watch: {
        login (newValue, oldValue) {
            this.searchDocumentList();
        }
    },
    created(){
        /**
         * Listen the event document_search and send the search to the Backend
         */
        EventBus.$on(ConstantEvent.FILTER_DOCUMENT, this.listener);
        this.searchDocumentList();
    },
    beforeDestroy(){
        EventBus.$off(ConstantEvent.FILTER_DOCUMENT, this.listener);
    },
    methods:{
        /**
         * Set the default research (first page)
         * @param searchFilter, the different filter from DocumentFilter
         */
        listener(searchFilter){
            this.searchFilter = searchFilter;
            this.pageable.page = 1;
            this.pageable.size = 12;
            this.pageable.direction = 'DESC';
            this.pageable.property = 'dataSheet.startDateOfApplicability';
            this.searchDocumentList();
        },
        /**
         * search a document list with a search filter and a pageable object
         * @param searchFilter
         * @param pageable
         */
        searchDocumentList(){
            this.displaySpinner = true;
            axios.post(
                'document/filter',
                this.searchFilter,
                {params: this.pageable}
            ).then( ({data}) => {
                this.displaySpinner = false;
                this.documentList = data.content;
                this.pageable.totalElements = data.totalElements;
                this.pageable.totalPages = data.totalPages;
                this.pageable.page = data.page;
            });
        },
        /**
         * Set color for the different type of document
         * @param type, type of document
         */
        setBgColor(type){
            switch(type){
                case "Legislation" : return "darkblue";
                case "Jurisprudence" : return "darkred";
                case "Doctrine" : return "yellow";
                case "Dossier Parlementaire" : return "lightgrey";
                case "Circulaire Administrative" : return "darkgrey";
                case "Livre Vert" : return "green";
                case "Bibliographie" : return "purple";
                default: return "green";
            }
        },
        /**
         * change page and navigate to the selected page
         * @param page
         */
        changePage(page){
            this.pageable.page = page;
            this.searchDocumentList();
        }
    }
}
</script>

<style scoped>
.container_list_documents
{

    width:100%;
}
.title_to_ellips
{
    font-size: 1.2em;
    text-overflow:ellipsis;
    overflow:hidden;
    height:50px;
    margin-top:-5px;
    margin-left:-5px;
}
.type_of_card
{
    display:inline-block;
    float:left;
    margin: 0 10px 0 0;
    width: 20px;
    height:20px;
    border: lightgrey 1px solid;
}
.text_of_card{
    font-size: 0.9em;
    padding-top: 0;
    padding-bottom: 0;
}
.spinner_doc{
    position:absolute;
    top:calc(50vh - 65px);
    left:30px;
    right:0;
    margin:auto;
}

.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
}

@-webkit-keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }
}

@keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }
}

.mx-auto:hover{
    animation: pulse 1s;
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
}
.over-flow-handler{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
}




</style>
