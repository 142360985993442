const A                     = 'A';
const ACT                   = 'ACT';
const ALINEA                = 'ALINEA';
const ALINEAS               = 'ALINEAS';
const ARTICLE               = 'ARTICLE';
const ANNEXE                = 'ANNEXE';
const AVENANT               = 'AVENANT';
const BLOCKQUOTE            = 'BLOCKQUOTE';
const CITATION              = 'CITATION';
const CONTENT               = 'CONTENT';
const CONSID                = 'CONSID';
const CONSIDS               = 'CONSIDS';
const CONVENTION            = 'CONVENTION';
const DOC_DATE              = 'DOC-DATE';
const DOC_ID                = 'DOC-ID';
const DIV                   = 'DIV';
const FOOTNOTES             = 'FOOTNOTES';
const GR_ARTICLES           = 'GR-ARTICLES'
const IMG                   = 'img';
const JUDGEMENT             = 'JUDGEMENT';
const LI                    = 'LI';
const NUM_ARTICLE           = 'NUM-ARTICLE';
const NO_PARAG              = 'NO-PARAG'
const OL                    = 'OL';
const P                     = 'P';
const PARAG                 = 'PARAG';
const PROTOCOL              = 'PROTOCOL';
const PROTOCOL_CONVENTION   = 'PROTOCOL-CONVENTION';
const QT                    = 'QT';
const RESUME                = 'RESUME';
const SPAN                  = 'SPAN';
const TABLE                 = 'table';
const TBODY                 = 'tbody';
const TD                    = 'td';
const TEXT                  = 'TEXT';
const TFOOT                 = 'TFOOT';
const TR                    = 'tr';
const TR_UPPER_CASE         = 'TR';
const UL                    = 'UL';
const VISA                  = 'VISA';
const VISAS                 = 'VISAS';
export default class ConstantTag{

    static get A(){
        return A;
    }
    static get ACT(){
        return ACT;
    }
    static get ALINEA(){
        return ALINEA;
    }
    static get ALINEAS(){
        return ALINEAS;
    }
    static get ARTICLE(){
        return ARTICLE;
    }
    static get ANNEXE(){
        return ANNEXE;
    }
    static get AVENANT(){
        return AVENANT;
    }
    static get BLOCKQUOTE(){
        return BLOCKQUOTE;
    }
    static get CITATION(){
        return CITATION;
    }
    static get CONTENT(){
        return CONTENT;
    }
    static get CONSID(){
        return CONSID;
    }
    static get CONSIDS(){
        return CONSIDS;
    }
    static get CONVENTION(){
        return CONVENTION;
    }
    static get DOC_DATE(){
        return DOC_DATE;
    }
    static get DOC_ID(){
        return DOC_ID;
    }
    static get DIV(){
        return DIV;
    }
    static get FOOTNOTES(){
        return FOOTNOTES;
    }
    static get IMG(){
        return IMG;
    }
    static get JUDGEMENT(){
        return JUDGEMENT;
    }
    static get LI(){
        return LI;
    }
    static get NUM_ARTICLE(){
        return NUM_ARTICLE;
    }
    static get NO_PARAG(){
        return NO_PARAG;
    }
    static get OL(){
        return OL;
    }
    static get P(){
        return P;
    }
    static get PARAG(){
        return PARAG;
    }
    static get PROTOCOL(){
        return PROTOCOL;
    }
    static get PROTOCOL_CONVENTION(){
        return PROTOCOL_CONVENTION;
    }
    static get QT(){
        return QT;
    }
    static get RESUME(){
        return RESUME;
    }
    static get SPAN(){
        return SPAN;
    }
    static get TABLE(){
        return TABLE;
    }
    static get TBODY(){
        return TBODY;
    }
    static get TD(){
        return TD;
    }
    static get TEXT(){
        return TEXT;
    }
    static get TFOOT() {
        return TFOOT;
    }
    static get TR(){
        return TR;
    }
    static get TR_UPPER_CASE(){
        return TR_UPPER_CASE;
    }
    static get UL(){
        return UL;
    }
    static get VISA(){
        return VISA;
    }
    static get VISAS(){
        return VISAS;
    }
    static get GR_ARTICLES(){
        return GR_ARTICLES;
    }
}
