import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
/**
 * Load all the internationalization module.
 *
 * @author DE SANTIS Sébastien, SFEIR Benelux
 * @since 14/09/2018
 * @version 1.0
 *
 */

/**
 * Date time constants
 */
const dateTimeFormats = {
    'en': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        medium: {
            year: 'numeric',month: 'numeric', day: 'numeric',
            hour: 'numeric', minute: 'numeric', hour12: true
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'fr': {
        short: {
            day: 'numeric', month: 'numeric', year: 'numeric'
        },
        medium: {
            day: 'numeric', month: 'numeric', year: 'numeric',
            hour: 'numeric', minute: 'numeric', hour12: false
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
        }
    }
}

/**
 * Load all the locale messages files
 */
function loadLocaleMessages() {
    // load all the json files
    const locales = require.context('../locales', true, /\/[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([a-z0-9]+)\./i)
        if (matched && matched.length > 1) {
            // Extract the language name
            const locale = matched.input.substring(2, 4);
            if (!messages[locale]) {
                // create a locale if it not exists
                messages[locale] = locales(key);
            } else {
                // add to an existing locale
                messages[locale] = Object.assign(locales(key), messages[locale]);
            }
        }
    })
    return messages
}

/**
 * Create a vue I18N component
 */
export default new VueI18n({
    dateTimeFormats,
    locale: process.env.VUE_APP_I18N_LOCALE || 'fr',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages()
})

