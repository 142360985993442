import FootnoteService              from './footnoteService.js';
import DomManipulatorService        from '../html/domManipulatorService.js';
import ConstantNodeContentElement   from '../../shared/constant/constantNodeContentElement.js';

/**
 * Manage all the operation related to the table footnotes
 * 
 * @author Gentilhomme Romain
 * @since 06/01/2019
 * @version 1.0
 */
class DocumentTableFootnoteService{

    constructor(){
        this.FOOTNOTE_ID_REGEX = new RegExp(/(?<=href="#).{36}(?=")/m);
    }

    /**
     * Extract a table footnote list from the tableFootnote content
     * @param {HTMLTableElement} node 
     */
    extractTableFootnoteListFromHTMLElement(htmlTableElement){
        let tableFootnoteList = [];
        if(!!htmlTableElement.tFoot) {
            Array.from(htmlTableElement.tFoot.rows).forEach(row => {
                let footnote = {
                    id: row.cells[0].lastChild.id,
                    rank: row.cells[0].firstChild.textContent.trim(),
                    content: row.cells[0].lastChild.textContent
                }
                tableFootnoteList.push(footnote);
            });
        }
        return tableFootnoteList;
    }

    /**
     * Set tableFootnote style depending on the rank of the first element of the tableFootnoteList
     * @param {*} rank 
     */
    setTableFootnoteStyle( rank ){
        const REGEX_CASUAL_NUMBER           = new RegExp(/^[0-9]{1,2}$/g);
        const REGEX_BRACKET_NUMBER          = new RegExp(/^\([0-9]{1,3}\)$/g);
        const REGEX_DOTTED_NUMBER           = new RegExp(/^[0-9]{1,3}\.$/g);
        const REGEX_CASUAL_STAR             = new RegExp(/^\*{1,}$/g);
        const REGEX_BRACKET_STAR            = new RegExp(/^\(\*{1,}\)$/g);
        if(REGEX_CASUAL_NUMBER.test(rank))  {return 1;}
        if(REGEX_DOTTED_NUMBER.test(rank))  {return 2;}
        if(REGEX_BRACKET_NUMBER.test(rank)) {return 3;}
        if(REGEX_CASUAL_STAR.test(rank))    {return 4;}
        if(REGEX_BRACKET_STAR.test(rank))   {return 5;}
    }

    /**
     * Convert a tableFootnote list into a table footer
     * @param {Array<*>} tableFootnoteList
     * @param {String} tableFootnoteStyle
     * @param {HTMLTable} tableFootnoteStyle     * @return {HTMLTableSectionElement} tableFooter
     */
    $insertTableFootnoteListInFooter(tableFootnoteList, tableFootnoteStyle, tableFooter){
        let colSpan = Math.max(...(DomManipulatorService.getArrayFromHTMLTableElement(tableFooter.parentElement).map( row =>
            row.cells.reduce( (totalCell, cell) =>  totalCell + (cell.colSpan || 1)
                , 0)
        )));
        tableFootnoteList.sort((a, b) => parseFloat(a.rank) - parseFloat(b.rank))
            .forEach( (tableFootnote, index) => {
                let row = tableFooter.insertRow(index);
                let cell = row.insertCell(0);
                cell.innerHTML = this.$createHTMLFootnote(tableFootnote, tableFootnoteStyle);
                cell.className = `${ConstantNodeContentElement.CUSTOM_TABLE_FOOTNOTE_CLASS} ${ConstantNodeContentElement.CUSTOM_TABLE_FOOTNOTE_TFOOT_CLASS}`;
                cell.colSpan = colSpan;
            });
    }

    /**
     * Return the html content of a footnote
     * @param {*} rank 
     * @param {*} tableFootnoteStyle 
     */
    $createHTMLFootnote(tableFootnote, style) {
        let styledRank = FootnoteService.createStyleOnRank(tableFootnote.rank, style);
        let rankSpan =  document.createElement('span');
        rankSpan.className = ConstantNodeContentElement.CUSTOM_TABLE_FOOTNOTE_RANK_CLASS;
        rankSpan.innerHTML = `${styledRank} `;
        
        let descriptionSpan =  document.createElement('span');
        descriptionSpan.id = tableFootnote.id;
        descriptionSpan.className = 'bol-custom-table-footnote-description';
        descriptionSpan.textContent = tableFootnote.content;

        return rankSpan.outerHTML + descriptionSpan.outerHTML;
    }

    /**
     * Create a table Footnote
     * @param {String} content 
     */
    createTableFootnote(content){
        return FootnoteService.createFootnote(content);
    }
    /**
     * Create a tableFootnote link
     * @param {*} tableFootnote
     * @return {HTMLAnchorElement} 
     */
    createTableFootnoteLink(tableFootnote){
        let supElement = document.createElement('sup');
        supElement.className = `${ConstantNodeContentElement.CUSTOM_TABLE_FOOTNOTE_CLASS} mceNonEditable`;
        supElement.setAttribute('data-placement', 'top');

        let anchorElement = document.createElement('a');
        anchorElement.className = `${ConstantNodeContentElement.CUSTOM_TABLE_FOOTNOTE_INDEX_CLASS} mceNonEditable`;
        anchorElement.id = FootnoteService.generateUid();
        anchorElement.href = `#${tableFootnote.id}`;
        anchorElement.text = '*';

        supElement.appendChild(anchorElement);
        return supElement;
    }
    /**
     * Update the table with the table footnote list
     * @param {Array<*>} tableFootnoteList
     * @param {*} tableFootnoteStyle
     * @param {HTMLTableElement} htmlTableElement
     */
    updateTableFootnoteList(tableFootnoteList, tableFootnoteStyle, htmlTableElement){
        this.$orderTableFootnoteList(tableFootnoteList, tableFootnoteStyle, htmlTableElement);
        this.$updateFooterInTable(htmlTableElement, tableFootnoteList, tableFootnoteStyle);
    }
    /**
     * Unlink the target table footnote and keep his value in the table footnote list
     */
    unlinkTableFootnote(tableFootnoteList, tableFootnoteId, tableFootnoteStyle, htmlTableElement) {
        let updatedTableFootnoteList = this.manageTableFootnoteInTableFootnoteList(tableFootnoteList, tableFootnoteId, htmlTableElement);
        this.$orderTableFootnoteList(updatedTableFootnoteList, tableFootnoteStyle, htmlTableElement);
        this.$updateFooterInTable(htmlTableElement, updatedTableFootnoteList, tableFootnoteStyle);
    }
    /**
     * Delete the footnote in the footnotes node
     */
    deleteTableFootnote(tableFootnoteList, tableFootNoteId, tableFootnoteStyle, htmlTableElement) { 
        tableFootnoteList = this.deleteTableFootnoteInTableFootnoteList(tableFootnoteList, tableFootNoteId, htmlTableElement);
        this.$updateFooterInTable(htmlTableElement, tableFootnoteList, tableFootnoteStyle);
    }

    /**
     * Update the footer of a table with the footnote list
     * @param {*} tableElement 
     * @param {*} tableFootnoteList 
     */
    $updateFooterInTable(tableElement, tableFootnoteList, tableFootnoteStyle) {
        if(!!tableElement.tFoot){
            tableElement.deleteTFoot();
        }
        let tableFooter = tableElement.createTFoot();
        tableFooter.className = `${ConstantNodeContentElement.CUSTOM_TABLE_FOOTNOTE_CLASS}`;

        if(tableFootnoteList.length > 0) {
            this.$insertTableFootnoteListInFooter(tableFootnoteList, tableFootnoteStyle, tableFooter);
        }
    }
    /**
     * Reorder the Table footnote, change the anchor index inside the table.
     * @param {*} tableFootnoteList
     * @param {*} htmlTableElement 
     */
    $orderTableFootnoteList(tableFootnoteList, tableFootnoteStyle, htmlTableElement) {
        let rank = 1;
        let calculatedTableFootnoteList = [];
        let tableFootnoteAnchorList = htmlTableElement.outerHTML.match(RegExp(`<a class=\"${ConstantNodeContentElement.CUSTOM_TABLE_FOOTNOTE_INDEX_CLASS}.*?<\/a>`, "gm"));
        
        if (!!tableFootnoteAnchorList) {
            tableFootnoteAnchorList.forEach( tableFootnoteAnchor => {
                let tableFootnoteId = tableFootnoteAnchor.match(this.FOOTNOTE_ID_REGEX)[0];
                tableFootnoteList.forEach( tableFootnote => {
                    if(tableFootnote.id === tableFootnoteId){
                        if(!calculatedTableFootnoteList.some( calculatedTableFootnote => calculatedTableFootnote.id === tableFootnoteId)){
                            tableFootnote.rank = rank++;
                            calculatedTableFootnoteList.push(tableFootnote);
                        }
                        htmlTableElement.innerHTML = htmlTableElement.innerHTML.replace(tableFootnoteAnchor, tableFootnoteAnchor.replace(/>.*?</, `>${FootnoteService.createStyleOnRank(tableFootnote.rank, tableFootnoteStyle)}<`));
                    }
                });
            });
        }
    }
    /**
     * Delete the tableFootnote in the tableFootnoteList
     * @param {*} tableFootnoteList
     */
    deleteTableFootnoteInTableFootnoteList(tableFootnoteList, tableFootnoteId, htmlTableElement){
        let tableFootnoteIndex = tableFootnoteList.findIndex(tableFootote => tableFootote.id === tableFootnoteId);

        let tableFootnoteAnchorList = htmlTableElement.outerHTML.match(RegExp(`<sup class=\"${ConstantNodeContentElement.CUSTOM_TABLE_FOOTNOTE_CLASS}.*?<\/sup>`, "gm"));
        if( !!tableFootnoteAnchorList ){
            tableFootnoteAnchorList.forEach( tableFootnoteAnchor => {
                let tableFootnoteAnchorId = tableFootnoteAnchor.match(this.FOOTNOTE_ID_REGEX)[0];
                if(tableFootnoteAnchorId === tableFootnoteId){
                    htmlTableElement.innerHTML = htmlTableElement.innerHTML.replace(tableFootnoteAnchor, '');
                }
            });
        }

        return tableFootnoteList.splice(tableFootnoteIndex, 1);
    }

    /**
     * Reorder the tableFootnote and put the unlink tableFootnote at the end of the list
     * @param {*} tableFootnoteList
     * @param {*} tableFootnoteId
     */
    manageTableFootnoteInTableFootnoteList(tableFootnoteList, tableFootnoteId, htmlTableElement){
        let tableFootnote = tableFootnoteList.find(footnote => footnote.id === tableFootnoteId);
        let footnoteIndex = tableFootnoteList.indexOf(tableFootnote);
        tableFootnoteList.splice(footnoteIndex, 1);
        tableFootnote.rank = 0;
        tableFootnoteList.push(tableFootnote);

        let tableFootnoteAnchorList = htmlTableElement.outerHTML.match(RegExp(`class=\"${ConstantNodeContentElement.CUSTOM_TABLE_FOOTNOTE_CLASS}.*?<\/sup>`, "gm"));
        if( !!tableFootnoteAnchorList ){
            tableFootnoteAnchorList.forEach( tableFootnoteAnchor => {
                let tableFootnoteAnchorId = tableFootnoteAnchor.match(this.FOOTNOTE_ID_REGEX)[0];
                if(tableFootnoteAnchorId === tableFootnoteId){
                    htmlTableElement.innerHTML = htmlTableElement.innerHTML.replace(tableFootnoteAnchor, '');
                }
            });
        }

        return tableFootnoteList;
    }
    
}

export default new DocumentTableFootnoteService();