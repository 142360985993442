import DomManipulatorService                from '../html/domManipulatorService';
import CheckDocumentNodeSelectionService    from '../document/checkDocumentNodeSelectionService';
import DocumentTreeService                  from '../document/documentTreeService';
import ConstantAction                       from '../../shared/constant/constantAction';
import ConstantDocumentNode                 from '../../shared/constant/constantDocumentNode.js';
import ConstantTag                          from "../../shared/constant/constantTag";

class NodeContentTreeService {
    constructor(){}
    
    
    /**
     * Copy selected node
     */
    copyNode( selectedNode ){
        return { action: ConstantAction.COPY_NODE, node: selectedNode };
    }

    /**
     * Copy selected text
     * @param {String} selectedText
     */
    copyText(selectedText){
        let content = '';
        let childNodes = [...selectedText.cloneContents().childNodes];
        childNodes.forEach( child => {
            if ( child.tagName !== ConstantTag.BLOCKQUOTE && child.nodeType === node.TEXT_NODE) {
                content += child.outerHTML;
            }
            else if ( child.tagName === ConstantTag.BLOCKQUOTE ) {
                content += child.outerHTML;
            }
            else {
                content += child.textContent;
            }
        });
        return content;
    }
    /**
     * Paste the content of the clipboard as structural nodes
     * @param {String} structureType, type of node who will be created
     */
    async pasteTextOnEmptyNode( structureType, clipboardContent, documentType ){
        let nodeStructure = await DocumentTreeService.createNodeStructure(
            {
                clipboardContent: clipboardContent,
                documentTypeId: documentType.id,
                structureType: structureType
            }
        );
        return nodeStructure;
    }
    /**
     * If a part of textnode is selected, CTRL+V/CMD+V should replace the selectedText
     * If an entire textnode is selected, CTRL+V/CMD+V should paste selectedText
     */
    pasteText(textFromClipboard, globalAttributes ) {
        if ( window.getSelection().toString().length > 1) {
            globalAttributes.caretPosition.clickedNode = globalAttributes.windowSelection.anchorNode;
            globalAttributes.caretPosition.clickedNodeOffset = globalAttributes.windowSelection.anchorOffset;
            globalAttributes.caretPosition.clickedFocusNodeOffset = globalAttributes.windowSelection.focusOffset;
            return this.replaceText(textFromClipboard, globalAttributes);
        } else {
            return DocumentTreeService.pasteTextAsMultiplePElement( textFromClipboard, globalAttributes.windowSelection.anchorNode, globalAttributes.windowSelection.focusOffset );
        }
    }
    /**
     * If we want to paste the content of the clipboard as text there is two possibility
     * Paste it in a table element, in that case the clicked element is the lowest one possible in a selection 
     * like a text type in HTML.
     * Or Paste it in a node with other html element in that case it's the parent element who is the based.
     * If we click in the text of a P the based element is not the text of the P but the P element itself; 
     * @param {String} textFromClipboard, content of the browser clipboard
     */
    pasteTextInNode( textFromClipboard, globalAttributes ){
        let offset;
        if ( globalAttributes.tableClicked ) {
            offset = CheckDocumentNodeSelectionService.checkEndCaretPosition( globalAttributes.caretPosition.clickedNodeOffset, globalAttributes.caretPosition.clickedFocusNodeOffset );
            return DocumentTreeService.pasteTextAsMultiplePElement( textFromClipboard, globalAttributes.caretPosition.clickedNode, offset );
        } else {
            offset = CheckDocumentNodeSelectionService.checkEndCaretPosition( globalAttributes.caretPosition.checkedNodeOffset, globalAttributes.caretPosition.clickedFocusNodeOffset );
            return DocumentTreeService.pasteTextAsMultiplePElement( textFromClipboard, globalAttributes.caretPosition.checkedNode, offset );
        }
    }
    replaceText(textFromClipboard, globalAttributes){
        if (globalAttributes.windowSelection.anchorNode === globalAttributes.windowSelection.focusNode){
            return this.replaceTextByTextInTheSameElement( textFromClipboard , globalAttributes.caretPosition);
        } else {
            return this.$replaceTextByTextOverSeveralElement( textFromClipboard, globalAttributes.windowSelection );
        }
    }
    /**
     * Replace the selected text by the copiedText when the selection is only on one text
     * @param {String} copiedText, text from clipboard
     */
    replaceTextByTextInTheSameElement( copiedText, caretPosition ){
        let offset = CheckDocumentNodeSelectionService.checkCaretPosition(caretPosition.clickedNodeOffset, caretPosition.clickedFocusNodeOffset);
        return DomManipulatorService.replaceTextByText(
            caretPosition.clickedNode, 
            offset.anchorOffset,
            offset.focusOffset,
            copiedText, 
            ConstantDocumentNode.TEXT_NODE_CLASS);
    }
    /**
     * Replace a selected text node by a HTML element
     */
    replaceTextByHTMLElement( customList, caretPosition  ){
        let offset = CheckDocumentNodeSelectionService.checkCaretPosition( caretPosition.clickedNodeOffset, caretPosition.clickedFocusNodeOffset );
        return DomManipulatorService.replaceTextByHTMLElementInNode(
            caretPosition.clickedNode,
            offset.anchorOffset,
            offset.focusOffset,
            customList,
            ConstantDocumentNode.TEXT_NODE_CLASS );
    }

    /**
     * Replace multiple selected nodes by a HTML element
     */
    replaceMultipleTextByHTMLElement( customList, windowSelection  ){
        let offset = CheckDocumentNodeSelectionService.checkCaretPositionOverElements( windowSelection );
        return DomManipulatorService.replaceHTMLByHTMLElementInNode(
            offset.domNode,
            offset.anchorOffset,
            offset.focusOffset,
            customList.outerHTML,
            ConstantDocumentNode.TEXT_NODE_CLASS );
    }
    /**
     * Replace the selected text by the clipboard when the selection is on several node
     * if the selection is at the half of the starting and ending element the text before and after the selection
     * will be added around the clipboard content to create an new element
     * @param {String} textFromClipboard, clipbaord content
     */
    $replaceTextByTextOverSeveralElement( textFromClipboard , windowSelection){
        let parent = CheckDocumentNodeSelectionService.checkCommonParentNode( windowSelection.anchorNode, windowSelection.focusNode );
        let startingElementIndex = Array.from(parent.childNodes).indexOf( windowSelection.anchorNode.parentNode);
        let endingElementIndex = Array.from(parent.childNodes).indexOf(windowSelection.focusNode.parentNode);
        
        return DomManipulatorService.replaceMultipleTextByText(
            parent,
            windowSelection,
            startingElementIndex,
            endingElementIndex+1,
            textFromClipboard, 
            ConstantDocumentNode.TEXT_NODE_CLASS
        );
    }
}

export default new NodeContentTreeService();