/**
 * Manage common behaviors for components managing a table.
 * @author Cédric de BOISVILLIERS
 * @since 01/04/2019
 * @version 1.0
 */
export const tableMixin = {
    data: () => ({ 
        tableRows: []
    }),
    computed: {
        /**
         * Return the theoric cell number in a row of the tableRows
         */
        rowsMaxLength() {
            return Math.max(...(this.tableRows.map( row =>
                row.cells.reduce( (totalCell, cell) =>  totalCell + (cell.colSpan || 1), 0)
            )));
        }
    }
}