const MCE_NON_EDITABLE      = 'mceNonEditable';
const QT_INLINE_CLASS       = 'bol_qt_inline';
const QT_STRUCTURAL_CLASS   = 'bol_qt_structural';

export default class ConstantQuotation{
    static get MCE_NON_EDITABLE(){
        return MCE_NON_EDITABLE;
    }
    static get QT_INLINE_CLASS(){
        return QT_INLINE_CLASS;
    }
    static get QT_STRUCTURAL_CLASS(){
        return QT_STRUCTURAL_CLASS;
    }
}