const NUMBER_STYLE_CLASS                = 'bol-number-style';
const DASH_STYLE_CLASS                  = 'bol-dash-style';
const FILLED_DOT_STYLE_CLASS            = 'bol-filled-dot-style';
const FILLED_SQUARE_STYLE_CLASS         = 'bol-filled-square-style';
const LOWER_ALPHA_STYLE_CLASS           = 'bol-lower-alpha-style';
const LOWER_ROMAN_STYLE_CLASS           = 'bol-lower-roman-style';
const OUTLINED_DOT_STYLE_CLASS          = 'bol-outlined-dot-style';
const STAR_STYLE_CLASS                  = 'bol-star-style';
const UPPER_ALPHA_STYLE_CLASS           = 'bol-upper-alpha-style';
const UPPER_ROMAN_STYLE_CLASS           = 'bol-upper-roman-style';

const DOT_SEPARATOR                     = 'bol-dot-separator';
const ONE_BRACKET_SEPARATOR             = 'bol-one-bracket-separator';
const DOUBLE_BRACKET_SEPARATOR          = 'bol-double-bracket-separator';
const DEGREE_SEPARATOR                  = 'bol-degree-separator';
const BLANK_SEPARATOR                   = 'bol-blank-separator';

const ALPHA_STYLE                       = 'alpha';
const BRACKET_NUMBER_STYLE              = 'bracket-number';
const DASH_STYLE                        = '-';
const DEGREE_NUMBER_STYLE               = 'degree-number';
const DOT_NUMBER_STYLE                  = 'dot_number';
const DOUBLE_BRACKET_NUMBER_STYLE       = 'double-bracket-number';
const FILLED_DOT_ICON                   = 'fiber_manual_record';
const FILLED_SQUARE_ICON                = 'stop';
const OUTLINED_DOT_ICON                 = 'panorama_fish_eye';
const ROMAN_STYLE                       = 'roman-style';
const STAR_STYLE                        = '*';


const BODY_CLASS                        = 'body';
const CELL_CLASS                        = 'cell';
const ELEMENT_CLASS                     = 'class';
const ROW_CLASS                         = 'row';
/**
 * All style relateds to html list
 * @author Justin WILMET
 * @since 29/05/2019
 * @version 1.0
 */
export default class ConstantBulletStyle {
    static get BLANK_SEPARATOR(){
        return BLANK_SEPARATOR;
    }
    static get DEGREE_SEPARATOR(){
        return DEGREE_SEPARATOR;
    }
    static get DOT_SEPARATOR(){
        return DOT_SEPARATOR;
    }
    static get ONE_BRACKET_SEPARATOR(){
        return ONE_BRACKET_SEPARATOR;
    }
    static get DOUBLE_BRACKET_SEPARATOR(){
        return DOUBLE_BRACKET_SEPARATOR;
    }
    static get ALPHA_STYLE(){
        return ALPHA_STYLE;
    }
    static get BRACKET_NUMBER_STYLE(){
        return BRACKET_NUMBER_STYLE;
    }
    static get DASH_STYLE(){
        return DASH_STYLE;
    }
    static get DEGREE_NUMBER_STYLE(){
        return DEGREE_NUMBER_STYLE;
    }
    static get DOT_NUMBER_STYLE(){
        return DOT_NUMBER_STYLE;
    }
    static get DOUBLE_BRACKET_NUMBER_STYLE(){
        return DOUBLE_BRACKET_NUMBER_STYLE;
    }
    static get FILLED_DOT_ICON(){
        return FILLED_DOT_ICON;
    }
    static get FILLED_SQUARE_ICON(){
        return FILLED_SQUARE_ICON;
    }
    static get OUTLINED_DOT_ICON(){
        return OUTLINED_DOT_ICON;
    }
    static get ROMAN_STYLE(){
        return ROMAN_STYLE;
    }
    static get STAR_STYLE(){
        return STAR_STYLE;
    }
    static get NUMBER_STYLE_CLASS(){
        return NUMBER_STYLE_CLASS;
    }
    static get DASH_STYLE_CLASS(){
        return DASH_STYLE_CLASS;
    }
    static get FILLED_DOT_STYLE_CLASS(){
        return FILLED_DOT_STYLE_CLASS;
    }
    static get FILLED_SQUARE_STYLE_CLASS(){
        return FILLED_SQUARE_STYLE_CLASS;
    }
    static get LOWER_ALPHA_STYLE_CLASS(){
        return LOWER_ALPHA_STYLE_CLASS;
    }
    static get LOWER_ROMAN_STYLE_CLASS(){
        return LOWER_ROMAN_STYLE_CLASS;
    }
    static get OUTLINED_DOT_STYLE_CLASS(){
        return OUTLINED_DOT_STYLE_CLASS;
    }
    static get UPPER_ALPHA_STYLE_CLASS(){
        return UPPER_ALPHA_STYLE_CLASS;
    }
    static get UPPER_ROMAN_STYLE_CLASS(){
        return UPPER_ROMAN_STYLE_CLASS;
    }
    static get STAR_STYLE_CLASS(){
        return STAR_STYLE_CLASS;
    }

    static get BODY_CLASS(){
        return BODY_CLASS;
    }
    static get CELL_CLASS(){
        return CELL_CLASS;
    }
    static get ELEMENT_CLASS(){
        return ELEMENT_CLASS;
    }
    static get ROW_CLASS(){
        return ROW_CLASS;
    }

}