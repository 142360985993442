<template>
  <div>
    <div class="btn-cancel" @click="cancel">
      <bol-back-button :textBackButton="$t('global.action.cancel')"/>
    </div>
    <div class="btn-save" @click="save">
      <bol-save-button :labelButton="$t('global.action.save')"/>
    </div>
    <div v-if="!!actionList" class="btn-action-list">
      <div v-for="action in actionList"
           :key="action.label"
           class="btn-action">
        <v-layout align-center justify-start row @click="doAction(action.method)">
          <v-icon class="icon">
            {{ action.icon }}
          </v-icon>
          <h3>{{ $t('global.action.' + action.label) }}</h3>
        </v-layout>
      </div>
    </div>
  </div>
</template>

<script>
/**
 *
 * Specific buttons panel to save or cancel modification on a document
 * Possibility has been added to add a list of other buttons with a standard behavior.
 * Those standard action has set in the action list
 *
 * @author Cindy SWENNEN, SFEIR Benelux
 * @version 1.0
 * @since 02/07/2019
 *
 */
import BolSaveButton from '../shared/BolSaveButton';
import BolBackButton from '../shared/BolBackButton';

export default ({
  name: 'EditionActionPanel',
  components: {
    BolBackButton,
    BolSaveButton,
  },
  props: {
    isPlaced: {
      bottom: Boolean,
      top: Boolean,
      left: Boolean,
      right: Boolean
    },
    actionList: null
  },
  methods: {
    /**
     * Save the specified document
     */
    save() {
      this.$emit('save');
    },
    /**
     * Cancel the modifications on a specified document
     */
    cancel() {
      this.$emit('cancel');
    },
    /**
     * Send a standard event for all the other action inside the action list
     */
    doAction(method) {
      this.$emit('action', method);
    }
  }
})
</script>

<style scoped>
.btn-cancel {
  background-color: #355B7F;
  position: absolute;
  bottom: 50px;
  left: 40px;
  color: white;
  cursor: pointer;
}

.btn-save {
  background-color: #355B7F;
  position: absolute;
  bottom: 130px;
  left: 40px;
  color: white;
  cursor: pointer;
}

.btn-action-list {
  background-color: #355B7F;
  position: absolute;
  bottom: 210px;
  left: 40px;
  color: white;
  cursor: pointer;
}

.btn-action {
  background-color: #355B7F;
  margin-bottom: 30px;
}

.btn-action h3 {
  max-width: 150px;
}

.icon {
  width: 50px;
  height: 50px;
  background-color: white;
  color: #355B7F;
  border-radius: 50px;
  margin-left: 0px;
  margin-right: 30px;
}

.icon:hover {
  transform: scale(1.1);
}
</style>
