<template>
    <v-layout justify-start align-start row fill-height>
        <v-flex class="structurate">
            <structural-node-tree @saveDocument="saveDocument"></structural-node-tree>
        </v-flex>
        <v-layout column>
            <v-layout  >
                <v-container>
                    <v-layout  >
                        <v-flex xs11>
                            <v-card>
                                <v-layout >
                                    <v-flex class="combobox-container">
                                        <v-autocomplete
                                            v-model="selectedDocumentModificator"
                                            :items="documentModificatorList"
                                            :label="$t('document.label.documentModificator')"
                                            return-object
                                            >

                                            <template slot="selection" slot-scope="prop" style="display:contents;">
                                                <span class="truncate"> {{prop.item.title}}  </span>
                                                <v-btn v-show="prop.item.consolidated" icon>
                                                    <v-icon>check</v-icon>
                                                </v-btn>
                                            </template>

                                            <template slot="item" slot-scope="props">
                                                <v-list-tile-content class="truncate-list-content">
                                                    {{props.item.title}}
                                                </v-list-tile-content>
                                                <v-spacer></v-spacer>
                                                <v-list-tile-action>
                                                    <v-btn icon>
                                                        <v-icon>{{ !props.item.consolidated ? ''   : 'check' }}</v-icon>
                                                    </v-btn>
                                                </v-list-tile-action>
                                            </template>
                                        </v-autocomplete>
                                    </v-flex>
                                    <v-flex xs1>
                                        <v-tooltip top>
                                            <v-btn color="primary" small fab class="btn-save" slot="activator"  @click="openModificator" :disabled="!selectedDocumentModificator">
                                                <v-icon>tab</v-icon>
                                            </v-btn>
                                            <span>{{ $t('global.action.open') }}</span>
                                        </v-tooltip>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-layout>
            <v-flex>
                <node-content-tree :storeName="documentContentStore" :isConsolidation="true" :modificatorContentChunk="modificatorContentChunk" @resetModificator="resetModificator" @saveDocument="saveDocument" v-on:dblclick.native="openTiny" ref="editor"></node-content-tree>
            </v-flex>
        </v-layout>

        <dialog-manage-quote/>

        <v-snackbar
            v-model="snackbar"
            color="#358DD2"
            :timeout="timeout"
            top="top"
        >
            {{ $t(text) }}
            <v-btn
                dark
                flat
                @click="snackbar = false"
                >
                {{ $t('navigation.consolidation.close') }}
            </v-btn>
        </v-snackbar>
    </v-layout>
</template>
<script>
import axios                    from 'axios';
import ConstantAction           from '../../../shared/constant/constantAction.js';
import ConstantConsolidation    from '../../../shared/constant/constantConsolidation.js';
import ConstantDocumentNode     from '../../../shared/constant/constantDocumentNode.js';
import ConstantEvent            from '../../../shared/constant/constantEvent.js';
import ConstantNotification     from '../../../shared/constant/constantNotification.js';
import ConstantStoreName        from '../../../shared/constant/constantStoreName.js';
import DialogManageQuote        from '../structure/dialog/DialogManageQuote.vue';
import EventBus                 from '../../../utils/event-bus.js';
import NodeContentTree          from '../structure/displayer/NodeContentTree.vue';
import StructuralNodeTree       from '../structure/displayer/StructuralNodeTree.vue';
import Vue                      from 'vue';
import { documentLockMixin }    from '../../../mixins/documentLockMixin';
import { mapState, mapActions } from 'vuex';
import { setTimeout }           from 'timers';

/**
 * Manage all the operations to consolidate a document
 *
 * @author Cédric de BOISVILLIERS, SFEIR Benelux
 * @version 1.0
 * @since 18/02/2019
 */
export default {
    name: 'DocumentConsolidation',
    mixins: [documentLockMixin],
    data: () => ({
        snackbar: false,
        timeout: 6000,
        text: '',
        referenceInterval: null,
        selectedDocumentModificator: null,
        consolidationChannel: null,
        modificatorContentChunk: null,
        modificatorOpenId: 0,
        windowModificator: null,
    }),
    created(){
        this.loadDataSheetById(this.$route.params.id);
        this.startInterval();
        this.consolidationChannel = new BroadcastChannel(ConstantConsolidation.CONSOLIDATION_CHANNEL);
    },
    computed: {
        ...mapState({
            rootNode: state => state.documentContent.rootNode,
            selectedNode: state => state.documentContent.selectedNode,
            documentModificatorList : state => state.dataSheetStore.documentModificatorList,
            documentStatus: state => state.dataSheetStore.metaDocument.documentStatus,
            versions: state => state.dataSheetStore.metaDocument.versions,
            documentId: state => state.dataSheetStore.metaDocument.idDocument,
            hasBeenPublishedOnce: state => state.dataSheetStore.metaDocument.hasBeenPublishedOnce
        }),
        documentContentStore(){
            return ConstantStoreName.DOCUMENT_CONTENT;
        }
    },
    mounted() {
        this.consolidationChannel.onmessage = this.receiveFromModificator;
    },
    beforeDestroy() {
        this.stopInterval();
        this.consolidationChannel.close();
    },
    watch: {
        documentId() {
            if( !!this.documentId ) {
                this.loadDocumentContent({id: this.documentId, toLock: true})
                    .catch(() => this.$router.back());
                this.loadDocumentModificatorList(this.documentId);
            }
        },
        documentModificatorList: function (val, oldVal){
            if(!!this.selectedDocumentModificator){
                let reference = this.selectedDocumentModificator.reference;
                this.selectedDocumentModificator = this.documentModificatorList.find( function(element) {
                    if(element.reference === reference ){
                        return element;
                    }
                })
            }
        }
    },
    methods:{
        /**
         * Actions from the store
         */
        ...mapActions({
            loadDocumentContent: 'documentContent/loadDocumentContent',
            loadDataSheetById: 'dataSheetStore/loadDataSheetById',
            loadDataSheetByReferenceAndVersion: 'dataSheetStore/loadDataSheetByReferenceAndVersion',
            loadDocumentModificatorList: 'dataSheetStore/loadDocumentModificatorList'
        }),
        /**
         * Start an interval which trigger an automatique save when it reach zero
         */
        startInterval() {
            this.referenceInterval = setInterval(this.saveDocument, 900000);
        },
        /**
         * At the double click on the document will open the content editor if the selected node exist and if it's a text node
         * we use the keyword 'ref' to allow the parent to be conscious of methods of is child component containing the 'ref'
         */
        openTiny(){
            if(!!this.selectedNode && this.selectedNode.label === ConstantDocumentNode.TEXT_TYPE){
                this.$refs.editor.onOpenDialog( {dialog:ConstantEvent.OPEN_DIALOG_CONTENT_EDITOR});
            }
        },
        /**
         * Stop the interval to avoid autosave in other components
         */
        stopInterval() {
            clearInterval(this.referenceInterval);
        },
        /**
         * Save the modification of the document
         */
        saveDocument() {
            axios
                .put("/content/save", {documentId: this.documentId, content:this.rootNode})
                .then(response => {
                    const notification = {
                        type: ConstantNotification.SUCCEED,
                        message: 'document.notification.content.save.succeed',
                    };
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
                });
        },
        /**
         * Open the document modificator in a new tab/window(browser specific)
         */
        openModificator() {
            this.windowModificator = null;
            const modificatorRoute = this.$router.resolve(
                {
                    name: 'documentModificator',
                    params: {modificatorId: this.selectedDocumentModificator.id,  documentId: this.documentId}
                }
            );
            const windowFeatures = `height=${window.screen.height},width=${window.screen.width},menubar=no`;
            this.windowModificator = window.open(modificatorRoute.href,'document_modifier', windowFeatures);
            const windowOpened = !!this.windowModificator;
            if(windowOpened) {
                this.modificatorOpenId = this.selectedDocumentModificator.id;
            }
        },
        /**
         * Handle action coming from a documentModificator
         * @param {data: {modificatorId<number>, action<string>, contentType<any>, content<any>}}
         */
        receiveFromModificator({data}) {
            if (parseInt(data.modificatorId) === this.modificatorOpenId) {
                if (data.action === ConstantAction.COPY_NODE) {
                    this.text = 'navigation.consolidation.nodeInChannel';
                    this.modificatorContentChunk = data;
                    this.snackbar = true;
                } else if (data.action === ConstantAction.COPY_STRUCTURAL_QUOTE) {
                    this.text = 'navigation.consolidation.structuralBlockquoteInChannel';
                    this.modificatorContentChunk = data;
                    this.snackbar = true;
                } else if (data.action === ConstantAction.COPY_STRUCTURAL_QUOTE_LIST) {
                    this.text = 'navigation.consolidation.structuralBlockquoteListInChannel';
                    this.modificatorContentChunk = data;
                    this.snackbar = true;
                } else if (data.action === ConstantAction.READY_TO_COPY_CONTENT) {
                    this.text = 'navigation.consolidation.contentCanBeApplied';
                    this.snackbar = true;
                } else if (data.action === ConstantAction.COPY_SIBLINGS_NODE) {
                    this.text = 'navigation.consolidation.severalNodeInChannel';
                    this.modificatorContentChunk = data;
                    this.snackbar = true;
                } else if (data.action === ConstantAction.SAVE_DOCUMENT_CONTENT) {
                    this.saveDocument();
                    this.closeModificatorWindow();
                    this.loadDocumentModificatorList(this.documentId);
                } else if (data.action === ConstantAction.REMOVE_DOCUMENT_MODIFIER) {
                    if (data.content.length === 0) {
                        this.removeModifcator();
                    } else {
                        this.loadDocumentModificatorList(this.documentId);
                    }
                    this.closeModificatorWindow();
                }
            }
            if (data.action === ConstantAction.READY_TO_COPY_DOCUMENT_MODIFIER) {
                this.text = 'document.notification.consolidation.copy';
                this.modificatorContentChunk = data;
                this.snackbar = true;
            } else if (data.action === ConstantAction.COPY_ONLINE_QUOTE) {
                this.text = 'document.notification.consolidation.copyQuote';
                this.modificatorContentChunk = data;
                this.snackbar = true;
            }
        },
        /**
         * Remove the relation between a document modificator and a document to consolidate
         */
        removeModifcator() {
            if( this.versions.length-1 >= 1){
                let notification = {
                    type: ConstantNotification.INFO,
                    message: 'document.notification.consolidation.versionDeleted',
                    };
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
                this.$router.go(-1);
            } else {
                if (this.hasBeenPublishedOnce ) {
                    let notification = {
                        type: ConstantNotification.INFO,
                        message: 'document.notification.consolidation.modifierUnlinked',
                    };
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
                    this.$router.go(-1);
                } else {
                    let notification = {
                        type: ConstantNotification.INFO,
                        message: 'document.notification.consolidation.versionDeleted',
                    };
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
                    this.$router.push('/dashboard');
                }
            }
        },
        /**
         * resetModificator
         */
        resetModificator(){
            this.modificatorContentChunk = null;
        },
        /**
         * Close the window/tab where the modificator is open.
         */
        closeModificatorWindow() {
            setTimeout(() => this.windowModificator.close(), 1500);
        }
    },
    components: {
        DialogManageQuote,
        NodeContentTree,
        StructuralNodeTree
    }
}
</script>
<style scoped>
.align-button{
    display: flex;
    justify-content: center;
    align-items: center;
}
.truncate {
    max-width: 600px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.structurate{
    height:calc(100vh + 80px) !important;
    background-color:#355B7F;
}
.combobox-container{
    padding-left:40px;padding-right:40px;
}
.truncate-list-content {
    height: fit-content;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
