const FILE = 'FILE';
const PART = 'PART';
/**
 * Constants for all the PAO node
 * 
 * @author Sébastien De Santis
 * @version 1.0
 * @since 3/11/2020
 */
export default class ConstantPaoNode{

    static get TYPE(){
        return {
            FILE,
            PART
        }
    }
}