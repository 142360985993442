<script>
import ConstantDocumentNode from '../../../../shared/constant/constantDocumentNode';
import ConstantTag from '../../../../shared/constant/constantTag';
import domManipulatorService from '../../../../service/html/domManipulatorService';
import moment from 'moment';
import ConstantApplication from '../../../../shared/constant/constantApplication';

const TEXT_NODE_CLASSES = [ConstantDocumentNode.CONTENT_STYLE_CLASS, ConstantDocumentNode.TEXT_NODE_CLASS];
/**
 * Component that display a node with his content.
 * This component is recursive and display each children it may have.
 *
 * @author Sébastien DE SANTIS, Cédric de BOISVILLIERS
 * @version 1.1
 * @since 2018-11-23
 */
export default {
  name: 'ContentNode',
  props: {
    node: {
      required: true
    }
  },
  render: function (createElement) {
    return createElement('div',
        {
          attrs: {
            id: `${ConstantDocumentNode.NODE_CONTENT_CLASS}-${this.node.id}`
          },
          class: [ConstantDocumentNode.NODE_CONTENT_CLASS, {'bol-node-content-selected': this.node.selected}]
        },
        [this.getNodeElementList(createElement)]
    );
  },
  computed: {
    nodeContent() {
      return this.node.content;
    },
    nodeContentChildren() {
      return domManipulatorService.extractNodesFromHTML(this.node.content);
    }
  },
  methods: {
    /**
     * Return a list of Vue Vnodes to nest in the root Vnode of the component
     */
    getNodeElementList(createElement) {
      return [this.getContentElement(createElement), ...this.getNodeChildrenElement(createElement)];
    },
    /**
     * Return a Vue Vnode with either the node type or the content
     */
    getContentElement(createElement) {
      let contentElement;
      if (this.node.type !== ConstantDocumentNode.TEXT_TYPE) {
        contentElement = this.getNodeTypeElement(createElement);
      } else {
        contentElement = this.getNodeContentTextElement(createElement);
      }
      return contentElement;
    },
    /**
     * Return a Vue Vnode with the document node type
     */
    getNodeTypeElement(createElement) {
      let keywords;
      if (!!this.node.keywords && this.node.keywords.length > 0) {
        keywords = createElement('v-icon', {
          class: 'node-keyword',
          domProps: {
            innerHTML: 'vpn_key'
          },
          style: {
            "font-size": "20px",
            "margin-left": "5px"
          }
        })
      }
      let lang;
      if (!!this.node.lang) {
        lang = createElement('v-icon', {
          class: 'node-lang',
          domProps: {
            innerHTML: this.node.lang
          }
        })
      }
      let icon;
      if (!this.$isNodeVisible()) {
        icon = createElement('v-icon', {
          domProps: {
            innerHTML: 'visibility_off'
          },
          style: {
            "font-size": "20px",
            "margin-left": "5px",
            "margin-right": "5px"
          }
        })
      } else {
        icon = createElement('v-icon', {
          domProps: {
            innerHTML: 'arrow_drop_down'
          }
        })
      }
      let spanInfo;
      let nodeInfoText = this.handleNodeInfoText();
      if (!!nodeInfoText) {
        spanInfo = createElement(ConstantTag.SPAN, {
          domProps: {
            innerHTML: `&nbsp;->&nbsp;${nodeInfoText}`
          }
        })
      }
      return createElement('div',
          {class: 'bol-node-type'},
          [
            icon,
            this.node.label,
            lang,
            keywords,
            spanInfo
          ]
      );
    },
    /**
     * Manage the information text of a node. An information text will appear next to node in the following case:
     *  * The node has a replacement text
     *  * The node is a DOC-DATE node. The information to display is a date contained inside the content field
     *  * The node is a DOC-ID node. The information to display is a document reference.
     */
    handleNodeInfoText() {
      if (!!this.node.replacementText) {
        return this.node.replacementText
      }
      if (this.node.label === ConstantTag.DOC_DATE) {
        return moment(this.node.content).format(ConstantApplication.DEFAULT_DATE_FORMAT);
      }
      if (this.node.label === ConstantTag.DOC_ID) {
        return this.node.content;
      }
    },
    /**
     * Return a Vue Vnode with the document node content
     */
    getNodeContentTextElement(createElement) {
      let htmlElementList = [];
      let tableNbr = 1;

      this.nodeContentChildren.forEach(domNode => {
        htmlElementList.push(this.$getElementVNode(domNode, createElement));
      });

      return createElement('div', {class: TEXT_NODE_CLASSES}, htmlElementList);
    },
    /**
     * return a list of Vue Vnodes with the child Nodes of the current document node
     */
    getNodeChildrenElement(createElement) {
      let childrenElementList = []
      if (!!this.node.children) {
        if (this.$isNodeVisible()) {
          childrenElementList.push(...this.node.children.map(childNode => createElement('div',
              {key: childNode.id},
              [
                createElement('content-node', {
                  props: {
                    node: childNode,
                  }
                })
              ]
          )));
        }
      }
      return childrenElementList;
    },
    /**
     * Return true or false depending on if the visibility of the node is present or not and if it's set to true or false.
     */
    $isNodeVisible() {
      if (this.node.visible !== undefined && this.node.visible !== null) {
        return this.node.visible
      } else {
        return true;
      }
    },
    /**
     * return a list of Vue Vnodes from part of the content node without table
     */
    $getElementVNode(domNode, createElement) {
      if (domNode.nodeType === Node.ELEMENT_NODE) {
        let elementAttributes = domNode.attributes;
        let attributes = {};
        for (const attr of elementAttributes) {
          if (!attr.name.startsWith('data-v-')) {
            attributes[attr.name] = attr.value;
          }
          ;
        }
        return createElement(domNode.tagName, {
          attrs: attributes,
          class: elementAttributes.class ? elementAttributes.class.textContent : null,
          domProps: {
            innerHTML: domNode.innerHTML
          }
        });
      } else if (domNode.nodeType === Node.TEXT_NODE) {
        return domNode.textContent;
      }
    }
  }
}
</script>
<style>
.custom-footnote-index {
  vertical-align: super;
  font-size: smaller;
}

.bol-text-node table tbody tr > td:first-child {
  width: 5%;
}

@media (max-width: 900px) {
  .bol-text-node table tbody tr > td:first-child {
    width: 6%;
  }
}

.bol-text-node table tbody tr td {
  font-size: small;
}
</style>
<style scoped>
.node-keyword {
  font-size: 20px;
  margin-left: 5px;
}

.node-lang {
  font-size: 8px;
  margin-left: 5px;
  font-style: italic;
  color: grey;
}

.bol-node-content {
  margin-left: 10px;
  margin-right: 10px;
}

.bol-node-content .bol-node-type {
  color: darkgray;
  margin-left: 15px;
  font-size: smaller;
}

.bol-node-content-selected {
  border: dashed 2px #bdbdbd;

}

.bol-style-content {
  min-height: 15px;
}

.bol-text-node {
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 7px;
  word-break: break-word;
  height: 100%;
}

.bol-online-blockquote {
  border-left: solid 2px #bdbdbd;
  padding: 0 1em;
  margin: 1em;
}
</style>


