<template>
    <v-layout
        row
        wrap
        align-left
    >
        <v-flex>
            <v-container>
                <h1
                    v-if="$route.params.uid == null"
                    class="bolded"
                >
                    {{ $t('administration.user.manage.add.title') }}
                </h1>
                <v-form
                    ref="formUser"
                    v-model="valid"
                    class="form-style"
                    lazy-validation
                >
                    <v-layout
                        column
                        justify-center
                    >
                        <v-flex md6>
                            <v-text-field 
                                v-model="user.firstName"
                                :label="$t('administration.user.label.firstName')"
                                :rules="labelRules"
                                required
                                clearable
                            />
                        </v-flex>
                        <v-flex md6>
                            <v-text-field
                                v-model="user.lastName"
                                :label="$t('administration.user.label.secondName')"
                                :rules="labelRules"
                                required
                                clearable
                            />
                        </v-flex>
                        <v-flex md6>
                            <v-text-field
                                v-model="user.commonName"
                                :label="$t('administration.user.label.givenName')"
                                :rules="labelRules"
                                required
                                clearable
                            />
                        </v-flex>
                        <v-flex md6>
                            <v-text-field
                                v-model="user.email"
                                :label="$t('administration.user.label.email')"
                                :rules="labelRules"
                                required
                                clearable
                            />
                        </v-flex>
                        <v-flex md6>
                            <v-radio-group
                                v-model="user.preferredLanguage"
                                row
                            >
                                <v-radio
                                    label="FR"
                                    value="FR"
                                />
                                <v-radio
                                    label="EN"
                                    value="EN"
                                />
                            </v-radio-group>
                        </v-flex>
                        <v-flex style="display: flex; width: fit-content">
                            <v-flex>
                                <v-checkbox
                                    v-model="user.marketCodes"
                                    :label="$t('administration.user.label.marketCodes.luxembourg')"
                                    value="lu"
                                    multiple
                                />
                            </v-flex>
                            <v-flex style="margin-left: 10%">
                                <v-checkbox
                                    v-model="user.marketCodes"
                                    :label="$t('administration.user.label.marketCodes.belgium')"
                                    value="be"
                                    multiple
                                />
                            </v-flex>
                        </v-flex>    
                        <v-flex md6>
                            <v-autocomplete
                                v-model="user.ldapRoleList"
                                :label="$t('administration.user.label.roles')"
                                :items="allRoles"
                                :multiple="true"
                                :chips="true"
                                item-value="uid"
                                item-text="name"
                                required
                                clearable
                                return-object
                                :deletable-chips="true"
                            />
                        </v-flex>
                        <v-flex
                            v-if="!!user.uid"
                            md6
                        >
                            <v-checkbox
                                v-model="editPassword"
                                :label="$t('administration.user.manage.password.editPassword')"
                            />
                        </v-flex>
                        <v-flex
                            v-if="!user.uid || editPassword"
                            md6
                        >
                            <v-text-field
                                autocomplete="current-password"
                                :value="user.password"
                                :label="$t('administration.user.manage.password.enterPassword')"
                                :hint="$t('administration.user.manage.password.wellDonePassword')"
                                :append-icon="passwordVisible ? 'visibility' : 'visibility_off'"
                                :type="passwordVisible ? 'password' : 'text'"
                                :rules="[passwordRule]"
                                @click:append="() => (passwordVisible = !passwordVisible)"
                                @input="_=>user.password=_"
                            />
                        </v-flex>

                        <!-- BUTTONS -->
                        <v-flex
                            xs3
                            justify-end
                        >
                            <v-tooltip
                                top
                                class="align-action-end"
                            >
                                <v-btn
                                    slot="activator"
                                    color="#355B7F"
                                    small
                                    fab
                                    :to="{ name: 'UserSearch' }"
                                >
                                    <v-icon color="white">
                                        cancel
                                    </v-icon>
                                </v-btn>
                                <span>{{ $t("administration.user.manage.action.cancel") }}</span>
                            </v-tooltip>
                            <v-tooltip
                                v-if="valid"
                                top
                                class="align-action-end"
                            >
                                <v-btn
                                    slot="activator"
                                    color="primary"
                                    small
                                    fab
                                    dark
                                    icon
                                    @click="manage(user)"
                                >
                                    <v-icon>save</v-icon>
                                </v-btn>
                                <span>{{ $t("administration.user.manage.action." + (!!user.uid ? "add" : "save")) }}</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </v-form>     
            </v-container>
        </v-flex>
    </v-layout>
</template>

<script>

import axios from 'axios';

export default {
    name: 'UserManagement',
    data() {
        return {
            valid: false,
            passwordVisible: false,
            editPassword: false,
            user: {
                uid: null,
                firstName: null,
                lastName: null,
                commonName: null,
                email: null,
                preferredLanguage: null,
                ldapRoleList: null,
                marketCodes: []
            },
            allRoles: [],
            labelRules: [
                v => !!v || this.$t("administration.user.manage.required")
            ],
            passwordRule: value => {
                const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
                return (
                    pattern.test(value) ||
                    this.$t("administration.user.manage.password.invalidPassword")
                );
            },
        }
    },
    mounted() {
        /**
         * When the page is charging, we pay attention if an uid is passed,
         * if it's the case, it means that we update a user instead of creating one
         */
        if (this.$route.params.uid !== undefined) {
            this.loadUser(this.$route.params.uid);
        }
        this.loadRoles();
    },
    methods: {
        /**
         *  Create or update a user
         * @param newUser
         */
        manage(user) {
            if (this.$refs.formUser.validate()) {
                let method = null;
                if (!!this.$route.params.uid) {
                    method = "PUT",
                    user.uid = this.$route.params.uid;
                } else {
                    method = "POST";
                }

                axios({
                    method: method,
                    url: "administration/user",
                    data: user
                }).then(() => {
                    this.$router.push('/administration/users');
                });
            }
        },
        /**
         * Retrieve a user with its uid
         * @param uid
         */
        loadUser(uid) {
            axios.get(
                `administration/user/${uid}`
            ).then(result => {
                this.user = result.data;
            });
        },

        loadRoles() {
            axios.post('administration/user/roles').then(result => {
                this.allRoles = result.data;
            });
        }
    }
}
</script>
<style>
.form-style {
    margin-top:50px;
}
.align-action-end{
    float: right;
}
.bolded{
    font-weight: bold;color: #355B7F;
}
</style>
