<template>
    <v-btn color="white" small fab dark slot="activator" @click.native="goToPage()">
        <v-icon color="primary">{{ iconText }}</v-icon>
    </v-btn>
</template>

<script>
import axios from 'axios';
/**
 * 
 * Specific button to go to editable pages (document, datasheet, link) 
 * which adds a parameter "editable" into the specified route
 * @author Cindy SWENNEN, SFEIR Benelux
 * @version 1.0
 * @since 02/07/2019
 * 
 */
export default ({
    name: 'ButtonToEditableState',
    props: {'iconText': { default: null}},
    data(){
        return {
            editable: true
        }
    },
    methods: {
        /**
         * Go to specified page from ActionPanelDocument
         */
        goToPage(){
            if( this.iconText === 'cloud_upload'){
                this.$emit('upload');
            }else {
                this.$emit('to-page');
            } 
        }
    }
})
</script>


