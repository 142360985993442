<template>
    <v-layout align-center justify-start row fill-height>
        <v-icon class="icon">
            save
        </v-icon>
        <h3>{{labelButton}}</h3>
    </v-layout>
</template>

<script>
    /**
     * Custom button with label props and save icon
     * Use this component when you have to put a
     * save button in the application.
     * @author Romain GENTILHOMME, Sfeir Benelux
     * @version 1.0
     * @since 03/02/2020
    */
    export default {
        name: "BolSaveButton",
        props:{
            labelButton:{
                default:null
            }
        },
    }
</script>

<style scoped>
.icon{
    width:50px;
    height:50px;
    background-color: white;
    color:#355B7F;
    border-radius: 50px;
    margin-left:0px;
    margin-right:30px;
}
.icon:hover{
    transform: scale(1.1);
}
</style>