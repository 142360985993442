<template>
    <v-layout
        row
        wrap
        align-left
    >
        <v-flex>
            <v-container>
                <h1
                    v-if="$route.params.id == null"
                    class="bolded"
                >
                    {{ $t('administration.partner.manage.add.title') }}
                </h1>
                <v-form
                    ref="formPartner"
                    v-model="valid"
                    class="form-style"
                    lazy-validation
                >
                    <v-layout
                        column
                        justify-center
                    >
                        <v-flex md6>
                            <v-text-field
                                v-model="partner.label"
                                :label="$t('administration.partner.label.name')"
                                :rules="labelRules"
                                required
                                clearable
                            />
                        </v-flex>
                        <v-flex md6>
                            <v-text-field
                                v-model="partner.code"
                                :label="$t('administration.partner.label.abbreviation')"
                                :rules="abbreviationRules"
                                required
                                clearable
                            />
                        </v-flex>
                        <v-flex md6>
                            <v-text-field
                                    v-model="partner.importLabel"
                                    :label="$t('administration.partner.label.importLabel')"
                                    clearable/>
                        </v-flex>
                        <!-- TYPE -->
                        <v-select
                            v-model="partner.type"
                            :label="$t('administration.partner.label.type')"
                            :rules="labelRules"
                            :items="partnerTypeList"
                            required
                            clearable
                        />
                        <!-- MARKET -->
                        <v-autocomplete
                            v-model="partner.markets"
                            :items="allMarketList"
                            :disabled="allMarketList.length === 0"
                            dense
                            filled
                            multiple
                            chips
                            small-chips
                            item-text="code"
                            return-object
                            :label="$t('administration.partner.label.markets')"
                        />
                        <!-- BUTTONS -->
                        <v-flex
                            xs3
                            justify-end
                        >
                            <v-tooltip
                                top
                                class="align-action-end"
                            >
                                <v-btn
                                    slot="activator"
                                    color="#355B7F"
                                    small
                                    fab
                                    :to="{ name: 'PartnerSearch' }"
                                >
                                    <v-icon color="white">
                                        cancel
                                    </v-icon>
                                </v-btn>
                                <span>{{ $t("administration.partner.manage.action.cancel") }}</span>
                            </v-tooltip>
                            <v-tooltip
                                v-if="valid"
                                top
                                class="align-action-end"
                            >
                                <v-btn
                                    slot="activator"
                                    color="primary"
                                    small
                                    fab
                                    dark
                                    icon
                                    @click="manage(partner)"
                                >
                                    <v-icon>save</v-icon>
                                </v-btn>
                                <span>{{ $t("administration.partner.manage.action.add") }}</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-container>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from 'axios';
import EventBus from '../../../utils/event-bus.js';
import ConstantEvent from '../../../shared/constant/constantEvent.js'

/**
     * Form to create or update a partner
     *
     * @author Cesar Castro, Legitech
     * @version 1.0
     * @since 19/06/2019
     */
export default {
    name: 'PartnerManagement',
    data () {
        return {
            valid: false,
            partner: {
                id: null,
                label: null,
                importLabel: null,
                code: null,
                markets: null,
                type: null
            },
            allMarketList: [],
            partnerTypeList: ['LEXNOW', 'LEGITECH'],
            labelRules: [
                v => !!v || this.$t("administration.partner.manage.required")
            ],
            abbreviationRules: [
                v => !!v || this.$t("administration.partner.manage.required"),
                v => !!v && v.length < 6 || this.$t("administration.partner.manage.too-long") + "5"
            ]
        }
    },
    mounted(){
        /**
             * When the page is charging, we pay attention if an id is passed,
             * if it's the case, it means that we update a partner instead of creating one
             */
        if(this.$route.params.id !== undefined) {
            this.loadPartner(this.$route.params.id);
        }
        this.loadMarkets();
    },
    methods:{
        /**
             *  Create or update a partner
             * @param newPartner
             */
        manage(partner) {
            if(this.$refs.formPartner.validate()){
                let method = null;
                if(!!this.$route.params.id) {
                    method = "PUT",
                    partner.id = this.$route.params.id;
                } else {
                    method = "POST";
                }

                axios({
                    method: method,
                    url: "administration/partner",
                    data: partner
                }).then(() => {
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                        message: 'administration.partner.manage.' +  (!this.$route.params.id ? 'add' : 'edit') +'.success',
                        type: 'SUCCEED'
                    });
                    this.$router.push('/administration/partner');
                });
            }
        },
        /**
         * Retrieve a partner with its id
         * @param id
         **/
        loadPartner( id ) {
            axios.get(
                `administration/partner/${id}`
            ).then( result => {
                this.partner = result.data;
            });
        },
        loadMarkets() {
            axios.get(
                `administration/markets`
            ).then(result => {
                this.allMarketList = result.data;
            });
        }
    }
}
</script>
<style>
    .form-style {
        margin-top:50px;
    }
    .align-action-end{
        float: right;
    }
    .bolded{
        font-weight: bold;color: #355B7F;
    }
</style>
