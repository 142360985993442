<template>
    <v-layout
        row
        wrap
    >
        <!-- TITLE OF THE PAGE-->
        <h2 class="custom_book">
            {{ $t('administration.publication.book.search.mainTitle') }}
        </h2>
        
        <!-- FORM WITH RESEARCH BUTTON -->
        <v-layout
            row
            wrap
            justify-center
            class="layout_book"
        >
            <v-flex
                xs3
                class="sizer_flex"
            >
                <v-text-field
                    v-model="filter.title"
                    dark
                    :label="$t('administration.publication.book.search.title')"
                    clearable
                    @keyup.enter="search(filter,pageable)"
                />
            </v-flex> 
            <v-flex
                xs3
                class="sizer_flex"
            >
                <v-text-field
                    v-model="filter.code"
                    dark
                    :label="$t('administration.publication.book.search.code')"
                    clearable
                    @keyup.enter="search(filter,pageable)"
                />
            </v-flex> 
            <v-flex
                xs3
                class="sizer_flex"
            >
                <v-select 
                    v-model="filter.bookType"
                    dark
                    :label="$t('administration.publication.book.search.type')"
                    :items="bookTypeList"
                    clearable
                >
                    <template
                        slot="selection"
                        slot-scope="{ item }"
                    >
                        {{ $t('administration.publication.book.' + item ) }}
                    </template>
                    <template
                        slot="item"
                        slot-scope="{ item }"
                    >
                        {{ $t('administration.publication.book.' + item ) }}
                    </template>
                </v-select>
            </v-flex> 
            <v-flex
                xs1
                justify-end
            >
                <v-btn
                    color="white"
                    small
                    fab
                >
                    <v-icon
                        color="primary"
                        dark
                        @keyup.enter="search(filter,pageable)"
                        @click="search(filter,pageable)"
                    >
                        search
                    </v-icon>
                </v-btn>
            </v-flex>
        </v-layout>

        <!-- PROGRESS BAR -->
        <v-layout
            v-if="displaySpinner"
            align-center
            justify-center
            row
        >
            <v-progress-circular
                :size="70"
                :indeterminate="true"
                color="primary"
            />
        </v-layout>

        <!-- DATA TABLES -->
        <v-flex v-if="!displaySpinner">
            <v-data-table
                :headers="headers"
                :items="bookList"
                hide-actions
                disable-initial-sort
                align-center
                justify-center
                class="elevation-1"
            >
                <template
                    slot="headers"
                    slot-scope="props"
                >
                    <th>{{ $t('administration.publication.book.label.title') }}</th>
                    <th>{{ $t('administration.publication.book.label.code') }}</th>
                    <th>{{ $t('administration.publication.book.label.type') }}</th>
                    <th>{{ $t('administration.publication.book.label.actions') }}</th>
                </template>

                <template slot="no-data">
                    <td
                        colspan="100%"
                        class="text-xs-center"
                    >
                        {{ $t('administration.publication.book.search.none') }}
                    </td>
                </template>

                <template
                    slot="items"
                    slot-scope="props"
                >
                    <td class="align-items">
                        {{ props.item.label }}
                    </td>
                    <td class="align-items">
                        {{ props.item.code }}
                    </td>
                    <td class="align-items">
                        {{ $t('administration.publication.book.' + props.item.bookType) }}
                    </td>
                    <td class="justify-center layout px-0 icon-customer">
                        <router-link
                            tag="button"
                            :to="{name: 'BookEdit', params: { id: props.item.id }}"
                        >
                            <v-icon
                                class="edit-icon"
                                medium
                            >
                                edit
                            </v-icon>
                        </router-link>
                        <v-icon
                            slot="activator"
                            class="edit-icon"
                            medium
                            @click="deleteBook(props.item)"
                        >
                            delete_forever
                        </v-icon>
                    </td>
                </template>
            </v-data-table>

            <v-dialog
                v-model="dialog"
                persistent
                max-width="350"
                icon
            >
                <v-card>
                    <v-card-title class="headline">
                        {{ $t('administration.publication.book.manage.delete.title') }}
                    </v-card-title>
                    <v-divider />
                    <v-card-text>{{ $t('administration.publication.book.manage.delete.message') }}</v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            color="primary"
                            small
                            flat
                            @click.native="dialog = false"
                        >
                            {{ $t('administration.publication.book.manage.delete.cancel') }}
                        </v-btn>
                        <v-btn
                            color="primary"
                            small
                            flat
                            @click.native="confirmDelete()"
                        >
                            {{ $t('administration.publication.book.manage.delete.confirm') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-flex>

        <!-- FLOATING BUTTON (ADD) -->
        <v-flex
            xs12
            text-xs-right
        >
            <v-tooltip top>
                <v-btn
                    slot="activator"
                    color="primary"
                    small
                    fab
                    dark
                    icon
                    class="icon-add-custom"
                    :to="{name: 'BookAdd'}"
                >
                    <v-icon dark>
                        add
                    </v-icon>
                </v-btn>
                <span>{{ $t('administration.publication.book.action.add') }}</span>
            </v-tooltip>
        </v-flex>

        <!-- PAGINATION -->
        <v-flex
            v-if="!!bookList && bookList.length > 0"
            xs12
            class="book-list"
        >
            <v-pagination
                :length="pageable.totalPages"
                :total-visible="5"
                :value="pageable.page"
                circle
                @input="changePage"
            />
        </v-flex>
    </v-layout>
</template>

<script>

import axios from 'axios';

export default {
    name:'BookSearch',
    data () {
        return {
            headers: [],
            bookList: [],
            displaySpinner: false,
            pageable: {page: 1, size: 10,  direction: 'ASC', property: "title"},
            filter: {},
            dialog: false,
            bookTypeList: []
        }
    },
    created(){
        this.loadBookTypes();
        this.filterBooks(this.filter, this.pageable);
    },
    methods:{
        /**
         * Search a book 
         * @param filter
         * @param pageable
         */
        search(filter, pageable) {
            this.filterBooks(filter, pageable);
        },
        
        /**
         * Search monographies and codes by filter
         * @param filter
         * @param pageable
         */
        filterBooks(filter, pageable){
            this.displaySpinner = true;
            axios.post(
                'administration/publication/book/filter',
                filter,
                {params: pageable}
            ).then( (result ) => {
                this.displaySpinner = false;
                this.bookList = result.data.content;
                this.pageable.totalElements = result.data.totalElements;
                this.pageable.totalPages = result.data.totalPages;
                this.pageable.page = result.data.page;
            });
        },
        /**
         * Open the delete modal to confirm deleting
         * @param item
         */
        deleteBook( book ) {
            this.bookIdToDelete = book.id;
            this.dialog = true;
        },
        confirmDelete() {
            this.dialog = false;
            axios.delete(
                `administration/publication/book/${this.bookIdToDelete}`
            ).then( () => {
                this.filterBooks(this.filter, this.pageable);
            });
        },
        /**
         * change page and navigate to the selected page
         * @param page
         */
        changePage(page){
            this.pageable.page = page;
            this.filterBooks(this.filter, this.pageable);
        },
        /**
         * Retrieve the list of book types
         */
        loadBookTypes( ) {
            axios.get(
                `administration/publication/book/init`
            ).then( result => {
                this.bookTypeList = result.data;
            });
        }
    }
}
</script>

<style scoped>
    .custom_book{
        background-color: #355B7F;
        color:white;
        padding-top:50px;
        text-align: center;
        width:100vw;
    }
    .layout_book{
        background-color: #355B7F;
        color:white;
        padding-top: 25px;
    }
    .sizer_flex{
        width:100vw;
        padding-right: 25px;
    }
    .align-items{
        text-align: center;
    }
    .icon-customer{
        margin-left: 15px;
    }
    .book-list{
        transform: translate(45%,0);
        margin-top: 20px;
    }
    .icon-add-custom{
        position: fixed; 
        right: 50px; 
        bottom: 0;
    }
</style>


