/**
 * Manage all the specific operation related to a pao tree
 * 
 * @author Cédric de BOISVILLIERS
 * @since 26/09/2019
 * @version 1.0
 * 
 */
class paoService {

    constructor(){

    }

    /**
     * Put a pao structure tree in a hashmap.
     * The key is the ID of the node and the value is the node.
     * If a node hasn't its ID set, generate and ID and set it in the structure.
     * @param {Array<paoNode>} structure 
     */
    toHashMap(structure) {
        return this.$toHashMap([structure]);
    }
    $toHashMap(nodeList) {
        return nodeList.reduce( (hashMap, node) => {
            node.id = node.id || this.$generateUid();
            hashMap.set(`${node.id}`, node);
            if(!!node.children && node.children.length > 0) {
                hashMap = new Map([...hashMap, ...this.$toHashMap(node.children)]);
            }
            return hashMap;
        } , new Map());
    }
    /**
     * Remove the IDs set to manage the new nodes
     */
    cleanNodes(hashMap){
        let regexId = /^.{8}-.{4}-5.{3}-.{4}-.{12}/;
        for (let [id, node] of hashMap) {
            if (regexId.test(id)) {
                node.id = null;
            }
        }
    } 

    /**
     * Create a new pao node
     * @param {*} nodePolicy
     * @param {*} parentNode
     */
    createPaoNode(parentNode, nodePolicy) {
        return {
            id: this.$generateUid(),
            rank: 0,
            idNodePolicy: nodePolicy.id,
            type: nodePolicy.type,
            label: "",
            children: null
        };
    }

    /**
     * Organize list by each index of elements and set it into rank var of the element
     * @param childrenList
     */
    organizeRank(childrenList){
        if (!!childrenList) {
            childrenList.forEach((value, index) => {
                value.rank = index;
            });
        }
    }

    /**
     * Generate a ( client ) uid use for node id generation
     * ( Note: we need a node id for finding / deleting node etc. )
     */
    $generateUid( ) {
        return 'xxxxxxxx-xxxx-5xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
            function(c) {
                var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
                return v.toString(16);
            });
    }
    /**
     * Found a document inside the node structure by its ID
     * @param {*} documentId 
     * @param {*} node 
     */
    findDocumentById(documentId, node){
        if(!!node.documentList){
            let document = node.documentList.find(child => parseInt(child.id, 10) === parseInt(documentId, 10));
            if(!!document){
                return document;
            }
        }
        if(!!node.children){
            let found;
            let index = 0;
            while(!found && index < node.children.length){
                found = this.findDocumentById(documentId, node.children[index]);
                index ++;
            }
            if(!!found){
                return found;
            }
        }
    }
}

export default new paoService();