<template>
    <v-container class="container">
        <v-layout
            align-space-start
            justify-start
            row
            fill-height
        >
            <v-flex>
                <structural-node-tree @saveDocument="saveDocument" />
            </v-flex>
            <v-flex class="node-content">
                <node-content-tree
                    ref="editor"
                    :store-name="documentContent"
                    @saveDocument="saveDocument"
                    @dblclick.native="openTiny"
                />
            </v-flex>
        </v-layout>
        <dialog-manage-quote />
    </v-container>
</template>
<script>

import axios from 'axios';
import ConstantEvent from '../../../shared/constant/constantEvent.js';
import ConstantNotification from '../../../shared/constant/constantNotification.js';
import ConstantStoreName from '../../../shared/constant/constantStoreName.js';
import DialogManageQuote from './dialog/DialogManageQuote';
import EventBus from '../../../utils/event-bus.js';
import NodeContentTree from './displayer/NodeContentTree.vue';
import StructuralNodeTree from './displayer/StructuralNodeTree.vue';
import {documentLockMixin} from '../../../mixins/documentLockMixin';
import {mapActions, mapState} from 'vuex';
import ConstantDocumentNode from '../../../shared/constant/constantDocumentNode.js';

/**
 * Manage all the operation related to the content document edition
 *
 * @author DE SANTIS Sébastien, SFEIR Benelux
 * @version 1.0
 * @since 24/09/2018
 */
export default {
    name: 'StructureEditor',
    mixins: [documentLockMixin],
    data: () => ({
        referenceInterval: null,
    }),
    created() {
        this.loadDataSheetById(this.$route.params.id);
        if (!!this.idDocument) {
            this.loadDocumentContent({id: this.idDocument, toLock: true})
                .catch(() => this.$router.back());
        }
        this.startInterval();
    },
    computed: {
        ...mapState({
            idDocument: state => state.dataSheetStore.metaDocument.idDocument
        }),
        rootNode() {
            return this.$store.state[`${ConstantStoreName.DOCUMENT_CONTENT}`].rootNode;
        },
        selectedNode() {
            return this.$store.state[`${ConstantStoreName.DOCUMENT_CONTENT}`].selectedNode;
        },
        documentContent() {
            return ConstantStoreName.DOCUMENT_CONTENT;
        }
    },
    components: {
        DialogManageQuote,
        NodeContentTree,
        StructuralNodeTree
    },
    methods: {
        ...mapActions({
            loadDataSheetById: 'dataSheetStore/loadDataSheetById',
            loadDocumentContent: 'documentContent/loadDocumentContent'
        }),
        startInterval: function () {
            this.referenceInterval = setInterval(this.saveDocument, 900000);
        },
        stopFocusInEvent(event) {
            event.stopPropagation();
        },
        openTiny() {
            if (!!this.selectedNode && this.selectedNode.label === ConstantDocumentNode.TEXT_TYPE) {
                this.$refs.editor.onOpenDialog({dialog: ConstantEvent.OPEN_DIALOG_CONTENT_EDITOR});
            }
        },
        saveDocument: function () {
            axios
                .put("/content/save", {documentId: this.idDocument, content: this.rootNode})
                .then(response => {
                    var notification = {
                        type: ConstantNotification.SUCCEED,
                        message: 'document.notification.content.save.succeed',
                    };
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
                });
        },
        stopInterval() {
            clearInterval(this.referenceInterval);
        }
    },
    watch: {
        idDocument() {
            if (!!this.idDocument) {
                this.loadDocumentContent({id: this.idDocument, toLock: true})
                    .catch(() => this.$router.back());
            }
        },
    },
    mounted() {
        //this is tinymc fix for table editor.
        window.addEventListener("focusin", this.stopFocusInEvent, true);
    },
    beforeDestroy() {
        this.stopInterval();
        //this is tinymc fix for table editor.
        window.removeEventListener("focusin", this.stopFocusInEvent);
    }
}
</script>
<style scoped>
.container {
  margin: 0;
  padding: 0;
  min-width: 100% !important;
}

.node-content {
  width: 100%;
}
</style>
