const PAO_NODE          = 'pao-node';
const TEXTNODE          = 'textNode';
const CUSTOM_LIST_CLASS = 'bol-custom-list';

/**
 * Constants for html/css class names
 * @author Cindy SWENNEN
 * @since 22/08/2019
 * @version 1.0
 */
export default class ConstantClassName {
    static get PAO_NODE(){
        return PAO_NODE;
    }
    static get TEXTNODE(){
        return TEXTNODE;
    }
    static get CUSTOM_LIST_CLASS(){
        return CUSTOM_LIST_CLASS;
    }   
}