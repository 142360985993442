/**
 * 
 * Manage common operations related to any kind of footnotes
 * 
 * @author Sébastien DE SANTIS, Cédric de BOISVILLIERS
 * @since 26/04/2019
 * @version 1.0
 */
class FootnoteService{

    constructor(){

    }

    /**
     * Create a footnote
     * @param {*} content 
     */
    createFootnote(content){
        return {
            id: this.generateUid(),
            rank: 0,
            content: content
        }
    }

    /**
     * render the rank style and return it
     * @param {*} rank 
     * @param {*} footnoteStyle 
     */
    createStyleOnRank(rank, footnoteStyle){
        var rankStyle;
        rank = this.$extractActualRankWithoutStyle(""+rank);

        switch (footnoteStyle) {
            case 2:
                rankStyle = `${rank}.`;
                break;
            case 3:
                rankStyle = `(${rank})`;
                break;
            case 4:
                rankStyle = rank === '0' ? '0' : `${"*".repeat(rank)}`;
                break;
            case 5:
                rankStyle = rank === '0' ? '0' : `(${"*".repeat(rank)})`;
                break;
            default:
                rankStyle = rank;
        }

        return rankStyle;
    }

    /**
     * Extract rank value of his style
     * @param {*} rank 
     */
    $extractActualRankWithoutStyle( rank ){
        let regex = /[\d*]+/gm;
        return rank.match(regex)[0];
    }

    /**
     * Generate a ( client ) uid use for footnote id generation
     */
    generateUid( ) {
        return 'xxxxxxxx-xxxx-1xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
            function(c) {
                var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
                return v.toString(16);
            });
    }

}

export default new FootnoteService();