const AVAILABLE             = 'AVAILABLE';
const CONSOLIDATION         = 'CONSOLIDATION';
const EDITION               = 'EDITION';
const MARKING               = 'MARKING';
const QUALITY_CONTROL       = 'QUALITY_CONTROL';
const VALIDATION            = 'VALIDATION';

/**
 * 
 */
export default class ConstantAction{
    static get AVAILABLE(){
        return AVAILABLE;
    }
    static get CONSOLIDATION(){
        return CONSOLIDATION;
    }
    static get EDITION(){
        return EDITION;
    }
    static get MARKING(){
        return MARKING;
    }
    static get QUALITY_CONTROL(){
        return QUALITY_CONTROL;
    }
    static get VALIDATION(){
        return VALIDATION;
    }
}