<template>
    <input type="file" 
        id="file" 
        ref="file" 
        @change="handleFileUpload()" 
        required
        class="input-file"
        />
</template>
<script>
import axios    from 'axios';
/**
 * Generic component to handle the file upload
 * @author Sébastien De Santis
 * @version 1.0
 * @date 6/3/2020
 */
export default {
    methods:{
        /**
         * This method affect the first file of the FileList to the data file of the component.
         */
        handleFileUpload(){
            this.$emit('setFile', this.$refs.file.files[0]);
        }
    }
}
</script>
<style scoped>
.input-file{
    padding: 5px;
}
</style>