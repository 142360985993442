<template >
    <div>
        <v-tabs v-model="active"
                color="primary"
                dark
                right
                slider-color="white">
            <v-tab ripple>
                {{ $t('document.paoDocument.linkPao') }}
            </v-tab>
            <v-tab ripple>
                {{ $t('document.paoDocument.linkedPao') }}
            </v-tab>
            <v-tab-item>
                <v-layout v-if="displaySpinner" align-center justify-center row>
                    <v-progress-circular
                        :size="70"
                        :indeterminate="true"
                        color="primary"
                    ></v-progress-circular>
                    
                </v-layout>
                <pao-list-tab-pao v-if="!displaySpinner" :paoList="paoList" :pageable="pageable" @changePage="changePage" @linkToPao="openPaoStructure"/>
            </v-tab-item>
            <v-tab-item>
                <pao-list-tab-linked-pao :paoDocumentList="paoDocumentList" @unlinkPao="unlinkPao"/>
            </v-tab-item>
        </v-tabs>

        <dialog-pao-structure v-if="!!selectedPao" :structure="selectedPao.structure" :readOnly="false" :dialog.sync="openStructureDialog" @linkPaoNode="linkToPao"/>

    </div>
</template>
<script>
import axios                                from 'axios';
import ConstantEvent                        from '../../../shared/constant/constantEvent';
import DialogPaoStructure                   from './DialogPaoStructure';
import EventBus                             from '../../../utils/event-bus';
import Namespace                            from '../../../shared/constant/store/module/namespace.js';
import paoListTabLinkedPao                  from '../link/PaoListTabLinkedPao';
import PaoListTabPao                        from '../link/PaoListTabPao';
import PaoStructureStoreTypes               from '../../../shared/constant/store/module/paoStructureTypes';
import StoreModule                          from '../../../shared/constant/store/module/namespace';
import { mapState, mapGetters, mapActions } from 'vuex';

/**
 * Display a pao list from a search, dispalyed  as a table.
 * 
 * @author Cédric de BOISVILLIERS, SFEIR benelux
 * @version 1.0
 * @since 17/05/2019
 */
export default {
    name:'PaoListTabs',
    components : {
        DialogPaoStructure,
        PaoListTabPao,
        paoListTabLinkedPao
    },
    props: {
        paoList: {
            default: () => []
        }
    },
     data(){
        return{
            active: null,
            displaySpinner: false,
            documentList: [],
            idDocument: null,
            paoDocumentList: [],
            selectedPao: null,
            openStructureDialog: false
        }
     },
    computed: {
        ...mapState(StoreModule.PAO_FILTER, {
            pageable: state => state.pageable
        }),
        ...mapGetters(StoreModule.PAO_STRUCTURE, {
            clickedNodeRootByDefault: PaoStructureStoreTypes.GETTER.DEFAULT_CLICKED_NODE
        })
    },
    created(){
        this.idDocument = parseInt(this.$route.params.idDocument);
        this.loadPaoDocumentList();
    },
    methods:{
        /**
         * Initialize a pao structure.
         */
        ...mapActions(Namespace.PAO_STRUCTURE, {
            initStructureStore: PaoStructureStoreTypes.ACTION.INIT
        }),
        /**
         * Load all the pao
         */
        loadPaoDocumentList(){
            axios.post(
                `pao/document/filter`,
                {idDocument: this.idDocument},
                {params: {page: 1, size: 100, direction: 'ASC', property: "id"}}//TODO remove pageable on this endpoint or implements pagination on component to display this list
            ).then( ({data}) => {
                this.paoDocumentList = data.content;
            }).catch(() => this.$router.back());
        },
        /**
         * change page and navigate to the selected page
         * @param page
         */
        changePage(page){
            this.$emit('changePage', page);
        },
        /**
         * Set the pao structure.
         */
        openPaoStructure(paoTemp){

            axios.get(`pao/${paoTemp.id}/display`)
                .then( ({data}) => {
                    this.initStructureStore(data.structure);
                    this.selectedPao = data;
                    this.openStructureDialog = true;
                });


        },
        linkToPao(paoNode){

            axios.post(
                'pao/document',
                {
                    pao:{id: this.selectedPao.id},
                    documentId:  this.idDocument,
                    rank: 0,
                    paoNode: {id: paoNode.id}
                }
            ).then( () => {
                this.loadPaoDocumentList();
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    message: 'document.notification.paoDocument.linkPao',
                    type: 'SUCCEED'
                });
            });
        },
        unlinkPao(){
            
        }
    }
}
</script>

<style scoped>

</style>