<template>
    <v-layout align-space-start justify-start row fill-height>

        <v-flex class="container_filter_documents">
            <list-link :hyperlink="hyperlink" @scroll="scrollToClickedLinkById"></list-link>
        </v-flex>
        <v-flex v-if="rootNode" id="container_content" @contextmenu="show" @click="cancelHyperlinkVisualization">
            <overview-document-node :rootNode="rootNode.children"/>
        </v-flex>

        <v-menu v-model="showMenu"
                :position-x="x"
                :position-y="y"
                absolute
                offset-y >
            <v-list>
                <!-- CONTEXT MENU TO ADD LINK -->
                <v-list-tile v-if="isText"
                    @click="addExternalHyperLink">
                    <v-list-tile-title>{{ $t('document.action.addExternalLink') }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile v-if="isText"
                    @click="addDocumentHyperLink(DOCUMENT_HYPERLINK_TYPE)">
                    <v-list-tile-title>{{ $t('document.action.addDocumentLink') }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile v-if="isText"
                    @click="addDocumentHyperLink(CONTENT_HYPERLINK_TYPE)">
                    <v-list-tile-title>{{ $t('document.action.addDocumentNodeLink') }}</v-list-tile-title>
                </v-list-tile>
                <!-- CONTEXT MENU TO VIEW OR DELETE A LINK -->
                <v-list-tile v-if="isLink && isSearchableLink"
                    @click="viewLink">
                    <v-list-tile-title>{{ $t('document.action.viewHyperLink') }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile v-if="isLink"
                    @click="deleteLink">
                    <v-list-tile-title>{{ $t('document.action.deleteHyperLink') }}</v-list-tile-title>
                </v-list-tile>
            </v-list>
        </v-menu>
        <edition-action-panel @save="saveDocument" @cancel="goBack" />
        <dialog-external-hyperlink :dialog.sync="externalHyperDialog" :hyperLinkType="typeHyperLink" />
        <dialog-document-hyper-link :dialog.sync="documentHyperDialog" :documentId="parseInt(this.$route.params.id)" :hyperLinkType="typeHyperLink" @documentChosen="documentChosen"/>
        <dialog-document-node-hyper-link :dialog.sync="documentContentHyperLinkDialog" @closeDocumentNode="closeDocumentNode"/>
        <dialog-view-a-link v-if="!!viewId" :dialog.sync="viewALinkDialog" :viewId="viewId" :viewNodeId="viewNodeId" onClose="onClose"></dialog-view-a-link>
        <dialog-error/>
    </v-layout>
</template>
<script>
import { mapState, mapActions}          from 'vuex';
import { documentLockMixin }            from '../../../mixins/documentLockMixin';
import EventBus                         from '../../../utils/event-bus.js';
import * as $                           from 'jquery';
import OverviewDocumentNode             from '../overview/OverviewDocumentNode';
import DialogExternalHyperlink          from '../link/dialog/DialogExternalHyperlink';
import DialogError                      from '../link/dialog/DialogError';
import ConstantHyperLink                from '../../../shared/constant/constantDocumentHyperLink.js';
import ConstantDocumentNode             from '../../../shared/constant/constantDocumentNode.js';
import ConstantEvent                    from '../../../shared/constant/constantEvent.js';
import ConstantTag                      from '../../../shared/constant/constantTag.js';
import ConstantNotification             from '../../../shared/constant/constantNotification.js';
import CheckSelectionService            from '../../../service/document/checkDocumentNodeSelectionService.js';
import axios                            from 'axios';
import DialogDocumentHyperLink          from '../link/dialog/DialogDocumentHyperLink';
import DialogDocumentNodeHyperLink      from '../link/dialog/DialogDocumentNodeHyperLink';
import DialogViewALink                  from '../link/dialog/DialogViewALink';
import ListLink                         from '../link/component/ListLink.vue';
import EditionActionPanel               from '../../shared/EditionActionPanel';

const ORIGIN_URL_NODE_DOCUMENT  = window.location.origin + '/(.*)/(.*)';
const ORIGIN_URL_DOCUMENT       = window.location.origin + '/(.*)/';
/**
 * TODO: change description to match the component's behaviour
 * Create or Edit a Document
 * 
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 24/10/2018
 */
export default {
    name: 'HyperLinkManagement',
    mixins: [documentLockMixin],
    data () {
        return {
            showMenu: false,
            checkedSelection: {},
            isDocumentReady: false,
            nodeId: null,
            linkId: null,
            viewId: null,
            viewNodeId: null,
            externalURL: null,
            className: null,
            x: 0,
            y: 0,
            isLink: false,
            isSearchableLink: false,
            isText: false,
            selectedText: null,
            selectedNode: null,
            caretPosition: {
                clickedNode: null,
                clickedNodeOffset: 0,
                clickedFocusNodeOffset: 0,
                focusNode: null
            },
            typeHyperLink: null,
            chosenTitleDocumentDestination: null,
            referenceDocumentDestination: null,
            externalHyperDialog: false,
            documentHyperDialog: false,
            documentContentHyperLinkDialog: false,
            viewALinkDialog: false,
            DOCUMENT_HYPERLINK_TYPE: ConstantHyperLink.DOCUMENT_HYPERLINK_TYPE,
            CONTENT_HYPERLINK_TYPE: ConstantHyperLink.CONTENT_HYPERLINK_TYPE,
            hyperlink: {
                content: null,
                document: null,
                external: null
            },
            documentMarkets: null
        }
    },
    /**
     * Load the document content at the creation of the component and lock it
     */
    created(){
        this.loadDocumentContent({id: parseInt(this.$route.params.id), toLock: true})
            .then( this.isDocumentReady = true)
            .catch(() => this.$router.back());
    },
    updated(){
        if(this.isDocumentReady){
            this.$nextTick(() => {
                this.createHyperlinkList();
            })
        }
    },
    computed: {
        ...mapState({
            rootNode: state => state.documentContent.rootNode,
            documentHyperLink: state => state.hyperLinkStore.documentHyperLink,
            sourceNode: state => state.hyperLinkStore.sourceNode,
            documentId: state => state.documentContent.documentId
        }),
    },
    methods: {
        ...mapActions({
            loadDocumentContent: 'documentContent/loadDocumentContent',
            updateNodeContent: 'documentContent/modifyContent',
            initSourceNode: 'hyperLinkStore/initSourceNode',
            findNodeById: 'documentContent/findNodeById',
            updateHyperLinkType: 'hyperLinkStore/updateHyperLinkType',
            loadDocumentLinkList : 'documentContent/loadDocumentLinkList',
            resetDocumentFilter: 'documentFilterStore/resetDocumentFilter'
        }),
        /**
         * Research in the whole document different type of hyperlink
         */
        createHyperlinkList(){
            this.hyperlink.content = $("#container_content").find(`.${ConstantHyperLink.CONTENT_HYPERLINK_CLASS}`).toArray();
            this.hyperlink.document = $("#container_content").find(`.${ConstantHyperLink.DOCUMENT_HYPERLINK_CLASS}`).toArray();
            this.hyperlink.external = $("#container_content").find(`.${ConstantHyperLink.EXTERNAL_HYPERLINK_CLASS}`).toArray();
        },
        /**
         * the method show receive a mouseEvent who contains all lot of data about the selected text
         * the method allow the user to open a context menu at the position of the cursor
         * @param {*} event
         */
        show (event) {
            // loop on the Array 'path' of the mouse event to find the id of the node 
            this.viewId = null;
            this.viewNodeId = null;
            this.isLink = false;
            this.isSearchableLink = false;
            this.isText = false;
            event.composedPath().forEach(element => {
                if(element.nodeName === ConstantTag.SPAN && element.className === 'bol-id-node'){
                    this.nodeId = element.id;
                }
            });
            this.isALink(event);
            // display the context menu
            event.preventDefault();
            this.showMenu = false;
            this.x = event.clientX;
            this.y = event.clientY;
            this.$nextTick(() => {
                this.showMenu = true;
            })
        },
        /**
         * Open the page of the document / page of the link 
         */
        viewLink(){
            if( !!this.viewId ){
                this.viewALinkDialog = true;
            } else {
                if( !!this.externalURL){
                    window.open(this.externalURL, '_blank');
                }
            }
        },
        /**
         * Come back from the view a link component and reset data necessary for the loading of the document
         */
        onClose(){
            this.viewId = null;
            this.viewNodeId = null;
        },
        /**
         * Delete the link in the document
         */
        deleteLink(){
            let contain = $('#'+this.linkId);
            contain.contents().unwrap();
            let container = $('#'+this.nodeId)[0].innerHTML;
            this.updateNodeContent({ id: this.nodeId, content: container });
            this.createHyperlinkList();
        },
        /**
         * Thanks to the event, we know if the click is on a link and if it's a link
         * we know which link is clicked and adapt the action 
         */
        isALink(event){
            if (event.target.nodeName === 'A'){
                this.linkId = event.target.id;
                let className = event.target.className.split(' ');
                this.isLink = true;
                if(className.includes(ConstantHyperLink.CONTENT_HYPERLINK_CLASS)){
                    var test = event.target.href.match(ORIGIN_URL_NODE_DOCUMENT);
                    this.viewId = test[1];
                    this.viewNodeId = test[2];
                    this.isSearchableLink = true;
                } else if (className.includes(ConstantHyperLink.DOCUMENT_HYPERLINK_CLASS)){
                    var test = event.target.href.match(ORIGIN_URL_DOCUMENT);
                    this.viewId = test[1];
                    this.isSearchableLink = true;
                } else if (className.includes(ConstantHyperLink.EXTERNAL_HYPERLINK_CLASS) || className.includes('EXTERNAL_HYPERLINK')){
                    this.externalURL = event.target.href;
                    this.isSearchableLink = true;
                } 
            } else {
                let selection = window.getSelection();
                if(String(selection.toString()).length > 0){
                    this.checkedSelection = CheckSelectionService.isViabilitySelection( selection );
                    if(this.checkedSelection.isCreatable){
                        this.caretPosition = CheckSelectionService.calculatePosition( selection, this.checkedSelection );

                        var range = window.getSelection().getRangeAt(0);
                        var content = range.cloneContents();
                        var span = document.createElement('SPAN');

                        span.appendChild(content);
                        var htmlContent = span.innerHTML;

                        this.selectedText = htmlContent;
                        this.selectedNode = this.$getParentElement( selection );
                        
                        this.isText = true;
                    } else{
                        EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                            message: 'document.error.badSelectionLink',
                            type: 'INFO'
                        });
                    }
                }   
            }
        },
        /**
         * Add external Hyperlink
         */
        addExternalHyperLink(){
            if ( !!this.selectedText ){ 
                this.initHyperLinkStore(ConstantHyperLink.EXTERNAL_HYPERLINK_TYPE);
                this.externalHyperDialog = true;
            }
            this.resetData();
        },
        /**
         * Add hyperlink to a document or a node document
         */
        addDocumentHyperLink(hyperLinkType){
            this.typeHyperLink = hyperLinkType;
            this.initHyperLinkStore(hyperLinkType);
            this.documentHyperDialog = true;
        },
        /**
         * Intialize the hyperlink store with the setted value
         */
        initHyperLinkStore( hyperLinkType ){
            this.initSourceNode({ 
                nodeId: this.nodeId,
                selectedContent: this.selectedText, 
                selectedNode: this.selectedNode,
                hyperLinkType: hyperLinkType, 
                clickedNode: this.caretPosition.clickedNode,
                clickedNodeOffset: this.caretPosition.clickedNodeOffset,
                clickedFocusNodeOffset: this.caretPosition.clickedFocusNodeOffset,
                focusNode: this.caretPosition.focusNode,
                isCrossSelection: this.caretPosition.isCrossSelection
            });
        },
        /**
         * If the user close the dialog for the selection of a node the dialog to select a document is reopen
         */
        closeDocumentNode(){
            this.typeHyperLink = ConstantHyperLink.CONTENT_HYPERLINK_TYPE;
            this.updateHyperLinkType({ typeHyperlink: ConstantHyperLink.CONTENT_HYPERLINK_TYPE});
            this.documentHyperDialog = true;
            this.createHyperlinkList();
        },
        /**
         * For a hyperlink of content we need to keep the reference and his title to display him in another
         * dialog open in this method and let the user chose the node he want to link
         */
        documentChosen( data ){
            this.documentContentHyperLinkDialog = true;
            EventBus.$emit(ConstantEvent.OPEN_DIALOG_DOCUMENT_NODE_HYPERLINK, {
                referenceDocumentDestination: data.referenceDocumentDestination,
                chosenTitleDocumentDestination: data.chosenTitleDocumentDestination
            });
            this.createHyperlinkList();
        },
        /**
         * Reset data
         */
        resetData() {
            this.nodeId= null;
            this.selectedText= null;
            this.caretPosition.clickedNode = null;
            this.clickedNodeOffset = 0;
            this.clickedFocusNodeOffset = 0;
            this.typeHyperLink = null;
        },
        /**
         * Cancel the left click on the link 
         */
        cancelHyperlinkVisualization(event) {
            event.preventDefault();
        },
        /**
         * Save the document content
         */
        saveDocument(){
            axios
                .put("/content/save",{documentId: this.documentId, content:this.rootNode})
                .then(response => {
                    let notification = {
                        type: ConstantNotification.SUCCEED,
                        message: 'document.notification.content.save.succeed',
                    };
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
                });
            this.goBack();
        },
        /**
         * Scroll to the selected node
         * @param selectedNode
         */
        scrollToClickedLinkById( clickedLinkId ) {
            let container = $('#container_content');
            let anchorReferenceElement = $( `#${clickedLinkId}` );
            if( !!anchorReferenceElement && !!anchorReferenceElement.offset()){
                container.animate({
                    scrollTop: anchorReferenceElement.offset().top - container.offset().top + container.scrollTop()
                });
            }

        },
        goBack() {
            this.$router.go(-1);
        },
        $getParentElement(selection) {
            let element = selection.anchorNode.parentElement;
            while ( element.className !== ConstantDocumentNode.TEXT_NODE_CLASS ){
                element = element.parentElement;
            }
            let text = element.innerText;
            return text;
        }
    },
    beforeDestroy(){
        this.resetDocumentFilter();
    },
    watch: {
        documentHyperDialog() {
            if( !this.documentHyperDialog ) {
                this.resetData();
            } 
        },
        documentContentHyperLinkDialog(){
            if( !this.documentContentHyperLinkDialog ){
                this.resetData();
            }
        }
    },
    components: {
        OverviewDocumentNode,
        DialogExternalHyperlink,
        DialogError,
        DialogDocumentHyperLink,
        DialogDocumentNodeHyperLink,
        DialogViewALink,
        ListLink,
        EditionActionPanel
    }
}
</script>
<style scoped>
#container_content{
    overflow-y: auto;
    height:calc(100vh - 48px);
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 40px;
    text-align: left;
}
.container_filter_documents{
    min-width: 300px;
    max-width: 300px;
    overflow-x: auto;
    height: calc(100vh - 48px);
    background-color: #355B7F;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
}
.floating_button_settings{
    position: fixed;
    top: 30px;
    right: 0;
    padding-top: 40px;
}
.margin-button-settings{
    margin-top:5px;
}
.btn-save{
    display:inline-block;
    margin-top:20px;
    width:45px;
    height:45px;
    margin-right:10px;
    margin-left:10px;
}
</style>
