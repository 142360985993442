<template>
    <div class="container_filter_documents">
        <h2 class="title_color">
            {{ $t('document.label.search') }}
        </h2>

        <!-- FIRST LINE -->
        <v-flex xs12>
            <v-text-field
                v-model="reference"
                :label="$t('document.label.reference')"
                clearable
                dark
                @keyup.enter="search()"
            />
        </v-flex>
        <v-flex xs12>
            <v-text-field
                v-model="title"
                :label="$t('document.label.title')"
                clearable
                dark
                @keyup.enter="search()"
            />
        </v-flex>
        <!-- USER -->
        <v-flex xs12>
            <v-autocomplete
                v-model="userId"
                :label="$t('document.label.user')"
                :items="userList"
                :item-text="userLastNameFirstName"
                item-value="id"
                :loading="displaySpinner"
                dark
                @keyup.enter="search()"
            />
        </v-flex>
        <v-flex xs12>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
                dark
            >
                <v-text-field
                    slot="activator"
                    v-model="dateFormatted"
                    :label="$t('document.label.documentDate')"
                    prepend-icon="event"
                    clearable
                    dark
                    @keyup.enter="search()"
                />
                <v-date-picker
                    v-model="documentDate"
                    :min="'1800-01-01'"
                    dark
                    :locale="language"
                    @input="menu = false"
                />
            </v-menu>
        </v-flex>
        <!-- SECOND LINE -->
        <v-flex xs12>
            <v-select
                v-model="workspaceId"
                :items="workspaceList"
                :label="$t('document.label.workspace')"
                item-value="id"
                item-text="name"
                clearable
                :loading="displaySpinner"
                dark
                @keyup.enter="search()"
            />
        </v-flex>
        <v-flex xs12>
            <v-select
                v-model="step"
                :items="stepListTranslate"
                :label="$t('document.label.step')"
                item-value="value"
                item-text="trad"
                clearable
                :loading="displaySpinner"
                dark
                @keyup.enter="search()"
            />
        </v-flex>
        <v-flex xs12>
            <v-select
                v-model="status"
                :items="statusListTranslate"
                :label="$t('document.label.status')"
                item-value="value"
                item-text="trad"
                clearable
                :loading="displaySpinner"
                dark
                @keyup.enter="search()"
            />
        </v-flex>
        <v-flex>
            <v-select
                v-model="documentTypeId"
                :items="documentTypeList"
                item-value="id"
                item-text="label"
                :label="$t('document.label.type')"
                clearable
                :loading="displaySpinner"
                dark
                @keyup.enter="search()"
            />
        </v-flex>

        <!-- DOMAINS -->
        <v-flex>
            <v-autocomplete
                v-model="domainsSelectedList"
                :label="$t('document.create.domains')"
                :multiple="true"
                :chips="true"
                :items="domainList"
                :loading="displaySpinner"
                item-value="id"
                item-text="label"
                :dark="true"
            />
        </v-flex>
        <!-- NATURE -->
        <v-autocomplete
            v-if="documentTypeId === 100"
            v-model="natureId"
            :label="$t('document.create.nature')"
            :items="natureList"
            :loading="displaySpinner"
            item-text="label"
            item-value="id"
            :dark="true"
        />

        <!-- JURISDICTION -->
        <v-autocomplete
            v-if="documentTypeId === 101"
            v-model="jurisdictionId"
            :label="$t('document.create.jurisdiction')"
            :items="jurisdictionList"
            :loading="displaySpinner"
            item-text="label"
            item-value="id"
            :dark="true"
        />

        <!-- BUTTONS -->
        <v-flex
            xs48
            sm24
            md12
            text-xs-right
        >
            <span
                class="search_data"
                @click="search()"
            >{{ $t('document.label.searchDataSheet') }}</span>
            <v-btn
                fab
                dark
                small
                color="white"
                @click.native="search()"
            >
                <v-icon color="primary">
                    search
                </v-icon>
            </v-btn>
        </v-flex>
        <v-flex
            xs48
            sm24
            md12
            text-xs-right
        >
            <div v-if="!isLink">
                <div>
                    <span class="new_datasheet_btn">{{ $t('document.label.newDataSheet') }}</span>
                    <v-btn
                        slot="activator"
                        color="white"
                        small
                        fab
                        dark
                        :to="{name:'DatasheetAdd' , params:{ title: $t('document.label.newDataSheet')}}"
                    >
                        <v-icon color="primary">
                            add
                        </v-icon>
                    </v-btn>
                </div>
            </div>
        </v-flex>
        <v-flex
            xs48
            sm24
            md12
            text-xs-right
        >
            <div v-if="!isLink">
                <div>
                    <span
                        class="new_datasheet_btn"
                        @click="exportFunds()"
                    >{{ $t('document.label.exportFund') }}</span>
                    <v-btn
                        slot="activator"
                        color="white"
                        small
                        fab
                        dark
                        @click.native="exportFunds()"
                    >
                        <v-icon color="primary">
                            file_download
                        </v-icon>
                    </v-btn>
                </div>
            </div>
        </v-flex>
    </div>
</template>
<script>
import axios from 'axios'
import EventBus from '../../../utils/event-bus';
import ConstantEvent from '../../../shared/constant/constantEvent.js'
import ConstantHyperLink from '../../../shared/constant/constantDocumentHyperLink.js';
import {mapState, mapActions} from 'vuex';

/**
 *
 * Document search component.
 * @author DE SANTIS Sébastien, SFEIR benelux
 * @version 1.0
 * @since 13/09/2018
 *
 */
export default {
    name: "DocumentFilter",
    props: ['hyperLinkType', 'isLink', "newSearch"],
    data() {
        return {
            displaySpinner: false,
            menu: false,
            statusList: [],
            documentTypeList: [],
            workspaceList: [],
            dateFormatted: null,
            documentDate: null,
            stepList: [],
            domainList: [],
            natureList: [],
            jurisdictionList: [],
            userList: []
        }
    },
    created() {
        if (!!this.hyperLinkType || this.newSearch) {
            this.resetStore();
        }
    },
    computed: {
        ...mapState({
            filter: state => state.documentFilterStore.filter,
            language: state => state.userStore.userPreferences.preferredLanguage,
        }),
        statusListTranslate() {
            let statusListTranslated = [];
            this.statusList.forEach(status => {
                statusListTranslated.push({value: status, trad: this.$t(`document.label.${status}`)});
            });
            return statusListTranslated;
        },
        stepListTranslate() {
            let stepListTranslated = [];
            this.stepList.forEach(step => {
                stepListTranslated.push({value: step, trad: this.$t(`document.label.${step}`)});
            })
            return stepListTranslated;
        },
        reference: {
            get() {
                return this.$store.state.documentFilterStore.filter.reference
            },
            set(value) {
                this.updateReference(value);
            }
        },
        title: {
            get() {
                return this.$store.state.documentFilterStore.filter.title
            },
            set(value) {
                this.updateTitle(value);
            }
        },
        userId: {
            get() {
                return this.$store.state.documentFilterStore.filter.userId
            },
            set(value) {
                this.updateUserId(value);
            }
        },
        workspaceId: {
            get() {
                return this.$store.state.documentFilterStore.filter.workspaceId
            },
            set(value) {
                this.updateWorkspaceId(value);
            }
        },
        status: {
            get() {
                return this.$store.state.documentFilterStore.filter.status
            },
            set(value) {
                this.updateStatus(value);
            }
        },
        step: {
            get() {
                return this.$store.state.documentFilterStore.filter.step
            },
            set(value) {
                this.updateDocumentStep(value);
            }
        },
        documentTypeId: {
            get() {
                return this.$store.state.documentFilterStore.filter.documentTypeId
            },
            set(value) {
                this.updateDocumentTypeId(value);
                //resets filters of nature and jurisdiction when needed
                if (value === 101) this.resetNatureId();
                else if (value === 100) this.resetJurisdictionId();
                else {
                    this.resetJurisdictionId();
                    this.resetNatureId();
                }
            }
        },
        natureId: {
            get() {
                return this.$store.state.documentFilterStore.filter.nature;
            },
            set(value) {
                this.updateNatureId(value);
            }
        },
        jurisdictionId: {
            get() {
                return this.$store.state.documentFilterStore.filter.jurisdiction;
            },
            set(value) {
                this.updateJurisdictionId(value);
            }
        },
        domainsSelectedList: {
            get() {
                return this.$store.state.documentFilterStore.filter.domains;
            },
            set(value) {
                this.updateDomains(value);
            }
        },
    },
    watch: {
        documentDate(value) {
            this.updateDocumentDate(value);
            this.dateFormatted = this.formatDate(this.filter.documentDate)
        },
        /**
     * if the text field is reset the value in the store is reset too
     */
        dateFormatted() {
            if (!this.dateFormatted) {
                this.updateDocumentDate(null);
            }
        }
    },
    mounted() {
        if (!!this.filter.documentDate) {
            this.dateFormatted = this.formatDate(this.filter.documentDate)
        }
        this.displaySpinner = true,
        axios(
            {
                method: "GET",
                url: "document/search/init",
            }
        ).then(({data}) => {
            this.displaySpinner = false;
            this.statusList = data.documentStatusList;
            this.documentTypeList = data.documentTypeList;
            this.workspaceList = data.workspaceList;
            this.stepList = data.documentSteps;
            this.userList = data.userList;
        });
        this.initJurisdictionList();
        this.initDomainList();
        this.initNatureList();
    },
    methods: {
        ...mapActions('documentFilterStore', [
            'resetDocumentFilter',
            'setDocumentFilter',
            'updateDocumentDate',
            'updateReference',
            'updateTitle',
            'updateWorkspaceId',
            'updateStatus',
            'updateDocumentTypeId',
            'updateUserId',
            'updateDocumentStep',
            'resetJurisdictionId',
            'resetNatureId',
            'updateNatureId',
            'updateJurisdictionId',
            'updateDomains'
        ]
        ),
        search() {
            if (this.hyperLinkType == ConstantHyperLink.DOCUMENT_HYPERLINK_TYPE || this.hyperLinkType == ConstantHyperLink.CONTENT_HYPERLINK_TYPE) {
                EventBus.$emit(ConstantEvent.DOCUMENT_HYPERLINK_LIST, this.filter);
            } else if (this.hyperLinkType == ConstantHyperLink.RESUME_TO_LINK) {
                EventBus.$emit(ConstantEvent.DOCUMENT_LIST, this.filter);
            } else {
                EventBus.$emit(ConstantEvent.FILTER_DOCUMENT, this.filter);
            }
        },
        formatDate(documentDate) {
            if (!documentDate) return null;

            const [year, month, day] = documentDate.split('-');
            if (this.language === 'fr-FR') {
                return `${day}/${month}/${year}`;
            } else if (this.language === 'en-EN') {
                return `${month}/${day}/${year}`;
            }
        },
        resetStore() {
            this.updateReference(null);
            this.updateTitle(null);
            this.updateWorkspaceId(null);
            this.updateStatus(null);
            this.updateDocumentStep(null);
            this.updateDocumentTypeId(null);
            this.updateDocumentDate(null);
        },
        initJurisdictionList() {
            axios({
                method: "GET",
                url: "administration/jurisdiction/"
            }).then(({data}) => {
                this.jurisdictionList = data;
            });
        },
        initDomainList() {
            axios({
                method: "GET",
                url: "administration/domain/"
            }).then(({data}) => {
                this.domainList = data;
            });
        },
        initNatureList() {
            axios({
                method: "GET",
                url: "administration/nature/"
            }).then(({data}) => {
                this.natureList = data;
            });
        },
        userLastNameFirstName: item => item.lastName + " " + item.firstName,
        forceFileDownload(response, title) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
        },
        exportFunds() {
            axios({
                method: 'get',
                url: '/document/export/fonds',
                responseType: 'arraybuffer',
            }).then((response) => {
                this.forceFileDownload(response, 'documentFund.xlsx')
            }).catch(()=> console.log('Error downloading excel file'));
        }
    }
}
</script>
<style scoped>
.container_filter_documents {
  height: 100%;
  background-color: #355B7F;
  padding-top: 150px;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 1000px;
}

.title_color {
  color: aliceblue;
}

.new_datasheet_btn {
  cursor: pointer;
  color: white;
  font-weight: bold;
}

.btn_export {
  float: right;
  width: 180px;
  height: 50px;
  margin-top: 10px;

}

.search_data {
  cursor: pointer;
  color: white;
  font-weight: bold;
}
</style>
