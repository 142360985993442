<template>
    <v-layout align-center justify-center class="margin_status">
        <v-flex xs8 class="text-white">
            <v-layout row justify-space-between >
                <v-flex>
                    <h2 class="under-text" >{{$t(`document.label.statusstate`)}}</h2>
                    <h4>{{$t(`document.label.${status}`)}}</h4>
                </v-flex>
                <v-flex>
                    <h2 class="under-text" >{{$t(`document.label.stepstate`)}}</h2>
                    <h4>{{$t(`document.label.${step}`)}}</h4>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex xs2>
            <v-tooltip top>
                <v-btn color="red" fab small class="btn-save" slot="activator" @click="deleteVersion()" >
                    <v-icon color="white" small>delete_forever</v-icon>
                </v-btn>
                <span>{{ $t('global.action.deleteVersion') }}</span>
            </v-tooltip>
        </v-flex>
         <v-flex xs2 align-end>
            <v-tooltip top>
                <v-btn color="white" fab small class="btn-save" slot="activator" @click="duplicateDocument()" >
                    <v-icon color="primary" small>control_point_duplicate</v-icon>
                </v-btn>
                <span>{{ $t('global.action.duplicateDocument') }}</span>
            </v-tooltip>
        </v-flex>
        <dialog-confirmation :dialog.sync="openConfirmation" :message="message" @confirm="isConfirm" />
    </v-layout>
</template>

<script>
import { mapState }             from 'vuex'; 
import DialogConfirmation       from '../../../shared/DialogConfirmation';
import axios                    from 'axios';
import EventBus                 from '../../../../utils/event-bus.js';
import ConstantEvent            from '../../../../shared/constant/constantEvent.js';
/**
 * 
 * Display the current step and status of the document
 * 
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 25/06/2019
 */
export default {
    name: 'StepAndStatusDisplay',
    props: {
        datasheet: {
            default: null,
        }
    },
    data: () => ({
        publishedLogList: [],
        openConfirmation: false,
        message: '',
    }),
    computed: {
        ...mapState({
            status: state => state.dataSheetStore.metaDocument.documentStatus,
            step: state => state.dataSheetStore.metaDocument.documentStep,
            idDocument: state => state.dataSheetStore.metaDocument.currentVersion.documentId
        })
    },
    methods: {
        /**
         * try to delete the actual version of the document 
         * the version can only be deleted if this version has never been published
         * and if it's the last version of the document.
         */
        deleteVersion(){
            axios.get(`logs/document/${this.datasheet.currentVersion.documentId}/publication`).then( ({data}) => {
                this.openConfirmation = true;
                this.publishedLogList = data;
                if( this.publishedLogList.length > 0 ){
                    this.message = 'document.action.deleteVersionImpossible';
                } else {
                    this.message = 'document.action.deleteVersionPossible';
                }
            });

            this.openConfirmation = true;
        },
        /**
         * If the version has not been published and we are sure we want
         * to delete the version, the version will be delete it in database.
         */
        isConfirm(){
            if(this.publishedLogList.length === 0){
                axios.delete(`document/${this.datasheet.currentVersion.documentId}`).then( ({data}) => {
                    this.$router.go(-1);
                });
            }
        },
        /**
         * Duplicate the current document to create a new one with the current document has base.
         */
        duplicateDocument(){
            axios.get(`document/${this.idDocument}/duplicate`)
                .then( ({data}) => {
                    this.$router.replace({name:'details', params: { reference: data.reference, title: data.title}})
                    EventBus.$emit(ConstantEvent.RELOAD_DOCUMENT, data.reference)
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                        message: 'document.action.successfulDuplication',
                        type: 'SUCCEED'
                    });
                }, () => {
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                        message: 'document.action.unsuccessfulDuplication',
                        type: 'ERROR'
                    });
                });
        }
    },
    components: {
        DialogConfirmation
    }
    
}
</script>

<style scoped>
.text-white{
    color:white;
}
.margin_status{
    margin-bottom:10%;
}
.under-text{
    text-decoration: underline;
}
</style>
