<template>
    <v-dialog v-model="openDialog" fullscreen persistent>
        <v-card>
            <v-layout row fill-height >
                <v-flex class="filter_sizer" >
                    <document-filter :isLink="true" :hyperLinkType="linkType" />
                </v-flex>
                <v-layout column fill-height>
                    <div>
                        <v-radio-group v-model="radioGroupRelationType" class="radio-group">
                            <h4> {{ $t('pao.summary.jurisprudence-link')}} </h4><br>
                            <v-radio @change="setDocumentTypeId(JURISPRUDENCE_ID)" :label=" $t('pao.relationtype.contrary') " :value="CONTRARY" />
                            <v-radio @change="setDocumentTypeId(JURISPRUDENCE_ID)" :label=" $t('pao.relationtype.same') " :value="SAME" />
                            <v-radio @change="setDocumentTypeId(JURISPRUDENCE_ID)" :label=" $t('pao.relationtype.following') " :value="FOLLOWING" />
                            <v-radio @change="setDocumentTypeId(null)" :label=" $t('pao.relationtype.none') " :value="NONE" />
                        </v-radio-group>
                    </div>
                    <v-flex class="document_hyperlink_sizer" >
                        <Documents-to-link @onSelectDocument="onSelectDocument"/>
                        <div @click="resetComponent" class="backtodoc-search">
                            <bol-back-button :textBackButton="backToDocument" />
                        </div>
                    </v-flex>
                </v-layout>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
import DocumentFilter           from '../../search/DocumentFilter';
import DocumentsToLink    from '../DocumentsToLink';
import ConstantHyperLink            from '../../../../shared/constant/constantDocumentHyperLink.js';
import {  mapActions,mapState }     from 'vuex';
import ConstantEvent from "../../../../shared/constant/constantEvent";
import EventBus      from '../../../../utils/event-bus';
import ConstantRelationType from "../../../../shared/constant/constantRelationType";
import ConstantDocumentType from "../../../../shared/constant/constantDocumentType";
import BolBackButton from "../../../shared/BolBackButton";

const UPDATE_DIALOG = 'update:dialog';
/**
 * 
 * Dialog who display the document list letting the user
 * choose the document he want to link
 *
 * @author Gentilhomme Romain, SFEIR benelux
 * @version 1.0
 * @since 07/01/2020
 * 
 */
export default {
    name: "DialogSearchDocumentToLink",
    props: {
        dialog: {
            default: false
        }
    },
    data() {
        return {
            referenceDocumentDestination: null,
            chosenTitleDocumentDestination: null,
            radioGroupRelationType:ConstantRelationType.NONE,
        }
    },
    computed:{

        openDialog: {
            get() {
                return this.dialog;
            },
            set(dialog) {
                if(!dialog) {
                    this.$emit(UPDATE_DIALOG, false);
                }
            }
        },
        linkType : {
            get() {
                return ConstantHyperLink.RESUME_TO_LINK;
            },
        },
        CONTRARY : {
            get() {
                return ConstantRelationType.CONTRARY;
            },
        },
        SAME : {
            get() {
                return ConstantRelationType.SAME;
            },
        },
        FOLLOWING : {
            get() {
                return ConstantRelationType.FOLLOWING;
            },
        },
        NONE : {
            get() {
                return ConstantRelationType.NONE;
            },
        },
        JURISPRUDENCE_ID :{
            get(){
                return ConstantDocumentType.JURISPRUDENCE_ID;
            }
        },
        ...mapState({
            filter : state => state.documentFilterStore.filter
        }),
        backToDocument(){
            return this.$t('global.action.backTodoc');
        }
},
    methods: {
        ...mapActions('documentFilterStore', [
            'updateDocumentTypeId',
            ]
        ),
        /**
         * The document as been selected 
         */
        onSelectDocument( {referenceDocument,chosenTitleDocument} ) {
            this.$emit('documentChosen', {
                referenceDocumentDestination: referenceDocument,
                chosenTitleDocumentDestination: chosenTitleDocument,
                relationType : this.radioGroupRelationType
            });
        },
        resetComponent(){
            this.referenceDocumentDestination = null;
            this.chosenTitleDocumentDestination = null;
            this.$emit(UPDATE_DIALOG, false);
        },
        setDocumentTypeId(typeID){
            this.updateDocumentTypeId(typeID);
            EventBus.$emit(ConstantEvent.DOCUMENT_LIST, this.filter);
        }
    },
    components: {
        BolBackButton,
        DocumentFilter,
        DocumentsToLink
    }
}
</script>

<style scoped>
    .filter_sizer{
        min-width: 350px;
        max-width: 350px;
    }
    .document_hyperlink_sizer{
        width: 100%;
        height:100vh;
    }
    .backtodoc-search{
        font-weight: bold;
        color:#D39E4E;
        width:300px;
        left:10px;
        position:absolute;
        bottom:350px;
    }
    .backtodoc-search:hover{
        transform: scale(1.1);
    }
    .radio-group{
        margin-left:50px;
        margin-top:90px;
        border: solid 1px cadetblue;
        border-radius: 10px;
        width:200px;
        padding:10px;
    }
</style>
