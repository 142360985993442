<template>
    <v-layout align-end justify-end column>
        <v-flex class="actions" v-if="status !== available && step !== consolidation ">
            <span class="text_actions">{{ $t('document.action.step.validate') }}</span>
            <v-btn color="white" small fab dark slot="activator" @click="validateStep(true)">
                <v-icon color="success">check_circle</v-icon>
            </v-btn>
        </v-flex>
        <v-flex class="actions" v-if="step === consolidation">
            <span class="text_actions">{{ $t('global.action.consolidate') }}</span>
            <v-btn color="white" small fab dark slot="activator" @click="confirm">
                <v-icon color="success">done_all</v-icon>
            </v-btn>
        </v-flex>
        <v-flex class="actions" v-if="step !== edition && step !== consolidation">
            <span class="text_actions">{{ $t('document.action.step.reject') }}</span>
            <v-btn color="white" small fab dark slot="activator" @click="validateStep(false)">
                <v-icon color="error">remove_circle</v-icon>
            </v-btn>
        </v-flex>
    </v-layout>
</template>

<script>
import { mapState }     from 'vuex';
import ConstantWorkflow from '../../../../shared/constant/constantWorkflow.js'
/**
 * 
 * Display all the action button for a document
 * 
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 25/06/2019
 */
export default {
    name: 'ActionValidationWorkflow',
    computed: {
        ...mapState({
            status: state => state.dataSheetStore.metaDocument.documentStatus,
            step: state => state.dataSheetStore.metaDocument.documentStep,
            documentId: state => state.dataSheetStore.metaDocument.currentVersion.documentId,
            startDocumentStep: state => state.dataSheetStore.metaDocument.startDocumentStep
        }),
        edition(){
            return ConstantWorkflow.EDITION;
        },
        available(){
            return ConstantWorkflow.AVAILABLE;
        },
        consolidation(){
            return ConstantWorkflow.CONSOLIDATION;
        }
    },
    methods: {
        /**
         * Send the data to validate the step
         */
        validateStep( validation ){
            this.$emit('onValidation', validation );
        },
        confirm(){
            this.$emit('onConfirm');
        }
    }

}
</script>

<style scoped>
.actions{
    margin-bottom:20px;
}
.text_actions{
    color:white;
}
</style>
