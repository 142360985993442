<template>

    <div class="margins_content_tree"  >

        <v-progress-circular v-if="displaySpinner"
                :size="50"
                :indeterminate="true"
                color="primary"
                class="spinner-circus"
        ></v-progress-circular>

        <search-on-node-component :hiddenSearch="isHiddenSearch" />

        <v-container fluid>
            <v-layout column justify-start align-start >
                <v-flex id="#container-event">
                    <div :id="`container-${storeName}`" class="container-class" v-if="!isHiddenSearch"
                        @click="clickOnNode"
                        @contextmenu="showContextMenu"
                        @copy="onCopy"
                        @paste="onPaste">
                        <content-node class="node-content" :node="node"/>
                    </div>
                    <div :id="`container-${storeName}`" class="container-split" v-else
                         @click="clickOnNode"
                         @contextmenu="showContextMenu"
                         @copy="onCopy"
                         @paste="onPaste">
                        <content-node class="node-content" :node="node"/>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>

        <div class="floating_button_settings" v-if="storeName === 'documentContent' ">
            <v-flex class="position_settings">
                <v-btn class="margin-settings" fab color="white" @click="isHiddenSearch = !isHiddenSearch">
                    <v-icon color="primary">settings</v-icon>
                </v-btn>
            </v-flex>
            <div v-if="isHiddenSearch" class="margin-button-settings">
                <v-tooltip top>
                    <v-btn color="primary" fab class="btn-save" slot="activator"  @click="createFootnote" >
                        <v-icon >filter_1</v-icon>
                    </v-btn>
                    <span>{{ $t('global.action.manageFootnote') }}</span>
                </v-tooltip>
            </div>
        </div>

        <dialog-add-node :dialog.sync="openDialogAddNode" :storeName="storeName" :idDocumentType="idDocumentType" :position="where"></dialog-add-node>
        <dialog-content-editor :dialog.sync="openDialogContentEditor" :storeName="storeName"></dialog-content-editor>
        <dialog-delete-node :dialog.sync="openDialogDeleteNode" :storeName="storeName" :documentId="documentId"></dialog-delete-node>
        <dialog-add-keyword :dialog.sync="openDialogAddKeyword" />
        <dialog-create-custom-list :dialog.sync="openDialogCreateCustomList" :updateMode="updateMode" @onCreateCustomList="onCreateCustomList" @onUpdateCustomList="onUpdateCustomList"/> 
        <dialog-footnote :dialog.sync="openDialogFootnote" :storeName="storeName" :position="position"></dialog-footnote>
        <dialog-import-files :dialog.sync="openDialogImportFiles" :documentId="documentId" :operationType="operationType" @onImportFile="onImportFile" />
        <dialog-change-picture-size :dialog.sync="openDialogChangePictureSize" @updateWidthHeight="updateWidthHeight" />
        <dialog-format-text :dialog.sync="openDialogFormatText" :storeName="storeName" :position="position" @format="setSelectedNodeContent"/>
        <dialog-manage-table :dialog="manageTableDialog" :table="tableToEdit" @close-popup="resetTableToEdit" @add-table="addHTMLElementInNodeContent" @update-table="updateHTMLElementInNodeContent"/>
        <dialog-table-footnote :dialog.sync="openDialogFootnoteTable" :position="position" @update-table="updateHTMLElementInNodeContent"/>

        <menu-for-text :menu.sync="showMenuForText" :position="positionXY" :storeName="storeName" 
                       :modificatorContentChunk="modificatorContentChunk" :isConsolidation="isConsolidation" 
                       :globalAttributes="globalAttributes" :list="list" :quoteClicked="quoteClicked" :isInlineQt="isInlineQt" 
                       @onOpenDialog="onOpenDialog"/>
        
        <menu-for-node  :menu.sync="showMenuForNode" :position="positionXY" :storeName="storeName"  
                        :modificatorContentChunk="modificatorContentChunk" :isConsolidation="isConsolidation" 
                        :globalAttributes="globalAttributes"
                        @onOpenDialog="onOpenDialog"/>
        
        <menu-for-title :menu.sync="showMenuForTitle" :position="positionXY" :storeName="storeName"
                        :modificatorContentChunk="modificatorContentChunk" :langList="langList"
                        @resetModificator="resetModificator" @scrollToPasteNode="scrollToPasteNode" @onOpenDialog="onOpenDialog"/>
    </div>
</template>
<script>
import CheckDocumentNodeSelectionService    from '../../../../service/document/checkDocumentNodeSelectionService.js';
import ContentNode                          from './ContentNode';
import ConstantAction                       from '../../../../shared/constant/constantAction';
import ConstantDocumentNode                 from '../../../../shared/constant/constantDocumentNode.js';
import ConstantEvent                        from '../../../../shared/constant/constantEvent.js';
import ConstantNode                         from '../../../../shared/constant/constantNode.js';
import axios                                from 'axios';
import ConstantNotification                 from '../../../../shared/constant/constantNotification.js';
import ConstantQuotation                    from '../../../../shared/constant/constantQuotation.js';
import ConstantStoreName                    from '../../../../shared/constant/constantStoreName.js';
import ConstantKeyCode                      from '../../../../shared/constant/constantKeyCode';
import ConstantTag                          from '../../../../shared/constant/constantTag.js';
import ConstantUploadOperationType          from '../../../../shared/constant/constantUploadOperationType';
import CustomListService                    from '../../../../service/document/customListService';
import DialogAddKeyword                     from '../dialog/DialogAddKeyword';
import DialogAddNode                        from '../dialog/DialogAddNode.vue';
import DialogChangePictureSize              from '../dialog/DialogChangePictureSize';
import DialogContentEditor                  from '../dialog/DialogContentEditor.vue';
import DialogCreateCustomList               from '../dialog/DialogCreateCustomList';
import DialogDeleteNode                     from '../dialog/DialogDeleteNode.vue';
import DialogFootnote                       from '../dialog/DialogFootnote.vue';
import DialogFormatText                     from '../dialog/DialogFormatText';
import DialogImportFiles                    from '../dialog/DialogImportFiles';
import DialogManageTable                    from '../dialog/DialogManageTable';
import DialogTableFootnote                  from '../dialog/DialogTableFootnote.vue';
import DocumentTreeService                  from '../../../../service/document/documentTreeService.js'
import DomManipulatorService                from '../../../../service/html/domManipulatorService';
import MenuForText                          from '../menu/MenuForText';
import MenuForTitle                         from '../menu/MenuForTitle';
import MenuForNode                          from '../menu/MenuForNode';
import NodeContentTreeService               from '../../../../service/document/nodeContentTreeService.js';
import SearchOnNodeComponent                from '../components/SearchOnNodeComponent.vue';

import EventBus                             from '../../../../utils/event-bus';
import { nodeContentMixin }                 from '../../../../mixins/nodeContentMixin';
import { mapState } from 'vuex';
import * as $                               from 'jquery';

/**
 * Display the node tree and the content
 *
 * @author Sébastien DE SANTIS
 * @version 1.0
 * @since 2018-11-23
 */
export default {
    name: 'NodeContentTree',
    mixins: [nodeContentMixin],
    props: {
        storeName: {
            default: null
        },
        modificatorContentChunk: {
            default: false
        },
        idDocumentType: {
            default: null
        },
        isConsolidation: false
    },
    data: () => ({
        openDialogAddNode: false,
        openDialogContentEditor: false,
        openDialogAddQuote: false,
        openDialogDeleteNode: false,
        openDialogAddKeyword: false,
        openDialogCreateCustomList: false,
        openDialogFootnote: false,
        openDialogImportFiles: false,
        openDialogChangePictureSize: false,
        openDialogFormatText: false,
        openDialogFootnoteTable: false,
        updateMode: false,
        displaySpinner: true,
        showMenuForText: false,
        showMenuForNode: false,
        showMenuForTitle: false,
        textClicked: false,
        quoteClicked: false,
        isInlineQt: false,
        list: {
            node: null,
            isHtmlList: false
        },
        isHiddenSearch: false,
        positionXY: {
            x: 0,
            y: 0
        },
        position: { },
        manageTableDialog: false,
        tableToEdit: null,
        globalAttributes: {
            windowSelection: null,
            selectedTable: null,
            isSelectedTable: null,
            caretPosition: {
                clickedNode: null,
                clickedNodeOffset: 0,
                clickedFocusNodeOffset: 0,
                checkedNode: null,
                checkedNodeOffset: 0,
            },
            nodeId: null,
            idFiles: null,
            tableClicked: false,
            udpatePictures: false,
            deleteFilesClicked: false
        },
        where: null
    }),
    components: {
        ContentNode,
        DialogAddNode,
        DialogDeleteNode,
        DialogFootnote,
        DialogAddKeyword,
        DialogCreateCustomList,
        DialogChangePictureSize,
        DialogFormatText,
        DialogImportFiles,
        DialogManageTable,
        DialogTableFootnote,
        DialogContentEditor,
        MenuForText,
        MenuForTitle,
        MenuForNode,
        SearchOnNodeComponent
    },
    computed: {
        /**
         * States and getters below are loaded depending on the name of the store to use
         */
        langList(){
            return this.$store.state.languageStore.langList;
        },
        selectedNode(){
            return this.$store.state[`${this.storeName}`].selectedNode;
        },
        documentId(){
            return this.$store.state[`${this.storeName}`].documentId;
        },
        additionalSelectedNodeList(){
            return this.$store.state[`${this.storeName}`].additionalSelectedNodeList;
        },
        documentType(){
            return this.$store.state.dataSheetStore.metaDocument.documentType;
        },
        rootNode(){
            return this.$store.state[`${this.storeName}`].rootNode;
        },
        allSelectedNodes(){
            return this.$store.getters[`${this.storeName}/allSelectedNodes`]
        },
        operationType(){
            return ConstantUploadOperationType.ATTACHED_CONTENT_OPERATION;
        },
        node(){
            return this.rootNode;
        },
        nodeToPaste(){
            return this.$store.state[`${this.storeName}`].nodeToPaste;
        },
        textToPaste(){
            return this.$store.state[`${this.storeName}`].textToPaste;
        }
    },
    mounted() {
        EventBus.$on(ConstantEvent.UPDATE_TABLE, this.updateHTMLElementInNodeContent);
        if( this.storeName === ConstantStoreName.DOCUMENT_CONTENT ){
            EventBus.$on(ConstantEvent.SELECT_NODE_FROM_TREE, this.scrollToSelectedNode);
            EventBus.$on(ConstantEvent.ON_MANAGE_QUOTE, this.onAddQuote);
            //We have to manage the key up event in that way because the @keyup of vue need a typing inside an input to send the event.
            window.document.addEventListener("keyup", event => {
                this.keymonitor(event);
            }, {capture: true});
        }
    },
    beforeDestroy(){
        EventBus.$off(ConstantEvent.UPDATE_TABLE);
        if( this.storeName === ConstantStoreName.DOCUMENT_CONTENT ){
            EventBus.$off(ConstantEvent.SELECT_NODE_FROM_TREE, this.scrollToSelectedNode);
            EventBus.$off(ConstantEvent.ON_MANAGE_QUOTE, this.onAddQuote);
        }
    },
    updated(){
        this.$nextTick(function(){
            this.displaySpinner = false;
        });
    },
    methods:{
        /**
         * Acces to the actions of the store depending on which instance of the store we need to check
         */
        modifyContent( nodeContent ) {
            this.$store.dispatch(`${this.storeName}/modifyContent`, nodeContent);
        },
        removeAllAdditionalSelectedNodes() {
            this.$store.dispatch(`${this.storeName}/removeAllAdditionalSelectedNodes`);
        },
        selectNode( node ) {
            this.$store.dispatch(`${this.storeName}/selectNode`, node);
        },
        setSelectedNodeContent( nodeContent ) {
            this.$store.dispatch(`${this.storeName}/setSelectedNodeContent`, nodeContent);
        },
        toggleAdditionalSelectedNode( node ) {
            this.$store.dispatch(`${this.storeName}/toggleAdditionalSelectedNode`, node);
        },
        toggleOpenNode( node ) {
            this.$store.dispatch(`${this.storeName}/toggleOpenNode`, node);
        },
        updateSelectedNodePath() {
            this.$store.dispatch(`${this.storeName}/updateSelectedNodePath`);
        },
        /**
         * For each menu component there is some dialog to open 
         * This method manage the opening of all the dialog
         * @param dialog, the dialog to open
         * @param caretPosition, the position and information related to the selected text if exist
         */
        onOpenDialog( { dialog, caretPosition } ){
            if( dialog === ConstantEvent.ADD_NODE ){
                this.openDialogAddNode = true;
            } else if( dialog === ConstantEvent.OPEN_DIALOG_CONTENT_EDITOR ) {
                this.openDialogContentEditor = true;
            } else if( dialog === ConstantEvent.DELETE_NODE ){
                this.openDialogDeleteNode = true;
            } else if( dialog === ConstantEvent.ADD_KEYWORD ){
                this.openDialogAddKeyword = true;
            } else if( dialog === ConstantEvent.OPEN_DIALOG_CREATE_CUSTOM_LIST ){
                this.openDialogCreateCustomList = true;
                this.updateMode = false;
            } else if( dialog === ConstantEvent.OPEN_DIALOG_FOOTNOTES ){
                this.openDialogFootnote = true;
                this.position = caretPosition;
            } else if( dialog === ConstantEvent.IMPORT_FILES ){
                this.openDialogImportFiles = true;
            } else if( dialog === ConstantEvent.CHANGE_WIDTH_HEIGHT){
                this.openDialogChangePictureSize = true;
            } else if( dialog === ConstantEvent.FORMAT_TEXT ){
                this.openDialogFormatText = true;
                this.position = caretPosition;
            } else if( dialog === ConstantEvent.UPDATE_TABLE ){
                this.manageTableDialog = true;
            } else if( dialog === ConstantEvent.OPEN_DIALOG_TFOOT ){
                this.openDialogFootnoteTable = true;
                this.position = caretPosition;
            } else if( dialog === ConstantEvent.OPEN_DIALOG_UPDATE_CUSTOM_LIST){
                this.openDialogCreateCustomList = true;
                this.updateMode = true;
            }
        },
        /**
         * ALL THE ACTIONS DESTINATED TO MODIFY THE INSIDE OF NODE OF TYPE TEXT
         */
        /**
         * Come back from the DialogAddQuote with the html element blockquote
         */
        onAddQuote( blockquote ){
            if( this.quoteClicked ){
                let htmlContent = DomManipulatorService.replaceHTMLByHTMLElementInNode(
                    this.globalAttributes.caretPosition.checkedNode, 
                    this.globalAttributes.caretPosition.checkedNodeOffset, 
                    this.globalAttributes.caretPosition.checkedNodeOffset+1, 
                    blockquote.outerHTML, 
                    ConstantDocumentNode.TEXT_NODE_CLASS);
                
                this.setSelectedNodeContent(htmlContent);
            } else {
                this.$checkCaretPositionNode(this.globalAttributes.caretPosition.clickedNode, this.globalAttributes.caretPosition.clickedNodeOffset);
                this.addHTMLElementInNodeContent( blockquote.outerHTML );
            }  
        },
        /**
         * Get back with the created list to insert on the node
         */
        onCreateCustomList( customList ){
            this.$checkCaretPositionNode(this.globalAttributes.caretPosition.clickedNode, this.globalAttributes.caretPosition.clickedNodeOffset);
            this.addHTMLElementInNodeContent(customList.outerHTML);
        },
        /**
         * Update a custom list with
         */
        onUpdateCustomList(bulletClass, separatorClass){
            let node = this.globalAttributes.caretPosition.checkedNode;
            let customListTable = CustomListService.updateCustomListClasses({ node: node, position: this.positionXY }, bulletClass, separatorClass);
            let htmlContent = DomManipulatorService.updateHTMLElementInNode(this.list.node, customListTable.outerHTML, ConstantDocumentNode.TEXT_NODE_CLASS);
            this.setSelectedNodeContent(htmlContent);
        },
        /**
         * Create a footnote
         */
        createFootnote(){
            this.onOpenDialog({ 
                dialog: ConstantEvent.OPEN_DIALOG_FOOTNOTES,
                caretPosition: {
                    caretPositionNode: null,
                    caretPositionOffset: 0
                } 
            });
        },
        /**
         * Get back the source of the file / pictures that needs to be integrated to the document
         */
        onImportFile( file ){
            this.$checkCaretPositionNode(this.globalAttributes.caretPosition.clickedNode, this.globalAttributes.caretPosition.clickedNodeOffset);
            this.addHTMLElementInNodeContent(DomManipulatorService.createElementForImportedFile( file ).outerHTML);
        },
        /**
         * set the new width and height of the selected picture
         */
        updateWidthHeight( widthHeight ){
            let img = document.getElementById(this.globalAttributes.idFiles);
            img.setAttribute('width', widthHeight.width);
            img.setAttribute('height', widthHeight.height);
            let container = $( `#${ConstantDocumentNode.NODE_CONTENT_CLASS}-${this.globalAttributes.nodeId}`);
            if(container[0].children[0].className === `${ConstantDocumentNode.CONTENT_STYLE_CLASS} ${ConstantDocumentNode.TEXT_NODE_CLASS}`){
                this.modifyContent({ id: this.globalAttributes.nodeId, content: container[0].children[0].innerHTML });
            }else{
                this.modifyContent({ id: this.globalAttributes.nodeId, content: container[0].innerHTML });
            }
        },
        /**
         * Create footnote on a table
         */
        createTableFootnote(){
            let tableToEdit = this.globalAttributes.caretPosition.checkedNode.childNodes[this.globalAttributes.caretPosition.checkedNodeOffset];
            EventBus.$emit(ConstantEvent.OPEN_DIALOG_TFOOT, {clickedNode: this.globalAttributes.caretPosition.clickedNode, clickedOffset: this.globalAttributes.caretPosition.clickedNodeOffset, htmlTableElement: tableToEdit});
        },
        /**
         * Reset the data from the component document modificator
         */
        resetModificator(){
            this.$emit('resetModificator');
        },
        /**
         * Focus on the pasted node.
         */
        scrollToPasteNode(){
            this.$nextTick(function(){
                this.scrollToSelectedNode(this.selectedNode);
            });
        },
        /**
         * read and save the clipboard content
         */
        async readClipboardContent(){
            const lastSelection = window.getSelection().getRangeAt(0);
            let content = "";
            let childNodes = [...lastSelection.cloneContents().childNodes];
            childNodes.forEach( child => {
                if ( child.tagName !== 'BLOCKQUOTE' && child.nodeName !== '#text' ) {
                    content += child.outerHTML;
                }
                else if ( child.tagName === 'BLOCKQUOTE' ) {
                    content += child.outerHTML;
                }
                else {
                    content += child.textContent;
                }
            })
            await navigator.clipboard.writeText(content);
        },
        // TODO: if possible methods below destinate to loop over the click and set the visibility 
        // in the different menu should be placed in a service or something else
        /**,
         * Open the context menu where the mouse is
         */
        showContextMenu (event) {
            event.preventDefault();
            this.positionXY.x = event.clientX;
            this.positionXY.y = event.clientY;
            this.globalAttributes.isSelectedTable = this.globalAttributes.selectedTable;
            this.clickOnNode(event);
            this.$checkMenuOptions(event);
        },
        /**
         * Manage the event click somewhere inside the component.
         * Isolate the node where the user has clicked and update it has selected
         * @param event
         */
        clickOnNode( event ) {
            this.isHiddenSearch = false;
            let node = this.findClickedDocumentNode(event, this.node);//nodeContentMixin method
            if(!!node){
                this.globalAttributes.nodeId = node.id;

                if ( CheckDocumentNodeSelectionService.isMultipleTextNodeSelectionAvailable(event, this.selectedNode, node) ) {
                    if (node !== this.selectedNode) {
                        this.toggleAdditionalSelectedNode(node);
                    } else if ( node === this.selectedNode && this.additionalSelectedNodeList.length > 0) {
                        let newSelectedNode = this.additionalSelectedNodeList[0];
                        this.toggleAdditionalSelectedNode(newSelectedNode);
                        this.$toggleSelectedNode(newSelectedNode);
                    }
                } else if( !(event.button === 2 && this.allSelectedNodes.includes(node)) ) {
                    this.removeAllAdditionalSelectedNodes();
                    this.$toggleSelectedNode(node);
                }
                if (!!this.selectedNode.content && CheckDocumentNodeSelectionService.isSelectionOnText(event, window.getSelection())) {
                    CheckDocumentNodeSelectionService.forceSelectionOnText( window.getSelection() );
                } 
            }

        },
        /**
         * Set the selected node
         */
        $toggleSelectedNode(node) {
            this.toggleOpenNode(node);
            EventBus.$emit(ConstantEvent.SELECT_NODE_FROM_CONTENT_TREE, node);
            this.selectNode(node);
            this.updateSelectedNodePath();
        },
        /**
         * Activate the contexttual menu to display, and check options to be displayed
         * @param click event
         */
        $checkMenuOptions(event) {
            this.showMenuForText = false;
            this.showMenuForNode = false;
            this.showMenuForTitle = false;
            if(this.selectedNode.label === ConstantTag.TEXT){
                this.readWindowSelection();
                if( this.globalAttributes.windowSelection.toString().length > 0 || this.selectedNode.content.length > 0 ){
                    this.showMenuForText = true;
                } else {
                    this.showMenuForNode = true;
                }
                this.$checkMenuOptionsOnTextNode(event);
            } else {
                this.showMenuForTitle = true;
            }
        },
        /**
         * Keep all the information about the selection
         */
        readWindowSelection(){
            this.globalAttributes.windowSelection = window.getSelection();
        },
        /**
         * Check in which HTML element the user click on show the Action on the menu corresponding
         * @param click event
         */
        $checkMenuOptionsOnTextNode(event){
            this.textClicked = false;
            this.globalAttributes.tableClicked = false;
            this.globalAttributes.deleteFilesClicked = false;
            this.quoteClicked = false;
            this.isInlineQt = false;
            this.globalAttributes.udpatePictures = false;
            this.list.node = null;
            this.list.isHtmlList = false;

            const selection = this.globalAttributes.windowSelection;
            const anchorNode = selection.anchorNode;
            
            if(!!anchorNode) {
                this.globalAttributes.caretPosition.clickedNode = selection.anchorNode;
                this.globalAttributes.caretPosition.clickedNodeOffset = selection.anchorOffset;
                this.globalAttributes.caretPosition.clickedFocusNodeOffset = selection.focusOffset;

                const path = event.composedPath();
                let pathIndex = 0;
                
                while(pathIndex < path.length && !this.globalAttributes.tableClicked && !this.list.node && !this.textClicked  && !this.globalAttributes.deleteFilesClicked && !this.quoteClicked) {
                    let node = path[pathIndex];
                    // FIRST ONE CHECK IF WE ARE IN NODE OF TYPE TEXT
                    if(CheckDocumentNodeSelectionService.isDocumentTextNode(node)) {
                        this.textClicked = true;
                        this.$checkCaretPositionNode(anchorNode, selection.anchorOffset);
                    } // SECOND CHECK IF WE CLICK ON AN EDITABLE TABLE AND SET THE POSITION ON IT
                    else if(CheckDocumentNodeSelectionService.isEditableTable(node) || CheckDocumentNodeSelectionService.isEditableTable(anchorNode)) {
                        this.tableToEdit = node;
                        this.globalAttributes.tableClicked = true;
                        this.$checkCaretPositionNodeFromParent(node);
                    }
                    else if(CheckDocumentNodeSelectionService.isCustomList(node) || CheckDocumentNodeSelectionService.isCustomList(anchorNode)) {
                        this.list.node = node;
                        this.$checkCaretPositionNode(anchorNode, selection.anchorOffset);
                        this.showMenuForNode = false;
                        this.showMenuForText = true;
                    }
                    else if(CheckDocumentNodeSelectionService.isPDFLink(node)) {
                        this.globalAttributes.deleteFilesClicked = true;
                        this.globalAttributes.idFiles = node.id;
                    }
                    else if(CheckDocumentNodeSelectionService.isPictureFile(node)) {
                        this.globalAttributes.udpatePictures = true;
                        this.globalAttributes.deleteFilesClicked = true;
                        this.globalAttributes.idFiles = event.target.id;
                    }
                    else if(CheckDocumentNodeSelectionService.isHTMLList(node)) {
                        this.list.node = node.parentNode;
                        this.list.isHtmlList = true;
                        this.$checkCaretPositionNodeFromParent(node);
                    }
                    else if(CheckDocumentNodeSelectionService.isBlockquote(node)) {
                        let block = CheckDocumentNodeSelectionService.extractParentBlockquoteIfExist(event.target);

                        if( block.className.includes(ConstantQuotation.QT_INLINE_CLASS)){
                            this.isInlineQt = true;
                        }
                        this.globalAttributes.caretPosition.checkedNode = block.parentNode;
                        let index = Array.from(block.parentNode.childNodes).indexOf(block);
                        this.globalAttributes.caretPosition.checkedNodeOffset = index;
        
                        this.quoteClicked = true;
                    }
                    pathIndex++;
                }
            } else {
                this.globalAttributes.caretPosition.clickedNode = event.target.parentNode;
                this.globalAttributes.caretPosition.clickedNodeOffset = [...event.target.parentNode.childNodes].indexOf(event.target);
                this.globalAttributes.caretPosition.clickedFocusNodeOffset = this.globalAttributes.caretPosition.clickedNodeOffset;
            }
        },
        /**
         * Add a table at the caret position in the selected node
         */
        addHTMLElementInNodeContent(outerHTMLContent) {
            this.globalAttributes.caretPosition.checkedNode = DomManipulatorService.isPDFStructure(this.globalAttributes.caretPosition.checkedNode);
            let htmlContent = DomManipulatorService.insertHTMLInNode(
                this.globalAttributes.caretPosition.checkedNode, 
                this.globalAttributes.caretPosition.clickedFocusNodeOffset < this.globalAttributes.caretPosition.checkedNodeOffset ? this.globalAttributes.caretPosition.checkedNodeOffset : this.globalAttributes.caretPosition.clickedFocusNodeOffset, 
                outerHTMLContent,
                ConstantDocumentNode.TEXT_NODE_CLASS);
            this.setSelectedNodeContent(htmlContent);
        },
        /**
         * Update the selected table in its node
         */
        updateHTMLElementInNodeContent(outerHTML) {
            let node;
            if(this.list.isHtmlList ) {
                node = this.globalAttributes.caretPosition.checkedNode;
            } else {
                node = this.globalAttributes.caretPosition.checkedNode.childNodes[this.globalAttributes.caretPosition.checkedNodeOffset];
            }
            let htmlContent = DomManipulatorService.updateHTMLElementInNode(node, outerHTML, ConstantDocumentNode.TEXT_NODE_CLASS);
            this.setSelectedNodeContent(htmlContent);
            this.tableToEdit = null;
        },
        /**
         * Reset tabletoEdit in case of popup is closing
         * */
        resetTableToEdit(){
            this.tableToEdit = null;
            this.manageTableDialog = false;
        },
        /**
         * Set the caretPosition's checkedNode,
         * and checkedNodeOffset as the offset required to use with this checkedNode later
         * @param {Node} node the checked node 
         * @param {Number} offset the checked node offset
         */
        $checkCaretPositionNode(node, offset) {
            this.globalAttributes.caretPosition.checkedNode = node;
            this.globalAttributes.caretPosition.checkedNodeOffset = offset;
        },
        /**
         * Set the caretPosition's checkedNode from its parent.
         * The parent will bet set as the checkedNode, 
         * and the checkedNodeOffset will be set to the index of the node in its parent childNodes list.
         * The real checked node will be available later from its index in its parent node.
         * @param {Node} node the node to be available as the checked node later
         */
        $checkCaretPositionNodeFromParent(node) {
            this.$checkCaretPositionNode(node.parentNode, [...node.parentNode.childNodes].indexOf(node));
        },
        onCopy(){
            if ( this.selectedNode.label !== ConstantTag.TEXT ){
                this.$store.dispatch(`${this.storeName}/setCopiedSelection`, { action: ConstantAction.COPY_NODE, node: this.selectedNode } );
            } else {
                this.readClipboardContent();  
            }
        },
        /**
         * Paste the selection (either a node or a text)
         * @param {String} selectedText
         */
        async onPaste(){
            let textFromClipboard = await navigator.clipboard.readText();
            // If a node is selected, CTRL+V/CMD+V should paste the copied node after the selected node
            if (!!this.nodeToPaste) {
                this.$pasteNodeAfter(this.nodeToPaste.node);
            } 
            else if (!!textFromClipboard){
                this.readWindowSelection();
                let htmlContent = NodeContentTreeService.pasteText(textFromClipboard, this.globalAttributes);
                this.setSelectedNodeContent( htmlContent );
            } 
            else {
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    message: 'administration.structure.action.emptyPaste',
                    type: 'INFO'
                });
            }
            navigator.clipboard.writeText("");
        },
        /**
         * F6: Add an empty structure node before the selected node
         * F7: Add an empty structure inside the selected node
         * F8: Add an empty structure after the selected node
         * CTRL - X : Delete either a node or a text
         */
        keymonitor(event){
            let key = event.which || event.keyCode; // keyCode detection
            let ctrl = event.ctrlKey ? event.ctrlKey : ((key === ConstantKeyCode.CTRL) ? true : false); // ctrl detection
            event.preventDefault();
            if ( key === ConstantKeyCode.F6){    
                this.where = ConstantNode.WHERE_BEFORE;
                this.openDialogAddNode = true;
            } else if ( key === ConstantKeyCode.F7) {
                this.openDialogAddNode = true;
                this.where = ConstantNode.WHERE_INSIDE;
            } else if ( key === ConstantKeyCode.F8) {
                this.openDialogAddNode = true;
                this.where = ConstantNode.WHERE_AFTER;
            } else if ( key === ConstantKeyCode.x && ctrl ){
                // Delete only the selected text in a TextNode
                if ( this.selectedNode.label === ConstantTag.TEXT ){
                    this.readWindowSelection();
                    let textToDelete = window.getSelection().toString();
                    this.globalAttributes.caretPosition.clickedNode = this.globalAttributes.windowSelection.anchorNode;
                    this.globalAttributes.caretPosition.clickedNodeOffset = this.globalAttributes.windowSelection.anchorOffset;
                    this.globalAttributes.caretPosition.clickedFocusNodeOffset = this.globalAttributes.windowSelection.focusOffset;
           
                    let htmlContent = NodeContentTreeService.replaceTextByTextInTheSameElement("", this.globalAttributes.caretPosition);
                    this.setSelectedNodeContent(htmlContent);
                } 
                // Delete the node
                else {
                    this.openDialogDeleteNode = true;
                }
            }
        },
        /**
         * Paste node after the selected node
         * @param {Node} node, node to paste
         */
        $pasteNodeAfter(node){
            if( window.getSelection().isCollapsed ){
                this.$store.dispatch(`${this.storeName}/addPasteNodeInTree`, {
                    selectedNode : node,
                    node : DocumentTreeService.createClonePattern(node),
                    where : ConstantNode.WHERE_AFTER,
                    action : ConstantAction.PASTE_NODE
                });
            } else {
                EventBus.$emit( ConstantEvent.ADD_NOTIFICATION, {
                    type: ConstantNotification.ERROR,
                    message: 'structure.node.notification.pasteError',
                });
            }
        }
    }
}
</script>
<style>
.bol-custom-list{
    border: 1px dotted gray;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 5px;
}
.bol-custom-list td:first-child{
    border: 1px dotted gray;
    padding: 10px !important;
    width: 75px;
    min-height: 30px !important;
}
.bol-custom-list td{
    border: 1px dotted gray;
    padding: 10px !important;
    min-height: 30px !important;
}
.bol-editable-table{
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    margin-right: 5px;
    margin-bottom: 10px;
}
.bol-editable-table table{
    table-layout: fixed;
}
.bol-editable-table thead tr:first-of-type{
    height: 30px !important;
}
.bol-editable-table thead tr:first-of-type th{
    padding: 5px !important;
    width: 20px;
}
.bol-editable-table table tbody tr{
    border: none !important;
    background: none;
}
.bol-editable-table td{
    border: 1px solid black;
    padding: 5px !important;
    height: 30px !important;
    overflow-wrap: break-word;
}
.bol-editable-table table td div div{
    display: inline;
}
.bol-editable-table table td div div a{
    display: block;
}
</style>
<style scoped>
.container-class{
    height:calc(100vh - 85px);
    padding-bottom:20px;
    overflow-y:auto;
    text-align: left;
    width:calc(100vw - 300px);
}
.container-split{
    height:calc(100vh - 180px);
    padding-bottom:20px;
    overflow-y:auto;
    text-align: left;
    min-width:calc(100vw - 300px);
}
.margins_content_tree{
    margin-left:-30px;
    padding:0;
}
.btn-save{
    display:inline-block;
    margin-top:20px;
    width:45px;
    height:45px;
    margin-right:10px;
    margin-left:10px;
}
.menu-size{
    max-height: 400px;
}
.floating_button_settings{
    position: fixed;
    top:50px;
    right:-10px;
    height:100px;
    padding-top:30px;
}
.position_settings{
    float: right;
    margin-right:30px;
}
.margin_search{
    margin-left:10px;
}
.overlay_spinner{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: #00000088;
}
.loader_spinner{
    position:fixed;
    top:40%;
    left:50%;
}
.margin-button-settings{
    margin-top:5px;
}
.margin-settings{
    width:45px;
    height:45px;
}
.container-blockquote{
    height: calc(80vh) !important;
    padding-bottom: 20px !important;
    /*overflow-y: auto !important;*/
    text-align: left !important;
    width: calc(100vw - 30px) !important;
}
.content-split{
    width:calc(100% - 80px);
}
.spinner-circus{
    position:fixed;
    bottom:30px;
    right:20px;
}
</style>
