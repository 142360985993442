/**
 * 
 * Manage all the operation related to the document footnotes
 * 
 * @author Sébastien DE SANTIS
 * @since 07/08/2019
 * @version 1.0
 */
class DataSheetService{

    constructor(){

    }
    mergeDataSheet(datasheet){
        let mergedDataSheet = this.createDataSheet();
        mergedDataSheet.id = datasheet.id;
        mergedDataSheet.reference = datasheet.reference;
        mergedDataSheet.documentType = datasheet.documentType;
        mergedDataSheet.idWorkspace = datasheet.idWorkspace;
        mergedDataSheet.marketsId = datasheet.marketsId;
        mergedDataSheet.authorsId = datasheet.authorsId;
        mergedDataSheet.title = datasheet.title;
        mergedDataSheet.isVisible = datasheet.isVisible;
        if(!!datasheet.partner) {
            mergedDataSheet.partner = datasheet.partner;
        }
        mergedDataSheet.source = datasheet.source;
        mergedDataSheet.idOtherWorkspace = datasheet.idOtherWorkspace;
        mergedDataSheet.causeEndOfApplicability = datasheet.causeEndOfApplicability;
        mergedDataSheet.endDateOfApplicability = datasheet.endDateOfApplicability;
        mergedDataSheet.remark = datasheet.remark;
        mergedDataSheet.sourceEndOfApplicability = datasheet.sourceEndOfApplicability;
        mergedDataSheet.indeterminate = datasheet.indeterminate;
        for(const field in mergedDataSheet.basicAdditionalField){
            if(!!datasheet.basicAdditionalField[field]){
                mergedDataSheet.basicAdditionalField[field] = datasheet.basicAdditionalField[field];
            }
        }
        return mergedDataSheet;
    }
    /**
     * Return an empty DataSheet with all the fields declared.
     * This method should provide a model for a component to watch each fields
     */
    createDataSheet(){
        return {
            id: null,
            reference: null,
            documentType: {
                id: null
            },
            idWorkspace: null,
            marketsId:[],
            authorsId: [],
            title: null,
            partner:{
                id: null,
            },
            isVisible: true,
            idOtherWorkspace: [],
            causeEndOfApplicability: null,
            endDateOfApplicability: null,
            remark: null,
            sourceEndOfApplicability: null,
            indeterminate: null,
            basicAdditionalField: {
                age: { value: null },
                alternativeTitle: { value: null},
                baseAct: { value:null },
                baseDocument:{ value:null },
                code:{ value:null },
                commercialCourt:{ value:null },
                country:{ value:null },
                damageType:{ value: null },
                dateDecision:{ valueDate: null },
                dateDocument:{ valueDate: null },
                datePublication:{ valueDate:null },
                dismissalType:{ value:null },
                domain:{ domainsId:[] },
                entryDate:{ valueDate: null },
                gender:{ value: null },
                guidelineNature: {value: null},
                guidelineSource: {value: null},
                jurisdiction:{ value:null },
                judicialOrder:{ value:null },
                moralAllowance:{ value:null },
                nature:{ value: null },
                number:{ value:null },
                page:{ value:null},
                physicalAllowance:{ value:null },
                salary:{ value:null },
                sector:{ value:null },
                searchTag:{ value:null },
                seniority:{ value:null },
                source:{ value:null },
                sourceType:{ value: null },
                startDateOfApplicability:{ valueDate: null },
                status:{ valude:null },
                territory:{ value: null },
                language: {value: null}
            }
        };
    }
    /**
     * Reset a DataSheet and all of his fields
     * @param {*} dataSheet 
     */
    resetDataSheet(dataSheet){
        return this.$resetElement(dataSheet);
    }
    /**
     * Recursive method to reset a data sheet and all of its elements.
     * @param {*} element 
     */
    $resetElement(element){
        if(Array.isArray(element)) {
            return [];
        }
        if(typeof element !== 'object'){
            return null;
        } else {
            for (let [key, value] of Object.entries(element)) {
                if(element[key] !== null){
                    element[key] = this.$resetElement(element[key]);
                }
            }
        }
        return element;
    }
}

export default new DataSheetService();
