<template>
    <v-layout
        row
        wrap
        align-left
    >
        <v-flex>
            <v-container class="t_create_edit_dialog">
                <h1
                    v-if="$route.params.id == null"
                    class="bolded"
                >
                    {{
                        $t('administration.guidelinesource.manage.add.title')
                    }}
                </h1>
                <v-form
                    ref="formGuideline"
                    v-model="valid"
                    class="form-style"
                    lazy-validation
                >
                    <v-layout
                        column
                        justify-center
                    >
                        <v-flex md6>
                            <v-text-field
                                v-model="guideline.label"
                                class="t_name"
                                :label="$t('administration.guidelinesource.label.name')"
                                :rules="labelRules"
                                required
                                clearable
                            />
                        </v-flex>
                        <v-flex md6>
                            <v-text-field
                                v-model="guideline.code"
                                class="t_code"
                                :label="$t('administration.guidelinesource.label.code')"
                                :rules="abbreviationRules"
                                required
                                clearable
                            />
                        </v-flex>
                        <v-flex md6>
                            <v-text-field
                                v-model="guideline.description"
                                class="t_description"
                                :label="$t('administration.guidelinesource.label.description')"
                                :rules="labelRules"
                                required
                                clearable
                            />
                        </v-flex>
                        <v-flex md6>
                            <v-checkbox
                                v-model="guideline.visible"
                                :label="$t('administration.guidelinesource.label.visible')"
                            />
                        </v-flex>
                        <!-- MARKET -->
                        <v-autocomplete
                            v-model="guideline.markets"
                            class="t_markets"
                            :items="allMarketList"
                            :disabled="allMarketList.length === 0"
                            dense
                            filled
                            multiple
                            chips
                            small-chips
                            item-text="code"
                            return-object
                            label="Markets"
                        />
                        <!-- BUTTONS -->
                        <v-flex
                            xs3
                            justify-end
                        >
                            <v-tooltip
                                top
                                class="align-action-end"
                            >
                                <v-btn
                                    slot="activator"
                                    color="#355B7F"
                                    small
                                    fab
                                    :to="{ name: 'GuidelineSourceSearch' }"
                                >
                                    <v-icon color="white">
                                        cancel
                                    </v-icon>
                                </v-btn>
                                <span>{{ $t("administration.guidelinesource.manage.action.cancel") }}</span>
                            </v-tooltip>
                            <v-tooltip
                                v-if="valid"
                                top
                                class="align-action-end"
                            >
                                <v-btn
                                    slot="activator"
                                    class="t_save"
                                    color="primary"
                                    small
                                    fab
                                    dark
                                    icon
                                    @click="manage(guideline)"
                                >
                                    <v-icon>save</v-icon>
                                </v-btn>
                                <span>{{ $t("administration.guidelinesource.manage.action.add") }}</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-container>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from 'axios';
import EventBus from '../../../utils/event-bus.js';
import ConstantEvent from '../../../shared/constant/constantEvent.js'

export default {
    name: 'GuidelineSourceManagement',
    data() {
        return {
            valid: false,
            guideline: {
                id: null,
                code: null,
                label: null,
                visible: false,
                description: null,
                markets: []
            },
            allMarketList: [],
            labelRules: [
                v => !!v || this.$t("administration.guidelinesource.manage.required")
            ],
            abbreviationRules: [
                v => !!v || this.$t("administration.guidelinesource.manage.required"),
                v => !!v && v.length < 6 || this.$t("administration.guidelinesource.manage.too-long") + "5"
            ]
        }
    },
    mounted() {
        /**
         * When the page is charging, we pay attention if an id is passed,
         * if it's the case, it means that we update a guidelinesource instead of creating one
         */
        if (this.$route.params.id !== undefined) {
            this.loadGuideline(this.$route.params.id);
        }
        this.loadMarkets();
    },
    methods: {
        /**
         *  Create or update a guideline
         * @param newguideline
         */
        manage(guideline) {
            if (this.$refs.formGuideline.validate()) {
                var method = null;
                if (!!this.$route.params.id) {
                    method = "PUT",
                    guideline.id = this.$route.params.id;
                } else {
                    method = "POST";
                }

                axios({
                    method: method,
                    url: "administration/guidelinesource",
                    data: guideline
                }).then(() => {
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                        message: 'administration.guidelinesource.manage.' + (!this.$route.params.id ? 'add' : 'edit') + '.success',
                        type: 'SUCCEED'
                    }
                    );
                    this.$router.push('/administration/guidelinesource');
                });
            }
        },
        /**
     * Retrieve a guidelinesource with its id
     * @param id
     */
        loadGuideline(id) {
            axios.get(
                `administration/guidelinesource/${id}`
            ).then(result => {
                this.guideline = result.data;
            });
        },
        loadMarkets() {
            axios.get(
                `administration/markets`
            ).then(result => {
                this.allMarketList = result.data;
            });
        }
    }
}
</script>
<style>
.form-style {
  margin-top: 50px;
}

.align-action-end {
  float: right;
}

.bolded {
  font-weight: bold;
  color: #355B7F;
}
</style>
