import '@babel/polyfill';
import Vue from 'vue';
import './plugins/vuetify';
import App from './App.vue';
import i18n from './utils/i18n';
import router from './router';
import interceptor from './shared/interceptor.js';
import store from './store';
import CountryFlag from 'vue-country-flag';

Vue.config.productionTip = false;

interceptor(router, store);
Vue.component('VueCountryFlag', CountryFlag);

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
