<template>
    <div class="bol_editable_component">
        <div v-if="editable">
            <input ref="labelInput" type="text"
                @blur="changeEditable"
                :value="label"
                @input="changeNodeLabel($event)"
                @keyup.enter="exitInput($event)"/>
            <span class="bol_input_highlight">{{label}}</span>
        </div>
        <div v-else class="bol_label" @dblclick="!readOnly ? changeEditable() : ()=>{}">
            <span>{{label}}</span>
            <span v-if="!readOnly && !label" class="bol_placeholder">{{placeholder}}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'EditableInput',
    props: {
        label: {
            default: false
        },
        readOnly: {
            default: false
        },
        placeholder: {
            default: ''
        }
    },
    data(){
        return {
            editable: false
        }
    },
    methods:{
        /**
         * Trigger the input to become editable or only readeable
         */
        changeEditable() {
            this.editable = !this.editable;
            this.$nextTick( ()=> {
                if(this.editable){
                    this.$refs.labelInput.focus();
                }
            });
        },
         /**
         * Remove the focus on an input text
         * @param {Event} event 
         */
        exitInput(event){
            event.target.blur();
        },
         /**
         * Edit the label of a node.
         * @param {Event} event 
         */
        changeNodeLabel(event) {
            this.$emit('updateNodeLabel', event.target.value);
        },
    }
}
</script>
<style scoped>
.bol_label{
    min-width: 200px;
    min-height: 15px;
}
.bol_placeholder{
    min-width: 100px;
    min-height: 15px;
    color:gray;
    font-style: italic;
}
.bol_editable_component {
    position: relative;
}
input {
    border: 0;
    outline: 0;
    background: transparent;
    min-width: 10px;
    width: 100%;
}
.bol_input_highlight {
    max-width: 100%;
    border-top: 2px solid #1976d2;
    left: 0;
    bottom: 0;
    height: 0;
    position: absolute;
    color: transparent;
    user-select: none;
    overflow: hidden;
}
</style>