<template>
  <div>
    <v-menu
        :close-on-content-click="false"
        v-model="showMenu"
        :position-x="xPosition"
        :position-y="yPosition"
        absolute
        offset-y>
      <v-list>
        <v-list-group v-if="!!clickedNode" v-show="availablePolicies.length > 0" no-action>
          <v-list-tile slot="activator">
            <v-list-tile-content>
              <v-list-tile-title>{{ $t('pao.node.action.add') }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile v-for=" availablePolicy in availablePolicies"
                       :key="availablePolicy.id"
                       @click="handleAction(addNewNode, availablePolicy)">
            <v-list-tile-content>
              <v-list-tile-title> {{ availablePolicy.type }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list-group>
        <v-list-tile @click="handleAction(addContent)">
          <v-list-tile-title>{{ $t('structure.node.action.add-content') }}</v-list-tile-title>
        </v-list-tile>
        <v-list-tile @click="handleAction(cutPaoNode)">
          <v-list-tile-title>{{ $t('structure.node.action.cut') }}</v-list-tile-title>
        </v-list-tile>


        <v-list-group v-if="readyToPaste">
          <v-list-tile slot="activator">
            <v-list-tile-title>{{ $t('structure.node.action.paste') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="handleAction(pastePaoNodeOnTop)">
            <v-list-tile-title>{{ $t('structure.node.action.paste-above') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="handleAction(pastePaoNodeInside)">
            <v-list-tile-title>{{ $t('structure.node.action.paste-in') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="handleAction(pastePaoNodeOnBottom)">
            <v-list-tile-title>{{ $t('structure.node.action.paste-below') }}</v-list-tile-title>
          </v-list-tile>
        </v-list-group>

        <v-list-group v-if="clickedDocument">
          <v-list-tile slot="activator">
            <v-list-tile-title>{{ $t('pao.document.action.toc.label') }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-title @click="handleAction(setToDetailToc, true)">
              {{ $t('pao.document.action.toc.detailed') }}
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-title @click="handleAction(setToDetailToc, false)">{{ $t('pao.document.action.toc.normal') }}
            </v-list-tile-title>
          </v-list-tile>
        </v-list-group>

        <v-list-tile v-if="!clickedDocument" @click="handleAction(deletePaoNode)">
          <v-list-tile-title>{{ $t('pao.node.action.delete') }}</v-list-tile-title>
        </v-list-tile>
        <v-list-tile v-else @click="handleAction(deletePaoDocument)">
          <v-list-tile-title>{{ $t('pao.node.action.delete-document') }}</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>

    <dialog-confirmation :dialog.sync="openConfirmation" :message="confirmationMessage"
                         @confirm="confirmDeleteNode"></dialog-confirmation>
    <dialog-confirmation :dialog.sync="openDeleteDocumentConfirmation" :message="confirmationDeleteDocumentMessage"
                         @confirm="confirmDeleteDocument"></dialog-confirmation>

  </div>
</template>

<script>
import axios from 'axios';
import StoreModule from '../../../../shared/constant/store/module/namespace';
import PaoStructureStoreTypes from '../../../../shared/constant/store/module/paoStructureTypes';
import {mapActions, mapGetters, mapState} from 'vuex';
import EventBus from '../../../../utils/event-bus';
import ConstantEvent from '../../../../shared/constant/constantEvent';
import ConstantPaoNode from '../../../../shared/constant/constantPaoNode';
import DialogConfirmation from '../../../shared/DialogConfirmation';
import ConstantNotification from '../../../../shared/constant/constantNotification';
import PaoService from '../../../../service/pao/paoService';
import ConstantDocumentNode from '../../../../shared/constant/constantDocumentNode.js';

const UPDATE_MENU = 'update:openMenu';
const ADD_NODE = 'addnode';
const DELETE_NODE = 'deletenode';
const CUT_NODE = 'cutnode';
const PASTE_NODE = 'pastenode';


/**
 * Open a context menu to manage actions on paoNode
 *
 * @author Cédric de BOISVILLIERS
 * @version 1.0
 * @since 2019-08-13
 */
export default {
  name: 'PaoStructureMenu',
  components: {
    DialogConfirmation,
    ConstantNotification
  },
  props: {
    openMenu: {
      default: false
    },
    yPosition: {
      default: null
    },
    xPosition: {
      default: null
    },
    paoRootNodeId: {
      default: null
    }
  },
  data() {
    return {
      policyList: [],
      cacheNodeId: 0,
      confirmationMessage: "",
      openConfirmation: false,
      openDeleteDocumentConfirmation: false,
      confirmationDeleteDocumentMessage: "",
      readyToPaste: false,
    }
  },
  computed: {
    ...mapGetters(StoreModule.PAO_STRUCTURE, {
      currentPolicy: PaoStructureStoreTypes.GETTER.POLICY,
      parentNode: PaoStructureStoreTypes.GETTER.PARENT_NODE,
      availablePolicies: PaoStructureStoreTypes.GETTER.AVAILABLE_POLICIES
    }),
    ...mapState(StoreModule.PAO_STRUCTURE, {
      clickedNode: state => state.clickedNode,
      clickedDocument: state => state.clickedNodeDocument,
      nodeToPaste: state => state.nodeToPaste,
      nodePolicies: state => state.nodePolicies
    }),
    showMenu: {
      get() {
        return this.openMenu;
      },
      set() {
        this.$emit(UPDATE_MENU, false);
      }
    },
  },
  mounted() {
    EventBus.$on(ConstantEvent.CLICK_ON_DOCUMENT_NODE, this.setIdCutDocumentNode);
    EventBus.$on(ConstantEvent.CONFIRM_ADD_PAO_CONTENT, this.confirmAddPaoContent);
  },
  beforeDestroy() {
    EventBus.$off(ConstantEvent.CLICK_ON_DOCUMENT_NODE, this.setIdCutDocumentNode);
    EventBus.$off(ConstantEvent.CONFIRM_ADD_PAO_CONTENT, this.confirmAddPaoContent);
  },
  methods: {
    ...mapActions(StoreModule.PAO_STRUCTURE, {
      cutNode: PaoStructureStoreTypes.ACTION.CUT_NODE,
      pasteNode: PaoStructureStoreTypes.ACTION.PASTE_NODE,
      addNode: PaoStructureStoreTypes.ACTION.ADD_NODE,
      addCreatedNode: PaoStructureStoreTypes.ACTION.ADD_CREATED_NODE,
      deleteNode: PaoStructureStoreTypes.ACTION.DELETE_NODE,
      deleteDocumentStore: PaoStructureStoreTypes.ACTION.DELETE_DOCUMENT,
      updateSelectedNodeDocument: PaoStructureStoreTypes.ACTION.UPDATE_SELECTED_NODE_DOCUMENT
    }),
    handleAction(action, arg) {
      action(arg);
      this.showMenu = false;
    },
    /**
     * get ID from selected document
     **/
    setIdCutDocumentNode(event) {
      this.cacheNodeId = event;
    },
    /**
     * Call cut action to move one document for another ACTS
     **/
    cutPaoNode() {
      this.readyToPaste = true;
      let cutNodes = {
        "nodeID": this.cacheNodeId,
        "parentClickedNode": this.clickedNode
      }
      this.cutNode(cutNodes);
      this.cacheNodeId = null;

    },
    /**
     * paste node at the top of the clicked node
     **/
    pastePaoNodeOnTop() {
      if (this.$validatePasteTopAndBottom()) {
        this.readyToPaste = false;

        let pasteNodes = {
          "current": this.cacheNodeId,
          "action": ConstantDocumentNode.ACTION_TOP
        }

        this.pasteNode(pasteNodes);
        this.cacheNodeId = null;
      }
    },
    /**
     * paste node at the bottom of the clicked node
     **/
    pastePaoNodeOnBottom() {
      if (this.$validatePasteTopAndBottom()) {
        this.readyToPaste = false;

        let pasteNodes = {
          "current": this.cacheNodeId,
          "action": ConstantDocumentNode.ACTION_BOTTOM
        }
        this.pasteNode(pasteNodes);
        this.cacheNodeId = null;
      }
    },
    /**
     * paste node inside the clicked node
     **/
    pastePaoNodeInside() {
      if (this.$validatePasteInside()) {
        this.readyToPaste = false;

        let pasteNodes = {
          "current": this.cacheNodeId,
          "action": ConstantDocumentNode.ACTION_INSIDE
        }
        this.pasteNode(pasteNodes);
        this.cacheNodeId = null;

      }
    },
    /**
     * Call add a node action
     **/
    addNewNode(nodePolicy) {
      if (this.$validateNodeInsertion(nodePolicy)) {
        let node = PaoService.createPaoNode(this.clickedNode, nodePolicy);
        this.addNode(node);
        PaoService.organizeRank(this.clickedNode.children);
      }
    },
    /**
     * Call delete a node action
     **/
    deletePaoNode() {
      if (this.$validateMinimum(this.currentPolicy)) {
        this.confirmationMessage = 'pao.structure.confirmation.deleteNode';
        this.openConfirmation = true;
      }
    },
    /**
     * Call the delete methode on a document
     */
    deletePaoDocument() {
      this.confirmationDeleteDocumentMessage = 'pao.message.confirm-delete-pao-document';
      this.openDeleteDocumentConfirmation = true;
    },
    /**
     * Use to accept delete document action
     */
    confirmDeleteDocument() {
      this.deleteDocumentStore({document: this.clickedDocument, parent: this.clickedNode});
    },
    /**
     * Use to accept delete action
     **/
    confirmDeleteNode() {
      if (this.clickedNode.type === ConstantPaoNode.TYPE.FILE) {
        axios.delete('/pao/node/' + this.clickedNode.id)
            .then(() => {
              this.deleteNode(this.clickedNode);
            })
      } else {
        this.deleteNode(this.clickedNode);
      }
    },

    /**
     Call ValidateMaximum method to check if the clickedNode respect the nodePolicy max size
     **/
    $validateNodeInsertion(nodePolicy) {
      return this.$validateMaximum(nodePolicy);
    },
    /**
     Check if it respect the MAX size of NodePolicy
     **/
    $validateMaximum(nodePolicy) {
      const currentLength = this.clickedNode.children.filter(childNode =>
          childNode.idNodePolicy === nodePolicy.id
      ).length;
      if (nodePolicy.maximum !== -1 && currentLength >= nodePolicy.maximum) {
        const notification = {
          type: ConstantNotification.INFO,
          message: 'pao.notification.structure.add.maximumReached',
        };
        EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
        return false;
      }
      return true;
    },
    /**
     * Checks if node to paste can be pasted in parent of clicked element
     **/
    $validatePasteTopAndBottom() {
      if (this.availablePolicies.length > 0 && this.nodeToPaste) {
        const canPaste = this.nodePolicies.filter(pol =>
            pol.idParentNodePolicy === this.parentNode.idNodePolicy &&
            pol.type === this.nodeToPaste.type
        ).length !== 0;

        if (!canPaste) {
          const notification = {
            type: ConstantNotification.INFO,
            message: 'pao.notification.structure.paste.policyRestrictions',
          };
          EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
        }

        return canPaste;
      }
      return true;
    },
    /**
     * Checks if node to paste can be pasted inside of clicked element
     **/
    $validatePasteInside() {
      if (this.availablePolicies.length > 0 && this.nodeToPaste) {
        const canPaste = this.availablePolicies.filter(pol =>
            pol.type === this.nodeToPaste.type)
            .length !== 0;
        if (!canPaste) {
          const notification = {
            type: ConstantNotification.INFO,
            message: 'pao.notification.structure.paste.policyRestrictions',
          };
          EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
        }
        return canPaste;
      }
      return true;
    },

    /**
     Check if it respect the MIN size of NodePolicy
     **/
    $validateMinimum(nodePolicy) {
      const parentNode = this.parentNode;
      const currentLength = parentNode.children.filter(childNode =>
          childNode.idNodePolicy === nodePolicy.id
      ).length;
      if (currentLength <= nodePolicy.minimum) {
        const notification = {
          type: ConstantNotification.INFO,
          message: 'pao.notification.structure.delete.minimumRequired',
        };
        EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
        return false;
      }
      return true;
    },
    addContent() {
      EventBus.$emit(ConstantEvent.OPEN_BOL_DIALOG_ADD_PAO_CONTENT, {paoRootNodeId: this.paoRootNodeId});
    },
    confirmAddPaoContent(node) {
      this.addCreatedNode(node);
      PaoService.organizeRank(this.clickedNode.children);
    },
    /**
     * Set the toc to detailed to the selected document
     */
    setToDetailToc(isDetailedToc) {
      let node = {detailedToc: isDetailedToc};
      this.updateSelectedNodeDocument(node)
    }
  }
}
</script>

<style scoped>

</style>
