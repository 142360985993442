<template>
    <v-dialog v-model="dialog" persistent max-width="700px">
        <v-card>
            <v-card-title class="headline">{{ $t(message) }}</v-card-title>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" flat @click="close();">{{$t('global.action.cancel')}}</v-btn>
                <v-btn color="primary" flat @click="confirm();">{{$t('global.action.confirm')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import EventBus         from '../../utils/event-bus';
import ConstantEvent    from '../../shared/constant/constantEvent';

/**
 * 
 * Open a dialog to confirm the action the user want to do 
 * 
 * @author Sébastien DE SANTIS
 * @since 21/02/2020
 * @version 1.0
 * 
 */
export default {
    name: 'BolDialogConfirmation',
    data(){
        return {
            dialog:false,
            message: "",
            callback:{}
        }
    },
    created(){
        EventBus.$on(ConstantEvent.OPEN_BOL_DIALOG_CONFIRMATION, this.open);
    },
    beforeDestroy(){
        EventBus.$off(ConstantEvent.OPEN_BOL_DIALOG_CONFIRMATION);
    },
    methods:{
        confirm(){
            this.callback();
            this.close();
        },
        /**
         * Close the dialog and reset the stat
         */
        close(){
            this.dialog = false;
            this.message = '';
            this.callback = {};
        },
        open(message, callback){
            this.message = message;
            this.dialog = true;
            this.callback = callback;
        }
    }
}
</script>
<style scoped>
.headline{
    font-size: 24px;
    line-height: 32px;
    text-align: center;
}
</style>

