<template>
    <v-layout
        row
        wrap
    >
        <h2 class="custom_user">
            {{ $t('administration.user.search.mainTitle') }}
        </h2>

        <!-- PROGRESS BAR -->
        <v-layout
            v-if="isLoading"
            align-center
            justify-center
            row
        >
            <v-progress-circular
                :size="70"
                :indeterminate="true"
                color="primary"
            />
        </v-layout>

        <!-- DATA TABLES -->
        <v-flex
            v-if="!isLoading"
            xs12
        >
            <v-data-table
                :headers="headers"
                :items="userList"
                hide-actions
                disable-initial-sort
                align-center
                justify-center
                class="elevation-1"
            >
                // eslint-disable-next-line vue/no-parsing-error
                <template slot="no-data">
                    <td
                        colspan="6"
                        class="text-xs-center"
                    >
                        {{ $t('administration.user.search.none') }}
                    </td>
                </template>

                <template slot="items" slot-scope="props">
                    <td class="align-items">
                        {{ props.item.firstName }}
                    </td>
                    <td class="align-items">
                        {{ props.item.lastName }}
                    </td>
                    <td class="align-items">
                        {{ props.item.commonName }}
                    </td>
                    <td class="align-items">
                        {{ props.item.email }}
                    </td>
                    <td class="align-items">
                        {{ props.item.preferredLanguage }}
                    </td>
                    <td class="align-items">
                        <v-flex>
                            <v-chip class="ma-2" v-for="item in props.item.marketCodes" :key="item">
                                {{item}}
                            </v-chip>
                        </v-flex>
                    </td>
                    <td class="align-items">
                        <v-flex>
                            <v-chip class="ma-2" v-for="item in props.item.ldapRoleList" :key="item.name">
                                {{item.name}}
                            </v-chip>
                        </v-flex>
                    </td>
                    <td class="align-items">
                        <router-link
                            v-if="props.item.uid !== ADMIN_UID"
                            tag="button"
                            :to="{name: 'UserEdit', params: { uid: props.item.uid }}"
                        >
                            <v-icon class="edit-icon">
                                edit
                            </v-icon>
                        </router-link>
                        <v-icon
                            v-if="props.item.uid !== ADMIN_UID"
                            class="edit-icon"
                            @click="openConfirmationDialog(props.item.uid)"
                        >
                            delete
                        </v-icon>
                    </td>
                </template>
            </v-data-table>

            <v-dialog
                v-model="dialog"
                persistent
                max-width="350"
                icon
            >
                <v-card>
                    <v-card-title class="headline">
                        {{ $t('administration.user.manage.delete.title') }}
                    </v-card-title>
                    <v-divider />
                    <v-card-text>{{ $t('administration.user.manage.delete.message') }}</v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            color="primary"
                            small
                            flat
                            @click.native="dialog = false"
                        >
                            {{ $t('administration.user.manage.delete.cancel') }}
                        </v-btn>
                        <v-btn
                            color="primary"
                            small
                            flat
                            @click.native="deleteUser()"
                        >
                            {{ $t('administration.user.manage.delete.confirm') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-flex>

        <!-- FLOATING BUTTON (ADD) -->
        <v-flex
            xs12
            text-xs-right
        >
            <v-tooltip left>
                <v-btn
                    slot="activator"
                    color="primary"
                    small
                    fab
                    dark
                    icon
                    class="icon-add-custom"
                    :to="{name: 'UserAdd'}"
                >
                    <v-icon dark>
                        add
                    </v-icon>
                </v-btn>
                <span>{{ $t('administration.user.manage.action.add') }}</span>
            </v-tooltip>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";

export default {
    name:'Search',
    data () {
        return {
            ADMIN_UID: "00000000-0000-0000-0000-000000000001",
            userList:[],
            isLoading: false,
            dialog: false,
            headers:[
                {
                    text: this.$t('administration.user.label.firstName'),
                    value:"firstName",
                    sortable: false,
                    align: 'center',
                },
                {
                    text: this.$t('administration.user.label.secondName'),
                    value:"secondName",
                    sortable: false,
                    align: 'center',
                },
                {
                    text: this.$t("administration.user.label.givenName"),
                    value:"givenName",
                    sortable: false,
                    align: 'center',
                },
                {
                    text: this.$t("administration.user.label.email"),
                    value:"email",
                    sortable: false,
                    align: 'center',
                },
                {
                    text: this.$t("administration.user.label.language"),
                    value:"preferredLanguage",
                    sortable: false,
                    align: 'center',
                },
                {
                    text: this.$t("administration.user.label.market"),
                    value:"market",
                    sortable: false,
                    align: 'center',
                },
                {
                    text: this.$t("administration.user.label.roles"),
                    align: 'center',
                },
                {
                    text: this.$t("administration.user.label.actions"),
                    align: 'center',
                }
            ],
        }
    },
    created(){
        if (this.login) this.getAllUsers();
    },
    computed: {
        ...mapState({
            login: state => state.userStore.userPreferences.login,
        }),
    },
    watch: {
        login () {
            this.getAllUsers();
        }
    },
    methods: {
        /**
         * filter the paginated users
         */
        getAllUsers() {
            this.isLoading = true;
            axios.post( 
                `administration/user/all`
            ).then( result => {
                this.isLoading = false;
                this.userList = result.data;
            });
        },
        openConfirmationDialog(userId) {
            this.dialog = true;
            this.userIdToDelete = userId;
        },
        deleteUser() {
            this.dialog = false;
            axios.delete(
                `administration/user/${this.userIdToDelete}`
            ).then( () => {
                this.getAllUsers();
            });
        }
    }
}

</script>
<style scoped>
    .custom_user{
        background-color: #355B7F;color: #ffffff;padding-top:25px;padding-bottom:25px;text-align: center;width:100vw;
    }
    .align-items{
        text-align: center;
    }
    .icon-add-custom{
        position: fixed;top: 80px;right: 15px;
    }
</style>
