<template>
    <v-layout row wrap align-left>
        <v-flex>
                <v-card v-if="!!rootNode" id="container" @click="cancelHyperlinkVisualization">
                    <v-card-text >
                        <v-tooltip left>
                            <v-btn color="white" fab small class="position_structure margin-settings" slot="activator" @click="isStructureVisible = !isStructureVisible" >
                                <v-icon color="primary" medium v-if="!isStructureVisible">visibility</v-icon>
                                <v-icon color="primary" medium v-else>visibility_off</v-icon>
                            </v-btn>
                            <span>{{ $t( structureButtonText ) }}</span>
                        </v-tooltip>
                        <overview-document-node :rootNode="rootNode.children" :isStructureVisible="isStructureVisible"/>
                    </v-card-text>
                </v-card>
        </v-flex>
    </v-layout>
</template>
<script>
import { mapState, mapActions} from 'vuex';
import EventBus from '../../../utils/event-bus.js';
import OverviewDocumentNode from '../overview/OverviewDocumentNode';

/**
 * Create or Edit a Document
 * 
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 12/10/2018
 */
export default {
    name: 'OverviewDocument',
    data: () => ({
        isStructureVisible: false
    }),
    computed: {
        ...mapState({
            rootNode: state => state.documentContent.rootNode
        }),
        structureButtonText(){
            if( !this.isStructureVisible ){
                return 'global.action.showStructure';
            } else {
                return 'global.action.hideStructure';
            }
        }
    },
    components: {
        OverviewDocumentNode
    },
    methods:{
        cancelHyperlinkVisualization(event){
            event.preventDefault();
        }
    }
}
</script>
<style scoped>
    #container{
        width:100%;
    }
.position_structure {
    float: right;
    margin-right: 30px;
}
.margin-settings {
    width: 45px;
    height: 45px;
}
</style>
