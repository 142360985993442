<template>
    <div class="node-style" >
        <div v-for="node in rootNode" :key="node.id" :class="node.type"  >
            <div  class="span_style" v-if="isTitleShowable(node)"  >
                <span  v-if="node.label !== 'TEXT'"><b>{{node.label}}</b></span>
                <v-icon
                    v-if="isSelectable(node) "
                    align-end justify-end
                    medium
                    @click="saveNodeIdAndNature(node)"
                    class="add-link"
            >
                add_circle_outline
            </v-icon>

            <v-layout row @click="cancelHyperlinkVisualization" v-if="isTitleShowable(node) ">
                <v-layout>
                    <v-flex  align-start justify-start >
                    <div  v-html="node.content" :id="node.id"></div>
                    </v-flex>
                </v-layout>
            </v-layout>
                <node-document-to-link v-if="!!node.children" :rootNode="node.children" :allowedTagName="allowedTagName" :parentRootNode="node"/>
            </div>
            <div v-else>
                <node-document-to-link v-if="!!node.children" :rootNode="node.children" :allowedTagName="allowedTagName" :parentRootNode="node"/>
            </div>
        </div>
    </div>
</template>
<script>
import EventBus                 from '../../../../utils/event-bus.js';
import ConstantEvent            from '../../../../shared/constant/constantEvent.js';
import ConstantTag              from '../../../../shared/constant/constantTag.js';
/**
 * Display a node with his content if he got one.
 * Display all his children using a recursively way
 *
 * @author Romain Gentilhomme, SFEIR Benelux
 * @version 1.0
 * @since 07/01/2020
 */
export default {
    name: "NodeDocumentToLink",
    props: ["rootNode","parentRootNode","allowedTagName"],
    methods:{
        /**
         * send the id of the node that the user want to bind in the link
         */
        saveNodeIdAndNature: function( node ){
            if(node.label !== ConstantTag.RESUME) {
                EventBus.$emit(ConstantEvent.SEND_ID_NODE_DESTINATION, {node: node, rootNode: node});
            }else{
                EventBus.$emit(ConstantEvent.SEND_ID_NODE_DESTINATION, {node: node.children[0], rootNode: node});

            }
        },
        /**
         * Cancel the left click on the link
         */
        cancelHyperlinkVisualization(event) {
            event.preventDefault();
        },
        isSelectable(node){
                switch (this.allowedTagName) {
                    case ConstantTag.ARTICLE :
                        return this.parentRootNode.label === ConstantTag.NUM_ARTICLE;
                    case  ConstantTag.RESUME :
                        return node.label === ConstantTag.RESUME ;
                }
        },
        isTitleShowable(node){
            switch (this.allowedTagName) {
                case ConstantTag.ARTICLE :
                    return node.label === ConstantTag.NUM_ARTICLE
                        || node.label === ConstantTag.ARTICLE
                        || node.label === ConstantTag.TEXT;
                case  ConstantTag.RESUME :
                    return node.label === ConstantTag.RESUME
                        || node.label === ConstantTag.TEXT;
            }
        }
    },

}
</script>
<style>
    .node-style{
        width:100%;
    }
.span_style{
    border: 1px dotted #007bff33;
    border-radius: 10px;
    margin: 5px;
    padding: 10px 5px 5px;
}
    .add-link{
        float:right;
        background-color: #355B7F;
        border-radius: 50px;
        color:white !important;
        margin-top:-9px;
        height:50px;
        width:50px;
    }
.add-link:hover{
    transform: scale(1.2);
}

</style>


