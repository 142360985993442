import FootnoteService  from './footnoteService.js';
import ConstantTag      from '../../shared/constant/constantTag.js';
/**
 * 
 * Manage all the operation related to the document footnotes
 * 
 * @author Sébastien DE SANTIS
 * @since 23/10/2018
 * @version 1.0
 */
class DocumentFootnoteService{

    constructor(){

    }
    /**
     * Extract a footnote list from the footnote content
     * @param {*} node 
     */
    extractFootnoteListFromNode(node){
        var footnoteList = [];
        if(!!node && node.children){
            var div = document.createElement('div');
            div.innerHTML =  node.children[0].content;

            var table = div.firstChild;
            var rows = table.rows;
            for(let i = 0; i < rows.length; i++){
                var row = rows[i];
                var cells = row.cells;
                var footnote = {
                    id: cells[0].id,
                    rank: cells[0].innerText,
                    content: cells[1].innerHTML
                }
                footnoteList.push(footnote);
            }
        }
        return footnoteList;
    }

    /**
     * Set footnote style depending on the rank of the first element of the footnoteList
     * @param {*} rank 
     */
    setFootnoteStyle( rank ){
        var REGEX_CASUAL_NUMBER             = new RegExp(/^[0-9]{1,2}$/g);
        var REGEX_BRACKET_NUMBER            = new RegExp(/^\([0-9]{1,3}\)$/g);
        var REGEX_DOTTED_NUMBER             = new RegExp(/^[0-9]{1,3}\.$/g);
        var REGEX_CASUAL_STAR               = new RegExp(/^\*{1,}$/g);
        var REGEX_BRACKET_STAR              = new RegExp(/^\(\*{1,}\)$/g);
        if(REGEX_CASUAL_NUMBER.test(rank))  {return 1;}
        if(REGEX_DOTTED_NUMBER.test(rank))  {return 2;}
        if(REGEX_BRACKET_NUMBER.test(rank)) {return 3;}
        if(REGEX_CASUAL_STAR.test(rank))    {return 4;}
        if(REGEX_BRACKET_STAR.test(rank))   {return 5;}
    }

    /**
     * Convert a footnote list into a footnote node content
     * @param {*} footnoteList 
     */
    convertFootnoteListToNode(footnoteList, footnoteStyle){
        var table = document.createElement('table');

        var orderFootNotList = footnoteList.sort((a, b) => parseFloat(a.rank) - parseFloat(b.rank));

        orderFootNotList.forEach((footnote, index) => {
            var row = table.insertRow(index);
            var cell = row.insertCell(0);
            if (footnote.rank != '0') {
                let innerHTML = FootnoteService.createStyleOnRank(footnote.rank, footnoteStyle);
                cell.innerHTML = innerHTML;
            } else {
                let innerHTML = FootnoteService.createStyleOnRank(footnote.rank, footnoteStyle);
                cell.innerHTML = '<span style="visibility: hidden">' + innerHTML + '</span>';
            }
            cell.id = footnote.id;
            var cell2 = row.insertCell(1);
            cell2.innerHTML = footnote.content;
        });
        return table;
    }

    /**
     * Create a footnote
     * @param {String} content 
     */
    createDocumentFootnote(content) {
        return FootnoteService.createFootnote(content);
    }
    /**
     * Create a footnote link
     * @param {*} footnote 
     */
    createFootnoteLink(footnote){
        let a = document.createElement('a');
        a.id= FootnoteService.generateUid();
        a.className = 'custom-footnote-index mceNonEditable';
        a.href = `#${footnote.id}`;
        a.innerHTML = '*';
        return a;
    }
    /**
     * Reorder the footnote of the application and change the anchor index inside the document content.
     * @param {*} footnoteList 
     * @param {*} flattedContentTree 
     */
    orderFootnoteListInContent(footnoteList, footnoteStyle, flattedContentTree){
        var rank = 1;
        var calculatedFootnoteList = [];

        /**
         * Browse the flatted tree
         */
        let flattedContentTreeCopy = flattedContentTree.map( node => {
            if(node.type === ConstantTag.TEXT){
                //Check if the text node contains a footnote anchor
                let footnoteAnchorList = node.content.match(RegExp("<a [^>]*?class=\"custom-footnote-index.*?<\/a>", "gm"));
                if( footnoteAnchorList ){
                    let content = node.content;
                    footnoteAnchorList.forEach( footnoteAnchor => {
                        //Extract the footnote ID
                        let startIndex = footnoteAnchor.indexOf(`href="#`) + 7;
                        let footnoteId = footnoteAnchor.substring(startIndex, startIndex + 36 );
                        //For each footnote of the document
                        footnoteList.forEach( footnote => {
                            if(footnote.id === footnoteId){
                                //If the anchor is related to a foonote
                                let alreadyExists = calculatedFootnoteList.find( calculatedFootnote => {
                                    return calculatedFootnote.id === footnoteId;
                                });
                                //If the footnote rank already been calculated (footnote already spotted sooner in the document)
                                //The rank is not updated otherwise, the rank is updated
                                if(!alreadyExists){
                                    footnote.rank = rank++;

                                    calculatedFootnoteList.push(footnote);
                                }
                                //Return a couple node + updated content
                                content = content.replace(footnoteAnchor, footnoteAnchor.replace(/>.*?</, `>${FootnoteService.createStyleOnRank(footnote.rank, footnoteStyle)}<`));
                            }
                        });
                    });
                    return {node:node, content: content};
                }
            }
        });
        return flattedContentTreeCopy.filter(couple => !!couple);
    }
    /**
     * Delete the footnote of the application and change the anchor index inside the document content.
     * @param {*} footnoteList 
     * @param {*} flattedContentTree 
     */
    deleteFootnoteInContent(footnoteList, footnoteId, flattedContentTree){
        // Browse the flatted tree
        flattedContentTree.forEach( node => {
            if(node.type === ConstantTag.TEXT){
                //Check if the text node contains a footnote anchor
                var footnoteAnchorList = node.content.match(RegExp("<a [^>]*?class=\"custom-footnote-index.*?<\/a>", "gm"));
                if( footnoteAnchorList ){
                    footnoteAnchorList.forEach( footnoteAnchor => {
                        var startIndex = footnoteAnchor.indexOf(`href="#`) + 7;
                        var footnoteAnchorId = footnoteAnchor.substring(startIndex, startIndex + 36 );
                        if(footnoteAnchorId === footnoteId){
                            node.content = node.content.replace(footnoteAnchor, '');
                        }
                    });
                }
            }
        });
    }
    /**
     * Delete the footnote in the the footnoteList
     * @param {*} footnoteList 
     */
    deleteFootnoteInFootnoteList(footnoteList, footnoteId){
        var indexIdFootnote = footnoteList.findIndex(function(element) {
            return element.id === footnoteId;
        });

        return footnoteList.splice(indexIdFootnote, 1);
    }

    /**
     * Reorder the footnote and put the unlink footnote at the end of the list
     * @param {Array} footnoteList 
     * @param {number} footnoteId 
     */
    manageFootnoteInFootnoteList(footnoteList, footnoteId){
        let documentFootnote = footnoteList.find(footnote =>footnote.id === footnoteId);
        const documentFootnoteIndex = footnoteList.indexOf(documentFootnote);
        footnoteList.splice(documentFootnoteIndex, 1);
        documentFootnote.rank = '0';
        footnoteList.push(documentFootnote);
        return footnoteList;
    }

}

export default new DocumentFootnoteService();