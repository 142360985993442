<template>
    <v-dialog v-model="openDialog" fullscreen >
        <v-layout row align-start justify-center class="document_hyperlink_sizer custom-card" >
            <v-flex v-if="displaySpinner">
                <v-progress-circular
                    :size="70"
                    :indeterminate="true"
                    color="primary">
                </v-progress-circular>
            </v-flex>
            <div class="header-color">
                <h3 v-if="!!chosenTitleDocumentDestination" class="bol-title-style">
                    <p class="display-1">{{$t('pao.summary.linkto')}} {{chosenTitleDocumentDestination}}</p>
                </h3>
                <div class="backtodoc" @click="resetComponent" >
                    <bol-back-button :textBackButton="backToDocument" />
                </div>
            </div>
            <v-flex v-if="!displaySpinner" >
                    <div v-if="currentRootNode">
                        <node-document-to-link class="content-document" :rootNode="currentRootNode.children" :allowedTagName="allowedTagName" :parentRootNode="currentRootNode"/>
                    </div>
            </v-flex>
        </v-layout>
    </v-dialog>
</template>

<script>
import axios                            from 'axios'
import EventBus                         from '../../../../utils/event-bus';
import ConstantEvent                    from '../../../../shared/constant/constantEvent.js';
import NodeDocumentToLink               from './NodeDocumentToLink';
import ConstantTag                      from "../../../../shared/constant/constantTag";
import ConstantRelationType             from "../../../../shared/constant/constantRelationType";
import BolBackButton                       from "../../../shared/BolBackButton";

const UPDATE_DIALOG = 'update:dialog';
/**
 *
 * Display content of the chosen document and
 * give possibility to choose a node to link
 *
 *
 * Document search component.
 * @author Romain Gentilhomme, SFEIR benelux
 * @version 1.0
 * @since 07/01/2020
 * 
 */
export default {
    name: "DialogDocumentContentToLink",
    props: {
        dialog: {
            default: false
        }
    },
    data(){
        return {
            displaySpinner: false,
            currentRootNode: [],
            referenceDocumentDestination: null,
            chosenTitleDocumentDestination: null,
            currentDocumentId:null,
            allowedTagName:null,
            relationType:null
        }
    }, 
    computed:{
        openDialog: {
            get() {
                return this.dialog;
            },
            set(dialog) {
                if(!dialog) {
                    this.$emit(UPDATE_DIALOG, false);
                }
            }
        },
        backToDocument(){
            return this.$t('global.action.backTodoc');
        }
    },
    /**
     * Open the dialog and set value if needed
     */
    created: function(){
        EventBus.$on(ConstantEvent.OPEN_DIALOG_DOCUMENT_NODE_HYPERLINK, this.listener);
        EventBus.$on(ConstantEvent.SEND_ID_NODE_DESTINATION, this.selectIdNodeDestination);
    },
    /**
     * Destroy the even bus before the component destruction
     */
    beforeDestroy: function(){
        EventBus.$off(ConstantEvent.OPEN_DIALOG_DOCUMENT_NODE_HYPERLINK);
        EventBus.$off(ConstantEvent.SEND_ID_NODE_DESTINATION, this.selectIdNodeDestination);
    },
    methods:{
        listener( data ){
            if(!!data ){
                this.loadDocumentContent(data.referenceDocumentDestination);
                this.referenceDocumentDestination = data.referenceDocumentDestination;
                this.chosenTitleDocumentDestination = data.chosenTitleDocumentDestination;
                this.relationType = data.relationType;
                if(this.relationType !== ConstantRelationType.NONE){
                    this.allowedTagName = ConstantTag.RESUME;
                }else{
                    this.allowedTagName = ConstantTag.ARTICLE;
                }
            }
        },
        loadDocumentContent(referenceDocumentDestination){
            this.displaySpinner = true;
            axios.get(`link/document/reference/${referenceDocumentDestination}`)
            .then( result => {
                    this.displaySpinner = false;
                    this.currentRootNode = result.data.content;
                    this.currentDocumentId = result.data.id;
            });
        },
        selectIdNodeDestination( { node,rootNode } ){

            let div = document.createElement("div");
            div.innerHTML = node.content;
            let contentNode = div.innerText.substring(0,200);
            let nodeDetails = {
                "contentDocumentDestination":contentNode,
                "DestinationDocumentID":this.currentDocumentId,
                "DestinationDocumentNodeUUID": rootNode.id,
                "titleDocumentDestination":this.chosenTitleDocumentDestination,
                "typeLinkDestination":this.allowedTagName,
                "relationDocumentType": this.relationType
            };
            this.$emit('closeDocumentNode',nodeDetails);
            this.resetComponent();
        },
        cancel(){
            this.$emit('closeDocumentNode');
            this.resetComponent();
        },
        resetComponent(){

            this.referenceDocumentDestination = null;
            this.chosenTitleDocumentDestination = null;
            this.$emit(UPDATE_DIALOG, false);
        }
    },
    components: {
        NodeDocumentToLink,
        BolBackButton
    }
}
</script>
<style>
    .custom-card{
        background-color: white;
        height:100vh;
        width:100vw;
    }
    .header-color{
        position:absolute;
        height:140px;
        width:100%;
        background-color: #355B7F;
    }
.display-1 {
    font-size: 34px !important;
    font-weight: 400;
    line-height: 40px !important;
    letter-spacing: normal !important;
    font-family: 'Roboto', sans-serif !important;
}
.bol-title-style{
    position:absolute;
    top:60px;
    left:320px;
    color:white;
}
.document_hyperlink_sizer{
    width: 100%;
    height:100vh;
}
.backtodoc{
    font-weight: bold;
    position:absolute;
    color:#D39E4E;
    width:300px;
    height:20px;
    top:90px;
    left:20px;
}
    .backtodoc:hover{
        transform: scale(1.1);
    }
.content-document{
    margin-top: 150px;
    padding-top:20px;
    position:absolute;
    z-index: 999;
    overflow: scroll;
    background-color: white;
}
</style>

