<template>

    <v-dialog style="card-background" v-model="openDialog" persistent width="500px" v-if="!!tocToShow" >
        <v-card  min-height="300px">

            <v-card-title><h2>{{$t('pao.structure.node.paotitle')}}</h2></v-card-title>
            <v-divider></v-divider>

            <div class="toc-container" >

                <div  class="text_folder">{{structure.label}}</div>

                <div  v-if="tocToShow.type === $t('pao.structure.node.PART')"  class="parent" >
                    <span class="text_part">{{tocToShow.label}}</span>
                </div>

            <div  v-for="doc in tocToShow.children" :key="doc.id">
                <div  v-if="doc.type === $t('pao.structure.node.PART')"  class="parent" >
                    <span class="text_part">{{doc.label}}</span>
                </div>

                    <div v-if="doc.type === $t('pao.structure.node.ACTS')">
                        <div v-for="child in doc.documentList" :key="child.id">
                            <div  class="child" >
                                <span class="text_doc">{{child.title}}</span>
                            </div>
                        </div>
                    </div>


                    <div  v-for="document in doc.children" :key="document.id">
                        <div v-if="document.type === $t('pao.structure.node.ACTS')">
                            <div v-for="child in document.documentList" :key="child.id">
                                <div  class="child" >
                                    <span class="text_doc">{{child.title}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" flat @click.native="close();">{{$t('document.confirm.ok')}}</v-btn>
            </v-card-actions>

        </v-card>

    </v-dialog>

</template>

<script>

    const UPDATE_DIALOG  = 'update:dialog';
    import ConstantEvent                from '../../../shared/constant/constantEvent.js';
    import EventBus                     from '../../../utils/event-bus';

    export default {
        name: "TocVisualisatorDialogComponent",
        props: {
            dialog: {
                default: false
            },
            node:{
                default:null
            },

        },
        mounted(){
            EventBus.$on(ConstantEvent.SEND_ID_TOC, this.getIdState);
        },
        created(){
            this.structure = this.node;
        },
        beforeDestroy(){
            EventBus.$off(ConstantEvent.SEND_ID_TOC);
        },
        methods:{

            /**
             * check with the idToc where is the toc selected inside pao struct
             * if toc is found here its a general toc
             * if not, call getTocStructure to look if it is inside part of pao
             **/
            getIdState(event){
                if(!!event) {
                    this.idToc = event;
                    let self = this;
                    this.parent = this.structure;
                    this.structure.children.forEach(function (item) {
                        if (item.type === self.$i18n.t('pao.structure.node.TOC')) {
                             if (item.id === event) {
                                 self.tocToShow = self.parent;
                             }
                         }
                        self.getTocStructure(item);

                    })
                }
            },
            /**
             * check with the idToc where is the toc selected inside pao PART
             * if toc is found here its an intermediate TOC
             **/
            getTocStructure(node){
                    let self = this;
                    if(node.type === self.$i18n.t('pao.structure.node.PART')) {
                        this.parent = node;
                    }
                    if(!!node.children) {
                        node.children.forEach(function (item) {
                              if (item.type === self.$i18n.t('pao.structure.node.TOC')) {
                            if (item.id === self.idToc) {
                                self.tocToShow = self.parent;
                            }
                        }
                            self.getTocStructure(item,node);
                        })
                    }
            },
            close(){
                this.tocToShow = null;
                this.idToc = null;
                this.parent = null;
                this.$emit(UPDATE_DIALOG, false);
            },

        },
        computed:{

            openDialog: {
                get(){
                    return this.dialog;
                },
                set( dialog ){
                    if( !dialog ){
                        this.$emit(UPDATE_DIALOG, false);
                    }
                }
            },
        },
        data(){
            return {
                structure:null,
                idToc:null,
                tocToShow:null,
                parent:null,
            }
        },
        components:{
            ConstantEvent,
            EventBus

        }
    }



</script>

<style scoped>
    .card-background{
        background:white;
    }
    .toc-container{
        width:500px;
    }
    .selected_folder{
        color:brown;
        margin-left:40px;
        margin-top:20px;
        font-size:2.0em;
    }
    .text_folder{
        background:#355B7F;
        font-size:1.2em;
        font-weight:bold;
        height:25px;
        width:100%;
        color:white;
        padding-left:8px;
    }
    .text_part{
        color:#355B7F;
        margin-left:5px;
        font-weight:bold;
    }
    .text_doc{
        margin-left:5px;
    }
    .parent{
        margin-left:40px;
        margin-top:10px;
        text-align:left;
    }
    .child{
        margin-left:60px;
        text-align:left;
        margin-top:10px;
    }
</style>