<template>
    <div>
        <v-tabs>
            <v-tab>
                {{this.$t('navigation.label.document')}}
            </v-tab>
            <v-tab>
                {{this.$t('navigation.administration.publication')}}
            </v-tab>
            <v-tab>
                {{this.$t('navigation.administration.system.label')}}
            </v-tab>
            <v-tabs-items class="tabs-items">
                <v-tab-item>
                    <bol-document-administration />
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                            <bol-publication-administration />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                            <bol-system-administration @open-dialog="openDialog()" :confirm="confirm"/>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
        <bol-dialog-confirmation @confirm="confirmCallBack()"/>
    </div>
</template>
<script>
import BolDocumentAdministration    from './overview/Document'
import BolPublicationAdministration from './overview/Publication'
import BolSystemAdministration      from './overview/System'
import BolDialogConfirmation        from '../shared/BolDialogConfirmation'


/**
 * 
 * Main content for the administration 
 * @author Sébastien DE SANTIS, SFEIR Benelux
 * @version 1.0
 * @since 19/02/2020
 */
export default {
    data(){
        return {
            openConfirmation: false,
            confirm: false,
            confirmationMessage: "administration.system.correct-links.confirmation-message"
        }
    },
    components: {
        BolDocumentAdministration,
        BolPublicationAdministration,
        BolSystemAdministration,
        BolDialogConfirmation
    },
    methods:{
        openDialog(){
            this.openConfirmation = true;
        },
        confirmCallBack(){
            this.confirm = true;
        }
    }
    
}
</script>
<style scoped>
.tabs-items{
    background-color: white;
    height: 100vh;
    padding: 25px;
    vertical-align: middle;
}
</style>