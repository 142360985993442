<template>
    <v-layout
        row
        wrap
        align-left
    >
        <v-flex>
            <v-container class="t_create_edit_dialog">
                <h1
                    v-if="$route.params.id == null"
                    style="font-weight: bold;color: #355B7F"
                >
                    {{ $t('document.label.newJurisdiction') }}
                </h1>
                <h1
                    v-if="$route.params.id != null"
                    style="font-weight: bold;color: #355B7F"
                >
                    {{ $t('document.label.editJurisdiction') }}
                </h1>
                <v-form
                    ref="formAddJurisdiction"
                    v-model="valid"
                    class="form-style"
                    lazy-validation
                >
                    <v-text-field
                        v-model="newJurisdiction.code"
                        class="t_code"
                        :label="$t('document.jurisdiction.code')"
                        :rules="codeRules"
                        required
                        clearable
                    />
                    <v-text-field
                        v-model="newJurisdiction.label"
                        class="t_label"
                        :label="$t('document.jurisdiction.label')"
                        :rules="labelRules"
                        required
                        clearable
                    />
                    <v-select
                        v-model="newJurisdiction.idJurisprudenceType"
                        class="t_type"
                        :label="$t('document.jurisdiction.jurisprudenceType')"  
                        :rules="typeRules"                      
                        :items="jurisprudenceList"
                        item-value="id"
                        item-text="label"      
                        required
                        clearable
                    />
                    <v-textarea
                        v-model="newJurisdiction.description"
                        class="t_description"
                        :label="$t('document.jurisdiction.description')"
                        clearable
                        auto-grow
                        rows="1"
                    />
                    <!-- MARKET -->
                    <v-autocomplete
                        v-model="newJurisdiction.markets"
                        class="t_markets"
                        :items="allMarketList"
                        :disabled="allMarketList.length === 0"
                        dense
                        filled
                        multiple
                        chips
                        small-chips
                        item-text="code"
                        return-object
                        label="Markets"
                    />
                    <!-- BUTTONS -->
                    <v-btn
                        v-if="$route.params.id == null"
                        class="t_add"
                        color="#355B7F"
                        style="float:right;color:white"
                        @click="add(newJurisdiction)"
                    >
                        {{ $t("document.action.add") }}
                    </v-btn>
                    <v-btn
                        v-if="$route.params.id != null"
                        class="t_save"
                        color="#355B7F"
                        style="float:right;color:white"
                        @click="add(newJurisdiction)"
                    >
                        {{ $t("document.action.save") }}
                    </v-btn>
                </v-form>
            </v-container>
        </v-flex>
    </v-layout>
</template>
<script>
import axios from 'axios';


/**
 * 
 * Form to create or update a jurisdiction
 * 
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 20/09/2018
 */
export default {
    name:'AddJurisdiction',
    data () {
        return {
            valid: false,
            jurisprudenceList: [],
            newJurisdiction: {
                id: "",
                code: "",
                label: "",
                description: null,
                jurisprudenceType: null,
                idJurisprudenceType: "",
                markets: []
            },
            allMarketList: [],
            codeRules: [
                v => !!v || this.$t("document.jurisdiction.required.code")
            ],
            labelRules: [
                v => !!v || this.$t("document.jurisdiction.required.label")
            ],
            typeRules: [
                v => !!v || this.$t("document.jurisdiction.required.type")
            ],
        }
    },
    beforeMount() {
        /**
         * By default the page will charge all the jurisdiction
         */
        this.loadJurisprudence();
    },
    mounted(){
        /**
         * When the page is charging, we pay attention if an id is pass,
         * if it's the case, it means that we update a document instead of create one
         */
        if(this.$route.params.id !== undefined) {
            this.loadOneJurisdiction(this.$route.params.id);
        }
        this.loadMarkets();
    },
    methods:{
        /**
         * Method 'add' will create or update a jurisdiction document
         * depending on the document's id only trigger if the form is valid
         * @param newJurisdiction
         */
        add(newJurisdiction) {
            if(this.$refs.formAddJurisdiction.validate()){
                if(this.$route.params.id) {
                    newJurisdiction.id = parseInt(this.$route.params.id);
                    axios.put("administration/jurisdiction", newJurisdiction)
                        .then( () => {
                            this.$router.push('/administration/documentjurisdiction');
                        }, () => {
                            this.error= true;
                            this.message = this.$t('login.error.credentials.bad');
                        });
                }else {
                    axios.post("administration/jurisdiction", newJurisdiction)
                        .then( () => {
                            this.$router.push('/administration/documentjurisdiction');
                        }, () => {
                            this.error= true;
                            this.message = this.$t('login.error.credentials.bad');
                        });
                }
            }
        },
        /**
         * Methode will load the list of jurisprudence
         */
        loadJurisprudence() {
            axios.get("administration/jurisdiction/init/jurisprudenceType")
                .then( ({data}) => {
                    this.jurisprudenceList = data;
                });
        },
        /**
         * Method retrieve a jurisdiction with his id
         * @param id
         */
        loadOneJurisdiction( id ) {
            axios.get(`administration/jurisdiction/${id}`)
                .then( ({data}) => {
                    this.newJurisdiction.code = data.code;
                    this.newJurisdiction.label = data.label;
                    this.newJurisdiction.description = data.description;
                    this.newJurisdiction.idJurisprudenceType = data.idJurisprudenceType;
                    this.newJurisdiction.markets = data.markets;
                });
        },
        loadMarkets() {
            axios.get(
                `administration/markets`
            ).then(result => {
                this.allMarketList = result.data;
            });
        }
    }
}
</script>
<style scoped>
.form-style {
    margin-top:50px;
}
</style>
