const ATTACHED_CONTENT_OPERATION    = 'attached_content_operation';
const CONTENT_IMPORT_OPERATION      = 'content_import_operation';
/**
 * All the kind of upload operation available
 * @author Sébastien DE SANTIS
 * @since 15/05/2019
 * @version 1.0
 */
export default class ConstantUploadOperationType {
    static get ATTACHED_CONTENT_OPERATION(){
        return ATTACHED_CONTENT_OPERATION;
    }
    static get CONTENT_IMPORT_OPERATION(){
        return CONTENT_IMPORT_OPERATION;
    }
}