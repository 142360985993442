const CTRL  = 17;
const F6    = 117;
const F7    = 118;
const F8    = 119;
const x     = 88;

/**
 * Store all the key code
 * @author Sébastien DE SANTIS
 * @since 17/01/2020
 * @version 1.0
 */
export default class ConstantKeyCode {

    static get CTRL(){
        return CTRL;
    }

    static get F6(){
        return F6;
    }

    static get F7() {
        return F7;
    }

    static get F8(){
        return F8;
    }

    static get x(){
        return x;
    }
}