<template>
    <router-view />
</template>

<script>

export default {
    name: 'User'
}

</script>