
import ConstantDocumentNode         from '../../shared/constant/constantDocumentNode.js';
import ConstantNode                 from '../../shared/constant/constantNode.js';
import ConstantNodeContentElement   from '../../shared/constant/constantNodeContentElement.js';
import ConstantTag                  from '../../shared/constant/constantTag.js';
import DomManipulatorService        from '../../service/html/domManipulatorService.js';
import DocumentTreeService          from '../../service/document/documentTreeService.js';

class TableHtmlService {

    constructor(){
        this.regexpTABLE = /<table\s?.*>.*<\/table>/gm;
        this.regexpOneTR = /<tr\s?.*>.*<\/tr>/gm;
        this.regexpTD = /^(<td\s?.*>.*<\/td>)/gm;
        this.regexpFootnote = /<tfoot\s?.*<\/tfoot>/gm;
    }

    /****************************************** GET ****************************************************/
    /**
     * Find the first tBody element inside the table element and its parent
     * @param {Object} element
     */
    findFirstTBodyFromTable(element){
        let node = element;

        while( node.parentNode.tagName.toLowerCase() !== ConstantTag.TABLE){
            node = node.parentNode;
        }

        return node;
    }
    // Return only the table element from the text copied from clipboard
    getTableWithoutTextAndFootnotes(text){
        if ( !!text.match(this.regexpTD) ){
            text = "<table><tr>"+ text +"</tr></table>"
        } else if ( !!text.match(this.regexpOneTR) && !text.match( this.regexpTABLE ) ){
            text = "<table>"+ text + "</table>"
        } else if ( !!text.match(this.regexpTABLE)) {
            text = text.match(this.regexpTABLE)[0];
        } else {
            text = "<table><tr><td>" + text + "</td></tr></table>"
        }

        let tableElement = DomManipulatorService.createHTMLElementFromHtml( text );
        this.$removeSup(tableElement);
        if ( !!tableElement.tFoot ){
            tableElement.deleteTFoot();
        }
        return tableElement;
    }
    /**
     * Return the maximum number of columns of a table element which contains at least one tbody
     * @param {HTMLElement} element
     */
    $getNumberOfColumnsFromNode( element ){
        let tableArray = DomManipulatorService.getArrayFromHTMLTableElement( element );
        return this.$getRowsMaxLength(tableArray);
    }
    /**
     * Find first TR element inside the table and its children recursively
     * @param {HTMLElement} element
     */
    $getFirstRow(element){
        if( element.nodeName === ConstantTag.TR_UPPER_CASE){
            return element;
        } else {
            return this.$getFirstRow(element.children[ 0 ]);
        }
    }
    /**
     * Return the theoric cell numbers in a row of the tableRows
     * @param {Object} tableRows
     */
    $getRowsMaxLength(tableRows) {
        return Math.max(...(tableRows.map( row =>
            row.cells.reduce( (totalCell, cell) => totalCell + (cell.colSpan || 1), 0)
        )));
    }
    /**
     * Find the clicked row and is position in the table
     * @param {*} position, Specification to know where the new row should be added
     */
    $findNodeAndIndexOfTrInTable( position, windowSelection){
        let node = windowSelection.anchorNode;

        while( node.parentNode.tagName.toLowerCase() !== ConstantTag.TBODY){
            node = node.parentNode;
        }

        let offset = node.rowIndex;
        if( position === ConstantNode.WHERE_AFTER ) {
            offset += 1;
        }
        return { offset: offset, row: node };
    }


    /****************************************** CREATE ****************************************************/
    /**
     * Generic method to create a row for a table
     * @param {Number} numberOfTd, number of TD to create in the row
     */
    $createRow( numberOfTd ){
        let tr = document.createElement(ConstantTag.TR);

        for(let index = 0;  index < numberOfTd; index++){
            let tdContent = document.createElement(ConstantTag.TD);
            tr.appendChild(tdContent);
        }
        return tr;
    }


    /****************************************** CHECK ****************************************************/
    /**
     * Compare the number of columns of two table elements
     * @param {HTMLElement} firstTable
     * @param {HTMLElement} secondTable
     */
    checkNumberOfColumns(firstTable, secondTable){
        let firstNumberOfCols = this.$getNumberOfColumnsFromNode(firstTable);
        let secondNumberOfCols = this.$getNumberOfColumnsFromNode(secondTable);
        return firstNumberOfCols === secondNumberOfCols;
    }


    /****************************************** UPDATE ****************************************************/
    /**
     * Insert in an existing table at the choosen position the given row(s)
     * if no row(s) are passed to the method a new one is created
     * @param {*} currentTable, the current table
     * @param {*} position, the index where the row(s) should be inserted
     * @param {*} rowsToInsert, row(s) to insert in the table 
     */
    insertRowsInExistingTable( currentTable, position, windowSelection, rowsToInsert = false){
        let node = this.$findNodeAndIndexOfTrInTable( position, windowSelection );

        if(!rowsToInsert){
            rowsToInsert = this.$createRow( node.row.children.length ).outerHTML;
        } 
        return DomManipulatorService.insertHTMLInNode( currentTable, node.offset, rowsToInsert, ConstantDocumentNode.TEXT_NODE_CLASS);
    }
    /**
     * Remove the sup tags from its TD parent
     * @param {HTMLTableElement} tableHtml 
     */
    $removeSup( tableHtml ){
        let anchorElementList = tableHtml.getElementsByClassName(ConstantNodeContentElement.CUSTOM_TABLE_FOOTNOTE_INDEX_CLASS);
        Array.from(anchorElementList).forEach(anchorElement => {
            let supElement = anchorElement.parentNode;
            supElement.parentNode.removeChild( supElement );
        });
    }
}

export default new TableHtmlService();