<template>
    <v-dialog v-model="openDialog" persistent max-width="450px">
        <v-card>
            <v-card-title class="headline center">
                {{$t('pao.label.titlePaoDocument')}}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text @click="selectNode">
                <pao-node :node="structure" :readOnly="readOnly"/>
            </v-card-text>
            <v-divider></v-divider>
             <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" flat @click.native="close()">{{$t('pao.action.cancel')}}</v-btn>
                <v-btn color="primary" :disabled="isLink" flat @click.native="linkPaoNode">{{$t('pao.action.link')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import PaoNode                  from '../../pao/structure/PaoNode';
import PaoStructureStoreTypes   from '../../../shared/constant/store/module/paoStructureTypes';
import StoreModule              from '../../../shared/constant/store/module/namespace';
import { mapActions, mapState } from 'vuex';

const UPDATE_DIALOG = 'update:dialog';
const LINK_PAO_NODE = 'linkPaoNode';
/**
 * Dialog to choose a pao node to link to the document
 * @author Cédric de BOISVILLIERS, SFEIR benelux
 * @version 1.0
 * @since 09/09/2019
 * 
 */
export default {
    name: 'DialogPaoStructure',
    props: {
        dialog: {
            default: false
        },
        structure: null,
        readOnly:{
            default:true
        }
    },
    data(){
        return {
            selectedNodeId: null,
            isLink:true

        }
    },
    components:{
        PaoNode
    },
    computed: {
        openDialog: {
            get(){
                return this.dialog;
            },
            set( dialog ){
                if( !dialog ){
                    this.$emit(UPDATE_DIALOG, false);
                }
            }
        }, 
        ...mapState(StoreModule.PAO_STRUCTURE,{
            clickedNode: state => state.clickedNode,
        }),
    },
    methods: {
        /**
         * Update the clicked node in the store.
         */
        ...mapActions(StoreModule.PAO_STRUCTURE, {
            updateClickedNode: PaoStructureStoreTypes.ACTION.UPDATE_CLICKED_NODE
        }),
        /**
         * Select a node in the pao structure
         */
        selectNode(event) {
            const eventTargetList = event.composedPath();
            let targetIndex = 0;
            let eventTarget = eventTargetList[targetIndex];

            while( targetIndex < eventTargetList.length && (!eventTarget.className || !eventTarget.className.includes('pao-node')) ) {
                targetIndex++;
                eventTarget = eventTargetList[targetIndex];
            }
            if( !!eventTarget.className  && eventTarget.className.includes('pao-node') ) {
                this.updateClickedNode( eventTarget.id );
            } else {
                this.updateClickedNode( null );
            }
            this.manageLinkState();
        },
        /**
         * Link the selectedNode with a document
         */
        linkPaoNode(){
            if(this.clickedNode.type === this.$t('pao.structure.node.ACTS')) {
                this.$emit(LINK_PAO_NODE, this.clickedNode);
                this.close();
            }
        },
        /**
         *
         */
        manageLinkState(){
            if(!!this.clickedNode) {
                if (this.clickedNode.type === this.$t('pao.structure.node.ACTS')) {
                    this.isLink = false;
                }
                else {
                    this.isLink = true;
                }
            }
        },
        /**
         * Close the import dialog
         */
        close(){
            this.$emit(UPDATE_DIALOG, false);
            this.updateClickedNode( null );
        }
    }
}
</script>
<style scoped>
</style>
