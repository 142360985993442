<template>
    <v-dialog v-model="isOpen" persistent max-width="350" icon>
        <v-card>
            <v-card-title class="headline">{{$t('pao.dialog.add-content.title')}}</v-card-title>
            <v-divider></v-divider>
            <v-card-text >
                 <v-form 
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    >
                    <v-select
                        :items="items"
                        :label="$t('pao.dialog.add-content.label.content-type')"
                        item-value="code"
                        v-model="paoContentType"
                        required
                    >
                        <template slot="selection" slot-scope="data">
                            <!-- HTML that describe how select should render selected items -->
                                {{$t(`pao.dialog.add-content.content-type.${data.item.name}`)}}
                        </template>
                        <template slot="item" slot-scope="data">
                            <!-- HTML that describe how select should render items when the select is open -->
                                {{$t(`pao.dialog.add-content.content-type.${data.item.name}`)}}
                        </template>
                    </v-select>
                    <v-layout justify-center>
                        <bol-upload-file @setFile="setFile"/>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" small flat @click="cancel">{{$t('global.action.cancel')}}</v-btn>
                <v-btn color="primary" small flat @click="confirm">{{$t('global.action.confirm')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
   
</template>
<script>
import axios            from 'axios';
import EventBus         from '../../../../utils/event-bus'
import BolUploadFile    from '../../../shared/BolUploadFile'
import ConstantEvent    from '../../../../shared/constant/constantEvent'
/**
 * This dialog should manage the adding content operation
 * @author Sébastien De Santis
 * @date 03/03/2020
 * @version 1.0
 */
export default {
    name: "DialogAddContent",
    data(){
        return {
            items: [],
            file: null,
            paoContentType: '',
            valid: true,
            isOpen: false
        }
    },
    mounted(){
        EventBus.$on(ConstantEvent.OPEN_BOL_DIALOG_ADD_PAO_CONTENT, this.open)
        axios.get('/pao/contentType')
        .then((response) => {
            this.items = response.data.content;
        });
    },
    beforeDestroy(){
        EventBus.$off(ConstantEvent.OPEN_BOL_DIALOG_ADD_PAO_CONTENT)
    },
    methods:{
        open(data){
            this.isOpen = true;
            this.paoRootNodeId = data.paoRootNodeId;
        },
        confirm(){
            if(this.$refs.form.validate()){
                let formData = new FormData;
                formData.append('file', this.file);
                formData.append('paoContentType', this.paoContentType);
                formData.append('paoRootNodeId', this.paoRootNodeId);
                axios.post(
                    '/pao/node/content',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                .then((response) => {
                    EventBus.$emit(ConstantEvent.CONFIRM_ADD_PAO_CONTENT, response.data);
                    this.isOpen = false;
                })
            }
        },
        cancel(){
            this.isOpen = false;
        },
        setFile(file){
            this.file = file;
        }
    },
    components:{
        BolUploadFile
    }
}
</script>