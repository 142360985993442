<template>
    <v-container fluid grid-list-md>
        <v-layout row wrap justify-start>
            <v-timeline v-if="documentHistoryList.length > 0" >
                <v-timeline-item
                    v-for="step in documentHistoryList"
                    :key="step.id"
                    :color="step.stepRejected ? 'red lighten-2' : 'primary lighten-2' "
                    small
                    right
                >
                    <v-card class="elevation-2" style="width:500px; height:fit-content;">
                        <v-card-title class="title-step-history">  
                            <span>{{ $t('document.label.step') }} : {{ $t(`document.label.${step.documentStep}`) }}</span>
                            <span>{{ $t('document.label.status') }} : {{ $t(`document.label.${step.status}`) }}</span>
                        </v-card-title>
                        
                        <v-card-title class="text_of_card">{{ $t('document.label.modificationDate')}} : <span class="font-weight-bold"> {{step.stepModificationDate != null ? $d(new Date(step.stepModificationDate)) : null}}</span></v-card-title>
                        <v-card-text  class="text_of_card">{{ $t('document.label.user')}} : <span class="font-weight-bold"> {{step.user}} </span></v-card-text>
                        <v-card-text  class="text_of_card">{{ $t('document.label.observation')}} : <span class="font-weight-bold"> {{step.observation}} </span></v-card-text>
                    </v-card>
                </v-timeline-item>
            </v-timeline>
        </v-layout>
    </v-container>
</template>
<script>
/**
 * Display the list of the different step of a document 
 * 
 * @author Justin WILMET, Sfeir Benelux
 * @version 1.0
 * @since 03/05/2019
 */
export default {
    name: 'DocumentHistoryList',
    props: ['documentHistoryList']
}
</script>
<style scoped>
.text_of_card{
    font-size: 1.0em;
    padding-top: 0;
    padding-bottom: 0;
}
.title-step-history {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
}
</style>
