<template>
    <v-layout
        align-start
        justify-space-between
        row
    >
        <v-flex xs6>
            <v-card class="all_cards_container">
                <v-container fluid>
                    <div class="title_card">
                        {{ $t('document.dashboard.lastedModifiedTitle') }}
                    </div>
                    <v-layout
                        column
                        wrap
                    >
                        <v-card
                            v-for="doc in documentList"
                            :key="doc.id"
                            class="document-card"
                            @click="goToLastModifiedDocument(doc)"
                        >
                            <v-layout
                                row
                                wrap
                            >
                                <v-flex xs9>
                                    <div class="type_of_card_title">
                                        <div
                                            class="type_of_card"
                                            :style="{backgroundColor:setBgColor(doc.documentType)}"
                                        />
                                        <span class="bolded_font">{{ doc.documentType }}</span>
                                    </div>
                                </v-flex>
                            </v-layout>
                            <v-divider />
                            <v-layout
                                row
                                wrap
                            >
                                <v-flex xs12>
                                    <v-tooltip
                                        bottom
                                        max-width="550"
                                    >
                                        <template slot="activator">
                                            <div class="over-flow-handler">
                                                {{ doc.title }}
                                            </div>
                                        </template>
                                        <span>{{ doc.title }}</span>
                                    </v-tooltip>

                                    <div class="descriptor_font align_horizontally">
                                        Version : {{ doc.version }}
                                    </div>
                                    <div class="descriptor_font">
                                        Ref : {{ doc.reference }}
                                    </div>
                                    <div class="descriptor_font">
                                        Status : {{ $t(`document.label.${doc.status}`) }}
                                    </div>
                                    <div
                                        class="descriptor_font"
                                        style="margin-bottom:5px;"
                                    >
                                        {{
                                            $t('document.label.step')
                                        }} : {{ $t(`document.label.${doc.step}`) }}
                                    </div>
                                </v-flex>
                            </v-layout>
                            <v-divider />
                            <v-card-actions>
                                <span
                                    v-show="!!doc.startApplicabilityDate"
                                    class="card-action-span"
                                > 
                                    <v-icon
                                        small
                                        class="card-action-icon"
                                    >timer</v-icon>
                                    {{ $d(parseDate(doc.startApplicabilityDate)) }}
                                </span>
                                <span class="card-action-span">
                                    {{
                                        doc.hasBeenPublishedOnce ? $t('document.dashboard.available') : $t('document.dashboard.unavailable')
                                    }}
                                </span>
                                <span class="card-action-span">
                                    <v-icon
                                        small
                                        class="card-action-icon"
                                    >timelapse</v-icon>{{ $d(parseDateTime(doc.lastModificationDate), 'medium') }}
                                </span>
                            </v-card-actions>
                        </v-card>
                    </v-layout>
                </v-container>
            </v-card>
        </v-flex>

        <v-flex xs6>
            <v-card class="all_cards_container">
                <v-container fluid>
                    <div class="title_card">
                        {{ $t('document.dashboard.lastOpenings') }}
                    </div>
                    <v-layout
                        column
                        wrap
                        justify-start
                    >
                        <v-card
                            v-for="doc in documentUserList"
                            :key="doc.id"
                            class="document-card"
                            @click="goToLastModifiedDocument(doc)"
                        >
                            <v-layout
                                row
                                wrap
                            >
                                <v-flex xs9>
                                    <div class="type_of_card_title">
                                        <div
                                            class="type_of_card"
                                            :style="{backgroundColor:setBgColor(doc.documentType)}"
                                        />
                                        <span class="bolded_font">{{ doc.documentType }}</span>
                                    </div>
                                </v-flex>
                            </v-layout>
                            <v-divider />
                            <v-layout
                                row
                                wrap
                            >
                                <v-flex xs12>
                                    <v-tooltip
                                        bottom
                                        max-width="550"
                                    >
                                        <template slot="activator">
                                            <div class="over-flow-handler">
                                                {{ doc.title }}
                                            </div>
                                        </template>
                                        <span>{{ doc.title }}</span>
                                    </v-tooltip>

                                    <div class="descriptor_font align_horizontally">
                                        Version : {{ doc.version }}
                                    </div>
                                    <div class="descriptor_font">
                                        Ref : {{ doc.reference }}
                                    </div>
                                    <div class="descriptor_font">
                                        Status : {{ $t(`document.label.${doc.status}`) }}
                                    </div>
                                    <div
                                        class="descriptor_font"
                                        style="margin-bottom:5px;"
                                    >
                                        {{
                                            $t('document.label.step')
                                        }} : {{ $t(`document.label.${doc.step}`) }}
                                    </div>
                                </v-flex>
                            </v-layout>
                            <v-divider />
                            <v-card-actions>
                                <span
                                    v-show="!!doc.startApplicabilityDate"
                                    class="card-action-span"
                                >
                                    <v-icon
                                        small
                                        class="card-action-icon"
                                    >timer</v-icon>
                                    {{ $d(parseDate(doc.startApplicabilityDate)) }}
                                </span>
                                <span class="card-action-span">
                                    {{
                                        doc.hasBeenPublishedOnce ? $t('document.dashboard.available') : $t('document.dashboard.unavailable')
                                    }}
                                </span>
                                <span class="card-action-span">
                                    <v-icon
                                        small
                                        class="card-action-icon"
                                    >timelapse</v-icon>{{
                                        $d(parseDateTime(doc.lastModificationDate), 'medium')
                                    }}
                                </span>
                            </v-card-actions>
                        </v-card>
                    </v-layout>
                </v-container>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
//TODO La deuxieme liste est à retirer c'est pour la demo actuellement
import axios from 'axios';
import moment from 'moment';
import NotificationContainer from '../../shared/notification/NotificationContainer';
import ConstantApplication from "@/shared/constant/constantApplication";

export default {
    name: 'LastModifyDocument',
    components: {
        NotificationContainer
    },
    data() {
        return {
            documentList: [],
            documentUserList: [],
        }
    },
    mounted() {
        axios.get('/secure/user/current')
            .then(({data}) => {
                this.$store.dispatch('userStore/setUserPreferences', data);//TODO set store action in method and call this method
            }, () => {
            });
        this.loadLastModifier();
        this.loadLastModifierByUser();
    },
    methods: {
        parseDate(date) {
            return !!date ? new Date(moment(date).format(ConstantApplication.DEFAULT_DATE_FORMAT)) : '';
        },
        parseDateTime(date) {
            return !!date ? new Date(moment(date).format(ConstantApplication.DEFAULT_DATE_TIME_FORMAT)) : '';
        },
        /**
     * Set the color for the different type of document
     * @param type, type of document
     */
        setBgColor(type) {
            switch (type) {
            case "Legislation" :
                return "darkblue";
            case "Jurisprudence" :
                return "darkred";
            case "Doctrine" :
                return "yellow";
            case "Dossier Parlementaire" :
                return "lightgrey";
            case "Circulaire Administrative" :
                return "darkgrey";
            case "Livre Vert" :
                return "green";
            case "Bibliographie" :
                return "purple";
            default:
                return "green";
            }
        },
        /**
     * Load the last modifier document
     */
        loadLastModifier() {
            axios.get('/document/lastModified')
                .then(({data}) => {
                    this.documentList = data;
                }, () => {
                });
        },
        /**
     * Load the last modifier documents by user
     */
        loadLastModifierByUser() {
            axios.get(`/document/lastModifiedCurrent`)
                .then(({data}) => {
                    this.documentUserList = data;
                }, () => {
                });
        },
        /**
     * Redirect to the selected document
     * @param document, selected document
     */
        goToLastModifiedDocument(document) {
            this.$router.push({
                name: 'versionDetails',
                params: {reference: document.reference, version: document.version, title: document.title}
            });
        }
    }
}
</script>
<style scoped>

.card-action-span {
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
}

.card-action-icon {
  margin-left: auto;
  margin-right: 5px;
}

.over-flow-handler {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 10px;
}

.document-card {
  width: 100%;
  min-height: 0px;
  margin: 3px;
  cursor: pointer;
}

.type_of_card_title {
  width: 100%;
  margin-top: 10px;
  height: 30px;
}

.type_of_card {
  display: inline-block;
  float: left;
  margin: 0 10px;
  width: 20px;
  height: 20px;
  border: lightgrey 1px solid;
}

.all_cards_container {
  margin: 30px 40px;
  background-color: #375C7E;
}

.title_card {
  color: white;
  font-weight: bold;
  font-size: 16px;
  margin: 0px 10px 10px 0px;
}

.descriptor_font {
  font-size: 10px;
  margin-left: 10px;
}

.bolded_font {
  color: black;
  font-weight: bold;
}

.align_horizontally {
  float: right;
  clear: none;
  margin-right: 10px;
  font-size: 12px;
  font-weight: bold;
}
</style>
