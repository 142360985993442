const SPAN_LTH    = 'span-lth';
const SPAN_UNL    = 'span-unl';
const SPAN        = 'span';
const DECORATION_UNDERLINE    = 'underline';
const DECORATION_LINETHROUGH    = 'line-through';

class FormatTextService {

    constructor(){

    }
    
    /**
     * 
     * will format the selected text with the chosen HTML element
     * 1) we loop on the style array who will create a style tree base on the checkbox style
     *    ! the deepest child will contain the selected text
     * 2) if the user choose a sub / sup style will add it to tree created previously
     *    ! if no tree created before will add the selected text to his 'textContent'
     * 3) if the user choose a Header will create the element and add the tree as his child
     *    ! if no tree created before will add the selected text to his 'textContent'
     *
     * @param {*} data 
     */
    $formatSelectedText(data){
        var elementTree = null;

        if(data.styleBIUO.length > 0){
            elementTree = this.$createStyleHtml(elementTree, data.styleBIUO, data.nodeContent);
        }

        if(!!data.subSupScript){
            elementTree = this.$createHtmlElement(elementTree, data.subSupScript, data.nodeContent);
        }
  
        if(!!data.header){
            elementTree = this.$createHtmlElement(elementTree, data.header, data.nodeContent);
        }

        return elementTree;
    }

    /**
     * Loop on the array of style available on the checkbox, create the corresponding element 
     * and set a text-decoration depending on if the element is a span or not
     * @param {*} elementTree 
     * @param {*} style 
     * @param {*} nodeContent 
     */
    $createStyleHtml( elementTree, style, nodeContent ){
        style.forEach( e => {
            elementTree = this.$createHtmlElement( elementTree, e, nodeContent);
            if(e === SPAN_LTH){
                elementTree.style.textDecoration = DECORATION_LINETHROUGH;
            }
            if(e === SPAN_UNL){
                elementTree.style.textDecoration = DECORATION_UNDERLINE;
            }
        });
        return elementTree;
    }
    /**
     * Generic method to create an element HTML 
     * Depending on if elements has been created before him 
     * Will attach them to his child or will set his textContent
     * @param {*} elementTree 
     * @param {*} subSup 
     * @param {*} nodeContent 
     */
    $createHtmlElement( elementTree, el, nodeContent ){
        var subElem
        if(el === SPAN_LTH || el === SPAN_UNL){
            subElem = document.createElement(SPAN);
        }else{
            subElem = document.createElement(el);
        }

        if(!!elementTree){
            subElem.appendChild(elementTree);
        }else{
            subElem.innerHTML = nodeContent;
        }
        return subElem;
    }
}

export default new FormatTextService();