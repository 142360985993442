const CONTRARY                       = 'CONTRARY';
const SAME                           = 'SAME';
const FOLLOWING                      = 'FOLLOWING';
const NONE                           = 'NONE';

/**
 * Store all the document type constant
 * @author Gentilhomme Romain
 * @since 22/01/2020
 * @version 1.0
 */
export default class ConstantRelationType {

    static get CONTRARY(){
        return CONTRARY;
    }

    static get FOLLOWING(){
        return FOLLOWING;
    }

    static get SAME() {
        return SAME;
    }
    static get NONE() {
        return NONE;
    }

}