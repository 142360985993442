<template>
    <v-layout align-end justify-center column  v-if="!!status">
        <v-flex v-if="step === edition || step === qualityControl" class="actions">
            <span class="text_actions">{{ $t('document.action.xmlUpload') }}</span>
            <button-to-editable-state @upload="uploadXmlFile" :iconText="'cloud_upload'"></button-to-editable-state>
        </v-flex>
        <v-flex v-if="step === validation && status === available" class="actions">
            <span class="text_actions">{{ $t('document.action.plusOne') }}</span>
            <v-btn color="white" small fab dark slot="activator"  @click="incrementDocumentVersion">
                <v-icon color="primary">plus_one</v-icon>
            </v-btn>
        </v-flex>
        <v-flex v-if="step === validation && status === available && metaDocument.currentVersion.startApplicabilityDate !== null" class="actions" >
            <span class="text_actions">{{ $t('document.action.manageDocumentModificator') }}</span>
            <v-btn color="white" small fab dark slot="activator" @click="goToPage(`/document/manageConsolidation/${idDocument}`)">
                <v-icon color="primary">attachment</v-icon>
            </v-btn>
        </v-flex >
        <v-flex  v-if="documentModificatorList.length > 0 && step === consolidation" class="actions" >
            <span class="text_actions">{{ $t('document.action.consolidateDocument') }}</span>
            <v-btn color="white" small fab dark slot="activator"  @click="goToPage(`/document/consolidation/${reference}/${idDocument}`)" >
                <v-icon color="primary">extension</v-icon>
            </v-btn>
        </v-flex >
        <v-flex v-if="step === edition || step === qualityControl" class="actions">
            <span class="text_actions" >{{ $t('document.action.editDocument') }}</span>
            <button-to-editable-state @to-page="goToPage(`/document/editor/${reference}/${idDocument}`)" :iconText="'description'"></button-to-editable-state>
        </v-flex>
        <v-flex v-if="step === edition || step === qualityControl" class="actions">
            <span class="text_actions">{{ $t('document.action.editDataSheet') }}</span>
            <button-to-editable-state @to-page="goToPage(`/document/cuDocument/${idDocument}`)" :iconText="'find_in_page'"></button-to-editable-state>
        </v-flex>
        <v-flex class="actions" >
            <span class="text_actions">{{ $t('document.action.overview') }}</span>
            <v-btn color="white" small fab dark slot="activator"  :to="'/document/overview/'" >
                <v-icon color="primary">pageview</v-icon>
            </v-btn>
        </v-flex>
        <v-flex v-if="step === marking || step === qualityControl" class="actions">
            <span class="text_actions">{{ $t('document.action.manageDocumentLinks') }}</span>
            <button-to-editable-state @to-page="goToPage(`/document/hyperLink/${idDocument}`)" :iconText="'insert_link'"></button-to-editable-state>
        </v-flex>
    </v-layout>
</template>

<script>
import ConstantWorkflow         from '../../../../shared/constant/constantWorkflow.js';
import { mapState }             from 'vuex';
import ButtonToEditableState    from '../../../shared/ButtonToEditableState';

/**
 * 
 * Display all the action button for a document
 * 
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 25/06/2019
 */
export default {
    name: 'ActionPanelDocument',
    props: {
        idDocument: {
            default: null
        },
        reference: {
            default: null
        }
    },
    components: {
        ButtonToEditableState
    },
    computed: {
        ...mapState({
            status: state => state.dataSheetStore.metaDocument.documentStatus,
            step: state => state.dataSheetStore.metaDocument.documentStep,
            metaDocument : state => state.dataSheetStore.metaDocument,
            documentModificatorList : state => state.dataSheetStore.documentModificatorList,
        }),
        edition(){
            return ConstantWorkflow.EDITION;
        },
        marking(){
            return ConstantWorkflow.MARKING;
        },
        qualityControl(){
            return ConstantWorkflow.QUALITY_CONTROL;
        },
        validation(){
            return ConstantWorkflow.VALIDATION;
        },
        available(){
            return ConstantWorkflow.AVAILABLE;
        },
        consolidation(){
            return ConstantWorkflow.CONSOLIDATION;
        }
    },
    methods: {
        incrementDocumentVersion(){
            this.$emit('onIncrementVersion');
        },
        uploadXmlFile(){
            this.$emit('onUploadXmlFile');
        },
        goToPage( path ){
            this.$router.push({ path: path });
        }
    }
}
</script>

<style scoped>
.actions{
    margin-bottom:20px;
}
.text_actions{
    color:white;
}
</style>
