<template>
    <v-dialog v-model="openDialog" persistent max-width="450px">
        <v-card>
            <v-card-title class="headline">
                {{ $t('administration.keyword.link') }}
            </v-card-title>
            <v-divider></v-divider>
            <v-form>
                <v-card-text v-if="editionMode" class="card_keywords">
                    <div v-if="keywordsGlobalList.length < 1">
                        <p class="list_empty"> {{ $t('administration.keyword.empty') }} </p>
                    </div>
                    <div v-else v-for="(items, index) in keywordsGlobalList" :key="index"  class="list_keywords">
                        <h3 class="title_keywords">{{index+1}} 
                            <v-icon color="white" @click="deleteKeywords(index)" class="icon_keywords">delete</v-icon>
                        </h3>
                        <div v-for="item in items" :key="item.id" class="items_keywords">
                            <span class="bolder">{{item.label}}</span>
                        </div>
                    </div>
                </v-card-text>

                <v-card-text v-if="!editionMode">
                    <v-autocomplete
                        :label="$t('administration.keyword.level1')"
                        :items="keywordsListLevelOne"
                        item-text="label"
                        v-model="keywordOne"
                        :search-input.sync="keywordInput1"
                        clearable
                        no-data
                        :loading="notLoaded"
                        return-object
                        filled 
                        >
                        <template slot="no-data" v-if="!editionMode">
                            <v-btn class="custom-add-keyword" @click="persistNewKeyword({'label':keywordInput1,'level':1})">
                                <v-icon  medium> add </v-icon>
                                {{$t('administration.keyword.action.create')}}
                            </v-btn>
                        </template>
                    </v-autocomplete>
                    <v-autocomplete
                        :label="$t('administration.keyword.level2')"
                        :items="keywordsListLevelTwo"
                        item-text="label"
                        v-model="keywordTwo"
                        :disabled="!keywordOne"
                        :search-input.sync="keywordInput2"
                        clearable
                        no-data
                        :loading="notLoaded"
                        return-object
                        filled
                        >
                        <template slot="no-data" v-if="!editionMode">
                            <v-btn class="custom-add-keyword" @click="persistNewKeyword({'label':keywordInput2,'level':2})">
                                <v-icon  medium> add </v-icon>
                                {{$t('administration.keyword.action.create')}}
                            </v-btn>
                        </template>
                    </v-autocomplete>
                    <v-autocomplete
                        :label="$t('administration.keyword.level3')"
                        :items="keywordsListLevelThree"
                        item-text="label"
                        v-model="keywordThree"
                        :disabled="!keywordTwo"
                        :search-input.sync="keywordInput3"
                        clearable
                        no-data
                        :loading="notLoaded"
                        return-object
                        filled
                        >
                        <template slot="no-data" v-if="!editionMode">
                            <v-btn class="custom-add-keyword" @click="persistNewKeyword({'label':keywordInput3,'level':3})">
                                <v-icon  medium> add </v-icon>
                                {{$t('administration.keyword.action.create')}}
                            </v-btn>
                        </template>
                    </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" flat @click="close()" v-if="editionMode">{{$t('global.action.back')}}</v-btn>
                    <v-btn color="primary" flat @click="goToAddKeyword()" v-if="editionMode">{{$t('document.action.add')}}</v-btn>
                    <v-btn color="error" flat @click="backToEdition()" v-if="!editionMode">{{$t('global.action.cancel')}}</v-btn>
                    <v-btn color="primary" flat @click="addNewKeyword(),close()" v-if="!editionMode">{{$t('document.action.apply')}}</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import axios                                from 'axios';
import { mapState, mapMutations }           from 'vuex';

const UPDATE_DIALOG = 'update:dialog';
/**
 * Display the dialog to set keywords on a node
 * 
 * @author Justin WILMET
 * @version 1.0
 * @since 18/03/2019
 */
export default {
    name: 'DialogAddKeyword',
    props: {
        dialog: {
            default: false
        }
    },
    data(){
        return{
            notLoaded: true,
            keywordOne: null,
            keywordTwo: null,
            keywordThree: null,
            keywordsListLevelOne: [],
            keywordsListLevelTwo: [],
            keywordsListLevelThree: [],
            editionMode: true,
            keywordsGlobalList: [],
            rank:0,
            keywordInput1:'',
            keywordInput2:'',
            keywordInput3:''
        }
    },
    computed:{
        ...mapState({
            selectedNode: state => state.documentContent.selectedNode
        }),
        openDialog: {
            get(){
                if( this.dialog ){
                    this.$nextTick().then(() => {
                        this.getKeywords();
                    })
                }
                return this.dialog;
            },
            set( dialog ){
                if( !dialog ){
                    this.$emit(UPDATE_DIALOG, false);
                }
            }
        }
    },
    watch: {
        keywordOne: function (val, oldVal){
            if(!this.keywordOne){
                this.keywordTwo = null;
            }
        },
        keywordTwo: function (val, oldVal){
            if(!this.keywordTwo){
                this.keywordThree = null;
            }
        }
    },
    mounted(){
        this.loadKeywords();
        this.keywordsGlobalList = [];
    },
    methods: {  
        ...mapMutations('documentContent', [
            'removeKeyword',
            'setRankKeyword'
        ]),
        /**
         * Load all the existing keywords
         */
        loadKeywords(){
            axios.get(`document/keyword/readAll`
            ).then(result => {
                let keywordsListLevelOne = [];
                let keywordsListLevelTwo = [];
                let keywordsListLevelThree = [];
                result.data.forEach(function(element) {
                    if(element.level === 1){ keywordsListLevelOne.push(element); }
                    if(element.level === 2){ keywordsListLevelTwo.push(element); }
                    if(element.level === 3){ keywordsListLevelThree.push(element); }
                });

                this.keywordsListLevelOne = keywordsListLevelOne;
                this.keywordsListLevelTwo = keywordsListLevelTwo;
                this.keywordsListLevelThree = keywordsListLevelThree;
                this.notLoaded = false;
            });
        },
        /**
         * Reset the dialog
         */
        close(){
            this.editionMode = true;
            this.keywordsGlobalList= [];
            this.rank=0;
            this.keywordOne = null;
            this.keywordTwo = null;
            this.keywordThree = null;
            this.$emit(UPDATE_DIALOG, false);
        },
        /**
         * Add some keywords for the selected node
         */
        addNewKeyword(){
            let newKeywords = [];
            if(!!this.keywordOne){
                newKeywords.push({id: this.keywordOne.id, label: this.keywordOne.label, rank: this.rank});
            }
            if(!!this.keywordTwo){
                newKeywords.push({id: this.keywordTwo.id, label: this.keywordTwo.label, rank: this.rank});
            }
            if(!!this.keywordThree){
                newKeywords.push({id: this.keywordThree.id, label: this.keywordThree.label, rank: this.rank});
            }
            this.keywordsGlobalList.push(newKeywords);
            this.$store.commit('documentContent/addKeywords', newKeywords);
            this.rank++;
            this.keywordOne = null;
            this.keywordTwo = null;
            this.keywordThree = null;
            this.backToEdition();
        },
        /**
         * Delete keyword group associated to the node
         * @params indexToDelete : int
         */
        deleteKeywords(indexToDelete){
            let listToUpdate = [...this.selectedNode.keywords];
            let listIndexToDelete = [];
            for (let index = 0; index < listToUpdate.length; index++) {
                if ( listToUpdate[index].rank === indexToDelete ){
                    listIndexToDelete.push(index);
                }
            }
            this.$store.commit('documentContent/removeKeywords', listIndexToDelete);

            for (let index = 0; index < this.selectedNode.keywords.length; index++) {
                if ( this.selectedNode.keywords[index].rank > indexToDelete ){
                    this.$store.commit('documentContent/setRankKeyword', index);
                }
            }
            this.getKeywords();
        },
        goToAddKeyword(){
            this.editionMode = false;
        },
        backToEdition(){
            this.editionMode = true;
        },
        /**
         *  Add new keyword to database with his label and level
         * @params keyword{
         *          label:''
         *          level:0
         *         }
         *
         */
        persistNewKeyword(keywordToManage){
            axios.post("administration/keyword", keywordToManage).then(() => {
                this.loadKeywords();
            });
        },
        /**
         * Get the keywords from the store and return a list by rank
         */
        getKeywords(){
            this.rank = 0;
            this.keywordsGlobalList = [];
            if(!!this.selectedNode.keywords) {
                for (let indexList = 0; indexList < this.selectedNode.keywords.length; indexList++) {
                    let listKeywords = [];
                    for (let indexElement = indexList; indexElement < this.selectedNode.keywords.length; indexElement++) {
                        if (indexList === this.selectedNode.keywords[indexElement].rank) {
                            listKeywords.push(this.selectedNode.keywords[indexElement]);
                        }
                    }
                    if (listKeywords.length > 0){
                        this.keywordsGlobalList.push(listKeywords);
                        this.rank++
                    }
                }
            }
        }
    }
}
</script>
<style>
    .items_keywords{
        margin-top:5px;
        margin-bottom:5px;
        text-align: center;
    }
    .title_keywords{
        text-align: center;
        color:white;
        background-color: #355B7F;
        padding-left:30px;
    }
    .icon_keywords{
        float:right;
        padding-right:10px;
        cursor:pointer;
    }
    .list_keywords{
        border: #355B7F 1px solid;
    }
    .list_empty{
        text-align: center;
        color:lightgrey;
    }
    .bolder{
        font-weight: bold;
    }
    .card_keywords{
        height:250px;
        overflow: auto;
    }
    /*
     * !important here take priority on v-btn which not taking any modification without this param
     */
    .custom-add-keyword{
        background-color: white !important;
        box-shadow: none !important;
        border:none !important;
        color:#355B7F !important;
    }
</style>