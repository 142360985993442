<template >
    <v-container class="container_list_paos">

            <v-layout v-if="displaySpinner" >
                <v-progress-circular
                    :size="50"
                    :indeterminate="true"
                    color="primary"
                    class="spinner_doc">
                </v-progress-circular>
            </v-layout>

            <slot ></slot>

    </v-container>

</template>
<script>
import EventBus         from '../../../utils/event-bus';
import ConstantEvent    from '../../../shared/constant/constantEvent';
import StoreModule      from '../../../shared/constant/store/module/namespace';
import { mapState }     from 'vuex';
/**
 * Display a pao list from a search.
 * 
 * @author Cédric de BOISVILLIERS, SFEIR benelux
 * @version 1.0
 * @since 17/05/2019
 */
export default {
    name:'PaoList',
    props: {
        displaySpinner: {
            default: false
        }
    },
    methods: {
        /**
         * change page and navigate to the selected page
         * @param page
         */
        changePage(page){
            this.$emit('changePage', page);
        }
    }
}
</script>

<style scoped>
.container_list_paos{
    width:100%;
}
.title_to_ellips{
    font-size: 1.2em;
    text-overflow:ellipsis;
    overflow:hidden;
    height:50px;
    margin-top:-5px;
    margin-left:-5px;
}
.spinner_doc{
    position:absolute;
    top:calc(50vh - 65px);
    left:30px;
    right:0;
    margin:auto;
}

.mx-auto:hover{
    animation: pulse 1s;
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
}
</style>