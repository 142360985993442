<template>
  <v-dialog v-model="openDialog" persistent max-width="500px">
    <v-card>

      <v-card-title v-if="isValidate" class="headline">{{ $t(`document.label.${step}`) }} -
        {{ $t('document.action.step.validate') }}
      </v-card-title>
      <v-card-title v-else class="headline"> {{ $t('document.action.step.reject') }}</v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <div v-if="isValidate">
          <p class="bol-validation-message">{{ $t('document.action.step.information') }}
            <span class="bol-bold-step">{{ $t(`document.label.${step}`) }}</span>.
          </p>
          <p class="bol-validation-message">{{ $t('document.action.step.changement') }}
            <span class="bol-bold-step">{{ $t(nextStep) }}</span>.
          </p>
          <p class="bol-validation-message">{{ $t('document.action.step.consequence') }}</p>
        </div>
        <div v-else>
          <v-textarea
              name="observation"
              :label="$t('document.label.observation')"
              v-model="observation"
          ></v-textarea>
          <v-divider v-if="startDocumentStep === consolidation"></v-divider>
          <v-item-group v-model="rejectionCause" v-if="startDocumentStep === consolidation && step !== validationStep">
            <v-layout>
              <v-item value="contentRejected">
                <v-layout align-center justify-center class="bol-row-item-radio-group" slot-scope="{ active, toggle }">
                  <v-icon :color="active ? 'primary' : ''" @click.native="toggle" class="mr-2">
                    {{ active ? 'radio_button_checked' : 'radio_button_unchecked' }}
                  </v-icon>
                  {{ $t('document.action.rejectContent') }}
                </v-layout>
              </v-item>
              <v-item value="consolidationRejected">
                <v-layout align-center justify-center class="bol-row-item-radio-group" slot-scope="{ active, toggle }">
                  <v-icon :color="active ? 'primary' : ''" @click.native="toggle" class="mr-2">
                    {{ active ? 'radio_button_checked' : 'radio_button_unchecked' }}
                  </v-icon>
                  {{ $t('document.action.rejectConsolidation') }}
                </v-layout>
              </v-item>
            </v-layout>
          </v-item-group>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" flat @click.native="close();">{{ $t('document.action.cancel') }}</v-btn>
        <v-btn color="primary" flat @click.native="updateStep();">
          {{ isValidate ? $t('document.action.validate') : $t('document.action.reject') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ConstantWorkflow from '../../../../shared/constant/constantWorkflow.js';
import axios from 'axios';
import {mapState} from 'vuex';

const UPDATE_DIALOG = 'update:dialog';
const CONSOLIDATION_REJECTED = 'consolidationRejected';
const CONTENT_REJECTED = 'contentRejected';

/**
 * Dialog use to validate the different step of a document
 * @author Justin WILMET
 * @since 06/05/2019
 * @version 1.0
 */
export default {
  name: 'DialogValidateStep',
  props: {
    dialog: {
      default: false
    },
    isValidate: {
      default: false
    }
  },
  data() {
    return {
      observation: null,
      rejectionCause: null
    }
  },
  computed: {
    ...mapState({
      status: state => state.dataSheetStore.metaDocument.documentStatus,
      step: state => state.dataSheetStore.metaDocument.documentStep,
      documentId: state => state.dataSheetStore.metaDocument.currentVersion.documentId,
      startDocumentStep: state => state.dataSheetStore.metaDocument.startDocumentStep
    }),
    consolidation() {
      return ConstantWorkflow.CONSOLIDATION;
    },
    validationStep() {
      return ConstantWorkflow.VALIDATION;
    },
    openDialog: {
      get() {
        return this.dialog;
      },
      set(dialog) {
        if (!dialog) {
          this.$emit(UPDATE_DIALOG, false);
        }
      }
    },
    nextStep() {
      switch (this.step) {
        case ConstantWorkflow.EDITION:
          return 'document.label.MARKING';
        case ConstantWorkflow.MARKING:
          return 'document.label.QUALITY_CONTROL';
        case ConstantWorkflow.QUALITY_CONTROL:
          return 'document.label.VALIDATION';
        case ConstantWorkflow.VALIDATION:
          return 'document.label.VALIDATION';
        case ConstantWorkflow.CONSOLIDATION:
          return 'document.label.MARKING';
        default :
          return '';
      }
    },
    contentRejected() {
      return CONTENT_REJECTED;
    },
    consolidationRejected() {
      return CONSOLIDATION_REJECTED;
    }
  },
  methods: {
    updateStep() {
      axios.post('step/history/', {
        documentId: this.documentId,
        step: this.step,
        isRejected: !this.isValidate,
        observation: this.observation,
        isContentRejected: this.rejectionCause === this.contentRejected
      })
          .then((result) => {
            this.$emit("onStepChange", {rejected: !!this.rejectionCause});
          })
          .then(() => this.close());
    },
    close() {
      this.observation = null;
      this.rejectionCause = null;
      this.$emit(UPDATE_DIALOG, false);
    }
  }
}
</script>

<style scoped>
.bol-validation-message {
  font-size: 18px;
}

.bol-radio-group {
  justify-content: center;
}

.bol-row-radio-group {
  display: flex;
  align-items: flex-start;
}

.bol-row-item-radio-group {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 16px;
  padding-top: 4px;
  color: rgba(0, 0, 0, .54);
}

.bol-bold-step {
  font-weight: bold;
}
</style>
