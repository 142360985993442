<template>
    <v-layout column wrap>      
        <v-layout row wrap justify-end class="pagination">
            <v-pagination 
                :length="pageable.totalPages" 
                :total-visible="6" 
                @input="changePage" 
                :value="pageable.page" 
                circle
                />
        </v-layout>
        <v-flex>
            <v-data-table
                :headers="[]"
                :items="paoList"
                disable-initial-sort
                hide-actions
                class="elevation-1"       
            >
                <template slot="headers" slot-scope="props">
                    <th class="column active text-xs-left">{{ $t('pao.label.title')}}</th>
                    <th class="column active text-xs-left">{{ $t('pao.label.code')}}</th>
                    <th class="column active text-xs-left">{{ $t('pao.label.modificationDate')}}</th>
                    <th class="column active text-xs-left">{{ $t('pao.label.actions')}}</th>
                </template>

                <template slot="no-data">
                    <td colspan="4" class="text-xs-center" >
                        {{ $t('pao.label.none')}}
                    </td>
                </template>

                <template slot="items" slot-scope="props">
                    <td>{{ props.item.title }}</td>
                    <td>{{ props.item.code }}</td>
                    <td>{{ $d(parseDate(props.item.lastModificationDate)) }}</td>
                    <td justify-space-around>
                        <v-icon medium @click="linkToPao(props.item)">link</v-icon>
                    </td>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>

<script>
import moment               from 'moment';
import ConstantApplication  from '../../../shared/constant/constantApplication';

/**
 * List of pao to link to the document
 * 
 * @author Cédric de BOISVILLIERS, SFEIR Benelux
 * @version 1.0
 * @since 09/09/2019
 */
export default {
    name: 'paoListTabPao',
    props: {
        paoList: {
            default: () => []
        },
        pageable: {
            required: true
        }
    },
    methods: {
        /**
         * Send to the parent the page index to display
         */
        changePage( page ){
            this.$emit('changePage', page);
        },
        /**
         * Send to the parent the document modificator to link to the current documents
         */
        linkToPao( pao ){
            this.$emit('linkToPao', pao);
        },
        parseDate(date){
            if (!!date) {
                return new Date(moment(date).format(ConstantApplication.DEFAULT_DATE_FORMAT));
            } else {
                return '';
            } 
        }
    }
}
</script>

<style scoped>
.pagination{
    margin: 15px;
}
</style>
