<template>
    <v-layout align-center justify-start row fill-height>
        <v-icon class="icon">
            keyboard_backspace
        </v-icon>
        <h3>{{textBackButton}}</h3>
    </v-layout>
</template>

<script>
    export default {
        name: "BolBackButton",
        props:{
            textBackButton:{
                default:null
            }
        },
    }
</script>

<style scoped>
.icon{
    width:50px;
    height:50px;
    background-color: white;
    color:#355B7F;
    border-radius: 50px;
    margin-left:0px;
    margin-right:30px;
}
.icon:hover{
    transform: scale(1.1);
}
</style>