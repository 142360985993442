<template>
    <v-container>
        <v-form
            v-if="!displaySpinner"
            ref="formCreateEditDocument"
            v-model="valid"
            lazy-validation
        >
            <v-layout
                row
                wrap
                justify-center
            >
                <!-- DOCUMENT TYPE -->
                <v-flex xs6>
                    <div v-if="isUpdateMode && !!datasheet.documentType">
                        <v-select
                            v-model="datasheet.documentType.id"
                            :label="$t('document.create.documenttype')"
                            :items="documentTypeList"
                            item-value="id"
                            item-text="label"
                            disabled
                        />
                    </div>
                </v-flex>
                <v-spacer />
                <v-flex xs6>
                    <v-text-field
                        v-if="isUpdateMode"
                        :label="$t('document.create.reference')"
                        :placeholder="datasheet.reference"
                        disabled
                    />
                </v-flex>
            </v-layout>
            <!-- DOCUMENT TYPE -->
            <autocomplete-select
                v-if="!isUpdateMode"
                :value="datasheet.documentType.id"
                :label="$t('document.create.documenttype')"
                :rule="documentTypeRules"
                :items="documentTypeList"
                @onBindModel="
                    setField($event, DATASHEET_FIELD.DOCUMENT_TYPE_ID);
                    syncSelectedMarketsToDocumentType();
                    syncSelectedWorkspacesToSelectedMarkets();
                "
            />
            <!-- MARKETS -->
            <autocomplete-select
                :label="$t('document.create.market')"
                :rule="marketRules"
                :items="documentTypeMarkets"
                :multiple="true"
                :chips="true"
                :value="datasheet.marketsId"
                @onBindModel="
                    setField($event, DATASHEET_FIELD.MARKET_ID);
                    syncSelectedWorkspacesToSelectedMarkets();
                "
            />
            <!-- MAIN WORKSPACE -->
            <autocomplete-select
                :label="$t('document.create.mainworkspace')"
                :value="datasheet.idWorkspace"
                :rule="workSpaceRules"
                :items="availableWorkspacesForSelectedMarkets"
                @onBindModel="setField($event, DATASHEET_FIELD.WORKSPACE_ID)"
            />
            <v-alert
                v-if="isJurisprudence() && !isUpdateMode"
                :value="true"
                type="warning"
            >
                {{ $t("document.alert.jurisprudence") }}
            </v-alert>
            <!-- TITLE -->
            <v-text-field
                v-if="!isJurisprudence()"
                :value="datasheet.title"
                :label="$t('document.create.title')"
                :rules="titleRules"
                required
                clearable
                @change="setField($event, DATASHEET_FIELD.TITLE)"
            />
            <!-- PARTNER -->
            <autocomplete-select
                :value="partnerId"
                :label="$t('document.create.partner')"
                :items="partnerList"
                @onBindModel="setField($event, DATASHEET_FIELD.PARTNER_ID)"
            />
            <!-- END DATE OF APPLICABILITY -->
            <v-layout
                row
                wrap
                justify-center
            >
                <bol-date-picker
                    :value="datasheet.endDateOfApplicability"
                    :label="$t('document.create.endofapplicability')"
                    @onBindModel="setEndDateOfApplicability($event)"
                />
                <v-spacer />
                <!-- INDETERMINATE -->
                <v-flex>
                    <v-checkbox
                        :label="$t('document.create.indeterminate')"
                        color="primary"
                        :input-value="datasheet.indeterminate"
                        @change="setField($event, DATASHEET_FIELD.INDETERMINATE)"
                    />
                </v-flex>
            </v-layout>
            <!-- CAUSE END APPLICABILITY -->
            <autocomplete-select
                :value="datasheet.causeEndOfApplicability"
                :label="$t('document.create.causeendapplicability')"
                :items="causeEndOfApplicabilityList"
                :disabled="isCauseEndOfApplicabilityDisabled"
                @onBindModel="setField($event, DATASHEET_FIELD.CAUSE_END_OF_APPLICABILITY)"
            />
            <!-- SOURCE END APPLICABILITY -->
            <v-text-field
                :value="datasheet.sourceEndOfApplicability"
                :label="$t('document.create.sourceendapplicability')"
                :disabled="isSourceEndApplicabilityDisabled"
                clearable
                @input="setField($event, DATASHEET_FIELD.SOURCE_END_OF_APPLICABILITY)"
            />
            <!-- ADDITIONAL WORKSPACES -->
            <autocomplete-select
                :value="datasheet.idOtherWorkspace"
                :label="$t('document.create.additionalworkspaces')"
                :items="availableWorkspacesForSelectedMarkets"
                :multiple="true"
                :chips="true"
                @onBindModel="setField($event, DATASHEET_FIELD.OTHER_WORKSPACE_ID_LIST)"
            />
            <!-- IS VISIBLE-->
            <v-layout
                row
                wrap
                justify-center
            >
                <v-checkbox
                    :label="$t('document.create.is-visible')"
                    color="primary"
                    :input-value="datasheet.isVisible"
                    @change="setField($event, DATASHEET_FIELD.IS_VISIBLE)"
                />
            </v-layout>
            <!-- REMARK -->
            <v-textarea
                v-if="isUpdateMode"
                :label="$t('document.create.remark')"
                :value="datasheet.remark"
                clearable
                @change="setField($event,DATASHEET_FIELD.REMARK)"
            />
            <v-layout
                row
                wrap
                justify-end
            >
                <v-btn
                    color="error"
                    small
                    fab
                    dark
                    @click="$router.go(-1)"
                >
                    <v-icon>clear</v-icon>
                </v-btn>
                <v-btn
                    v-if="!isUpdateMode"
                    color="primary"
                    small
                    fab
                    dark
                    @click="clear"
                >
                    <v-icon>replay</v-icon>
                </v-btn>
                <v-btn
                    color="primary"
                    small
                    fab
                    dark
                    @click="next()"
                >
                    <v-icon>forward</v-icon>
                </v-btn>
            </v-layout>
        </v-form>
        <v-layout
            v-if="displaySpinner"
            align-center
            justify-center
            row
        >
            <v-progress-circular
                :size="70"
                :indeterminate="true"
                color="primary"
            />
        </v-layout>
    </v-container>
</template>
<script>
import axios from 'axios';
import ConstantCustomEvent from '../../../../shared/constant/constantCustomEvent';
import ConstantDocumentType from '../../../../shared/constant/constantDocumentType';
import ConstantDatasheetField from '../../../../shared/constant/constantDatasheetField';
import AutocompleteSelect from '../../../shared/AutocompleteSelect.vue';
import BolDatePicker from '../../../shared/DatePicker';

/**
 * 
 * First step of the creation of a datasheet
 * 
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 26/09/2018
 */
export default {
    name: "DatasheetFormStepOne",
    components:{
        AutocompleteSelect,
        BolDatePicker
    },
    props: {
        idDocument: Number,
        datasheet: Object
    },
    data() {
        return {
            isUpdateMode: false,
            displaySpinner: false,
            valid: false,
            documentTypeList: [],
            partnerList: [],
            marketList: [],
            workspaceList: [],
            causeEndOfApplicabilityList: [],
            menu: false,
            documentTypeRules: [
                v => !!v || this.$t("document.create.required.documenttype")
            ],
            titleRules: [
                v => !!v || this.$t("document.create.required.title")
            ],
            workSpaceRules: [
                v => !!v || this.$t("document.create.required.workspace")
            ],
            marketRules: [
                v => !! v || this.$t("document.create.required.market")
            ],
            isCauseEndOfApplicabilityDisabled: true,
            isSourceEndApplicabilityDisabled: true,
        }
    },
    computed: {
        DATASHEET_FIELD: () => ConstantDatasheetField.FIELD,
        documentId:{
            get(){
                return this.idDocument;
            }
        },
        partnerId:{
            get(){
                return !!this.datasheet.partner ? this.datasheet.partner.id : -1;
            }
        },
        documentTypeMarkets:{
            get(){
                if(!!this.datasheet.documentType && !!this.datasheet.documentType.marketsId) {
                    return this.marketList.filter((market) => {
                        return this.datasheet.documentType.marketsId.includes(market.id)
                    });
                } else {
                    return [];
                }
            }
        },
        availableWorkspacesForSelectedMarkets:{
            get(){
                return this.workspaceList.filter(
                    (workspace) => workspace.markets.find(
                        market => this.datasheet.marketsId.includes(market.id)
                    )
                );
            }
        },
    },
    /**
     * Method will load all the list for the first page of a datasheet management step 1
     */
    created() {
        this.displaySpinner = true;
        if(!!this.idDocument){
            this.isUpdateMode = true;
        }
        axios.get('/datasheet/create/init/step1').then( ({data}) => {
            this.documentTypeList = data.documentTypeList;
            this.partnerList = data.partnerList;
            this.workspaceList = data.workspaceList;
            this.marketList = data.marketList.map((market) => {
                market.label = market.code; return market;
            });
            this.causeEndOfApplicabilityList = data.causeEndApplicabilityList;
            this.displaySpinner = false;
            this.hasPartner();
            this.setComponentVisibility()
        });
    },
    methods: {
        syncSelectedMarketsToDocumentType() {
            this.datasheet.marketsId = this.datasheet.marketsId.filter((marketId) =>
                this.documentTypeMarkets.include(marketId)
            );
        },

        syncSelectedWorkspacesToSelectedMarkets() {
            let workspaces = this.availableWorkspacesForSelectedMarkets;
            if (!workspaces.find((workspace) => workspace.id === this.datasheet.idWorkspace))
            {
                this.datasheet.idWorkspace = null;
            }
            this.datasheet.idOtherWorkspace = this.datasheet.idOtherWorkspace.filter((workspaceId) =>
                !workspaces.find((workspace) => workspace.id === workspaceId)
            )
        },

        /**
         * Show a warning if we choose a jurisprudence
         */
        isJurisprudence(){
            return !!this.datasheet.documentType && this.datasheet.documentType.code === ConstantDocumentType.JURISPRUDENCE;
        },
        /**
         * Add the necessary information on the document type value returned
         */
        $consolidateDocumentType(documentTypeId){
            return this.documentTypeList.find(documentType => documentTypeId === documentType.id) || {documentType:{}};
        },
        /**
         * Go to the second step of the creation if the form is validate
         */
        next() {
            if (this.$refs.formCreateEditDocument.validate()) {
                this.$emit(ConstantCustomEvent.EVENT.NEXT_STEP);
            }
        },
        /**
         * Reset the form
         */
        clear() {
            this.$refs.formCreateEditDocument.reset();
        },
        /**
         * Set the value of specified datasheet field
         */
        setField(fieldValue, fieldName){
            if(fieldName === ConstantDatasheetField.FIELD.DOCUMENT_TYPE_ID){
                fieldValue = this.$consolidateDocumentType(fieldValue);
                fieldName = ConstantDatasheetField.FIELD.DOCUMENT_TYPE;
            }
            this.$emit(ConstantCustomEvent.EVENT.SET_FIELD, { value: fieldValue, name:fieldName });
            this.setComponentVisibility();
        },
        /**
         * Set or update the value of specified datasheet field
         */
        setEndDateOfApplicability(fieldValue) {
            this.setField(fieldValue, ConstantDatasheetField.DATE_END_APPLICABILITY);
        },
        /**
         * Set the visibility of specific components
         */
        setComponentVisibility(){
            this.isCauseEndOfApplicabilityDisabled = (this.datasheet.endDateOfApplicability === null || this.datasheet.indeterminate);
            this.isSourceEndApplicabilityDisabled = (this.datasheet.causeEndOfApplicability === ConstantDatasheetField.CAUSE_END_OF_APPLICABILITY_VALUE.NONE ||
                this.datasheet.causeEndOfApplicability === ConstantDatasheetField.CAUSE_END_OF_APPLICABILITY_VALUE.GIVEN ||
                this.datasheet.causeEndOfApplicability === null ||
                this.datasheet.indeterminate);
        },
        /**
         * If the datasheet is in a creation mode, we set the partner selected to the default value "Legitech"
         */
        hasPartner(){
            if ( !this.isUpdateMode ){
                let partnerLgc = this.partnerList.find( partner => partner.code === ConstantDatasheetField.PARTNER.LEGITECH_CODE);
                this.setField(partnerLgc.id, ConstantDatasheetField.FIELD.PARTNER_ID);
            }
        }
    }
}
</script>
