/**
 * Constants for paoFilterStore
 */

const UPDATE_TITLE          = 'updateTitle';
const UPDATE_STATUS         = 'updateStatus';
const CHANGE_PAGE           = 'changePage';
const UPDATE_PAGEABLE       = 'updatePageable';

const SET_FILTER_TITLE      = 'setFilterTitle';
const SET_FILTER_STATUS     = 'setFilterStatus';
const SET_PAGE              = 'setPage';
const SET_PAGEABLE          = 'setPageable';

export default class Types{

    static get ACTION(){
        return {
            UPDATE_TITLE,
            UPDATE_STATUS,
            CHANGE_PAGE,
            UPDATE_PAGEABLE
        }
    }

    static get MUTATION(){
        return {
            SET_FILTER_TITLE,
            SET_FILTER_STATUS,
            SET_PAGE,
            SET_PAGEABLE
        }
    }

}

