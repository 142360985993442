<template>
    <v-content pa-0>
        <global-tool-bar />
        <router-view />
        <notification-container />
    </v-content>
</template>
<script>
import axios                    from 'axios';
import GlobalToolBar            from './shared/GlobalToolBar';
import NotificationContainer    from './shared/notification/NotificationContainer';

export default ({
    name: 'Main',
    components: {
        GlobalToolBar,
        NotificationContainer
    },
    mounted(){
        axios(
            {
                method: 'GET',
                url: '/secure/user/current'
            }
        ).then( result => {
            this.$store.dispatch('userStore/setUserPreferences',result.data);
        });
        axios.get(`/language`)
            .then( ({data}) => {
                this.$store.dispatch('languageStore/setLanguages', data);
            });
    }
})
</script>
