<template>
    <v-btn large class="bol-large-button" :to="route">
        <v-layout justify-space-around>
            <v-icon color="primary" dark>{{this.icon}}</v-icon>
            <div>{{this.label}}</div>
            <v-icon>arrow_right_alt</v-icon>
        </v-layout>
      </v-btn>
</template>
<script>
export default {
    name:"BolLargeButton",
    props: {
        icon: {
            default: null
        },
        label: {
            default: null
        },
        route: {
            default: null
        }
    }
}
</script>
<style scoped>
.bol-large-button{
    width: 380px;
    height: 72px;
    padding: 25px;
}
</style>
