<template>
    <v-layout justify-start fill-height >
        <v-flex class="filter_sizer">
            <document-filter :isLink="false" :newSearch="$route.params.newSearch"/>
        </v-flex>
        <v-flex class="document_sizer">
            <document-list/>
        </v-flex>
    </v-layout>
</template>
<script>
import DocumentList     from    './DocumentList';
import DocumentFilter   from    './DocumentFilter';

/**
 * Document search component.
 * @author DE SANTIS Sébastien, SFEIR benelux
 * @version 1.0
 * @since 13/09/2018
 */
export default {
    name: "DocumentSearch",
    components: {
        DocumentList,
        DocumentFilter
    }
}
</script>
<style scoped>

    .filter_sizer{
        width: 400px;
        min-height:1000px;
    }
    .document_sizer{
        width: 100%;
    }

</style>