<template>
    <div @click="clickOnNode"
            @contextmenu="showContextMenu">
        <content-node class="node-content" :node="rootNode"/>
        <v-menu v-model="isMenuOpen"
                :position-x="menuPosition.x"
                :position-y="menuPosition.y"
                absolute>
            <v-list>
                <v-list-tile
                    @click="linkNodeId()">
                    <v-list-tile-title>{{ $t('document.link.add') }}</v-list-tile-title>
                </v-list-tile>
            </v-list>
        </v-menu>
    </div>
</template>
<script>
import EventBus                 from '../../../../utils/event-bus';
import ConstantEvent            from '../../../../shared/constant/constantEvent';
import ConstantTag              from '../../../../shared/constant/constantTag';
import ContentNode              from '../../structure/displayer/ContentNode';
import DocumentTreeService      from '../../../../service/document/documentTreeService';
import { nodeContentMixin }     from '../../../../mixins/nodeContentMixin';

/** 
 * Display a node with his content if he got one.
 * Display all his children using a recursively way
 * 
 * @author Justin WILMET, SFEIR Benelux
 * @author Cédric deBoisvilliers, SFEIR Benelux
 * @version 1.0
 * @since 15/11/2018
 */
export default {
    name: "DocumentNodeContentHyperLink",
    mixins: [nodeContentMixin],
    components: {
        ContentNode
    },
    props: {
        rootNode: {
            default: null
        }
    },
    data: () => ({
        selectedNode: null,
        isMenuOpen: false,
        menuPosition: {
            x: 0,
            y: 0
        }
    }),
    methods:{
        /**
         * Store the id of the node that the user want to bind in the HyperLink
         */
        linkNodeId() {
            EventBus.$emit(ConstantEvent.SEND_ID_NODE_DESTINATION, {nodeId: this.selectedNode.id});
        },
        /**
         * Show the contextual menu to add a link.
         */
        showContextMenu (event) {
            event.preventDefault();
            this.selectNode(event);
            if (!!this.selectedNode) {
                this.menuPosition.x = event.clientX;
                this.menuPosition.y = event.clientY;
                this.isMenuOpen = true;
            }
        },
        /**
         * Select the clicked Node inside the document.
         * @param event
         */
        clickOnNode(event) {
            this.cancelHyperlinkVisualization(event);//nodeContentMixin method
            this.selectNode(event);
        },
        /**
         * Select the appropriate node, depending on the clicked node.
         */
        selectNode(event){
            let clickedNode = this.findClickedDocumentNode(event, this.rootNode);
            if (this.isSelectable(clickedNode)) {
                if (clickedNode.label === ConstantTag.TEXT) {
                    clickedNode = DocumentTreeService.findParentNode(clickedNode, DocumentTreeService.flatTree(this.rootNode));
                }
                if (!!this.selectedNode) {
                    this.selectedNode.selected = false;
                }
                clickedNode.selected = true;
                this.selectedNode = clickedNode;
            } else if (!!this.selectedNode) {
                this.selectedNode.selected = false;
                this.selectedNode = null;
            }
        },
        /**
         * Cancel the left click on the link 
         */
        cancelHyperlinkVisualization(event) {
            event.preventDefault();
        },
        /**
         * Check if the clicked node can be selected.
         */
        isSelectable(node){
            return !!node && node.label !== ConstantTag.ACT;
        }
    }
}
</script>


