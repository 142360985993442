<template>
    <v-dialog v-model="dialog" persistent max-width="700px">
        <v-card>
            <v-card-title>
                <v-layout align-start justify-center>
                    <h3>{{$t('document.error.existingLink')}}</h3>
                </v-layout>
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" flat @click.native="close">{{$t('document.confirm.ok')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import EventBus from '../../../../utils/event-bus.js';
import axios from 'axios';
import { mapState, mapActions} from 'vuex';
/**
 * //TODO: change description to match the component's behaviour
 * Open a dialog to add a hyperlink on the selected part of the text
 * @author Justin WILMET
 * @since 23/10/2018
 * @version 1.0
 * 
 */
export default {
    name: 'DialogError',
    data: function(){
        return{
            dialog: false
        }
    },
    created: function(){
        EventBus.$on('event-document-hyper-link-management-error', this.listener);
    },
    beforeDestroy: function(){
        EventBus.$off('event-document-hyper-link-management-error', this.listener);
    },
    methods:{
        listener(data){
            this.dialog = true;
        },
        close: function(){
           this.dialog = false;
        }
    }
}
</script>
