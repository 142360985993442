<template>
    <v-layout
        row
        wrap
        align-left
    >
        <v-flex>
            <v-container class="t_create_edit_dialog">
                <h1
                    v-if="$route.params.id == null"
                    class="bolded"
                >
                    {{ $t('administration.nature.manage.add.title') }}
                </h1>
                <v-form
                    ref="formNature"
                    v-model="valid"
                    class="form-style"
                    lazy-validation
                >
                    <v-layout
                        column
                        justify-center
                    >
                        <v-flex md6>
                            <v-text-field
                                v-model="nature.label"
                                class="t_name"
                                :label="$t('administration.nature.label.name')"
                                :rules="labelRules"
                                required
                                clearable
                            />
                        </v-flex>
                        <v-flex md6>
                            <v-text-field
                                v-model="nature.code"
                                class="t_abbreviation"
                                :label="$t('administration.nature.label.abbreviation')"
                                :rules="abbreviationRules"
                                required
                                clearable
                            />
                        </v-flex>
                        <v-flex md6>
                            <v-text-field
                                v-model="nature.description"
                                class="t_description"
                                :label="$t('administration.nature.label.description')"
                                clearable
                            />
                        </v-flex>
                        <!-- MARKET -->
                        <v-autocomplete
                            v-model="nature.markets"
                            class="t_markets"
                            :items="allMarketList"
                            :disabled="allMarketList.length === 0"
                            dense
                            filled
                            multiple
                            chips
                            small-chips
                            item-text="code"
                            return-object
                            :label="$t('administration.nature.label.markets')"
                        />
                        <!-- BUTTONS -->
                        <v-flex
                            xs3
                            justify-end
                        >
                            <v-tooltip
                                top
                                class="align-action-end"
                            >
                                <v-btn
                                    slot="activator"
                                    color="#355B7F"
                                    small
                                    fab
                                    :to="{ name: 'NatureSearch' }"
                                >
                                    <v-icon color="white">
                                        cancel
                                    </v-icon>
                                </v-btn>
                                <span>{{ $t("administration.nature.manage.action.cancel") }}</span>
                            </v-tooltip>
                            <v-tooltip
                                v-if="valid"
                                top
                                class="align-action-end"
                            >
                                <v-btn
                                    slot="activator"
                                    class="t_save"
                                    color="primary"
                                    small
                                    fab
                                    dark
                                    icon
                                    @click="manage(nature)"
                                >
                                    <v-icon>save</v-icon>
                                </v-btn>
                                <span>{{ $t("administration.nature.manage.action.add") }}</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-container>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from 'axios';
import EventBus from '../../../utils/event-bus.js';
import ConstantEvent from '../../../shared/constant/constantEvent.js'

export default {
    name: 'NatureManagement',
    data () {
        return {
            valid: false,
            nature: {
                id: null,
                label: null,
                description: null,
                code: null,
                markets: [],
            },
            allMarketList: [],
            labelRules: [
                v => !!v || this.$t("administration.nature.manage.required")
            ],
            abbreviationRules: [
                v => !!v || this.$t("administration.nature.manage.required"),
                v => !!v && v.length < 21 || this.$t("administration.nature.manage.too-long") + "20"
            ]
        }
    },
    mounted(){
        /**
             * When the page is charging, we pay attention if an id is passed,
             * if it's the case, it means that we update a nature instead of creating one
             */
        if(this.$route.params.id !== undefined) {
            this.loadNature(this.$route.params.id);
        }
        this.loadMarkets();
    },
    methods:{
        /**
             *  Create or update a nature
             * @param newNature
             */
        manage(nature) {
            if(this.$refs.formNature.validate()){
                var method = null;
                if(!!this.$route.params.id) {
                    method = "PUT",
                    nature.id = this.$route.params.id;
                } else {
                    method = "POST";
                }

                axios( {
                    method: method,
                    url: "administration/nature",
                    data: nature
                }).then( () => {
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                        message: 'administration.nature.manage.' +  (!this.$route.params.id ? 'add' : 'edit') +'.success',
                        type: 'SUCCEED'
                    }
                    );
                    this.$router.push('/administration/nature');
                });
            }
        },
        /**
             * Retrieve a nature with its id
             * @param id
             */
        loadNature( id ) {
            axios.get(
                `administration/nature/${id}`
            ).then( result => {
                this.nature = result.data;
            });
        },
        loadMarkets() {
            axios.get(
                `administration/markets`
            ).then(result => {
                this.allMarketList = result.data;
            });
        }
    }
}
</script>
<style>
    .form-style {
        margin-top:50px;
    }
    .align-action-end{
        float: right;
    }
    .bolded{
        font-weight: bold;color: #355B7F;
    }
</style>
