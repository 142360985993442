<template>
    <v-layout
        align-space-between
        justify-space-around
        row
        fill-height
        class="background_white"
    >
        <v-flex
            class="settings"
            xs3
        >
            <v-layout
                class="container_panel"
                row
                wrap
                fill-height
            >
                <v-flex class="status_sizer">
                    <v-layout
                        v-if="!!pao && !!pao.status"
                        align-center
                        justify-center
                        class="margin_status"
                    >
                        <v-flex
                            xs8
                            class="text-white"
                        >
                            <v-layout
                                row
                                justify-space-between
                            >
                                <v-flex>
                                    <h2 class="under-text">
                                        {{ $t(`pao.label.status`) }}
                                    </h2>
                                    <h4>{{ $t(`pao.status.label.${pao.status}`) }}</h4>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>

                    <v-divider
                        dark
                        class="divider_pao_panel"
                    />

                    <action-panel-pao
                        v-if="!!pao && !!pao.status"
                        :id-pao="pao.id"
                    />
                </v-flex>
            </v-layout>
        </v-flex>

        <v-flex
            class="tabs-container"
            xs9
        >
            <v-tabs
                slot="extension"
                v-model="tab"
                grow
            >
                <v-tabs-slider color="primary" />
                <v-tab>{{ $t("pao.label.metadata") }}</v-tab>
                <v-tab>{{ $t("pao.label.structure") }}</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-layout
                        v-if="!displaySpinner"
                        column
                    >
                        <v-layout
                            align-right
                            justify-start
                            column
                        >
                            <v-flex
                                xs12
                                sm6
                            >
                                <v-card-title>
                                    <h4 class="headline primary--text">
                                        {{ $t("pao.label.globalInformation") }}
                                    </h4>
                                </v-card-title>
                                <v-container
                                    v-bind="{ [`grid-list-xs`]: true }"
                                    fluid
                                >
                                    <v-layout
                                        row
                                        wrap
                                        justify-content-start
                                    >
                                        <v-card
                                            class="item"
                                            flat
                                            raised
                                        >
                                            <v-card-text>
                                                <span
                                                    class="grey--text"
                                                >{{
                                                    $t("pao.label.disponibility")
                                                }} : </span><br>{{
                                                    pao.hasBeenExportedOnce ? $t('pao.disponibility.available') : $t('pao.disponibility.unavailable')
                                                }}
                                            </v-card-text>
                                        </v-card>
                                        <v-card
                                            class="item"
                                            flat
                                            raised
                                        >
                                            <v-card-text>
                                                <span class="grey--text">{{ $t("pao.label.code") }} : </span><br>{{ pao.code }}
                                            </v-card-text>
                                        </v-card>
                                        <v-card
                                            v-if="!!pao.publicationDate"
                                            class="item"
                                            flat
                                            raised
                                        >
                                            <v-card-text>
                                                <span class="grey--text">{{ $t("pao.label.publication-date") }} : </span><br>{{
                                                    $d(parseDate(pao.publicationDate))
                                                }}
                                            </v-card-text>
                                        </v-card>
                                        <v-card
                                            v-if="!!pao.creationDate"
                                            class="item"
                                            flat
                                            raised
                                        >
                                            <v-card-text>
                                                <span class="grey--text">{{ $t("pao.label.creationDate") }} : </span><br>{{
                                                    $d(parseDate(pao.creationDate))
                                                }}
                                            </v-card-text>
                                        </v-card>
                                        <v-card
                                            v-if="!!pao.lastModificationDate"
                                            class="item"
                                            flat
                                            raised
                                        >
                                            <v-card-text>
                                                <span class="grey--text">{{ $t("pao.label.modificationDate") }} : </span><br>{{
                                                    $d(parseDateTime(pao.lastModificationDate), 'medium')
                                                }}
                                            </v-card-text>
                                        </v-card>
                                        <v-card
                                            v-if="!!pao.description"
                                            class="item"
                                            flat
                                            raised
                                        >
                                            <v-card-text>
                                                <span
                                                    class="grey--text"
                                                >{{ $t("pao.label.description") }} : </span><br>{{ pao.description }}
                                            </v-card-text>
                                        </v-card>
                                        <v-card
                                            class="item"
                                            flat
                                            raised
                                        >
                                            <v-card-text>
                                                <span
                                                    class="grey--text"
                                                >{{ $t("pao.label.version") }} : </span><br>{{ pao.version }}
                                            </v-card-text>
                                        </v-card>
                                        <v-card
                                            class="item"
                                            flat
                                            raised
                                        >
                                            <v-card-text>
                                                <span
                                                    class="grey--text"
                                                >{{ $t("pao.label.edition") }} : </span><br>{{ pao.edition }}
                                            </v-card-text>
                                        </v-card>
                                    </v-layout>
                                </v-container>
                            </v-flex>
                        </v-layout>
                    </v-layout>
                    <!-- LOADING SPINNER -->
                    <v-layout
                        v-if="displaySpinner"
                        align-center
                        justify-center
                        row
                    >
                        <v-progress-circular
                            :size="70"
                            :indeterminate="true"
                            color="primary"
                        />
                    </v-layout>
                </v-tab-item>

                <v-tab-item>
                    <v-container @click="clickOnNode">
                        <pao-node
                            v-if="!!pao && !!pao.structure"
                            :document-openable="true"
                            :node="pao.structure"
                            :read-only="true"
                        />
                    </v-container>
                </v-tab-item>
            </v-tabs-items>
        </v-flex>
      <dialog-node-content-tree-light v-if="openNodeContentTreeLight" :dialog.sync="openNodeContentTreeLight" :storeName="storeName" :idPaoDocument="idPaoDocument"/>
      <toc-visualisator-dialog-component v-if="!!paoStructure" :dialog.sync="popupTocState" :node="paoStructure" />
    </v-layout>
</template>

<script>
import ActionPanelPao from './ActionPanelPao';
import axios from 'axios';
import ConstantClassName from '../../../shared/constant/constantClassName.js'
import ConstantEvent from '../../../shared/constant/constantEvent.js';
import ConstantApplication from '../../../shared/constant/constantApplication';
import DialogNodeContentTreeLight from '../dialog/DialogNodeContentTreeLight.vue';
import EventBus from '../../../utils/event-bus';
import moment from 'moment';
import Namespace from '../../../shared/constant/store/module/namespace';
import PaoStructureStoreTypes from '../../../shared/constant/store/module/paoStructureTypes';
import PaoNode from '../structure/PaoNode';
import {mapActions} from 'vuex';
import TocVisualisatorDialogComponent from '../components/TocVisualisatorDialogComponent';

/**
 * Display a pao details(metadata and structure)
 *
 * @author Cédric de BOISVILLIERS, SFEIR Benelux
 * @version 1.0
 * @since 24/09/2019
 */
export default {
    name: 'PaoDisplay',
    components: {
        ActionPanelPao,
        DialogNodeContentTreeLight,
        PaoNode,
        TocVisualisatorDialogComponent
    },
    data: () => ({
        displaySpinner: false,
        pao: null,
        document: null,
        openNodeContentTreeLight: false,
        tab: null,
        idPaoDocument: null,
        popupTocState: false,
        paoStructure: null,
    }),
    computed: {
        storeName() {
            return Namespace.DOCUMENT_CONTENT_STORE;
        }
    },
    created() {
        const id = this.$route.params.id;
        this.loadPao(id);
    },
    mounted() {
        EventBus.$on(ConstantEvent.SEND_ID_DOCUMENT, this.loadDocumentContent);
        EventBus.$on(ConstantEvent.OPEN_ID_TOC, this.setPopupTocState);

    },
    beforeDestroy() {
        EventBus.$off(ConstantEvent.SEND_ID_DOCUMENT);
        EventBus.$off(ConstantEvent.OPEN_ID_TOC);
    },
    methods: {
        ...mapActions(Namespace.PAO_STRUCTURE, {
            updateClickedNode: PaoStructureStoreTypes.ACTION.UPDATE_CLICKED_NODE,
            initStructureStore: PaoStructureStoreTypes.ACTION.INIT,
            updateDocument: PaoStructureStoreTypes.ACTION.UPDATE_DOCUMENT
        }),
        ...mapActions(Namespace.DOCUMENT_CONTENT_STORE, {
            loadDocumentContentWithUrl: 'loadDocumentContentWithUrl',
        }),
        ...mapActions(Namespace.DATASHEET_STORE, {
            resetMetadataDatasheet: 'resetMetadataDatasheet',
        }),
        /**
     * Load a pao with its metadata and its structure
     * @return a promise
     */
        loadPao(id) {
            this.displaySpinner = true;
            return axios.get(`pao/${id}/display`)
                .then(({data}) => {
                    this.pao = data;
                    this.paoStructure = data.structure;
                    this.initStructureStore(data.structure)
                    this.displaySpinner = false;
                });
        },
        /**
     * open dialog for toc and send an id to load toc selected
     **/
        setPopupTocState(event) {
            this.popupTocState = true;
            EventBus.$emit(ConstantEvent.SEND_ID_TOC, event)
        },
        /**
     * Select the clicked node
     * @param {Event} event
     */
        clickOnNode(event) {
            const eventTargetList = event.composedPath();

            let targetIndex = 0;
            let eventTarget = eventTargetList[targetIndex];

            while (targetIndex < eventTargetList.length && (!eventTarget.className || !eventTarget.className.includes(ConstantClassName.PAO_NODE))) {
                targetIndex++;
                eventTarget = eventTargetList[targetIndex];
            }
            if (!!eventTarget && !!eventTarget.className && eventTarget.className.includes(ConstantClassName.PAO_NODE)) {
                this.updateClickedNode(eventTarget.id);
            } else {
                this.updateClickedNode(null);
            }
        },
        /**
     * Load the content of a document in the pao
     * @param {Number} idDocument, id of the clicked document
     * @param {Number} idNode, id of the node containing the document
     */
        loadDocumentContent(idPaoDocument) {
            // Before loading a document content for a pao,
            // we need to be sure the variable watched to reload a document content isn't set,
            // otherwise if the current value is the same than the next time a document is opened
            // the content wont be reloaded and the content of the document loaded here will be accessible for another document.
            // Actually it's the metaDocument.idDocument in the dataSheet store.
            this.resetMetadataDatasheet();
            this.idPaoDocument = idPaoDocument;
            this.displaySpinner = true;
            let idPao = this.$route.params.id;
            this.loadDocumentContentWithUrl({id: this.idPaoDocument, url: `pao/document/${idPaoDocument}`})
                .then(data => {
                    this.openNodeContentTreeLight = true;
                    this.displaySpinner = false;
                }, () => {
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                        type: ConstantNotification.ERROR,
                        message: 'pao.notification.openDocumentError',
                    });
                });
        },
        parseDate(date) {
            return !!date ? new Date(moment(date).format(ConstantApplication.DEFAULT_DATE_FORMAT)) : '';
        },
        parseDateTime(date) {
            return !!date ? new Date(moment(date).format(ConstantApplication.DEFAULT_DATE_TIME_FORMAT)) : '';
        }
    }
}
</script>
<style scoped>
.background_white {
  background-color: white;
}

.container_panel {
  height: calc(100vh + 48px);
  padding-top: 20%;
  padding-left: 20px;
  padding-right: 20px;
}

.divider_pao_panel {
  margin-bottom: 5%;
}

.margin_status {
  margin-bottom: 10%;
}

.settings {
  background-color: #355B7F;
  height: 100%;
}

.text-white {
  color: white;
}

.tabs-container {
  min-height: 100vh;
}

.under-text {
  text-decoration: underline;
}

.item {
  min-width: 300px;
}
</style>
