<template>
    <div>
            <v-data-table
                :headers="[]"
                :items="documentLinkList"
                hide-actions          
            >
                <template slot="headers" slot-scope="props">
                    <th class="title-link">{{ $t('document.link.title')}}</th>
                    <th>{{ $t('document.link.destination-node')}}</th>
                    <th>{{ $t('document.link.linkType')}}</th>
                    <th>{{ $t('document.link.linkRelationType')}}</th>
                    <th>{{ $t('document.link.sourceNode')}}</th>
                    <th>{{ $t('document.link.localise')}}</th>
                </template>

                <template slot="items" slot-scope="props">
                    <td v-if="!!props.item.url">
                        <v-tooltip right max-width="550">
                            <template slot="activator">
                                <a :id="props.item.uuid" :href="props.item.url" target="_blank" class="tech_bol_external over-flow-handler"> {{ props.item.url }}... </a>
                            </template>
                            <span>{{ props.item.url }}</span>
                        </v-tooltip>                        
                    </td>
                    <td v-if="!!props.item.referenceDocumentDestination && !props.item.nodeUUIDDestination">
                        <v-tooltip right max-width="550">
                            <template slot="activator">
                                <a  :id="props.item.uuid"
                                    @click="goToLink(props.item)"
                                    :class="bolDocumentClass( props.item.relationType )" 
                                    >{{ props.item.titleDocumentDestination }}...</a>
                            </template>
                            <span>{{ props.item.titleDocumentDestination }}</span>
                        </v-tooltip>                  
                    </td>
                    <td v-if="!!props.item.referenceDocumentDestination && !!props.item.nodeUUIDDestination">
                        <v-tooltip right max-width="550">
                            <template slot="activator">
                                <a  :id="props.item.uuid"
                                    @click="goToLink(props.item)"
                                    :class="bolContentClass( props.item.relationType )" 
                                    >{{ props.item.titleDocumentDestination}} </a>
                             </template>
                            <span>{{ props.item.titleDocumentDestination }}</span>
                        </v-tooltip>   
                    </td>
                    <td class="center-item">{{ props.item.nodeDestinationReference}}</td>
                    <td class="center-item">{{ props.item.type }}</td>
                    <td class="center-item">{{ props.item.relationType }}</td>
                    <td class="center-item">{{ props.item.nodeSourceReference}}</td>
                    <td>
                        <v-icon class="center-item" medium @click="goToLink(props.item)">
                            search
                        </v-icon>
                    </td>
                </template>
            </v-data-table>
        <dialog-view-a-link v-if="!!viewId" :dialog.sync="viewALinkDialog" :viewId="viewId" :viewNodeId="viewNodeId" @onClose="onClose"></dialog-view-a-link>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import axios                    from 'axios';
import DialogViewALink          from '../../link/dialog/DialogViewALink.vue';
import EventBus                 from '../../../../utils/event-bus';
import ConstantEvent            from '../../../../shared/constant/constantEvent';


/**
 * Display the list of all links available on the document.
 * 
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 29/04/2019
 */
export default {
    name:'DocumentLinkList',
    props: ['documentLinkList'],
    data(){
        return {
            viewALinkDialog: false,
            viewId: null,
            viewNodeId: null
        }
    },
    methods: {
        goToLink( item ) {
            if( !!item.url ){
                window.open(item.url);
            } else {
                if (!item.referenceDocumentDestination || !!item.nodeUUIDDestination) {
                    this.viewNodeId = item.nodeUUIDDestination;
                }
                this.viewId = item.referenceDocumentDestination;
                this.viewALinkDialog = true;
                EventBus.$emit(ConstantEvent.HIDE_BACK_ARROW_FOR_POPUP);
            }
        },
        onClose(){
            this.viewId = null;
            this.viewNodeId = null;
        },
        bolDocumentClass ( relationType ) {
            let relation =  !!relationType ? relationType : '';
            return `tech_bol_document ${relation} over-flow-handler`;
        },
        bolContentClass ( relationType ) {
            let relation =  !!relationType ? relationType : '';
            return `tech_bol_content ${relation} over-flow-handler`;
        }

    },
    components:{ 
        DialogViewALink
    }

}
</script>
<style scoped>
.center-item {
    text-align: center;
}
.title-link {
    width: 100%;
    word-break: break-all;
    display: block;
    padding: 24px !important;
}
.over-flow-handler{
    display: flex;
    align-items: center;
    height: 48px;
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>