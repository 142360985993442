import axios                from 'axios';
import ConstantEvent        from '../shared/constant/constantEvent.js';
import ConstantNotification from '../shared/constant/constantNotification.js';
import EventBus             from '../utils/event-bus.js';
/**
 * Interceptor for all the application.
 * Manage the CSRF TOKEN. Store it in a global variable and reuse it in all the request
 * 
 * @author Sébastien DE SANTIS, SFEIR Benelux
 * @param {*} router 
 */

export default function setup(router, store) {

    axios.defaults.baseURL = '/api';
    axios.defaults.withCredentials = true;
    /**
     * Response interceptor.
     * Manage all the errors
     */
    axios.interceptors.response.use(response => {
        store.dispatch('userStore/setCsrfToken',response.headers["x-csrf-token"]);
        return response;
    }, error => {
        if( !!error.response ){
            if( error.response.status === 401 ) {
                //If the request came from the login page, we do nothing. Because the login page could display the connection error
                if(error.request.responseURL.includes('authentication')){
                    return Promise.reject(error);
                } else {
                    localStorage.setItem("token", null); //reset the token as session is dead in backend
                    router.push('/');
                }
            } else if ( error.response.status === 400 || error.response.status === 412 ) {
                var notification = {type: ConstantNotification.ERROR};
                notification.message = "global.errors."+error.response.data.type.toLowerCase()+"."+error.response.data.code;
                if( !!error.response.data.message ) {
                    notification.additionalMessage = error.response.data.message;
                }
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
                return Promise.reject(error);
            }
        } else {
            return Promise.reject(error);
        }
    });
    /**
     * Request interceptor
     */
    axios.interceptors.request.use(request => {
        let XCsrfToken = store.getters["userStore/userPreferences"];
        request.headers["x-csrf-token"] = XCsrfToken;
        return request;
    })
}

