<template>
    <v-flex md6 pa-3>
        <v-menu
        ref="menuDate"
        :close-on-content-click="false"
        v-model="menuDate"
        :nudge-right="40"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
        >
        <v-text-field
            slot="activator"
            :value="value != null ? $d(new Date(value)): null"
            :label="label"
            prepend-icon="event"
            readonly
            clearable
            @input="updateDateValue()"
        ></v-text-field>
        <v-date-picker 
            :value="datepickerDate"
            @input="updateDate($event)"
            :min="'1800-01-01'"
            :locale="preferredLanguage"></v-date-picker>
        </v-menu>
    </v-flex>
</template>
<script>
/**
 * A date picker component for all formulary who need a date picker
 * @author Sébastien De Santis, Sfeir Benelux
 * @version 1.0
 * @since 12/12/2019
 */
import moment               from 'moment';    
import { mapState }         from 'vuex';
import ConstantApplication  from '../../shared/constant/constantApplication';

const EVENT_TO_PARENT       = 'onBindModel';

export default {
    name: 'BolDatePicker',
    props:{
        value: {
            default: null
        },
        label: {
            defaul: null
        }
    },
    data(){
        return {
            menuDate: null
        }
    },
    computed:{
        ...mapState({
            preferredLanguage: state => state.userStore.userPreferences.preferredLanguage
        }),
        datepickerDate(){
            return this.value ? moment(this.value).format(ConstantApplication.DEFAULT_DATE_FORMAT) : '';
        }
    },
    methods:{
        /**
         * Update the date value
         */
        updateDateValue(date){
            this.sendModel(date);
        },
        /**
         * update the date menu to display the new content
         */
        updateDate(date){
            this.$refs.menuDate.save(date);
            this.sendModel(date);
        },
        /**
         * Return the field(s) selected in the autocomplete to the parent.
         */
        sendModel( fieldValue ) {
            this.$emit(EVENT_TO_PARENT, fieldValue);
        }
    }
}
</script>