import axios from 'axios';
import ConstantDocumentNode from '../../shared/constant/constantDocumentNode.js';
import Types from '../../shared/constant/store/module/paoStructureTypes'
import PaoService from '../../service/pao/paoService';

/**
 * Pao structure store
 */
export default {
    namespaced: true,
    /**
     * STATE
     */
    state: () => ({
        structure: null,
        nodeMap: new Map(),
        clickedNode: null,
        nodeTypeList: [],
        nodePolicies: [],
        document: null,
        clickedNodeDocument: null,
        documentToPaste: null,
        nodeToPaste: null
    }),
    /**
     * GETTERS
     */
    getters: {
        /**
         * Return the available policies that can be applied on the clickedNode.
         */
        [Types.GETTER.AVAILABLE_POLICIES]: (state) => {
            return state.nodePolicies.filter(policy =>
                policy.idParentNodePolicy === state.clickedNode.idNodePolicy
            );
        },
        /**
         * Return the parent Node of the clickedNode.
         */
        [Types.GETTER.PARENT_NODE]: (state) => {
            return Array.from(state.nodeMap.values()).find(node => {
                return !!node.children && node.children.some(childNode => childNode.id === state.clickedNode.id)
            });
        },
        /**
         * Return the nodePolicy of the clickedNode.
         */
        [Types.GETTER.POLICY]: (state) =>
            state.nodePolicies.find(policy => policy.id === state.clickedNode.idNodePolicy),
        /**
         * Return the clicked node, if no nodes are selected the root is set as selectedNode
         */
        [Types.GETTER.DEFAULT_CLICKED_NODE]: (state) => {
            if (!!state.clickedNode) {
                return Array.from(state.nodeMap.values()).find(node => {
                    return node.id === state.clickedNode.id
                });
            } else {
                return Array.from(state.nodeMap.values()).find(node => {
                    return node.type === ConstantDocumentNode.ROOT;
                });
            }
        }
    },
    /**
     * ACTIONS
     */
    actions: {
        /**
         * Use this action to add a node that have been already saved in database.
         */
        [Types.ACTION.ADD_CREATED_NODE]: ({commit}, node) => {
            commit(Types.MUTATION.ADD_NODE, node);
        },
        [Types.ACTION.ADD_NODE]: ({state, commit}, node) => {
            if (!!state.clickedNode) {
                const nodeType = state.nodeTypeList.find(nodeType =>
                    nodeType.code === node.type
                );
                node.label = nodeType.defaultLabel;
                node.children = nodeType.leaf ? null : [];
                commit(Types.MUTATION.ADD_NODE, node);
            } else {
                //TODO use case where it can happen? if needed throw an error to catch in components or use notifications
                console.info("node", node)
            }
        },
        [Types.ACTION.CLEAN_STRUCTURE]: ({commit}) => {
            commit(Types.MUTATION.REMOVE_NEW_NODES_ID);
        },
        [Types.ACTION.DELETE_NODE]: ({commit, getters}, node) => {
            commit(Types.MUTATION.DELETE_NODE, {node, parentNode: getters[Types.GETTER.PARENT_NODE]});
        },
        [Types.ACTION.DELETE_DOCUMENT]: ({commit}, {document, parent}) => {
            commit(Types.MUTATION.DELETE_DOCUMENT, {document, parent});
        },
        [Types.ACTION.CUT_NODE]: ({commit, getters}, cutNodes) => {
            commit(Types.MUTATION.CUT_NODE, {cutNodes, parentNode: getters[Types.GETTER.PARENT_NODE]});
        },
        [Types.ACTION.PASTE_NODE]: ({commit, getters}, node) => {
            commit(Types.MUTATION.PASTE_NODE, {node, parentNode: getters[Types.GETTER.PARENT_NODE]});
        },
        [Types.ACTION.INIT]: ({state, commit, dispatch}, structure) => {
            if (state.nodeTypeList.length === 0 || state.nodePolicies.length === 0) {
                axios.get('/pao/create/init').then(({data}) => {
                    commit(Types.MUTATION.SET_NODE_TYPE_LIST, data.nodeTypeList);
                    commit(Types.MUTATION.SET_NODE_POLICIES, data.nodePolicyList);
                });
            }
            dispatch(Types.ACTION.UPDATE_STRUCTURE, structure)
        },
        [Types.ACTION.UPDATE_CLICKED_NODE]: ({state, commit}, clickedNodeId) => {
            let clickedNode = state.nodeMap.get(clickedNodeId);
            commit(Types.MUTATION.SET_CLICKED_NODE, clickedNode);
        },
        [Types.ACTION.UPDATE_CLICKED_NODE_LABEL]: ({commit}, label) => {
            commit(Types.MUTATION.SET_NODE_LABEL, label);
        },
        [Types.ACTION.UPDATE_CLICKED_NODE_CODE]: ({commit}, code) => {
            commit(Types.MUTATION.SET_NODE_CODE, code);
        },
        [Types.ACTION.UPDATE_STRUCTURE]: ({commit}, structure) => {
            commit(Types.MUTATION.SET_STRUCTURE, structure);
            commit(Types.MUTATION.SET_NODE_MAP, structure);
        },
        [Types.ACTION.UPDATE_DOCUMENT]: ({commit}, document) => {
            commit(Types.MUTATION.SET_DOCUMENT, document);
        },
        [Types.ACTION.UPDATE_CLICKED_DOCUMENT]: ({state, commit}, documentId) => {
            let found = PaoService.findDocumentById(documentId, state.structure)
            commit(Types.MUTATION.SET_CLICKED_DOCUMENT, found);
        },
        [Types.ACTION.UPDATE_SELECTED_NODE_DOCUMENT]: ({commit}, node) => {
            commit(Types.MUTATION.UPDATE_SELECTED_NODE_DOCUMENT_VALUES, node);
        },
        [Types.ACTION.UPDATE_IS_SELECTED_NODE]: ({commit}, isSelected) => {
            commit(Types.MUTATION.SET_IS_SELECTED_NODE, isSelected);
        },
        [Types.ACTION.UPDATE_VISIBILITY]: ({commit}, {node, isVisible}) => {
            commit(Types.MUTATION.SET_VISIBILITY, {node: node, isVisible: isVisible});
        }
    },
    /**
     * MUTATIONS
     */
    mutations: {
        [Types.MUTATION.ADD_NODE]: (state, node) => {
            state.clickedNode.children.splice(node.rank, 0, node);
            state.clickedNode.children.forEach((node, index) => {
                node.rank = index;
            });
            state.nodeMap.set(node.id.toString(), node);
        },
        [Types.MUTATION.REMOVE_NEW_NODES_ID]: (state) => {
            PaoService.cleanNodes(state.nodeMap);
        },
        [Types.MUTATION.DELETE_NODE]: (state, {node, parentNode}) => {
            state.nodeMap.delete(node.id);
            const nodeIndex = parentNode.children.indexOf(node);
            parentNode.children.splice(nodeIndex, 1);
            if (!!state.clickedNode.children) {
                state.clickedNode.children.forEach((node, index) => {
                    node.rank = index;
                });
            }

            state.clickedNode = null;
        },
        /**
         * Delete a document from a document List
         */
        [Types.MUTATION.DELETE_DOCUMENT]: (state, {document, parent}) => {
            const nodeIndex = parent.documentList.findIndex(child => child.id === document.id);
            parent.documentList.splice(nodeIndex, 1);
            state.clickedNode.documentList.forEach((node, index) => {
                node.rank = index;
            });
        },
        [Types.MUTATION.CUT_NODE]: (state, {cutNodes, parentNode}) => {
            if (!!cutNodes.nodeID) { // document case
                state.documentToPaste = cutNodes.parentClickedNode.documentList.find(elementToFind => elementToFind.id === cutNodes.nodeID.id);
                cutNodes.parentClickedNode.documentList = cutNodes.parentClickedNode.documentList.filter(function (value, index, arr) {
                    return value.id !== cutNodes.nodeID.id;
                });
                PaoService.organizeRank(cutNodes.parentClickedNode.documentList);
                state.nodeToPaste = null;
            } else { // node case
                if (!!parentNode) {
                    state.nodeToPaste = cutNodes.parentClickedNode;
                    state.nodeMap.delete(cutNodes.parentClickedNode.id);
                    const nodeIndex = parentNode.children.indexOf(cutNodes.parentClickedNode);
                    parentNode.children.splice(nodeIndex, 1);
                    PaoService.organizeRank(parentNode.children);
                    state.documentToPaste = null;
                }
            }
        },
        [Types.MUTATION.PASTE_NODE]: (state, {node, parentNode}) => {
            if (!!state.documentToPaste) { // document case
                if (!!node) {
                    switch (node.action) {
                        case ConstantDocumentNode.ACTION_TOP:
                            if (!!state.clickedNode.documentList && !!node.current) {
                                state.clickedNode.documentList.splice(node.current.rank, 0, state.documentToPaste);
                                break;
                            } else {
                                state.clickedNode.documentList = state.clickedNode.documentList === null ? [] : state.clickedNode.documentList;
                                state.clickedNode.documentList.push(state.documentToPaste);
                                break;
                            }
                        case ConstantDocumentNode.ACTION_BOTTOM:
                            if (!!state.clickedNode.documentList && !!node.current) {
                                state.clickedNode.documentList.splice(node.current.rank + 1, 0, state.documentToPaste);
                                break;
                            } else {
                                state.clickedNode.documentList = state.clickedNode.documentList === null ? [] : state.clickedNode.documentList;
                                state.clickedNode.documentList.push(state.documentToPaste);
                                break;
                            }
                        case ConstantDocumentNode.ACTION_INSIDE:
                            if (!!state.clickedNode.documentList) {
                                state.clickedNode.documentList.push(state.documentToPaste);
                                break;
                            } else {
                                state.clickedNode.documentList = [];
                                state.clickedNode.documentList.push(state.documentToPaste);
                                break;
                            }
                    }
                }
                state.documentToPaste.paoNode.id = state.clickedNode.id;
                PaoService.organizeRank(state.clickedNode.documentList);
                state.documentToPaste = null;
                state.nodeToPaste = null;

            } else if (!!state.nodeToPaste) { // node case
                state.nodeMap.set(state.nodeToPaste.id, state.nodeToPaste);
                if (!!node && !!parentNode) {
                    switch (node.action) {
                        case ConstantDocumentNode.ACTION_TOP:
                            if (!!parentNode && !!state.clickedNode) {
                                parentNode.children.splice(state.clickedNode.rank, 0, state.nodeToPaste);
                                break;
                            } else {
                                state.clickedNode.children.push(state.nodeToPaste);
                                break;
                            }
                        case ConstantDocumentNode.ACTION_BOTTOM:
                            if (!!parentNode && !!state.clickedNode) {
                                parentNode.children.splice(state.clickedNode.rank + 1, 0, state.nodeToPaste);
                                break;
                            } else {
                                state.clickedNode.children.push(state.nodeToPaste);
                                break;
                            }
                        case ConstantDocumentNode.ACTION_INSIDE:
                            state.clickedNode.children.push(state.nodeToPaste);
                            break;
                    }

                    PaoService.organizeRank(parentNode.children);

                    state.nodeToPaste = null;
                    state.documentToPaste = null;

                }
            }

        },
        [Types.MUTATION.SET_CLICKED_NODE]: (state, clickedNode) => {
            state.clickedNode = clickedNode;
        },
        [Types.MUTATION.SET_NODE_MAP]: (state, structure) => {
            state.nodeMap = PaoService.toHashMap(structure);
        },
        [Types.MUTATION.SET_NODE_LABEL]: (state, label) => {
            state.clickedNode.label = label;
        },
        [Types.MUTATION.SET_NODE_CODE]: (state, code) => {
            state.clickedNode.code = code;
        },
        [Types.MUTATION.SET_NODE_POLICIES]: (state, nodePolicies) => {
            state.nodePolicies = nodePolicies;
        },
        [Types.MUTATION.SET_NODE_TYPE_LIST]: (state, nodeTypeList) => {
            state.nodeTypeList = nodeTypeList;
        },
        [Types.MUTATION.SET_STRUCTURE]: (state, structure) => {
            state.structure = structure;
        },
        [Types.MUTATION.SET_DOCUMENT]: (state, document) => {
            state.document = document;
        },
        [Types.MUTATION.UPDATE_SELECTED_NODE_DOCUMENT_VALUES]: (state, node) => {
            for (const field in node) {
                state.clickedNodeDocument[field] = node[field];
            }
        },
        [Types.MUTATION.SET_IS_SELECTED_NODE]: (state, isSelected) => {
            state.clickedNodeDocument.selected = isSelected;
        },
        [Types.MUTATION.SET_VISIBILITY]: (state, {node, isVisible}) => {
            node.visible = isVisible;
        },
        [Types.MUTATION.SET_CLICKED_DOCUMENT]: (state, document) => {
            state.clickedNodeDocument = document;
        }
    }
}
