import Vue from 'vue'
import Vuex from 'vuex'
import documentContentStore         from './modules/documentContentStore'
import dataSheetStore               from './modules/dataSheetStore'
import userStore                    from './modules/userStore'
import hyperLinkStore               from './modules/hyperLinkStore'
import documentFilterStore          from './modules/documentFilterStore'
import paoFilterStore               from './modules/paoFilterStore'
import paoStructureStore            from './modules/paoStructureStore'
import languageStore                from './modules/languageStore'

Vue.use(Vuex)

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        dataSheetStore,
        hyperLinkStore,
        documentContent: documentContentStore,
        blockquote: documentContentStore,
        userStore,
        documentFilterStore,
        paoFilterStore,
        paoStructureStore,
        languageStore
    }
})
