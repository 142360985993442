<template>
    <v-layout align-space-between justify-space-around row fill-height class="background_white">
        <v-flex class="settings">
            <v-layout class="container_filter_documents" row wrap fill-height>
                <v-flex  class="status_sizer">
                    <step-and-status-display v-if="!!status && !!step" :datasheet="metaDocument"/>

                    <action-pao-link-component :dataSheet="metaDocument" :idDocument="idDocument"/>

                    <v-divider dark class="divider_doc"></v-divider>

                    <action-panel-document v-if="!!status" :idDocument="idDocument" :reference="metaDocument.reference" @onIncrementVersion="incrementDocumentVersion" @onUploadXmlFile="uploadXmlFile"/>

                    <v-divider dark class="divider_doc"></v-divider>

                    <action-validation-workflow v-if="!!status" @onConfirm="confirm" @onValidation="onValidation"/>
                </v-flex>
            </v-layout>
        </v-flex>
        <!-- TABS CONTENT -->
        <v-flex class="tabs_container" >

            <v-tabs
                slot="extension"
                v-model="tab"
                grow>

                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab>{{ $t("document.label.metadata") }}</v-tab>
                <v-tab>{{ $t("document.label.documentModificatorList") }}</v-tab>
                <v-tab>{{ $t("document.label.historic") }}</v-tab>
                <v-tab>{{ $t("document.label.links") }}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card  flat id="contains">
                        <data-sheet @changeVersionDocument="changeVersionDocument" :metaDocument="metaDocument"/>
                    </v-card>
                </v-tab-item>

                <v-tab-item>
                    <document-modificator-list :documentModificatorList="documentModificatorList" :isConsolidationMode="false" />
                </v-tab-item>

                <v-tab-item>
                    <document-history-list :documentHistoryList="documentHistoryList" />
                </v-tab-item>

                <v-tab-item>
                    <document-link-list :documentLinkList="documentLinkList" />
                </v-tab-item>
            </v-tabs-items>
        </v-flex>

        <dialog-import-files :dialog.sync="openImportFiles" :documentId="idDocument" :operationType="operationType" @onImportFile="onImportFile"/>
        <dialog-validate-step :dialog.sync="openValidation" :isValidate="isValidate" @onStepChange="onStepChange"/>
        <dialog-confirmation :dialog.sync="openConfirm" :message="message" @confirm="validateConsolidation"/>
    </v-layout>
</template>
<script>
import ActionPanelDocument          from './components/ActionPanelDocument.vue';
import ActionValidationWorkflow     from './components/ActionValidationWorkflow.vue';
import EventBus                     from '../../../utils/event-bus';
import ConstantEvent                from '../../../shared/constant/constantEvent.js'
import DocumentModificatorList      from './tabs/DocumentModificatorList';
import DataSheet                    from './tabs/DataSheet';
import axios                        from 'axios';
import { mapState, mapActions }     from 'vuex';
import DialogImportFiles            from '../structure/dialog/DialogImportFiles.vue'
import DialogConfirmation           from '../../shared/DialogConfirmation.vue';
import ConstantNotification         from '../../../shared/constant/constantNotification.js';
import DocumentLinkList             from './tabs/DocumentLinkList';
import DocumentHistoryList          from './tabs/DocumentHistoryList';
import DialogValidateStep           from './dialog/DialogValidateStep';
import ConstantUploadOperationType  from '../../../shared/constant/constantUploadOperationType';
import StepAndStatusDisplay         from './components/StepAndStatusDisplay.vue';
import ActionPaoLinkComponent from "./components/ActionPaoLinkComponent";
import ConstantDocumentType         from '../../../shared/constant/constantDocumentType.js';
/**
 * 
 * Display a document after a search.
 * 
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 26/10/2018
 */
export default {
    name:'DocumentDisplay',
    data() {
        return {
            tab: null,
            message: '',
            openImportFiles: false,
            openConfirm: false,
            openValidation: false,
            isValidate: false
        }
    },
    created(){
        if(!!this.$route.params.reference) {
            if( !!this.$route.params.version ){
                this.loadDataSheetByReferenceAndVersion({reference:this.$route.params.reference, version: Number(this.$route.params.version)});
            }else {
                this.loadDataSheetByReference(this.$route.params.reference);
            }
        }
    },
    mounted(){
        EventBus.$on(ConstantEvent.RELOAD_DOCUMENT, this.loadDataSheetByReference); 
    },
    beforeDestroy(){
        EventBus.$off(ConstantEvent.RELOAD_DOCUMENT, this.loadDataSheetByReference);
    },
    computed: {
        ...mapState({
            status: state => state.dataSheetStore.metaDocument.documentStatus,
            step: state => state.dataSheetStore.metaDocument.documentStep,
            metaDocument : state => state.dataSheetStore.metaDocument,
            documentModificatorList : state => state.dataSheetStore.documentModificatorList,
            documentLinkList : state => state.documentContent.documentLinkList,
            documentHistoryList: state => state.dataSheetStore.documentHistoryList,
            idDocument: state => state.dataSheetStore.metaDocument.idDocument
        }),
        operationType(){
            return ConstantUploadOperationType.CONTENT_IMPORT_OPERATION;
        },
        jurisprudence(){
            return ConstantDocumentType.JURISPRUDENCE;
        },
        /**
         * Return true if the pao link is available
         */
        isPaoLinkAvailable(){
            return !!this.metaDocument.documentType &&
             ( this.metaDocument.documentType.code === ConstantDocumentType.JURISPRUDENCE ||
              this.metaDocument.documentType.code === ConstantDocumentType.BIBLIOGRAPHY )
        }
    },
    methods:{
        ...mapActions({
            loadDataSheetByReference: 'dataSheetStore/loadDataSheetByReference',
            loadDataSheetById: 'dataSheetStore/loadDataSheetById',
            loadDataSheetByReferenceAndVersion: 'dataSheetStore/loadDataSheetByReferenceAndVersion',
            loadDocumentContent: 'documentContent/loadDocumentContent',
            loadDocumentModificatorList: 'dataSheetStore/loadDocumentModificatorList',
            loadDocumentLinkList: 'documentContent/loadDocumentLinkList',
            loadDocumentStepHistoryList: 'dataSheetStore/loadDocumentStepHistoryList',
        }),
        /**
         * Update the id document if the user change the version of the document
         */
        changeVersionDocument({reference, version}){
            this.loadDataSheetByReferenceAndVersion( {reference,version} );
        },
        /**
         * Go to the link page that match with the document type
         */
        goToLinkPage(){
            this.$router.push({ path: `/document/paolink/${this.idDocument}` });
        },
       /**
        * After import an XML File.
        * The method is called to re-upload the content of the document.
        *
        */
        uploadXmlFile(){
            this.openImportFiles = true;
        },
        /**
         * Reload the data about the document and
         */
        onImportFile( ){
            this.loadDocumentContent({id: this.metaDocument.currentVersion.documentId});
            this.sendNotification(
                'document.notification.import.succeed',
                ConstantNotification.SUCCEED
            );
        },
        /**
         * Create a new version of the current document
         */
        incrementDocumentVersion(){
            axios.post(`document/version/${this.metaDocument.currentVersion.documentId}`)
                .then( ({data}) => {
                    this.loadDataSheetByReference(data.reference),
                    this.sendNotification(
                        'document.notification.newVersion.success',
                        ConstantNotification.SUCCEED
                    );
            }, ( error ) => {
                this.sendNotification(
                    'document.notification.newVersion.error',
                    ConstantNotification.ERROR
                );
            });
        },
        /**
         * Call the back to reload all the data correpsonding to the document after changes
         */
        reloadDocumentData( documentId ){
            this.loadDocumentContent({id: documentId});
            this.loadDocumentModificatorList(documentId);
            this.loadDocumentLinkList(documentId);
            this.loadDocumentStepHistoryList(documentId);
        },
        /**
         * Open the dialog to confirm the validation
         * @param {Boolean} isValidate, boolean to know if the validation is positive or not
         */
        onValidation(isValidate) {
            this.openValidation = true;
            this.isValidate = isValidate;
        },
        /**
         * If step was changed successfully, reload data and display success message
         */
        onStepChange(step) {
            this.loadDataSheetByReferenceAndVersion(
                {
                    reference: this.metaDocument.reference,
                    version: this.metaDocument.currentVersion.version
                }
            );
            this.reloadDocumentData(this.metaDocument.currentVersion.documentId);         
            this.sendNotification(
                `document.notification.step.${step.rejected ? 'rejected' : 'validated'}`,
                ConstantNotification.SUCCEED
            );
        },
        /**
         * Open dialog to confirm the action
         */
        confirm(){
            this.openConfirm = true;
            this.message = 'document.consolidation.confirmation.validateConsolidation';
        },
        /**
         * Confirm that the document has been fully consolidate and put it in AVAILABLE status
         */
        validateConsolidation(){
            axios.post(
                'document/validate/consolidation',
                {
                    targetDocumentId: this.metaDocument.currentVersion.documentId
                }
            ).then( ({data}) => {
                this.sendNotification(
                    'document.notification.consolidation.validation',
                    ConstantNotification.SUCCEED
                );
                this.loadDataSheetById(this.metaDocument.currentVersion.documentId);
                this.loadDocumentModificatorList(this.metaDocument.currentVersion.documentId);
                this.$router.push({name: 'details' , params: {reference: data.reference, title: data.title}});
            });
        },
        /**
         * Display a notification based on a message and a type
         */
        sendNotification( message, type ){
            EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                message: message,
                type: type
            });
        }
    },
    watch: {
        // Be carefull of the different flows involving the reloading of a document data
        // and make sure this variable is not set if you need to reload a document that
        // is different of the last document data loaded.
        idDocument() {
            if( !!this.idDocument ) {
                this.reloadDocumentData(this.idDocument);
            } 
        },
    },
    components: {
        ActionPaoLinkComponent,
        ActionPanelDocument,
        ActionValidationWorkflow,
        DocumentModificatorList,
        DataSheet,
        DialogImportFiles,
        DocumentLinkList,
        DocumentHistoryList,
        DialogValidateStep,
        DialogConfirmation,
        StepAndStatusDisplay
    }
}
</script>
<style scoped>
#contains{
    min-height:100vh;
}
.container_filter_documents{
    height:calc(100vh + 48px);
    background-color:#355B7F;
    padding-top: 20%;
    padding-left: 20px;
    padding-right: 20px;
}
.status_sizer{
    width:300px;
    background-color:#355B7F;
    height:100%;
}
.divider_doc{
    margin-bottom:5%;
}
.tabs_container{
    width: 100%;
    max-width: 75%;
}
.background_white{
    background-color: white;
}
.settings{
    background-color:#355B7F;
    height: 100%;
}

</style>