<template>
    <v-layout row wrap>
        <v-flex xs12>    
            <v-data-table
              :headers="[]"
              :items="documentModificatorList"
              hide-actions
            >
               <template slot="headers" slot-scope="props">
                    <th>{{ $t('document.documentModificator.reference') }}</th>
                    <th>{{ $t('document.documentModificator.title') }}</th>
                    <th>{{ $t('document.documentModificator.integrated') }}</th>
                    <th v-if="!!isConsolidationMode">{{ $t('document.label.actions') }}</th>
                </template>

                <template slot="items" slot-scope="props">
                    <td>{{ props.item.reference }}</td>
                    <td>{{ props.item.title }}</td>
                    <td>{{ props.item.consolidated ? $t('document.documentModificator.integrated') : $t('document.documentModificator.nonIntegrated')}}</td>
                    <td v-if="isConsolidationMode">
                        <v-icon 
                            medium
                            @click="unlinkDocument(props.item)">
                            link_off
                        </v-icon>
                    </td>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
import { mapState, mapActions } from 'vuex';

/**
 * Display a the list of all modificator for a document.
 * 
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 26/10/2018
 */
export default {
    name:'DocumentModificatorList',
    props: ['documentModificatorList', 'isConsolidationMode'],
    methods: {
      unlinkDocument( document ) {
        this.$emit('unlinkDocument', document);
      }
    }
}
</script>