<template>
    <div v-if="onHiddenSearch" class="search-bar" >
        <v-btn class="btn-search" @click="searchOnNode" flat><v-icon>search</v-icon></v-btn>
        <div  class="searchBtn">
            <div class="containActionBar">
                <v-text-field v-model="searchWord" @keyup.enter="searchOnNode" class="search-bars text_settings"></v-text-field>
                <span class="highText"> {{nbHigh}} {{ $t('global.action.numberSearch')}}</span>
                <span class="indexOnHigh"> {{nbHigh === 0 ? 0: indexSearch+1}} / {{nbHigh}} </span>
            </div>
            <v-btn class="arrow margin_arrow" width="20"  @click="navigateOnNode(downInSearch)"><v-icon>arrow_drop_down</v-icon></v-btn>
            <v-btn class="arrow" width="20" @click="navigateOnNode(upInSearch)"><v-icon>arrow_drop_up</v-icon></v-btn>
        </div>

        <v-btn class="btn-replace" @click="replaceOnNode(replaceOne)" flat ><v-icon>find_replace</v-icon></v-btn>
        <div  class="searchBtn">
            <div class="containActionBar">
                <v-text-field width="300" v-model="replaceWord" @keyup.enter="replaceOnNode(replaceOne)" class="search-bars"></v-text-field>
            </div>
            <v-btn width="20" class="arrow margin_arrow"  @click="replaceOnNode(replaceOne)">{{ $t('global.action.oneByOne') }}</v-btn>
            <v-btn width="20" class="arrow tiny_margin_arrow"  @click="replaceOnNode(replaceAll)">{{ $t('global.action.all') }}</v-btn>
        </div>
        <v-snackbar
                v-model="snackbar"
                :bottom="y === 'bottom'"
                :left="x === 'left'"
                :multi-line="mode === 'multi-line'"
                :right="x === 'right'"
                :timeout="timeout"
                :top="y === 'top'"
                :vertical="mode === 'vertical'"
                color="primary">
            {{ textSnackBar }}
            <v-btn
                    color="white"
                    flat
                    @click="snackbar = false">
                {{ $t('global.action.close') }}
            </v-btn>
        </v-snackbar>
    </div>
</template>
<script>
import ConstantAction                       from '../../../../shared/constant/constantAction.js';
import ConstantDocumentNode                 from '../../../../shared/constant/constantDocumentNode.js';
import ConstantEvent                        from '../../../../shared/constant/constantEvent.js';
import ConstantStoreName                    from '../../../../shared/constant/constantStoreName.js';
import DomManipulatorService                from '../../../../service/html/domManipulatorService';
import { mapActions }                       from 'vuex';
import * as $                               from 'jquery';
import EventBus                             from '../../../../utils/event-bus';

const UPDATE_HIDDEN_SEARCH                  = 'update:hiddenSearch';
const HIGHLIGHT_CLASS                       = 'highlight';
const UPDATE_WORD_TO_SEARCH                 = 'update:word';
/**
 * Display the search and replace component
 *
 * @author Justin WILMET
 * @version 1.0
 * @since 2019-06-18
 */
export default {
    name: 'SearchOnNodeComponent',
    props: {
        hiddenSearch: {
            default: false
        }
    },
    data: () => ({
        replaceWord: '',
        searchWord: null,
        snackbar: false,
        y: 'top',
        x: null,
        mode: '',
        timeout: 6000,
        indexSearch: 0,
        nbHigh: 0,
        textSnackBar: ''
    }),
    computed: {
        onHiddenSearch: {
            get(){
                if( !this.hiddenSearch ){
                    this.cleanDocument();
                }
                return this.hiddenSearch;
            },
            set( hiddenSearch ){
                if( !hiddenSearch ){
                    this.$emit(UPDATE_HIDDEN_SEARCH, false);
                }
            }
        },
        replaceOne(){
            return ConstantAction.REPLACE_ONE;
        },
        replaceAll(){
            return ConstantAction.REPLACE_ALL;
        },
        searchAll(){
            return ConstantAction.SEARCH_ALL;
        },
        downInSearch(){
            return ConstantAction.DOWN_IN_SEARCH;
        },
        upInSearch(){
            return ConstantAction.UP_IN_SEARCH;
        }
    },
    methods: {
        /**
         * Actions from the store
         */
        ...mapActions({
            modifyContent: 'documentContent/modifyContent'
        }),
        /**
         * Action related to a search, will search all the occurences of the word to search
         */
        searchOnNode(){
            let childDiv = document.getElementsByName(HIGHLIGHT_CLASS);
            this.indexSearch = 0;
            this.nbHigh = DomManipulatorService.searchInNodes(this.searchWord,this.indexSearch,$("div.bol-text-node"));
            this.textSnackBar =  this.nbHigh +  this.$t('global.action.numberSearch')  ;
            this.snackbar = true;
            this.scrollToSelectedNodeById(DomManipulatorService.searchUpInNodes(childDiv, this.indexSearch));
        },
        /**
         * Action related to a replacement, will navigate up or down depending on the action trigger
         * @param {String} action, indicator to navigate up or down
         */
        navigateOnNode( action ){
            let childDiv = document.getElementsByName(HIGHLIGHT_CLASS);
            if(action === this.upInSearch){
                this.$updateIndexUpInDocument( childDiv );
                this.scrollToSelectedNodeById( DomManipulatorService.searchUpInNodes( childDiv, this.indexSearch ) );
            }else if(action === this.downInSearch){
                this.$updateIndexDownInDocument( childDiv );
                this.scrollToSelectedNodeById( DomManipulatorService.searchDownInNodes( childDiv, this.indexSearch ) );
            }
        },
        /**
         * Action related to a replacement, depending on the action selected will replace one by one every items or 
         * all items in one shoot
         * @param {String} action, indicator to replace one by one or all 
         */
        replaceOnNode( action ){
            if( !!this.searchWord ){
                if(action === this.replaceOne){
                    DomManipulatorService.searchInNodes(this.searchWord,this.indexSearch,$("div.bol-text-node"));
                    let impactedNode = DomManipulatorService.replaceInNodes(this.searchWord,this.replaceWord,this.indexSearch);
                    if(!!impactedNode){
                        impactedNode.replacedText = impactedNode.replacedText.replace(new RegExp('<span class="highlight-grey" name="highlight">', "gm"), '', "gm").replace(new RegExp('<span class="highlight-yellow" name="highlight">', "gm"), '', "gm").replace(new RegExp('</span>', "gm"), '', "gm");
                        let htmlContent = DomManipulatorService.insertContentInHtmlElement( impactedNode.parentNode, impactedNode.replacedText, ConstantDocumentNode.TEXT_NODE_CLASS);
                        this.modifyContent({ content: htmlContent, id: impactedNode.nodeId });

                        if( this.nbHigh > 0 ){
                            this.nbHigh--;
                            let childDiv = document.getElementsByName(HIGHLIGHT_CLASS);
                            if( this.indexSearch >= childDiv.length) {
                                this.indexSearch = 0;
                            }
                            this.scrollToSelectedNodeById(DomManipulatorService.searchDownInNodes(childDiv, this.indexSearch));
                        }
                    }
                
                }else if(action === this.replaceAll){
                    this.indexSearch = 0;
                    DomManipulatorService.searchInNodes(this.searchWord,this.indexSearch,$("div.bol-text-node"));
                    let impactedNodes = DomManipulatorService.replaceAllInNodes(this.searchWord,this.replaceWord);

                    for (let node of impactedNodes) {
                        if(node) {
                            node.replacedText = node.replacedText.replace(new RegExp('<span class="highlight-grey" name="highlight">', "gm"), '', "gm").replace(new RegExp('<span class="highlight-yellow" name="highlight">', "gm"), '', "gm").replace(new RegExp('</span>', "gm"), '', "gm");
                            let htmlContent = DomManipulatorService.insertContentInHtmlElement( node.parentNode ,node.replacedText, ConstantDocumentNode.TEXT_NODE_CLASS);
                            this.modifyContent({ content: htmlContent, id: node.nodeId });
                        }
                    }
                    this.nbHigh = 0;
                }
            }
        },
        /**
         * Recalculated the index by decreasing his value to search through top in the document
         * @param {HTMLCollection} childDiv, all div (bol-text-node) containing the word we looking for
         */
        $updateIndexUpInDocument( childDiv ){
            if(this.indexSearch > 0) {
                this.indexSearch--;
            } else {
                this.indexSearch = childDiv.length - 1;
            }
        },
        /**
         * Recalculated the index by increasing his value to search through bottom in the document
         * @param {HTMLCollection} childDiv, all div (bol-text-node) containing the word we looking for
         */
        $updateIndexDownInDocument( childDiv ){
            if(this.indexSearch < childDiv.length - 1 ) {
                this.indexSearch++;
            } else {
                this.indexSearch = 0;
            }
        },
        /**
         * Spot all the highlight in the document and clean them
         */
        cleanDocument(){
            this.indexSearch = 0;
            this.nbHigh = 0;
            this.textSnackBar = '';
            this.snackbar = false;
            this.replaceWord = '';
            this.searchWord = null;
            let listHigh = $(`span.${HIGHLIGHT_CLASS}`);
            for (let bolNode of listHigh) {
                bolNode.outerHTML = bolNode.innerHTML;
            }
        },
        /**
         * Scroll to the selected node
         * @param selectedNode
         */
        scrollToSelectedNodeById(selectedNodeById) {
            let container = $(`#container-${ConstantStoreName.DOCUMENT_CONTENT}`);
            let anchorReferenceElement = $( `#${ConstantDocumentNode.NODE_CONTENT_CLASS}-${selectedNodeById}` );
            if( !!anchorReferenceElement && !!anchorReferenceElement.offset()){
                container.animate({
                    scrollTop: anchorReferenceElement.offset().top - container.offset().top + container.scrollTop()
                });
            }
        }
    }
}
</script>
<style scoped>
.search-bar{
    background-color: whitesmoke;
    padding-top:10px;
    padding-left:20px;
    margin-left:30px;
    display: flex;
}
.btn-search{
    height:44px;
    display:inline-block;
    background: whitesmoke;
    margin:0;
    margin-bottom: 29px;
}
.searchBtn{
    display:flex;
    flex-direction: row;
}
.containActionBar{
    margin:0;
    height:50px;
    width:300px;
    margin-top:-4px;
    position:absolute
}
.search-bars{
    margin-left:-3px;
    height:45px;
    display:inline-block;
    background: whitesmoke;
}
.text_settings{
    height:45px;
    width: 170px;
}
.highText{
    position:absolute;
    left:0px;
    top:50px;
    width:300px;
    color:black;
}
.arrow{
    height:44px;
    display:inline-block;
    box-shadow: none !important;
    background: whitesmoke;
    margin:0;
    margin-bottom: 30px;
}
.margin_arrow{
    margin-left:180px;
}
.tiny_margin_arrow{
    margin-left:-3px;
}
.btn-replace{
    height:45px;
    display:inline-block;
    background: whitesmoke;
    margin:0;
    margin-bottom: 29px;
    margin-left:10px;
}
.indexOnHigh{
    position: absolute;
    top: 50px;
}
</style>

