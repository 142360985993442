<template>
    <v-layout
        justify-space-around
        wrap
    >
        <bol-large-button
            icon="school"
            :label="this.$t('navigation.administration.jurisdiction')"
            route="/administration/documentjurisdiction"
        />
        <bol-large-button
            icon="vpn_key"
            :label="this.$t('navigation.administration.keywords')"
            route="/administration/keyword"
        />
        <bol-large-button
            icon="storefront"
            :label="this.$t('navigation.administration.workspace')"
            route="/administration/workspace"
        />
        <bol-large-button
            icon="public"
            :label="this.$t('navigation.administration.country')"
            route="/administration/country"
        />
        <bol-large-button
            icon="sports_kabaddi"
            :label="this.$t('navigation.administration.partner')"
            route="/administration/partner"
        />
        <bol-large-button
            icon="nature"
            :label="this.$t('navigation.administration.nature')"
            route="/administration/nature"
        />
        <bol-large-button
            icon="domain"
            :label="this.$t('navigation.administration.domain')"
            route="/administration/domain"
        />
        <bol-large-button
            icon="people"
            :label="this.$t('navigation.administration.users')"
            route="/administration/users"
        />
        <bol-large-button
            icon="assignment_turned_in"
            :label="this.$t('navigation.administration.guidelineSource')"
            route="/administration/guidelinesource"
        />
        <bol-large-button
            icon="face"
            :label="this.$t('navigation.administration.author')"
            route="/administration/author"
        />
    </v-layout>
</template>
<script>
import BolLargeButton from '../../shared/BolLargeButton'

export default {
    name: 'BolDocumentAdministration',
    components: {
        BolLargeButton
    }

}
</script>
