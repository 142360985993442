<template>
    <v-card elevation="1" width="100%" class="pa-2">
        <div class="pao-node-container" @click="clickOnNode" @contextmenu="openMenu" >
            <pao-node :node="structure"/>
        </div>
        <pao-structure-menu 
                :openMenu.sync="showMenu"
                :xPosition="xPosContextMenu"
                :yPosition="yPosContextMenu"
                :paoRootNodeId="structure.id"
        />
    </v-card>
</template>
<script>
import PaoNode                              from './PaoNode';
import PaoStructureMenu                     from './menu/PaoStructureMenu';
import PaoStructureStoreTypes               from '../../../shared/constant/store/module/paoStructureTypes';
import StoreModule                          from '../../../shared/constant/store/module/namespace';
import ConstantEvent                        from '../../../shared/constant/constantEvent';
import EventBus                             from '../../../utils/event-bus';
import { mapState, mapActions }             from 'vuex';

/**
 * Pao structure component.
 * 
 * @author Cédric de BOISVILLIERS, SFEIR benelux
 * @version 1.0
 * @since 09/07/2019
 */
export default {
    name: "PaoStructure",
    components: {
        PaoNode,
        PaoStructureMenu,
    },
    props: {
        structure: {
            required: true
        }
    },
    data(){
        return {
            showMenu: false,
            xPosContextMenu: 0,
            yPosContextMenu: 0,
        }
    },
    computed:{
        ...mapState(StoreModule.PAO_STRUCTURE,{
            clickedNode: state => state.clickedNode,
        }),
    },
    methods:{
            ...mapActions(StoreModule.PAO_STRUCTURE, {
                updateClickedNode: PaoStructureStoreTypes.ACTION.UPDATE_CLICKED_NODE,
                updateClickedDocument: PaoStructureStoreTypes.ACTION.UPDATE_CLICKED_DOCUMENT,
            }),
        /**
         * @param {Event} event
         */
        openMenu(event) {
            event.preventDefault();
            this.xPosContextMenu = event.clientX;
            this.yPosContextMenu = event.clientY;
            this.clickOnNode(event);
            this.showMenu = true;
        },

        clickOnNode(event) {
            const eventTargetList = event.composedPath();

            let targetIndex = 0;
            let eventTarget = eventTargetList[targetIndex];

            while( targetIndex < eventTargetList.length && (!eventTarget.className || (!eventTarget.className.includes('pao-node')))) {
                targetIndex++;
                eventTarget = eventTargetList[targetIndex];
            }
            let clickedDocument = eventTargetList.find(element => {
                return !!element.className && element.className.includes('bol_document_pao');
            })
            if(!!clickedDocument){
                this.updateClickedDocument(clickedDocument.id);
            } else {
                this.updateClickedDocument(null)
            }
            if( !!eventTarget.className  && eventTarget.className.includes('pao-node') ) {
                this.updateClickedNode(eventTarget.id);
            } else {
                this.updateClickedNode(null);
            }
        },
    }
}
</script>
<style scoped>
.bol-structure{
    min-width: 100%;
}
    .pao-node-container{
        padding:30px;
    }
</style>