import BulletStyleFactory           from '../../shared/factory/bulletStyleFactory.js';
import ConstantBulletStyle          from '../../shared/constant/constantBulletStyle.js';
import ConstantDocumentNode         from '../../shared/constant/constantDocumentNode.js';
import ConstantEvent                from '../../shared/constant/constantEvent.js';
import ConstantNode                 from '../../shared/constant/constantNode.js';
import ConstantNodeContentElement   from '../../shared/constant/constantNodeContentElement.js';
import ConstantTag                  from '../../shared/constant/constantTag.js';
import ConstantClassName            from '../../shared/constant/constantClassName.js';
import DomManipulatorService        from '../html/domManipulatorService.js';
import TableHtmlService             from '../html/tableHtmlService.js';
import EventBus                     from '../../utils/event-bus.js';

class CustomListService {

    constructor(){
        this.regexpTABLE = /<table\s?.*>.*<\/table>/gm;
        this.regexpOneTR = /<tr\s?.*>.*<\/tr>/gm;
        this.regexpTD = /^(<td\s?.*>.*<\/td>)/gm;
        this.regexpFootnote = /<tfoot\s?.*<\/tfoot>/gm;
    }

    /****************************************** CREATE ****************************************************/ 
    /**
     * Return a table HTML element composed with 1 row and 2 columns ( 1 bullet, 1 field containing text )
     * The first row will be calculated by the style selected by the user
     * @param {Object} bulletStyle, style of the bullet
     * @param {Number} rowNumber, number of row for the custom list
     * @param {Object} separatorStyle separator style chosen by the user
     */
    createCustomList( bulletStyle, rowNumber, separatorStyle ){
        let table = this.$createTable();
        table.firstChild.classList.add(bulletStyle.class);
        table.firstChild.classList.add(separatorStyle);

        if( bulletStyle.type === ConstantNode.ORDERED ){
            let startIndex = 0;
            let styleToApply = this.$generatBulletStyle(bulletStyle, rowNumber, separatorStyle, startIndex);
            for(let index = 0; index < rowNumber; index++){
                let row = this.$createRow();
                row.firstChild.innerHTML = styleToApply[index];
                table.firstChild.appendChild(row);
            }
        } else if( bulletStyle.type === ConstantTag.IMG){
            for(let index = 0; index < rowNumber; index++){
                let row = this.$createRow();
                let img = this.$createBulletImage(bulletStyle.style)
                row.firstChild.innerHTML = img.outerHTML;
                table.firstChild.appendChild(row);
            }
        } else if( bulletStyle.type === ConstantNode.UNORDERED) {
            for(let index = 0; index < rowNumber; index++){
                let row = this.$createRow();
                row.firstChild.innerHTML = bulletStyle.style;
                table.firstChild.appendChild(row);
            }
        }

        return table;
    }
    $createCustomListOnText( textMatcher, regex, text ){
        let table = this.$createTable();
        let classOfBullets = this.$determineBulletClasses(textMatcher[1]);
        classOfBullets.forEach(classOfBullet => {
            if(!!classOfBullet){
                table.firstChild.classList.add(classOfBullet)
            }
        });
        while ( !!textMatcher && textMatcher[0] !== "") {
            let tr = this.$createRow();
            tr.firstChild.textContent = !!textMatcher[1] ? textMatcher[1] : '';
            tr.lastChild.textContent = !!textMatcher[2] ? textMatcher[2] : textMatcher[3];
            table.firstChild.appendChild(tr);
            textMatcher = regex.exec(text);
        }
        return table;
    }
    /**
     * Generic method to create a table with only tbody for a custom list
     */
    $createTable(){
        let table = document.createElement(ConstantTag.TABLE);
        table.setAttribute(ConstantBulletStyle.ELEMENT_CLASS, ConstantNodeContentElement.CUSTOM_LIST_CLASS);
        table.id = this.$generateUidCustomList();

        let tbody = document.createElement(ConstantTag.TBODY);
        tbody.setAttribute(ConstantBulletStyle.ELEMENT_CLASS, ConstantBulletStyle.BODY_CLASS);

        table.appendChild(tbody);
        return table;
    }
    /**
     * Generic method to create a row for a custom list
     */
    $createRow(){
        let tr = document.createElement(ConstantTag.TR);
        tr.setAttribute(ConstantBulletStyle.ELEMENT_CLASS, ConstantBulletStyle.ROW_CLASS);

        let tdBullets = document.createElement(ConstantTag.TD);
        tdBullets.setAttribute(ConstantBulletStyle.ELEMENT_CLASS, ConstantBulletStyle.CELL_CLASS);
        let tdContent = document.createElement(ConstantTag.TD);
        tdContent.setAttribute(ConstantBulletStyle.ELEMENT_CLASS, ConstantBulletStyle.CELL_CLASS);

        tr.appendChild(tdBullets);
        tr.appendChild(tdContent);

        return tr;
    }
    /**
     * Create an img element with a given source and a preset size 
     * @param {*} source, acces url to the image
     */
    $createBulletImage( source ){
        let img = document.createElement(ConstantTag.IMG);
        img.src = source;
        img.width = 10;
        img.height = 10;
        return img;
    }
    /**
     * Depending of the level on the type of the current list set the class for the custom list 
     * And create the bulletStyle object to generate all the bullets
     * @param {Object} levelTypeCurrentList, Object who contains the level of the list in the structural node, and its type (OL/UL) 
     * @param {Node} table, custom list previously created 
     */
    $generateBulletStyleByLevel( levelTypeCurrentList, table){
        let bulletStyle = {};
        let tableBody = table.firstChild;
        let classOfTheBullet = '';

        if( levelTypeCurrentList.type === ConstantTag.UL){
            if( levelTypeCurrentList.level === 0 ){
                classOfTheBullet = ConstantBulletStyle.FILLED_DOT_STYLE_CLASS;
            } else if( levelTypeCurrentList.level === 1 ){
                classOfTheBullet = ConstantBulletStyle.OUTLINED_DOT_STYLE_CLASS;
            } else if( levelTypeCurrentList.level >= 2 ) {
                classOfTheBullet = ConstantBulletStyle.FILLED_SQUARE_STYLE_CLASS;
            }
        } else {
            classOfTheBullet = ConstantBulletStyle.NUMBER_STYLE_CLASS;
        }

        bulletStyle = BulletStyleFactory.create( classOfTheBullet );
        tableBody.classList.add( classOfTheBullet );

        this.$addBulletInTable(tableBody, bulletStyle);
                
        return table;
    }
    /**
     * Will generate the complete list of bullet style to apply to the list custom
     * @param {Object} bulletStyle, style of the bullet
     * @param {Number} rowNumber, number of row for the custom list
     * @param {String} separatorStyle separator style
     * @param {Number} startIndex, number of start index
     */
    $generatBulletStyle ( bulletStyle, rowNumber, separatorStyle, startIndex ){
        let styleToApply = [];
        for( let index = startIndex; index < startIndex + rowNumber; index++){
            let bullet = this.$generateBullet(bulletStyle, index);
            let bulletStylized;
            if( separatorStyle === ConstantBulletStyle.DOT_SEPARATOR ){
                bulletStylized = bullet + '.';
            } else if( separatorStyle === ConstantBulletStyle.ONE_BRACKET_SEPARATOR ){
                bulletStylized = bullet + ')';
            } else if( separatorStyle === ConstantBulletStyle.DOUBLE_BRACKET_SEPARATOR ){
                bulletStylized = '(' + bullet + ')';
            } else if( separatorStyle === ConstantBulletStyle.DEGREE_SEPARATOR ){
                bulletStylized = bullet + '°';
            } else {
                bulletStylized = bullet;
            }

            styleToApply.push(bulletStylized);
        }
        return styleToApply;
    }
    /**
     * Generate the associate bullet regarding to the slected bullet style and the rank
     * @param {Object} bulletStyle
     * @param {Number} rank
     */
    $generateBullet(bulletStyle, rank){
        let bullet = '';
        if( bulletStyle.style === ConstantBulletStyle.DOT_NUMBER_STYLE ){
            bullet = rank + 1;
        } else if( bulletStyle.style === ConstantBulletStyle.ALPHA_STYLE ){
            bullet = this.$alphabetize( rank, bulletStyle.isUpperCase );
        } else if( bulletStyle.style === ConstantBulletStyle.ROMAN_STYLE ){
            bullet = this.$romanize(rank+1, bulletStyle.isUpperCase );
        }
        return bullet;
    }
    /**
     * Generate a uid use for a custom list
     * make things easier to indentify which custom-list is focus on the node
     */
    $generateUidCustomList( ) {
        return 'xxxxxxxx-xxxx-9xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
            function(c) {
                let r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
                return v.toString(16);
            });
    }


    /****************************************** CHECK AND EXTRACT ****************************************************/ 
    /**
     * Based on the selected text, it will isolate the bullets if they exist with the regex
     * @param {String} text, text selected by the user
     */
    checkAndCreateCustomListOnText( text ){
        // 1. | 1) | a. | a) | 1° | a° | - | bullet | * | >
        let regex = /^([0-9a-zA-Z]+?\.\s?|[0-9a-zA-Z]+?\)\s?|[0-9a-zA-Z]+?°\s?|\([0-9a-zA-Z]+?\)\s?|[\u002A\u002D\u003E\u2022\u2013\u25E6]\s?)(.*\s*)|(^.+)/gm;
        let match = regex.exec(text);
        let table;
        if(!! match){
            table = this.$createCustomListOnText( match, regex, text);
        }
        return table;
    }
    /**
     * Based on a selected html list, will extract its content and detect if other html list exists in deepest level
     * In parallel will place this content in a custom list and reconstruct their bullets
     * @param {Node} domNode, the selected list and all its children
     */
    extractListContentAndCreateCustomList( domNode ){
        let table = this.$createTable();
        let levelTypeCurrentList = this.$extractLevelAndTypeOfTheCurrentList( domNode );
        /**
         * Loop all over the row of the html list and create the corresponding row of the custom list
         */
        for(let indexList = 0; indexList < domNode.childNodes.length; indexList++){
            let tr =  this.$createRow();
            let childNodesCollection = domNode.childNodes[indexList].childNodes;
            /**
             * if the row contains at least one other list we need to loop over it too
             * otherwhise it's simply some text to insert in the cell
             */
            if(this.$containsUlOl(childNodesCollection)){
                for (let indexChildNode = 0; indexChildNode < childNodesCollection.length; indexChildNode++ ){
                    /**
                     * Children are treated according to their type, if they are node element and not an html list
                     * they are simply added the row.
                     * If it's a html list we do a recursion to manage the list like above
                     */
                    if( childNodesCollection[indexChildNode].nodeType === Node.ELEMENT_NODE){
                        if( this.$isHTMLList( childNodesCollection[indexChildNode] ) ){
                            let innerTable = this.extractListContentAndCreateCustomList( childNodesCollection[indexChildNode] ); 
                            tr.lastChild.appendChild(innerTable);
                        }else {
                            tr.lastChild.innerHTML += childNodesCollection[indexChildNode].outerHTML;
                        }
                    /**
                     * If the child is text, it's added as textContent
                     */
                    } else if ( childNodesCollection[indexChildNode].nodeType === Node.TEXT_NODE){
                        tr.lastChild.textContent = childNodesCollection[indexChildNode].textContent;
                    }
                }               
            }else {
                tr.lastChild.innerHTML = domNode.childNodes[indexList].innerHTML;
            }
            table.firstChild.appendChild(tr);
        }
        /**
         * Once the bullet style and the level are detected, and the table is created with his content 
         * We add all the bullets to the custom list
         */
        return this.$generateBulletStyleByLevel( levelTypeCurrentList, table);
    }
    /**
     * Extract the level of the current list in his structural node with his type (OL/UL)
     * @param {Node} nodeCustomList, node that contains the HTML list
     */
    $extractLevelAndTypeOfTheCurrentList( nodeCustomList ){
        let type = nodeCustomList.tagName;
        let level = 0;
        
        while( !nodeCustomList.className.includes(ConstantDocumentNode.TEXT_NODE_CLASS ) ){
            if(nodeCustomList.parentNode.tagName === ConstantTag.OL || nodeCustomList.parentNode.tagName === ConstantTag.UL){
                level += 1;
            }
            nodeCustomList = nodeCustomList.parentElement;
        }

        return { type, level };
    }
    /**
     * Extract the bullet style of the given classList 
     * @param {Element node} classList 
     */
    $extractBulletStyle( classList ){
        return BulletStyleFactory.create(classList[1]);
    }
    /**
     * Extract the bullet style of the given classList
     * @param {Element node} classList
     */
    $extractSeparatorStyle( classList ){
        return classList[2];
    }
    /**
     * Extract the indice  class from a text.
     * @param {String} text
     * @param {String} separatorClass
     */
    $extractIndiceClass(text, separatorClass){
        let extractedIndice;
        if(separatorClass === ConstantBulletStyle.DEGREE_SEPARATOR){
            extractedIndice = text.substring(0, text.indexOf('°'));
        } else if (separatorClass === ConstantBulletStyle.DOT_SEPARATOR){
            extractedIndice = text.substring(0, text.indexOf("."));
        } else if (separatorClass === ConstantBulletStyle.ONE_BRACKET_SEPARATOR){
            extractedIndice = text.substring(0, text.indexOf(")"));
        } else if (separatorClass === ConstantBulletStyle.DOUBLE_BRACKET_SEPARATOR){
            extractedIndice = text.substring(text.indexOf("(") + 1, text.indexOf(")"))
        } else {
            extractedIndice = text;
        }
        extractedIndice = extractedIndice.trim();

        if (extractedIndice.match(/^[mdclxvi]+$/g)){
            return ConstantBulletStyle.LOWER_ROMAN_STYLE_CLASS;
        } else if (extractedIndice.match(/^[MDCLXVI]+$/g)){
            return ConstantBulletStyle.UPPER_ROMAN_STYLE_CLASS;
        } else if(extractedIndice.match(/^[a-z]+$/g)){
            return ConstantBulletStyle.LOWER_ALPHA_STYLE_CLASS;
        } else if (extractedIndice.match(/^[A-Z]+$/g)){
            return ConstantBulletStyle.UPPER_ALPHA_STYLE_CLASS;
        } else if (extractedIndice.match(/^[0-9]+$/g)){
            return ConstantBulletStyle.NUMBER_STYLE_CLASS;
        } else if (extractedIndice.match(/^\u002D+$/g)){
            return ConstantBulletStyle.DASH_STYLE_CLASS;
        } else if (extractedIndice.match(/^\u002A+$/g)){
            return ConstantBulletStyle.STAR_STYLE_CLASS;
        } else if (extractedIndice.match(/^\u2022+$/g)){
            return ConstantBulletStyle.OUTLINED_DOT_STYLE_CLASS;
        } else {
            return "";
        }
    }
    /**
     * Extract the separator class from a text
     * @param {String} text
     */
    $extractSeparatorClass(text){
        let REGEX_DOTTED_SEPARATOR             = new RegExp(/\s?[0-9a-zA-Z]{1,2}\.\s?/g);
        let REGEX_BRACKET_SEPARATOR            = new RegExp(/\s?[0-9a-zA-Z]{1,2}\)\s?/g);
        let REGEX_DEGREE_SEPARATOR             = new RegExp(/\s?[0-9a-zA-Z]{1,2}°\s?/g);
        let REGEX_DOUBLE_BRACKET_SEPARATOR     = new RegExp(/\s?\([0-9a-zA-Z]{1,2}\)\s?/g);

        if(REGEX_DOTTED_SEPARATOR.test(text))  {
            return ConstantBulletStyle.DOT_SEPARATOR;
        } else if (REGEX_DOUBLE_BRACKET_SEPARATOR.test(text)) {
            return ConstantBulletStyle.DOUBLE_BRACKET_SEPARATOR;
        } else if(REGEX_DEGREE_SEPARATOR.test(text)) {
            return ConstantBulletStyle.DEGREE_SEPARATOR;
        } else if(REGEX_BRACKET_SEPARATOR.test(text)) {
            return ConstantBulletStyle.ONE_BRACKET_SEPARATOR;
        } else {
            return ConstantBulletStyle.BLANK_SEPARATOR;
        }
    }
    /**
     * Return true when the htmlCollections contain UL or OL
     * @param {*} nodeList, element html selected by the user 
     */
    $containsUlOl( nodeList ) {
        let containsUlOl = false;
        nodeList.forEach( e  => {
            if(e.tagName === ConstantTag.UL || e.tagName === ConstantTag.OL) {containsUlOl = true;}
        });
        return containsUlOl;
    }
    /**
     * Return true if the Element node is a HTML list 
     * @param {Node.ELEMENT_NODE} node, element html
     */
    $isHTMLList( node ) {
        return node.tagName === ConstantTag.UL || node.tagName === ConstantTag.OL;
    }


    /****************************************** UPDATE ****************************************************/ 
    /**
     * Update the custom list by adding a new row depending on the selected position
     * @param {Node.ELEMENT_NODE} node, selected element node by the click
     * @param {String} position, offset over the selected element node
     */
    updateCustomList({ node, position }, linesToInsert ){
        // Loop over the parent of the node to be placed on the one that is a TR 
        let trElement = this.$findFirstRowElement(node);
        let tbodyElement = trElement.parentNode;
        let index = this.$getIndexRow(trElement, position);

        // Regroup the previous and the next row of the new one, in two distinct array
        let domNodePreviousSiblingsList = Array.from(tbodyElement.childNodes).slice(0, index);
        let domNodeNextSiblingsList = Array.from(tbodyElement.childNodes).slice(index);
        
        let newRows;
        if( !linesToInsert ){
            newRows = this.$createRow();
        } else {
            newRows = linesToInsert;
        }
        return this.$gatherNewRowToCustomList(newRows, domNodePreviousSiblingsList, domNodeNextSiblingsList, tbodyElement);
    }
    /**
     * This methods will update the CSS classes of the custom list with the values in arguments.
     * @param {*} structure with the current node and the position inside the DOM 
     * @param {*} bulletClass CSS class of the bullet(indice) of the list
     * @param {*} separatorClass CSS class of the separator of the list
     */
    updateCustomListClasses({node, position}, bulletClass, separatorClass ){
        let customListTable = this.$findFirstElement(node, ConstantClassName.CUSTOM_LIST_CLASS, ConstantTag.TABLE.toUpperCase());
        [...customListTable.children].forEach(child => {
            if(child.nodeName === ConstantTag.TBODY.toUpperCase()){
                child.className = `${ConstantClassName.CUSTOM_LIST_CLASS} ${bulletClass} ${separatorClass}`;
            }
        })
        return customListTable;
    }
    /**
     * Insert in an existing custom list at the choosen position the given row(s)
     * @param {Node} domNode, the current customList
     * @param {String} textFromClipboard, copied text
     * @param {String} position, the index where the line(s) should be inserted
     * @param {Node} node, found node at the caret's position. 
     */
    insertLinesInExistingCustomList( domNode, textFromClipboard, position, node ){
        let linesToInsert = this.$convertTextToCustomList(textFromClipboard);
        let sameCols = TableHtmlService.checkNumberOfColumns(linesToInsert, domNode);
        if ( !sameCols ){
            EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                message: 'document.notification.table.wrongNumberOfColumns',
                type: 'INFO'
            });
            return null;
        } else {
            return this.updateCustomList({node: node, position: position}, linesToInsert.firstChild.children);
        }  
    }
    /**
     * Create a new custom list with separator and bullet's style 
     * and update it by placing all the content of the old one inside
     */
    $updateNewCustomList( bulletStyle, rowNumber, separatorStyle, rowList){
        let updatedCustomList = this.createCustomList( bulletStyle, rowNumber, separatorStyle);
        let bodyOfCustomList = updatedCustomList.firstChild;
        for( let index = 0; index < rowNumber; index++){
            bodyOfCustomList.children[index].lastChild.innerHTML = rowList[index].lastChild.innerHTML;
        }
        return updatedCustomList;
    }
    /**
     * Depending on the class apply to the custom list
     * will loop over the customl list and add the corresponding style for the bullet
     * @param {Table Element} tableBody, the tbody of the custom list 
     * @param {Object} bulletStyle, Object who contains all the information about the style to apply
     */
    $addBulletInTable( tableBody , bulletStyle){
        if( bulletStyle.class === ConstantBulletStyle.NUMBER_STYLE_CLASS){
            let startIndex = 0;
            let styleToApply = this.$generatBulletStyle(bulletStyle, tableBody.children.length, ConstantBulletStyle.DOT_SEPARATOR, startIndex);
            for(let index = 0; index < tableBody.children.length; index++){
                tableBody.children[index].firstChild.innerHTML = styleToApply[index];
            }
        } else {
            for( let index = 0; index < tableBody.children.length; index++){
                let firstCellRow = tableBody.children[index].firstChild;
                let img = this.$createBulletImage(bulletStyle.style)
                firstCellRow.innerHTML = img.outerHTML;
            }
        }  
    }
    /**
     * Based on a number will create the corresponding Roman number
     * @param {*} number, number to transform as his roman equivalent
     */
    $romanize (number, isUpperCase) {
        let digits = String(+number).split("");
        let key = [];
        if( isUpperCase ){
            key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM","","X","XX","XXX","XL","L","LX","LXX","LXXX","XC","","I","II","III","IV","V","VI","VII","VIII","IX"];
        } else {
            key = ["","c","cc","ccc","cd","d","dc","dcc","dccc","cm","","x","xx","xxx","xl","l","lx","lxx","lxxx","xc","","i","ii","iii","iv","v","vi","vii","viii","ix"];
        }
        let roman = "";
        let repetition = 3;
        while (repetition--){
            roman = (key[+digits.pop() + (repetition * 10)] || "") + roman;
        }
        return Array(+digits.join("") + 1).join("M") + roman;
    }
    /**
     * Depending on if the alphabet need to be Upper case or Lower case, will calculate the good Ascii code from 'a' | 'A'
     * And will loop over the number until it's not over 0 anymore to concat each letter 
     * @param {Number} number, number to transform on alpha representation
     * @param {Boolean} isUpperCase, boolean to know if the alpha need to be upper or lower representation
     */
    $alphabetize( number, isUpperCase ) {
        let charCodeA = isUpperCase ? 'A'.charCodeAt(0) : 'a'.charCodeAt(0);
        let alphaNumber = "";
        while(number >= 0) {
            alphaNumber = String.fromCharCode(number % 26 + charCodeA) + alphaNumber;
            number = Math.floor(number / 26) - 1;
        }
        return alphaNumber;
    }


    /****************************************** GET ****************************************************/ 
    /**
     * Convert the text copied from clipboard into a table element
     * @param {String} text, text copied from clipboard
     */
    $convertTextToCustomList(text){
        if ( !!text.match(this.regexpTD) ){
            text = "<table><tr>"+ text +"</tr></table>"
        } else if ( !!text.match(this.regexpOneTR) && !text.match( this.regexpTABLE ) ){
            text = "<table>"+ text + "</table>"
        } else if ( !!text.match(this.regexpTABLE)) {
            text = text.match(this.regexpTABLE)[0];
        } else {
            text = "<table><tr><td>" + text + "</td></tr></table>"
        }
        let tableElement = DomManipulatorService.createHTMLElementFromHtml( text );
        return tableElement;
    }
    /**
     *  Look where the new row should be placed on the custom list
     */
    $getIndexRow(rowElement, position){
        let index = rowElement.rowIndex;
        if( position === ConstantNode.WHERE_AFTER ) {
            index += 1;
        }
        return index;
    }
    /**
     * find the first TR element inside the element and its parent recursively
     * @param {Object} element
     */
    $findFirstRowElement(element){
        return this.$findFirstElement(element, ConstantBulletStyle.ROW_CLASS,ConstantTag.TR.toUpperCase());
    }
    /**
     * Recursive method to find an element with the specific class name and the specific tagname inside the element in argument.
     * The method search recursively inside each parent to the element.
     * @param {*} element  element where the search is done
     * @param {*} className the element class name to find 
     * @param {*} tagName the element tag name to find
     */
    $findFirstElement(element, className, tagName){
        if(!!element.className && element.className.includes(className) && element.nodeName === tagName){
            return element;
        } else {
            return this.$findFirstElement(element.parentElement, className, tagName);
        }
    }
    /** 
     * Create new customList with the previous rows, the new row and the next rows
     */
    $gatherNewRowToCustomList(newRow, domNodePreviousSiblingsList, domNodeNextSiblingsList, tbodyElement){
        // Reconstruct temporarly the custom list with the new row
        let rowList;
        if( !!newRow.length ){
            rowList = [...domNodePreviousSiblingsList, ...newRow, ...domNodeNextSiblingsList];
        } else {
            rowList = [...domNodePreviousSiblingsList, newRow, ...domNodeNextSiblingsList];
        }
        
        /**
         * Set the two value required to create an empty custom list
         * the number of row (with the new one), and the style of the bullet to apply
         */
        let rowNumber = rowList.length;
        let bulletStyle = this.$extractBulletStyle(tbodyElement.classList);
        let separatorStyle = this.$extractSeparatorStyle(tbodyElement.classList)
        
        return this.$updateNewCustomList(bulletStyle, rowNumber, separatorStyle, rowList);
    }
    /**
     * Based on the bullets found on the text determine the class to apply to the custom list
     * @param {Array} firstIndex, the first index of the list
     */
    $determineBulletClasses(firstIndex){
        let bulletClasses = [];
        let separatorClass = this.$extractSeparatorClass(firstIndex);
        let indiceClass = this.$extractIndiceClass(firstIndex, separatorClass);
        bulletClasses.push(indiceClass);
        bulletClasses.push(separatorClass);
        return bulletClasses;
    }
}

export default new CustomListService();