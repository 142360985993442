const CLIPBOARD                         = 'clipboard';
const COPY_NODE                         = 'copy_node';
const COPY_ONLINE_QUOTE                 = 'copy_online_quote';
const COPY_SIBLINGS_NODE                = 'copy_siblings_node';
const COPY_STRUCTURAL_QUOTE             = 'copy_structural_quote';
const COPY_STRUCTURAL_QUOTE_LIST        = 'copy_structural_quote_list';
const COPY_STRUCT                       = 'copyStruct';
const CUT                               = 'cut';
const COPY_TEXT                         = 'copy_text';
const DOWN_IN_SEARCH                    = 'down';
const IS_FORMAT_TEXT                    = 'formatText';
const PASTE_NODE                        = 'paste_node';
const PASTE_TEXT                        = 'paste_text';
const READY_TO_COPY_CONTENT             = 'ready_to_copy_content';
const READY_TO_COPY_DOCUMENT_MODIFIER   = 'ready_to_copy_document_modifier';
const REMOVE_DOCUMENT_MODIFIER          = 'remove_document_modifier';
const REMOVE                            = 'remove';
const REPLACE_ALL                       = 'replace_all';
const REPLACE_ONE                       = 'replace_one';
const REPLACE_TEXT_BY_TEXT              = 'replace_text_by_text';
const SAVE_DOCUMENT_CONTENT             = 'save_document_content';
const SEARCH_ALL                        = 'search';
const SELECTION                         = 'selection';
const UP_IN_SEARCH                      = 'up';
const UPDATE                            = 'update';
const VALIDATE                          = 'validate';

export default class ConstantAction{
    static get IS_FORMAT_TEXT(){
        return IS_FORMAT_TEXT;
    }
    static get SEARCH_ALL(){
        return SEARCH_ALL;
    }
    static get UP_IN_SEARCH(){
        return UP_IN_SEARCH;
    }
    static get DOWN_IN_SEARCH(){
        return DOWN_IN_SEARCH;
    }
    static get REPLACE_ONE(){
        return REPLACE_ONE;
    }
    static get REPLACE_ALL(){
        return REPLACE_ALL;
    }
    static get REPLACE_TEXT_BY_TEXT(){
        return REPLACE_TEXT_BY_TEXT;
    }
    static get CLIPBOARD(){
        return CLIPBOARD;
    }
    static get COPY_NODE(){
        return COPY_NODE;
    }
    static get COPY_ONLINE_QUOTE(){
        return COPY_ONLINE_QUOTE;
    }
    static get COPY_SIBLINGS_NODE(){
        return COPY_SIBLINGS_NODE;
    }
    static get COPY_STRUCTURAL_QUOTE(){
        return COPY_STRUCTURAL_QUOTE;
    }
    static get COPY_STRUCTURAL_QUOTE_LIST(){
        return COPY_STRUCTURAL_QUOTE_LIST;
    }
    static get COPY_TEXT(){
        return COPY_TEXT;
    }
    static get COPY_STRUCT(){
        return COPY_STRUCT;
    }
    static get CUT(){
        return CUT;
    }
    static get PASTE_NODE(){
        return PASTE_NODE;
    }
    static get PASTE_TEXT(){
        return PASTE_TEXT;
    }
    static get SAVE_DOCUMENT_CONTENT(){
        return SAVE_DOCUMENT_CONTENT;
    }
    static get REMOVE_DOCUMENT_MODIFIER(){
        return REMOVE_DOCUMENT_MODIFIER;
    }
    static get READY_TO_COPY_CONTENT(){
        return READY_TO_COPY_CONTENT;
    }
    static get READY_TO_COPY_DOCUMENT_MODIFIER(){
        return READY_TO_COPY_DOCUMENT_MODIFIER;
    }
    static get REMOVE(){
        return REMOVE;
    }
    static get SELECTION(){
        return SELECTION;
    }
    static get VALIDATE(){
        return VALIDATE;
    }
    static get UPDATE(){
        return UPDATE;
    }
}