/**
 * Constants for paoStructureStore
 */
const AVAILABLE_POLICIES            = 'getAvailablePolicies';
const DEFAULT_CLICKED_NODE          = 'getClickedNodeRootByDefault';
const PARENT_NODE                   = 'getParentNode';
const POLICY                        = 'getPolicyById'

const ADD_NODE                      = 'addNode';
const ADD_CREATED_NODE              = 'addCreatedNode';
const CLEAN_STRUCTURE               = 'cleanStructure';
const DELETE_NODE                   = 'deleteNode';
const DELETE_DOCUMENT               = 'deleteDocument';
const CUT_NODE                      = 'cutNode';
const PASTE_NODE                    = 'pasteNode';
const INIT                          = 'initStructureStore';
const UPDATE_CLICKED_DOCUMENT       = 'updateClickedDocument';
const UPDATE_CLICKED_NODE           = 'updateClickedNode';
const UPDATE_CLICKED_NODE_LABEL     = 'updateClickedNodelabel'
const UPDATE_CLICKED_NODE_CODE      = 'updateClickedNodeCode'
const UPDATE_STRUCTURE              = 'updtaeStructure';
const UPDATE_DOCUMENT               = 'updateDocument';
const UPDATE_SELECTED_NODE_DOCUMENT = 'updateSelectedNodeDocument';
const UPDATE_SELECTED_NODE_DOCUMENT_VALUES = 'updateSelectedNodeDocumentValues';
const UPDATE_IS_SELECTED_NODE       = 'updateIsSelectedNode';
const UPDATE_VISIBILITY             = 'updateVisibility';

const SET_CLICKED_DOCUMENT          = 'setClickedDocument';
const SET_CLICKED_NODE              = 'setClickedNode';
const REMOVE_NEW_NODES_ID           = 'removeNewNodesId'
const SET_NODE_MAP                  = 'setNodeMap';
const SET_NODE_LABEL                = 'setNodeLabel';
const SET_NODE_CODE                 = 'setCode';
const SET_NODE_POLICIES             = 'setNodePolicies';
const SET_NODE_TYPE_LIST            = 'setNodeTypeList';
const SET_STRUCTURE                 = 'setStructure';
const SET_DOCUMENT                  = 'setDocumentContent';
const SET_IS_SELECTED_NODE          = 'setIsSelectedNode';
const SET_SELECTED_NODE_DOCUMENT    = 'setSelectedNodeDocument';
const SET_VISIBILITY                = 'setVisibility';

export default class Types{

    static get GETTER(){
        return {
            AVAILABLE_POLICIES,
            DEFAULT_CLICKED_NODE,
            PARENT_NODE,
            POLICY
        }
    }
    
    static get ACTION(){
        return {
            ADD_NODE,
            ADD_CREATED_NODE,
            CLEAN_STRUCTURE,
            DELETE_DOCUMENT,
            DELETE_NODE,
            CUT_NODE,
            PASTE_NODE,
            INIT,
            UPDATE_CLICKED_DOCUMENT,
            UPDATE_CLICKED_NODE,
            UPDATE_CLICKED_NODE_LABEL,
            UPDATE_CLICKED_NODE_CODE,
            UPDATE_STRUCTURE,
            UPDATE_SELECTED_NODE_DOCUMENT,
            UPDATE_IS_SELECTED_NODE,
            UPDATE_DOCUMENT,
            UPDATE_VISIBILITY
        }
    }

    static get MUTATION(){
        return {
            ADD_NODE,
            DELETE_NODE,
            DELETE_DOCUMENT,
            CUT_NODE,
            PASTE_NODE,
            REMOVE_NEW_NODES_ID,
            UPDATE_SELECTED_NODE_DOCUMENT_VALUES,
            SET_CLICKED_DOCUMENT,
            SET_CLICKED_NODE,
            SET_NODE_MAP,
            SET_NODE_LABEL,
            SET_NODE_CODE,
            SET_NODE_POLICIES,
            SET_NODE_TYPE_LIST,
            SET_STRUCTURE,
            SET_DOCUMENT,
            SET_IS_SELECTED_NODE,
            SET_SELECTED_NODE_DOCUMENT,
            SET_VISIBILITY
        }
    }

}