const NODE_CONTENT_CLASS        = 'bol-node-content';
const TEXT_NODE_CLASS           = 'bol-text-node';
const CONTENT_STYLE_CLASS       = 'bol-style-content';
const NODE_CONTENT_ID_PREFIX    = 'bol-node-content-';
const NON_PERSISTENT_PREFIX     = 'bol-np';
const NODE_RESUME_CLASS         = 'bol-node-resume';
const NODE_RESUME_GROUP_CLASS   = 'bol-node-resume-group';
const NODE_RESUME_CLASS_INVALIDATE         = 'bol-node-resume-invalidate';
const NODE_RESUME_GROUP_CLASS_INVALIDATE   = 'bol-node-resume-group-invalidate';
const NODE_TYPE                 = 'bol-node-type';
const RANK                      = 'rank';
const NODE_LABEL                = 'nodeLabel';
const ROOT                      = 'ROOT';
const TEXT_TYPE                 = 'TEXT';

const ACTION_TOP                ='top';
const ACTION_INSIDE             ='inside';
const ACTION_BOTTOM             ='bottom';


export default class ConstantDocumentNode{
    static get NODE_TYPE(){
        return NODE_TYPE;
    }
    static get NODE_RESUME_CLASS(){
        return NODE_RESUME_CLASS;
    }
    static get NODE_RESUME_GROUP_CLASS(){
        return NODE_RESUME_GROUP_CLASS;
    }
    static get NODE_RESUME_CLASS_INVALIDATE(){
        return NODE_RESUME_CLASS_INVALIDATE;
    }
    static get NODE_RESUME_GROUP_CLASS_INVALIDATE(){
        return NODE_RESUME_GROUP_CLASS_INVALIDATE;
    }
    static get NODE_CONTENT_CLASS(){
        return NODE_CONTENT_CLASS;
    }
    static get TEXT_NODE_CLASS(){
        return TEXT_NODE_CLASS;
    }
    static get RANK(){
        return RANK;
    }
    static get NODE_LABEL(){
        return NODE_LABEL;
    }
    static get CONTENT_STYLE_CLASS(){
        return CONTENT_STYLE_CLASS;
    }

    static get NODE_CONTENT_ID_PREFIX(){
        return NODE_CONTENT_ID_PREFIX;
    }

    static get NON_PERSISTENT_PREFIX(){
        return NON_PERSISTENT_PREFIX;
    }

    static get ROOT(){
        return ROOT;
    }

    static get TEXT_TYPE(){
        return TEXT_TYPE;
    }
    static get ACTION_TOP(){
        return ACTION_TOP;
    }
    static get ACTION_INSIDE(){
        return ACTION_INSIDE;
    }
    static get ACTION_BOTTOM(){
        return ACTION_BOTTOM;
    }

}