<template>

    <v-dialog lazy v-model="openDialog" width="900">
        <v-card>
            <v-card-title class="headline">
                {{$t(!!table? 'structure.table.title.edit' :'structure.table.title.add')}}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                
                <v-container>
                    <v-layout row wrap>
                        <v-flex xs4 offset-xs1>
                            <v-text-field
                                v-model.number="totalRowNbr"
                                :label="$t('structure.table.row.number')"
                                type="number"
                                max="99"
                                min="1"
                                @change="applyValidationRules('totalRowNbr')"
                                required/>
                        </v-flex>
                        <v-flex xs4 offset-xs2>
                            <v-text-field
                                v-model.number="totalColumnNbr"
                                :label="$t('structure.table.column.number')"
                                type="number"
                                max="99"
                                min="1"
                                @change="applyValidationRules('totalColumnNbr')"
                                required/>
                        </v-flex>
                    </v-layout>
                    <v-layout >
                        <v-flex>
                            <v-data-table ref="editableTable" :class="editableTableClass"
                                    :items="tableRows"
                                    :headers="[]"
                                    disable-initial-sort
                                    hide-actions>
                                <template slot="headers" slot-scope="props">
                                    <tr>
                                        <th></th>
                                        <th v-for="columnNumber in totalColumnNbr" :key="`th_${columnNumber}`">
                                            <v-hover>
                                                <v-card flat slot-scope="{ hover }">
                                                    <v-expand-transition>
                                                        <div class="bol-column-reveal" v-if="hover">
                                                            <v-icon color="black">arrow_drop_up</v-icon>
                                                        </div>
                                                    </v-expand-transition>
                                                    <v-menu class="bol-table-column-menu" transition="slide-y-reverse-transition">
                                                        <v-btn class="bol-table-column-btn" flat dark slot="activator">
                                                            <v-icon :color="hover ? 'transparent' : 'rgba(0, 0, 0, 0.2)'" class="bol-column-arrow">arrow_drop_up</v-icon>
                                                        </v-btn>
                                                        <v-list>
                                                            <v-list-tile @click="removeColumn(columnNumber)">
                                                                <v-list-tile-title  v-text="$t('structure.table.column.remove')"></v-list-tile-title>
                                                            </v-list-tile>
                                                        </v-list>
                                                    </v-menu>
                                                </v-card>
                                            </v-hover>
                                        </th>
                                    </tr>
                                </template>

                                <template slot="items" slot-scope="props">
                                    <td align="right" class="bol-table-first-cell">
                                        <v-hover>
                                            <v-card height=100% class="bol-table-row-menu-container" flat slot-scope="{ hover }">
                                                <v-slide-x-reverse-transition >
                                                    <div class="bol-row-reveal" v-if="hover">
                                                        <v-icon color="black">arrow_left</v-icon>
                                                    </div>
                                                </v-slide-x-reverse-transition >
                                                <v-menu transition="slide-x-reverse-transition">
                                                    <v-btn class="bol-table-row-btn" small flat dark slot="activator">
                                                        <v-icon :color="hover ? 'transparent' : 'rgba(0, 0, 0, 0.2)'" class="bol-row-arrow">arrow_left</v-icon>
                                                    </v-btn>
                                                    <v-list> 
                                                        <v-list-tile @click="removeRow(props.index +1)">
                                                            <v-list-tile-title  v-text="$t('structure.table.row.remove')"></v-list-tile-title>
                                                        </v-list-tile>
                                                    </v-list>
                                                </v-menu>
                                            </v-card>
                                        </v-hover>
                                    </td>
                                    <td v-for="(cell, index) in props.item.cells"
                                            :key="'row_'+props.index+'_'+'column_'+index"
                                            :colspan="cell.colSpan" v-html="cell.content">
                                    </td>
                                </template>
                            </v-data-table>
                        </v-flex>
                    </v-layout>
                </v-container>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" flat @click="close">{{$t('structure.table.action.cancel')}}</v-btn>
                <v-btn v-if="!!table" color="primary" flat @click="saveTable">{{$t('structure.table.action.save')}}</v-btn>
                <v-btn v-else color="primary" flat @click="addTable">{{$t('structure.table.action.add')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
        
</template>
<script >
import * as $ from 'jquery';
import domManipulatorService        from '../../../../service/html/domManipulatorService.js';
import ConstantNodeContentElement   from '../../../../shared/constant/constantNodeContentElement.js';
import {tableMixin}                 from '../../../../mixins/tableMixin.js';
import EventBus                     from '../../../../utils/event-bus.js';

const CLOSE_DIALOG = 'close-popup';
const EDIT_DIALOG = 'update-table';
const ADD_DIALOG = 'add-table';

/**
 * Modal to add or edit a table, manage rows and columns number.
 * 
 * @author Cédric de BOISVILLIERS
 * @version 1.0
 * @since 2018-12-10
 */
export default {
    name: 'DialogManageTable',
    mixins: [tableMixin],
    props: {
        dialog: {
            default: false
        },
        table: {
            required: false,
            default: null
        }
    },
    data () {
        return {
            tableRows: [], //Override tableMixin
            totalColumnNbr: 2,
            totalRowNbr: 2
        };
    },
    computed: {
        openDialog: {
            get() {
                return this.dialog;
            }
        },
        editableTableClass() {
            return ConstantNodeContentElement.EDITABLE_TABLE_CLASS;
        }
    },
    methods: {
        /**
         * Extract the final table and send it to the parent component as html text
         */
        addTable() {
            this.$emit( ADD_DIALOG, this.$extractHTMLTable());
            this.close();
        },
        /**
         * Extract the modified table and send it to the parent component as html text
         */
        saveTable() {
            this.$emit( EDIT_DIALOG, this.$extractHTMLTable());
            this.close();
        },
        /**
         * Forbid to update the column/row number outside of min/max values
         */
        applyValidationRules(numericField) {
            if(this[numericField] >= 100) {
                this[numericField] = 99;
            } else if (this[numericField] < 1) {
                this[numericField] = 1;
            }
        },
        /**
         * Remove a column from the table
         */
        removeColumn(columnNumber) {
            this.tableRows.forEach( row => {
                let cellIndex = 0;
                let totalColumnNumber = 0
                while (totalColumnNumber < columnNumber) {
                    totalColumnNumber += row.cells[cellIndex].colSpan || 1;
                    if (totalColumnNumber < columnNumber) {
                        cellIndex++;
                    }
                }
                if (!row.cells[cellIndex].colSpan || row.cells[cellIndex].colSpan === 1) {
                    row.cells.splice(cellIndex, 1);
                } else {
                    row.cells[cellIndex].colSpan--;
                }
            });
            this.totalColumnNbr--;
        },
        /**
         * Remove a row from the table
         */
        removeRow(rowNumber) {
            this.tableRows.splice(rowNumber-1, 1);
            this.totalRowNbr--;
        },
        /**
         * Close the modal
         */
        close () {
            this.$emit(CLOSE_DIALOG, false);
        },
        /**
         * Extract the table to save from the editable table
         */
        $extractHTMLTable() {
            let editableTableElement = this.$refs.editableTable.$el;
            let cleanEditableTable = this.$removeMenusFromEditableTable(editableTableElement);
            let tableElement = domManipulatorService.extractTableFromVDataTable(cleanEditableTable);
            tableElement.classList.add(ConstantNodeContentElement.EDITABLE_TABLE_CLASS);
            let listFooter = domManipulatorService.getFooterArrayFromHTMLTableElement(this.table);
            let tableFooter = tableElement.createTFoot();
            listFooter.forEach((tableFootNote, index) => {
                var row = tableFooter.insertRow(index);
                var cell = row.insertCell();
                cell.colSpan = this.totalColumnNbr;
                cell.innerHTML = tableFootNote;
            });

            return tableElement.outerHTML;
        },
        /**
         * Remove the menus for columns and rows
         */
        $removeMenusFromEditableTable(editableTableElement) {
            let cleanEditableTable = domManipulatorService.createHTMLElementFromHtml(editableTableElement.outerHTML);
            //actually html table element in datatable in wrapped in 2 div element
            // delete columns menu
            cleanEditableTable.firstChild.firstChild.deleteTHead();
            // delete rows menu
            Array.from(cleanEditableTable.firstChild.firstChild.tBodies).forEach(tBody => {
                Array.from(tBody.rows).forEach(row => {
                    row.deleteCell(0);
                });
            });
            return cleanEditableTable;
        },
        /**
         * Reset component to initial state
         */
        $reset() {
            this.totalRowNbr = 2;
            this.totalColumnNbr = 2;
            this.tableRows = [
                this.createNewRow(),
                this.createNewRow()
            ];
        },
        /**
         * Create a new empty row
         */
        createNewRow() {
            let cells = [];
            for (let colIndex = 1; colIndex <= this.totalColumnNbr && colIndex < 100; colIndex++) {
                cells.push(this.createNewCell());
            }
            return {cells: cells, align: "", vAlign: ""};
        },
        createNewCell() {
            return {content: ""};
        }
    },
    watch: {
        dialog() {
            if (this.dialog && !!this.table) {
                let tableElement = this.table.tagName === 'TABLE' ? this.table : domManipulatorService.extractTableFromVDataTable(this.table);
                this.tableRows = domManipulatorService.getArrayFromHTMLTableElement(tableElement);
                this.totalRowNbr = this.tableRows.length;
                this.totalColumnNbr = this.rowsMaxLength; //rowsMaxLength from tableMixin
            } else if (this.dialog) {
                this.$reset();
            }
        },
        totalRowNbr() {
            if(this.totalRowNbr > this.tableRows.length){
                for (let rowIndex = this.tableRows.length; rowIndex < this.totalRowNbr && rowIndex <100; rowIndex++) {
                    let row = this.createNewRow();
                    this.tableRows.push(row);
                }
            } else {
                this.tableRows.splice(this.totalRowNbr, this.tableRows.length - this.totalRowNbr);
            }
        },
        totalColumnNbr() {
            let currentColumnNumber = this.rowsMaxLength;
            if (currentColumnNumber < this.totalColumnNbr) {
                this.tableRows.forEach( (row) => {
                    for (let colIndex = currentColumnNumber; colIndex < this.totalColumnNbr; colIndex++) {
                        row.cells.push(this.createNewCell());
                    }
                });
            } else if (currentColumnNumber > this.totalColumnNbr) {
                this.tableRows.forEach( (row) => {
                    for (let colIndex = currentColumnNumber - 1; colIndex >= this.totalColumnNbr; colIndex--) {
                        if (!!row.cells[colIndex]) {
                            row.cells.splice(colIndex, 1);
                        } else {
                            let lastCell = row.cells[row.cells.length -1];
                            if (lastCell.colSpan > 1) {
                                lastCell.colSpan--;
                            } else {
                                row.cells.pop();
                            }
                        }
                    }
                });
            }
        }
    }
}
</script>
<style scoped>
    .bol-editable-table{
        width: 99%;
    }
    .bol-editable-table thead tr{
        border: none !important;
    }
    .bol-editable-table table tbody td:not(:first-child){
        width: 100%;
    }
    .bol-table-first-cell{
        border: none;
        padding: 0 !important;
    }
    .bol-table-column-btn{
        min-width: 0;
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .bol-table-row-btn{
        min-width: 0;
        padding: 0;
        margin: 0;
    }
    .bol-table-column-menu{
        width: 100%;
    }
    .bol-table-row-menu-container{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .bol-column-reveal{
        align-items: center;
        position: absolute;
        bottom: 0;
        justify-content: center;
        opacity: .7;
        width: 100%;
    }
    .bol-row-reveal{
        position: absolute;
        right: 10px;
        justify-content: center;
        opacity: .7;
    }
    .bol-column-arrow{
        position: relative;
        bottom: -16px;
    }
    .bol-row-arrow{
        position: relative;
        right: -4px;
    }
</style>

