<template>
    <v-layout class="treeNode" :id="'node_leaf-' + node.id">
        <v-flex v-if="node.type === 'TEXT'" :class="{ selectedNodeTree: node.selected }">
            <v-flex v-if="node.selected" >
                <v-icon  color="#D39E4E">subject</v-icon>
                <span class="font-white">{{ellipsText(node)}}</span>
            </v-flex>
            <v-flex v-else>
                <v-icon  color="#D39E4E">subject</v-icon>
                <span >{{ellipsText(node)}}</span>
            </v-flex>
        </v-flex>
        <v-flex  v-else class="clickable" :class="{ selectedNodeTree: node.selected }">
            <v-flex  v-if="node.selected">
                <v-icon v-if="node.opened" color="#D39E4E">folder_open</v-icon>
                <v-icon v-else color="#D39E4E">folder</v-icon>
                <span class="font-white" >{{node.label}}</span>
            </v-flex >
            <v-flex  v-else >
                <v-icon v-if="node.opened" color="#D39E4E">folder_open</v-icon>
                <v-icon v-else color="#D39E4E">folder</v-icon>
                <span>{{node.label}}</span>
            </v-flex >
            <v-flex  v-if="node.opened && node.children" class="children"> 
                <div v-for="childNode in node.children" :key="childNode.id">
                    <structural-node :node="childNode"></structural-node>
                </div>
            </v-flex >
        </v-flex >
    
    </v-layout>
</template>
<script>
/**@
 * 
 * Display a Node as a leaf of a simple node tree
 * 
 * @author Sébastien DE SANTIS
 * @since 2018-11-28
 * @version 1.0
 */
export default {
    name: 'StructuralNode',
    props:['node'],
    methods:{
        ellipsText(text){
            var txt = document.createElement("textarea");
            txt.innerHTML = text.content;
            if(txt.innerText.includes('<sup>0</sup>')){
                return ((txt.value.replace(new RegExp('<[^>]*>','gm'),'')).substring(1, 20) + '...');
            }
            else {
                return ((txt.value.replace(new RegExp('<[^>]*>','gm'),'')).substring(0, 20) + '...');
            }
        }
    }

}
</script>
<style>
    .clickable{
        cursor: pointer;
    }
    .children{
        margin-left: 15px;
    }
    .treeNode{
        color:lightgrey;
    }
    .font-white{
        color: white;
    }
    .selectedNodeTree{
        border: lightgrey 2px dashed;
    }
</style>


