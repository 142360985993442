<template>
    <v-layout
        row
        wrap
        align-left
    >
        <v-flex>
            <v-container class="t_create_edit_dialog">
                <h1
                    v-if="$route.params.id == null"
                    class="bolded"
                >
                    {{ $t('administration.workspace.manage.add.title') }}
                </h1>
                <v-form
                    ref="formWorkspace"
                    v-model="valid"
                    class="form-style"
                    lazy-validation
                >
                    <v-layout
                        column
                        justify-center
                    >
                        <v-flex md6>
                            <v-text-field
                                v-model="workspace.name"
                                class="t_name"
                                :label="$t('administration.workspace.label.name')"
                                :rules="labelRules"
                                required
                                clearable
                            />
                        </v-flex>
                        <v-flex md6>
                            <v-text-field
                                v-model="workspace.abbreviation"
                                class="t_abbreviation"
                                :label="$t('administration.workspace.label.abbreviation')"
                                :rules="abbreviationRules"
                                required
                                clearable
                            />
                        </v-flex>
                        <v-flex md6>
                            <v-text-field
                                v-model="workspace.description"
                                class="t_description"
                                :label="$t('administration.workspace.label.description')"
                                :rules="labelRules"
                                required
                                clearable
                            />
                        </v-flex>
                        <v-flex md6>
                            <v-checkbox
                                v-model="workspace.visible"
                                :label="$t('administration.workspace.label.visible')"
                                @change="warnForVisibility(workspace.visible)"
                            />
                        </v-flex>
                        <v-flex md6>
                            <v-checkbox 
                                v-model="workspace.important"
                                :label="$t('administration.workspace.label.important')"
                            />
                        </v-flex>
                        <v-flex md6>
                            <v-text-field
                                v-model="workspace.rank"
                                class="t_rank"
                                :label="$t('administration.workspace.label.rank')"
                                :rules="labelRules"
                                required
                                clearable
                                type="number"
                            />
                        </v-flex>
                        <!-- MARKET -->
                        <v-autocomplete
                            v-model="workspace.markets"
                            class="t_markets"
                            :items="allMarketList"
                            :disabled="allMarketList.length === 0"
                            dense
                            filled
                            multiple
                            chips
                            small-chips
                            item-text="code"
                            return-object
                            label="Markets"
                        />
                        <!-- BUTTONS -->
                        <v-flex
                            xs3
                            justify-end
                        >
                            <v-tooltip
                                top
                                class="align-action-end"
                            >
                                <v-btn
                                    slot="activator"
                                    color="#355B7F"
                                    small
                                    fab
                                    :to="{ name: 'WorkspaceSearch' }"
                                >
                                    <v-icon color="white">
                                        cancel
                                    </v-icon>
                                </v-btn>
                                <span>{{ $t("administration.workspace.manage.action.cancel") }}</span>
                            </v-tooltip>
                            <v-tooltip
                                v-if="valid"
                                top
                                class="align-action-end"
                            >
                                <v-btn
                                    slot="activator"
                                    class="t_save"
                                    color="primary"
                                    small
                                    fab
                                    dark
                                    icon
                                    @click="manage(workspace)"
                                >
                                    <v-icon>save</v-icon>
                                </v-btn>
                                <span>{{ $t("administration.workspace.manage.action.add") }}</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </v-form>     
            </v-container>
        </v-flex>
    </v-layout>
</template>

<script>
import axios            from 'axios';
import EventBus         from '../../../utils/event-bus.js';
import ConstantEvent    from '../../../shared/constant/constantEvent.js'
import ConstantNotification from '../../../shared/constant/constantNotification.js';

/**
 * Form to create or update a keyword
 *
 * @author Cindy SWENNEN, SFEIR Benelux
 * @version 1.0
 * @since 19/06/2019
 */
export default {
    name: 'WorkspaceManagement',
    data () {
        return {
            valid: false,
            workspace: {
                id: null,
                name: null,
                visible: false,
                description: null,
                important: false,
                abbreviation: null
            },
            labelRules: [
                v => !!v || this.$t("administration.workspace.manage.required")
            ],
            abbreviationRules: [
                v => !!v || this.$t("administration.workspace.manage.required"),
                v => !!v && v.length < 6 || this.$t("administration.workspace.manage.too-long") + "5"
            ],
            allMarketList: []
        }
    },
    mounted(){
        /**
         * When the page is charging, we pay attention if an id is passed,
         * if it's the case, it means that we update a workspace instead of creating one
         */
        if(this.$route.params.id !== undefined) {
            this.loadWorkspace(this.$route.params.id);
        }
        this.loadMarkets();
    },
    methods:{
        /**
         *  Create or update a workspace
         * @param newWorkspace
         */
        manage(workspace) {
            if(this.$refs.formWorkspace.validate()){
                var method = null;
                if(!!this.$route.params.id) {
                    method = "PUT",
                    workspace.id = this.$route.params.id;
                } else {
                    method = "POST";
                }

                axios( {
                    method: method,
                    url: "administration/workspace",
                    data: workspace
                }).then( () => {
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                        message: 'administration.workspace.manage.' +  (!this.$route.params.id ? 'add' : 'edit') +'.success',
                        type: 'SUCCEED'
                    }
                    );
                    this.$router.push('/administration/workspace');
                });
            }
        },
        /**
         * Retrieve a workspace with its id
         * @param id
         */
        loadWorkspace( id ) {
            axios.get(
                `administration/workspace/${id}`
            ).then( result => {
                this.workspace = result.data;
            });
        },
        warnForVisibility(isvisible) {
            if ( isvisible ){
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    type: ConstantNotification.WARNING,
                    message: 'administration.workspace.manage.add.alertmessage'
                });
            }
        },
        loadMarkets() {
            axios.get(
                `administration/markets`
            ).then(result => {
                this.allMarketList = result.data;
            });
        }
    }
}
</script>
<style>
.form-style {
    margin-top:50px;
}
.align-action-end{
    float: right;
}
.bolded{
    font-weight: bold;color: #355B7F;
}
</style>
