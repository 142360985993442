const NEXT_STEP             = 'nextStep';
const SET_ADDITIONAL_FIELD  = 'setAdditionalField';
const SET_FIELD             = 'setField';


/**
 * Store all the custom event name for the application
 * /!\ A custom event is an event sent to a component to its direct parent. 
 * Don't confuse it with a global event sent to all the application 
 * @author Sébastien DE SANTIS
 * @since 28/02/2020
 * @version 1.0
 */
export default class ConstantCustomEvent{
    static get EVENT(){
        return {
            NEXT_STEP,
            SET_ADDITIONAL_FIELD,
            SET_FIELD
        }
    }

}