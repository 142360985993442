<template>
    <v-dialog v-model="openDialog" fullscreen > 
        <v-layout row align-start justify-center class="document_hyperlink_sizer  custom-card">
            <v-flex v-if="displaySpinner">
                <v-progress-circular 
                    :size="70"
                    :indeterminate="true"
                    color="primary">
                </v-progress-circular>
            </v-flex>
            <div class="header-color">
                <h3 v-if="!!chosenTitleDocumentDestination" class="bol-title-style">
                    <p class="display-1">{{$t('pao.summary.linkto')}} {{chosenTitleDocumentDestination}}</p>
                </h3>
                <div class="backtodoc" @click="cancel" >
                    <bol-back-button :textBackButton="$t('global.action.backTodoc')" />
                </div>
            </div>
            <v-flex v-if="!displaySpinner && !!rootNode" >
                    <document-node-content-hyperLink class="content-document" :rootNode="rootNode"/>
            </v-flex>
        </v-layout>
    </v-dialog>
</template>
<script>
import axios                            from 'axios'
import EventBus                         from '../../../../utils/event-bus';
import ConstantEvent                    from '../../../../shared/constant/constantEvent.js';
import DocumentNodeContentHyperLink     from './DocumentNodeContentHyperLink';
import DocumentHyperLinkService         from '../../../../service/document/documentHyperLinkService.js';
import ConstantDocumentNode             from '../../../../shared/constant/constantDocumentNode.js';
import domManipulatorService            from '../../../../service/html/domManipulatorService.js';
import { mapState, mapActions }         from 'vuex';
import BolBackButton                    from "../../../shared/BolBackButton";

const UPDATE_DIALOG = 'update:dialog';
/**
 * 
 * Document search component.
 * @author Justin WILMET, SFEIR benelux
 * @version 1.0
 * @since 15/11/2018
 * 
 */
export default {
    name: "DialogDocumentNodeHyperLink",
    props: {
        dialog: {
            default: false
        }
    },
    data(){
        return {
            displaySpinner: false,
            rootNode: null,
            referenceDocumentDestination: null,
            chosenTitleDocumentDestination: null
        }
    }, 
    computed:{
        ...mapState({
            documentHyperLink: state => state.hyperLinkStore.documentHyperLink,
            sourceNode: state => state.hyperLinkStore.sourceNode,
        }),
        openDialog: {
            get() {
                return this.dialog;
            },
            set(dialog) {
                if(!dialog) {
                    this.$emit(UPDATE_DIALOG, false);
                }
            }
        },
    },
    /**
     * Open the dialog and set value if needed
     */
    created: function(){
        EventBus.$on(ConstantEvent.OPEN_DIALOG_DOCUMENT_NODE_HYPERLINK, this.listener);
        EventBus.$on(ConstantEvent.SEND_ID_NODE_DESTINATION, this.selectIdNodeDestination);
    },
    /**
     * Destroy the even bus before the component destruction
     */
    beforeDestroy: function(){
        EventBus.$off(ConstantEvent.OPEN_DIALOG_DOCUMENT_NODE_HYPERLINK);
        EventBus.$off(ConstantEvent.SEND_ID_NODE_DESTINATION, this.selectIdNodeDestination);
    },
    methods:{
        ...mapActions({
            setIdNodeDestination: 'hyperLinkStore/setIdNodeDestination',
            updateNodeContent: 'documentContent/modifyContent'
        }),
        listener( data ){
            if(!!data ){
                this.loadDocumentContent(data.referenceDocumentDestination);
                this.referenceDocumentDestination = data.referenceDocumentDestination;
                this.chosenTitleDocumentDestination = data.chosenTitleDocumentDestination;
            }
        },
        loadDocumentContent(referenceDocumentDestination){
            this.displaySpinner = true;
            axios.get(`link/document/reference/${referenceDocumentDestination}`)
            .then( result => {
                    this.displaySpinner = false;
                    this.rootNode = result.data.content;
            });
        },
        selectIdNodeDestination( { nodeId, nature } ){
            this.setIdNodeDestination({idNodeDestination: nodeId});
            let link = DocumentHyperLinkService.insertHyperLinkInContent(this.documentHyperLink, this.sourceNode, nature);
            let htmlContent = domManipulatorService.replaceTextByHTMLElementInNode(
                this.sourceNode.caretPosition.clickedNode,
                this.sourceNode.caretPosition.clickedNodeOffset,
                this.sourceNode.caretPosition.clickedFocusNodeOffset,
                link,
                ConstantDocumentNode.TEXT_NODE_CLASS,
                this.sourceNode.caretPosition.focusNode );
            this.updateNodeContent( { id: this.sourceNode.id, content: htmlContent });
            this.resetComponent();
        },
        cancel(){
            this.$emit('closeDocumentNode');
            this.resetComponent();
        },
        resetComponent(){

            this.referenceDocumentDestination = null;
            this.chosenTitleDocumentDestination = null;
            this.$emit(UPDATE_DIALOG, false);
        }
    },
    components: {
        DocumentNodeContentHyperLink,
        BolBackButton
    }
}
</script>
<style>
.custom-card{
    background-color: white;
    height:100vh;
    width:100vw;
}
.header-color{
    position:absolute;
    height:140px;
    width:100%;
    background-color: #355B7F;
}
.display-1 {
    font-size: 34px !important;
    font-weight: 400;
    line-height: 40px !important;
    letter-spacing: normal !important;
    font-family: 'Roboto', sans-serif !important;
}
.primary--text {
    color: #1867c0 !important;
}

.bol-title-style{
    position:absolute;
    top:60px;
    left:320px;
    color:white;
}
.document_hyperlink_sizer{
    width: 100%;
    height:100vh;
}
.backtodoc{
    font-weight: bold;
    position:absolute;
    color:#D39E4E;
    width:300px;
    height:20px;
    top:90px;
    left:20px;
}
    .backtodoc:hover{
        transform: scale(1.1);
    }
.content-document{
    margin-top: 150px;
    padding-top:20px;
    position:absolute;
    z-index: 999;
    overflow: scroll;
    background-color: white;
}
</style>

