import axios from 'axios';

/**
 * Manage locks on documents.
 * @author Cédric de BOISVILLIERS
 * @since 14/12/2018
 * @version 1.0
 */
export const documentLockMixin = {
    beforeRouteEnter(to, from, next) {
        const documentId = to.params.id;
        if(!!documentId) {
            const checkDocumentAvailability = async () => axios.get(`/document/${documentId}/availability`)
                .then(() => next())
                .catch(() => {
                    next(false);
                });
            checkDocumentAvailability();
        } else {
            next();
        }
    },
    beforeRouteUpdate(to, from, next) {
        const previousDocumentId = from.params.id;
        if(!!previousDocumentId && to.params.idDcoument !== previousDocumentId) {
            this.unlockDocument(previousDocumentId);
        }
        next();
    },
    beforeRouteLeave(to, from, next) {
        const previousDocumentId = from.params.id;
        if(!!previousDocumentId && to.name !== "login" ) {
            this.unlockDocument(previousDocumentId);
        }
        next();
    },
    methods: {
        /**
         * Try to unlock a document locked by the current user
         * @param {int} documentId 
         */
        unlockDocument(documentId) {
            axios.get(`/document/${documentId}/unlock`);
        }
    }
}