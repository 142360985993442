<template>
  <v-dialog v-model="dialogViewALink" fullscreen persistent>
    <v-card>
      <v-card-text>
        <v-layout column class="dialog_view_a_link">
          <v-flex v-if="displaySpinner">
            <v-progress-circular
                style="margin-top:200px; margin-right: 50%; margin-left: 50%;"
                :size="70"
                :indeterminate="true"
                color="primary"
            ></v-progress-circular>
          </v-flex>
          <div class="head-style">
            <div class="floating_button_settings">
              <div class="margin-button-settings">
                <v-tooltip right>
                  <v-btn color="red--text" fab small class="btn-save" slot="activator" @click="close">
                    <v-icon>arrow_back</v-icon>
                  </v-btn>
                  <span>{{ $t('global.action.back') }}</span>
                </v-tooltip>
              </div>
            </div>
            <v-flex v-if="!!title && !displaySpinner" class="bol-title-style">
              <p class="display-1 primary--text align_text">{{ title }}</p>
            </v-flex>
          </div>
          <v-flex v-if="!!node" id="viewLink">
            <span class="contentNode" v-if="node.type === TEXT" v-html="node.content" :id="node.id"></span>
            <overview-document-node :rootNode="node.children"/>
          </v-flex>
        </v-layout>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import * as $ from 'jquery';
import {mapState} from 'vuex';
import axios from 'axios';
import ConstantTag from '../../../../shared/constant/constantTag.js';
import EventBus from '../../../../utils/event-bus.js';
import OverviewDocumentNode from '../../overview/OverviewDocumentNode.vue';
import ConstantEvent from '../../../../shared/constant/constantEvent.js';

const UPDATE_DIALOG = 'update:dialog';
/**
 * Create or Edit a Document
 *
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 20/11/2018
 */
export default {
  name: 'DialogViewALink',
  props: {
    dialog: {
      default: false
    },
    viewId: {
      default: null
    },
    viewNodeId: {
      default: null
    }
  },
  data() {
    return {
      displaySpinner: false,
      node: [],
      isLoaded: false,
      title: ''
    }
  },
  computed: {
    ...mapState({
      documentId: state => state.documentContent.documentId,
      rootNode: state => state.documentContent.rootNode
    }),
    /**
     * We watch the selector who close and open the dialog
     * Depending on it's value we load or reset the data related to the display of the document
     */
    dialogViewALink: {
      get() {
        if (!this.dialog) {
          this.close();
        } else {
          if (parseInt(this.viewId) === this.documentId) {
            if (!!this.viewNodeId) {
              this.isLoaded = true;
            } else {
              this.node = this.rootNode;
              this.isLoaded = true;
            }
          } else {
            this.loadDocumentContent();
            this.loadDocumentTitle();
            this.isLoaded = true;
          }
        }
        return this.dialog;
      },
      set(dialog) {
        if (!dialog) {
          this.$emit(UPDATE_DIALOG, false);
        }
      }
    },
    TEXT() {
      return ConstantTag.TEXT;
    },
  },
  updated() {
    /**
     * if all the data related to the document are loaded we scroll to the concerned node
     */
    if (this.isLoaded) {
      this.$nextTick(() => {
        if (!!this.title && !!this.viewNodeId) {
          this.scrollToSelectedNodeById(this.viewNodeId);
        }
      })
    }
  },
  methods: {
    /**
     * Load the title of the document
     */
    loadDocumentTitle() {
      axios.get(`datasheet/last/`, {
        params: {
          reference: this.viewId
        }
      }).then(({data}) => {
        this.title = data.title;
      })
    },
    /**
     * Load the content of the document
     */
    loadDocumentContent() {
      this.displaySpinner = true;
      axios.get(
          `link/document/reference/${this.viewId}`
      ).then(result => {
        this.displaySpinner = false;
        this.node = result.data.content;
      });
    },
    /**
     * Scroll to the selected node
     * @param selectedNode
     */
    scrollToSelectedNodeById(selectedNodeById) {
      let container = $('#viewLink');
      let anchorReferenceElement = $(`#${selectedNodeById}`);
      if (!!container[0] && !!anchorReferenceElement[0]) {
        if (!!anchorReferenceElement && !!anchorReferenceElement.offset()) {
          container.animate({
            scrollTop: anchorReferenceElement.offset().top - container.offset().top + container.scrollTop()
          });
        }
      }
    },
    /**
     * Reset the data and close the dialog
     */
    close() {
      this.node = [];
      this.title = '';
      this.$emit('onClose');
      EventBus.$emit(ConstantEvent.SHOW_BACK_ARROW_FOR_POPUP);
      this.$emit(UPDATE_DIALOG, false);
    }
  },
  components: {
    OverviewDocumentNode
  }

}
</script>
<style scoped>
#viewLink {
  height: calc(100vh - 48px);
  margin-top: 50px;
  padding-left: 40px;
  padding-right: 40px;
  overflow-y: auto;
  text-align: left;
}

.bol-title-style {
  margin-top: 25px;
  position: fixed;
}

.head-style {
  width: 100%;
  height: 80px;
  margin-top: -50px;
  position: fixed;
  background-color: white;
}

.floating_button_settings {
  z-index: 999;
}

.margin-button-settings {
  margin-top: 5px;
}

.dialog_view_a_link {
  margin-top: 100px;
}

.align_text {
  text-align: center;
}
</style>
