 <template>
    <v-form ref="formModificatorFilter" v-model="valid" lazy-validation>
        <v-container grid-list-xl text-xs-center>
            <v-layout row wrap>
                
                <v-flex v-for="(reference, index) in references" :key="index" xs12 sm2 md2>
                    <v-text-field 
                        v-model="reference.value"
                        :label="$t('document.consolidation.filter.reference')"
                        clearable
                        @keyup.enter="search()"/>
                </v-flex>
                <v-flex xs12 sm2 md1>
                    <v-btn 
                        fab
                        dark
                        small
                        color="primary"
                        :disabled="references[references.length-1].value === ''"
                        @click.native="add()">
                        <v-icon dark >add</v-icon>
                    </v-btn>
                </v-flex>
                
           
                <v-layout row wrap>
                    <v-flex xs12 sm2 md5>
                        <v-text-field 
                            v-model="filter.title"
                            :label="$t('document.consolidation.filter.title')"
                            clearable
                            @keyup.enter="search()"/>
                    </v-flex>
                    <v-flex xs12 sm2 md5>
                        <v-select
                            v-model="filter.documentTypeId"
                            :label="$t('document.consolidation.filter.type')"                 
                            :items="documentTypeList"
                            item-value="id"
                            item-text="label"
                            clearable
                            @keyup.enter="search()"
                        />
                    </v-flex>
                    <v-flex>
                        <v-btn
                            fab
                            dark
                            small
                            color="primary"
                            @click.native="search()">
                            <v-icon dark >search</v-icon>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-layout>
        </v-container>
    </v-form>
</template>

<script>
import axios from 'axios'
/**
 * Filter to find document to link to the document we try to consolidate
 * 
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 19/02/2019
 */
export default {
    name: 'ModificatorDocumentFilter',
    props: {
        removeReference: null
    },
    data(){
        return {
            valid: false,
            formConsolidationFilter: null,
            references: [
                    {
                        value: ''
                    }
                ],
            filter: {
                references: [],
                title: '',
                documentTypeId: '',
            }, 
            consolidationFilter: null,
            documentTypeList: [],
        }
    },
    created(){
        this.loadDocumentTypeList();
    },
    methods: {
        /**
         * Load the list of document type 
         */
        loadDocumentTypeList(){
            axios.get('document/search/init').then( result => {
                this.documentTypeList = result.data.documentTypeList;
            });
        },
        /**
         * Send the filters to the parent component
         */
        search(){
            if(this.$refs.formModificatorFilter.validate()){
                this.filter.references = [];
                this.references.forEach(ref => {
                    if(!!ref.value){
                        this.filter.references.push(ref.value);
                    }
                });
                this.$emit('consolidationFilter', this.filter);
            }
        },
        add(){
            this.references.push({value: ''});
        }
    },
    watch: {
        removeReference() {
            if (this.removeReference) {
                this.references = [
                    {
                        value: ''
                    }
                ];
            }
        },
    }
}
</script>

<style>

</style>
