<template>
    <v-layout class="pao" fill-height>
        <router-view />
        <dialog-add-content />
    </v-layout>
</template>

<script>
import DialogAddContent     from '../pao/structure/dialog/DialogAddContent';
import ConstantEvent        from '../../shared/constant/constantEvent';
/**
 * 
 * Main content structure Pao.
 * @author Cédric de BOISVILLIERS, SFEIR Benelux
 * @version 1.0
 * @since 17/05/2019
 */
export default {
    name: 'Pao',
    data() {
        return {
            ConstantEvent: ConstantEvent
        }
    },
    components: {
        DialogAddContent
    }
}
</script>