
export default {
    namespaced: true,
    /**
     * State of the document content store
     */
    state:{
        sourceNode:{
            id: null,
            selectedContent: null,
            selectedNode: null,
            caretPosition: {
                clickedNode: null,
                clickedNodeOffset: 0,
                clickedFocusNodeOffset: 0,
                isCrossSelection: false,
                focusNode: null
            },
        },

        documentHyperLink: {
            referenceDocumentDestination: null,
            idNodeDestination: null,
            relationType: null,
            hyperLinkType: null,
            hyperLinkSubType: null,
            urlEntered: null
        }
    },
    /**
     * Actions available to the document content store
     */
    actions:{
        initSourceNode({commit}, data) {    
            commit('setSourceNode', data);
        },
        initExternalHyperLinkData({commit}, {url, hyperLinkSubType}){
            commit('setUrlEnteredAndSubType', {url:url, subType: hyperLinkSubType});
        },
        setReferenceDocumentDestinationAndRelationType({commit}, data){
            commit('setReferenceDocumentDestinationAndRelationType', data);
        },
        setIdNodeDestination({commit}, data){
            commit('setIdNodeDestination', data );
        },
        updateHyperLinkType({commit}, data){
            commit('update_hyperlink_type', data);
        },
        reset({commit}){
            commit('reset');
        }
    },
    /**
     * Mutations
     * 
     */
    mutations:{
        setUrlEnteredAndSubType(state, {url, subType}){
            state.documentHyperLink.urlEntered = url;
            state.documentHyperLink.hyperLinkSubType = subType
        },
        setSourceNode(state, data){
            state.sourceNode.id = data.nodeId;
            state.sourceNode.selectedContent = data.selectedContent;
            state.sourceNode.selectedNode = data.selectedNode;
            state.sourceNode.caretPosition.clickedNode = data.clickedNode;
            state.sourceNode.caretPosition.isCrossSelection = data.isCrossSelection;
            state.sourceNode.caretPosition.focusNode = data.focusNode;
            
            if( !data.isCrossSelection ){
                if( data.clickedFocusNodeOffset < data.clickedNodeOffset){
                    state.sourceNode.caretPosition.clickedNodeOffset = data.clickedFocusNodeOffset; 
                    state.sourceNode.caretPosition.clickedFocusNodeOffset = data.clickedNodeOffset;
                } else {
                    state.sourceNode.caretPosition.clickedNodeOffset = data.clickedNodeOffset;
                    state.sourceNode.caretPosition.clickedFocusNodeOffset = data.clickedFocusNodeOffset;
                }
            } else {
                state.sourceNode.caretPosition.clickedNodeOffset = data.clickedNodeOffset; 
                state.sourceNode.caretPosition.clickedFocusNodeOffset = data.clickedFocusNodeOffset;
            }
            
            state.documentHyperLink.hyperLinkType = data.hyperLinkType;
        },
        setReferenceDocumentDestinationAndRelationType(state, data){
            state.documentHyperLink.referenceDocumentDestination = data.referenceDocumentDestination;
            state.documentHyperLink.relationType = data.relationType;
        },
        setIdNodeDestination(state, data){
            state.documentHyperLink.idNodeDestination = data.idNodeDestination;
        },
        update_hyperlink_type(state, data){
            state.documentHyperLink.hyperLinkType = data.typeHyperlink;
        },
        reset(state){
            state.sourceNode.id = null;
            state.sourceNode.selectedContent = null;
            state.sourceNode.caretPosition.clickedNode = null;
            state.sourceNode.caretPosition.clickedNodeOffset = 0;
            state.sourceNode.caretPosition.clickedFocusNodeOffset = 0;

            state.documentHyperLink.referenceDocumentDestination = null;
            state.documentHyperLink.relationType = null;
            state.documentHyperLink.idNodeDestination = null;
            state.documentHyperLink.hyperLinkType = null;
            state.documentHyperLink.hyperLinkSubType = null;
            state.documentHyperLink.urlEntered = null;
        }
    }
}