<template>
    <v-content>
        <v-container
            fluid
            fill-height
            class="t_login_dialog"
        >
            <v-layout column>
                <v-flex xs2>
                    <v-alert
                        :value="error"
                        type="error"
                    >
                        {{ message }}
                    </v-alert>
                </v-flex>
                <v-flex
                    xs
                    8
                >
                    <v-layout
                        align-center
                        justify-center
                    >
                        <v-flex
                            xs12
                            sm8
                            md5
                        >
                            <v-card class="title pa-5 elevation-12">
                                <v-card-text>
                                    <v-form v-model="valid"> 
                                        <v-text-field
                                            v-model="username"
                                            class="t_username"
                                            prepend-icon="person"
                                            :label="$t('login.label.login')"
                                            :rules="loginRules"
                                            required
                                            @keyup.enter="sendLogin()"
                                        />
                                        <v-text-field
                                            v-model="password"
                                            class="t_password"
                                            prepend-icon="lock"
                                            :label="$t('login.label.password')"
                                            :rules="passwordRules"
                                            :type="'password'"
                                            required
                                            @keyup.enter="sendLogin()"
                                        />
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer />
                                    <v-btn
                                        class="t_login"
                                        round
                                        color="primary"
                                        @click="sendLogin()"
                                    >
                                        {{ $t("login.action.connect") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-container>
    </v-content>
</template>
<script>
import axios from 'axios';
import querystring from 'querystring';

export default ({
    name: 'Login',
    data () {
        return{
            valid: false,
            username: "",
            loginRules: [
                v => !!v || this.$t("login.error.required.login")
            ],
            passwordRules: [
                v => !!v || this.$t("login.error.required.password")
            ],
            password: "",
            message: "",
            error: false
        }
    },
    methods: {
        sendLogin(){
            // We have to stringify the data because of Spring Security module
            var data = querystring.stringify({ 
                "j_username": this.username, 
                "j_password": this.password
            });
            axios.post(
                '/authentication', 
                data,
                {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
            ).then( () => {
                this.$router.push('/dashboard');
            }, () => {
                this.error= true;
                this.message = this.$t('login.error.credentials.bad');
            });
        }
    }    
})
</script>

