<template>
    <v-dialog v-model="openDialog" persistent max-width="350px">
        <v-card>
            <v-card-title class="headline">
                {{$t('structure.node.action.choose')}}
            </v-card-title>
            <v-card-text>
                <v-item-group v-model="header" class="bol-radio-group"> 
                    <v-layout class="bol-row-radio-group">
                        <v-item v-for="n in 6" :key="n">
                            <v-layout align-center justify-center class="bol-row-item-radio-group bol-row-item-with-radio-group" slot-scope="{ active, toggle }">
                            <v-icon :color="active ? 'primary' : ''" @click.native="toggle" class="mr-2">
                                {{ active ? 'radio_button_checked' : 'radio_button_unchecked' }}
                            </v-icon>
                                h{{ n }}
                            </v-layout>
                        </v-item>
                    </v-layout>
                </v-item-group>
                <v-divider></v-divider>
                    <v-item-group v-model="subSupScript">
                        <v-layout>
                            <v-item value="sub">
                                <v-layout align-center justify-center class="bol-row-item-radio-group" slot-scope="{ active, toggle }">
                                <v-icon :color="active ? 'primary' : ''" @click.native="toggle" class="mr-2">
                                    {{ active ? 'radio_button_checked' : 'radio_button_unchecked' }}
                                </v-icon>
                                {{ $t('structure.checkbox.subscript') }}
                                </v-layout>
                            </v-item>
                            <v-item value="sup">
                                <v-layout align-center justify-center class="bol-row-item-radio-group" slot-scope="{ active, toggle }">
                                <v-icon :color="active ? 'primary' : ''" @click.native="toggle" class="mr-2">
                                    {{ active ? 'radio_button_checked' : 'radio_button_unchecked' }}
                                </v-icon>
                                {{ $t('structure.checkbox.superscript') }}
                                </v-layout>
                            </v-item>
                        </v-layout>
                    </v-item-group>
                <v-divider></v-divider>
                <div class="bol-checkbox-group"> 
                    <v-layout justify-center align-center class="bol-checkbox-group-row">
                        <v-checkbox v-model="styleBIUO" :label="$t('structure.checkbox.bold')" value="strong" class="bol-format-checkbox bol-format-checkbox-first-child"></v-checkbox>
                        <v-checkbox v-model="styleBIUO" :label="$t('structure.checkbox.italic')" value="em" class="bol-format-checkbox"></v-checkbox>
                        <v-checkbox v-model="styleBIUO" :label="$t('structure.checkbox.underline')" value="span-unl" class="bol-format-checkbox bol-format-checkbox-first-child"></v-checkbox>
                        <v-checkbox v-model="styleBIUO" :label="$t('structure.checkbox.overline')" value="span-lth" class="bol-format-checkbox"></v-checkbox>
                    </v-layout>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" flat @click.native="close();">{{$t('document.action.cancel')}}</v-btn>
                <v-btn color="primary" flat @click.native="addHtmlElement();">{{$t('document.action.add')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import EventBus                 from '../../../../utils/event-bus';
import FormatTextService        from '../../../../service/document/formatTextService.js';
import DomManipulatorService    from '../../../../service/html/domManipulatorService';
import ConstantEvent            from '../../../../shared/constant/constantEvent.js';
import ConstantDocumentNode     from '../../../../shared/constant/constantDocumentNode.js';
import { mapActions }           from 'vuex';

const UPDATE_DIALOG             = 'update:dialog';
/**
 * 
 * Dialog to choose which element will frame the selected text
 * @author WILMET Justin, SFEIR benelux
 * @version 1.0
 * @since 04/12/2018
 * 
 */
export default {
    name: 'DialogFormatText',
    props: {
        storeName: {
            default: null
        },
        dialog: {
            default: false
        },
        position: {
            selectedText: null,
            clickedNode: null,
            clickedNodeOffset: 0,
            clickedFocusNodeOffset: 0,
            focusNode: null
        }
    },
    data(){
        return {
            header: null,
            subSupScript: null,
            styleBIUO: []
        }
    },
    computed: {
        selectedNode(){
            return this.$store.state[`${this.storeName}`].selectedNode;
        },
        openDialog: {
            get(){
                return this.dialog;
            },
            set( dialog ){
                if( !dialog ){
                    this.$emit(UPDATE_DIALOG, false);
                }
            }
        }
    },
    methods: {
        /**
         * Method to update the content of a node depending on which store to use
         */
        modifyContent( nodeContent ) {
            this.$store.dispatch(`${this.storeName}/modifyContent`, nodeContent);
        },
        setSelectedNodeContent( nodeContent ) {
            this.$store.dispatch(`${this.storeName}/setSelectedNodeContent`, nodeContent);
        },
        /**
         * Create the HTML format around the selected text and update it the store
         */
        addHtmlElement(){ 
            typeof(this.header) === 'number' ? this.header = `h${this.header+1}` : null; 
            
            let htmlElement = FormatTextService.$formatSelectedText({nodeContent: this.position.selectedText, header: this.header , styleBIUO: this.styleBIUO, subSupScript: this.subSupScript });
            
            let htmlContent = DomManipulatorService.replaceTextByHTMLElementInNode(
                    this.position.clickedNode,
                    this.position.clickedNodeOffset,
                    this.position.clickedFocusNodeOffset,
                    htmlElement,
                    ConstantDocumentNode.TEXT_NODE_CLASS,
                    this.position.focusNode );

            this.$emit('format', htmlContent );
            this.close();
        },
        /**
         * Close the dialog and reset the 'form'
         */
        close(){
            this.header = null;
            this.styleBIUO = [];
            this.subSupScript = null;
            this.$emit(UPDATE_DIALOG, false)
        }
    }
}
</script>
<style scoped>
.bol-radio-group{
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}
.bol-row-radio-group{
    flex-wrap: wrap;
    width: 293px;
}
.bol-row-item-radio-group{
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 16px; 
    padding-top: 4px;
    color: rgba(0,0,0,.54);
}
.bol-row-item-with-radio-group{
    width: 97px; 
}
.bol-format-checkbox{
    width: 80px;
    margin-left:50px;
}
.bol-format-checkbox-first-child{
    margin-left: auto;
    margin-right: auto;
}
.bol-checkbox-group{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.bol-checkbox-group-row{
    width: 250px;
    flex-wrap: wrap;
}
</style>
    
