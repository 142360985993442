<template>
    <router-view>
    </router-view>
</template>

<script>

export default {
    name: 'Book'
}

</script>