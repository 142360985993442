import ConstantBulletStyle          from '../constant/constantBulletStyle';
import ConstantNode                 from '../constant/constantNode';
import ConstantTag                  from '../constant/constantTag';
import FilledDot                    from '../../assets/filled-dot.png';
import FilledSquare                 from '../../assets/filled-square.png';
import OutlineDot                   from '../../assets/outline-dot.png';

/**
 * Helper to create Object based on constant and depending on the calling class
 * @author Justin WILMET
 * @since 31/05/2019
 * @version 1.0
 */
export default class BulletStyleFactory{

    static create( classType ){
        switch (classType){
            case ConstantBulletStyle.DASH_STYLE_CLASS:
                return {
                    style: ConstantBulletStyle.DASH_STYLE,
                    type: ConstantNode.UNORDERED,
                    class: ConstantBulletStyle.DASH_STYLE_CLASS
                };
            case ConstantBulletStyle.STAR_STYLE_CLASS:
                return {
                    style: ConstantBulletStyle.STAR_STYLE,
                    type: ConstantNode.UNORDERED,
                    class: ConstantBulletStyle.STAR_STYLE_CLASS
                };
            case ConstantBulletStyle.FILLED_DOT_STYLE_CLASS:
                return {
                    style: FilledDot,
                    type: ConstantTag.IMG,
                    icon: ConstantBulletStyle.FILLED_DOT_ICON,
                    class: ConstantBulletStyle.FILLED_DOT_STYLE_CLASS
                };
            case ConstantBulletStyle.FILLED_SQUARE_STYLE_CLASS:
                return {
                    style: FilledSquare,
                    type: ConstantTag.IMG,
                    icon: ConstantBulletStyle.FILLED_SQUARE_ICON,
                    class: ConstantBulletStyle.FILLED_SQUARE_STYLE_CLASS
                };
            case ConstantBulletStyle.OUTLINED_DOT_STYLE_CLASS:
                return {
                    style: OutlineDot,
                    type: ConstantTag.IMG,
                    icon: ConstantBulletStyle.OUTLINED_DOT_ICON,
                    class: ConstantBulletStyle.OUTLINED_DOT_STYLE_CLASS
                };
            case ConstantBulletStyle.NUMBER_STYLE_CLASS:
                return {
                    style: ConstantBulletStyle.DOT_NUMBER_STYLE,
                    type: ConstantNode.ORDERED,
                    class: ConstantBulletStyle.NUMBER_STYLE_CLASS
                };
            case ConstantBulletStyle.UPPER_ALPHA_STYLE_CLASS:
                return {
                    style: ConstantBulletStyle.ALPHA_STYLE,
                    type: ConstantNode.ORDERED,
                    isUpperCase: true,
                    class: ConstantBulletStyle.UPPER_ALPHA_STYLE_CLASS
                };
            case ConstantBulletStyle.LOWER_ALPHA_STYLE_CLASS:
                return {
                    style: ConstantBulletStyle.ALPHA_STYLE,
                    type: ConstantNode.ORDERED,
                    isUpperCase: false,
                    class: ConstantBulletStyle.LOWER_ALPHA_STYLE_CLASS
                };
            case ConstantBulletStyle.UPPER_ROMAN_STYLE_CLASS:
                return {
                    style: ConstantBulletStyle.ROMAN_STYLE,
                    type: ConstantNode.ORDERED,
                    isUpperCase: true,
                    class: ConstantBulletStyle.UPPER_ROMAN_STYLE_CLASS
                };
            case ConstantBulletStyle.LOWER_ROMAN_STYLE_CLASS:
                return {
                    style: ConstantBulletStyle.ROMAN_STYLE,
                    type: ConstantNode.ORDERED,
                    isUpperCase: false,
                    class: ConstantBulletStyle.LOWER_ROMAN_STYLE_CLASS
                };
            default: 
                return {
                    style: ConstantBulletStyle.DASH_STYLE,
                    type: ConstantNode.UNORDERED,
                    class: ConstantBulletStyle.DASH_STYLE_CLASS
                };
        }
    }
}