<template>
  <v-dialog v-model="openDialog" persistent max-width="450px">
    <v-card>
      <v-card-title class="headline">{{ $t('structure.node.action.importFiles') }}</v-card-title>
      <v-card-text v-show="!isContentUpload">
        <v-layout justify-center>
          <input type="file" id="file" accept="image/*, .pdf, .xml" ref="file" @change="handleFileUpload"/>
        </v-layout>
        <resize-picture v-if="isPicture" @onChangeWidthHeight="changeWidthHeight"/>
      </v-card-text>
      <v-card-text v-if="isContentUpload" class="warning-upload">
        {{ $t('document.notification.import.warning') }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" flat @click.native="close();">{{ $t('document.action.cancel') }}</v-btn>
        <v-btn color="primary" v-if="!isContentUpload" :disabled="file === null" flat @click.native="submitFile();">
          {{ $t('document.action.add') }}
        </v-btn>
        <v-btn color="primary" v-if="isContentUpload" flat @click.native="submitFile();">
          {{ $t('document.action.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import ConstantEvent from '../../../../shared/constant/constantEvent.js';
import ConstantNotification from '../../../../shared/constant/constantNotification.js';
import ConstantUploadOperationType from '../../../../shared/constant/constantUploadOperationType.js';
import EventBus from '../../../../utils/event-bus';
import ResizePicture from '../components/ResizePicture';

const BASE_UPLOAD_URL = '/file/';
const BASE_READ_URL = '/api/file/';
const UPDATE_DIALOG = 'update:dialog';

/**
 *
 * Dialog to choose a file to join to the document
 * @author WILMET Justin, SFEIR benelux
 * @version 1.0
 * @since 22/01/2019
 *
 */
export default {
  name: 'DialogImportFiles',
  props: {
    documentId: {
      default: null
    },
    operationType: {
      default: null
    },
    dialog: {
      default: false
    }
  },
  /*
    Defines the data used by the component
  */
  data() {
    return {
      file: null,
      source: '',
      mimeType: '',
      name: '',
      uid: null,
      isPicture: false,
      width: 'auto',
      height: 'auto',
      isContentUpload: false
    }
  },
  components: {
    ResizePicture
  },
  computed: {
    openDialog: {
      get() {
        return this.dialog;
      },
      set(dialog) {
        if (!dialog) {
          this.$emit(UPDATE_DIALOG, false);
        }
      }
    },
    isAttachedContent() {
      return this.operationType === ConstantUploadOperationType.ATTACHED_CONTENT_OPERATION;
    }
  },
  methods: {
    /**
     * Handle the file upload
     */
    handleFileUpload() {
      this.isContentUpload = this.operationType === ConstantUploadOperationType.CONTENT_IMPORT_OPERATION;
    },
    /**
     * Submits the file to the server
     */
    submitFile() {
      let formData = new FormData();
      formData.append('file', this.file);
      if (this.operationType === ConstantUploadOperationType.CONTENT_IMPORT_OPERATION) {
        this.submitFileContent(formData);
      } else if (this.isAttachedContent) {
        this.submitAttachedFile(formData);
      }
      this.close();
    },
    /**
     *  Handles a change on the file upload
     */
    handleFileUpload(event) {
      this.file = this.$refs.file.files[0];
      this.isPicture = this.file.type.includes('image');
      this.mimeType = this.file.type;
      this.name = this.file.name.replace(/\.[^/.]+$/, "");
    },
    changeWidthHeight(data) {
      this.width = data.width;
      this.height = data.height;
    },
    /**
     * Send an attached file to add to the content.
     *
     */
    submitAttachedFile(formData) {
      let uploadUrl, readUrl;
      if (this.isPicture) {
        uploadUrl = '/upload-image';
        readUrl = '/read-image/'
      } else {
        uploadUrl = '/upload-file';
        readUrl = '/read-file/'
      }
      axios.post(
          `${BASE_UPLOAD_URL}${this.documentId}${uploadUrl}`,
          formData,
          {headers: {'Content-Type': 'multipart/form-data'}}
      ).then(response => {
        this.uid = response.data;
        let src = `${BASE_READ_URL}${this.documentId}${readUrl}${this.uid}`;
        if (this.mimeType === 'application/pdf') {
          src += '.pdf'
        }

        this.$emit('onImportFile', {
          source: src,
          name: this.name,
          type: this.mimeType,
          uid: this.uid,
          width: this.width,
          height: this.height
        });
      })
          .catch(() => {
            EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
              type: ConstantNotification.ERROR,
              message: 'document.notification.import.fail',
            })
          });
    }
    ,
    /**
     * Submit a file content to init a document content
     */
    submitFileContent(formData) {
      axios.post(
          `content/${this.documentId}/upload/content`,
          formData,
          {headers: {'Content-Type': 'text/xml'}}
      ).then(response => {
        this.$emit('onImportFile');
      })
          .catch(() => {
            EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
              type: ConstantNotification.ERROR,
              message: 'document.notification.import.fail',
            });
          });
    }
    ,
    /**
     * Close the import dialog
     */
    close() {
      this.$refs.file.value = "";
      this.isPicture = false;
      this.$emit(UPDATE_DIALOG, false);
    }
  }
}
</script>
<style scoped>
.warning-upload {
  font-size: 18px;
}
</style>
