import ConstantTag              from '../../shared/constant/constantTag';
import DocumentTreeService      from '../../service/document/documentTreeService';

/**
 * Manage all the specific operation related to a pao document
 * Specially all the management around the replacement texte
 * 
 * @author Sébastien De Santis
 * @since 09/01/2020
 * @version 1.0
 * 
 */
class PaoDocumentService {

    constructor(){

    }
    /**
     * Return true if the node is available for the replacement text. False otherwise.
     * @param {*} node 
     */
    isNodeAvailableForReplacementText(node){
        return node.label === ConstantTag.ARTICLE || node.label === ConstantTag.GR_ARTICLES || node.label === ConstantTag.ALINEA ;
    }
    /**
     * Create a replacement node 
     * @param {*} node 
     */
    createReplacementNode(node, reference){
        let replacementNode;
        if(node.label === ConstantTag.GR_ARTICLES ){
            replacementNode = this.$createGrArticlesNode(reference, node.id);
        } else if(node.label === ConstantTag.ARTICLE ){
            replacementNode = this.$createArticleNode(reference, node.id);
        } else if(node.label === ConstantTag.ALINEA ){
            replacementNode = this.$createAlineaNode(reference, node.id);
        }

        return replacementNode;
    }
    /**
     * create a gr articles node
     * @param {*} reference 
     * @param {*} nodeId the node id to replace
     */
    $createGrArticlesNode(reference, nodeId){
        let grArticleNode = this.$createStandardNode(ConstantTag.GR_ARTICLES);
        if(!!nodeId){
            grArticleNode.relatedNodeId = nodeId;
        }
        grArticleNode.children.push(this.$createArticleNode(reference));
        return grArticleNode;
    }
    /**
     * create a Article node
     * @param {*} reference 
     * @param {*} nodeId the node id to replace
     */
    $createArticleNode(reference, nodeId){
        let articleNode = this.$createStandardNode(ConstantTag.ARTICLE);
        if(!!nodeId){
            articleNode.relatedNodeId = nodeId; 
        }
        articleNode.children.push(this.$createAlineaNode(reference));
        return articleNode;
    }
    /**
     * Create a alinea node that contain a text node
     * @param {*} reference 
     * @param {*} nodeId the node id to replace
     */ 
    $createAlineaNode(reference, nodeId){
        let alineaNode = this.$createStandardNode(ConstantTag.ALINEA);
        if(!!nodeId){
            alineaNode.relatedNodeId = nodeId;
        }
        alineaNode.children.push(this.$createTextNode(reference));
        return alineaNode;
    }
    /**
     * Create a text node with the replacement text containing the document reference
     * @param {*} reference 
     */
    $createTextNode(reference){
        let textNode = DocumentTreeService.createPatternNode(ConstantTag.TEXT);
        textNode.selected = false;
        textNode.visible = true;
        textNode.content = `<p><b>Note de l'éditeur:</b> <i>à cet endroit, nous avons supprimé un passage peu utile pour le présent ouvrage (modification d'un autre texte, disposition caduque...). Pour retrouver le texte intégral, rendez-vous sur www.lexnow.lu (ID ${reference})</i></p>`;
        return textNode
    }
    $createStandardNode(nodeType){
        let standardNode = DocumentTreeService.createPatternNode(nodeType);
        standardNode.selected = false;
        standardNode.visible = true;
        return standardNode;
    }
}

export default new PaoDocumentService();