<script>
import ConstantDocumentNode         from '../../../../shared/constant/constantDocumentNode';
import domManipulatorService        from '../../../../service/html/domManipulatorService';
import ConstantTag                  from '../../../../shared/constant/constantTag';
import ConstantDocumentType         from '../../../../shared/constant/constantDocumentType';

const TEXT_NODE_CLASSES             = [ConstantDocumentNode.CONTENT_STYLE_CLASS, ConstantDocumentNode.TEXT_NODE_CLASS];

/**
 * Component that display a node with his content.
 * This component is recursive and display each children it may have.
 * 
 * @author Gentilhomme Romain
 * @version 1.1
 * @since 2020-01-07
 */
export default  {
    name: 'ContentNodeLight',
    props: {
        documentType:{
            default:null
        },
        node: {
            required: true
        }
    },
    render: function(createElement) {
        let classesGroup = [];

        if(this.documentType.code === ConstantDocumentType.JURISPRUDENCE && this.node.label === ConstantTag.RESUME ){
            if(!!this.node.keywords && this.node.keywords.length > 0 ) {
                classesGroup = [ConstantDocumentNode.NODE_CONTENT_CLASS,ConstantDocumentNode.NODE_RESUME_GROUP_CLASS];
            }else{
                classesGroup = [ConstantDocumentNode.NODE_CONTENT_CLASS,ConstantDocumentNode.NODE_RESUME_GROUP_CLASS_INVALIDATE];
            }
        }else if(this.documentType.code === ConstantDocumentType.DOCTRINE && this.node.label === ConstantTag.RESUME){
            classesGroup =  [ConstantDocumentNode.NODE_CONTENT_CLASS,ConstantDocumentNode.NODE_RESUME_GROUP_CLASS];
        }else{
            classesGroup = [ConstantDocumentNode.NODE_CONTENT_CLASS];
        }


        return createElement ('button',
            {
                attrs: {
                    id: `${ConstantDocumentNode.NODE_CONTENT_CLASS}-${this.node.id}`
                },
                class: classesGroup
            },
            [this.getNodeElementList(createElement)]
        );
    },
    computed: {
        nodeContent() {
            return this.node.content;
        },
        nodeContentChildren() {
            return domManipulatorService.extractNodesFromHTML(this.node.content);
        }
    },
    methods: {
        /**
         * Return a list of Vue Vnodes to nest in the root Vnode of the component
         */
        getNodeElementList(createElement) {
            return [this.getContentElement(createElement), ...this.getNodeChildrenElement(createElement)];
        },
        /**
         * Return a Vue Vnode with either the node type or the content
         */
        getContentElement(createElement) {
            let contentElement;
            if(this.node.type !== ConstantDocumentNode.TEXT_TYPE) {
                contentElement = this.getNodeTypeElement(createElement);
            } else {
                contentElement = this.getNodeContentTextElement(createElement);
            }
            return contentElement;
        },
        /**
         * Return a Vue Vnode with the document node type
         */
        getNodeTypeElement(createElement) {
            let keywords;
            let keywordsList;
            if( !!this.node.keywords && this.node.keywords.length > 0 && this.node.path[this.node.path.length - 1] === ConstantTag.RESUME){
                keywords = createElement('v-icon', {
                        class:'node-keyword',
                        domProps: {
                            innerHTML: 'vpn_key'
                        },
                        style: {
                            "font-size": "20px",
                            "margin-left": "5px"
                        }
                });
                keywordsList = createElement('div', {
                    class:'label-keyword',
                    domProps: {
                        innerHTML: this.getInnerHTMLKeywordsList(),
                    },
                    style: {
                        "font-size": "1em",
                        "margin-right":"5px",
                        "margin-top":"15px",
                        "margin-bottom":"40px"
                    }
                });
            }
            let lang;
            if( !!this.node.lang ){
                lang = createElement('v-icon', {
                    class: 'node-lang',
                    domProps: {
                        innerHTML: this.node.lang
                       }
                })
            }
            let icon;
            if( !this.$isNodeVisible() ){
                icon = createElement('v-icon', {
                    domProps: {
                        innerHTML: 'visibility_off'
                    },
                    style: {
                        "font-size": "20px",
                        "margin-left": "5px",
                        "margin-right": "5px"
                    }
                })
            } else {
                icon = createElement('v-icon',{
                    domProps: {
                        innerHTML: 'arrow_drop_down'
                    }
                })
            }

            let replacementText;
            if(!!this.node.replacementText){
                replacementText = createElement('span', {
                    domProps: {
                        innerHTML: `&nbsp;->&nbsp;${this.node.replacementText}`
                    }
                })
            }
            let classesToElement = null;
            if(this.documentType.code === ConstantDocumentType.JURISPRUDENCE && this.node.label === ConstantTag.RESUME ){
                if(!!this.node.keywords && this.node.keywords.length > 0 ) {
                    classesToElement = [ConstantDocumentNode.NODE_TYPE, ConstantDocumentNode.NODE_RESUME_CLASS];
                }else{
                    classesToElement = [ConstantDocumentNode.NODE_TYPE, ConstantDocumentNode.NODE_RESUME_CLASS_INVALIDATE];
                }
            }else if(this.documentType.code === ConstantDocumentType.DOCTRINE && this.node.label === ConstantTag.RESUME){
                classesToElement =  [ConstantDocumentNode.NODE_TYPE,ConstantDocumentNode.NODE_RESUME_CLASS];
            }
            else {
                classesToElement =  ['bol-node-type'];
            }

            return createElement('button',
                {class: classesToElement},
                [
                    icon,
                    this.node.label,
                    lang,
                    keywords,
                    keywordsList,
                    replacementText
                ]
            );
        },
        /**
         * Return a Vue Vnode with the document node content
         */
        getNodeContentTextElement(createElement) {
            let htmlElementList = [];

            this.nodeContentChildren.forEach( domNode => {
                htmlElementList.push(this.$getElementVNode(domNode, createElement));
            });

            return createElement('div', {class: TEXT_NODE_CLASSES}, htmlElementList);
        },
        getInnerHTMLKeywordsList(){

            let keywordBlock = "";
            let index = 0;
            let level = 0;

            for(;index < this.node.keywords.length;index++){
                if(level === 0 || level > 2){
                    level = 0;
                    keywordBlock += `<div style="background-color: rgba(91, 162, 217, 0.14);display:inline-block;text-align: center;margin-left:5px;width:150px;border-radius:5px;">`;
                }
                keywordBlock += `${this.node.keywords[index].label}   <br>`;
                if(level === 2) {
                    keywordBlock += `</div>`;
                }
                level++;
            }
            return keywordBlock;
        },
        /**
         * return a list of Vue Vnodes with the child Nodes of the current document node
         */
        getNodeChildrenElement(createElement) {
            let childrenElementList = []
            if(!!this.node.children) {
                if( this.$isNodeVisible() ){
                    childrenElementList.push(...this.node.children.map(childNode => createElement('div',
                        { key: childNode.id },
                        [
                            createElement('content-node-light', {
                                props: {
                                    node: childNode,
                                    documentType: this.documentType
                                }
                            })
                        ]
                    )));
                }
            }
            return childrenElementList;
        },
        /**
         * Return true or false depending on if the visibility of the node is present or not and if it's set to true or false.
         */
        $isNodeVisible( ){
            if( this.node.visible !== undefined && this.node.visible !== null ) {
                return this.node.visible 
            } else {
                return true;
            }
        },
        /**
         * return a list of Vue Vnodes from part of the content node without table
         */
        $getElementVNode(domNode, createElement) {
            if (domNode.nodeType === Node.ELEMENT_NODE) {
                let elementAttributes = domNode.attributes;
                let attributes = {};
                for (const attr of elementAttributes) {
                    if(!attr.name.startsWith('data-v-')) {
                        attributes[attr.name] = attr.value;
                    };
                }
                return createElement(domNode.tagName, {
                    attrs: attributes,
                    class: elementAttributes.class ? elementAttributes.class.textContent : null,
                    domProps: {
                        innerHTML: domNode.innerHTML
                    }
                });
            } else if(domNode.nodeType === Node.TEXT_NODE) {
                return domNode.textContent;
            }
        }
    }
}
</script>
<style>
.custom-footnote-index{
    vertical-align: super;
    font-size: smaller;
}
</style>
<style scoped>
    .bol-node-resume  {
        color: black;
        cursor:pointer;
        padding:10px;
        border: dashed 1px #355B7F;
        border-radius:10px;

    }
    .bol-node-resume-invalidate  {
        color: gray;
        padding:10px;
        border: dashed 1px #c06a60;
        border-radius:10px;
    }
    .bol-node-resume-group  {
        color: black;
        padding:10px;
        border: dashed 1px #355B7F;
        border-radius:10px;
    }

    .bol-node-resume-group:hover {
        background-color:rgba(91, 162, 217, 0.14);
        border-radius:10px;
    }
    .bol-node-resume-group:focus{
        outline-width:1px;
        background-color:rgba(91, 162, 217, 0.14);
        border-radius:10px;
    }

    .node-keyword{
          font-size: 16px;
          margin-left: 5px;
      }
    .node-lang{
    font-size: 8px;
    margin-left: 5px;
    font-style:italic;
    color:grey;
    }
.bol-node-content{
    margin-left: 10px;
    margin-right: 10px;
    cursor:initial;
    text-align: left;

}

.bol-node-content .bol-node-type {
    color: darkgray;
    margin-left: 15px;
    font-size: smaller;
    width:95%;
    text-align: left;
}
    .bol-node-content:focus{
        outline-width:0px;
    }

.bol-style-content{
    min-height: 15px;
}
.bol-text-node{
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 7px;
    word-break: break-word;
    height: 100%;
}
.bol-online-blockquote{
    border-left: solid 2px #bdbdbd;
    padding: 0 1em;
    margin: 1em;
}
.label-keyword{
    width:100%;
    font-size: 0.8em;
}
</style>


