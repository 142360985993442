<template>
    <v-layout
        row
        wrap
    >
        <v-layout
            v-if="displaySpinner"
            align-center
            justify-center
            row
        >
            <v-progress-circular
                :size="50"
                :indeterminate="true"
                color="primary"
                class="spinner_doc"
            />
        </v-layout>
        <v-container
            v-if="displayTab"
            ma-0
            pa-0
            fluid
        >
            <v-data-table
                :headers="[]"
                :items="documentList"
                disable-initial-sort
                hide-actions
                pagination.sync="pagination"
            >
                <template
                    slot="headers"
                    slot-scope="props"
                >
                    <th>{{ $t('document.label.reference') }}</th>
                    <th>{{ $t('document.label.title') }}</th>
                    <th>{{ $t('document.label.workspace') }}</th>
                    <th>{{ $t('document.label.type') }}</th>
                    <th>{{ $t('document.label.documentDate') }}</th>
                    <th>{{ $t('document.label.actions') }}</th>
                </template>

                <template
                    slot="items"
                    slot-scope="props"
                >
                    <td>{{ props.item.reference }}</td>
                    <td>{{ props.item.title }}</td>
                    <td>{{ props.item.workspace }}</td>
                    <td>{{ props.item.documentType }}</td>
                    <td>{{ props.item.documentDate != null ? $d(new Date(props.item.documentDate)) : null }}</td>
                    <td class="justify-center layout px-0">
                        <v-icon
                            v-if="hyperLinkType == DOCUMENT_HYPERLINK_TYPE"
                            medium
                            @click="addAsHyperLink(props.item)"
                        >
                            add
                        </v-icon>
                        <v-icon
                            v-if="hyperLinkType == CONTENT_HYPERLINK_TYPE"
                            medium
                            @click="addAsHyperLink(props.item)"
                        >
                            pageview
                        </v-icon>
                    </td>
                </template>
            </v-data-table>
        </v-container>
        <v-flex
            v-if="documentList.length > 0"
            xs12
            text-xs-center
        >
            <v-pagination
                :length="pageable.totalPages"
                :total-visible="8"
                :value="pageable.page"
                circle
                @input="changePage"
            />
        </v-flex>
    </v-layout>
</template>
<script>
import EventBus from '../../../utils/event-bus.js';
import ConstantEvent from '../../../shared/constant/constantEvent.js';
import ConstantHyperLink from '../../../shared/constant/constantDocumentHyperLink.js'
import axios from 'axios';
import {mapState} from 'vuex';

const SELECT_HYPERLINK = 'onSelectDocumentHyperlink';

/**
 *
 * Display a document list after a search.
 *
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 12/11/2018
 */
export default {
    name: 'DocumentHyperlinkList',
    props: ['hyperLinkType', 'documentId'],
    data() {
        return {
            initialMarketFilter: null,
            documentList: [],
            displayTab: false,
            displaySpinner: false,
            pageable: {page: 1, size: 10, direction: 'ASC', property: 'id'},
            searchFilter: {},
            DOCUMENT_HYPERLINK_TYPE: ConstantHyperLink.DOCUMENT_HYPERLINK_TYPE,
            CONTENT_HYPERLINK_TYPE: ConstantHyperLink.CONTENT_HYPERLINK_TYPE
        }
    },
    computed: {
        ...mapState({
            filter: state => state.documentFilterStore.filter,
        })
    },
    created() {
        if (!!this.filter) {
            this.listener(this.filter);
        } else {
            this.listener(this.searchFilter);
        }
    },
    /**
   * Listen the event document-hyperlink-list and send the search to the Backend
   */
    mounted() {
        EventBus.$on(ConstantEvent.DOCUMENT_HYPERLINK_LIST, this.listener);
        EventBus.$on(ConstantEvent.RESET_FILTER_PAGINATION, this.listener);
    },
    /**
   * Destroy the event document-hyperlink-list before leaving the component
   */
    beforeDestroy() {
        EventBus.$off(ConstantEvent.DOCUMENT_HYPERLINK_LIST, this.listener);
        EventBus.$off(ConstantEvent.RESET_FILTER_PAGINATION, this.listener);
    },
    methods: {
        /**
         * Receive the filters from the search component and request the back to get the corresponding documents
         * @param searchFilter
         */
        listener(searchFilter) {
            this.searchFilter = searchFilter;
            this.pageable.page = 1;
            this.searchDocumentList();
        },
        /**
     * Select the id of the document wanted and store it in the hyperLinkStore
     * Open a modal to let the user choose what type of link to create
     * @param document
     */
        addAsHyperLink: function (document) {
            if (this.hyperLinkType == ConstantHyperLink.DOCUMENT_HYPERLINK_TYPE) {
                this.$emit(SELECT_HYPERLINK, {referenceDocument: document.reference});
            }
            if (this.hyperLinkType == ConstantHyperLink.CONTENT_HYPERLINK_TYPE) {
                this.$emit(SELECT_HYPERLINK, {referenceDocument: document.reference, chosenTitleDocument: document.title});
            }
        },
        /**
     * search a document list with a search filter and a pageable object
     * @param searchFilter
     * @param pageable
     */
        searchDocumentList() {
            if (this.documentId && this.initialMarketFilter === null) {
                this.loadDocumentMarkets(this.documentId);
            } else {
                this.displaySpinner = true;
                this.displayTab = false;

                axios.post(
                    'document/filter',
                    {...this.searchFilter, 'marketsCodes': this.initialMarketFilter},
                    {params: this.pageable}
                ).then(({data}) => {
                    this.displaySpinner = false;
                    this.displayTab = true;
                    this.documentList = data.content;
                    this.pageable.totalElements = data.totalElements;
                    this.pageable.totalPages = data.totalPages;
                    this.pageable.page = data.page;
                });
            }
        },
        /**
     * change page and navigate to the selected page
     * @param page
     */
        changePage(page) {
            this.pageable.page = page;
            this.searchDocumentList();
        },
        /**
       * Load document markets to filter the links
       * @param id
       */
        loadDocumentMarkets(id){
            axios.get('document/'+ id.toString() + '/markets').then(
                ({data}) => {
                    this.initialMarketFilter = data.map((market) => market.code);
                    this.searchDocumentList();
                }
            )
        }
    }
}
</script>
<style>
.spinner_doc {
  position: absolute;
  top: calc(50vh - 65px);
  left: 0;
  right: 0;
  margin: auto;
}
</style>

