const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
/**
 *
 * Global constant for all the application
 * @author Sébastien De Santis
 * @since 13/12/2019
 * @version 1.0
 *
 */
export default class ConstantApplication {
    static get DEFAULT_DATE_FORMAT() {
        return DEFAULT_DATE_FORMAT;
    }

    static get DEFAULT_DATE_TIME_FORMAT() {
        return DEFAULT_DATE_TIME_FORMAT;
    }
}
