<template>
    <v-toolbar
        class="custom_toolbar"
        app
    >
        <v-btn
            v-if="isNotHideForPopup && !$route.params.popup"
            flat
            icon
            @click="$router.go(-1)"
        >
            <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-tooltip transition="slide-y-transition">
            <v-container
                slot="activator"
                class="marge_large"
            >
                <v-toolbar-title>{{ partTitle }}</v-toolbar-title>
            </v-container>
            <span>{{ partTitle }}</span>
        </v-tooltip>
        <v-spacer />
        <v-toolbar-items class="text-xs-center d-flex align-center">
            <v-list-tile
                v-for="item in items"
                :key="item.title"
                class="marge_tall"
                @click="goToRoute(item.route)"
            >
                <v-list-tile-content>
                    <v-list-tile-title class="item_title">
                        <v-icon class="icon_marge">
                            {{ item.icon }}
                        </v-icon>
                        <span>{{ item.title }}</span>
                    </v-list-tile-title>
                </v-list-tile-content>
            </v-list-tile>

            <v-flex class="t_logout text-xs-center text_name">
                {{ userPreferences.lastName }} {{ userPreferences.firstName }}
                <v-tooltip bottom>
                    <v-btn
                        slot="activator"
                        class="text-xs-center"
                        icon
                        @click="sendLogout()"
                    >
                        <v-icon class="item_title">
                            logout
                        </v-icon>
                    </v-btn>
                    <span>{{ $t('login.label.logout') }} {{ userPreferences.login }}</span>
                </v-tooltip>
            </v-flex>
        </v-toolbar-items>
    </v-toolbar>
</template>
<script>
import axios from 'axios';
import ConstantEvent from '../../shared/constant/constantEvent.js';
import EventBus from '../../utils/event-bus';
import {mapState} from "vuex";
import store from '@/store';
/**
 *
 * Main toolbar at the top of the screen. Display title for each feature.
 * @author Sébastien DE SANTIS, SFEIR Benelux
 * @version 1.0
 * @since 12/09/2018
 *
 */
export default {
    name: 'GlobalToolBar',
    data() {
        return {
            partTitle: this.$t('navigation.label.home'),
            drawer: false,
            isNotHideForPopup: true,
            closed: true
        };
    },
    /**
   * Open the dialog and set value if needed
   */
    created: function () {
        EventBus.$on(ConstantEvent.HIDE_BACK_ARROW_FOR_POPUP, this.listenerHide);
        EventBus.$on(ConstantEvent.SHOW_BACK_ARROW_FOR_POPUP, this.listenerShow);
    },
    /**
   * Destroy the even bus before the component destruction
   */
    beforeDestroy: function () {
        EventBus.$off(ConstantEvent.HIDE_BACK_ARROW_FOR_POPUP);
        EventBus.$off(ConstantEvent.SHOW_BACK_ARROW_FOR_POPUP);
    },
    computed: {
        ...mapState({
            userPreferences: state => state.userStore.userPreferences
        }),
        items() {
            let result = [
                {
                    title: this.$t('navigation.label.home'),
                    icon: 'home',
                    route: '/dashboard/'
                },
                {
                    title: this.$t('navigation.label.document'),
                    icon: 'edit',
                    route: '/document/'
                },
                {
                    title: this.$t('navigation.label.pao'),
                    icon: 'library_books',
                    route: '/pao/'
                }
            ];
            if (this.userPreferences.roles.includes('Administrator')) {
                result.push({
                    title: this.$t('navigation.label.administration'),
                    icon: 'settings',
                    route: '/administration/'
                });
            }
            return result;
        }
    },
    watch: {
        //update the partTitle when navigating
        $route(to) {
            var matchedRoutes = to.matched;
            if (matchedRoutes.length === 1) {
                this.partTitle = to.meta.navTitle;
            } else {
                var i = 1;
                this.partTitle = matchedRoutes[i++].meta.navTitle;
                for (; i < matchedRoutes.length; i++) {
                    if (!!matchedRoutes[i].meta.navTitle) {
                        this.partTitle += '/' + matchedRoutes[i].meta.navTitle;
                        if (!!to.params.title) {
                            this.partTitle = to.params.title;
                        }
                    }
                }
            }
        }
    },
    methods: {
        listenerHide() {
            this.isNotHideForPopup = false;
        },
        listenerShow() {
            this.isNotHideForPopup = true;
        },
        sendEvent() {
            EventBus.$emit('drawer');
        },
        goToRoute(route) {
            this.drawer = !(this.drawer);
            if (route === '/document/') {
                this.$router.push({name: 'search', params: {newSearch: true}});
            } else {
                this.$router.push(route);
            }
        },
        sendLogout() {
            axios.post('/secure/logout').then(() => {
                store.dispatch("userStore/resetUserPreferences"); //remove all saved data of user
                this.$router.push('/');
            }, () => {
                this.error = true;
                this.message = this.$t('login.error.credentials.bad');
            });
        }
    }
}
</script>

<style>
.marge_large {
  margin-right: 80px;
  max-width: 380px;
}

.marge_tall {
  margin-right: 10px;
}

.menu_color {
  background-color: white;
}

.center_tile {
  padding: 0 10px 0 10px;
}

.text_center {
  text-align: center;
}

.text_center:hover {
  color: #365C7E;
}

.text_name {
  margin-top: 10px;
  font-weight: bold;
  width: 200px;
}

.item_title {
  font-weight: bold;
}

.item_title:hover {
  color: #5E96C6;
}

.item_title:hover > .icon_marge {
  color: #5E96C6;
}

.icon_marge {
  margin-right: 15px;
}

.custom_toolbar {
  position: fixed;
  z-index: 999;
}

.publication_tile .v-list__group__header .publication_tile.v-list__group__header__append-icon {
  padding: 0 15px 0 0;
}
</style>
