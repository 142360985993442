
<template>
    <div class="dashboard">
        <router-view/>
    </div>
</template>

<script>
    /**
     * 
     * Main content dashboard. Here transit every components relatives too the dashboard
     * @author Justin WILMET, SFEIR Benelux
     * @version 1.0
     * @since 12/04/2019
     * 
     */
    export default {
        name: 'Dashboard'
    }
</script>