<template>
  <v-layout align-space-start justify-start column id="tree_container" class="padding_bottom_20">
    <v-flex v-if="!!hyperlink.document">
      <h3 class="font_title">
        <v-btn class="font_button" @click="documentListHidden = !documentListHidden">
          {{ $t('document.link.document') }}
        </v-btn>
      </h3>
      <v-list class="font_list" v-show="documentListHidden">
        <v-list-tile class="display_item"
                     v-for="document in hyperlink.document"
                     :key="document.id"
                     @click="scroll(document.id)"
        >
          <v-list-tile-content class="display_link">
            <v-icon small class="display_icon">link</v-icon>
            <v-tooltip bottom max-width="550">
              <template slot="activator">
                <div class="over-flow-handler">{{ ellipsText(document.innerHTML) }}</div>
              </template>
              <span class="font_text">{{ document.textContent }}</span>
            </v-tooltip>
          </v-list-tile-content>
          <v-list-tile-content class="display_link display_relationtype">
            <span class="font_text">{{ relationType(document.classList) }}</span>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-flex>
    <v-divider v-if="!!hyperlink.content" class="font-divider"></v-divider>
    <v-flex v-if="!!hyperlink.content">
      <h3 class="font_title">
        <v-btn class="font_button" @click="contentListHidden = !contentListHidden">
          {{ $t('document.link.content') }}
        </v-btn>
      </h3>
      <v-list class="font_list" v-show="contentListHidden">
        <v-list-tile class="display_item"
                     v-for="content in hyperlink.content"
                     :key="content.id"
                     @click="scroll(content.id)"
        >
          <v-list-tile-content class="display_link">
            <v-icon small class="display_icon">link</v-icon>
            <v-tooltip bottom max-width="550">
              <template slot="activator">
                <div class="over-flow-handler">{{ ellipsText(content.innerHTML) }}</div>
              </template>
              <span class="font_text">{{ content.textContent }}</span>
            </v-tooltip>
          </v-list-tile-content>
          <v-list-tile-content class="display_link display_relationtype">
            <span class="font_text">{{ relationType(content.classList) }}</span>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-flex>
    <v-divider v-if="!!hyperlink.external" class="font-divider"></v-divider>
    <v-flex v-if="!!hyperlink.external">
      <h3 class="font_title">
        <v-btn class="font_button" @click="externalListHidden = !externalListHidden">
          {{ $t('document.link.external') }}
        </v-btn>
      </h3>
      <v-list class="font_list" v-show="externalListHidden">
        <v-list-tile
            class="display_item"
            v-for="external in hyperlink.external"
            :key="external.id"
            @click="scroll(external.id)"
        >
          <v-list-tile-content class="display_link">
            <v-icon small class="display_icon">link</v-icon>
            <v-tooltip bottom max-width="550">
              <template slot="activator">
                <div class="over-flow-handler">{{ ellipsText(external.innerHTML) }}</div>
              </template>
              <span class="font_text">{{ external.textContent }}</span>
            </v-tooltip>
          </v-list-tile-content>
          <v-list-tile-content class="display_link display_relationtype">
            <span class="font_text">{{ relationType(external.classList) }}</span>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-flex>
  </v-layout>
</template>

<script>
/**
 * List to display 3 types of hyperlink (Document/Content/External)
 *
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 27/06/2019
 */
export default {
  name: 'ListLink',
  props: {
    hyperlink: {
      content: null,
      document: null,
      external: null
    }
  },
  data() {
    return {
      contentListHidden: true,
      documentListHidden: true,
      externalListHidden: true
    }
  },
  methods: {
    /**
     * clean the text to avoid writing tag and replace the end of text taller than 30 character by 3 dot
     * @param {String} text, text in the hyperlink
     */
    ellipsText(text) {
      let txt = document.createElement("textarea");
      txt.innerHTML = text;
      let endText = '';
      if (!!text && text.length > 30) {
        endText = '...';
      }

      if (txt.innerText.includes('<sup>0</sup>')) {
        return ((txt.value.replace(new RegExp('<[^>]*>', 'gm'), '')).substring(1, 30) + endText);
      } else {
        return ((txt.value.replace(new RegExp('<[^>]*>', 'gm'), '')).substring(0, 30) + endText);
      }
    },
    /**
     * Send an event to the parent to scroll to the clicked link
     */
    scroll(hyperlinkId) {
      this.$emit('scroll', hyperlinkId);
    },
    relationType(classList) {
      const relationType = Array.from(classList).find(className => /^(ART_CIT|ART_REX|DOC_CIT|DOC_REX)$/.test(className));
      if (!!relationType) {
        return this.$t(`document.link.relationType.${relationType}`);
      } else {
        return '';
      }
    }
  }
}
</script>

<style scoped>
#tree_container {
  margin-left: -10px;
  height: calc(100vh - 240px);
  overflow: auto;
}

.container_filter_documents {
  width: 300px;
  overflow-y: auto;
  background-color: #355B7F;
  padding-top: 5%;
  padding-left: 20px;
  padding-right: 20px;
}

.floating_button_settings {
  position: fixed;
  top: 70px;
  left: 220px;
}

.margin-button-settings {
  margin-top: 5px;
}

.btn-save {
  display: inline-block;
  width: 35px;
  height: 35px;
}

.padding_bottom_20 {
  padding-bottom: 20px;
}

.display_link {
  display: flex;
  flex-direction: row;
  padding: 0px;
  height: 15px;
  justify-content: flex-start;
}

.display_relationtype {
  justify-content: flex-end;
}

.display_icon {
  padding-right: 20px;
  color: #D39E4E;
}

.display_item {
  height: 30px !important;
}

.font_list {
  background: inherit;
  color: #fff;
}

.font_title {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.font_button {
  color: #D39E4E;
  background-color: unset !important;
  box-shadow: none !important;
}

.font-divider {
  margin-top: 20px;
  padding-bottom: 20px;
  border-color: white;
}

.font_text {
  font-size: 12px;
}

.over-flow-handler {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
}
</style>
