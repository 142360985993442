<template>
    <v-layout column>
        <v-layout
            grid-list-md
            text-xs-center
            align-start
            justify-start
        >
            <v-flex xs4>
                <v-card-title>
                    <h4 class="headline primary--text">
                        {{ $t('document.label.documentVersions') }}
                    </h4>
                </v-card-title>
                <v-select
                    :value="currentVersion"
                    :label="$t('document.label.versions')"
                    :items="reverseVersions"
                    item-value="version"
                    item-text="version"
                    class="margin-items"
                    @change="switchVersion"
                >
                    <template
                        slot="selection"
                        slot-scope="data"
                    >
                        <p class="bol-version">
                            {{ data.item.version }} - {{ data.item.startApplicabilityDate != null ? $d(new Date(data.item.startApplicabilityDate)): null }}
                        </p>
                    </template>
                    <template
                        slot="item"
                        slot-scope="data"
                    >
                        <p class="bol-version">
                            {{ data.item.version }} - {{ data.item.startApplicabilityDate != null ? $d(new Date(data.item.startApplicabilityDate)): null }}
                        </p>
                    </template>
                </v-select>
            </v-flex>
        </v-layout>
        <v-layout
            align-right
            justify-start
            column
        >
            <v-flex
                xs12
                sm6
            >
                <v-card-title>
                    <h4 class="headline primary--text">
                        {{ $t("document.label.globalInformation") }}
                    </h4>
                </v-card-title>
                <v-container
                    v-bind="{ [`grid-list-xs`]: true }"
                    fluid
                >
                    <v-layout
                        row
                        wrap
                    >
                        <v-flex>
                            <v-card
                                class="item"
                                flat
                                raised
                            >
                                <v-card-text><span class="grey--text">{{ $t("document.create.markets") }} : </span><br><span class="text-capitalize">{{ metaDocument.markets.join(", ") }}</span></v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex v-if="metaDocument.authors && metaDocument.authors.length > 0">
                            <v-card
                                class="item"
                                flat
                                raised
                            >
                                <v-card-text><span class="grey--text">{{ $t("document.create.authors") }} : </span><br><span class="text-capitalize">{{ metaDocument.authors.join(", ") }}</span></v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex>
                            <v-card
                                class="item"
                                flat
                                raised
                            >
                                <v-card-text><span class="grey--text">{{ $t("document.create.disponibility") }} : </span><br>{{ metaDocument.hasBeenPublishedOnce ? $t('document.dashboard.available'): $t('document.dashboard.unavailable') }}</v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex v-if="isNotNullValue(metaDocument.workspace)">
                            <v-card
                                class="item"
                                flat
                                raised
                            >
                                <v-card-text><span class="grey--text">{{ $t("document.create.mainworkspace") }} : </span><br>{{ metaDocument.workspace }}</v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex v-if="isNotNullValue(metaDocument.documentType)">
                            <v-card
                                class="item"
                                flat
                                raised
                            >
                                <v-card-text><span class="grey--text">{{ $t("document.create.documenttype") }} : </span><br>{{ metaDocument.documentType.label }}</v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex v-if="isNotNullValue(metaDocument.partner)">
                            <v-card
                                class="item"
                                flat
                                raised
                            >
                                <v-card-text><span class="grey--text">{{ $t("document.create.partner") }} : </span><br>{{ metaDocument.partner }}</v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex v-if="isNotNullValue(metaDocument.endapplicabilitydate)">
                            <v-card
                                class="item"
                                flat
                                raised
                            >
                                <v-card-text><span class="grey--text">{{ $t("document.create.endofapplicability") }} : </span><br>{{ metaDocument.endapplicabilitydate != null ? formatedDate(metaDocument.endapplicabilitydate) : null }}</v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex v-if="isNotNullValue(metaDocument.causeEndApplicability)">
                            <v-card
                                class="item"
                                flat
                                raised
                            >
                                <v-card-text><span class="grey--text">{{ $t("document.create.causeendapplicability") }} : </span><br>{{ metaDocument.causeEndApplicability }}</v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex v-if="isNotNullValue(metaDocument.sourceEndApplicability)">
                            <v-card
                                class="item"
                                flat
                                raised
                            >
                                <v-card-text><span class="grey--text">{{ $t("document.create.sourceendapplicability") }} : </span><br>{{ metaDocument.sourceEndApplicability }}</v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex v-if="isNotNullValue(metaDocument.indeterminate)">
                            <v-card
                                class="item"
                                flat
                                raised
                            >
                                <v-card-text><span class="grey--text">{{ $t("document.create.indeterminate") }} : </span><br>{{ $t("document.label.yes") }}</v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex v-if="isNotNullValue(metaDocument.remark)">
                            <v-card
                                class="item"
                                flat
                                raised
                            >
                                <v-card-text><span class="grey--text">{{ $t("document.create.remark") }} : </span><br>{{ metaDocument.remark }}</v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex v-if="isNotNullValue(metaDocument.reference)">
                            <v-card
                                class="item"
                                flat
                                raised
                            >
                                <v-card-text><span class="grey--text">{{ $t("document.create.reference") }} :</span><br>{{ metaDocument.reference }}</v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex>
                            <v-card
                                class="item"
                                flat
                                raised
                            >
                                <v-card-text>
                                    <span class="grey--text">{{ $t("document.create.is-visible") }} :</span>
                                    <br>
                                    {{ formatBoolean(metaDocument.isVisible) }}
                                </v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex v-if="isNotEmpty(metaDocument.otherWorkspace)">
                            <v-card
                                class="item"
                                flat
                                raised
                            >
                                <v-card-text>
                                    <span class="grey--text">{{ $t("document.create.additionalworkspaces") }} :</span><br>
                                    <span
                                        v-for="item in metaDocument.otherWorkspace"
                                        :key="item"
                                    >
                                        {{ item }} ;
                                    </span>
                                </v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex v-if="isNotEmpty(metaDocument.domains)">
                            <v-card
                                class="item"
                                flat
                                raised
                            >
                                <v-card-text>
                                    <span class="grey--text">{{ $t("document.create.domains") }} :</span><br>
                                    <span
                                        v-for="item in metaDocument.domains"
                                        :key="item"
                                    >
                                        {{ item }} ;
                                    </span>
                                </v-card-text>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-flex>

            <v-flex
                v-if="metaDocument.additionalFields.length > 0"
                xs12
                sm6
            >
                <v-card-title>
                    <h4 class="headline primary--text">
                        {{ $t("document.label.additionalInformation") }}
                    </h4>
                </v-card-title>
                <v-container
                    v-bind="{ [`grid-list-xs`]: true }"
                    fluid
                >
                    <v-layout
                        row
                        wrap
                    >
                        <v-flex
                            v-for="item in metaDocument.additionalFields"
                            :key="item.id"
                        >
                            <v-card
                                class="item"
                                flat
                                raised
                            >
                                <v-card-text>
                                    <span class="grey--text">{{ label(item.documentTypeFieldLabel) }} : </span>
                                    <br>
                                    {{ format(item.documentTypeFieldLabel, item.label) }}
                                </v-card-text>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-flex>
        </v-layout>
    </v-layout>
</template>
<script>
import moment from 'moment';
import ConstantApplication from '../../../../shared/constant/constantApplication';
import ConstantDataSheetField from '../../../../shared/constant/constantDatasheetField';
import {mapState} from 'vuex';

/**
 * 
 * Display Metadata of the searched document.
 * 
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 30/10/2018
 */
export default {
    name:'DataSheet',
    data() {
        return {
            version: null
        }
    },
    computed: {
        ...mapState({
            currentVersion: state => state.dataSheetStore.metaDocument.currentVersion,
            metaDocument: state => state.dataSheetStore.metaDocument,
            langList: state => state.languageStore.langList
        }),
        reverseVersions: function() {
            return this.metaDocument.versions.slice().reverse();
        }
    },
    methods: {
        /**
         * Change and load another version of the document
         */
        switchVersion(value) {
            this.version = value;
            this.$router.replace({name:'versionDetails' , params:{ reference: this.metaDocument.reference, version: this.version,title:  this.metaDocument.title}});
            this.$emit('changeVersionDocument',{ reference: this.metaDocument.reference, version: this.version });
        },
        /**
         * will load the translation of the label for every fields expected the fields for date
         */
        label(val) {
            switch(val) {
                case ConstantDataSheetField.ADDITIONAL_FIELD.ALTERNATIVE_TITLE:
                    return this.$t("document.create.alternative-title");
                case ConstantDataSheetField.ADDITIONAL_FIELD.BASE_DOCUMENT:
                    return this.$t("document.create.basedocument");
                case ConstantDataSheetField.ADDITIONAL_FIELD.NATURE :
                    return this.$t("document.create.nature");
                case ConstantDataSheetField.ADDITIONAL_FIELD.GUIDELINE_NATURE :
                    return this.$t("document.create.nature");
                case ConstantDataSheetField.ADDITIONAL_FIELD.TERRITORY :
                    return this.$t("document.create.territory");
                case ConstantDataSheetField.ADDITIONAL_FIELD.DATE_DOCUMENT :
                    return this.$t("document.create.documentdate");
                case ConstantDataSheetField.ADDITIONAL_FIELD.DATE_PUBLICATION :
                    return this.$t("document.create.dateofpublication");
                case ConstantDataSheetField.ADDITIONAL_FIELD.COUNTRY:
                    return this.$t("document.create.country");
                case ConstantDataSheetField.ADDITIONAL_FIELD.CODE :
                    return this.$t("document.create.code");
                case ConstantDataSheetField.ADDITIONAL_FIELD.NUMBER :
                    return this.$t("document.create.number");
                case ConstantDataSheetField.ADDITIONAL_FIELD.DATE_ENTRY :
                    return this.$t("document.create.dateofentryintoforce");
                case ConstantDataSheetField.ADDITIONAL_FIELD.DATE_START_APPLICABILITY :
                    return this.$t("document.create.startdateofapplicability");
                case ConstantDataSheetField.ADDITIONAL_FIELD.BASE_ACT :
                    return this.$t("document.create.baseact");
                case ConstantDataSheetField.ADDITIONAL_FIELD.DOMAIN :
                    return this.$t("document.create.domains");
                case ConstantDataSheetField.ADDITIONAL_FIELD.DATE_DECISION :
                    return this.$t("document.create.decisiondate");
                case ConstantDataSheetField.ADDITIONAL_FIELD.JURISDICTION :
                    return this.$t("document.create.jurisdiction");
                case ConstantDataSheetField.ADDITIONAL_FIELD.JUDICIAL_ORDER :
                    return this.$t("document.create.judicialorder");
                case ConstantDataSheetField.ADDITIONAL_FIELD.COMMERCIAL_COURT :
                    return this.$t("document.create.commercialcourt");
                case ConstantDataSheetField.ADDITIONAL_FIELD.DISMISSAL_TYPE :
                    return this.$t("document.create.dismissaltype");
                case ConstantDataSheetField.ADDITIONAL_FIELD.DAMAGE_TYPE :
                    return this.$t("document.create.damagetype");
                case ConstantDataSheetField.ADDITIONAL_FIELD.MORAL_ALLOWANCE :
                    return this.$t("document.create.moralallowance");
                case ConstantDataSheetField.ADDITIONAL_FIELD.PHYSICAL_ALLOWANCE :
                    return this.$t("document.create.allowanceperiod");
                case ConstantDataSheetField.ADDITIONAL_FIELD.SENIORITY :
                    return this.$t("document.create.seniority");
                case ConstantDataSheetField.ADDITIONAL_FIELD.STATUS :
                    return this.$t("document.create.status");
                case ConstantDataSheetField.ADDITIONAL_FIELD.SECTOR :
                    return this.$t("document.create.sector");
                case ConstantDataSheetField.ADDITIONAL_FIELD.GENDER :
                    return this.$t("document.create.gender");
                case ConstantDataSheetField.ADDITIONAL_FIELD.AGE :
                    return this.$t("document.create.age");
                case ConstantDataSheetField.ADDITIONAL_FIELD.SALARY :
                    return this.$t("document.create.salary");
                case ConstantDataSheetField.ADDITIONAL_FIELD.SOURCE :
                case ConstantDataSheetField.ADDITIONAL_FIELD.GUIDELINE_SOURCE :
                    return this.$t("document.create.sourceDocument");
                case ConstantDataSheetField.ADDITIONAL_FIELD.SOURCE_TYPE :
                    return this.$t("document.create.sourceType");
                case ConstantDataSheetField.ADDITIONAL_FIELD.LANGUAGE:
                    return this.$t("document.create.language")
                default: return val;
            }
        },
        /**
         * will load the translation for the ohers label
         */
        format(item, val) {
            switch(item) {
                case ConstantDataSheetField.ADDITIONAL_FIELD.DATE_DOCUMENT :
                    return this.formatedDate(val);
                case ConstantDataSheetField.ADDITIONAL_FIELD.DATE_PUBLICATION :
                    return this.formatedDate(val);
                case ConstantDataSheetField.ADDITIONAL_FIELD.DATE_ENTRY :
                    return this.formatedDate(val);
                case ConstantDataSheetField.ADDITIONAL_FIELD.DATE_START_APPLICABILITY :
                    return this.formatedDate(val);
                case ConstantDataSheetField.ADDITIONAL_FIELD.BASE_ACT :
                    return this.formatBoolean(val);
                case ConstantDataSheetField.ADDITIONAL_FIELD.DATE_DECISION :
                    return this.formatedDate(val);
                case ConstantDataSheetField.ADDITIONAL_FIELD.LANGUAGE:
                    return this.formatLanguage(val);
                default: return val;
            }
        },
        formatLanguage(code){
            return this.$t('document.languages.'+ code);
        },      
        formatBoolean(item) {
            if (!!item) {
                return this.$t("document.label.yes");
            } else {
                return this.$t("document.label.no");
            }
        },
        /**
         *
         */
        formatedDate(date) {
            return !!date ? this.$d(new Date(moment(date).format(ConstantApplication.DEFAULT_DATE_FORMAT))) : date;
        },
        /**
         * hide the fields without value
         */
        isNotNullValue(item) {
            return !!item;
        },
        /**
         * hide the fields with empty array
         */
        isNotEmpty(item) {
            if(item !== null) {
                if(item.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    }
}
</script>
<style scoped> 
    .bol-version{
        margin: 0px 0px 0px 15px;
    }
    .margin-items{
        margin:10px;width:240px;margin-left:40px;
    }
</style>
