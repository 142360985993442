<template>
    <v-layout fill-height>
        <pao-search :searchOnly="true" ref="paoSearch">
            <template slot-scope="{displaySpinner, paoList}">
                <pao-list :displaySpinner="displaySpinner">
                    <pao-list-tabs :paoList="paoList" @changePage="changePage"/>
                </pao-list>
            </template>
        </pao-search>
    </v-layout>
</template>
<script>
import { documentLockMixin }    from '../../../mixins/documentLockMixin';
import PaoSearch                from '../search/PaoSearch';
import PaoList                  from '../search/PaoList';
import PaoListTabs              from '../link/PaoListTabs';

/** 
 * Manage The links between a document and a pao 
 * 
 * @author Cédric de BOISVILLIERS
 * @version 1.0
 * @since 06/09/2019
 */
export default {
    name: 'PaoLinkManagement',
    mixins: [
        documentLockMixin
    ],
    components: {
        PaoSearch,
        PaoList,
        PaoListTabs
    },
    methods: {
        /**
         * Change the current page and call the component paoSearch to relaunch the search
         */
        changePage( page ){
            this.$refs.paoSearch.changePage( page );
        }
    }
}
</script>
<style scoped>

</style>
