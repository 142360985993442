<template>
    <v-layout
        row
        wrap
        align-left
    >
        <v-flex>
            <v-container>
                <h1
                    v-if="$route.params.id == null"
                    class="bolded"
                >
                    {{ $t('administration.publication.book.manage.add.title') }}
                </h1>
                <v-form
                    ref="formBook"
                    v-model="valid"
                    class="form-style"
                    lazy-validation
                >
                    <v-layout
                        column
                        justify-center
                    >
                        <v-flex md3>
                            <v-text-field 
                                v-model="bookToManage.label"
                                :label="$t('administration.publication.book.label.title')"
                                :rules="labelRules"
                                required
                                clearable
                            />
                        </v-flex>
                        <v-flex md3>
                            <v-text-field 
                                v-model="bookToManage.code"
                                :label="$t('administration.publication.book.label.code')"
                                :rules="labelRules"
                                required
                                clearable
                            />
                        </v-flex>
                        <v-flex md3>
                            <v-select 
                                v-model="bookToManage.bookType"
                                :label="$t('administration.publication.book.label.type')"
                                :rules="labelRules"
                                :items="bookTypeList"
                                required
                                clearable
                            >
                                <template
                                    slot="selection"
                                    slot-scope="{ item }"
                                >
                                    {{ $t('administration.publication.book.' + item ) }}
                                </template>
                                <template
                                    slot="item"
                                    slot-scope="{ item }"
                                >
                                    {{ $t('administration.publication.book.' + item ) }}
                                </template>
                            </v-select>
                        </v-flex>

                        <v-flex md3>
                            <v-layout
                                row
                                align-center
                            >
                                <v-flex
                                    xs12
                                    justify-end
                                >
                                    <!-- BUTTONS -->
                                    <v-tooltip
                                        top
                                        class="align-action-end"
                                    >
                                        <v-btn
                                            slot="activator"
                                            color="#355B7F"
                                            small
                                            fab
                                            :to="{ name: 'BookSearch' }"
                                        >
                                            <v-icon color="white">
                                                cancel
                                            </v-icon>
                                        </v-btn>
                                        <span>{{ $t("administration.publication.book.manage.action.cancel") }}</span>
                                    </v-tooltip>
                                    <v-tooltip
                                        v-if="!!bookToManage.label && !!bookToManage.code && !!bookToManage.bookType"
                                        top
                                        class="align-action-end"
                                    >
                                        <v-btn
                                            slot="activator"
                                            color="primary"
                                            small
                                            fab
                                            dark
                                            icon
                                            @click="manage(bookToManage)"
                                        >
                                            <v-icon>save</v-icon>
                                        </v-btn>
                                        <span v-if="$route.params.id == null">{{ $t("administration.publication.book.manage.action.add") }}</span>
                                        <span v-if="$route.params.id != null">{{ $t("administration.publication.book.manage.action.save") }}</span>
                                    </v-tooltip>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-form>     
            </v-container>
        </v-flex>
    </v-layout>
</template>

<script>

import axios            from 'axios';
import EventBus         from '../../../../utils/event-bus.js';
import ConstantEvent    from '../../../../shared/constant/constantEvent.js';
import ConstantDatasheetField from '../../../../shared/constant/constantDatasheetField.js';

export default {
    name: 'BookManagement',
    data () {
        return {
            valid: false,
            bookToManage: {
                id: "",
                label: "",
                code: "",
                bookType: "",
                sourceType: ""
            },
            labelRules: [
                v => !!v || this.$t("administration.publication.book.manage.required")
            ],
            bookTypeList: []
        }
    },
    mounted(){
        /**
         * When the page is charging, we pay attention if an id is pass,
         * if it's the case, it means that we update a book instead of create one
         */
        if(this.$route.params.id !== undefined) {
            this.loadBook(this.$route.params.id);
        }
        this.loadBookTypes();
    },
    methods:{
        /**
         *  Create or update a book
         * @param newBook
         */
        manage(bookToManage) {
            if(this.$refs.formBook.validate()){
                var method = null;
                if(this.$route.params.id) {
                    method = "PUT",
                    bookToManage.id = this.$route.params.id;
                } else {
                    method = "POST";
                    bookToManage.sourceType = ConstantDatasheetField.MONOGRAPHY;
                }
               
                axios( {
                    method: method,
                    url: "administration/publication/book",
                    data: bookToManage
                }).then( () => {
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                        message: 'administration.publication.book.manage.' +  (!this.$route.params.id ? 'add' : 'edit') +'.success',
                        type: 'SUCCEED'
                    }
                    );
                    this.$router.go( -1 );
                });
            }
        },
        /**
         * Retrieve a book with its id
         * @param id
         */
        loadBook( id ) {
            axios.get(
                `administration/publication/book/${id}`
            ).then( result => {
                this.bookToManage = result.data;
            });
        },
        /**
         * Retrieve the list of book types
         */
        loadBookTypes( ) {
            axios.get(
                `administration/publication/book/init`
            ).then( result => {
                this.bookTypeList = result.data;
            });
        }
    }
}
</script>
<style>
.form-style {
    margin-top:50px;
}
.align-action-end{
    float: right;
}
.bolded{
    font-weight: bold;
    color: #355B7F;
}
</style>
