// initial state
const state = {
    userPreferences: {
        login: null,
        preferredLanguage: null,
        firstName: null,
        lastName: null,
        rights: [],
        roles: [],
        csrfToken: null,
    },
}

// mutations ==> Synchronous way
const mutations = {
    set_user_preferences: (state, preferences) => {
        state.userPreferences.login = preferences.login;
        if( preferences.preferredLanguage === 'FR' ){
            state.userPreferences.preferredLanguage = 'fr-FR';
        }else if( preferences.preferredLanguage === 'EN' ){
            state.userPreferences.preferredLanguage = 'en-EN';
        }
        state.userPreferences.rights = preferences.rights;
        state.userPreferences.roles = preferences.roles;
        state.userPreferences.firstName = preferences.firstName;
        state.userPreferences.lastName = preferences.lastName;
    },
    reset_user_preferences: (state) => {
        state.userPreferences.login = null;
        state.userPreferences.preferredLanguage = null;
        state.userPreferences.rights = [];
        state.userPreferences.roles = [];
        state.userPreferences.firstName = null;
        state.userPreferences.lastName = null;
        state.userPreferences.csrfToken = null;
        localStorage.setItem('token', null);
    },
    set_csrf_token: (state, csrf) => {
        state.userPreferences.csrfToken = csrf;
        localStorage.setItem('token', csrf);
    }
}

// actions ==> Asynchronous way
const actions = {
    setUserPreferences: ({commit}, preferences) => {     
        commit('set_user_preferences', preferences);
    },
    resetUserPreferences: ({commit}) => {
        commit('reset_user_preferences');
    },
    setCsrfToken: ({commit}, csrf ) => {
        commit('set_csrf_token', csrf);
    }
}

// getters
const getters = {
    userPreferences: state => state.userPreferences.csrfToken,
    getPreferredLanguage: state => state.userPreferences.preferredLanguage
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
