/**
 * 
 * Manage all the operation related to Dom NODE/HTML or Vue Vnodes transformation/manipulation
 * 
 * @author Cédric de BOISVILLIERS
 * @since 14/12/2018
 * @version 1.0
 * 
 */
import ConstantDocumentNode         from '../../shared/constant/constantDocumentNode';
import ConstantNodeContentElement   from '../../shared/constant/constantNodeContentElement';
import ConstantQuotation            from '../../shared/constant/constantQuotation.js';
import ConstantTag                  from '../../shared/constant/constantTag.js';
import CheckDocumentNodeSelectionService    from '../document/checkDocumentNodeSelectionService.js';

const VUE_DATA_ATTRIBUTE_PREFIX = 'data-v-';
const HIGHLIGHT_NAME            = 'highlight';
const HIGHLIGHT_GREY_CLASS      = 'highlight-grey';
const HIGHLIGHT_YELLOW_CLASS    = 'highlight-yellow';

class DomManipulatorService{

    constructor(){}

    /**
     * Clone an HTMLElement, exclude attributes/class generated on rendering.
     * @param {HTMLElement} htmlElement the element to clone
     */
    cloneHTMLElement(htmlElement) {
        let element = document.createElement(htmlElement.tagName.toLowerCase());
        Array.from(htmlElement.attributes).filter( attribute =>
            this.$isAttributeToKeep(attribute)
        ).forEach( attribute => {
            element.setAttribute(attribute.name, attribute.value);
        });
        Array.from(htmlElement.classList).filter( className =>
            this.$isClassToKeep(className)
        ).forEach( className => {
            element.classList.add(className);
        });
        element.innerHTML = htmlElement.innerHTML;
        return element;
    }


    /***********************************************  SEARCH / GET  ****************************************************/
    /**
     * Search in all text occurences of the word entered
     */
    searchInNodes(searchNode,indexSearch,listHigh){
        let nbHigh = 0;
        if(searchNode.trim() != '' && !searchNode.includes('$') && !searchNode.includes('^') ) {
            searchNode = searchNode.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // escaping user input
            for (let bolNode of listHigh) {
                bolNode.innerHTML = bolNode.innerHTML.replace(new RegExp('<span class="highlight-grey" name="highlight">', "gm"), '', "gm").replace(new RegExp('<span class="highlight-yellow" name="highlight">', "gm"), '', "gm").replace(new RegExp('</span>', "gm"), '', "gm");
                bolNode.innerHTML = bolNode.innerHTML.replace(new RegExp("(" + searchNode + ")(?!([^<]+)?>)", "gm"), '<span class="highlight-grey" name="highlight">$&</span>', "gm"); // keep regex modifications and insert the matched substring
            }

            let childDiv = document.getElementsByName(HIGHLIGHT_NAME)
            nbHigh = childDiv.length;
            if (indexSearch > 0){
                childDiv[(indexSearch - 1)].classList.replace(childDiv[(indexSearch - 1)].className, HIGHLIGHT_GREY_CLASS);
            }
            if (childDiv[indexSearch]){
                childDiv[indexSearch].classList.replace(childDiv[indexSearch].className, HIGHLIGHT_YELLOW_CLASS);
            }       
        }
        return nbHigh;
    }
    /**
     * Search  the next occurence of the word entered
     */
    searchUpInNodes(childDiv, indexSearch){
        let parent = childDiv[indexSearch];
        let idToScroll;
        if( !!parent ){
            while(!parent.id.includes(ConstantDocumentNode.NODE_CONTENT_CLASS)){
                while(parent){
                    if(parent.id.includes(ConstantDocumentNode.NODE_CONTENT_CLASS)) {
                        break;
                    }
                    parent = parent.parentElement;
                }
                if(parent.id.includes(ConstantDocumentNode.NODE_CONTENT_CLASS)) {
                    break;
                }
            }
            if(indexSearch < childDiv.length - 1 ) {
                if(childDiv.length === 1){
                    childDiv[(indexSearch)].classList.replace(childDiv[(indexSearch)].className, HIGHLIGHT_GREY_CLASS);
                }else {
                    childDiv[(indexSearch + 1)].classList.replace(childDiv[(indexSearch + 1)].className, HIGHLIGHT_GREY_CLASS);
                }
            }
            childDiv[indexSearch].classList.replace(childDiv[indexSearch].className, HIGHLIGHT_YELLOW_CLASS);
            idToScroll = parent.id.replace(`${ConstantDocumentNode.NODE_CONTENT_CLASS}-`,'');
        }
        return idToScroll;
    }
    /**
     * Search  the previous occurence of the word entered
     */
    searchDownInNodes(childDiv, indexSearch){
        let parent = childDiv[indexSearch];
        let idToScroll;
        if( !!parent ){
            while(!parent.id.includes(ConstantDocumentNode.NODE_CONTENT_CLASS)){
                while(parent){
                    if(parent.id.includes(ConstantDocumentNode.NODE_CONTENT_CLASS)) {
                        break;
                    }
                    parent = parent.parentElement;
                }
                if(parent.id.includes(ConstantDocumentNode.NODE_CONTENT_CLASS)) {
                    break;
                }
            }
            if(indexSearch > 0) {
                childDiv[(indexSearch - 1)].classList.replace(childDiv[(indexSearch - 1)].className, HIGHLIGHT_GREY_CLASS);
            }
            childDiv[indexSearch].classList.replace(childDiv[indexSearch].className, HIGHLIGHT_YELLOW_CLASS);
            idToScroll = parent.id.replace(`${ConstantDocumentNode.NODE_CONTENT_CLASS}-`,'');
        }
        return idToScroll;
    }
    /***
     * Transform an HTMLTableElement to an array
     */
    getArrayFromHTMLTableElement (htmlTableElement) {
        let tableRows = [];
        Array.from(htmlTableElement.tBodies).forEach( tBody => {
            tableRows.push(...Array.from(tBody.rows).map( ({cells, align, vAlign}) => {
                return {
                    align,
                    vAlign,
                    cells: Array.from(cells).map( ({colSpan, rowSpan, innerHTML}) => {
                        return {
                            colSpan,
                            rowSpan,
                            content: innerHTML
                        }
                    })
                }
            }));
        });
        return tableRows;
    }
    /***
     * Transform an HTMLTableElement to an array
     */
    getFooterArrayFromHTMLTableElement (htmlTableElement) {
        let tableRows = [];
        if(htmlTableElement) {
            if(htmlTableElement.tFoot) {
                Array.from(htmlTableElement.tFoot.rows).forEach(row => {
                    tableRows.push(Array.from(row.cells).map(cell => cell.innerHTML));
                });
            }
        }
        return tableRows;
    }
    /**
     * Return the first parent element of type "block"
     * @param {HTMLElement} htmlElement
     */
    $getParentBlockElement(htmlElement) {
        return !this.$isInlineElement(htmlElement.tagName) ? htmlElement : this.$getParentBlockElement(htmlElement.parentElement);
    }


    /***********************************************  REPLACE  ****************************************************/
    /**
     * Replace the selected text by the one from the clipboard
     * @param {Node} domNode, the element who contains the selected text
     * @param {Number} startOffset, offset at the start of the selection
     * @param {Number} endOffset, offset at the end of the selection
     * @param {String} textContent, text from the clipboard
     * @param {String} ParentNodeClassName, Class name of the node to stop the reconstruction of the node
     */
    replaceTextByText(domNode, startOffset, endOffset, textContent, ParentNodeClassName){
        let previousTextContent = domNode.textContent.slice(0, startOffset);
        let nextTextContent = domNode.textContent.slice(endOffset);
        let updatedText = [ document.createTextNode(previousTextContent + textContent + nextTextContent) ];
        let textNodeRecreated = this.$buildUpdatedNodeContent(domNode, updatedText);
        return this.updateHTMLElementInNode(domNode.parentNode, textNodeRecreated , ParentNodeClassName);
    }
    /**
     * Replace the selection by the clipboard
     * @param {Node} domNode, the closet parent element of the who contains the selected text
     * @param {Selection} windowSelection, the selection containing the starting node and the ending node with their respective offset
     * @param {Number} startIndex, the index of the starting node in his parent
     * @param {Number} endIndex, the index of the ending node in his parent
     * @param {String} textContent, text from the clipboard
     * @param {String} ParentNodeClassName, Class name of the node to stop the reconstruction of the node
     */
    replaceMultipleTextByText(domNode, windowSelection, startIndex, endIndex, textContent, ParentNodeClassName){
        let previousTextContent = windowSelection.anchorNode.textContent.slice( 0, windowSelection.anchorOffset );
        let nextTextContent = windowSelection.focusNode.textContent.slice( windowSelection.focusOffset );

        let updatedText = [ document.createTextNode(previousTextContent + textContent + nextTextContent) ];
        let textNodeRecreated = this.$buildUpdatedNodeContent(windowSelection.anchorNode, updatedText);

        return this.replaceHTMLByHTMLElementInNode(domNode, startIndex, endIndex, textNodeRecreated, ParentNodeClassName);
    }
    /**
     * Update the text with html in a text node.
     */
    replaceTextByHTMLElementInNode(domNode, startOffset, endOffset, htmlElement, ParentNodeClassName, endNode = null){
        // Recover the text before and after the selected text that will be replaced
        if( !!endNode ) {
            let parentNode = domNode.parentNode;
            let indexs = CheckDocumentNodeSelectionService.calculateIndexOfChildren(domNode, endNode, parentNode.childNodes);
            let childNodesArray = Array.from(parentNode.childNodes);
            /**
             * Got the previous and siblings in the parent
             */
            let domNodePreviousSiblingsList = childNodesArray.slice( 0, indexs.anchorIndex );
            let domNodeNextSiblingsList = childNodesArray.slice( indexs.focusIndex + 1 );
            /**
             * Got the previous and next text in the selection
             */

        
            let previousTextContent = childNodesArray[indexs.anchorIndex].textContent.slice(0, startOffset);
            let nextTextContent = childNodesArray[indexs.focusIndex].textContent.slice(endOffset);
            /**
             * the selection is recreated as html
             */
            let updatedSelection = [document.createTextNode(previousTextContent), htmlElement, document.createTextNode(nextTextContent) ];

            let updatedSiblings = [...domNodePreviousSiblingsList, ...updatedSelection, ...domNodeNextSiblingsList]
            let textNodeRecreated = this.$buildUpdatedNodeContentOnCrossSelection( domNode, updatedSiblings);
            return this.updateHTMLElementInNode(parentNode, textNodeRecreated, ParentNodeClassName);
        } else {
            let previousTextContent = domNode.textContent.slice(0, startOffset);
            let nextTextContent = domNode.textContent.slice(endOffset);
            if(this.$isInlineElement(htmlElement.tagName)){
                let updatedNodeList = [document.createTextNode(previousTextContent), htmlElement, document.createTextNode(nextTextContent) ];
                let textNodeRecreated = this.$buildUpdatedNodeContent(domNode, updatedNodeList);
                return this.updateHTMLElementInNode(domNode.parentNode, textNodeRecreated , ParentNodeClassName);
            } else {
                let parentNode = this.createHTMLElementFromHtml(this.$extractElementOuterHTML(domNode.parentNode, ''));
                let domNodePreviousSiblingsOuterHTML = this.$extractPreviousSiblingsOuterHTML(domNode) + previousTextContent;
                let domNodeNextSiblingsOuterHTML = nextTextContent + this.$extractNextSiblingsOuterHTML(domNode);
    
                if (!this.$isTableElement(parentNode.tagName)){
                    let previousSibling = this.$extractElementOuterHTML(parentNode, domNodePreviousSiblingsOuterHTML);
                    let nextSibling = this.$extractElementOuterHTML(parentNode, domNodeNextSiblingsOuterHTML);
                    return this.updateHTMLElementInNode(domNode.parentNode, previousSibling + htmlElement.outerHTML + nextSibling, ParentNodeClassName);
                } else {
                    let domNodePreviousSiblingsOuterHTML = this.$extractPreviousSiblingsOuterHTML(domNode) + previousTextContent;
                    let domNodeNextSiblingsOuterHTML = nextTextContent + this.$extractNextSiblingsOuterHTML(domNode);
                    return this.updateHTMLElementInNode(domNode, domNodePreviousSiblingsOuterHTML + htmlElement.outerHTML + domNodeNextSiblingsOuterHTML, ParentNodeClassName);
                }
            }
        }   
    }
    /**
     * Replace the HTML content between the startOffset and the enfOffset by the given outerHTML
     * @param {*} domNode
     * @param {*} startOffset
     * @param {*} endOffset
     * @param {*} outerHTML
     */
    replaceHTMLByHTMLElementInNode(domNode, startOffset, endOffset, outerHTML, parentNodeClassName){
        let htmlContent = '';
        let currentNode = domNode;
        if ( !!domNode.className && domNode.className.includes(parentNodeClassName) ) {
            htmlContent = this.$replaceHTMLInBlockElement(domNode, startOffset, endOffset, outerHTML);
        } else if(!this.$isInlineElement(currentNode.tagName)){
            htmlContent = this.$replaceHTMLInBlockElement(domNode, startOffset, endOffset, outerHTML);
            htmlContent = this.$extractElementOuterHTML(currentNode, htmlContent);
            htmlContent = this.$extractSiblings(currentNode, htmlContent);
            currentNode = currentNode.parentNode;
        } else { // in the case of insert with inline element  the things to do is add the element after the inline and rebuilt the node
            htmlContent = domNode.outerHTML;
            htmlContent += outerHTML;
        }
        htmlContent = this.rebuildNodeToParent(currentNode, htmlContent, parentNodeClassName);
        return htmlContent;
    }
    /**
     * Replace HTML in block element
     * @param {*} domNode
     * @param {*} startOffset
     * @param {*} endOffset
     * @param {*} outerHTML
     */
    $replaceHTMLInBlockElement(domNode, startOffset, endOffset, outerHTML){
        let htmlContent = '';
        for (let childIndex = 0; childIndex < startOffset; childIndex++) {
            htmlContent += domNode.childNodes[childIndex].nodeType === Node.TEXT_NODE ? domNode.childNodes[childIndex].textContent : domNode.childNodes[childIndex].outerHTML;
        }
        htmlContent += outerHTML;
        for (let childIndex = endOffset ; childIndex < domNode.childNodes.length; childIndex++) {
            htmlContent += domNode.childNodes[childIndex].nodeType === Node.TEXT_NODE ? domNode.childNodes[childIndex].textContent : domNode.childNodes[childIndex].outerHTML;
        }
        return htmlContent;
    }
    /**
     * Replace the next occurence with the word entered
     */
    replaceInNodes(searchNode,replaceNode,indexSearch){
        let childDiv = document.getElementsByName(HIGHLIGHT_NAME);
        let impactedNode = childDiv[indexSearch];
        let node;
        if( !!impactedNode ){
            let idNode = this.$extractIdTextNodeContent( impactedNode );
            let newText;

            if(childDiv[indexSearch].parentElement.innerHTML.includes('yellow')) {
                newText = childDiv[indexSearch].parentElement.innerHTML.replace(`<span class="highlight-yellow" name="highlight">${searchNode}</span>`, replaceNode);
            } else {
                newText = childDiv[indexSearch].parentElement.innerHTML.replace(`<span class="highlight-grey" name="highlight">${searchNode}</span>`, replaceNode);
            }

            node = {
                nodeId: idNode,
                replacedText: newText,
                parentNode: impactedNode.parentNode
            };

            childDiv[indexSearch].parentElement.innerHTML = newText;
        }
        return node;
    }
    /**
     * Replace in all text, all occurence with the word entered
     */
    replaceAllInNodes(searchNode,replaceNode){
        let childDiv = document.getElementsByName(HIGHLIGHT_NAME);
        let impactedNodes = [];
        
        for(let i = 0;i < childDiv.length;i++) {
            while (!!childDiv[i] && childDiv[i].parentElement.innerHTML.includes(searchNode)) {
                let idNode = this.$extractIdTextNodeContent( childDiv[i] );
                let newText;

                if (childDiv[i].parentElement.innerHTML.includes('yellow')) {
                    newText = childDiv[i].parentElement.innerHTML.replace(`<span class="highlight-yellow" name="highlight">${searchNode}</span>`, replaceNode);
                } else {
                    newText = childDiv[i].parentElement.innerHTML.replace(`<span class="highlight-grey" name="highlight">${searchNode}</span>`, replaceNode);
                }
            
                let node = {
                    replacedText: newText,
                    parentNode: childDiv[i].parentNode,
                    nodeId: idNode
                };

                impactedNodes.push(node);
                childDiv[i].parentElement.innerHTML = newText;
            }
        }
        return impactedNodes;
    }

    $extractIdTextNodeContent( domNode ){
        let node = domNode;
        let idNode;
        while ( !node.id.includes( ConstantDocumentNode.NODE_CONTENT_CLASS )){
            node = node.parentElement;
        }
        idNode = node.id.replace(`${ConstantDocumentNode.NODE_CONTENT_CLASS}-`, '');
        return idNode;
    }

    $extractNewTextContent( parentInnerHtml, searchText, replaceText ){
        if( parentInnerHtml.includes('yellow')){
            return parentInnerHtml.replace(`<span class="highlight-yellow" name="highlight">${searchText}</span>`, replaceText);
        } else {
            return parentInnerHtml.replace(`<span class="highlight-grey" name="highlight">${searchText}</span>`, replaceText);
        }
    }

    /***********************************************  REBUILD  ****************************************************/
    /**
     * Rebuild a node from one of its nested child node with a custom innerHTML
     * @param {Node} domNode 
     * @param {String} htmlContent 
     * @param {String} parentNodeClassName 
     */
    rebuildNodeToParent(domNode, htmlContent, parentNodeClassName) {
        let outerHTML = htmlContent;
        while( !domNode.className || !domNode.className.includes(parentNodeClassName) ) {
            outerHTML = this.$extractElementOuterHTML(domNode, outerHTML);
            outerHTML = this.$extractSiblings(domNode, outerHTML);
            domNode = domNode.parentNode;
        }
        return outerHTML;
    }
    /**
     * Build a new node content by merging a node content and an updated content
     * @param {*} domNode
     * @param {*} updatedNodeList
     */
    $buildUpdatedNodeContent(domNode, updatedNodeList){
        let parentNode = this.createHTMLElementFromHtml(this.$extractElementOuterHTML(domNode.parentNode, ''));

        let childNodes = domNode.parentNode.childNodes;
        let domNodeIndex = Array.from(childNodes).indexOf(domNode);
        let domNodePreviousSiblingsList = Array.from(childNodes).slice(0, domNodeIndex);
        let domNodeNextSiblingsList = Array.from(childNodes).slice(domNodeIndex + 1);//TODO check if out of array

        [...domNodePreviousSiblingsList, ...updatedNodeList, ...domNodeNextSiblingsList].forEach( htmlElement => {
            if(!!htmlElement.tagName){
                if( htmlElement.childNodes.length === 1 && htmlElement.childNodes[0].nodeType !== Node.TEXT_NODE){
                    parentNode.appendChild(this.createHTMLElementFromHtml(this.cloneHTMLElement(htmlElement).outerHTML));
                } else {
                    parentNode.appendChild(this.createHTMLElementFromHtml(this.$extractElementOuterHTML(htmlElement, htmlElement.innerText)));
                }
            } else {
                parentNode.append(htmlElement.textContent);
            }
        });
        return this.$extractElementOuterHTML(parentNode);

    }

    /**
     * Build a new node content by merging a node content and an updated content
     * @param {*} domNode
     * @param {*} updatedNodeList
     */
    $buildUpdatedNodeContentOnCrossSelection(anchorNode, childNodes){
        let parentNode = this.createHTMLElementFromHtml(this.$extractElementOuterHTML(anchorNode.parentNode, ''));
    
        childNodes.forEach( htmlElement => {
            if(!!htmlElement.tagName){
                if( htmlElement.childNodes.length === 1 && htmlElement.childNodes[0].nodeType !== Node.TEXT_NODE){
                    parentNode.appendChild(this.createHTMLElementFromHtml(this.cloneHTMLElement(htmlElement).outerHTML));
                } else {
                    parentNode.appendChild(this.createHTMLElementFromHtml(this.$extractElementOuterHTML(htmlElement, htmlElement.innerHTML)));
                }
            } else {
                parentNode.append(htmlElement.textContent);
            }
        });
        return this.$extractElementOuterHTML(parentNode);

    }
    /**
     * Recursively rebuild all previous sibling elements while no block element reached
     * @param {String} htmlContent
     * @param {HTMLElement} htmlElement 
     */
    $rebuildPreviousSiblingsToParentBlockElement(htmlContent, htmlElement) {
        if (!this.$isInlineElement(htmlElement.tagName)) {
            return htmlContent;
        } else {
            let parentElement = htmlElement.parentElement;
            let outerHTML = this.$extractElementOuterHTML(parentElement,`${this.$extractPreviousSiblingsOuterHTML(htmlElement)}${htmlContent}`);
            return this.$rebuildPreviousSiblingsToParentBlockElement(outerHTML, parentElement);
        }
    }
    /**
     * Recursively rebuild all next sibling elements while no block element reached
     * @param {String} htmlContent
     * @param {HTMLElement} htmlElement 
     */
    $rebuildNextSiblingsToParentBlockElement(htmlContent, htmlElement) {
        if (!this.$isInlineElement(htmlElement.tagName)) {
            return htmlContent;
        } else {
            let parentElement = htmlElement.parentElement;
            let outerHTML = this.$extractElementOuterHTML(parentElement,`${htmlContent}${this.$extractNextSiblingsOuterHTML(htmlElement)}`);
            return this.$rebuildNextSiblingsToParentBlockElement(outerHTML, parentElement);
        }
    }

    /************************************************ EXTRACTION  **************************************************/

    /**
     * Extract the first level HTMLElements of an html text
     * @param {String} html
     */
    extractNodesFromHTML (html) {
        const container = document.createElement('template');
        container.innerHTML = html;
        return Array.from(container.content.childNodes);
    }
    /**
     * Get concatened outerHTML of all previous siblings of the input node
     * @param {Node} domNode
     */
    $extractPreviousSiblingsOuterHTML(domNode) {
        let previousSibling = domNode.previousSibling;
        let previousSiblingsOuterHTML = '';
        while(!!previousSibling) {
            previousSiblingsOuterHTML = `${previousSibling.nodeType === Node.TEXT_NODE ? previousSibling.textContent : this.$extractElementOuterHTML(previousSibling)}${previousSiblingsOuterHTML}`;
            previousSibling = previousSibling.previousSibling;
        }
        return previousSiblingsOuterHTML;
    }
    /**
     * Get concatened outerHTML of all next siblings of the input node
     * @param {Node} domNode 
     */
    $extractNextSiblingsOuterHTML(domNode) {
        let nextSibling = domNode.nextSibling;
        let nextSiblingsOuterHTML = '';
        while(!!nextSibling) {
            nextSiblingsOuterHTML += nextSibling.nodeType === Node.TEXT_NODE ? nextSibling.textContent : this.$extractElementOuterHTML(nextSibling);
            nextSibling = nextSibling.nextSibling;
        }
        return nextSiblingsOuterHTML;
    }
    /**
     * Get the outer HTML of an HTMLElement, exclude attributes/class generated on rendering.
     * Replace the inner HTML of the element, if passed to arguments
     * @param {HTMLElement} htmlElement 
     * @param {String} innerHTML 
     */
    $extractElementOuterHTML(htmlElement, innerHTML) {
        if(!htmlElement.tagName) return '';
        let element = this.cloneHTMLElement(htmlElement);
        if ( innerHTML === '' || (innerHTML !=='' && !!innerHTML) ) {
            element.innerHTML = innerHTML;
        }else {
            element.innerHTML = htmlElement.innerHTML;
        }
        return element.outerHTML;
    }
    /**
     * Get the Siblings of the input DOM node,
     * concat all and return html of the result
     * @param {Node} domNode
     * @param {String} outerHTML
     */
    $extractSiblings(domNode, outerHTML = '') {
        return this.$extractPreviousSiblingsOuterHTML(domNode)
            + outerHTML
            + this.$extractNextSiblingsOuterHTML(domNode);
    }
    /**
     * Extract a clean table from a Vuetify v-data-table by removing all non table related element wrapping table related element
     * @param {HTMLElement} vDataTableElement
     */
    extractTableFromVDataTable(vDataTableElement) {
        let element = vDataTableElement.firstChild;
        while(element.nodeName !== 'TABLE') {
            element = element.firstChild;
        }
        let tableElement = document.createElement('table');
        tableElement.innerHTML = element.innerHTML;
        return tableElement;
    }


    /*********************************************** CHECKING  ************************************************/
    /**
     * Check if the begining of the next content start with a special caractere or a white space
     * @param {String} nextTextContent, text becoming just after the offset
     */
    $checkIfBlankExistAfterOffset( nextTextContent ){
        let regexSpecialCaractere = new RegExp(/[-,).;\s]/gm);
        if( regexSpecialCaractere.test( nextTextContent.charAt(0)) ){
            return nextTextContent;
        } else {
            return ` ${nextTextContent}`;
        }
    }
    /**
     * Check if the class must be keeped while rebuilding
     * @param {String} className 
     */
    $isClassToKeep(className) {
        return true;
    }
    /**
     * Check if the attibute must be keeped while rebuilding
     * @param {*} attribute 
     */
    $isAttributeToKeep(attribute) {
        return !attribute.name.startsWith(VUE_DATA_ATTRIBUTE_PREFIX)
                && !attribute.value.startsWith(ConstantDocumentNode.NON_PERSISTENT_PREFIX)
                && attribute.name!=='class';
    }
    /**
     * 
     * @param {String} tagName 
     */
    $isInlineElement(tagName) {
        let element = document.createElement(tagName);
        document.body.appendChild(element);
        let displayStyle =  window.getComputedStyle(element).display; 
        document.body.removeChild(element);

        return displayStyle === 'inline';
    }
    $isBlockElement(tagName){
        let element = document.createElement(tagName);
        document.body.appendChild(element);
        let displayStyle =  window.getComputedStyle(element).display; 
        document.body.removeChild(element);

        return displayStyle === 'block';
    }
    $isTableElement(tagName){
        let element = document.createElement(tagName);
        document.body.appendChild(element);
        let displayStyle =  window.getComputedStyle(element).display; 
        document.body.removeChild(element);

        return displayStyle.substring(0,5) === 'table';
    }
    /**
     * TODO: business method to remove from here, create a business service or transform to a generic method with required arguments
     * change return type or method name to be coherent
     */
    isPDFStructure(domNode){
        if(!!domNode.parentNode && domNode.parentNode.className.includes(ConstantNodeContentElement.CUSTOM_UPLOAD_FILE_PADDING)){
            while( !domNode.className || !domNode.className.includes(ConstantNodeContentElement.CUSTOM_UPLOAD_FILE_CLASS) ) {
                domNode = domNode.parentNode;
            }
        }
        return domNode;
    }


    /*********************************************** CREATION  ************************************************/
    /**
     * Create an htmlElement from its html source
     * @param {String} outerHTMLElement
     */
    createHTMLElementFromHtml(outerHTMLElement) {
        let elementContainer = document.createElement('template');
        elementContainer.innerHTML = outerHTMLElement;
        return elementContainer.content.firstChild;
    }
    /**
     * TODO: business method to remove from here create a business service or transform to a generic method with required arguments
     * Create a blockquote element with the quote in it.
     * @param {*} quote 
     * @param {*} documentTypeClass 
     */
    manageBlockquote(nodeList, documentTypeClass){
        let blockquote = document.createElement(ConstantTag.BLOCKQUOTE);
        blockquote.className = ConstantQuotation.QT_STRUCTURAL_CLASS;
        blockquote.classList.add( ConstantQuotation.MCE_NON_EDITABLE );
        if(!!documentTypeClass){
            blockquote.classList.add( documentTypeClass.label.toUpperCase() );
        }
        blockquote.innerHTML = this.convertStructuralNodeIntoDomElement( nodeList ); 
        return blockquote;
    }
    /**
     * TODO: Change description to make it generic , non business method here + rename method to explain the div is created with a specific class
     * Create a Div element with the label of the JSON node as a className.
     * @param {String} structuralClass, label of the JSON node.
     */
    $createDivElement( structuralClass ){
        let div = document.createElement(ConstantTag.DIV);
        div.className = structuralClass.toUpperCase();
        return div;
    }
    /**
     * TODO: business method to remove from here create a business service or transform to a generic method with required arguments
     * Create an html element for an imported file
     * @param {*} file 
     */
    createElementForImportedFile( file ){
        if( file.type === 'application/pdf'){
            return this.$createLinkForImportedPDF(file);
        }else {
            return this.$createImgForImportedPicture(file);
        }
    }
    /**
     * TODO: business method to remove from here create a business service or transform to a generic method with required arguments
     * Create the block who contains the link to the pdf and an icon representing the PDF 
     * @param {*} pdf 
     */
    $createLinkForImportedPDF( pdf ){
        let a = document.createElement('a');
        a.setAttribute('href',pdf.source);
        a.setAttribute('id', pdf.uid);
        a.setAttribute('target', '_blank');
        a.setAttribute('rel', 'noopener noreferrer');
        a.className = ConstantNodeContentElement.CUSTOM_UPLOAD_FILE_CLASS;
       
        let em = document.createElement('em');
        em.className = 'fa fa-download';

        let span = document.createElement('span');
        span.innerHTML = 'Voir PDF';
        span.className = 'uploadFile-padding';

        em.appendChild(span);
        a.appendChild(em);

        return a;
    }
    /**
     * TODO: business method to remove from here create a business service or transform to a generic method with required arguments
     * Create an img element
     * @param {*} picture 
     */
    $createImgForImportedPicture( picture ){
        let img = document.createElement(ConstantTag.IMG);
        img.setAttribute('src', picture.source);
        img.setAttribute('id', picture.uid);
        img.setAttribute('alt', picture.name);
        img.setAttribute('width', picture.width);
        img.setAttribute('height', picture.height);
        img.className = 'document-image';
        return img;
    }


    /*********************************************** INSERTION / UPDATE  ************************************************/
    /**
     * Update a nested child node in a parent node specified by a class, return the modified innerHTML of the parent node.
     *
     * @param {Node} domNode, current html node to update
     * @param {String} html of the updated node
     * @param {String} parentNodeClassName, class name of the parent node to return as html
     */
    updateHTMLElementInNode(domNode, outerHTML, parentNodeClassName) {
        let htmlContent = '';
        if ( !domNode.className || !domNode.className.includes(parentNodeClassName) ) {
            htmlContent = this.$extractSiblings(domNode, outerHTML);
            htmlContent = this.rebuildNodeToParent(domNode.parentNode, htmlContent, parentNodeClassName);
        } else {
            htmlContent = {error: "no content updated", cause: "Wrong node selected"};
        }
        return htmlContent;
    }
    /**
     * TODO: business method to remove from here create a business service or transform to a generic method with required arguments
     * Based on a JSON node structure, will create the corresponding HTML structure.
     * @param {*} structuralNodes, JSON structure
     */
    convertStructuralNodeIntoDomElement( structuralNodes ) {
        let children = '';
        structuralNodes.forEach( node => {
            let div = this.$createDivElement( node.label );

            if( node.label === ConstantDocumentNode.TEXT_TYPE) {
                div.innerHTML = node.content;
            }
            if( !!node.children && node.children.length > 0 ){
                div.innerHTML = this.convertStructuralNodeIntoDomElement( node.children );
            }
            children += div.outerHTML;

        });
        return children;
    }
    /**
     * Insert html in a text node
     * @param {*} textNode
     * @param {*} offset
     * @param {*} text
     * @param {*} parentNodeClassName
     */
    insertHtmlInTextNode( textNode, offset, html, parentNodeClassName){
        let previousTextContent = textNode.textContent.slice(0, offset);
        previousTextContent = previousTextContent.trimEnd();
        let textNodePreviousSiblings = this.$extractPreviousSiblingsOuterHTML(textNode) + previousTextContent;

        let nextTextContent = textNode.textContent.slice(offset);
        nextTextContent = this.$checkIfBlankExistAfterOffset( nextTextContent );
        let textNodeNextSiblings = nextTextContent + this.$extractNextSiblingsOuterHTML(textNode);

        let textNodeRecreated = this.$extractElementOuterHTML(textNode.parentNode, textNodePreviousSiblings + html + textNodeNextSiblings);
        return this.updateHTMLElementInNode(textNode.parentNode, textNodeRecreated , parentNodeClassName);
    }
    /**
     * Insert simple text in a DOM node nested in another Dom node with a specified class
     * @param {Node} domNode the node to insert text inside
     * @param {number} offset where text will be inserted
     * @param {String} text the text to insert
     * @param {String} parentNodeClassName class name of the parent node to return as html
     */
    insertTextInNode( domNode, offset, text, parentNodeClassName){
        let currentNode = domNode;
        let textNode = {};
        let siblingNodes = [];

        if (domNode.nodeType === Node.TEXT_NODE) {
            siblingNodes = this.extractNodesFromHTML(domNode.parentNode.innerHTML);
            let previousTextContent = domNode.textContent.slice(0, offset);
            let nextTextContent = domNode.textContent.slice(offset);
            textNode = document.createTextNode(previousTextContent + text + nextTextContent);
            offset = Array.from(domNode.parentNode.childNodes).indexOf(domNode);
            siblingNodes[offset] = textNode;
            currentNode = currentNode.parentNode;
        } else if (domNode.nodeType === Node.ELEMENT_NODE) {
            siblingNodes = this.extractNodesFromHTML(domNode.innerHTML);
            textNode = document.createTextNode(text);
            if (!!domNode.childNodes[offset] && domNode.childNodes[offset].nodeType === Node.TEXT_NODE) {// remove adjacent text node
                textNode.textContent += domNode.childNodes[offset].textContent;
                siblingNodes.splice(offset, offset, textNode);
            } else {
                siblingNodes.splice(offset, 0, textNode);
            }
        }

        let currentHTMLElement = this.createHTMLElementFromHtml(this.$extractElementOuterHTML(currentNode, ''));
        siblingNodes.forEach( node => currentHTMLElement.appendChild(node) );

        return this.rebuildNodeToParent(currentNode, currentHTMLElement.innerHTML, parentNodeClassName);
    }
    /**
     * Insert html in a node specified by a class, return outerHTML result of the node with the content inserted.
     * /!\ If the content of the node is not wrap inside a singe tag (like P) this method will not worked
     *
     * @param {Node} domNode, current html node where is added the html
     * @param {number} offset, position in the current node to add html (number of element
     * before in case actual node of type ELEMENT_NODE or index in text if TEXT_NODE)
     * @param {String} html to insert
     * @param {String} parentNodeClassName, class name of the parent node to return as html
     *
     * /!\Deprecated please refactor or use a different method instead
     */
    insertHTMLInNode (domNode, offset, outerHTML, parentNodeClassName) {
        let htmlContent = '';
        let currentNode = domNode;
        //nodeType text
        if (domNode.nodeType === Node.TEXT_NODE) {
            htmlContent = this.$insertHTMLInTextNode( currentNode, domNode, outerHTML, offset, parentNodeClassName);
        }
        //nodeType element
        else {
            if ( !!domNode.className && domNode.className.includes(parentNodeClassName) ) {
                htmlContent = this.$replaceHTMLInBlockElement(domNode, offset, offset, outerHTML);
            } else if ( !this.$isInlineElement(currentNode.tagName) ) {
                if (this.$isTableElement(currentNode.parentNode.tagName)){
                    if( domNode.tagName === 'TBODY'){
                        htmlContent = this.$insertLinesInTableElement( domNode, offset, outerHTML);
                        return this.updateHTMLElementInNode(domNode, htmlContent, parentNodeClassName);
                    }else {
                        htmlContent = this.$replaceHTMLInBlockElement(domNode, offset, offset, outerHTML);
                        htmlContent = this.$extractElementOuterHTML(currentNode, htmlContent);
                        htmlContent = this.$extractSiblings(currentNode, htmlContent);
                    }
                } else {
                    htmlContent = this.$replaceHTMLInBlockElement(domNode, offset, offset, outerHTML);
                    htmlContent = this.$extractElementOuterHTML(currentNode, htmlContent);
                    htmlContent = this.$extractSiblings(currentNode, htmlContent);
                }
                currentNode = currentNode.parentNode;
            } else { // in the case of insert with inline element  the things to do is add the element after the inline and rebuild the node
                htmlContent = domNode.outerHTML;
                htmlContent += outerHTML;
            }
            htmlContent = this.rebuildNodeToParent(currentNode, htmlContent, parentNodeClassName);
        }
        return htmlContent;
    }
    $insertHTMLInTextNode(currentNode, domNode, outerHTML, offset, parentNodeClassName) {
        let parentNode = currentNode.parentNode;
        let previousTextContent = domNode.textContent.slice(0, offset);
        let domNodePreviousSiblingsOuterHTML = this.$extractPreviousSiblingsOuterHTML(domNode) + previousTextContent;
        let previousSiblingsOuterHTML = !domNodePreviousSiblingsOuterHTML ? '' : this.$extractElementOuterHTML(parentNode, domNodePreviousSiblingsOuterHTML);
        let nextTextContent = domNode.textContent.slice(offset);
        let domNodeNextSiblingsOuterHTML = nextTextContent + this.$extractNextSiblingsOuterHTML(domNode);
        let nextSiblingsOuterHTML = !domNodeNextSiblingsOuterHTML ? '' : this.$extractElementOuterHTML(parentNode, domNodeNextSiblingsOuterHTML);

        if (!this.$isInlineElement(parentNode.tagName)) {
            currentNode = currentNode.parentNode;
            if (this.$isTableElement(parentNode.tagName)){
                return this.$insertHTMLInTableElement( outerHTML, domNodePreviousSiblingsOuterHTML, domNodeNextSiblingsOuterHTML, currentNode, domNode, parentNodeClassName );
            } else {
                let completeHtml = previousSiblingsOuterHTML + outerHTML + nextSiblingsOuterHTML;
                return this.updateHTMLElementInNode(currentNode, completeHtml, parentNodeClassName);
            }
        } else {
            return this.$insertHTMLInInlineText( outerHTML, previousSiblingsOuterHTML, nextSiblingsOuterHTML, currentNode, parentNode, parentNodeClassName );
        }
    }
    /**
     * Insert HTML in a table element
     * @param {HtmlElement} outerHTML
     * @param {HtmlElement} domNodePreviousSiblingsOuterHTML
     * @param {HtmlElement} domNodeNextSiblingsOuterHTML
     * @param {Node} currentNode
     * @param {Node} domNode
     * @param {String} parentNodeClassName
     */
    $insertHTMLInTableElement( outerHTML, domNodePreviousSiblingsOuterHTML, domNodeNextSiblingsOuterHTML, currentNode, domNode, parentNodeClassName ){
        let tableElement = this.createHTMLElementFromHtml(this.$extractElementOuterHTML(domNode.parentNode, ''));
        tableElement.innerHTML = domNodePreviousSiblingsOuterHTML + outerHTML + domNodeNextSiblingsOuterHTML;
        return this.updateHTMLElementInNode(currentNode, tableElement.outerHTML, parentNodeClassName);
    }
    $insertHTMLInInlineText(outerHTML, previousSiblingsOuterHTML, nextSiblingsOuterHTML, currentNode, parentNode, parentNodeClassName ){
        previousSiblingsOuterHTML = this.$rebuildPreviousSiblingsToParentBlockElement(previousSiblingsOuterHTML, parentNode);
        nextSiblingsOuterHTML = this.$rebuildNextSiblingsToParentBlockElement(nextSiblingsOuterHTML, parentNode);
        currentNode = this.$getParentBlockElement(currentNode);
        return this.updateHTMLElementInNode(currentNode, previousSiblingsOuterHTML + outerHTML + nextSiblingsOuterHTML, parentNodeClassName );
    }

    $insertLinesInTableElement(domNode, offset, outerHTML){
        let htmlContent = '';
        for (let childIndex = 0; childIndex < offset; childIndex++) {
            htmlContent += domNode.children[childIndex].outerHTML;
        }
        htmlContent += outerHTML;
        for (let childIndex = offset; childIndex < domNode.children.length; childIndex++) {
            htmlContent += domNode.children[childIndex].outerHTML;
        }
        return htmlContent;
    }

    insertContentInHtmlElement( domNode, innerHTML, ParentNodeClassName ){
        let htmlElement = this.createHTMLElementFromHtml(this.$extractElementOuterHTML(domNode, ''));
        htmlElement.innerHTML = innerHTML;
        return this.updateHTMLElementInNode(domNode, htmlElement.outerHTML, ParentNodeClassName);
    }
}

export default new DomManipulatorService();