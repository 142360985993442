const JURISPRUDENCE                 = 'JUR';
const JURISPRUDENCE_ID              =   101;
const LEGISLATION                   = 'LEG';
const DOCTRINE                      = 'DOC';
const BIBLIOGRAPHY                  = 'BIB';
const GUIDELINE                     = 'GUI';

/**
 * Store all the document type constant
 * @author Sébastien DE SANTIS
 * @since 16/01/2020
 * @version 1.0
 */
export default class ConstantDocumentType {

    static get JURISPRUDENCE(){
        return JURISPRUDENCE;
    }

    static get JURISPRUDENCE_ID(){
        return JURISPRUDENCE_ID;
    }

    static get LEGISLATION(){
        return LEGISLATION;
    }

    static get DOCTRINE() {
        return DOCTRINE;
    }

    static get GUIDELINE() {
        return GUIDELINE;
    }

    static get BIBLIOGRAPHY(){
        return BIBLIOGRAPHY;
    }
}