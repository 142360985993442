const ERROR     = 'ERROR';
const SUCCEED   = 'SUCCEED';
const INFO      = 'INFO';
const WARNING   = 'WARNING';

export default class ConstantNotification{

    static get ERROR(){
        return ERROR;
    }
    static get SUCCEED(){
        return SUCCEED;
    }
    static get INFO(){
        return INFO;
    }
    static get WARNING(){
        return WARNING;
    }
}