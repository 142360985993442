export default {
    namespaced: true,
    state: {
        langList: []
    },
    actions:{
        setLanguages: async ({commit}, languages) => {
            commit('SET_LANGUAGES', languages);
        }
    },
    getters:{
        getLanguages: state => {
            return state.langList;
        },
        getLanguageById: (state) => (languageId) => {
            return state.langList.find((lang)=>{
                return lang.id === Number.parseInt(languageId);
            });
        },
        getLanguageByCode: (state)=> (code)=>{
            return state.langList.find((lang)=>{
                return lang.code === code;
            })
        }
    },
    mutations:{
        SET_LANGUAGES: (state, languages) => state.langList = languages
    }
}
