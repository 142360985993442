<template>
    <v-menu
        :close-on-content-click="false"
        v-model="showMenu"
        :position-x="position.x"
        :position-y="position.y"
        absolute
        offset-y
    >
        <v-list>
            <v-list-tile v-if="!globalAttributes.tableClicked"
                @click="handleMenuAction(manageTableDialog)">
                <v-list-tile-title>{{ $t('structure.table.menu.add') }}</v-list-tile-title>
            </v-list-tile>
            
            <v-list-tile v-if="globalAttributes.tableClicked"
                @click="handleMenuAction(manageTableDialog)">
                <v-list-tile-title>{{ $t('structure.table.menu.edit') }}</v-list-tile-title>
            </v-list-tile>

            <v-list-tile v-if="globalAttributes.tableClicked"
                @click="addRowToTable(before)">
                <v-list-tile-title>{{ $t('structure.table.menu.addRowBefore')}}</v-list-tile-title>
            </v-list-tile>

            <v-list-tile v-if="globalAttributes.tableClicked"
                @click="addRowToTable(after)">
                <v-list-tile-title>{{ $t('structure.table.menu.addRowAfter')}}</v-list-tile-title>
            </v-list-tile>

            <v-divider></v-divider>

            <v-list-tile
                @click="handleMenuAction(editNode)">
                <v-list-tile-title>{{ $t('structure.node.action.edit') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
                @click="handleMenuAction(deleteNode)">
                <v-list-tile-title>{{ $t('structure.node.action.delete') }}</v-list-tile-title>
            </v-list-tile>
        
            <v-divider></v-divider>

            <v-list-tile 
                @click="handleMenuAction(openCreateCustomListDialog)">
                <v-list-tile-title>{{ $t('structure.node.action.customList') }}</v-list-tile-title>
            </v-list-tile>

            <v-divider></v-divider>

            <v-list-tile
                v-if="!!this.modificatorContentChunk && this.modificatorContentChunk.action === COPY_ONLINE_QUOTE"
                @click="handleMenuAction(pasteQuoteText)">
                <v-list-tile-title>{{ $t( 'structure.node.action.pasteQuoteText' ) }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
                v-else
                @click="handleMenuAction(pasteText)">
                <v-list-tile-title>{{ $t( 'structure.node.action.pasteText' ) }}</v-list-tile-title>
            </v-list-tile>
            

            <v-divider v-if="storeName === documentContentStore"></v-divider>

            <v-list-tile v-if="storeName === documentContentStore"
                @click="handleMenuAction(importFiles)">
                <v-list-tile-title>{{ $t('structure.node.action.importFiles') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile v-if="globalAttributes.deleteFilesClicked && storeName === documentContentStore"
                @click="handleMenuAction(deleteFiles)">
                <v-list-tile-title>{{ $t('structure.node.action.deleteFiles') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile v-if="globalAttributes.udpatePictures && storeName === documentContentStore"
                @click="handleMenuAction(udpateFiles)">
                <v-list-tile-title>{{ $t('structure.node.action.updateFiles') }}</v-list-tile-title>
            </v-list-tile>

            <v-divider v-if="storeName === documentContentStore"></v-divider>
            
            <v-list-tile v-if="!globalAttributes.selectedTable && storeName === documentContentStore" 
                @click="handleMenuAction(addQuote)">
                <v-list-tile-title>{{ $t('structure.node.action.addQuote') }}</v-list-tile-title>
            </v-list-tile>
        </v-list>
    </v-menu>
</template>

<script>

import CheckDocumentNodeSelectionService    from '../../../../service/document/checkDocumentNodeSelectionService.js';
import ConstantAction                       from '../../../../shared/constant/constantAction.js';
import ConstantDocumentNode                 from '../../../../shared/constant/constantDocumentNode.js';
import ConstantEvent                        from '../../../../shared/constant/constantEvent.js';
import ConstantNode                         from '../../../../shared/constant/constantNode.js';
import ConstantNodeContentElement           from '../../../../shared/constant/constantNodeContentElement.js'
import ConstantTag                          from '../../../../shared/constant/constantTag.js';
import ConstantStoreName                    from '../../../../shared/constant/constantStoreName.js';
import CustomListService                    from '../../../../service/document/customListService.js';
import documentTreeService                  from '../../../../service/document/documentTreeService';
import DomManipulatorService                from '../../../../service/html/domManipulatorService';
import EventBus                             from '../../../../utils/event-bus';
import TableHtmlService                     from '../../../../service/html/tableHtmlService.js';
import { mapState, mapActions }             from 'vuex';
import * as $                               from 'jquery';
import nodeContentTreeService from '../../../../service/document/nodeContentTreeService.js';

const UPDATE_MENU = 'update:menu';
/** 
 * Open a context menu to manage actions on selected node  
 * 
 * @author Justin WILMET
 * @version 1.0
 * @since 2019-06-06
 */
export default {
    name: 'MenuForNode',
    props: {
        menu: {
            default: false
        },
        position: {
            x: null,
            y: null
        },
        globalAttributes: {
            windowSelection: {
                default: null
            },
            caretPosition: {
                default: null
            },
            deleteFilesClicked: {
                default: false
            },
            udpatePictures: {
                default: false
            },
            tableClicked: {
                default: false
            },
            isSelectedTable: {
                default: false
            },
            idFiles: {
                default: null
            },
            nodeId: {
                default: null
            },
            selectedTable: {
                default: null
            }
        },
        isConsolidation: {
            default: false
        },
        modificatorContentChunk: {
            default: null
        },
        storeName: {
            default: null
        }
    },
    data: () => ({
        nodeToPaste: null,
    }),
    computed: {
        selectedNode(){
            return this.$store.state[`${this.storeName}`].selectedNode;
        },
        ...mapState({
            documentType: state => state.dataSheetStore.metaDocument.documentType
        }),
        showMenu: {
            get(){
                return this.menu;
            },
            set( menu ){
                if( !menu ){
                    this.$emit(UPDATE_MENU, false);
                }
            }
        },
        before(){
            return ConstantNode.WHERE_BEFORE;
        },
        after(){
            return ConstantNode.WHERE_AFTER;
        },
        documentContentStore(){
            return ConstantStoreName.DOCUMENT_CONTENT;
        },
        COPY_ONLINE_QUOTE(){
            return ConstantAction.COPY_ONLINE_QUOTE;
        }
    },
    methods: {
        modifyContent( nodeContent ) {
            this.$store.dispatch(`${this.storeName}/modifyContent`, nodeContent);
        },
        setSelectedNodeContent( nodeContent ) {
            this.$store.dispatch(`${this.storeName}/setSelectedNodeContent`, nodeContent);
        },
        /**
         * Hide the menu, then execute the action selected
         * @param {function} menuAction action to call on click on one menu option
         */
        handleMenuAction(menuAction) {
            menuAction();
        },
        /**
         * Open the dialog to create or modify a table
         */
        manageTableDialog(){
            this.$emit('onOpenDialog', { dialog: ConstantEvent.UPDATE_TABLE} );
            this.close();
        },
        /**
         * Open the selected node on the text editor (tinymce)
         */
        editNode(){
            if( this.selectedNode.label === ConstantTag.TEXT){
                this.$emit('onOpenDialog', { dialog: ConstantEvent.OPEN_DIALOG_CONTENT_EDITOR} );
            }
            this.close();
        },
        /**
         * Delete a node from the document
         */
        deleteNode(){
            this.$emit('onOpenDialog', { dialog: ConstantEvent.DELETE_NODE } );
            this.close();
        },
        /**
         * Open dialog to create a custom list on the node
         */
        openCreateCustomListDialog(){
            this.$emit('onOpenDialog', { dialog: ConstantEvent.OPEN_DIALOG_CREATE_CUSTOM_LIST} );
            this.close();
        },
        /**
         * Open the dialog to import files on the node
         */
        importFiles(){
            this.$emit('onOpenDialog', { dialog: ConstantEvent.IMPORT_FILES} );
            this.close();
        },
        /**
         * Open the dialog to set the new width and height of the selected picture
         */
        udpateFiles(){
            this.$emit('onOpenDialog', { dialog: ConstantEvent.CHANGE_WIDTH_HEIGHT} );
            this.close();
        },
        /**
         * Delete the link of the files in the document
         */
        deleteFiles(){
            let node = document.getElementById(this.globalAttributes.idFiles);
            let a = node.closest('a');
            node.className === ConstantNodeContentElement.CUSTOM_UPLOAD_FILE_CLASS ? a.parentNode.removeChild(a) : node.parentNode.removeChild(node);
            let container = $( `#${ConstantDocumentNode.NODE_CONTENT_CLASS}-${this.globalAttributes.nodeId}`);
            //Avoid creation of several div
            if(container[0].children[0].className === `${ConstantDocumentNode.CONTENT_STYLE_CLASS} ${ConstantDocumentNode.TEXT_NODE_CLASS}`){
                this.modifyContent({ id: this.globalAttributes.nodeId, content: container[0].children[0].innerHTML });
            }else{
                this.modifyContent({ id: this.globalAttributes.nodeId, content: container[0].innerHTML });
            }
            this.close();
        },
        /**
         * Paste the text of the clipboard in the text node
         * If the text contain carriage return character, the method will create a P element for each one.
         */
        async pasteText(){
            let textFromClipboard = await navigator.clipboard.readText();
            if(!!textFromClipboard){
                let htmlContent = nodeContentTreeService.pasteTextInNode( textFromClipboard, this.globalAttributes );
                this.setSelectedNodeContent( htmlContent );
            } else {
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    message: 'administration.structure.action.emptyPaste',
                    type: 'INFO'
                });
            }
            this.close();
        },
        async pasteQuoteText(){
            let textFromClipboard = await navigator.clipboard.readText();
            if(!!textFromClipboard){
                let offset = CheckDocumentNodeSelectionService.checkEndCaretPosition( this.globalAttributes.caretPosition.clickedNodeOffset, this.globalAttributes.caretPosition.clickedFocusNodeOffset );
                let htmlContent = DomManipulatorService.insertHTMLInNode( this.globalAttributes.caretPosition.clickedNode, offset, textFromClipboard , ConstantDocumentNode.TEXT_NODE_CLASS );
                this.setSelectedNodeContent( htmlContent );
            } else {
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    message: 'administration.structure.action.emptyPaste',
                    type: 'INFO'
                });
            }
            this.close();
        },
        /**
         * Add a structured quote to the node as a HTML blockquote
         */
        addQuote(){
            EventBus.$emit(ConstantEvent.ADD_QUOTE);
            this.close();
        },
        /**
         * Add one or several structured rows to the table node
         * @param {String} position, Specification to know where the new row should be added
         */
        async addRowToTable( position ){
            let selectedTBody = TableHtmlService.findFirstTBodyFromTable(this.globalAttributes.caretPosition.clickedNode);
            let textFromClipboard = await navigator.clipboard.readText();
            
            if(!!this.isConsolidation){
                let copiedTableHtml = TableHtmlService.getTableWithoutTextAndFootnotes(textFromClipboard);
                //Loop over the parent of the node to be placed on the tbody element
            
                let sameCols = TableHtmlService.checkNumberOfColumns(copiedTableHtml, selectedTBody.parentNode);
                if ( !sameCols ){
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                        message: 'document.notification.table.wrongNumberOfColumns',
                        type: 'INFO'
                    });
                } else {
                    //We need to find the correct offset, i.e. the rowIndex because the caretPosition.clickedNode is inside a text or a td element
                    let htmlContent = TableHtmlService.insertRowsInExistingTable( selectedTBody, position, this.globalAttributes.windowSelection, copiedTableHtml.outerHTML );
                    this.setSelectedNodeContent(htmlContent); 
                }
            } else {
                let htmlContent = TableHtmlService.insertRowsInExistingTable( selectedTBody, position, this.globalAttributes.windowSelection );
                this.setSelectedNodeContent(htmlContent);
            }
            this.close();
        },
        /** 
         * Close the context menu 
         */
        close(){
            this.$emit(UPDATE_MENU, false);
        }
    }
}
</script>

<style scoped>

</style>
