import ConstantHyperLink  from '../../shared/constant/constantDocumentHyperLink.js';

class DocumentHyperLinkService{

    constructor(){}

    /**
     * Create a document hyperlink HTML anchor.
     * @param {*} documentHyperlin 
     */
    createDocumentHyperLinkAnchor(documentHyperlink, selectedContent){
        return this.$createHyperlink({
            uidDocumentHyperLink: this.$generateUidHyperLink(),
            urlEntered: `${documentHyperlink.referenceDocumentDestination}/`, 
            class: `${ConstantHyperLink.DOCUMENT_HYPERLINK_CLASS} ${documentHyperlink.relationType}`, 
            content: selectedContent
        });
    }
    /**
     * Create a content hyperlink HTML anchor
     * @param {*} documentHyperLink 
     * @param {*} selectedContent 
     */
    createContentHyperLinkAnchor(documentHyperLink, selectedContent) {
        return this.$createHyperlink({
            uidDocumentHyperLink: this.$generateUidHyperLink(),
            urlEntered: `${documentHyperLink.referenceDocumentDestination}/${documentHyperLink.idNodeDestination}`, 
            class: `${ConstantHyperLink.CONTENT_HYPERLINK_CLASS} ${documentHyperLink.relationType} `,
            content: selectedContent
        });
    }
    /**
     * Insert a document hyperlink inside a node content at the target place.
     * @param {*} documentHyperLink
     * @param {*} content
     * @param {*} target
     */
    insertHyperLinkInContent(documentHyperLink, sourceNode){
        var link;
        if(documentHyperLink.hyperLinkType === ConstantHyperLink.DOCUMENT_HYPERLINK_TYPE){
            link = this.createDocumentHyperLinkAnchor(documentHyperLink, sourceNode.selectedContent);
        } else if(documentHyperLink.hyperLinkType === ConstantHyperLink.CONTENT_HYPERLINK_TYPE ){
            link = this.createContentHyperLinkAnchor(documentHyperLink, sourceNode.selectedContent);
        } else if(documentHyperLink.hyperLinkType === ConstantHyperLink.EXTERNAL_HYPERLINK_TYPE){
            link = this.createExternalHyperLinkAnchor(documentHyperLink, sourceNode.selectedContent);
        }
        return link;
    }
    /**
     * Create an external hyperlink and insert it inside a content.
     * @param {*} documentHyperLink 
     * @param {*} selectedContent 
     */
    createExternalHyperLinkAnchor(documentHyperLink, selectedContent){
        let link;
        if(documentHyperLink.hyperLinkSubType === ConstantHyperLink.EXTERNAL_HYPERLINK_TYPE_DIRECTIVE){
            var tab = documentHyperLink.urlEntered.split(',')
            link = this.$createHyperlink({
                uidDocumentHyperLink: this.$generateUidHyperLink(),
                urlEntered: `https://eur-lex.europa.eu/legal-content/FR/ALL/?uri=CELEX:3${tab[0]}${tab[2]}${tab[1].padStart(4,0)}`, 
                target: '_blank',
                class: ConstantHyperLink.EXTERNAL_HYPERLINK_CLASS, 
                content: selectedContent
            });
        }
        else if(documentHyperLink.hyperLinkSubType === ConstantHyperLink.EXTERNAL_HYPERLINK_TYPE_PARLIAMENTARY_FILE){
            link = this.$createHyperlink({
                uidDocumentHyperLink: this.$generateUidHyperLink(),
                urlEntered: `https://chd.lu/wps/portal/public/Accueil/TravailALaChambre/Recherche/RoleDesAffaires?action=doDocpaDetails&id=${documentHyperLink.urlEntered}`, 
                target: '_blank',
                class: ConstantHyperLink.EXTERNAL_HYPERLINK_CLASS, 
                content: selectedContent
            });
        }
        else if(documentHyperLink.hyperLinkSubType === ConstantHyperLink.EXTERNAL_HYPERLINK_TYPE_DEFAULT){
            link = this.$createHyperlink({
                uidDocumentHyperLink: this.$generateUidHyperLink(),
                urlEntered: documentHyperLink.urlEntered, 
                target: '_blank',
                class: ConstantHyperLink.EXTERNAL_HYPERLINK_CLASS, 
                content: selectedContent
            });
        }
        return link;
    }

    /**
     * Create hyperlink canvas
     */
    $createHyperlink( attributes ){
        let a = document.createElement('a');
        a.setAttribute('id', attributes.uidDocumentHyperLink);
        a.setAttribute('href', attributes.urlEntered);
        !!attributes.target ? a.setAttribute('target', attributes.target) : '' ;
        a.className = attributes.class;
        a.innerHTML = attributes.content;
        return a;
    }
    /**
     * Generate a ( client ) uid use for node id generation
     * ( Note: we need a node id for finding / deleting node etc. )
     */
    $generateUidHyperLink( ) {
        return 'xxxxxxxx-xxxx-6xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
            function(c) {
                var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
                return v.toString(16);
            });
    }
}

export default new DocumentHyperLinkService();