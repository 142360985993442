<template>
    <v-dialog v-model="openDialog" persistent max-width="700px">
        <v-card v-if="selectedNode">
            <v-card-title class="headline">
                {{$t('document.node.label.add')}}
            </v-card-title>
            <v-divider></v-divider>
            <v-form ref="formDialogAddNode">
                <v-card-text>
                    <v-container >
                        <span>{{$t('document.node.label.selected')}} :</span>
                        <span v-for="(pathChunk, index) in selectedNode.path" :key="index">
                            /{{pathChunk}}
                        </span>
                        <v-radio-group v-model="where" required>
                            <v-radio
                                :label="$t('document.node.label.before')"
                                :value="WHERE_BEFORE"
                                @click.native="selectWhere()"
                                :disabled="selectedNode.path.length <= 1"
                            ></v-radio>
                            <v-radio 
                                :label="$t('document.node.label.after')"
                                :value="WHERE_AFTER"
                                @click.native="selectWhere()"
                                :disabled="selectedNode.path.length <= 1"
                            ></v-radio>
                            <v-radio 
                                v-if="selectedNode.type !== 'TEXT'"
                                :label="$t('document.node.label.inside')"
                                :value="WHERE_INSIDE"
                                @click.native="selectWhere()"
                            ></v-radio>
                        </v-radio-group>
                        <v-progress-linear indeterminate
                            v-if="!displaySuggestedNodeList"/>
                        <v-select 
                            v-model="selectedSuggestedNode"
                            :items="suggestedNodeList"
                            item-text="label"
                            :label="$t('document.node.label.suggested')"
                            v-else
                            return-object
                            :disabled="suggestedNodeList.length < 1 || !!selectedFullNode"
                            clearable
                            :rules="(!!customNodeType || !!selectedFullNode )? [] : requiredIfEnabled ">
                        </v-select>
                        <v-progress-linear indeterminate
                            v-if="!displaySuggestedNodeList"/>
                        <v-select 
                            v-model="selectedFullNode"
                            :items="fullNodeList"
                            item-text="label"
                            :label="$t('document.node.label.available')"
                            v-else
                            return-object
                            :disabled="fullNodeList.length < 1 || !!selectedSuggestedNode"
                            clearable
                            :rules="(!!customNodeType || !!selectedSuggestedNode) ? [] : requiredIfEnabled ">
                        </v-select>
                        <v-text-field 
                            v-model="customNodeType"
                            :label="$t('document.node.label.type')"
                            :disabled="where === null || !!selectedSuggestedNode || !!selectedFullNode"
                            :rules="(!!selectedSuggestedNode || !!selectedFullNode ) ? [] : requiredIfEnabled"
                        >
                        </v-text-field>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" flat :disabled="!valid" @click.native="resetComponent();">{{$t('document.action.cancel')}}</v-btn>
                    <v-btn color="primary" flat @click.native="addNode();">{{$t('document.action.add')}}</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>

import axios                    from 'axios';
import ConstantEvent            from '../../../../shared/constant/constantEvent.js';
import ConstantNode             from '../../../../shared/constant/constantNode.js';
import DocumentTreeService      from '../../../../service/document/documentTreeService.js';
import EventBus                 from '../../../../utils/event-bus.js';
import ConstantTag              from '../../../../shared/constant/constantTag.js';
import {mapState, mapActions}   from 'vuex';
import ConstantDocumentNode     from "../../../../shared/constant/constantDocumentNode";

const UPDATE_DIALOG = 'update:dialog';
/**
 * 
 * Open a dialog to add a node in a document content tree
 * @author Sébastien DE SANTIS
 * @since 04/10/2018
 * @version 1.0
 * 
 */
export default {
    name: 'DialogAddNode',
    props: {
        storeName: {
            default: null
        },
        dialog: {
            isOpened: false,
            where: null
        },
        idDocumentType: {
            default: null
        },
        position: null
    },
    data: function(){
        return{
            valid: true,
            requiredIfEnabled: [v => !!v || this.$t('document.node.rules.required')],           
            displaySuggestedNodeList: true,
            suggestedNodeList: [],
            fullNodeList: [],
            selectedSuggestedNode: null,
            selectedFullNode: null,
            where: null,
            customNodeType: '',
            WHERE_INSIDE: ConstantNode.WHERE_INSIDE,
            WHERE_AFTER: ConstantNode.WHERE_AFTER,
            WHERE_BEFORE: ConstantNode.WHERE_BEFORE,
        }
    },
    computed:{
        rootNode(){
            return this.$store.state[`${this.storeName}`].rootNode;
        },
        selectedNode(){
            return this.$store.state[`${this.storeName}`].selectedNode;
        },
        ...mapState({
            documentType: state => state.dataSheetStore.metaDocument.documentType
        }),
        flattedTree(){
            return this.$store.state[`${this.storeName}`].flattedTree;
        },
        openDialog: {
            get(){
                if( !!this.position ){
                    this.where = this.position;
                    this.selectWhere();
                }
                return this.dialog;
            },
            set( dialog ){
                if( !dialog ){
                    this.$emit(UPDATE_DIALOG, false);
                }
            }
        }
    },
    methods:{
        /**
         * The two call below are written in a more conventional way because their call depend on which instance
         * of the store need to be call
         */
        addNodeToTree( node ){
            this.$store.dispatch(`${this.storeName}/addNodeToTree`, node);
        },
        addNodePatternToTree( nodePattern ){
            this.$store.dispatch(`${this.storeName}/addNodePatternToTree`, nodePattern);
        },
        /**
         * Load the suggested node list depending on where the new node will be created
         */
        selectWhere: function(){
            let node;
            if(this.where === ConstantNode.WHERE_INSIDE){
                node = this.selectedNode;
            } else {
                node = DocumentTreeService.findParentNode(this.selectedNode, this.flattedTree);
            }
            this.loadSuggestedNodeList(node);
            this.loadAllNodeList();
        },
        /**
         * Load all the possible nodes for this type of document
         */
        loadAllNodeList(){
            this.displaySuggestedNodeList=false;
            let documentTypeId = this.$checkDocumentTypeId();

            axios.get(
                'structure/node',
                {params:{
                        documentTypeId:documentTypeId,
                        property: ConstantDocumentNode.NODE_LABEL
                    }
                }
            ).then( response => {
                if( response.data ){
                    this.fullNodeList = response.data;
                }
                this.displaySuggestedNodeList=true;
            });
        },
        /**
         * Load the suggested node list for the specific path in argument.
         * @param {Node} node, the reference node to search
         */
        loadSuggestedNodeList: function(node){
            this.displaySuggestedNodeList=false;
            if(node.type !== 'MANUAL'){
                let documentType = this.$checkDocumentTypeId();
                axios.get(
                    'structure/node/available',
                    {
                        params: {
                            documentTypeId: documentType,
                            parentNodeLabel: node.label
                        }
                    }
                ).then( response => {
                    if( response.data ){
                        this.suggestedNodeList = response.data;
                    }
                    this.displaySuggestedNodeList=true;
                })
            } else {
                this.displaySuggestedNodeList=true;
            }
        },
        /**
         * Add a node with the specified values.
         * Validate the form before adding the node
         */
        addNode: function(){
            let node =  this.defineSelectedNodeforRequest();
            if(node.isRealCustomNode){
                var nodePattern = DocumentTreeService.createPatternNode(node.label);
                this.addNodePatternToTree({nodePattern: nodePattern, where:this.where});
                this.resetComponent();
            } else {
                let documentType = this.$checkDocumentTypeId();

                axios.post(
                    'structure/node',
                    {
                        documentTypeId: documentType,
                        parentNodeLabel: node.label,
                    }
                ).then( response => {
                    this.addNodeToTree({node: response.data, where:this.where, isOnQuoteNode: this.isOnQuoteNode});
                    this.resetComponent();
                })
            }    
        },
        /**
         * Set the node value depending on which type the user choose
         */
        defineSelectedNodeforRequest(){
            let choosenNode = {};
            if(this.$refs.formDialogAddNode.validate()){
                if(this.customNodeType){
                    choosenNode.label = this.customNodeType;
                    if(this.customNodeType.toUpperCase() !== ConstantTag.TEXT){
                        choosenNode.isRealCustomNode = true;
                    } else {
                        choosenNode.isRealCustomNode = false;
                    }  
                } else {
                    if(this.selectedSuggestedNode){
                        choosenNode = this.selectedSuggestedNode;
                    } else if (this.selectedFullNode){
                        choosenNode = this.selectedFullNode;
                    }
                    choosenNode.isRealCustomNode = false;
                }
            }
            return choosenNode;
        },
        /**
         * Reset all the form component
         */
        resetComponent: function(){
            this.$refs.formDialogAddNode.reset();
            this.suggestedNodeList = [];
            this.fullNodeList = [];
            this.selectedSuggestedNode = null;
            this.selectedFullNode = null;
            this.where = null;
            this.$emit(UPDATE_DIALOG, false);
        },
        /**
         * Depending on if the document type is received by an external
         * component will be set with the external value
         */
        $checkDocumentTypeId( ){
            if( !!this.idDocumentType ){
                return this.idDocumentType;
            } else {
                return this.documentType.id;
            }
        }
    }
}
</script>

