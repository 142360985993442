<template>
    <v-layout row wrap>
        <v-flex xs12>    
            <v-data-table
              :headers="[]"
              :items="paoDocumentList"
              hide-actions
            >
               <template slot="headers" slot-scope="props">
                    <th class="column active text-xs-left">{{ $t('pao.label.title') }}</th>
                    <th class="column active text-xs-left">{{ $t('pao.label.code') }}</th>
                    <th class="column active text-xs-left">{{ $t('pao.label.modificationDate') }}</th>
                    <th class="column active text-xs-left">{{ $t('pao.label.node') }}</th>
                </template>

                 <template slot="no-data">
                    <td colspan="4" class="text-xs-center" >
                        {{ $t('pao.label.none')}}
                    </td>
                </template>

                <template slot="items" slot-scope="props">
                    <td>{{ props.item.pao.title }}</td>
                    <td>{{ props.item.pao.code }}</td>
                    <td>{{ $d(parseDate(props.item.documentDate)) }}</td>
                    <td>{{ props.item.paoNode.label }}</td>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>

<script>
import moment from 'moment';
import ConstantApplication  from '../../../shared/constant/constantApplication';

/**
 * List of pao to link to the document
 * 
 * @author Cédric de BOISVILLIERS, SFEIR Benelux
 * @version 1.0
 * @since 09/09/2019
 */
export default {
    name: 'paoListTabLinkedPao',
    props: {
        paoDocumentList: {
            default: () => []
        }
    },
    methods: {
        unlinkToPao( pao ){
            this.$emit('unlinkToPao', pao);
        },
        parseDate(date){
            if (!!date) {
                return new Date(moment(date).format(ConstantApplication.DEFAULT_DATE_FORMAT));
            } else {
                return '';
            } 
        }
    }
}
</script>

<style scoped>
.pagination{
    margin: 15px;
}
</style>
