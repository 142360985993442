<template>
  <v-dialog v-model="openDialog" persistent max-width="450px">
    <v-card>
      <v-card-title>
        <h2 v-if="updateMode">{{ $t('document.label.update-custom-list') }}</h2>
        <h2 v-else>{{ $t('document.label.createCustomList') }}</h2>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-form lazy-validation v-model="valid" ref="formCreateCustomList">
          <v-combobox
              v-model="bulletStyle"
              :items="bulletList"
              item-value="value"
              @keyup.enter="create()"
              :label="$t('document.label.customListStyle')"
              required
              :rules="bulletStyleRules"
          >

            <template slot="selection" slot-scope="prop" style="display:contents;">
              <span v-if="!prop.item.value.icon" class="truncate"
                    style="margin-left: 3px;"> {{ prop.item.title }}  </span>
              <v-icon v-if="!!prop.item.value.icon" icon small>{{
                  !!prop.item.value.icon ? prop.item.value.icon : ''
                }}
              </v-icon>
            </template>

            <template slot="item" slot-scope="props">
              <v-list-tile-content v-if="!!props.item.value.icon" class="truncate"
                                   style="height:fit-content;width:80%;">
                <v-btn icon>
                  <v-icon small>{{ !!props.item.value.icon ? props.item.value.icon : '' }}</v-icon>
                </v-btn>
              </v-list-tile-content>
              <v-list-tile-content v-else class="truncate" style="height:fit-content;width:80%;margin-left: 22px;">
                {{ props.item.title }}
              </v-list-tile-content>
            </template>
          </v-combobox>
          <v-combobox
              v-model="separatorStyle"
              :items="separatorList"
              item-value="value"
              @keyup.enter="create()"
              :label="$t('document.label.customListSeparatorStyle')"
              :required="!!bulletStyle && bulletStyle.value.class == dashClass "
              :disabled="!!bulletStyle && bulletStyle.value.class == dashClass "
              :rules="separatorStyleRules"
          >

            <template slot="selection" slot-scope="prop" style="display:contents;">
              <span class="truncate" style="margin-left: 3px;"> {{ prop.item.label }}  </span>
            </template>

            <template slot="item" slot-scope="props">
              <v-list-tile-content class="truncate" style="height:fit-content;width:80%;margin-left: 22px;">
                {{ props.item.label }}
              </v-list-tile-content>
            </template>
          </v-combobox>
          <div v-if="!updateMode">
            <v-text-field
                :disabled="updateMode"
                :label="$t('document.label.rowNumber')"
                type="number"
                v-model="rowNumber"
                @keyup.enter="create()"
                required
                :rules="rowNumberRules"
            ></v-text-field>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" flat @click="close();">{{ $t('document.action.cancel') }}</v-btn>
        <v-btn v-if="updateMode" color="primary" flat @click="update();">{{ $t('document.action.edit') }}</v-btn>
        <v-btn v-else color="primary" flat @click="create();">{{ $t('document.action.create') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BulletStyleFactory from '../../../../shared/factory/bulletStyleFactory.js'
import ConstantBulletStyle from '../../../../shared/constant/constantBulletStyle.js';
import CustomListService from '../../../../service/document/customListService.js';

const UPDATE_DIALOG = 'update:dialog';
/**
 *
 * Confirm to manage the creation of a custom list
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 27/05/2019
 */
export default {
  name: 'DialogCreateCustomList',
  props: {
    dialog: {
      default: false
    },
    updateMode: {
      default: false
    }
  },
  data() {
    return {
      rowNumber: null,
      rowNumberRules: [
        v => !!v || this.$t("document.label.required.rowNumber")
      ],
      bulletStyle: null,
      bulletStyleRules: [
        v => !!v || this.$t("document.label.required.customListStyle")
      ],
      separatorStyle: null,
      separatorStyleRules: [
        v => !!v || this.$t("document.label.required.customListSeparatorStyle")
      ],
      valid: false,
      dashClass: ConstantBulletStyle.DASH_STYLE_CLASS
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.dialog;
      },
      set(dialog) {
        if (!dialog) {
          this.$emit(UPDATE_DIALOG, false);
        }
      }
    },
    bulletList() {
      return [
        {title: '-', value: BulletStyleFactory.create(ConstantBulletStyle.DASH_STYLE_CLASS)},
        {title: '*', value: BulletStyleFactory.create(ConstantBulletStyle.STAR_STYLE_CLASS)},
        {title: '', value: BulletStyleFactory.create(ConstantBulletStyle.FILLED_DOT_STYLE_CLASS)},
        {title: '', value: BulletStyleFactory.create(ConstantBulletStyle.OUTLINED_DOT_STYLE_CLASS)},
        {title: '', value: BulletStyleFactory.create(ConstantBulletStyle.FILLED_SQUARE_STYLE_CLASS)},
        {title: '1', value: BulletStyleFactory.create(ConstantBulletStyle.NUMBER_STYLE_CLASS)},
        {title: 'a', value: BulletStyleFactory.create(ConstantBulletStyle.LOWER_ALPHA_STYLE_CLASS)},
        {title: 'A', value: BulletStyleFactory.create(ConstantBulletStyle.UPPER_ALPHA_STYLE_CLASS)},
        {title: 'iv', value: BulletStyleFactory.create(ConstantBulletStyle.LOWER_ROMAN_STYLE_CLASS)},
        {title: 'IV', value: BulletStyleFactory.create(ConstantBulletStyle.UPPER_ROMAN_STYLE_CLASS)}
      ]
    },
    separatorList() {
      return [
        {label: '.', value: ConstantBulletStyle.DOT_SEPARATOR},
        {label: ')', value: ConstantBulletStyle.ONE_BRACKET_SEPARATOR},
        {label: '( )', value: ConstantBulletStyle.DOUBLE_BRACKET_SEPARATOR},
        {label: '°', value: ConstantBulletStyle.DEGREE_SEPARATOR},
        {label: ' ', value: ConstantBulletStyle.BLANK_SEPARATOR}
      ]
    }
  },
  methods: {
    /**
     * Create a custom list base on the selected bullet
     */
    create() {
      if (this.bulletStyle.value.class === ConstantBulletStyle.DASH_STYLE_CLASS) {
        this.separatorStyle = {label: ' ', value: ConstantBulletStyle.BLANK_SEPARATOR};
      }
      let customList = CustomListService.createCustomList(this.bulletStyle.value, this.rowNumber, this.separatorStyle.value);
      this.$emit('onCreateCustomList', customList);
      this.close();
    },
    /**
     * Update a custom list with the selected bullet and separator style.
     */
    update() {
      if (this.bulletStyle.value.class === ConstantBulletStyle.DASH_STYLE_CLASS) {
        this.separatorStyle = {label: ' ', value: ConstantBulletStyle.BLANK_SEPARATOR};
      }
      this.$emit('onUpdateCustomList', this.bulletStyle.value.class, this.separatorStyle.value);
      this.close();
    },
    /**
     * Reset all the form component
     */
    close() {
      this.rowNumber = null;
      this.bulletStyle = null;
      this.separatorStyle = null;
      this.$emit(UPDATE_DIALOG, false);
    }
  }
}
</script>
