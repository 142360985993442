<template>
  <v-menu 
        v-model="showMenu"
        :position-x="position.x"
        :position-y="position.y"
        absolute
        offset-y>
        <v-list>
            <v-list-tile
                v-if="isReplacementTextOptionAvailable()"
                @click="handleMenuAction(hideNodeWithReplacementText)">
                <v-list-tile-title>{{ $t('structure.node.action.hide-with-replacement-text') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
                v-if="clickedNodeDocument.visible"
                @click="handleMenuAction(hideNode)">
                <v-list-tile-title>{{ $t('structure.node.action.hide') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
                v-else
                @click="handleMenuAction(revealNode)">
                <v-list-tile-title>{{ $t('structure.node.action.reveal') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
                v-if="clickedNodeDocument.visible"
                @click="handleMenuAction(deleteNode)">
                <v-list-tile-title>{{ $t('structure.node.action.delete') }}</v-list-tile-title>
            </v-list-tile>
        </v-list>
  </v-menu>
</template>

<script>
import PaoStructureStoreTypes   from '../../../../shared/constant/store/module/paoStructureTypes';
import PaoDocumentService       from '../../../../service/pao/paoDocumentService';
import ConstantNode             from '../../../../shared/constant/constantNode';

const UPDATE_MENU = 'update:menu';
/**
 * Open a menu to manage actions on title
 * 
 * @author Justin WILMET
 * @version 1.0
 * @since 2019-11-11
 */
export default {
    name: 'MenuTitleNode',
    props:{
        menu: {
            default: false
        },
        position: {
            x: null,
            y: null
        },
        storeName: {
            default: null
        }
    },
    computed: {
        clickedNodeDocument(){
            return this.$store.state[`${this.storeName}`].selectedNode;
        },
        showMenu: {
            get(){
                return this.menu;
            },
            set( menu ){
                if( !menu ){
                    this.$emit(UPDATE_MENU, false);
                }
            }
        },
        documentId(){
            return this.$store.state[`${this.storeName}`].documentId;
        },
        reference(){
            return this.$store.state[`${this.storeName}`].reference;
        }
    },
    methods: {
        /**
         * Handle the action present in the menu.
         */
        handleMenuAction(menuAction) {
            menuAction();
        },
        hideNodeWithReplacementText(){
            this.$store.dispatch(`${this.storeName}/updateSelectedNodeVisibility`, false );
            let node = PaoDocumentService.createReplacementNode(this.clickedNodeDocument, this.reference);
            this.$store.dispatch(`${this.storeName}/addNodeToTree`, {node: node, where: ConstantNode.WHERE_BEFORE})
            this.close();
        },
        /**
         * Action menu to hide a node and all his child.
         */
        hideNode(){
            this.$store.dispatch(`${this.storeName}/updateSelectedNodeVisibility`, false );
            this.close();
        },
        /**
         * Action menu to reveal a node and all his child.
         */
        revealNode(){
            this.$store.dispatch(`${this.storeName}/updateSelectedNodeVisibility`, true );
            this.close();
        },
        /**
         * Detect if the node is available to add replacement node
         */
        isReplacementTextOptionAvailable(){
            return !!this.clickedNodeDocument.visible && PaoDocumentService.isNodeAvailableForReplacementText(this.clickedNodeDocument);
        },
        /**
         * Delete a node
         */
        deleteNode(){
            this.$store.dispatch(`${this.storeName}/deleteSelectedNode`, this.clickedNodeDocument );
            this.close();
        },
        /** 
         * Close the context menu
         */
        close(){
            this.$emit(UPDATE_MENU, false);
        }
    }

}
</script>

<style>

</style>