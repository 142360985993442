<template>
    <v-dialog v-model="openDialog" fullscreen persistent>
        <v-card>
            <v-layout row fill-height > 
                <v-flex class="filter_sizer"></v-flex>
                <v-layout column fill-height>
                    <v-flex style="margin-top:70px;">
                            <v-card-title>
                                <div class="headline center">{{$t('document.label.context')}}</div>
                                <div class="grey--text center">{{sourceNode.selectedContent}}</div>
                            </v-card-title>
                            <v-card-text>{{sourceNode.selectedNode}}</v-card-text>
                            
                            <v-divider></v-divider>

                            <v-card-title class="justify-center">
                                <div class="headline">
                                    {{$t('document.label.hyperLinkTitle')}}
                                </div>
                            </v-card-title>
                            <v-card-text>
                                <v-radio-group v-model="hyperLinkSubType" row class="justify-center">
                                    <v-radio
                                    :label="$t('document.label.directive')"
                                    :value=1
                                    ></v-radio>
                                    <v-radio
                                    :label="$t('document.label.parliamentaryDocument')"
                                    :value=2
                                    ></v-radio>
                                    <v-radio
                                    :label="$t('document.label.url')"
                                    :value=3
                                    ></v-radio>
                                </v-radio-group>
                            </v-card-text>
                            <v-form ref="formDialogAddHyperLink" v-model="valid" lazy-validation>
                                <v-card-text>
                                    <v-container >
                                        <v-text-field 
                                            v-if="hyperLinkSubType === EXTERNAL_HYPERLINK_TYPE_DIRECTIVE"
                                            v-model="urlEntered"
                                            :label="$t('document.label.directive')"
                                            :rules="hyperlinkRequired"
                                            :hint="$t('document.hint.directive')"
                                        ></v-text-field>
                                        <v-text-field 
                                            v-if="hyperLinkSubType === EXTERNAL_HYPERLINK_TYPE_PARLIAMENTARY_FILE"
                                            v-model="urlEntered"
                                            :label="$t('document.label.parliamentaryDocument')"
                                            :rules="hyperlinkRequired"
                                        ></v-text-field>
                                        <v-text-field 
                                            v-if="hyperLinkSubType === EXTERNAL_HYPERLINK_TYPE_DEFAULT"
                                            v-model="urlEntered"
                                            :label="$t('document.label.hyperLink')"
                                            :rules="[rules.hyperlinkRequired, rules.hyperlinkURL]"
                                        ></v-text-field>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="error" flat @click.native="resetComponent">{{$t('document.action.cancel')}}</v-btn>
                                    <v-btn color="primary" flat @keyup.enter="addHyperlink" @click.native="addHyperlink">{{$t('document.action.add')}}</v-btn>
                                </v-card-actions>
                            </v-form>
                    </v-flex>
                </v-layout>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
import ConstantHyperlink        from '../../../../shared/constant/constantDocumentHyperLink.js';
import ConstantEvent            from '../../../../shared/constant/constantEvent.js';
import ConstantDocumentNode     from '../../../../shared/constant/constantDocumentNode.js'
import ConstantNotification     from '../../../../shared/constant/constantNotification.js'
import EventBus                 from '../../../../utils/event-bus.js';
import DocumentHyperlinkService from '../../../../service/document/documentHyperLinkService.js'
import domManipulatorService    from '../../../../service/html/domManipulatorService.js';
import { mapActions, mapState } from 'vuex';
import axios                    from 'axios';

const UPDATE_DIALOG = 'update:dialog';
/**
 * 
 * Document search component.
 * @author Justin WILMET, SFEIR benelux
 * @version 1.0
 * @since 12/11/2018
 * 
 */
export default {
    name: "DialogExternalHyperlink",
    props: {
        hyperlinkType: {
            default: null
        },
        dialog: {
            default: false
        }
    },
    data() {
        return {
            valid: false,
            urlEntered: '',
            hyperLinkSubType: ConstantHyperlink.EXTERNAL_HYPERLINK_TYPE_DEFAULT,
            EXTERNAL_HYPERLINK_TYPE_DEFAULT: ConstantHyperlink.EXTERNAL_HYPERLINK_TYPE_DEFAULT,
            EXTERNAL_HYPERLINK_TYPE_DIRECTIVE: ConstantHyperlink.EXTERNAL_HYPERLINK_TYPE_DIRECTIVE,
            EXTERNAL_HYPERLINK_TYPE_PARLIAMENTARY_FILE: ConstantHyperlink.EXTERNAL_HYPERLINK_TYPE_PARLIAMENTARY_FILE,
            rules: {
                hyperlinkRequired : value => !!value || this.$t("document.node.rules.required"),
                hyperlinkURL: value => {
                    const pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
                    return pattern.test(value) ||  this.$t("document.error.badHyperLink")
                }
            },  
            hyperlinkRequired: [ 
                value => !!value || this.$t("document.node.rules.required")
            ]
        }
    },
    computed:{
        ...mapState({
            sourceNode: state => state.hyperLinkStore.sourceNode,
            documentHyperlink: state => state.hyperLinkStore.documentHyperLink
        }),
        openDialog: {
            get() {
                return this.dialog;
            },
            set(dialog) {
                if(!dialog) {
                    this.$emit(UPDATE_DIALOG, false);
                }
            }
        }
    },
    created: function(){
        EventBus.$on(ConstantEvent.OPEN_DIALOG_EXTERNAL_HYPERLINK, this.listener);
    },
    beforeDestroy: function(){
        EventBus.$off(ConstantEvent.OPEN_DIALOG_EXTERNAL_HYPERLINK);
        this.resetComponent();
    },
    methods:{
        ...mapActions({
            modifyContent: 'documentContent/modifyContent',
            initExternalHyperLinkData: 'hyperLinkStore/initExternalHyperLinkData'
        }),
        /**
         * Open the dialog
         */
        listener(){
            this.dialog = true;
        },
        /**
         * Add a node with the specified values.
         * Validate the form before adding the node
         */
        addHyperlink: function(){
            if(this.$refs.formDialogAddHyperLink.validate()){
                this.initExternalHyperLinkData({url: this.urlEntered, hyperLinkSubType: this.hyperLinkSubType});
                let link = DocumentHyperlinkService.insertHyperLinkInContent( this.documentHyperlink, this.sourceNode );
                let htmlContent = domManipulatorService.replaceTextByHTMLElementInNode(
                    this.sourceNode.caretPosition.clickedNode,
                    this.sourceNode.caretPosition.clickedNodeOffset,
                    this.sourceNode.caretPosition.clickedFocusNodeOffset,
                    link,
                    ConstantDocumentNode.TEXT_NODE_CLASS,
                    this.sourceNode.caretPosition.focusNode );
                this.modifyContent({
                    id: this.sourceNode.id, 
                    content: htmlContent
                })
                this.resetComponent();
            }
        },
        /**
         * Reset all the form component
         */
        resetComponent: function(){
            this.valid = false;
            this.$emit(UPDATE_DIALOG, false);
        }
    }
}
</script>

<style scoped>
    .filter_sizer{
        min-width: 350px;
        max-width: 350px;
        background-color: #355B7F;
    }
    .document_hyperlink_sizer{
        width: 100%;
        height:100vh;
    }
    .btn-cancel{
        bottom:10px;
        right:50px;
        position:absolute;
    }
    .backtodoc{
        font-weight: bold;
        color:#a7372e;
        cursor: pointer;
    }
    .contain-radio{
        margin-top:-20px;
        transform: translate(40%,0);
        font-weight: bold;
    }
    .halfCard_linkType {
        width: 50%; 
        height: 200px;
        float: left;
    }
    .halfCard_node {
        width: 50%; 
        height: 200px;
        float: right;
        overflow: hidden;
    }
    .headline{
        color:#355B7F;
        font-weight: bold;
    }
    .center{width: 100%;text-align: center;}

    .alignWithTitle{
        margin-left: 20px;
        margin-right: 20px;
    }
</style>
