<template>
    <v-layout row wrap align-left>
        <v-flex>
            <v-container>
                <h1 class="bolded" v-if="$route.params.id == null">{{$t('administration.keyword.manage.add.title')}}</h1>
                <h1 class="bolded" v-if="$route.params.id != null">{{$t('administration.keyword.manage.edit.title')}}</h1>
                <v-form ref="formKeyword" class="form-style" v-model="valid" lazy-validation>
                    <v-layout column justify-center>
                        <v-flex md6>
                            <v-text-field
                                 v-model="keywordToManage.label"
                                :label="$t('administration.keyword.manage.keyword')"
                                :rules="labelRules"
                                required
                                clearable/>
                        </v-flex>
                        <v-flex xs8>

                            <v-layout row align-center>
                                <v-flex xs9>
                                    <v-radio-group v-model="keywordToManage.level" row>
                                        <v-radio
                                        :label="$t('administration.keyword.manage.level1')"
                                        :value=1
                                        ></v-radio>
                                        <v-radio
                                        :label="$t('administration.keyword.manage.level2')"
                                        :value=2
                                        ></v-radio>
                                        <v-radio
                                        :label="$t('administration.keyword.manage.level3')"
                                        :value=3
                                        ></v-radio>
                                    </v-radio-group>
                                </v-flex>
                                <v-flex xs3 justify-end>
                                    <!-- BUTTONS -->
                                    <v-tooltip top class="align-action-end">
                                        <v-btn color="#355B7F" small fab  slot="activator"  :to="{ name: 'KeywordSearch' }">
                                            <v-icon color="white" >cancel</v-icon>
                                        </v-btn>
                                        <span>{{ $t("administration.keyword.manage.action.cancel") }}</span>
                                    </v-tooltip>
                                    <v-tooltip v-if="!!keywordToManage.level" top class="align-action-end">
                                        <v-btn color="primary" small fab dark slot="activator" icon @click="manage(keywordToManage)" >
                                            <v-icon>save</v-icon>
                                        </v-btn>
                                        <span v-if="$route.params.id == null">{{ $t("administration.keyword.manage.action.add") }}</span>
                                        <span v-if="$route.params.id != null">{{ $t("administration.keyword.manage.action.save") }}</span>
                                    </v-tooltip>
                                </v-flex>
                                </v-layout>

                        </v-flex>
                    </v-layout>
                </v-form>
            </v-container>
        </v-flex>
    </v-layout>
</template>

<script>
import axios            from 'axios';
import EventBus         from '../../../utils/event-bus.js';
import ConstantEvent    from '../../../shared/constant/constantEvent.js'

/**
 *
 * Form to create or update a keyword
 *
 * @author Cédric de BOISVILLIERS, SFEIR Benelux
 * @version 1.0
 * @since 20/11/2018
 */
export default {
    name: 'KeywordManagement',
    data () {
        return {
            valid: false,
            keywordToManage: {
                id: "",
                label: "",
                level: null,
            },
            labelRules: [
                v => !!v || this.$t("administration.keyword.manage.required")
            ]
        }
    },
    mounted(){
        /**
         * When the page is charging, we pay attention if an id is pass,
         * if it's the case, it means that we update a keyword instead of create one
         */
        if(this.$route.params.id !== undefined) {
            this.loadKeyword(this.$route.params.id);
        }
    },
    methods:{
        /**
         *  Create or update a keyword
         * @param newKeyword
         */
        manage(keywordToManage) {
            if(this.$refs.formKeyword.validate()){
                var method = null;
                if(this.$route.params.id) {
                    method = "PUT",
                    keywordToManage.id = this.$route.params.id;
                } else {
                    method = "POST";
                }
                axios( {
                    method: method,
                    url: "administration/keyword",
                    data: keywordToManage
                }).then( () => {
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                            message: 'administration.keyword.manage.' +  (!this.$route.params.id ? 'add' : 'edit') +'.success',
                            type: 'SUCCEED'
                        }
                    );
                    this.$router.go( -1 );
                });
            }
        },

        /**
         * Retrieve a keyword with his id
         * @param id
         */
        loadKeyword( id ) {
            axios.get(
                `administration/keyword/${id}`
            ).then( result => {
                this.keywordToManage = result.data;
            });
        }
    }
}
</script>
<style>

.form-style {
    margin-top:50px;
}
.align-action-end{
    float: right;
}
.bolded{
    font-weight: bold;color: #355B7F;
}
</style>
