const ADD_KEYWORD = 'event-document-node-add-keyword';
const ADD_NODE = 'event-document-node-content-add-node';
const ADD_NOTIFICATION = 'event-add-notification';
const ADD_QUOTE = 'event-dialog-node-content-tree-add-quote';
const CHANGE_WIDTH_HEIGHT = 'event-dialog-change-width-height';
const CONFIRM_DIALOG_OPERATION_DONE = 'event-confirm-dialog-operation-done';
const DELETE_NODE = 'event-document-node-content-delete-node';
const DOCUMENT_HYPERLINK_LIST = 'document-hyperlink-list';
const DOCUMENT_LIST = 'document-list';
const DOCUMENT_VALIDATE = 'event-document-validate';
const FILTER_DOCUMENT = 'event-filter-document';
const FILTER_PAO = 'event-filter-pao';
const FORMAT_TEXT = 'event-dialog-html-element-format-text';
const HIDE_BACK_ARROW_FOR_POPUP = 'event-hide-back-arrow-for-popup;'
const IMPORT_FILES = 'event-dialog-import-files';
const MODIFY_QUOTE = 'event-dialog-modify-quote';
const ON_MANAGE_QUOTE = 'event-on-manage-quote';
const OPEN_BOL_DIALOG_ADD_PAO_CONTENT = 'event-open-bol-dialog-add-pao-content';
const OPEN_BOL_DIALOG_CONFIRMATION = 'event-open-bol-dialog-confirmation';
const OPEN_DIALOG_ADD_NODE = 'event-open-dialog-add-node';
const OPEN_DIALOG_CONTENT_EDITOR = 'event-open-dialog-content-editor';
const OPEN_DIALOG_CREATE_CUSTOM_LIST = 'event-open-dialog-create-custom-list';
const OPEN_DIALOG_UPDATE_CUSTOM_LIST = 'event-open-dialog-update-custom-list';
const OPEN_DIALOG_DOCUMENT_HYPERLINK = 'event-document-hyper-link';
const OPEN_DIALOG_DOCUMENT_HYPERLINK_TYPE = 'event-document-hyper-link-list-select-type';
const OPEN_DIALOG_DOCUMENT_NODE_HYPERLINK = 'event-document-node-hyper-link';
const OPEN_DIALOG_EXTERNAL_HYPERLINK = 'event-hyper-link-management-add-hyper-link';
const OPEN_DIALOG_FOOTNOTES = 'event-document-content-edit-text-node-footnotes';
const OPEN_DIALOG_TFOOT = 'event-document-content-edit-text-node-tfoot';
const OPEN_DIALOG_VIEW_LINK = 'event-dialog-view-link';
const PASTE_NODE = 'event-paste-node';
const RELOAD_DOCUMENT = 'event-document-reload';
const UPDATE_TABLE = 'event-node-content-table-update';
const SELECT_NODE_FROM_CONTENT_TREE = 'select-node-from-node-content-tree';
const SELECT_NODE_FROM_TREE = 'select-node-from-node-tree';
const SEND_ID_DOCUMENT = 'send-id-document';
const SEND_ID_NODE_DESTINATION = 'event-send-id-node-destination';
const SHOW_BACK_ARROW_FOR_POPUP = 'event-show-back-arrow-for-popup';
const SEND_ID_TOC = 'event-send-id-toc';
const OPEN_ID_TOC = 'event-open-id-toc';
const CLICK_ON_DOCUMENT_NODE = 'event-click-on-document-node';
const CONFIRM_ADD_PAO_CONTENT = 'event-confirm-add-pao-content';
const RESET_FILTER_PAGINATION = 'event-reset-pagination';
const UPDATE_DOCUMENT_KEYWORD_DIALOG = 'event-update-document-keyword-dialog';


export default class ConstantEvent {

    static get UPDATE_DOCUMENT_KEYWORD_DIALOG() {
        return UPDATE_DOCUMENT_KEYWORD_DIALOG;
    }

    static get RESET_FILTER_PAGINATION() {
        return RESET_FILTER_PAGINATION;
    }

    static get ADD_KEYWORD() {
        return ADD_KEYWORD;
    }

    static get ADD_NODE() {
        return ADD_NODE;
    }

    static get CLICK_ON_DOCUMENT_NODE() {
        return CLICK_ON_DOCUMENT_NODE;
    }

    static get ADD_NOTIFICATION() {
        return ADD_NOTIFICATION;
    }

    static get ADD_QUOTE() {
        return ADD_QUOTE;
    }

    static get CHANGE_WIDTH_HEIGHT() {
        return CHANGE_WIDTH_HEIGHT;
    }

    static get DELETE_NODE() {
        return DELETE_NODE;
    }

    static get DOCUMENT_HYPERLINK_LIST() {
        return DOCUMENT_HYPERLINK_LIST;
    }

    static get DOCUMENT_LIST() {
        return DOCUMENT_LIST;
    }

    static get DOCUMENT_VALIDATE() {
        return DOCUMENT_VALIDATE;
    }

    static get FILTER_DOCUMENT() {
        return FILTER_DOCUMENT;
    }

    static get FORMAT_TEXT() {
        return FORMAT_TEXT;
    }

    static get HIDE_BACK_ARROW_FOR_POPUP() {
        return HIDE_BACK_ARROW_FOR_POPUP;
    }

    static get IMPORT_FILES() {
        return IMPORT_FILES;
    }

    static get MODIFY_QUOTE() {
        return MODIFY_QUOTE;
    }

    static get ON_MANAGE_QUOTE() {
        return ON_MANAGE_QUOTE;
    }

    static get FILTER_PAO() {
        return FILTER_PAO;
    }

    static get OPEN_DIALOG_ADD_NODE() {
        return OPEN_DIALOG_ADD_NODE;
    }

    static get OPEN_DIALOG_CONTENT_EDITOR() {
        return OPEN_DIALOG_CONTENT_EDITOR;
    }

    static get OPEN_DIALOG_CREATE_CUSTOM_LIST() {
        return OPEN_DIALOG_CREATE_CUSTOM_LIST;
    }

    static get OPEN_DIALOG_DOCUMENT_HYPERLINK() {
        return OPEN_DIALOG_DOCUMENT_HYPERLINK;
    }

    static get OPEN_DIALOG_DOCUMENT_HYPERLINK_TYPE() {
        return OPEN_DIALOG_DOCUMENT_HYPERLINK_TYPE;
    }

    static get OPEN_DIALOG_DOCUMENT_NODE_HYPERLINK() {
        return OPEN_DIALOG_DOCUMENT_NODE_HYPERLINK;
    }

    static get OPEN_DIALOG_EXTERNAL_HYPERLINK() {
        return OPEN_DIALOG_EXTERNAL_HYPERLINK;
    }

    static get OPEN_DIALOG_FOOTNOTES() {
        return OPEN_DIALOG_FOOTNOTES;
    }

    static get OPEN_DIALOG_TFOOT() {
        return OPEN_DIALOG_TFOOT;
    }

    static get OPEN_DIALOG_VIEW_LINK() {
        return OPEN_DIALOG_VIEW_LINK;
    }

    static get PASTE_NODE() {
        return PASTE_NODE;
    }

    static get RELOAD_DOCUMENT() {
        return RELOAD_DOCUMENT;
    }

    static get UPDATE_TABLE() {
        return UPDATE_TABLE;
    }

    static get SELECT_NODE_FROM_CONTENT_TREE() {
        return SELECT_NODE_FROM_CONTENT_TREE;
    }

    static get SELECT_NODE_FROM_TREE() {
        return SELECT_NODE_FROM_TREE;
    }

    static get SEND_ID_DOCUMENT() {
        return SEND_ID_DOCUMENT;
    }

    static get SEND_ID_TOC() {
        return SEND_ID_TOC;
    }

    static get OPEN_ID_TOC() {
        return OPEN_ID_TOC;
    }

    static get SEND_ID_NODE_DESTINATION() {
        return SEND_ID_NODE_DESTINATION;
    }

    static get SHOW_BACK_ARROW_FOR_POPUP() {
        return SHOW_BACK_ARROW_FOR_POPUP;
    }

    static get OPEN_DIALOG_UPDATE_CUSTOM_LIST() {
        return OPEN_DIALOG_UPDATE_CUSTOM_LIST;
    }

    static get OPEN_BOL_DIALOG_CONFIRMATION() {
        return OPEN_BOL_DIALOG_CONFIRMATION;
    }

    static get OPEN_BOL_DIALOG_ADD_PAO_CONTENT() {
        return OPEN_BOL_DIALOG_ADD_PAO_CONTENT;
    }

    static get CONFIRM_ADD_PAO_CONTENT() {
        return CONFIRM_ADD_PAO_CONTENT;
    }

    static get CONFIRM_DIALOG_OPERATION_DONE() {
        return CONFIRM_DIALOG_OPERATION_DONE;
    }
}