<template>
    <v-menu
        v-model="showMenu"
        :close-on-content-click="false"
        :position-x="position.x"
        :position-y="position.y"
        absolute
        offset-y
    >
        <v-list>
            <!-- FOR TABLES -->
            <v-list-tile
                v-if="!globalAttributes.tableClicked"
                @click="handleMenuAction(manageTableDialog)"
            >
                <v-list-tile-title>{{ $t('structure.table.menu.add') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
                v-if="globalAttributes.tableClicked"
                @click="handleMenuAction(manageTableDialog)"
            >
                <v-list-tile-title>{{ $t('structure.table.menu.edit') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
                v-if="globalAttributes.tableClicked"
                @click="addRowToTable(before)"
            >
                <v-list-tile-title>{{ $t('structure.table.menu.addRowBefore') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
                v-if="globalAttributes.tableClicked"
                @click="addRowToTable(after)"
            >
                <v-list-tile-title>{{ $t('structure.table.menu.addRowAfter') }}</v-list-tile-title>
            </v-list-tile>

            <v-divider />

            <!-- FOR NODES -->
            <v-list-tile
                @click="handleMenuAction(editNode)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.edit') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
                @click="handleMenuAction(deleteNode)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.delete') }}</v-list-tile-title>
            </v-list-tile>
        
            <v-divider />

            <!-- FOR CUSTOMLIST -->
            <v-list-group no-action>
                <v-list-tile
                    slot="activator"
                    @click="keepUnmodifiedProps()"
                >
                    <v-list-tile-content>
                        <v-list-tile-title>{{ $t('structure.node.action.customList') }}</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
                <v-list-tile
                    v-if="!list.node"
                    @click="transformTextToCustomList(SELECTION)"
                >
                    <v-list-tile-title>{{ $t('structure.node.action.transformSelection') }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                    v-if="!list.node"
                    @click="transformTextToCustomList(CLIPBOARD)"
                >
                    <v-list-tile-title>{{ $t('structure.node.action.transformClipboard') }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile 
                    @click="handleMenuAction(openCreateCustomListDialog)"
                >
                    <v-list-tile-title>{{ $t('structure.node.action.createCustomList') }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                    v-if="!!list.node && !list.isHtmlList"
                    @click="addRowToCustomList(before)"
                >
                    <v-list-tile-title>{{ $t('structure.customList.addRowBefore') }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                    v-if="!!list.node && !list.isHtmlList"
                    @click="addRowToCustomList(after)"
                >
                    <v-list-tile-title>{{ $t('structure.customList.addRowAfter') }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                    v-if="!!list.node && !list.isHtmlList"
                    @click="updateFormat()"
                >
                    <v-list-tile-title>{{ $t('structure.customList.update-format') }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                    v-if="!!list.node && list.isHtmlList"
                    @click="handleMenuAction(listToCustomList)"
                >
                    <v-list-tile-title>{{ $t('structure.node.action.listToCustomList') }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                    v-if="!!list.node && !list.isHtmlList && isConsolidation"
                    @click="insertLinesToCustomList(before)"
                >
                    <v-list-tile-title>{{ $t('structure.node.action.insertLinesBefore') }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                    v-if="!!list.node && !list.isHtmlList && isConsolidation"
                    @click="insertLinesToCustomList(after)"
                >
                    <v-list-tile-title>{{ $t('structure.node.action.insertLinesAfter') }}</v-list-tile-title>
                </v-list-tile>
            </v-list-group>

            <v-divider />

            <!-- FOR TEXT -->
            <v-list-tile
                @click="handleMenuAction(formatText)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.format') }}</v-list-tile-title>
            </v-list-tile>

            <v-list-group
                v-if="modificatorContentChunkActionCopyOnlineQuote !== copyOnlineQuote"
                no-action
            >
                <v-list-tile slot="activator">
                    <v-list-tile-content>
                        <v-list-tile-title>{{ $t('structure.node.action.paste') }}</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
                <v-list-tile
                    @click="handleMenuAction(pasteTextInLine)"
                >
                    <v-list-tile-title>{{ $t('structure.node.action.pasteTextInLine') }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                    @click="handleMenuAction(pasteText)"
                >
                    <v-list-tile-title>{{ $t( 'structure.node.action.pasteText' ) }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                    @click="handleMenuAction(pasteAsCode)"
                >
                    <v-list-tile-title>{{ $t('structure.node.action.pasteAsCode') }}</v-list-tile-title>
                </v-list-tile>
            </v-list-group>

            <v-list-tile
                v-if="!!this.modificatorContentChunk && this.modificatorContentChunk.action === copyOnlineQuote"
                @click="handleMenuAction(pasteTextInLine)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.pasteQuoteText') }}</v-list-tile-title>
            </v-list-tile>
            
            <v-list-tile
                @click="handleMenuAction(replaceText)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.replaceText') }}</v-list-tile-title>
            </v-list-tile>

            <v-divider v-if="storeName === documentContentStore" />

            <!-- FOR FILES -->
            <v-list-tile
                v-if="storeName === documentContentStore"
                @click="handleMenuAction(importFiles)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.importFiles') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
                v-if="globalAttributes.deleteFilesClicked && storeName === documentContentStore"
                @click="handleMenuAction(deleteFiles)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.deleteFiles') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
                v-if="globalAttributes.udpatePictures && storeName === documentContentStore"
                @click="handleMenuAction(udpateFiles)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.updateFiles') }}</v-list-tile-title>
            </v-list-tile>

            <v-divider v-if="storeName === documentContentStore" />

            <!-- FOR FOOTNOTES -->
            <v-list-tile
                v-if="storeName === documentContentStore"
                @click="handleMenuAction(createFootnote)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.footnote') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
                v-if="globalAttributes.tableClicked"
                @click="handleMenuAction(createTableFootnote)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.tfoot') }}</v-list-tile-title>
            </v-list-tile>

            <v-divider v-if="storeName === documentContentStore" />

            <!-- FOR QUOTES -->
            <v-list-tile
                v-if="!quoteClicked && !globalAttributes.selectedTable && storeName === documentContentStore"
                @click="handleMenuAction(addQuote)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.addQuote') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
                v-if="quoteClicked && storeName === documentContentStore && !isInlineQt"
                @click="handleMenuAction(updateQuote)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.modifyQuote') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
                v-if="quoteClicked && storeName === documentContentStore"
                @click="handleMenuAction(deleteQuote)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.deleteQuote') }}</v-list-tile-title>
            </v-list-tile>
        </v-list>
    </v-menu>
</template>

<script>
import CheckDocumentNodeSelectionService from '../../../../service/document/checkDocumentNodeSelectionService.js';
import ConstantAction from '../../../../shared/constant/constantAction.js';
import ConstantDocumentNode from '../../../../shared/constant/constantDocumentNode.js';
import ConstantEvent from '../../../../shared/constant/constantEvent.js';
import ConstantNode from '../../../../shared/constant/constantNode.js';
import ConstantNodeContentElement from '../../../../shared/constant/constantNodeContentElement.js'
import ConstantTag from '../../../../shared/constant/constantTag.js';
import ConstantStoreName from '../../../../shared/constant/constantStoreName.js';
import CustomListService from '../../../../service/document/customListService.js';
import DomManipulatorService from '../../../../service/html/domManipulatorService';
import TableHtmlService from '../../../../service/html/tableHtmlService.js';
import EventBus from '../../../../utils/event-bus';
import * as $ from 'jquery';
import NodeContentTreeService from '../../../../service/document/nodeContentTreeService';

const UPDATE_MENU = 'update:menu';
/**
 * Open a context menu to manage actions on selected texte  
 * 
 * @author Justin WILMET
 * @version 1.0
 * @since 2019-06-06
 */
export default {
    name: 'MenuForText',
    props: {
        menu: {
            default: false
        },
        position: {
            x: null,
            y: null
        },
        globalAttributes: {
            windowSelection: {
                default: null
            },
            caretPosition: {
                default: null
            },
            deleteFilesClicked: {
                default: false
            },
            udpatePictures: {
                default: false
            },
            tableClicked: {
                default: false
            },
            isSelectedTable: {
                default: false
            },
            idFiles: {
                default: null
            },
            nodeId: {
                default: null
            },
            selectedTable: {
                default: null
            }
        },
        modificatorContentChunk:{
            default: null
        },
        list: {
            node: null,
            isHtmlList: false
        },
        isConsolidation: {
            default: false
        },
        quoteClicked:{
            default: false
        },
        isInlineQt: {
            default: false
        },
        isBlockquote: {
            default: false
        },
        storeName: {
            default: null
        }
    },
    data: () => ({
        customListActions: null,
        unmodifiedProps: {},
        firstInteraction: true
    }),
    computed: {
        selectedNode(){
            return this.$store.state[`${this.storeName}`].selectedNode;
        },
        textToPaste(){
            return this.$store.state[`${this.storeName}`].textToPaste;
        },
        showMenu: {
            get(){
                return this.menu;
            },
            set( menu ){
                if( !menu ){
                    this.$emit(UPDATE_MENU, false);
                }
            }
        },
        before(){
            return ConstantNode.WHERE_BEFORE;
        },
        after(){
            return ConstantNode.WHERE_AFTER;
        },
        documentContentStore(){
            return ConstantStoreName.DOCUMENT_CONTENT;
        },
        copyOnlineQuote(){
            return ConstantAction.COPY_ONLINE_QUOTE;
        },
        SELECTION(){
            return ConstantAction.SELECTION;
        },
        CLIPBOARD(){
            return ConstantAction.CLIPBOARD;
        },
        modificatorContentChunkActionCopyOnlineQuote(){
            if ( !!this.modificatorContentChunk && this.modificatorContentChunk.action === ConstantAction.copyOnlineQuote ){
                return this.modificatorContentChunk.action;
            }
        }
    },
    methods: {
        keepUnmodifiedProps(){
            if(this.firstInteraction){
                this.unmodifiedProps = this.globalAttributes;
                this.firstInteraction = false;
            }
        },
        modifyContent( nodeContent ) {
            this.$store.dispatch(`${this.storeName}/modifyContent`, nodeContent);
        },
        setSelectedNodeContent( nodeContent ) {
            this.$store.dispatch(`${this.storeName}/setSelectedNodeContent`, nodeContent);
        },
        /**
         * Hide the menu, then execute the action selected
         * @param {function} menuAction action to call on click on one menu option
         */
        handleMenuAction(menuAction) {
            menuAction();
        },
        /**
         * Open the dialog to create or modify a table
         */
        manageTableDialog(){
            this.$emit('onOpenDialog', { dialog: ConstantEvent.UPDATE_TABLE} );
            this.close();
        },
        /**
         * Create footnote on a table
         */
        createTableFootnote(){
            let tableToEdit = this.globalAttributes.caretPosition.checkedNode.childNodes[this.globalAttributes.caretPosition.checkedNodeOffset];
            let offset = CheckDocumentNodeSelectionService.checkEndCaretPosition(this.globalAttributes.caretPosition.clickedNodeOffset, this.globalAttributes.caretPosition.clickedFocusNodeOffset);
            this.$emit('onOpenDialog', { 
                dialog: ConstantEvent.OPEN_DIALOG_TFOOT,
                caretPosition: {
                    clickedNode: this.globalAttributes.caretPosition.clickedNode, 
                    clickedOffset: offset,
                    htmlTableElement: tableToEdit
                }
            });
            this.close();
        },
        /**
         * Open the selected node on the text editor (tinymce)
         */
        editNode(){
            if( this.selectedNode.label === ConstantTag.TEXT){
                this.$emit('onOpenDialog', { dialog: ConstantEvent.OPEN_DIALOG_CONTENT_EDITOR} );
            }
            this.close();
        },
        /**
         * Delete a node from the document
         */
        deleteNode(){
            this.$emit('onOpenDialog', { dialog: ConstantEvent.DELETE_NODE } );
            this.close();
        },
        /**
         * Open dialog to create a custom list on the node
         */
        openCreateCustomListDialog(){
            this.$emit('onOpenDialog', { dialog: ConstantEvent.OPEN_DIALOG_CREATE_CUSTOM_LIST} );
            this.close();
        },
        /**
         * Open the dialog custom list in the update mode
         */
        updateFormat(){
            this.$emit('onOpenDialog', { dialog: ConstantEvent.OPEN_DIALOG_UPDATE_CUSTOM_LIST} );
            this.close();
        },
        /**
         * Add a row in a custom list
         * @param {String} position, Specification to know where the new row should be added
         */
        addRowToCustomList( position ){
            let updatedCustomList = CustomListService.updateCustomList({ node: this.globalAttributes.caretPosition.checkedNode, position: position }, null);
            let customList = this.list.node;
            this.$checkCaretPositionNode(customList.parentNode, [...customList.parentNode.childNodes].indexOf(customList));
            let node = this.globalAttributes.caretPosition.checkedNode.childNodes[this.globalAttributes.caretPosition.checkedNodeOffset];
            let htmlContent = DomManipulatorService.updateHTMLElementInNode(node, updatedCustomList.outerHTML, ConstantDocumentNode.TEXT_NODE_CLASS);
            this.setSelectedNodeContent(htmlContent);
            this.close();
        },
        /**
         * Transform a HTML list in a custom list
         */
        listToCustomList(){
            let customListTable = CustomListService.extractListContentAndCreateCustomList(this.list.node);
            let node = this.globalAttributes.caretPosition.checkedNode;
            let htmlContent = DomManipulatorService.updateHTMLElementInNode(node, customListTable.outerHTML, ConstantDocumentNode.TEXT_NODE_CLASS);
            this.setSelectedNodeContent(htmlContent);
            this.close();
        },
        /**
         * Insert the copied lines from modificatorDocument in the selected custom list
         * @param {String} position, where lines should be inserted
         */
        async insertLinesToCustomList( position ){
            let textFromClipboard = await navigator.clipboard.readText();
            let domNode = this.list.node;
            let node = this.globalAttributes.caretPosition.checkedNode;
            let updatedCustomList = CustomListService.insertLinesInExistingCustomList( domNode, textFromClipboard, position, node );

            if ( updatedCustomList !== null){
                let htmlContent = DomManipulatorService.updateHTMLElementInNode(domNode, updatedCustomList.outerHTML, ConstantDocumentNode.TEXT_NODE_CLASS);
                this.setSelectedNodeContent(htmlContent);
            }
            this.cleanClipboard();
            this.close();
        },
        /**
         * Transform the selected text in a custom list if possible
         */
        async transformTextToCustomList( action ){
            let customList = '';
            if( action === ConstantAction.CLIPBOARD){
                let textFromClipboard = await navigator.clipboard.readText();
                customList = CustomListService.checkAndCreateCustomListOnText(textFromClipboard);
            } else if ( action === ConstantAction.SELECTION ) {
                this.keepUnmodifiedProps();
                let textForSelection = this.unmodifiedProps.windowSelection.toString();
                customList = CustomListService.checkAndCreateCustomListOnText(textForSelection);
            }
            let htmlContent;

            if(!!customList){
                if( this.globalAttributes.windowSelection.anchorNode === this.globalAttributes.windowSelection.focusNode ){
                    htmlContent = NodeContentTreeService.replaceTextByHTMLElement( customList, this.globalAttributes.caretPosition );
                } else {
                    htmlContent = NodeContentTreeService.replaceMultipleTextByHTMLElement( customList, this.globalAttributes.windowSelection );
                }
                this.setSelectedNodeContent( htmlContent );
            } else {
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    message: 'document.notification.customList.notACustomList',
                    type: 'INFO'
                });
            }
            this.cleanClipboard();
            this.close();
        },
        /**
         * Open the dialog to import files on the node
         */
        importFiles(){
            this.$emit('onOpenDialog', { dialog: ConstantEvent.IMPORT_FILES} );
            this.close();
        },
        /**
         * Open the dialog to set the new width and height of the selected picture
         */
        udpateFiles(){
            this.$emit('onOpenDialog', { dialog: ConstantEvent.CHANGE_WIDTH_HEIGHT} );
            this.close();
        },
        /**
         * Delete the link of the files in the document
         */
        deleteFiles(){
            let node = document.getElementById(this.globalAttributes.idFiles);
            let a = node.closest('a');
            node.className === ConstantNodeContentElement.CUSTOM_UPLOAD_FILE_CLASS ? a.parentNode.removeChild(a) : node.parentNode.removeChild(node);
            let container = $( `#${ConstantDocumentNode.NODE_CONTENT_CLASS}-${this.globalAttributes.nodeId}`);
            //Avoid creation of several div
            if(container[0].children[0].className === `${ConstantDocumentNode.CONTENT_STYLE_CLASS} ${ConstantDocumentNode.TEXT_NODE_CLASS}`){
                this.modifyContent({ id: this.globalAttributes.nodeId, content: container[0].children[0].innerHTML });
            }else{
                this.modifyContent({ id: this.globalAttributes.nodeId, content: container[0].innerHTML });
            }
            this.close();
        },
        /**
         * Format the selected text
         */
        formatText(){
            if( this.selectedNode.label === ConstantTag.TEXT && !!this.selectedNode.content){
                let checkedSelection = CheckDocumentNodeSelectionService.isViabilitySelection( this.globalAttributes.windowSelection );
                if( checkedSelection.isCreatable ){

                    if( !!checkedSelection.commonParent ){
                        
                        let position = CheckDocumentNodeSelectionService.calculatePosition( this.globalAttributes.windowSelection, checkedSelection);
                        var range = window.getSelection().getRangeAt(0);
                        var content = range.cloneContents();
                        var span = document.createElement('SPAN');

                        span.appendChild(content);
                        var htmlContent = span.innerHTML;

                        position.selectedText = htmlContent;
                        this.$emit( 'onOpenDialog', { 
                            dialog: ConstantEvent.FORMAT_TEXT, 
                            caretPosition: position }
                        );

                    } else {
                        let offset = CheckDocumentNodeSelectionService.checkCaretPosition( this.globalAttributes.caretPosition.clickedNodeOffset, this.globalAttributes.caretPosition.clickedFocusNodeOffset );
                        this.$emit( 'onOpenDialog', { 
                            dialog: ConstantEvent.FORMAT_TEXT, 
                            caretPosition: {
                                selectedText: this.globalAttributes.windowSelection.toString(),
                                clickedNode: this.globalAttributes.caretPosition.clickedNode,
                                clickedNodeOffset: offset.anchorOffset,
                                clickedFocusNodeOffset: offset.focusOffset
                            }
                        });
                    }
                }else {
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                        message: 'document.error.badSelectionFormat',
                        type: 'INFO'
                    });
                }
            }
            this.close();
        },
        /**
         * Paste the text of the clipboard in the text node
         * If the text contain carriage return character, the method will create a P element for each one.
         */
        async pasteText(){
            let textFromClipboard = await navigator.clipboard.readText();
            if(!!textFromClipboard){
                let htmlContent;
                if( this.globalAttributes.caretPosition.clickedNode.nodeType === Node.TEXT_NODE) {
                    htmlContent = NodeContentTreeService.pasteTextInNode( textFromClipboard, this.globalAttributes );
                } else {
                    this.globalAttributes.tableClicked = true;
                    htmlContent = NodeContentTreeService.pasteTextInNode( textFromClipboard, this.globalAttributes );
                }
                this.setSelectedNodeContent( htmlContent );
            } else {
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    message: 'administration.structure.action.emptyPaste',
                    type: 'INFO'
                });
            }
            this.cleanClipboard();
            this.close();
        },
        /**
       * Prevent sanitization of text on clipboard and encapsulate it in a <code> tag
       */
        async pasteAsCode(){
            let textFromClipboard = await navigator.clipboard.readText();
            if(!!textFromClipboard){
                let offset = CheckDocumentNodeSelectionService.checkEndCaretPosition( this.globalAttributes.caretPosition.clickedNodeOffset, this.globalAttributes.caretPosition.clickedFocusNodeOffset );
                let textWithoutFormat = '<code>' +  textFromClipboard.replace(/\n|\r/gm, '')+ '</code>';
                let htmlContent;
                if( this.globalAttributes.caretPosition.clickedNode.nodeType === Node.TEXT_NODE) {
                    htmlContent = DomManipulatorService.insertHtmlInTextNode( this.globalAttributes.caretPosition.clickedNode, offset, ` ${textWithoutFormat} `, ConstantDocumentNode.TEXT_NODE_CLASS );
                } else {
                    htmlContent = DomManipulatorService.insertTextInNode( this.globalAttributes.caretPosition.clickedNode, offset, ` ${textWithoutFormat} `, ConstantDocumentNode.TEXT_NODE_CLASS );
                }
                this.setSelectedNodeContent(htmlContent);
            }else{
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    message: 'administration.structure.action.emptyPaste',
                    type: 'INFO'
                });
            }
            this.cleanClipboard();
            this.close();
        },
        /**
         * Paste the text of the clipboard in the text node
         * place it after the selection but still in the same line.
         */
        async pasteTextInLine(){
            let textFromClipboard = await navigator.clipboard.readText();
            if(!!textFromClipboard){
                let offset = CheckDocumentNodeSelectionService.checkEndCaretPosition( this.globalAttributes.caretPosition.clickedNodeOffset, this.globalAttributes.caretPosition.clickedFocusNodeOffset );
                let textWithoutFormat = textFromClipboard.replace(/\n|\r/gm, '');
                let htmlContent;
                if( this.globalAttributes.caretPosition.clickedNode.nodeType === Node.TEXT_NODE) {
                    htmlContent = DomManipulatorService.insertHtmlInTextNode( this.globalAttributes.caretPosition.clickedNode, offset, ` ${textWithoutFormat} `, ConstantDocumentNode.TEXT_NODE_CLASS );
                } else {
                    htmlContent = DomManipulatorService.insertTextInNode( this.globalAttributes.caretPosition.clickedNode, offset, ` ${textWithoutFormat} `, ConstantDocumentNode.TEXT_NODE_CLASS );
                }
                this.setSelectedNodeContent( htmlContent );
            } else {
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    message: 'administration.structure.action.emptyPaste',
                    type: 'INFO'
                });
            }
            this.cleanClipboard();
            this.close();
        },
        /**
         * Replace the selected text by the one in the clipboard
         */
        async replaceText(){
            let textFromClipboard = await navigator.clipboard.readText();
            let htmlContent = NodeContentTreeService.replaceText(textFromClipboard, this.globalAttributes);
            this.setSelectedNodeContent( htmlContent );
            this.cleanClipboard();
            this.close();
        },
        /**
         * Create a footnote
         */
        createFootnote(){
            let offset = CheckDocumentNodeSelectionService.checkEndCaretPosition( this.globalAttributes.windowSelection.anchorOffset, this.globalAttributes.windowSelection.focusOffset );
            this.$emit('onOpenDialog', { 
                dialog: ConstantEvent.OPEN_DIALOG_FOOTNOTES, 
                caretPosition: {
                    caretPositionNode: this.globalAttributes.caretPosition.clickedNode,
                    caretPositionOffset: offset
                }
            });
            this.close();
        },
        /**
         * Add a structured quote to the node as a HTML blockquote
         */
        addQuote(){
            EventBus.$emit(ConstantEvent.ADD_QUOTE);
            this.close();
        },
        /**
         * Update the content of a quote
         */
        updateQuote(){
            let blockquote = this.globalAttributes.caretPosition.checkedNode.childNodes.item(this.globalAttributes.caretPosition.checkedNodeOffset);
            EventBus.$emit(ConstantEvent.MODIFY_QUOTE, blockquote);
            this.close();
        },
        /**
         * Delete a text quote
         */
        deleteQuote(){
            let node = this.globalAttributes.caretPosition.checkedNode.childNodes[this.globalAttributes.caretPosition.checkedNodeOffset];
            let htmlContent = DomManipulatorService.updateHTMLElementInNode(node, '', ConstantDocumentNode.TEXT_NODE_CLASS);
            this.setSelectedNodeContent(htmlContent);
            this.close();
        },
        /** 
         * Close the context menu 
         */
        close(){
            this.firstInteraction = true;
            this.$emit(UPDATE_MENU, false);
        },
        /**
         * Reset the content of the clipoard
         */
        cleanClipboard(){
            navigator.clipboard.writeText("");
        },
        /**
         * Add one or several structured rows to the table node
         * @param {String} position, Specification to know where the new row should be added
         */
        async addRowToTable( position ){
            let selectedTBody = TableHtmlService.findFirstTBodyFromTable(this.globalAttributes.caretPosition.clickedNode);
            let textFromClipboard = await navigator.clipboard.readText();
            
            if(!!this.isConsolidation && !!textFromClipboard){
                let copiedTableHtml = TableHtmlService.getTableWithoutTextAndFootnotes(textFromClipboard);
                let sameCols = TableHtmlService.checkNumberOfColumns(copiedTableHtml, selectedTBody.parentNode);
                
                if ( !sameCols ){
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                        message: 'document.notification.table.wrongNumberOfColumns',
                        type: 'INFO'
                    });
                } else {
                    let rowsHtml = []
                    copiedTableHtml.childNodes[0].childNodes.forEach( row => {
                        rowsHtml.push(row.outerHTML);
                    });
                    //We need to find the correct offset, i.e. the rowIndex because the caretPosition.clickedNode is inside a text or a td element
                    let htmlContent = TableHtmlService.insertRowsInExistingTable( selectedTBody, position, this.globalAttributes.windowSelection, rowsHtml );
                    this.setSelectedNodeContent(htmlContent); 
                }
            } else {
                let htmlContent = TableHtmlService.insertRowsInExistingTable( selectedTBody, position, this.globalAttributes.windowSelection);
                this.setSelectedNodeContent(htmlContent);
            }
            this.cleanClipboard();
            this.close();
        },
        //              SHOULD BE PLACED IN AN EXTERNAL SERVICES
        /**
         * Set the caretPosition's checkedNode,
         * and checkedNodeOffset as the offset required to use with this checkedNode later
         * @param {Node} node the checked node 
         * @param {Number} offset the checked node offset
         */
        $checkCaretPositionNode(node, offset) {
            this.globalAttributes.caretPosition.checkedNode = node;
            this.globalAttributes.caretPosition.checkedNodeOffset = offset;
        }
    }
}
</script>

<style scoped>

</style>
