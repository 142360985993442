<template>
        <router-view />
</template>

<script>
    /**
     * 
     * Main content structure of Document.
     * @author Sébastien DE SANTIS, SFEIR Benelux
     * @version 1.0
     * @since 12/09/2018
     * 
     */
    export default {
        name: 'Document'
    }
</script>