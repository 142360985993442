<template>
    <v-layout justify-space-around wrap>
        <v-btn large class="bol-large-button" @click="clickOnCorrectLinksBtn">
            <v-layout justify-space-around>
                <v-icon color="primary" dark>warning</v-icon>
                <div>{{this.$t('navigation.administration.system.correct-links')}}</div>
            </v-layout>
        </v-btn>
    </v-layout>
</template>
<script>
import EventBus             from '../../../utils/event-bus';
import ConstantEvent        from '../../../shared/constant/constantEvent';
import ConstantNotification from '../../../shared/constant/constantNotification'
import axios                from 'axios'
/**
 * Administration System Component. 
 * This will allow user to manage all the operations related to the system application.
 * 
 * @author Sébastien De Santis
 * @version 1.0
 * @date 25/02/2020
 */
export default {
    name:'BolSystemAdministration',
    props: {
        confirm:{
            default: false
        }
    },
    methods:{
        /**
         * Manage click on the correct links button
         */
        clickOnCorrectLinksBtn(){
            EventBus.$emit(ConstantEvent.OPEN_BOL_DIALOG_CONFIRMATION, "administration.system.correct-links.confirmation-message", this.confirmCorrectLinksCallBack);
        },
        /**
         * Call back when the user confirms the correct links action. 
         */
        confirmCorrectLinksCallBack(){
            axios.get(
                'administration/system/correctLinks'
            ).then( result => {
                const notification = {
                    type: ConstantNotification.INFO,
                    message: 'administration.system.correct-links.in-progress-notification'
                };
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
            });
        }
    }
}
</script>
<style scoped>
.bol-large-button{
    width: 380px;
    height: 72px;
    padding: 25px;
}
</style>