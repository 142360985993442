<template>
    <v-menu
        v-model="showMenu"
        :close-on-content-click="false"
        :position-x="position.x"
        :position-y="position.y"
        absolute
        offset-y
    >
        <v-list>
            <v-list-tile
                @click="handleMenuAction(addNode)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.add') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
                @click="handleMenuAction(deleteNode)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.delete') }}</v-list-tile-title>
            </v-list-tile>

            <v-divider />

            <v-list-tile
                @click="handleMenuAction(cutNode)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.cut') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
                @click="handleMenuAction(copyNode)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.copy') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
                @click="handleMenuAction(copyStructure)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.copyStruct') }}</v-list-tile-title>
            </v-list-tile>

            <v-divider />

            <v-list-group no-action>
                <v-list-tile slot="activator">
                    <v-list-tile-content>
                        <v-list-tile-title>{{ $t(textPasteNode) }}</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
                <v-list-tile @click="pasteNode(before)">
                    <v-list-tile-content>
                        <v-list-tile-title>{{ $t('structure.node.action.pasteBeforeNode') }}</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
                <v-list-tile @click="pasteNode(inside)">
                    <v-list-tile-content>
                        <v-list-tile-title>{{ $t('structure.node.action.pasteInNode') }}</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
                <v-list-tile @click="pasteNode(after)">
                    <v-list-tile-content>
                        <v-list-tile-title>{{ $t('structure.node.action.pasteAfterNode') }}</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
            </v-list-group>

            <v-list-tile @click="openMenuLang()">
                <v-list-tile-content>
                    <v-list-tile-title>{{ $t('structure.node.action.addLang') }}</v-list-tile-title>
                </v-list-tile-content>
            </v-list-tile>

            <v-dialog
                v-model="dialog"
                width="300"
            >
                <v-card class="card-lang">
                    <h2 class="content-lang">
                        {{ $t('structure.node.action.addLang') }}
                    </h2>
                    <v-list-tile
                        v-for="lang in langList"
                        :key="lang.id"
                    >
                        <v-btn
                            v-if="!!lang"
                            id="btn-lang"
                            @click="selectedLang(selectedNode,lang.code)"
                        >
                            <span
                                class="name-lang"
                            >{{ lang.name }}</span>&nbsp
                            <country-flag
                                class="flag-lang"
                                :country="getFlag(lang.code)"
                                size="small"
                            />
                        </v-btn>
                    </v-list-tile>
                </v-card>
            </v-dialog>

            <v-list-group
                v-if="showMenuToPasteOnTitle"
                no-action
            >
                <v-list-tile slot="activator">
                    <v-list-tile-content>
                        <v-list-tile-title>{{ $t('structure.node.action.pasteTextOnNode') }}</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
                <v-list-tile
                    v-if="canPasteOnNode(nodeLabel)"
                    @click="pasteTextOnNode(ALINEA)"
                >
                    <v-list-tile-content>
                        <v-list-tile-title>{{ $t('structure.node.action.pasteTextAsAlinea') }}</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
                <v-list-tile
                    v-if="nodeLabel === VISAS"
                    @click="pasteTextOnNode(VISA)"
                >
                    <v-list-tile-content>
                        <v-list-tile-title>{{ $t('structure.node.action.pasteTextAsVisa') }}</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
                <v-list-tile
                    v-if="nodeLabel === CONSIDS"
                    @click="pasteTextOnNode(CONSID)"
                >
                    <v-list-tile-content>
                        <v-list-tile-title>{{ $t('structure.node.action.pasteTextAsConsid') }}</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
                <v-list-tile
                    v-if="nodeLabel === ARTICLE"
                    @click="pasteTextOnNode(PARAG)"
                >
                    <v-list-tile-content>
                        <v-list-tile-title>{{ $t('structure.node.action.pasteTextAsParag') }}</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
            </v-list-group>


            <v-divider v-if="storeName === documentContentStore" />

            <v-list-tile
                v-if="!showMenuForKeywords && storeName === documentContentStore"
                @click="handleMenuAction(addKeyword)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.addKeyword') }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
                v-if="showMenuForKeywords && storeName === documentContentStore"
                @click="handleMenuAction(manageKeywords)"
            >
                <v-list-tile-title>{{ $t('structure.node.action.manageKeyword') }}</v-list-tile-title>
            </v-list-tile>
        </v-list>
    </v-menu>
</template>

<script>
import CheckDocumentNodeSelectionService from '../../../../service/document/checkDocumentNodeSelectionService.js';
import ConstantAction from '../../../../shared/constant/constantAction.js';
import ConstantEvent from '../../../../shared/constant/constantEvent.js';
import ConstantNode from '../../../../shared/constant/constantNode.js';
import ConstantStoreName from '../../../../shared/constant/constantStoreName.js';
import ConstantTag from '../../../../shared/constant/constantTag.js';
import DocumentTreeService from '../../../../service/document/documentTreeService.js';
import NodeContentTreeService from '../../../../service/document/nodeContentTreeService.js';
import EventBus from '../../../../utils/event-bus';
import {mapState} from 'vuex';
import CountryFlag from 'vue-country-flag'
import documentTreeService from "../../../../service/document/documentTreeService";

const UPDATE_MENU = 'update:menu';
/**
 * Open a context menu to manage actions on title
 *
 * @author Justin WILMET
 * @version 1.0
 * @since 2019-06-05
 */
export default {
    name: 'MenuForTitle',
    components: {
        CountryFlag
    },
    props: {
        menu: {
            default: false
        },
        position: {
            x: null,
            y: null
        },
        modificatorContentChunk: {
            default: null
        },
        storeName: {
            default: null
        },
        langList: {
            id: 0,
            code: "",
            name: ""
        },
    },
    data: () => ({
        isMenuLang: false,
        dialog: false
    }),
    computed: {
        documentContentStore() {
            return ConstantStoreName.DOCUMENT_CONTENT;
        },
        rootNode() {
            return this.$store.state[`${this.storeName}`].rootNode;
        },
        selectedNode() {
            return this.$store.state[`${this.storeName}`].selectedNode;
        },
        nodeToPaste() {
            return this.$store.state[`${this.storeName}`].nodeToPaste;
        },
        ...mapState({
            documentType: state => state.dataSheetStore.metaDocument.documentType
        }),
        showMenu: {
            get() {
                return this.menu;
            },
            set(menu) {
                if (!menu) {
                    this.$emit(UPDATE_MENU, false);
                }
            }
        },
        before() {
            return ConstantNode.WHERE_BEFORE;
        },
        inside() {
            return ConstantNode.WHERE_INSIDE;
        },
        after() {
            return ConstantNode.WHERE_AFTER;
        },
        nodeLabel() {
            return this.selectedNode.label;
        },
        ALINEA() {
            return ConstantTag.ALINEA;
        },
        VISA() {
            return ConstantTag.VISA;
        },
        CONSID() {
            return ConstantTag.CONSID;
        },
        PARAG() {
            return ConstantTag.PARAG;
        },
        ARTICLE() {
            return ConstantTag.ARTICLE;
        },
        CONSIDS() {
            return ConstantTag.CONSIDS;
        },
        VISAS() {
            return ConstantTag.VISAS;
        },
        ALINEAS() {
            return ConstantTag.ALINEAS;
        },
        CONTENT() {
            return ConstantTag.CONTENT;
        },
        JUDGEMENT() {
            return ConstantTag.JUDGEMENT;
        },
        showMenuToPasteOnTitle() {
            return CheckDocumentNodeSelectionService.isANodeWhoCanReceiveText(this.selectedNode);
        },
        showMenuForKeywords() {
            return CheckDocumentNodeSelectionService.isNodeContainingKeywords(this.selectedNode);
        },
        textPasteNode() {
            if (!!this.modificatorContentChunk && this.modificatorContentChunk.action === ConstantAction.COPY_STRUCTURAL_QUOTE) {
                return 'structure.node.action.pasteQuote';
            } else if (!!this.modificatorContentChunk && this.modificatorContentChunk.action === ConstantAction.COPY_STRUCTURAL_QUOTE_LIST) {
                return 'structure.node.action.pasteQuoteList';
            } else {
                return 'structure.node.action.pasteNode';
            }
        }
    },
    methods: {
        addPasteNodeInTree(node, where, action) {
            this.$store.dispatch(`${this.storeName}/addPasteNodeInTree`, {
                selectedNode: this.selectedNode,
                node: DocumentTreeService.createClonePattern(node),
                where,
                action
            });
        },
        openMenuLang() {
            this.showMenu = false;
            this.dialog = !this.dialog;
        },
        /**
     * Return true if nodeLabel has for value one of these cases
     * @param nodeLabel
     */
        canPasteOnNode(nodeLabel) {
            return [this.ARTICLE, this.ALINEAS, this.CONTENT, this.JUDGEMENT].indexOf(nodeLabel) >= 0;
        },
        selectedLang(node, lang) {
            if (this.dialog) {
                this.dialog = false;
            }
            if (node.lang !== null) {
                node.lang = lang;
                if (!!node.children) {
                    node.children.forEach(child => {
                        if (!!child) {
                            this.selectedLang(child, lang);
                        }
                    });
                }
            } else {
                node.lang = lang;
                if (!!node.children) {
                    node.children.forEach(child => {
                        if (!!child && child.lang === null) {
                            this.selectedLang(child, lang);
                        }
                    });
                }
            }
        },
        addGenerateNodeInTree(node) {
            this.$store.dispatch(`${this.storeName}/addGenerateNodeInTree`, node);
        },
        /**
     * Hide the menu, then execute the action selected
     * @param {function} menuAction action to call on click on one menu option
     */
        handleMenuAction(menuAction) {
            menuAction();
        },
        getFlag(code) {
            return documentTreeService.convertCodeForFlags(code);
        },
        /**
     * Add a node to the document
     */
        addNode() {
            this.$emit('onOpenDialog', {dialog: ConstantEvent.ADD_NODE});
            this.close();
        },
        /**
     * Delete a node from the document
     */
        deleteNode() {
            this.$emit('onOpenDialog', {dialog: ConstantEvent.DELETE_NODE});
            this.close();
        },

        /**
     * Cut the selected node ( keep the id of the selected node on the action )
     */
        cutNode() {
            this.$store.commit(`${this.storeName}/setNodeToPaste`, {action: ConstantAction.CUT, node: this.selectedNode});
            this.close();
        },
        /**
     * Copy the selected node ( keep the id of the selected node on the action )
     */
        copyNode() {
            this.$store.commit(`${this.storeName}/setNodeToPaste`, {
                action: ConstantAction.COPY_NODE,
                node: this.selectedNode
            });
            this.close();
        },
        /**
     * Copy the struct of the selected node ( keep the id of the selected node on the action )
     */
        copyStructure() {
            this.$store.commit(`${this.storeName}/setNodeToPaste`, {
                action: ConstantNode.COPY_STRUCT,
                node: this.selectedNode
            });
            this.close();
        },
        /**
     * Paste a selected node depending on its position and if we are in consolidation mode
     * @param {String} where, the position where the node should be placed related to the current node
     */
        pasteNode(where) {
            if (!!this.modificatorContentChunk) {
                if (this.modificatorContentChunk.action === ConstantAction.COPY_STRUCTURAL_QUOTE || this.modificatorContentChunk.action === ConstantAction.COPY_NODE) {
                    this.addPasteNodeInTree(this.modificatorContentChunk.content, where, ConstantAction.PASTE_NODE);
                } else if (this.modificatorContentChunk.action === ConstantAction.COPY_SIBLINGS_NODE || this.modificatorContentChunk.action === ConstantAction.COPY_STRUCTURAL_QUOTE_LIST) {
                    if (where === ConstantNode.WHERE_BEFORE) {
                        this.modificatorContentChunk.content.reverse().forEach(element => {
                            this.addPasteNodeInTree(element, where, ConstantAction.PASTE_NODE);
                        });
                    } else if (where === ConstantNode.WHERE_INSIDE) {
                        this.modificatorContentChunk.content.forEach(element => {
                            this.addGenerateNodeInTree({selectedNode: this.selectedNode, node: element});
                        });
                    } else {
                        this.modificatorContentChunk.content.forEach(element => {
                            this.addPasteNodeInTree(element, where, ConstantAction.PASTE_NODE);
                        });
                    }
                }
                this.$emit('resetModificator');
            } else {
                if (!!this.nodeToPaste) {
                    this.addPasteNodeInTree(this.nodeToPaste.node, where, ConstantAction.PASTE_NODE);
                } else {
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                        type: 'INFO',
                        message: 'structure.node.notification.noCopiedSelection'
                    });
                }
            }
            this.$emit('scrollToPasteNode');
            this.close();
        },
        /**
     * Open dialog to add keyword on the node
     */
        addKeyword() {
            this.$emit('onOpenDialog', {dialog: ConstantEvent.ADD_KEYWORD});
            this.close();
        },
        /**
     * Modify - delete or add new keywords on the line
     */
        manageKeywords() {
            this.$emit('onOpenDialog', {dialog: ConstantEvent.ADD_KEYWORD});
            this.close();
        },
        /**
     * Paste the content of the clipboard as structural nodes
     * @param {String} structureType, type of node who will be created
     */
        async pasteTextOnNode(structureType) {
            let textFromClipboard = await navigator.clipboard.readText();
            let nodeStructure = await NodeContentTreeService.pasteTextOnEmptyNode(structureType, textFromClipboard, this.documentType);
            if (!!nodeStructure.message) {
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, nodeStructure);
            } else {
                nodeStructure.forEach(node => {
                    this.addGenerateNodeInTree({selectedNode: this.selectedNode, node: node});
                });
            }
            this.close();
        },
        /**
     * Close the context menu
     */
        close() {
            this.$emit(UPDATE_MENU, false);
        }
    }
}
</script>

<style scoped>

#btn-lang {
  background-color: white;
  border-color: white;
  box-shadow: none;
  margin: auto;
  padding-bottom: 5px;
  padding-top: 5px;
  font-size: 0.7em;
  width: 100%;
}

#btn-lang:hover {
  background-color: white !important;
  border-bottom: black;
}

.card-lang {
  height: 250px;
  text-align: center;
  margin: auto;
  border-radius: 20px;
}

.content-lang {
  padding-top: 15px;
  margin-bottom: 5px;
}

.name-lang {
  position: absolute;
  left: 5px;
}

.flag-lang {
  position: absolute;
  top: 0;
  right: 5px;
}
</style>
