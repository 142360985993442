<template >
    <v-layout align-start justify-start row fill-height>

        <div class="left-container">
            <v-tabs vertical class="tabs-container" color="#355B7F" dark>
                <v-tab>
                    <v-icon left>mdi-account</v-icon>
                    {{ $t('pao.summary.article-title') }}
                </v-tab>
                <v-tab>
                    <v-icon left>mdi-lock</v-icon>
                    {{ $t('pao.summary.jurisprudence-title') }}
                </v-tab>

                <v-tab-item class="item-left-container">
                    <h3 class="title-left"></h3>
                        <div v-for="item of documentLinkedList" :key="item.id" class="left-container-text">
                            <div v-if="item.typeLinkDestination === ARTICLE"  class="item-container">
                                <div class="item-article">
                                    <v-tooltip top>
                                        <template slot="activator">
                                            <span >{{item.titleDocumentDestination}}</span>
                                            <br />
                                            <span><b>version:</b>{{item.documentDestinationVersion}}</span>
                                        </template>
                                        <h4>{{item.titleDocumentDestination}}</h4>
                                    </v-tooltip>
                                    <h4>{{item.contentDocumentDestination}}</h4>
                                </div>
                                <div class="delete-article">
                                    <v-icon color="white"  @click="deletePao(item.id)">delete</v-icon>
                                </div>
                            </div>
                        </div>
                        <v-divider color="white"></v-divider>
                </v-tab-item>
                <v-tab-item class="item-left-container">
                    <h3 class="title-left"></h3>
                    <div v-for="item of jurisprudenceLinkedList" :key="item.id" class="left-container-text">
                        <div v-if="item.documentIdDestination === documentId"  class="item-container">
                            <div class="item-article">
                                <v-tooltip top>
                                    <template slot="activator">
                                        <span >{{item.titleDocumentDestination}}</span>
                                        <br />
                                        <span><b>version:</b>{{item.documentDestinationVersion}}</span>
                                    </template>
                                    <h4>{{item.titleDocumentDestination}}</h4>
                                </v-tooltip>
                                <h4>{{item.contentDocumentDestination}}</h4>
                                <h4>{{relationDocumentTypeFiltered(item.relationDocumentType)}}</h4>
                            </div>
                            <div class="delete-article">
                                <v-icon color="white"  @click="deletePao(item.id)">delete</v-icon>
                            </div>
                        </div>
                    </div>
                    <v-divider color="white"></v-divider>
                </v-tab-item>
            </v-tabs>
            <div v-if="canBeOpen" @click="openDocumentToLink" class="document-opener">
                <div class="open-doc" >
                    <v-icon>launch</v-icon>
                </div>
                <h4 class="text-opener-document"> {{ $t('pao.summary.link-a-doc') }} </h4>
            </div>
            <div class="document-exit" @click="leaveDocument">
                <bol-back-button :textBackButton="textBackButton"/>
            </div>
        </div>

        <div  v-if="rootNode" class="right-container">
            <v-flex class="node-content">
                <node-content-tree-light 
                    :rootNode="rootNode" 
                    @clickedOnResume="setDocumentDialog" 
                    ref="editor" 
                    :documentType="documentType" />
            </v-flex>
        </div>
        <dialog-search-document-to-link :dialog.sync="documentHyperDialog" @documentChosen="documentChosen"  />
        <dialog-document-content-to-link :dialog.sync="documentContentHyperLinkDialog" @closeDocumentNode="closeDocumentNode" />
        <dialog-confirmation :dialog.sync="confirmDialogOpen" :message="message" @confirm="isConfirm" />
    </v-layout>
</template>
<script>


import { mapState, mapActions}  from 'vuex';
import axios                    from 'axios';
import NodeContentTreeLight     from './component/NodeContentTreeLight';
import ConstantStoreName        from '../../../shared/constant/constantStoreName.js';
import DialogConfirmation       from '../../shared/DialogConfirmation';
import DialogSearchDocumentToLink from './dialog/DialogSearchDocumentToLink';
import DialogDocumentContentToLink from './dialog/DialogDocumentContentToLink';
import ConstantEvent            from '../../../shared/constant/constantEvent';
import EventBus                 from '../../../utils/event-bus.js';
import ConstantDocumentType from "../../../shared/constant/constantDocumentType";
import ConstantRelationType from "../../../shared/constant/constantRelationType";
import ConstantTag from "../../../shared/constant/constantTag";
import BolBackButton  from "../../shared/BolBackButton";

export default {
    name: 'SummaryLinkManagement',
    data () {
        return {
            isDocumentReady: false,
            documentHyperDialog: false,
            documentContentHyperLinkDialog: false,
            paoSummaryLink:{
                documentIdSource: null,
                documentIdDestination: null,
                documentDestinationVersion: 0,
                nodeUUIDSource: null,
                nodeUUIDDestination: null,
                contentDocumentDestination: "",
                titleDocumentDestination:"",
                typeLinkDestination:"",
                relationDocumentType: ""
            },
            documentTypeCode:null,
            documentLinkedList:{},
            jurisprudenceLinkedList:{},
            confirmDialogOpen:false,
            idSummaryToDelete:null,
            message:'pao.message.confirm-delete-pao-summary',
            canBeOpen:false,
            titleDocumentSource:""
        }
    },
    computed: {
        ...mapState({
            rootNode: state => state.documentContent.rootNode,
            documentId: state => state.documentContent.documentId,
            documentType: state => state.dataSheetStore.metaDocument.documentType
        }),
        documentContent(){
            return ConstantStoreName.DOCUMENT_CONTENT;
        },
        ARTICLE(){
            return ConstantTag.ARTICLE;
        },
        RESUME(){
            return ConstantTag.RESUME;
        },
        textBackButton(){
            return this.$t('pao.summary.leave');
        }
    },
    methods: {
        deletePao(idSummary){
            this.idSummaryToDelete = idSummary;
            this.confirmDialogOpen = true;
        },
        isConfirm(){
            axios.delete(
                `pao/summary/link/${this.idSummaryToDelete}`
            ).then(() => {
                this.getAllData(this.paoSummaryLink.nodeUUIDSource);
            });
            this.idSummaryToDelete = null;
        },
        /**
         * open a dialog to let the user chose the node he want to link
         */
        documentChosen( data ){

            this.documentContentHyperLinkDialog = true;
            EventBus.$emit(ConstantEvent.OPEN_DIALOG_DOCUMENT_NODE_HYPERLINK, {
                referenceDocumentDestination: data.referenceDocumentDestination,
                chosenTitleDocumentDestination: data.chosenTitleDocumentDestination,
                relationType: data.relationType
            });
        },
        relationDocumentTypeFiltered(relation){
            if(relation !== ConstantRelationType.NONE){
                switch(relation){
                    case ConstantRelationType.CONTRARY:
                        return this.$i18n.t('pao.relationtype.contrary');
                    case ConstantRelationType.SAME:
                        return this.$i18n.t('pao.relationtype.same');
                    case ConstantRelationType.FOLLOWING:
                        return this.$i18n.t('pao.relationtype.following');
                }
            }
        },
        openDocumentToLink(){
            if(this.canBeOpen) {
                this.documentHyperDialog = true;
            }
        },
        getAllData(uidNode){
            this.isDocumentReady = true;
            axios.get(
                'pao/summary/link/filter', {
                    params: {uidNode: uidNode}
                }
            ).then( (result) => {
                this.documentLinkedList = result.data;
            });

            axios.get(
                'pao/summary/link/jurisprudence', {
                    params: {uidNode: uidNode}
                }
            ).then( (result) => {
                this.jurisprudenceLinkedList = result.data;
            });
        },
        /**
         * If the user close the dialog for the selection of a node the dialog to select a document is reopen
         */
        closeDocumentNode(nodeDetails){
            if(!!nodeDetails) {
                if(nodeDetails.relationDocumentType === ConstantRelationType.NONE) {
                    this.paoSummaryLink.nodeUUIDDestination = nodeDetails.DestinationDocumentNodeUUID;
                    this.paoSummaryLink.documentIdSource = this.documentId;
                    this.paoSummaryLink.documentIdDestination = nodeDetails.DestinationDocumentID;
                }else{
                    this.paoSummaryLink.nodeUUIDDestination = this.paoSummaryLink.nodeUUIDSource;
                    this.paoSummaryLink.nodeUUIDSource = nodeDetails.DestinationDocumentNodeUUID;
                    this.paoSummaryLink.documentIdDestination = this.documentId;
                    this.paoSummaryLink.documentIdSource = nodeDetails.DestinationDocumentID;
                }
                this.paoSummaryLink.titleDocumentDestination = nodeDetails.titleDocumentDestination;
                this.paoSummaryLink.contentDocumentDestination = nodeDetails.contentDocumentDestination;
                this.paoSummaryLink.typeLinkDestination = nodeDetails.typeLinkDestination;
                this.paoSummaryLink.relationDocumentType = nodeDetails.relationDocumentType;


                axios.post(
                    'pao/summary/link/',
                    this.paoSummaryLink
                ).then(() => {
                    this.getAllData(this.paoSummaryLink.nodeUUIDSource);
                });
            }
            this.documentHyperDialog = false;
        },
        /**
         * Leave document link to pao to comeback datasheet
         **/
        leaveDocument(){
            this.$router.go(-1);
        },
        ...mapActions({
            updateNodeContent: 'documentContent/modifyContent',
            initSourceNode: 'hyperLinkStore/initSourceNode',
            findNodeById: 'documentContent/findNodeById',
            resetDocumentFilter: 'documentFilterStore/resetDocumentFilter'
        }),
        setDocumentDialog(event){
            this.documentLinkedList = [];
            this.paoSummaryLink.nodeUUIDSource = event.idnode;
            this.getAllData(this.paoSummaryLink.nodeUUIDSource);
            if(this.documentType.code === ConstantDocumentType.JURISPRUDENCE && event.value === true
                || this.documentType.code === ConstantDocumentType.DOCTRINE
                || this.documentType.code === ConstantDocumentType.BIBLIOGRAPHY){
                this.canBeOpen = true;
            }else{
                this.canBeOpen = false;
            }
        }
    },
    components: {
        DialogSearchDocumentToLink,
        NodeContentTreeLight,
        DialogDocumentContentToLink,
        DialogConfirmation,
        BolBackButton
    }
}
</script>
<style scoped>
    .item-left-container{
        height:600px;
        overflow: scroll;
        width:350px;
    }
.left-container{
    min-width:350px;
    height:100%;
    margin-left:1px;
    background:#355B7F;
    color:#D39E4E;
    text-align:center;
}
.right-container{
    overflow-y: auto;
    height:calc(100vh - 48px);
    padding-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    text-align: left;
    width:100%;
}

.left-container-text{
    color:white;
    font-size: .8em;
    margin-top:20px;
    width:350px;
    padding-left:5px;
    padding-right:5px;
}
.title-left{
    margin-top:15px;
    margin-bottom:15px;
}
.item-container{
    margin-top:15px;
    margin-bottom:15px;
    height:50px;
    max-height:50px;
}
.item-article{
    float:left;
    width:280px;
    padding-top: 5px;
    margin-left:5px;
    max-height:70px;
    white-space: nowrap;
    overflow: hidden;
}
.delete-article{
    float:right;
    padding-top: 7px;
    width:30px;
    height:30px;
    color: white;
}
.delete-article:hover{
    cursor:pointer;
}
.item-container:hover{
    background-color:rgba(91, 162, 217, 0.14);
    border-radius:10px;
}
.tabs-container{
        background-color:#355B7F;
        color:#D39E4E;
}
.open-doc{
    width:50px;
    height:50px;
    background-color: white;
    color:#355B7F;
    float:left;
    border-radius: 50px;
    padding-top:13px;
}
    .document-opener:hover{
        transform: scale(1.1);
    }
    .document-opener{
        width:200px;
        height:60px;
        position:absolute;
        bottom:130px;
        left:50px;
        cursor: pointer;
        background-color:#355B7F;
    }
.document-exit:hover{
    transform: scale(1.1);
}
.document-exit{
    width:200px;
    height:60px;
    position:absolute;
    bottom:50px;
    left:80px;
    cursor: pointer;
    background-color:#355B7F;
}
    .text-opener-document{
        float:right;
        margin-top:13px;
    }
    .text-exit-document{
        float:left;
        margin-top:13px;
        margin-left:75px;
    }
</style>
