<template>
    <v-layout
        row
        wrap
        align-left
    >
        <v-flex>
            <v-container>
                <h1
                    v-if="$route.params.id == null"
                    class="bolded"
                >
                    {{ $t('administration.magazine.manage.add.title') }}
                </h1>
                <h1
                    v-if="$route.params.id != null"
                    class="bolded"
                >
                    {{ $t('administration.magazine.manage.edit.title') }}
                </h1>
                <v-form
                    ref="formMagazine"
                    v-model="valid"
                    class="form-style"
                    lazy-validation
                >
                    <v-layout
                        column
                        justify-center
                    >
                        <v-flex md6>
                            <v-text-field 
                                v-model="magazineToManage.label"
                                :label="$t('administration.magazine.manage.label')"
                                :rules="labelRules"
                                required
                                clearable
                            />
                        </v-flex>
                        <v-flex xs8>
                            <v-layout
                                row
                                align-center
                            >
                                <v-flex xs9>
                                    <v-text-field 
                                        v-model="magazineToManage.code"
                                        :label="$t('administration.magazine.manage.code')"
                                        :rules="codeRules"
                                        required
                                        :disabled="!!this.$route.params.id"
                                        clearable
                                    />
                                </v-flex>
                                <v-flex
                                    xs3
                                    justify-end
                                >
                                    <!-- BUTTONS -->
                                    <v-tooltip
                                        top
                                        class="align-action-end"
                                    >
                                        <v-btn
                                            slot="activator"
                                            color="#355B7F"
                                            small
                                            fab
                                            :to="{ name: 'MagazineSearch' }"
                                        >
                                            <v-icon color="white">
                                                cancel
                                            </v-icon>
                                        </v-btn>
                                        <span>{{ $t("administration.magazine.manage.action.cancel") }}</span>
                                    </v-tooltip>
                                    <v-tooltip
                                        v-if="!!magazineToManage.label && !!magazineToManage.code"
                                        top
                                        class="align-action-end"
                                    >
                                        <v-btn
                                            slot="activator"
                                            color="primary"
                                            small
                                            fab
                                            dark
                                            icon
                                            @click="manage(magazineToManage)"
                                        >
                                            <v-icon>save</v-icon>
                                        </v-btn>
                                        <span v-if="$route.params.id == null">{{ $t("administration.magazine.manage.action.add") }}</span>
                                        <span v-if="$route.params.id != null">{{ $t("administration.magazine.manage.action.save") }}</span>
                                    </v-tooltip>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-form>     
            </v-container>
        </v-flex>
    </v-layout>
</template>
<script>

import axios            from 'axios';
import EventBus         from '../../../../utils/event-bus.js';
import ConstantEvent    from '../../../../shared/constant/constantEvent.js';

export default {
    name: 'MagazineManagement',
    data() {
        return {
            valid: false,
            magazineToManage: {
                id: "",
                label: "",
                code: ""
            },
            labelRules: [
                v => !!v || this.$t('administration.magazine.manage.required.label')
            ],
            codeRules: [
                v => !!v || this.$t('administration.magazine.manage.required.code')
            ]
        }
    },
    mounted(){
        /**
         * When the page is charging, we pay attention if an id is pass,
         * if it's the case, it means that we update a magazine instead of create one
         */
        if(this.$route.params.id !== undefined) {
            this.loadMagazine(this.$route.params.id);
        }
    },
    methods:{
        /**
         *  Create or update a magazine
         * @param magazineToManage
         */
        manage(magazineToManage) {
            if(this.$refs.formMagazine.validate()){
                var method = null;
                if(this.$route.params.id) {
                    method = "PUT",
                    magazineToManage.id = this.$route.params.id;
                } else {
                    method = "POST";
                }
               
                axios( {
                    method: method,
                    url: "administration/publication/magazine",
                    data: magazineToManage
                }).then( () => {
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                        message: 'administration.magazine.manage.' +  (!this.$route.params.id ? 'add' : 'edit') +'.success',
                        type: 'SUCCEED'
                    }
                    );
                    this.$router.go( -1 );
                });
            }
        },
        /**
         * Retrieve a magazine with his id
         * @param magazineId
         */
        loadMagazine( magazineId ) {
            axios.get(
                `administration/publication/magazine/${magazineId}`
            ).then( result => {
                this.magazineToManage = result.data;
            });
        },
    }
}
</script>
<style scoped>
.form-style {
    margin-top:50px;
}
.align-action-end{
    float: right;
}
.bolded{
    font-weight: bold;color: #355B7F;
}
</style>
