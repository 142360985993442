const WHERE_AFTER           = 'after';
const WHERE_BEFORE          = 'before';
const WHERE_INSIDE          = 'inside';
const ORDERED               = 'ordered';
const UNORDERED             = 'unordered';
export default class ConstantNode{
    
    static get WHERE_AFTER(){
        return WHERE_AFTER;
    }
    static get WHERE_BEFORE(){
        return WHERE_BEFORE;
    }
    static get WHERE_INSIDE(){
        return WHERE_INSIDE;
    }
    static get ORDERED(){
        return ORDERED;
    }
    static get UNORDERED(){
        return UNORDERED;
    }
    
}