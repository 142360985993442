<template>
    <div>
        <v-snackbar
            v-for="(notification, i) in notifications"
            :key="notification.id"
            :value="i === 0"
            :timeout="timeout(notification)"
            :color="color(notification)"
            :top="top(notification)"
            :bottom="bottom(notification)"
            :right="right(notification)"
            :left="left(notification)"
            auto-height
            multi-line
        >
            {{ $t(notification.message, {message: notification.additionalMessage}) }}
            <v-btn
                icon
                color="white"
                flat
                @click="removeNotification(notification.id)"
            >
                <v-badge color="red">
                    <span v-if="notifications.length > 1" 
                            slot="badge">
                        {{notifications.length - 1}}
                    </span>
                    <v-icon  v-if="notifications.length > 1 && notification.type !== ERROR"
                            large>
                        navigate_next
                    </v-icon>
                    <v-icon v-else
                            large>
                        close
                    </v-icon>
                </v-badge>
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import ConstantNotification from '../../../shared/constant/constantNotification.js';

const TYPES = {
    SUCCEED: {color: 'green', timeout: 5000},
    INFO: {color: 'blue', timeout: 6000},
    WARNING: {color: 'orange', timeout: 7000},
    ERROR: {color: 'red', timeout: 0}
};

export default {
    name: 'NotificationCard',
    data: () => ({
        processing: false,
        currentTimeoutID: null,
        ERROR: ConstantNotification.ERROR
    }),
    props: {
        notifications: {
            type: Array,
            required: true
        },
        value: {
            required: false,
            default: false
        }
    },
    methods: {
        /**
         * Show a notification
         */
        showNotifications () {
            if (this.notifications.length > 0) {
                const notification = this.notifications[0];
                if (notification.type !== ConstantNotification.ERROR){
                    return this.currentTimeoutID = setTimeout(() => {
                        this.removeNotification(notification.id);
                    }, TYPES[notification.type].timeout);
                }
            }
        },
        removeNotification (id) {
            clearTimeout(this.currentTimeoutID);
            this.$emit('remove-notification', id);
            if (this.notifications.length > 0) {
                return this.showNotifications();
            }
        },
        timeout (notification) {
            return TYPES[notification.type].timeout;
        },
        color (notification) {
            return TYPES[notification.type].color;
        },
        top (notification) {
            return notification.top || !notification.bottom;
        },
        bottom (notification) {
            return notification.bottom;
        },
        right (notification) {
            return notification.right || !notification.left;
        },
        left (notification) {
            return notification.left;
        }
    },
    watch: {
        notifications () {
            this.showNotifications();
        }
    }
}
</script>
