<template>
    <v-layout justify-start>
        <v-flex class="filter_sizer">
            <pao-filter @search="searchPaoList" :searchOnly="searchOnly"/>
        </v-flex>
        <v-flex class="pao_sizer">
            <slot :displaySpinner="loading" :paoList="paoList">
                <pao-list :displaySpinner="loading">
                    <pao-card-list :paoList="paoList" @changePage="changePage"/>
                </pao-list>
            </slot>
        </v-flex>
    </v-layout>
</template>
<script>
import PaoList                      from './PaoList';
import PaoCardList                  from './PaoCardList';
import PaoFilter                    from './PaoFilter';
import StoreModule                  from '../../../shared/constant/store/module/namespace';
import PaoFilterStoreTypes          from '../../../shared/constant/store/module/paoFilterTypes';
import {mapState, mapActions}       from 'vuex';
import axios                        from 'axios';

/**
 * Pao search component.
 *
 * @author Cédric de BOISVILLIERS, SFEIR benelux
 * @version 1.0
 * @since 17/05/2019
 */
export default {
    name: "PaoSearch",
    props: {
        searchOnly: {
            default: false
        }
    },
    components: {
        PaoList,
        PaoCardList,
        PaoFilter
    },
    created() {
        this.searchPaoList();
    },
    computed: {
        ...mapState(StoreModule.PAO_FILTER, {
            filter: state => state.filter,
            pageable: state => state.pageable
        })
    },
    data() {
        return {
            paoList: [],
            loading: false
        }
    },
    methods: {
        ...mapActions(
            StoreModule.PAO_FILTER, {
                updatePageable: PaoFilterStoreTypes.ACTION.UPDATE_PAGEABLE
            }
        ),
        /**
         * search a pao list with a search filter and a pageable object
         */
        searchPaoList(){
            this.loading = true;
            axios.post(
                'pao/filter',
                this.filter,
                {params: this.pageable}
            ).then( ({data}) => {
                this.loading = false;
                this.paoList = data.content;
                this.updatePageable({
                        totalElements: data.totalElements,
                        totalPages: data.totalPages,
                        page: data.page
                });
            });
        },
        /**
         * Update the page number and the pao list according to this page number
         * @param {*} number
         */
        changePage( page ){
            this.updatePageable({
                    totalElements: this.updatePageable.totalElements,
                    totalPages: this.updatePageable.totalPages,
                    page: page
            });

            this.searchPaoList(this.searchFilters, this.pageable);
        },
    }
}
</script>
<style scoped>

    .filter_sizer{
        width: 400px;
    }
    .pao_sizer{
        width: 100%;
    }

</style>
