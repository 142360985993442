<template>
    <v-dialog v-model="dialog" persistent fullscreen>
        <v-card>
            <v-card-title class="headline head_top">
                <span v-if="creationMode">{{$t('structure.node.action.addQuote')}} - {{documentType.label}}</span>
                <span v-else>{{$t('structure.node.action.modifyQuote')}}</span>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
                <v-select
                    :items="documentTypeList"
                    :label="$t('document.type.documenttype')"
                    item-text="label"
                    return-object
                    v-model="documentType"
                    clearable
                    no-data
                    />

                <node-content-tree :storeName="blockquote" :idDocumentType="documentType.id" v-on:dblclick.native="openTiny" ref="editor"/>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" flat @click.native="close();">{{$t('document.action.cancel')}}</v-btn>
                <v-btn v-if="creationMode" color="primary" flat @click.native="addQuote();">{{$t('document.action.add')}}</v-btn>
                <v-btn v-else color="primary" flat @click.native="updateQuote();">{{$t('document.action.edit')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios                    from 'axios';
import ConstantDocumentNode     from '../../../../shared/constant/constantDocumentNode.js';
import ConstantEvent            from '../../../../shared/constant/constantEvent.js';
import ConstantStoreName        from '../../../../shared/constant/constantStoreName.js';
import ConstantTag              from '../../../../shared/constant/constantTag.js';
import DocumentTreeService      from '../../../../service/document/documentTreeService.js';
import EventBus                 from '../../../../utils/event-bus.js';
import NodeContentTree          from '../displayer/NodeContentTree.vue';
import {mapState, mapActions}   from 'vuex';
import DomManipulatorService    from '../../../../service/html/domManipulatorService';

/**
 * 
 * Dialog use to add a quote to a node
 * @author Justin WILMET
 * @since 05/02/2019
 * @version 1.0
 * 
 */
export default {
    name: 'DialogManageQuote',
    data(){
        return {
            dialog: false,
            pageable: {page: 1, size: 100, direction: 'ASC', property:"id"},
            searchFilter: {},
            documentType: {},
            creationMode: false,
            documentTypeList: [],
        }
    },
    components: {
        NodeContentTree
    },
    computed:{
        selectedNode(){
            return this.$store.state[`${ConstantStoreName.BLOCKQUOTE}`].selectedNode;
        },
        blockquote(){
            return ConstantStoreName.BLOCKQUOTE;
        },
        rootNode(){
            return this.$store.state[`${ConstantStoreName.BLOCKQUOTE}`].rootNode;
        },
    },
    created(){
        EventBus.$on(ConstantEvent.ADD_QUOTE, this.manageQuote);
        EventBus.$on(ConstantEvent.MODIFY_QUOTE, this.manageQuote);
    },
    beforeDestroy: function(){
        EventBus.$off(ConstantEvent.ADD_QUOTE);
        EventBus.$off(ConstantEvent.MODIFY_QUOTE);
    },
    methods:{
        ...mapActions({
            addNodePatternToTree: 'blockquote/addNodePatternToTree',
            setRootNode: 'blockquote/setRootNode',
            addGenerateNodeInTree: 'blockquote/addGenerateNodeInTree'
        }),
        /**
         * At the creation of the dialog the component will initialize 
         * The document type list 
         * and the first node for the quote tree
         */
        manageQuote( blockquote = false){
            this.dialog = true;
            this.loadDocumentType(); 
            if( !blockquote ){ 
                this.creationMode = true; 
            }
            this.intializeBlockquoteStore( blockquote );
        },
        /**
         * At the double click on the document will open the content editor if the selected node exist and if it's a text node
         * we use the keyword 'ref' to allow the parent to be conscious of methods of is child component containing the 'ref'
         */
        openTiny(){
            if(!!this.selectedNode && this.selectedNode.label === ConstantDocumentNode.TEXT_TYPE){
                this.$refs.editor.onOpenDialog( {dialog:ConstantEvent.OPEN_DIALOG_CONTENT_EDITOR} );
            }
        },
        /**
         * Based on the created quote tree will change it as a HTML structure
         */
        addQuote(){
            let blockquote = DomManipulatorService.manageBlockquote( this.rootNode.children, this.documentType);
            EventBus.$emit( ConstantEvent.ON_MANAGE_QUOTE, blockquote );
            this.close();
        },
        /**
         * Update the quote passed in argument
         */
        updateQuote(){
            let blockquote = DomManipulatorService.manageBlockquote( this.rootNode.children, this.documentType);
            EventBus.$emit( ConstantEvent.ON_MANAGE_QUOTE, blockquote );
            this.close();
        },
        /**
         * Load all the document type
         */
        loadDocumentType(){
            axios.post(
                'administration/documenttype/findAll',
                this.searchFilter,
                {params: this.pageable}
            ).then( result => {
                let documentTypes =  result.data.content.filter( leg => leg.label === 'Legislation');
                this.documentTypeList = result.data.content;
                if( !!documentTypes ){
                    this.documentType = documentTypes[0];
                }
            });
        },
        /**
         * Create a first node 'CITATION' (json) and set it as the rootNode of the blockquote store
         */
        intializeBlockquoteStore( blockquote ){
            let node = DocumentTreeService.createPatternNode( `${ConstantTag.CITATION}`);
            DocumentTreeService.updateNodePath( node, node );
            this.setRootNode(node);
            if( !!blockquote ){
                blockquote.childNodes.forEach(child => {
                    let extractedStructuralNode = DocumentTreeService.convertDomElementIntoStructuralNode(child);
                    this.addGenerateNodeInTree({selectedNode: node, node: extractedStructuralNode});
                })
            } 
            
        },
        /**
         * Close the dialog and reset all the value
         */
        close(){
            this.creationMode = false;
            this.dialog = false;
        }
    }
}
</script>

<style scoped>

.bol-radio-group{
    justify-content: center;
}
.bol-row-radio-group{
    display:flex; 
    align-items:flex-start;
}
.head_top{
    margin-top: 65px;
}
</style>
