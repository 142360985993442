<template>
    <div class="administration">
        <router-view/>
    </div>
</template>

<script>
    /**
     * 
     * Main content structure. Display all the application.
     * @author Justin WILMET, SFEIR Benelux
     * @version 1.0
     * @since 18/09/2018
     * 
     */
    export default {
        name: 'Administration'
    }
</script>