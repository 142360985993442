export default {
    namespaced: true,
    // initial state
    state: {
        filter: {
            workspaceId: null,
            status: null,
            step: null,
            documentTypeId: null,
            documentDate: null,
            reference: '',
            title: '',
            text: '',
            userId: null,
            applicable: false,
            domains: [],
            natureId: null,
            jurisdictionId: null
        }
    },
    // mutations ==> Synchronous way
    mutations: {
        set_document_filter: (state, data) => {
            state.filter.workspaceId = data.workspaceId;
            state.filter.status = data.status;
            state.filter.step = data.step;
            state.filter.reference = data.reference;
            state.filter.title = data.title;
            state.filter.documentTypeId = data.documentTypeId;
            state.filter.applicable = data.applicable;
            state.filter.text = data.text;
            state.filter.userId = data.userId;
            state.filter.documentDate = data.documentDate;
            state.filter.domains = data;
            state.filter.natureId= data;
            state.filter.jurisdictionId= data;
        },
        reset_document_filter: (state, data) => {
            state.filter.workspaceId = null;
            state.filter.status = null;
            state.filter.step = null;
            state.filter.reference = '';
            state.filter.title = '';
            state.filter.documentTypeId = null;
            state.filter.applicable = false;
            state.filter.text = '';
            state.filter.userId = null;
            state.filter.documentDate = null;
            state.filter.domains= [];
            state.filter.natureId= null;
            state.filter.jurisdictionId= null;
        },
        update_reference: (state, value) => {
            state.filter.reference = value;
        },
        update_title: (state, value) => {
            state.filter.title = value;
        },
        update_document_date: (state, value) => {
            state.filter.documentDate = value;
        },
        update_workspace_id: (state, value) => {
            state.filter.workspaceId = value;
        },
        update_status: (state, value) => {
            state.filter.status = value;
        },
        update_document_type_id: (state, value) => {
            state.filter.documentTypeId = value;
        },
        update_document_step: (state, value) => {
            state.filter.step = value;
        },
        update_user_id: (state, value) => {
            state.filter.userId = value;
        },
        update_domains: (state, value) =>{
            state.filter.domains = value;
        },
        update_nature_id: (state, value) =>{
            state.filter.natureId = value;
        },
        update_jurisdiction_id: (state, value) =>{
            state.filter.jurisdictionId = value;
        },
        reset_nature_id: ( state ) =>{
            state.filter.natureId = null;
        },
        reset_jurisdiction_id: ( state ) =>{
            state.filter.jurisdictionId = null;
        }

    },
    // actions ==> Asynchronous way
    actions: {
        setDocumentFilter: ({ commit }, data) => {
            commit('set_document_filter', data);
        },
        resetDocumentFilter: ({ commit }, data) => {
            commit('reset_document_filter', data);
        },
        resetJurisdictionId: ( { commit } ) =>{
            commit('reset_jurisdiction_id');
        },
        resetNatureId: ( { commit } ) =>{
            commit('reset_nature_id');
        },
        updateNatureId: ({ commit }, value ) => {
            commit('update_nature_id', value);
        },
        updateJurisdictionId: ({ commit }, value ) => {
            commit('update_jurisdiction_id', value);
        },
        updateDomains: ({ commit }, value ) => {
            commit('update_domains', value);
        },
        updateReference: ({ commit }, value ) => {
            commit('update_reference', value);
        },
        updateTitle: ({ commit }, value ) => {
            commit('update_title', value);
        },
        updateUserId: ({ commit }, value) =>{
            commit('update_user_id', value)
        },
        updateDocumentDate: ({ commit }, value) => {
            commit('update_document_date', value);
        },
        updateWorkspaceId: ({ commit }, value) => {
            commit('update_workspace_id', value);
        },
        updateStatus: ({ commit }, value) => {
            commit('update_status', value);
        },
        updateDocumentTypeId: ({ commit }, value) => {
            commit('update_document_type_id', value);
        },
        updateDocumentStep: ({ commit }, value) => {
            commit('update_document_step', value);
        }
    }
}