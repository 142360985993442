import axios from 'axios';
import DocumentTreeService from '../../service/document/documentTreeService.js';
import DocumentFootnoteService from '../../service/document/documentFootnoteService.js';
import ConstantAction from '../../shared/constant/constantAction.js';
import ConstantTag from '../../shared/constant/constantTag.js';
import ConstantNode from '../../shared/constant/constantNode.js';

/**
 * Export the document content store
 */
export default {
    namespaced: true,
    /**
     * State of the document content store
     */
    state: () => ({
        rootNode: [],
        flattedTree: [],
        selectedNode: null,
        additionalSelectedNodeList: [],
        selectedNodePath: [],
        documentId: null,
        documentLinkList: [],
        nodeToPaste: null,
        textToPaste: null,
        reference: null
    }),
    /**
     * Getters of the document content store
     */
    getters: {
        /**
         * Create a path for the selected node. This path is a table of each node used to go to the selected node.
         * This table is usefull for update the document tree component.
         */
        createSelectedNodePath(state) {
            let nodePath = [];
            let currentNode = state.rootNode;
            state.selectedNode.technicalPath.forEach(idPath => {
                if (currentNode.id == idPath) {
                    nodePath.push(currentNode);
                } else {
                    let nextNode = currentNode.children.find(child => child.id == idPath);
                    nodePath.push(nextNode);
                    currentNode = nextNode;
                }
            });
            return nodePath;
        },
        allSelectedNodes: state => [state.selectedNode, ...state.additionalSelectedNodeList]
    },
    /**
     * Actions available to the document content store
     */
    actions: {
        /**
         * Load a document content tree and flat the tree in another variable
         * @param {*} context
         * @param {*} id
         */
        loadDocumentContent({state, commit, dispatch}, {id, toLock}) {
            if (!state.documentId || toLock || state.documentId !== id) {
                let url = `document/${id}`;
                if (toLock) {
                    url += '/lock';
                }
                dispatch('loadDocumentContentWithUrl', {id, url});
            }
        },
        /**
         * Load a document from an URL passed in argument and initialize all the needed store variable
         * @param {*} id
         * @param {*} url
         */
        loadDocumentContentWithUrl({commit}, {id, url}) {
            commit('setRootNode', []);
            commit('setFlattedTree', []);
            commit('setSelectedNode', null);
            commit('selectNodeFromPath', null);
            commit('setDocumentId', null);
            commit('setReference', null);

            return axios.get(url
            ).then(({data}) => {
                commit('setDocumentId', id);
                commit('setRootNode', data.content);
                commit('setFlattedTree', data.content);
                commit('setSelectedNode', data.content);
                commit('selectNodeFromPath', data.content);
                commit('setReference', data.reference);
            });
        },
        /**
         * Load all the link of a document
         */
        loadDocumentLinkList({state, commit}, documentId) {
            axios.get(
                'link/',
                {params: {idDocument: documentId}}
            ).then(({data}) => {
                commit('setDocumentLinkList', data);
            });
        },
        /**
         * Return a node that match with the id in argument
         * @param {*} nodeId
         */
        findNodeById({state}, nodeId) {
            return state.flattedTree.find(node => node.id === nodeId);
        },
        /**
         * Open a node
         */
        openNode({commit}, node) {
            commit('setOpenNode', {node: node, isOpened: true})
        },
        /**
         * Toggle the attribut open on the node
         */
        toggleOpenNode({commit}, node) {
            commit('setOpenNode', {node: node, isOpened: !node.opened});
        },
        /**
         * Update the selected node path
         * @param {*} param0
         */
        updateSelectedNodePath({commit, getters}) {
            let nodePath = getters.createSelectedNodePath;
            commit('setSelectedNodePath', nodePath);
        },
        /**
         * Update the selected node content
         * @param {*} param0
         */
        updateSelectedNodeContent({commit}, content) {
            commit('updateNodeContent', {node: selectedNode, updatedContent: content});
        },
        /**
         * Update the selected node visiblity and recursively all of his child
         * @param {*} param0
         * @param {*} isVisible
         */
        updateSelectedNodeVisibility({commit}, isVisible) {
            commit('updateSelectedNodeVisibility', isVisible);
        },
        /**
         * Select a node and update the flatted tree part to have the node in the middle of the table.
         * @param {*} param0
         * @param {*} node
         */
        selectNode({commit, getters}, node) {
            commit('setSelectedNode', node);
            let nodePath = getters.createSelectedNodePath;
            commit('setSelectedNodePath', nodePath);
        },
        /**
         * Select a node from the document tree component
         * @param {*} param0
         * @param {*} node
         */
        selectNodeFromPath({commit}, node) {
            commit('selectNodeFromPath', node);
            commit('setSelectedNode', node);
        },
        /**
         * Add a node to the selected node
         */
        toggleAdditionalSelectedNode({state, commit}, node) {
            if (state.additionalSelectedNodeList.includes(node)) {
                commit('toggleSelectedAdditionalNode', node);
                commit('removeAdditionalSelectedNode', node);
            } else {
                commit('addAdditionalSelectedNode', node);
                commit('toggleSelectedAdditionalNode', node);
            }
        },
        /**
         * Remove all additional selected nodes from list
         */
        removeAllAdditionalSelectedNodes({state, commit}) {
            if (state.additionalSelectedNodeList.length > 0) {
                commit('clearAdditionalSelectedNodeList');
            }
        },
        /**
         * Create a node and add it inside, before or after the selected node
         * @param {*} param0
         * @param {*} param1
         */
        addNodePatternToTree({state, dispatch}, {nodePattern, where}) {
            var node = [];
            var siblingIndex;
            var parentNode;
            if (where === ConstantNode.WHERE_INSIDE) {
                parentNode = state.selectedNode;
            } else {
                parentNode = DocumentTreeService.findParentNode(state.selectedNode, state.flattedTree);

                siblingIndex = parentNode.children.findIndex(childNode => childNode.id === state.selectedNode.id);

            }
            node = DocumentTreeService.createNode(nodePattern, parentNode);
            dispatch('addExistingNode', {node, where, parentNode, siblingIndex});
        },
        /**
         * Add a node to the current tree, inside, before or after the selected node
         * @param {*} param0
         * @param {*} param1
         */
        addNodeToTree({state, dispatch}, {node, where, isOnQuoteNode}) {
            let siblingIndex;
            let parentNode;
            if (where === ConstantNode.WHERE_INSIDE) {
                parentNode = state.selectedNode;
            } else {
                parentNode = DocumentTreeService.findParentNode(state.selectedNode, state.flattedTree);
                siblingIndex = parentNode.children.findIndex(childNode => childNode.id === state.selectedNode.id);
            }
            DocumentTreeService.updateRecursivelyNodePath(node, parentNode);

            dispatch('addExistingNode', {node, where, parentNode, siblingIndex});
        },
        /**
         * Add an existing node inside the document tree
         * @param {*} param0
         * @param {*} param1
         */
        addExistingNode({state, commit, getters}, {node, where, parentNode, siblingIndex}) {
            if (where === ConstantNode.WHERE_INSIDE) {
                commit('addNode', node);
            } else {
                if (where === ConstantNode.WHERE_AFTER) {
                    commit('addNodeAfterSiblingNode', {node, parentNode, index: siblingIndex});
                } else if (where === ConstantNode.WHERE_BEFORE) {
                    commit('addNodeBeforeSiblingNode', {node, parentNode, index: siblingIndex});
                }
            }
            commit('setFlattedTree', state.rootNode);

            var createdNode = state.flattedTree.find(element => element.id === node.id);
            commit('setSelectedNode', createdNode);

            let nodePath = getters.createSelectedNodePath;
            commit('setSelectedNodePath', nodePath);
            commit('deleteNodeToPaste');
        },
        /**
         * Delete a selected node and its children
         */
        deleteSelectedNode({state, commit, getters}, selectedNode) {
            let parentNode = DocumentTreeService.findParentNode(selectedNode, state.flattedTree);
            let indexDeletedNode = parentNode.children.findIndex(childNode => childNode.id === selectedNode.id);

            commit('deleteNodeInTree', {parentNode: parentNode, indexDeletedNode: indexDeletedNode});
            commit('setFlattedTree', state.rootNode);

            let deletedNodeParent = state.flattedTree.find(element => element.id === parentNode.id);
            commit('setSelectedNode', deletedNodeParent);

            let nodePath = getters.createSelectedNodePath;
            commit('setSelectedNodePath', nodePath);
        },
        setSelectedNodeContent({state, commit}, content) {
            commit('updateNodeContent', {node: state.selectedNode, updatedContent: content});
            commit('deleteTextToPaste');
        },
        setAllSelectedNodeContent({dispatch}, allSelectedNodeList) {
            allSelectedNodeList.forEach(selectednode => {
                dispatch('modifyContent', selectednode);
            })
        },
        /**
         * Update the footnotes node and create it if he does not exist
         * @param {*} param0
         * @param {*} content
         */
        updateFootnotes({state, commit}, data) {
            let coupleList = DocumentFootnoteService.orderFootnoteListInContent(data.footnoteList, data.footnoteStyle, state.flattedTree);
            coupleList.forEach(couple => {
                commit('updateNodeContent', {node: couple.node, updatedContent: couple.content})
            });

            let footnoteNode = state.flattedTree.find(element => element.label === ConstantTag.FOOTNOTES);
            if (!footnoteNode) {
                footnoteNode = DocumentTreeService.createNode(DocumentTreeService.createPatternNode(ConstantTag.FOOTNOTES), state.rootNode);
                footnoteNode.children[0] = DocumentTreeService.createNode(DocumentTreeService.createPatternNode(ConstantTag.TEXT), footnoteNode);
                footnoteNode.children[0].content = DocumentFootnoteService.convertFootnoteListToNode(data.footnoteList, data.footnoteStyle).outerHTML;
                state.rootNode.children.push(footnoteNode);
            }
            let content = footnoteNode.children[0].content.replace(footnoteNode.children[0].content, DocumentFootnoteService.convertFootnoteListToNode(data.footnoteList, data.footnoteStyle).outerHTML);
            commit('updateNodeContent', {node: footnoteNode.children[0], updatedContent: content})
            commit('setFlattedTree', state.rootNode);
        },
        /**
         * Unlink the target footnote in the document and keep his value in the footnotes node
         * @param {*} param0
         * @param {*} data
         */
        unlinkFootnotes({state, commit}, data) {
            DocumentFootnoteService.deleteFootnoteInContent(data.footnoteList, data.footnoteId, state.flattedTree);

            DocumentFootnoteService.orderFootnoteListInContent(data.footnoteList, data.footnoteStyle, state.flattedTree);

            let footnoteNode = state.flattedTree.find(element => element.label === ConstantTag.FOOTNOTES);
            let updatedFootnoteList = DocumentFootnoteService.manageFootnoteInFootnoteList(data.footnoteList, data.footnoteId);
            let footnoteNodeList = DocumentFootnoteService.convertFootnoteListToNode(updatedFootnoteList, data.footnoteStyle).outerHTML;
            let content = footnoteNode.children[0].content.replace(footnoteNode.children[0].content, footnoteNodeList);

            commit('updateNodeContent', {node: footnoteNode.children[0], updatedContent: content});
            commit('setFlattedTree', state.rootNode);
        },
        /**
         * Delete the footnote in the footnotes node
         * @param {*} param0
         * @param {*} data
         */
        deleteFootnotes({state, commit}, data) {
            DocumentFootnoteService.deleteFootnoteInContent(data.footnoteList, data.footnoteId, state.flattedTree);

            var footnoteList = DocumentFootnoteService.deleteFootnoteInFootnoteList(data.footnoteList, data.footnoteId);

            DocumentFootnoteService.orderFootnoteListInContent(footnoteList, data.footnoteStyle, state.flattedTree);

            var footnoteNode = state.flattedTree.find(element => element.label === ConstantTag.FOOTNOTES);

            footnoteNode.children[0].content = footnoteNode.children[0].content.replace(footnoteNode.children[0].content, DocumentFootnoteService.convertFootnoteListToNode(footnoteList, data.footnoteStyle).outerHTML);
            commit('setFlattedTree', state.rootNode);
        },

        /**
         * Persist a modification on the node
         * @param {*} data
         */
        modifyContent({state, commit}, nodeToUpdate) {
            if (!nodeToUpdate.content.error) {
                var node = DocumentTreeService.findNode(state.flattedTree, nodeToUpdate.id);
                commit('updateNodeContent', {node: node, updatedContent: nodeToUpdate.content});
            }
        },

        /**
         * Insert in the tree the copied or cutted nodes depending on where we decided to paste it
         * @param {*} param0
         * @param {*} data
         */
        addPasteNodeInTree({state, dispatch}, data) {
            const action = state.nodeToPaste ? state.nodeToPaste.action : data.action;
            if (action === ConstantAction.CUT) {
                dispatch('addNodeToTree', {
                    node: DocumentTreeService.changeNodeId(data.node, data.selectedNode),
                    where: data.where,
                    isOnQuoteNode: false
                });
                dispatch('deleteSelectedNode', data.node);
            } else if (data.action === ConstantAction.COPY_STRUCT) {
                let copiedStructNode = DocumentTreeService.changeNodeId(data.node, data.selectedNode);
                dispatch('addNodeToTree', {
                    node: DocumentTreeService.deleteContentNodeInside(copiedStructNode),
                    where: data.where,
                    isOnQuoteNode: false
                });
            } else if (data.action === ConstantAction.PASTE_NODE) {
                dispatch('addNodeToTree', {
                    node: DocumentTreeService.changeNodeId(data.node, data.selectedNode),
                    where: data.where,
                    isOnQuoteNode: false
                });
            }
        },
        /**
         * Add a new struct of nodes in the three without changing the selected node
         * @param {*} param0
         * @param {*} data
         */
        addGenerateNodeInTree({state, commit, getters}, data) {
            let parentNode = data.selectedNode;
            let node = DocumentTreeService.changeNodeId(data.node, data.selectedNode);
            DocumentTreeService.updateRecursivelyNodePath(node, parentNode);

            commit('addNode', node);
            commit('setFlattedTree', state.rootNode);

            let createdNode = state.flattedTree.find(element => element.id === parentNode.id);
            commit('setSelectedNode', createdNode);

            let nodePath = getters.createSelectedNodePath;
            commit('setSelectedNodePath', nodePath);
        },
        setRootNode({commit}, rootNode) {
            commit('setRootNode', rootNode);
            commit('setFlattedTree', rootNode);
            commit('setSelectedNode', rootNode);
            commit('selectNodeFromPath', rootNode);
        },
        /**
         * Paste either a  a node and update the flatted tree part to have the node in the middle of the table.
         * @param {*} param0
         * @param {*} node
         */
        setCopiedSelection({commit}, data) {
            if (data.action === ConstantAction.COPY_NODE) {
                commit('setNodeToPaste', data);
            }
        },
    },
    /**
     * MUTATIONS
     */
    mutations: {
        /**
         * Remove the list of keywords of the selected node
         * @param {*} listIndex the list of index to delete
         */
        removeKeywords(state, listIndex) {
            for (var i = listIndex.length - 1; i >= 0; i--) {
                state.selectedNode.keywords.splice(listIndex[i], 1);
            }
        },
        /**
         * Update the rank of keywords from the selected node
         * @param {*} index of the keywordList deleted
         */
        setRankKeyword(state, index) {
            state.selectedNode.keywords[index].rank--;
        },
        /**
         * Add new list of keywords to keywords of the selected node
         * @param {Object} keywordsToAdd
         */
        addKeywords(state, keywordsToAdd) {
            if (!state.selectedNode.keywords) {
                state.selectedNode.keywords = [];
            }
            keywordsToAdd.forEach(keyword => {
                state.selectedNode.keywords.push(keyword);
            })
        },
        deleteNodeInTree(state, nodeToDelete) {
            nodeToDelete.parentNode.children.splice(nodeToDelete.indexDeletedNode, 1);
        },
        setRootNode(state, rootNode) {
            state.rootNode = rootNode
        },
        setFlattedTree(state, rootNode) {
            state.flattedTree = DocumentTreeService.flatTree(rootNode);
        },
        addNode(state, node) {
            if (!state.selectedNode.children) {
                state.selectedNode.children = [];
            }
            state.selectedNode.children.push(node);
        },
        addNodeBeforeSiblingNode({state}, {node, parentNode, index}) {
            parentNode.children.splice(index, 0, node);
        },
        addNodeAfterSiblingNode({state}, {node, parentNode, index}) {
            parentNode.children.splice(index + 1, 0, node);
        },
        deleteNode({state}, {indexDeletedNode, parentNode}) {
            parentNode.children.splice(indexDeletedNode, 1);
        },
        setSelectedNode(state, node) {
            if (state.selectedNode) {
                state.selectedNode.selected = false;
            }
            state.selectedNode = node;
            if (state.selectedNode) {
                state.selectedNode.selected = true;
            }
        },
        setNodeToPaste(state, node) {
            if (!state.nodeToPaste) {
                state.nodeToPaste = node;
            }
        },
        setTextToPaste(state, text) {
            if (!state.textToPaste) {
                state.textToPaste = text;
            }
        },
        deleteNodeToPaste(state) {
            state.nodeToPaste = null;
        },
        deleteTextToPaste(state) {
            state.textToPaste = null;
        },
        setSelectedNodePath(state, nodePath) {
            state.selectedNodePath = nodePath;
        },
        selectNodeFromPath(state, node) {
            var index = state.selectedNodePath.indexOf(node);
            if (index >= 0) {
                state.selectedNodePath.splice(index + 1);
            } else {
                if (node) {
                    state.selectedNodePath.push(node);
                } else {
                    state.selectedNodePath = [];
                }
            }
        },
        addAdditionalSelectedNode(state, node) {
            state.additionalSelectedNodeList.push(node);
        },
        removeAdditionalSelectedNode(state, node) {
            let nodeIndex = state.additionalSelectedNodeList.indexOf(node);
            state.additionalSelectedNodeList.splice(nodeIndex, 1);
        },
        toggleSelectedAdditionalNode(state, node) {
            let nodeIndex = state.additionalSelectedNodeList.indexOf(node);
            state.additionalSelectedNodeList[nodeIndex].selected = !state.additionalSelectedNodeList[nodeIndex].selected;
        },
        clearAdditionalSelectedNodeList(state) {
            state.additionalSelectedNodeList.forEach(addAdditionalSelectedNode => {
                addAdditionalSelectedNode.selected = false;
            });
            state.additionalSelectedNodeList.splice(0, state.additionalSelectedNodeList.length);
        },
        setDocumentId(state, id) {
            state.documentId = id;
        },
        setOpenNode(state, {node, isOpened}) {
            node.opened = isOpened;
        },
        updateNodeContent(state, {node, updatedContent}) {
            if (!updatedContent.error) {
                node.content = updatedContent;
            }
        },
        setDocumentLinkList(state, links) {
            state.documentLinkList = links;
        },
        updateSelectedNodeVisibility(state, isVisible) {
            let selectedNodeTechnicalPath = state.selectedNode.technicalPath.toString();
            state.flattedTree.forEach(child => {
                if (child.technicalPath.toString().includes(selectedNodeTechnicalPath)) {
                    child.visible = isVisible;
                }
            })
        },
        setReference(state, reference) {
            state.reference = reference;
        }
    }
}
