const FR        = 'FR';
const EN        = 'EN';
const GB        = 'GB';
const NL        = 'NL';


export default class ConstantLanguage{

    static get FR(){
        return FR;
    }
    static get EN(){
        return EN;
    }
    static get GB(){
        return GB;
    }
    static get NL(){
        return NL;
    }
}