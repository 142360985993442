<template>
    <v-layout pl-3>
        <v-flex :id="`${node.id}`" class="pao-node">
            <div class="bol_structure_icon">
                <div v-if="!!node.children" class="d-inline-flex" @click="toggleOpen">
                    <span>
                        <v-icon v-if="!open" v-bind:class="{ bol_hilhlight_selected_folder: isFolderSelected(node.id) }">folder</v-icon>
                        <v-icon v-else v-bind:class="{ bol_hilhlight_selected_folder: isFolderSelected(node.id) }">folder_open</v-icon>
                    </span>
                </div>

                <div v-else class="d-inline-flex">
                    <span>
                        <v-icon v-bind:class="{ bol_hilhlight_selected_folder: isFolderSelected(node.id) }">notes</v-icon>
                    </span>
                </div>

                <editable-input 
                    :label="node.label" 
                    :readOnly="readOnly" 
                    @updateNodeLabel="changeNodeLabel"/>
                <span class="bol_code" v-if="isPart(node)"> 
                    <editable-input 
                        :label="node.code"
                        :readOnly="readOnly" 
                        :placeholder="$t('pao.structure.node.placeholder.code')"
                        @updateNodeLabel="changePaoNodeCode"/>
                </span>

                <v-tooltip v-if="documentOpenable && node.type === $t('pao.structure.node.TOC') "  top>
                    <v-icon color="primary" class="bol_open_document" small  @click="setDialogOpener(node.id)" slot="activator" >open_in_new</v-icon>
                    <span>{{ $t("pao.manage.action.openDocument") }}</span>
                </v-tooltip>
                <v-tooltip v-if="documentOpenable && node.type ===  TYPE.FILE"  top> 
                    <v-icon color="primary" class="bol_open_document" small  @click="downloadFile(node.id)" slot="activator" >cloud_download</v-icon>
                    <span>{{ $t("pao.manage.action.download") }}</span>
                </v-tooltip>
                
            </div>

            <!-- DISPLAY THE CHILDREN OF THE CURRENT NODE IF EXIST -->
            <div v-show="open">
                <div v-for="child in node.children" :key="child.id">
                    <pao-node :node="child" :documentOpenable="documentOpenable" :level="(level+1)" :readOnly="readOnly"/>
                </div>
                <div v-for="document in node.documentList" :key="document.id">
                    <v-layout pl-3>
                        <v-flex class="bol_document_pao" :id="`${document.id}`" @click="setDocumentNodeID(document, $event)" @contextmenu="setDocumentNodeID(document, $event)">
                            <span class="d-inline-flex">
                                <v-icon v-bind:class="{ bol_hilhlight_selected_folder: isFolderSelected(node.id) }">notes</v-icon>
                            </span>

                            <span>{{document.title}}</span>
                              
                            <v-tooltip v-if="documentOpenable" top>

                                <v-icon color="primary" class="bol_open_document" small slot="activator" @click="openDocument(document.id)">open_in_new</v-icon>
                              
                                <span>{{ $t("pao.manage.action.openDocument") }}</span>
                            </v-tooltip>
                            <v-tooltip v-if="document.detailedToc" top> 
                                <v-icon color="primary" class="bol_open_document" small slot="activator">format_align_left</v-icon>
                                <span>{{ $t("pao.document.tooltip.toc.detailed") }}</span>
                            </v-tooltip>
                        </v-flex>           
                    </v-layout>
                </div>
            </div>

        </v-flex>
    </v-layout>
</template>

<script>
import ConstantEvent                from '../../../shared/constant/constantEvent';
import ConstantPaoNode              from '../../../shared/constant/constantPaoNode';
import EditableInput                from '../../shared/EditableInput.vue';
import EventBus                     from '../../../utils/event-bus';
import PaoStructureStoreTypes       from '../../../shared/constant/store/module/paoStructureTypes';
import StoreModule                  from '../../../shared/constant/store/module/namespace';
import { mapActions, mapGetters }   from 'vuex';
import axios                        from 'axios';


/**
 * Pao Node component.
 *
 * @author Cédric de BOISVILLIERS, SFEIR benelux
 * @version 1.0
 * @since 09/07/2019
 */
export default {
    name: "PaoNode",
    props: {
        node: {
            required: true
        },
        readOnly: {
            default: false
        },
        documentOpenable:{
            default: false
        },
        level:{
            default:1
        }
    },
    components: {
        EditableInput
    },
    data(){
        return {
            open:  false,
            TYPE: ConstantPaoNode.TYPE
        }
    },
    computed:{
        ...mapGetters(StoreModule.PAO_STRUCTURE, {
            clickedNodeRootByDefault: PaoStructureStoreTypes.GETTER.DEFAULT_CLICKED_NODE
        })
    },
    beforeMount(){
      if(this.level === 1 || this.level === 2){
          this.open = true;
      }
    },
    methods:{
        ...mapActions(StoreModule.PAO_STRUCTURE, {
            updateNodeLabel: PaoStructureStoreTypes.ACTION.UPDATE_CLICKED_NODE_LABEL,
            updatePaoNodeCode: PaoStructureStoreTypes.ACTION.UPDATE_CLICKED_NODE_CODE,
            updateClickedLabel: PaoStructureStoreTypes.ACTION.UPDATE_CLICKED_NODE
        }),
        /**
         *  Retourne l'ID du document qui a été cliqué
         **/
        setDocumentNodeID(event){
            EventBus.$emit(ConstantEvent.CLICK_ON_DOCUMENT_NODE,event);
        },
        /**
         * Open or close a node in the tree to be able to see its children
         */
        toggleOpen() {
            this.open = !this.open;
        },
        /**
         * open dialog for toc with an id to load toc selected
         **/
        setDialogOpener(event){
            EventBus.$emit(ConstantEvent.OPEN_ID_TOC, event);
        },
        /**
         * Edit the label of a node.
         * @param {Event} event 
         */
        changeNodeLabel( label ) {
            this.updateNodeLabel( label );
        },
        /**
         * Edit the technical code of the selected node
         * @param {code}
         */
        changePaoNodeCode( code ) {
            this.updatePaoNodeCode( code );
        },
        /**
         * If the selected folder match one of the passed node the class is active.
         * @param idNode, id of the passed node
         */
        isFolderSelected( idNode ){
            return !!idNode && !!this.clickedNodeRootByDefault && this.clickedNodeRootByDefault.id=== idNode;
        },
        /**
         * Open a modale with the content of the document.
         * @param {Number} idPaoDocument, id of the clicked paoDocument.
         */
        openDocument( idPaoDocument ){
            EventBus.$emit(ConstantEvent.SEND_ID_DOCUMENT,  idPaoDocument);
        },
        /**
         * Download the static file attached to a PAO Node
         */
        downloadFile(paoNodeId){
            axios.get(
                '/pao/node/content/' + paoNodeId
            ).then((response) => {
                this.forceFileDownload(response);
            });
        },
        /**
         * Create an invisible component anchor to trigger the download and force the browser to launch
         * the process
         */
        forceFileDownload(response){
            let contentDisposition = response.headers['content-disposition'];
            let regex = /(filename=\\")(.*)(\\")/gm;
            let group = regex.exec(contentDisposition);
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', group[2]) //or any other extension
            document.body.appendChild(link)
            link.click()
        },
        isPart(node){
            return node.type === ConstantPaoNode.TYPE.PART;
        }
    },
    beforeDestroy() {
        this.updateClickedLabel(0);
    }
}
</script>
<style scoped>
.bol_hilhlight_selected_folder {
    color: #d39e4e;
}
.bol_structure_icon {
    display: flex;
    align-items: center;
}
.bol_structure_icon:hover {
    cursor:pointer;
    background:#EEEFEFEE;
}
.d-inline-flex{
    margin-right: 10px;
}
.bol_document_pao {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.bol_document_pao:hover {
    cursor:pointer;
    background:#EEEFEFEE;
}

.bol_open_document{
    margin: 5px 10px 5px 10px;

}
.bol_code{
    font-style: italic;
}
</style>