<template>
    <v-layout row wrap align-left>
        <v-flex>
            <v-container>
                <h1 class="bolded" v-if="$route.params.id == null">{{$t('administration.country.manage.add.title')}}</h1>
                <v-form ref="formCountry" class="form-style" v-model="valid" lazy-validation>
                    <v-layout column justify-center>
                        <v-flex md6>
                            <v-text-field 
                                v-model="country.label"
                                :label="$t('administration.country.label.name')"
                                :rules="labelRules"
                                required
                                clearable/>
                        </v-flex>
                        <v-flex md6>
                            <v-text-field 
                                v-model="country.code"
                                :label="$t('administration.country.label.abbreviation')"
                                :rules="abbreviationRules"
                                required
                                clearable
                                />
                        </v-flex>
                        <v-flex md6>
                            <v-text-field 
                                v-model="country.description"
                                :label="$t('administration.country.label.description')"
                                :rules="labelRules"
                                required
                                clearable/>
                        </v-flex>
                        <!-- BUTTONS -->
                        <v-flex xs3 justify-end>
                            <v-tooltip top class="align-action-end">
                                <v-btn color="#355B7F" small fab slot="activator" :to="{ name: 'CountrySearch' }">
                                    <v-icon color="white" >cancel</v-icon>
                                </v-btn>
                                <span>{{ $t("administration.country.manage.action.cancel") }}</span>
                            </v-tooltip>
                            <v-tooltip v-if="valid" top class="align-action-end">
                                <v-btn color="primary" small fab dark slot="activator" icon @click="manage(country)" >
                                    <v-icon>save</v-icon>
                                </v-btn>
                                <span>{{ $t("administration.country.manage.action.add") }}</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </v-form>     
            </v-container>
        </v-flex>
    </v-layout>
</template>

<script>
import axios            from 'axios';
import EventBus         from '../../../utils/event-bus.js';
import ConstantEvent    from '../../../shared/constant/constantEvent.js'
import ConstantNotification from '../../../shared/constant/constantNotification.js';

/**
 * Form to create or update a country
 * 
 * @author Ivan Sliepchenko, Legitech
 * @version 1.0
 * @since 19/06/2019
 */
export default {
    name: 'CountryManagement',
    data () {
        return {
            valid: false,
            country: {
                id: null,
                label: null,
                description: null,
                code: null
            },
            labelRules: [
                v => !!v || this.$t("administration.country.manage.required")
            ],
            abbreviationRules: [
                v => !!v || this.$t("administration.country.manage.required"),
                v => !!v && v.length < 6 || this.$t("administration.country.manage.too-long") + "5"
            ]
        }
    },
    mounted(){
        /**
         * When the page is charging, we pay attention if an id is passed,
         * if it's the case, it means that we update a country instead of creating one
         */
        if(this.$route.params.id !== undefined) {
            this.loadCountry(this.$route.params.id);
        }
    },
    methods:{
        /**
         *  Create or update a country
         * @param newCountry
         */
        manage(country) {
            if(this.$refs.formCountry.validate()){
                var method = null;
                if(!!this.$route.params.id) {
                    method = "PUT",
                    country.id = this.$route.params.id;
                } else {
                    method = "POST";
                }
               
                axios( {
                    method: method,
                    url: "administration/country",
                    data: country
                }).then( () => {
                    EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                            message: 'administration.country.manage.' +  (!this.$route.params.id ? 'add' : 'edit') +'.success',
                            type: 'SUCCEED'
                        }
                    );
                    this.$router.push('/administration/country');
                });
            }
        },
        /**
         * Retrieve a country with its id
         * @param id
         */
        loadCountry( id ) {
            axios.get(
                `administration/country/${id}`
            ).then( result => {
                this.country = result.data;
            });
        },
    }
}
</script>
<style>
.form-style {
    margin-top:50px;
}
.align-action-end{
    float: right;
}
.bolded{
    font-weight: bold;color: #355B7F;
}
</style>
