<template>
    <v-dialog v-model="openDialog" persistent max-width="700px">
        <v-card>
            <v-card-title class="headline">{{ $t(message) }}</v-card-title>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" flat @click.native="close();">{{$t('global.action.cancel')}}</v-btn>
                <v-btn color="primary" flat @click.native="confirm();">{{$t('global.action.confirm')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
const UPDATE_DIALOG = 'update:dialog';
/**
 * 
 * Open a dialog to confirm the action the user want to do 
 * 
 * @deprecated see BolDialogConfirmation
 * @author Justin WILMET
 * @since 10/10/2018
 * @version 1.0
 * 
 */
export default {
    name: 'DialogConfirmation',
    props: {
        message: String,
        dialog: {
            default: false
        }
    },
    computed: {
        openDialog: {
            get(){
                return this.dialog;
            },
            set( dialog ){
                if( !dialog ){
                    this.$emit(UPDATE_DIALOG, false);
                }
            }
        }
    },
    methods:{
        confirm(){
            this.$emit('confirm');
            this.close();
        },
        /**
         * Close the dialog and reset the form
         */
        close(){
            this.$emit(UPDATE_DIALOG, false);
        }
    }
}
</script>
<style scoped>
.headline{
    font-size: 24px;
    line-height: 32px;
    text-align: center;
}
</style>

