<template>
    <div class="container_filter_paos pt-5 px-4">
        <h2 class="title_color">{{$t('pao.label.search')}}</h2>
    
        <!-- FIRST LINE -->
        <v-flex xs12 >
            <v-text-field
                v-model="title"
                :label="$t('pao.label.title')"
                clearable
                @keyup.enter="search()"
                dark
            />
        </v-flex>
        <!-- SECOND LINE -->
        <v-flex xs12 >
            <v-select
                :items="statusList"
                item-text= "label"
                item-value="status"
                :label="$t('pao.label.status')"
                v-model="status"
                clearable
                :loading="displaySpinner"
                @keyup.enter="search()"
                dark
            />
        </v-flex>
        <!-- BUTTONS -->
        <v-flex xs48 sm24 md12 text-xs-right>
            <span  @click="search()" class="new_pao_btn">{{ $t('pao.action.search')}}</span>
            <v-btn fab dark small
                    color="white"
                    @click.native="search()">
                <v-icon color="primary" >search</v-icon>
            </v-btn>
        </v-flex>
        <v-flex v-if="!searchOnly" xs48 sm24 md12 text-xs-right>
            <span class="new_pao_btn">{{ $t('pao.action.newPao')}}</span>
            <v-btn fab small dark
                    color="white" 
                    :to="{name: 'PaoAdd'}">
                <v-icon color="primary" >add</v-icon>
            </v-btn>
        </v-flex>
        <v-flex v-else xs48 sm24 md12 text-xs-right>
            <span class="new_pao_btn">{{ $t('pao.action.cancel')}}</span>
            <v-btn fab small dark
                    color="white" 
                    @click="goBack">
                <v-icon color="primary" >clear</v-icon>
            </v-btn>
        </v-flex>
    </div>
</template>
<script>
import axios                        from 'axios'
import ConstantEvent                from '../../../shared/constant/constantEvent'
import { mapState, mapActions }     from 'vuex';
import StoreModule                  from '../../../shared/constant/store/module/namespace'
import PaoFilterStoreTypes          from '../../../shared/constant/store/module/paoFilterTypes'

/**
 * Pao filter component.
 * 
 * @author Cédric de BOISVILLIERS, SFEIR benelux
 * @version 1.0
 * @since 17/05/2019
 */
export default {
    name: "PaoFilter",
    props: {
        searchOnly: {
            default: false
        }
    },
    data(){
        return {
            displaySpinner: false,
            menu: false,
            statusList: []
        }
    },
    computed: {
        title: {
            get () {
                return this.$store.state.paoFilterStore.filter.title
            },
            set (value) {
                this.updateTitle(value);
            }
        },
        status: {
            get () {
                return this.$store.state.paoFilterStore.filter.status
            },
            set (value) {
                this.updateStatus(value);
            }
        },
    },
    mounted(){
        this.displaySpinner = true,
        axios(
            {
                method: "GET",
                url: "pao/search/init",
            }
        ).then( ({data}) => {
            this.displaySpinner = false;
            for(let stat of data.paoStatusList){
                this.statusList.push({
                    "status":stat,
                    "label":this.convertStatusLabel(stat)
                });
            }
        });
    },
    methods:{
        ...mapActions(StoreModule.PAO_FILTER, {
            updateTitle: PaoFilterStoreTypes.ACTION.UPDATE_TITLE,
            updateStatus: PaoFilterStoreTypes.ACTION.UPDATE_STATUS,
        }),
        search() {
            this.$emit('search');
        },
        goBack() {
            this.$router.go(-1);
        },
        convertStatusLabel(label){

            return this.$i18n.t('pao.status.label.'+label);

        }

    },
}
</script>
<style scoped>
    .container_filter_paos
    {
        height:100%;
        background-color:#355B7F;
    }
    .title_color
    {
        color: aliceblue;
    }
    .new_pao_btn{
        cursor:pointer;
        color:white;
        font-weight: bold;
    }
</style>