<template>
    <v-layout column wrap>      
        <v-layout row wrap justify-end class="pagination">
            <v-pagination 
                :length="pageable.totalPages" 
                :total-visible="6" 
                @input="changePage" 
                :value="pageable.page" 
                circle
                />
        </v-layout>
        <v-flex>
            <v-data-table
                :headers="[]"
                :items="documentList"
                hide-actions
                class="elevation-1">

               <template slot="headers" slot-scope="props">
                    <th>{{ $t('document.label.reference') }}</th>
                    <th>{{ $t('document.label.title') }}</th>
                    <th>{{ $t('document.label.type') }}</th>
                    <th>{{ $t('document.label.actions') }}</th>
                </template>

                <template slot="items" slot-scope="{item}" :active="item.linked">
                    <td :class="{isLinked: item.linked}">{{item.reference}}</td>
                    <td :class="{isLinked: item.linked}">{{item.title}}</td>
                    <td :class="{isLinked: item.linked}">{{item.documentType}}</td>
                    <td justify-space-around :class="{isLinked: item.linked}">
                        <span v-if="item.linked">{{ $t('document.documentModificator.isLinked') }}</span>
                        <v-icon v-else medium
                            @click="linkToDocument(item)">
                        link
                        </v-icon>
                    </td>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>

<script>
/**
 * 
 * List of document to link to the document to consolidate
 * 
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 19/02/2019
 */
export default {
    name: 'ModificatorDocumentList',
    props: ['documentList','pageable'],
    data(){
        return {
            dialog: false
        }
    },
    methods: {
        /**
         * Send to the parent the page index to display
         */
        changePage( page ){
            this.$emit('changePage', page);
        },
        /**
         * Send to the parent the document modificator to link to the current documents
         */
        linkToDocument( document ){
            this.$emit('linkToDocument', document);
        }
    }
}
</script>

<style scoped>
.pagination{
    margin: 15px;
}
.isLinked {
    background-color: #eeebeb;
}
</style>
