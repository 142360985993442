<template>
    <v-dialog v-model="openDialog" fullscreen>
        <v-card>
            <v-card-title class="headline">
                {{$t('structure.tfoots.title') }}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-layout row wrap>
                     <v-flex xs7>
                        <v-textarea v-model="tableFootnoteContent" :label="$t('structure.tfoots.typeFootTableContent')"/>
                    </v-flex>
                    <v-flex xs1>
                        <v-btn color="primary" fab dark small @click="addTableFootnote()" :disabled="!tableFootnoteContent">
                            <v-icon>add</v-icon>
                        </v-btn>
                    </v-flex>
                    <v-flex xs4>
                        <v-select
                            v-model="tableFootnoteStyle"
                            :label="$t('structure.tfoots.indexType')"
                            :items="tableFootnoteStyleList"
                            item-text="value"
                            item-value="key"
                            clearable>
                        </v-select>
                    </v-flex>
                </v-layout>
                <span class="headline">{{$t('structure.tfoots.toInsert')}}</span>
                <v-data-table
                    v-if="tableFootnoteList"
                    :headers="headers"
                    :items="tableFootnoteList"
                    disable-initial-sort
                    hide-actions
                >
                    <template slot="items" slot-scope="props">
                        <td>{{props.item.rank}}</td>
                        <td v-html="props.item.content"></td>
                        <td>
                            <v-layout justify-space-around>
                                <v-tooltip top> 
                                    <v-btn slot="activator" small flat icon @click="link(props.item)">
                                        <v-icon>
                                            link
                                        </v-icon>
                                    </v-btn>
                                    <span>{{$t('structure.tfoots.action.link')}}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <v-btn slot="activator" small flat icon @click="unlink(props.item)">
                                        <v-icon>
                                            link_off
                                        </v-icon>
                                    </v-btn>
                                    <span>{{$t('structure.tfoots.action.unlink')}}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <v-btn slot="activator" small flat icon @click="destroy(props.item)">
                                        <v-icon>
                                        delete_forever
                                        </v-icon>
                                    </v-btn>
                                    <span>{{$t('structure.tfoots.action.delete')}}</span>
                                </v-tooltip>
                            </v-layout>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn color="error" flat @click.native="close();">{{$t('document.action.cancel')}}</v-btn>
                    <v-btn color="primary" flat @click.native="apply();">{{$t('structure.edit.action.apply')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import DocumentTableFootnoteService from '../../../../service/document/documentTableFootnoteService.js';
import DomManipulatorService        from '../../../../service/html/domManipulatorService.js';
import ConstantEvent                from '../../../../shared/constant/constantEvent.js';
import ConstantDocumentNode         from '../../../../shared/constant/constantDocumentNode.js';
import ConstantNodeContentElement   from '../../../../shared/constant/constantNodeContentElement.js';
import EventBus                     from '../../../../utils/event-bus.js';

const UPDATE_DIALOG                 = 'update:dialog';
/**
 * Manage all the operation related to Table footnotes
 * 
 * @author Romain GENTILHOMME, Cédric de Boisvilliers
 * @since 23/04/2019
 * @version 2.0
 */

export default {
    name: 'DialogTableFootnote',
    props: {
        dialog: {
            default: false
        },
        position: {
            clickedNode: null, 
            clickedOffset: 0,
            htmlTableElement: null
        }
    },
    data(){
        return {
            tableFootnoteList: [],
            tableFootnoteContent: '',
            tableFootnoteStyle: null,
            headers:[
                { text: this.$t('structure.tfoots.tfoot.rank'), value: 'rank' },
                { text: this.$t('structure.tfoots.tfoot.content'), value: 'content' },
                { text: this.$t('structure.tfoots.tfoot.action'), value: 'action', sortable: false, width: '200px', align: 'right' },
            ],
            tableElement: null
        }
    },
    computed:{
        openDialog: {
            get(){
                if( this.dialog ){
                    this.$nextTick(function(){
                        this.fillTableFootnoteList();
                    })
                }
                return this.dialog;
            },
            set( dialog ){
                if( !dialog ){
                    this.$emit(UPDATE_DIALOG, false);
                }
            }
        },
        tableFootnoteStyleList(){
            return [ 
                {key: 1, value: '1, 2, 3, ...' },
                {key: 2, value: '1., 2., 3., ...' },
                {key: 3, value: '(1), (2), (3), ...' },
                {key: 4, value: '*, **, ***, ...' },
                {key: 5, value: '(*), (**), (***), ...'} 
            ];
        }
    },
    methods:{
        /**
         * Close the dialog
         */
        close(){
            this.$emit(UPDATE_DIALOG, false);
        },
        /**
         * Set up the table footnote list and its style
         */
        fillTableFootnoteList() {
            this.tableFootnoteList = DocumentTableFootnoteService.extractTableFootnoteListFromHTMLElement(this.position.htmlTableElement);
            if(this.tableFootnoteList.length >= 1){
                let style = this.$extractFootNoteStyle();
                this.tableFootnoteStyle = DocumentTableFootnoteService.setTableFootnoteStyle( style );
            }else {
                this.tableFootnoteStyle = 1;
            }
        },
    
        $extractFootNoteStyle(){
            let style = '0'
            this.tableFootnoteList.forEach( tableFootnote => { 
                if( this.tableFootnoteList.rank !== '0') return style = tableFootnote.rank;
            });
            return style;
        },
        /**
         * Add a table footnote to the list
         */
        addTableFootnote(){
            let tableFootnote = DocumentTableFootnoteService.createTableFootnote(this.tableFootnoteContent);
            this.tableFootnoteList.push(tableFootnote);
            this.tableFootnoteContent = '';
        },
        /**
         * Apply the modification and update the Table footnote list
         */
        apply(){
            DocumentTableFootnoteService.updateTableFootnoteList(this.tableFootnoteList, this.tableFootnoteStyle, this.tableElement);
            this.saveTableFootNote(this.tableElement.outerHTML);
        },
        /**
         * Link the modification and update the Table footnote list
         */
        link(tableFootnote){
            let link = DocumentTableFootnoteService.createTableFootnoteLink(tableFootnote).outerHTML;
            let tableElement = DomManipulatorService.cloneHTMLElement(this.position.htmlTableElement);
            tableElement.innerHTML = DomManipulatorService.insertHtmlInTextNode(this.position.clickedNode, this.position.clickedOffset, link, ConstantNodeContentElement.EDITABLE_TABLE_CLASS);
            this.tableElement = tableElement;
            this.apply();
        },
        /**
         * Save the table with the footnotes updated
         */
        saveTableFootNote(htmlTable) {
            this.$emit('update-table', htmlTable);
            if(!!htmlTable){
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    message: 'document.notification.tableFootnote.linked',
                    type: 'INFO'
                });
            }
            this.tableElement = null;
            this.close();
        },
        /**
         * Unlink the tableFootnote of all its occurences in the document but keep it in the list of tableFootnote
         */
        unlink(tableFootnote){
            let tableElement = DomManipulatorService.cloneHTMLElement(this.position.htmlTableElement);
            DocumentTableFootnoteService.unlinkTableFootnote(this.tableFootnoteList, tableFootnote.id, this.tableFootnoteStyle, tableElement);
            this.tableElement = tableElement;
        },
        /**
         * Destroy the table Footnote and its occurence in the document
         */
        destroy(tableFootnote){
            this.tableElement = DomManipulatorService.cloneHTMLElement(this.position.htmlTableElement);
            DocumentTableFootnoteService.deleteTableFootnote(this.tableFootnoteList, tableFootnote.id, this.tableFootnoteStyle, this.tableElement);
        }
    }
}
</script>

