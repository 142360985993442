<template>
    <div>
        <div v-for="node in rootNode" :key="node.id" :class="node.type" >
            <span :id="node.id" class="bol-id-node">
            <span v-if="isStructureVisible"  :class="{'bol-node-type': isStructureVisible}">
                {{node.label}}</span>
            <span class="bol-text-node" v-if="node.type === 'TEXT'" v-html="node.content" ></span>
           </span>

            <overview-document-node v-if="!!node.children" :rootNode="node.children" :isStructureVisible="isStructureVisible"/>
            
        </div>
    </div>
</template>
<script>
/**
 * Display the overview of the document
 * 
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 12/10/2018
 */
export default {
    name: 'OverviewDocumentNode',
    props: ['rootNode', 'isStructureVisible']
}
</script>
<style scoped>
    .bol-node-type {
        color: darkgray;
        margin-left: 15px;
        font-size: smaller;
    }
</style>
