import Types from '../../shared/constant/store/module/paoFilterTypes';

/**
 * Pao filter store
 * @author Cédric de BOISVILLIERS, SFEIR Benelux
 * @version 1.0
 * @since 22/05/2019
 */

export default {
    namespaced: true,
    state: {
        filter: {
            status: null,
            title: '',
        },
        pageable: {
            page: 1,
            size: 12, 
            direction: 'ASC',
            property: "id",
            totalElements: 0,
            totalPages: 0
        }
    },
    mutations: {
        [Types.MUTATION.SET_FILTER_TITLE]: (state, title) => {
            state.filter.title = title;
        },
        [Types.MUTATION.SET_FILTER_STATUS]: (state, status) => {
            state.filter.status = status;
        },
        [Types.MUTATION.SET_PAGE]: (state, page) => {
            state.pageable.page = page;
        },
        [Types.MUTATION.SET_PAGEABLE]: (state, pageable) => {
            state.pageable = {...state.pageable, ...pageable};
        }
    },
    actions: {
        [Types.ACTION.UPDATE_TITLE]: ({ commit }, title ) => {
            commit(Types.MUTATION.SET_FILTER_TITLE, title);
        },
        [Types.ACTION.UPDATE_STATUS]: ({ commit }, status) => {
            commit(Types.MUTATION.SET_FILTER_STATUS, status);
        },
        [Types.ACTION.CHANGE_PAGE]: ({commit}, page) => {
            commit(Types.MUTATION.SET_PAGE, page);
        },
        [Types.ACTION.UPDATE_PAGEABLE]: ({commit}, pageable) => {
            commit(Types.MUTATION.SET_PAGEABLE, pageable);
        }
    }
}