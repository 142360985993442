<!--mce-tinymce visibility: inherit !important-->
<template>
    <v-dialog
        v-model="openDialog"
    >
        <v-card>
            <v-card-title class="headline">
                {{ $t('structure.edit.node.title') }}
            </v-card-title>
            <v-divider />
            <v-card-text>
                <textarea
                    :id="tinySelectorId"
                    class="editor_tinymce"
                />
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="error"
                    flat
                    @click.native="close();"
                >
                    {{ $t('document.action.cancel') }}
                </v-btn>
                <v-btn
                    color="primary"
                    flat
                    @click.native="editNode();"
                >
                    {{ $t('structure.edit.action.apply') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ConstantEvent            from '../../../../shared/constant/constantEvent.js';
import DomManipulatorService    from '../../../../service/html/domManipulatorService.js';
import Editor                   from '@tinymce/tinymce-vue';
import EventBus                 from '../../../../utils/event-bus.js';
import tinymce                  from 'tinymce/tinymce';
import { mapState, mapGetters } from 'vuex';
import 'tinymce/themes/modern/theme';
import 'tinymce/plugins/autolink/plugin';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/colorpicker/plugin';
import 'tinymce/plugins/noneditable/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/preview/plugin';
import 'tinymce/plugins/textcolor/plugin';
import 'tinymce/plugins/textpattern/plugin';
import 'tinymce/plugins/table/plugin';
import 'tinymce/plugins/paste/plugin';
import 'tinymce/plugins/autoresize/plugin';
import ConstantQuotation from '../../../../shared/constant/constantQuotation.js';
import ConstantTag from '../../../../shared/constant/constantTag.js';

const UPDATE_DIALOG                     = 'update:dialog';
const EDITOR_TINYMCE                    = 'editor_tinymce';
const NON_PERSISTENT_ATTRIBUTE_REGEX    = /\s+\S+="bol-np[^\s]+?"/gim;
const CONTENT_WRAPPER_ID_PREFIX         = 'bol-np-wrapper-';
/**
 *
 * Rich text editor to edit a content node.
 * Use TinyMCE library.
 *
 * @author Sébastien DE SANTIS, SFEIR Benelux
 * @version 1.0
 * @since 19/10/2018
 *
 */
export default {
    name: "DialogContentEditor",
    components: {
        'editor': Editor
    },
    props: {
        dialog: {
            default: false
        },
        storeName: {
            default: null
        }
    },
    data(){
        return {
            content:null,
            footnoteList: [],
            init: {
                selector: `#${EDITOR_TINYMCE}`,
                plugins: ['autolink code colorpicker lists noneditable preview textcolor textpattern table paste advlist autoresize'],
                toolbar1: 'bold italic underline strikethrough | forecolor backcolor | blockquote code | subscript superscript | alignleft aligncenter alignright alignjustify | numlist bullist',
                toolbar2: 'table | outdent indent | preview | removeformat',
                valid_elements : '*[*]',
                autoresize_bottom_margin : 0,
                autoresize_min_height: 300,
                autoresize_max_height: 600,
                menubar: false,
                skin_url: 'skins/lightgray',
                table_default_attributes: {
                    class: 'bol-editable-table'
                },
                force_br_newlines: true,
                paste_postprocess : function(plugin, args) {
                    // When we copy/paste from consolidation, Tinymce does not read html as proper html but as plain text
                    // We then need to overwrite its innerHTML
                    var html = args.node.innerText;
                    args.node.innerHTML = html;
                },
                invalid_styles:{
                    'table': 'width height',
                    'tr': 'width height',
                    'th': 'width height',
                    'td': 'width height'
                }
            }
        }
    },
    computed: {
        node(){
            return this.$store.state[`${this.storeName}`].selectedNode;
        },
        hasAdditionalNodesSelected(){
            return this.$store.state[`${this.storeName}`].additionalSelectedNodeList.length > 0;
        },
        allSelectedNodes(){
            return this.$store.getters[`${this.storeName}/allSelectedNodes`]
        },
        openDialog: {
            get(){
                if( this.dialog ){
                    this.$nextTick().then(() => {
                        this.listener();
                    })
                }
                return this.dialog;
            },
            set( dialog ){
                if( !dialog ){
                    this.$emit(UPDATE_DIALOG, false);
                }
            }
        },
        tinySelectorId(){
            return `${EDITOR_TINYMCE}_${this.storeName}`;
        }
    },
    beforeDestroy(){
        for (var i = tinymce.editors.length - 1 ; i > -1 ; i--) {
            var ed_id = tinymce.editors[i].id;
            tinyMCE.execCommand("mceRemoveEditor", true, ed_id);
        }
    },
    mounted(){
        this.$nextTick().then(() => {
            tinymce.init({ ...this.init, ...{ selector: `#${this.tinySelectorId}` } })
        })
    },
    methods: {
        listener(){
            if(!this.hasAdditionalNodesSelected) {
                tinymce.get(this.tinySelectorId).setContent(this.node.content);
            } else {
                tinymce.get(this.tinySelectorId).setContent(this.$wrapNodesContent());
            }
        },
        /**
         * Close dialog window without applying the changes
         */
        close: function(){
            this.openDialog = false;
        },
        /**
         * Close the dialog and apply the changes.
         */
        editNode: function(){
            let content = tinyMCE.get(this.tinySelectorId).getContent().replace(/\n|\r/gm, '');
            if (!this.hasAdditionalNodesSelected) {
                let updatedContent = this.$addClassOnInlineQt(content);
                this.$store.dispatch(`${this.storeName}/setSelectedNodeContent`, updatedContent);
            } else {
                let unWrappedNodeList = this.$unWrapNodesContent(content);
                this.$store.dispatch(`${this.storeName}/setAllSelectedNodeContent`, unWrappedNodeList);
            }
            this.close();
        },
        /**
         * Wraps each node content in an html container with an id related to the node id
         */
        $wrapNodesContent() {
            return this.allSelectedNodes.map( selectedNode => {
                let nodeWrapper = document.createElement("div");
                nodeWrapper.id = `${CONTENT_WRAPPER_ID_PREFIX}${selectedNode.id}`;
                nodeWrapper.innerHTML = selectedNode.content;
                return nodeWrapper.outerHTML;
            }).join('');
        },
        /**
         * Unwraps the nodes content to a lightweight node with only its id and content
         * @param {*} content
         */
        $unWrapNodesContent(content) {
            let wrappedNodeContentList = DomManipulatorService.extractNodesFromHTML(content);
            return wrappedNodeContentList.map(wrappedNodeContent =>  ({
                id: wrappedNodeContent.id.replace(CONTENT_WRAPPER_ID_PREFIX, ''),
                content: wrappedNodeContent.innerHTML.replace(NON_PERSISTENT_ATTRIBUTE_REGEX, '')
            })
            );
        },
        $addClassOnInlineQt( nodeContent ){
            let inlineBlockquote = nodeContent.split(new RegExp( /(<blockquote[^>]*>)/gm ));
            let updatedContent = '';
            for(let index = 0; index < inlineBlockquote.length ; index++){
                let matchBlockquote = inlineBlockquote[index].match( new RegExp( /<blockquote[^>]*>/gm ));
                if( !!matchBlockquote ){
                    if( !matchBlockquote[0].includes(ConstantQuotation.QT_STRUCTURAL_CLASS) && !matchBlockquote[0].includes(ConstantQuotation.MCE_NON_EDITABLE)){
                        let blockquote = document.createElement(ConstantTag.BLOCKQUOTE);
                        blockquote.className = ConstantQuotation.QT_INLINE_CLASS;
                        let newInlineBlockquote = blockquote.outerHTML;
                        updatedContent += newInlineBlockquote.substring(0 , newInlineBlockquote.length - 13);
                    } else {
                        updatedContent += inlineBlockquote[index];
                    }
                } else {
                    updatedContent += inlineBlockquote[index];
                }
            }
            return updatedContent;
        }
    }
}
</script>

<style>
</style>
