<template>
    <div class="bol-autocomplete">
        <v-autocomplete
            :value="value"
            :items="reworkedList"
            :label="label"
            :rules="rules"
            :multiple="multiple"
            :no-data-text="`${isListEmpty ? $t('document.create.noResult') : $t('document.create.noMatchingResult') }`"
            item-value="id"
            item-text="label"
            required      
            clearable    
            :disabled="disabled"
            :chips="chips"
            :deletable-chips="chips"
            :return-object="returnObject"
            @change="sendModel($event)"
        />
        <span class="bol-hint-quantity">({{ reworkedList.length }})</span>
    </div>
</template>
<script>
/**
 * An autocomplete component for all items list in the application
 */
export default {
    name: 'AutocompleteSelect',
    props: {
        items: {
            default: []
        },
        label: {
            default: ''
        },
        rule: {
            default: null
        },
        disabled: {
            default: false
        },
        multiple: {
            default: false
        },
        chips: {
            default: false
        },
        value: {
            default: null
        },
        returnObject: {
            default: false
        },
        isDomainSelect: {
            default: false
        }
    },
    data(){
        return {
            model: null
        }
    },
    computed: {
        /**
         * Not all autocomplete component will have rules. If there is rule return the default value of a rule
         * /!\ Generate a warning if the default value is directly in props
         */
        rules(){
            if( !!this.rule ){
                return this.rule;
            }else {
                return [];
            }
        },
        /**
         * Allow a display of a different 'empty message'.
         */
        isListEmpty(){
            return !this.items || this.items.length === 0;
        },
        /**
         * Not all list have the same format, this method recreate an 'unique' format
         * to allow the autocomplete to display every list in the same way.
         */
        reworkedList(){
            if( !!this.items && this.items.length > 0 ){
                let reworkedList = [];
                this.items.forEach( item => {
                    let reworkedItem = this.reworkItem( item );
                    reworkedList.push( reworkedItem );
                });
                return reworkedList;
            } else {
                return [];
            }
        }
    },
    methods: {
        /**
         * Return the field(s) selected in the autocomplete to the parent.
         */
        sendModel( fieldValue ) {
            this.$emit('onBindModel', fieldValue);
        },
        /**
         * Recreate an item of a list
         */
        reworkItem( item ) {
            let reworkedItem = {};
            if( typeof item === 'object'){
                if( this.returnObject ){
                    reworkedItem = item;
                    reworkedItem.label = `${item.code} - ${item.label}`
                } else {
                    reworkedItem.id = item.uid || item.id;
                    if( item.hasOwnProperty('name') ){
                        reworkedItem.label = item.name;
                    } else if ( item.hasOwnProperty('label') ){
                        reworkedItem.label = item.label;
                    }
                }                        
            } else {
                reworkedItem.id = item;
                reworkedItem.label = item;
            }
            return reworkedItem;
        }
    }
}
</script>
<style scoped>
.bol-autocomplete{
    display:flex;
}
.bol-hint-quantity{
    font-style: italic;
    color: rgba(0,0,0,.38);
    display: flex;
    align-items: center;
    padding-left: 10px;
}
</style>
