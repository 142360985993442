<template>
    <v-layout>
        <v-flex>
            <v-container>
                <v-stepper v-model="stepperStatus">
                    <v-stepper-header>
                        <v-stepper-step :complete="stepperStatus > 1" editable step="1">
                            <span v-if="$route.params.id == null">{{$t('document.label.createDocument')}}</span>
                            <span v-else>{{$t('document.label.editDocument')}}</span>
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2">{{$t('document.label.completeDocument')}}</v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <datasheet-form-step-one 
                                :idDocument="idDocument" 
                                :datasheet="datasheet" 
                                @nextStep="nextStep"
                                @setField="setField"/>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <datasheet-form-step-two 
                                v-if="stepperStatus === 2" 
                                :datasheet="datasheet" 
                                :idDocument="idDocument"
                                @setField="setField"
                                @setAdditionalField="setAdditionalField"/>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-container>
        </v-flex>
    </v-layout>
</template>
<script>
import axios from 'axios';
import {documentLockMixin} from '../../../mixins/documentLockMixin';
import DatasheetFormStepOne from './stepper/DatasheetFormStepOne';
import DatasheetFormStepTwo from './stepper/DatasheetFormStepTwo';
import ConstantDatasheetField from '../../../shared/constant/constantDatasheetField';
import DataSheetService from '../../../service/document/dataSheetService';

/**
 * Create or Edit a Document
 * 
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 25/09/2018
 */
export default {
    name: 'DatasheetManagement',
    mixins: [documentLockMixin],
    data(){
        return {
            stepperStatus: 1,
            idDocument: null,
            datasheet: {}
        }
    },
    created(){
        if(!!this.$route.params.id) {
            this.idDocument = parseInt(this.$route.params.id);
            axios.get(
                `/datasheet/read/${this.idDocument}`
            ).then((response) => {
                this.datasheet = DataSheetService.mergeDataSheet(response.data);
            })
        } else {
            this.datasheet = DataSheetService.createDataSheet();
        }
    },
    methods: {
        setField(data){
            if(data.name === ConstantDatasheetField.FIELD.PARTNER_ID){
                this.datasheet.partner.id = data.value;
            } else {
                this.datasheet[data.name] = data.value;
            }
        },
        /**
         * Set value of an additional field.
         */
        setAdditionalField(data){
            if(data.name === ConstantDatasheetField.FIELD.TITLE){
                this.datasheet.title = data.value;
            } else if(data.name === ConstantDatasheetField.ADDITIONAL_FIELD.DOMAIN){
                this.datasheet.basicAdditionalField.domain.domainsId = data.domainsId;
            } else if(data.type === 'date'){
                this.datasheet.basicAdditionalField[data.name].valueDate = data.valueDate;
            } else {
                this.datasheet.basicAdditionalField[data.name].value = data.value;
            }
        },
        nextStep(){
            this.stepperStatus = 2;
        }
    },
    components: {
        DatasheetFormStepOne,
        DatasheetFormStepTwo
    }
}
</script>
