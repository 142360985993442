<template>
    <v-dialog v-model="openDialog" persistent fullscreen>
        <v-card class="margins_content_tree">
            <v-layout v-if="spinner">
                <v-progress-circular
                    :size="50"
                    :indeterminate="true"
                    color="primary"
                    class="spinner_doc">
                </v-progress-circular>
            </v-layout>
            <v-layout align-space-start justify-start row fill-height>

            <v-flex class="left-container" >
                <edition-action-panel
                    class="left-items"
                    :is-placed="{right:true}"
                    @save="saveDocument" 
                    @cancel="close" 
                    @action="doAction"
                    :actionList="actionList"
                    ></edition-action-panel>
            </v-flex>

            <v-flex class="right-container">
                <v-layout column justify-start align-start >
                    <v-flex id="#container-event">
                        <div id="container-light"
                            class="container-class"
                            @click="clickOnNode"
                            @contextmenu="showContextMenu">
                            <content-node class="node-content" :node="document"></content-node>
                        </div>
                    </v-flex>
                </v-layout>

                <menu-title-node v-if="!!clickedNodeDocument" :menu.sync="isTitleNodeClicked" :position="position" :storeName="storeName"></menu-title-node>
            </v-flex>
            </v-layout>
            <dialog-confirmation :dialog.sync="confirmDialogOpen" :message="message" @confirm="isConfirm" />
        </v-card>
    </v-dialog>
</template>
<script>
import axios                    from 'axios';
import ConstantDocumentNode     from '../../../shared/constant/constantDocumentNode.js';
import ConstantEvent            from '../../../shared/constant/constantEvent';
import ConstantNotification     from '../../../shared/constant/constantNotification';
import ConstantTag              from '../../../shared/constant/constantTag';
import ConstantAction           from '../../../shared/constant/constantAction';
import ContentNode              from '../../document/structure/displayer/ContentNode.vue';
import DocumentTreeService      from '../../../service/document/documentTreeService.js';
import DialogConfirmation       from '../../shared/DialogConfirmation';
import EditionActionPanel       from '../../shared/EditionActionPanel.vue';
import EventBus                 from '../../../utils/event-bus.js';
import MenuTitleNode            from '../../pao/document/menu/menuTitleNode.vue';
import PaoStructureStoreTypes   from '../../../shared/constant/store/module/paoStructureTypes';

const UPDATE_DIALOG     = 'update:dialog';
const UPDATE_REFERENCE_LINKS = 'update-reference-links'
/**
 * This component is a dialog to display a light node content tree.
 * 
 * @author Justin WILMET, Sfeir Benelux
 * @version 1.0
 * @since 17/10/2019
 */
export default {
    name: 'DialogNodeContentTreeLight',
    components: { ContentNode, EditionActionPanel, MenuTitleNode, DialogConfirmation },
    props: {
        dialog: { 
            default: false
        },
        storeName: {
            default: null
        },
        idPaoDocument: {
            default: null
        }
    },
    data: () => ({
        selectedNode: null,
        isTitleNodeClicked: false,
        position: { },
        actionList: [{
            method: ConstantAction.UPDATE, 
            icon:   ConstantAction.UPDATE,
            label: ConstantAction.UPDATE
        }, {
            method:  UPDATE_REFERENCE_LINKS,
            icon:   ConstantAction.UPDATE,
            label: UPDATE_REFERENCE_LINKS
        }],
        confirmDialogOpen: false,
        message: 'pao.message.confirm-update-pao-document' ,
        spinner: false,
        currentAction: null
    }),
    computed: {
        openDialog: {
            get(){
                return this.dialog;
            },
            set( dialog ){
                if( !dialog ){
                    this.$emit(UPDATE_DIALOG, false);
                }
            }
        },
        clickedNodeDocument(){
            return this.$store.state[`${this.storeName}`].selectedNode;
        },
        document(){
            return this.$store.state[`${this.storeName}`].rootNode;
        }
    },
    methods: {
        /**
         * Action of the pao structure store to set the selected node of the document.
         */
        selectNodeDocument( node ){
            this.$store.dispatch(`${this.storeName}/selectNode`, node );
        },
        /**
         * Close the dialog.
         */
        close(){
            this.$emit(UPDATE_DIALOG, false);
        },
        /**
         * Save the snapshot of the pao document.
         */
        saveDocument(){
            axios.put("/pao/document/content",{ documentId: this.idPaoDocument, content: this.document })
                .then(response => {
                    const notification = {
                        type: ConstantNotification.SUCCEED,
                        message: 'document.notification.content.save.succeed',
                    };
                    EventBus.$emit( ConstantEvent.ADD_NOTIFICATION, notification );
                });
        },
        /**
         * Select the clicked node.
         * @param {Event} event
         */
        clickOnNode( event ){
            let node = this.findClickedNode( event );
            if( !!node ){
                this.selectNodeDocument( node );
            } 
        },
        /**
         * Manage the actions sent from the component EditionActionPanel
         */
        doAction(event){
            if(event===ConstantAction.UPDATE){
                this.$openConfirmDialog('pao.message.confirm-update-pao-document', event);
            } else if (event===UPDATE_REFERENCE_LINKS){
                this.$openConfirmDialog('pao.message.confirm-update-reference-links-pao-document', event);
            }
        },
        /**
         * Open a menu at the position of the mouse if the condition are respected.
         */
        showContextMenu( event ){
            event.preventDefault();
            this.position.x = event.clientX;
            this.position.y = event.clientY;
            this.clickOnNode( event );
            this.$checkMenuOptions( event );
        },
        /**
         * Check the origin of the click and open the corresponding menu.
         */
        $checkMenuOptions( event ){
            this.isTitleNodeClicked = false;
            if( this.clickedNodeDocument.label !== ConstantTag.TEXT ){
                this.isTitleNodeClicked = true;
            }
        },
        /**
         * Find if the clicked element is present in the document.
         * @param {Event} event
         */
        findClickedNode( event ){
            const contentNode = event.composedPath().find(htmlElement => {
                return !!htmlElement.className && htmlElement.className.includes(ConstantDocumentNode.NODE_CONTENT_CLASS);
            });
            let clickedNode;
            if(!!contentNode) {
                let nodeId = contentNode.id.substring(ConstantDocumentNode.NODE_CONTENT_ID_PREFIX.length);
                clickedNode = DocumentTreeService.findNodeById(this.document, nodeId);
            }
            return clickedNode;
        },
        /**
         * update the pao document with the id in argument.
         * Then update the store with the new document content.
         */
        isConfirm(event){
            this.spinner = true;
            if(this.currentAction === ConstantAction.UPDATE){
                this.update();
            } else if( this.currentAction === UPDATE_REFERENCE_LINKS){
                this.updateReferenceLinks();
            }
            this.currentAction = null;
        },
        /**
         * update the reference links of the document by adding all the reference links of the older version of the document to this document.
         */
        updateReferenceLinks(){
            this.spinner = false;
            axios.patch(
                `/pao/document/${this.idPaoDocument}/update-reference-links`
            ).then((data) => {
                 const notification = {
                    type: ConstantNotification.INFO,
                    message: 'pao.notification.document-reference-links-updated.success',
                };
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
                this.$store.dispatch(`${this.storeName}/setRootNode`, data.data.content);
                this.spinner = false;
            }).catch(() => {
                 const notification = {
                    type: ConstantNotification.ERROR,
                    message: 'pao.notification.document-reference-links-updated.error',
                };
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
            });
        }, 
        /**
         * Update content of the document
         */
        update(){
            axios.put(
                '/pao/document/update',
                { documentId: this.idPaoDocument, content: this.document }
            ).then((data) => {
                 const notification = {
                    type: ConstantNotification.INFO,
                    message: 'pao.notification.document-content-updated.in-progress',
                };
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
                this.$store.dispatch(`${this.storeName}/setRootNode`, data.data.content);
                this.spinner = false;
                this.close();
            }).catch(() => {
                 const notification = {
                    type: ConstantNotification.ERROR,
                    message: 'pao.notification.document-content-updated.error',
                };
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
            });
        },
        /**
         * Open the confirm dialog with the needed translated message code
         */
        $openConfirmDialog(messageCode, currentAction){
            this.confirmDialogOpen = true;
            this.currentAction = currentAction;
            this.message = messageCode;
        }
    }
}
</script>

<style scoped>
.margins_content_tree{
    margin-top: 40px !important;
    padding:0 !important;
}
.left-container{
    height:100vh;
    min-width:350px;
    background-color: #355B7F;
}
.right-container{
    padding-top:50px;
    height:90%;
    width:100%;
}
.container-class{
    height:calc(100vh - 40px);
    padding-bottom:20px;
    overflow-y:auto;
    text-align: left;
    margin-left: 50px;
    margin-right: 50px;
}
.left-items{
    margin-top:40px;
}
</style>