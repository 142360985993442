
<template>
    <v-layout justify-start row >
        <v-flex class="title-container">
            <structural-node-tree :isModificator="true"></structural-node-tree>
        </v-flex>
        <v-flex >
            <v-layout column align-end>
                <span class="tooltips">
                    <v-tooltip top>
                        <v-btn color="primary" class="btn-save" small fab dark slot="activator"  @click="confirm(VALIDATE)" >
                            <v-icon >done</v-icon>
                        </v-btn>
                        <span>{{ $t('document.documentModificator.validateModifications') }}</span>
                    </v-tooltip>
                     <v-tooltip top>
                        <v-btn color="primary" class="btn-save" small fab dark slot="activator"  @click="confirm(REMOVE)" >
                            <v-icon >link_off</v-icon>
                        </v-btn>
                        <span>{{ $t('document.documentModificator.removeDocumentModifier') }}</span>
                    </v-tooltip>
                </span>
            </v-layout>
                    <div id="modificator"
                        @copy="onCopy"
                        @click="clickOnNode"
                        @contextmenu="showContextMenu"
                        class="document-modificator">
                        <h2 class="title-consolidation">
                            {{metaDocument.title}}
                        </h2>
                        <content-node :storeName="documentContentStoreName" :node="rootNode"></content-node>
                    </div>
            <notification-container/>
            <dialog-confirmation :dialog.sync="openConfirmation" :message="message" @confirm="isConfirm"></dialog-confirmation>
        <v-menu
            v-model="menuOpen"
            :position-x="menuXAxis"
            :position-y="menuYAxis"
            absolute
            offset-y>
            <v-list>
                <v-list-tile v-if="isNodeMenu && additionalSelectedNodeList.length == 0 && !selectedBlockquote"
                        @click="copyNode">
                    <v-list-tile-title>{{ $t('structure.node.action.copy') }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile v-if="isNodeMenu && additionalSelectedNodeList.length > 0 && !selectedBlockquote"
                        @click="copySiblingsNode">
                    <v-list-tile-title>{{ $t('structure.node.action.copySiblingsNode') }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile v-if="isNodeMenu && !!selectedBlockquote && additionalSelectedBlockquoteList.length === 0"
                        @click="copyBlockquote">
                    <v-list-tile-title>{{ $t('structure.node.action.copyBlockquote') }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile v-if="isNodeMenu && !!selectedBlockquote && additionalSelectedBlockquoteList.length > 0"
                        @click="copySiblingsBlockquote">
                    <v-list-tile-title>{{ $t('structure.node.action.copySiblingsBlockquote') }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile v-if="isNodeMenu && !!selectedBlockquote"
                        @click="copyContent">
                    <v-list-tile-title>{{ $t('structure.node.action.copyContent') }}</v-list-tile-title>
                </v-list-tile>
            </v-list>
        </v-menu>
        </v-flex>
    </v-layout>
</template>
<script>
import Vue                                  from 'vue';
import axios                                from 'axios';
import { mapState, mapActions, mapGetters } from 'vuex';
import ContentNode                          from '../structure/displayer/ContentNode';
import StructuralNodeTree                   from '../structure/displayer/StructuralNodeTree';
import EventBus                             from '../../../utils/event-bus';
import DocumentTreeService                  from '../../../service/document/documentTreeService.js';
import ConstantAction                       from '../../../shared/constant/constantAction.js';
import ConstantEvent                        from '../../../shared/constant/constantEvent';
import ConstantNotification                 from '../../../shared/constant/constantNotification';
import ConstantDocumentNode                 from '../../../shared/constant/constantDocumentNode.js';
import ConstantConsolidation                from '../../../shared/constant/constantConsolidation.js';
import ConstantStoreName                    from '../../../shared/constant/constantStoreName.js';
import ConstantTag                          from '../../../shared/constant/constantTag';
import ConstantQuotation                    from '../../../shared/constant/constantQuotation';
import { nodeContentMixin }                 from '../../../mixins/nodeContentMixin';
import { documentLockMixin }                from '../../../mixins/documentLockMixin';
import NotificationContainer                from '../../shared/notification/NotificationContainer';
import DialogConfirmation                   from '../../shared/DialogConfirmation.vue'
import domManipulatorService                from '../../../service/html/domManipulatorService';
import CheckDocumentNodeSelectionService    from '../../../service/document/checkDocumentNodeSelectionService.js';
import checkDocumentNodeSelectionService from '../../../service/document/checkDocumentNodeSelectionService.js';

/**
 * Display a document modificator
 *
 * @author de BOISVILLIERS Cédric, SFEIR Benelux
 * @version 1.0
 * @since 18/02/2019
 */
export default {
    name: 'DocumentModificator',
    mixins: [nodeContentMixin],
    components: {
        ContentNode,
        StructuralNodeTree,
        NotificationContainer,
        DialogConfirmation
    },
    data: () => ({
        modificatorId: 0,
        documentId: 0,
        consolidationChannel: null,
        menuOpen: false,
        menuXAxis: 0,
        menuYAxis: 0,
        node: null,
        isNodeMenu: false,
        selectedBlockquote: null,
        message: '',
        action: null,
        openConfirmation: false,
        additionalSelectedBlockquoteList: [],
        allSelectedBlockquoteList:[]
    }),
    computed: {
        ...mapState({
            additionalSelectedNodeList: state => state.documentContent.additionalSelectedNodeList,
            rootNode: state => state.documentContent.rootNode,
            selectedNode: state => state.documentContent.selectedNode,
            metaDocument: state => state.dataSheetStore.metaDocument,
        }),
        ...mapGetters('documentContent', [
            'allSelectedNodes'
        ]),
        documentContentStoreName() {
            return ConstantStoreName.DOCUMENT_CONTENT;
        },
        VALIDATE(){
            return ConstantAction.VALIDATE;
        },
        REMOVE() {
            return ConstantAction.REMOVE;
        }
    },
    created(){
        this.documentId = parseInt(this.$route.params.documentId);
        this.modificatorId = parseInt(this.$route.params.modificatorId);
        this.consolidationChannel = new BroadcastChannel(ConstantConsolidation.CONSOLIDATION_CHANNEL);
        this.loadDocumentContent({id: this.modificatorId, toLock: false});
        this.loadDataSheetById(this.modificatorId);
    },
    mounted(){
        EventBus.$on(ConstantEvent.SELECT_NODE_FROM_TREE, this.scrollToSelectedNode);
    },
    beforeRouteUpdate (to, from, next) {
        this.modificatorId = parseInt(to.params.modificatorId);
        this.loadDocumentContent({id: this.modificatorId});
        this.loadDataSheetById(this.modificatorId);
        next();
    },
    beforeDestroy(){
        EventBus.$off(ConstantEvent.SELECT_NODE_FROM_TREE);
    },
    methods:{
        ...mapActions({
            loadDocumentContent: 'documentContent/loadDocumentContent',
            loadDataSheetById: 'dataSheetStore/loadDataSheetById',
            removeAllAdditionalSelectedNodes: 'documentContent/removeAllAdditionalSelectedNodes',
            selectNode: 'documentContent/selectNode',
            toggleAdditionalSelectedNode: 'documentContent/toggleAdditionalSelectedNode',
            toggleOpenNode: 'documentContent/toggleOpenNode',
            updateSelectedNodePath: 'documentContent/updateSelectedNodePath',
        }),
        /**
         * Manage the event click somewhere inside the component.
         * Isolate the node where the user has clicked and update it as selected
         * @param {MouseEvent} event
         */
        clickOnNode(event) {
            this.isNodeMenu = false;
            this.node = this.findClickedDocumentNode(event, this.rootNode);//nodeContentMixin method

            if (!!this.node) {
                let parentNode = DocumentTreeService.findNodeById(this.rootNode, this.selectedNode.technicalPath[this.selectedNode.technicalPath.length - 2]);
                if (this.isMultipleSiblingsNodeSelectionAvailable( event, this.node, this.selectedNode, parentNode, this.additionalSelectedNodeList)) {
                    if (this.node !== this.selectedNode) {
                        this.toggleAdditionalSelectedNode(this.node);
                    } else if ( this.node === this.selectedNode && this.additionalSelectedNodeList.length > 0) {
                        let newSelectedNode = this.additionalSelectedNodeList[0];
                        this.toggleAdditionalSelectedNode(newSelectedNode);
                        this.toggleSelectedNode(newSelectedNode);
                    }
                } else if (!(event.button === 2 && this.allSelectedNodes.includes(this.node))) {
                    this.removeAllAdditionalSelectedNodes();
                    this.toggleSelectedNode(this.node);
                }
                if (this.node.type !== ConstantTag.TEXT) {
                    this.allSelectedBlockquoteList = [];
                    this.selectedBlockquote = null;
                    this.isNodeMenu = true;
                } else {
                    let quote = CheckDocumentNodeSelectionService.extractParentBlockquoteIfExist(event.target);
                    if (!!quote.className && quote.className.includes(ConstantQuotation.QT_STRUCTURAL_CLASS)) {
                        this.$handleSelectionInBlockquote( event );
                    } else if (!!quote.className && quote.className.includes(ConstantQuotation.QT_INLINE_CLASS)) {
                        this.selectedBlockquote = quote;
                        this.isNodeMenu = true;
                        checkDocumentNodeSelectionService.expandSelectionToAllTheDomNode(quote, window.getSelection());
                    }
                }
            }
        },

        /**
         * Manage selection in a blockquote 
         * 1) select a structure and its child
         * 2) select siblings of a strcuture and their children
         * @param {Object} event, the selection object TODO: rename this argument or change its description to match
         */
        $handleSelectionInBlockquote(event){
            let newBlockquote = CheckDocumentNodeSelectionService.extractPartBlockquoteIfExist( event.target );
            if(!!this.selectedBlockquote) {
                if( this.isMultipleSiblingsNodeSelectionAvailable( event, newBlockquote, this.selectedBlockquote, this.selectedBlockquote.parentNode, this.additionalSelectedBlockquoteList) ){    
                    /**
                     * IF it's NOT a right click and the new selected blockquote is NOT in those already selected
                     */
                    if( event.button !== 2 && !this.$isBlockquoteAlreadySelected( newBlockquote ) ) {
                        this.additionalSelectedBlockquoteList.push(newBlockquote);
                        this.$orderBlockquoteList();
                        CheckDocumentNodeSelectionService.expandSelectionToAllSelectedBlockquote( this.allSelectedBlockquoteList, window.getSelection() );
                    /**
                     * if we selected a second time a blockquote that has been selected.
                     * We deselected it and replace it. If it's the first blockquote we replace it by another selected blockquote
                     */
                    } else if( this.$isBlockquoteAlreadySelected( newBlockquote ) ){
                        this.$deselectBlockquote( newBlockquote );
                    } else {
                        CheckDocumentNodeSelectionService.expandSelectionToAllSelectedBlockquote( this.allSelectedBlockquoteList, window.getSelection() );
                        this.isNodeMenu = true;
                    }
                } else if( event.button !== 2 && this.$isClickedOnBlockquote( newBlockquote )){
                    this.$selectBlockquote( newBlockquote );
                } else if( event.button === 2 && this.$isClickedOnBlockquote( newBlockquote )) {
                    this.$selectBlockquote( newBlockquote );
                    CheckDocumentNodeSelectionService.expandSelectionToAllSelectedBlockquote( this.allSelectedBlockquoteList, window.getSelection() );
                    this.isNodeMenu = true;
                }
            } else {
                this.$selectBlockquote( newBlockquote );
            }
            
        },

        /**
         * Check if a blockquote is already selected in the list of selected blockquotes
         * @param {Object} blockquote, the blockquote to check
         */
        $isBlockquoteAlreadySelected( blockquote ){
            return this.allSelectedBlockquoteList.includes( blockquote );
        },

        /**
         * if we selected a second time a blockquote who has been selected. 
         * We deselected it and replace it. If it's the first blockquote we replace it by another selected blockquote
         * @param {Object} blockquote, the blockquote to remove from list of selected blockquotes
         */
        $deselectBlockquote( blockquote ){
            if( blockquote === this.selectedBlockquote){
                this.selectedBlockquote = this.additionalSelectedBlockquoteList[0];
                this.additionalSelectedBlockquoteList.shift();
            } else {
                let indexOfAllSelectedBlockquote = this.allSelectedBlockquoteList.indexOf(blockquote);
                // We can only remove a node at the extreme of the selection otherwise we can't
                if( indexOfAllSelectedBlockquote === 0 || indexOfAllSelectedBlockquote === this.allSelectedBlockquoteList.length-1){
                    let indexOfBlockquoteToDelete = this.additionalSelectedBlockquoteList.indexOf( blockquote );
                    this.additionalSelectedBlockquoteList.splice(indexOfBlockquoteToDelete, 1);
                }
            }
            this.$orderBlockquoteList();
            CheckDocumentNodeSelectionService.expandSelectionToAllSelectedBlockquote( this.allSelectedBlockquoteList, window.getSelection() );
        },

        /**
         * Select the quote that the user click on. We can pass through this method when the user try to select another quote,
         * if the user don't select a siblings of the previous one, So we reset all the previous informations
         * and select the clicked one as the first one.
         * @param {Object} blockquote, the new selected blockquote
         */
        $selectBlockquote( blockquote ){
            this.additionalSelectedBlockquoteList = [];
            this.allSelectedBlockquoteList = [];
            this.selectedBlockquote = CheckDocumentNodeSelectionService.extractPartBlockquoteIfExist( blockquote );
            
            if(!!this.selectedBlockquote){
                this.isNodeMenu = true;
                if ( this.selectedBlockquote.className.includes(ConstantQuotation.QT_STRUCTURAL_CLASS )){
                    for ( let index = 0; index < this.selectedBlockquote.children.length; index++) {
                        this.allSelectedBlockquoteList.push(this.selectedBlockquote.childNodes[index]);
                        this.additionalSelectedBlockquoteList.push(this.allSelectedBlockquoteList);
                    }
                } else {
                    this.allSelectedBlockquoteList.push(this.selectedBlockquote);
                }
                CheckDocumentNodeSelectionService.expandSelectionToAllTheDomNode(this.selectedBlockquote, window.getSelection());
            }
        },

        /**
         * TODO: use a sort function rather than checking every childNodes of parent
         * Order all the selected blockquote according to their order in their parent
         */
        $orderBlockquoteList(){
            let parent = this.selectedBlockquote.parentNode;
            this.allSelectedBlockquoteList = [];
            parent.childNodes.forEach( blockquote => {
                if( [this.selectedBlockquote, ...this.additionalSelectedBlockquoteList].includes( blockquote )){
                    this.allSelectedBlockquoteList.push( blockquote );
                }
            });
        },

        /**
         * Check if the user clicked on a blockquote
         * @param {Object} target, the selection object
         */
        $isClickedOnBlockquote( target ){
            let isInBlockquote = false;
            let element = target;
            while( !!element && !element.className.includes(ConstantDocumentNode.NODE_CONTENT_CLASS) ){
                if( element.tagName === ConstantTag.BLOCKQUOTE){
                    isInBlockquote = true;
                }
                element = element.parentNode;
            }
            return isInBlockquote;
        },
        /**
         * Set the selected node
         */
        toggleSelectedNode(node) {
            this.toggleOpenNode(node);
            EventBus.$emit(ConstantEvent.SELECT_NODE_FROM_CONTENT_TREE, node);
            this.selectNode(node);
            this.updateSelectedNodePath();
        },
        /**
         * Check the possibility to select multiple siblings node on click
         * TODO: explain condition to select multiple siblings in comments here or on each condition,
         * and what is node and newNode given as arguments?
         */
        isMultipleSiblingsNodeSelectionAvailable(event, newNode, node, parentNode, listOfPotentialSiblings){
            let isSiblingOnAdditionalArray = false;

            if(listOfPotentialSiblings.length > 0){
                for(let i = 0; i  < listOfPotentialSiblings.length ; i++){
                    if(!isSiblingOnAdditionalArray){
                        let nodeOfList = listOfPotentialSiblings[i];
                        isSiblingOnAdditionalArray = this.isSiblingOfSelectedNode(nodeOfList, newNode, parentNode);
                    }
                }
            }

            if(event.button === 0
                    && event.altKey
                    && !!newNode
                    &&  ( this.isSiblingOfSelectedNode(node, newNode, parentNode)
                         || isSiblingOnAdditionalArray)){
                return true;
            }else {
                return false;
            }

        },

        /**
         * Check if another clicked element is a direct siblings of the previous one
         * @param node, the selected node
         * @param newNode, the new selected node to check his siblings
         * @param parentnNode, the parent of the selected node, who contains all the potential siblings of the node
         */
        isSiblingOfSelectedNode(node, newNode, parentNode){
            let noPreviousSiblings = false;
            let noNextSiblings = false;
            let isSiblingOfNode = false;
            /** 
             * This method is use in two type of object for the parent, once it as a JSON object where the children is an array
             * the second use is with a HTML object where the children is an HTMLCollection.
             * With the Array.from We ensure the durability of the function 
             */ 
            let indexOfSelectedNode = !!parentNode ? Array.from(parentNode.children).indexOf(node) : -1;
            if(indexOfSelectedNode !== -1){
                if(indexOfSelectedNode === 0){
                    noPreviousSiblings = true;
                }
                if(indexOfSelectedNode === parentNode.children.length-1){
                    noNextSiblings = true;
                }

                if(!noPreviousSiblings){
                    isSiblingOfNode = (newNode === parentNode.children[indexOfSelectedNode-1]);
                }
                if(!noNextSiblings && !isSiblingOfNode){
                    isSiblingOfNode = (newNode === parentNode.children[indexOfSelectedNode+1]);
                }
            }
            return isSiblingOfNode;
        },
        /**
         * Display the contextual menu
         */
        showContextMenu( event ) {
            this.menuOpen = false;
            event.preventDefault()
            this.menuXAxis = event.clientX;
            this.menuYAxis = event.clientY;
            this.clickOnNode(event);
            this.$nextTick(() => {
                this.menuOpen = true;
            })
        },
        /**
         * Copy the selected blockquote, depending on if it's an online or structural blockquote;
         * will copy the blockquote in the clipboard or in the channel and display the corresponding
         * notification
         */
        copyBlockquote(){
            let qtClassName = this.selectedBlockquote.className;
            if(qtClassName.includes( ConstantQuotation.QT_INLINE_CLASS) ){
                this.sendSelectionToConsolidate(window.getSelection());
            } else {
                let extractedStructuralNode = DocumentTreeService.convertDomElementIntoStructuralNode(this.selectedBlockquote);
                if( !!extractedStructuralNode ){
                    EventBus.$emit( ConstantEvent.ADD_NOTIFICATION, {
                        type: ConstantNotification.SUCCEED,
                        message: 'document.notification.consolidation.structuralIsCopied',
                    });
                    this.sendToDocumentToConsolidate(extractedStructuralNode, extractedStructuralNode.type, ConstantAction.COPY_STRUCTURAL_QUOTE);
                } else {
                    EventBus.$emit( ConstantEvent.ADD_NOTIFICATION, {
                        type: ConstantNotification.ERROR,
                        message: 'document.notification.consolidation.structuralIsNotCopied',
                    });
                }
            }
        },

        /**
         * Copy Siblings blockquote and convert them into a list of JSON nodes (siblings too)
         * this list of JSON nodes is send to the document to consolidate and the user is notify that 
         * the nodes are copied and send.
         */
        copySiblingsBlockquote(){
            let extractedStructuralBlockquote = [];

            this.allSelectedBlockquoteList.forEach( blockquotes => {
                let structuralBlockquote = DocumentTreeService.convertDomElementIntoStructuralNode( blockquotes );
                extractedStructuralBlockquote.push( structuralBlockquote );
            });

            if( !!extractedStructuralBlockquote ){
                EventBus.$emit( ConstantEvent.ADD_NOTIFICATION, {
                    type: ConstantNotification.SUCCEED,
                    message: 'document.notification.consolidation.structuralBlockquoteIsCopied',
                });
                this.sendToDocumentToConsolidate(extractedStructuralBlockquote, null, ConstantAction.COPY_STRUCTURAL_QUOTE_LIST);
            }else {
                EventBus.$emit( ConstantEvent.ADD_NOTIFICATION, {
                    type: ConstantNotification.ERROR,
                    message: 'document.notification.consolidation.structuralBlockquoteIsNotCopied',
                });
            }
        },
        /**
         * This is an example, each created menu option must be implemented
         */
        copyNode() {
            if( !!this.node ){
                EventBus.$emit( ConstantEvent.ADD_NOTIFICATION, {
                    type: ConstantNotification.SUCCEED,
                    message: 'document.notification.consolidation.copyNode',
                });
                this.sendToDocumentToConsolidate(this.node, this.node.type, ConstantAction.COPY_NODE);
            } else {
                EventBus.$emit( ConstantEvent.ADD_NOTIFICATION, {
                    type: ConstantNotification.ERROR,
                    message: 'document.notification.consolidation.nodeNotCopied',
                });
            }
        },
        /**
         * Copy multiple node if and only if they are siblings
         */
        copySiblingsNode(){
            let parentNode = DocumentTreeService.findNodeById(this.rootNode, this.selectedNode.technicalPath[this.selectedNode.technicalPath.length - 2]);
            let mapStructureSiblings = new Map();

            for(let i = 0; i < parentNode.children.length; i++){
                this.allSelectedNodes.find( function(element) {
                    if(element === parentNode.children[i]){
                        mapStructureSiblings.set(i, element);
                    }
                });
            }
            if(!!Array.from(mapStructureSiblings.values()) ) {
                EventBus.$emit( ConstantEvent.ADD_NOTIFICATION, {
                    type: ConstantNotification.SUCCEED,
                    message: 'document.notification.consolidation.copyNodes',
                });
                this.sendToDocumentToConsolidate(Array.from(mapStructureSiblings.values()), null, ConstantAction.COPY_SIBLINGS_NODE);
            } else {
                EventBus.$emit( ConstantEvent.ADD_NOTIFICATION, {
                    type: ConstantNotification.ERROR,
                    message: 'document.notification.consolidation.nodesNotCopied',
                });
            }
        },
        /**
         * Send a content to be available to use in document to consolidate
         * @param {any} content, any kind of object can be sent
         * @param {any} contentType
         * @param {*} action, describe in which case we are
         */
        sendToDocumentToConsolidate(content, contentType, action) {
            this.consolidationChannel.postMessage(
                {
                    modificatorId: this.modificatorId,
                    contentType,
                    content,
                    action
                }
            );
        },
        /**
         * Copy the content of the selection end put the selection in the navigator clipboard
         */
        copyContent(){
            let content = window.getSelection().toString();
            if( !!content ){
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    type: ConstantNotification.SUCCEED,
                    message: 'document.notification.consolidation.copyContent',
                }); 
                navigator.clipboard.writeText(content);
                this.sendToDocumentToConsolidate(null,null, ConstantAction.READY_TO_COPY_CONTENT);
            } else {
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    type: ConstantNotification.ERROR,
                    message: 'document.notification.consolidation.contentNotCopied',
                }); 
            } 
        },
        /**
         * Open dialog to confirm the action
         */
        confirm( action ){
            if(action === this.VALIDATE){
                this.action = this.VALIDATE;
                this.message = 'document.consolidation.confirmation.validateModificator';
            } else if(action === this.REMOVE){
                this.action = this.REMOVE;
                this.message = 'document.consolidation.confirmation.removeModificator';
            }
            this.openConfirmation = true;
        },
        /**
         * Back from the dialog will execute the corresponding method
         */
        isConfirm(){
            if(this.action === this.VALIDATE){
                this.validateModifications();
            } else if(this.action === this.REMOVE){
                this.removeDocumentModifier();
            }
        },
        /**
         * Send the id of the document modificator for the document
         * To validate his integration on his document
         */
        validateModifications(){
            axios.post(
                'document/modifier/validateModificator',
                {
                    targetDocumentId:  this.modificatorId,
                    modifierDocumentId: this.documentId
                }
            ).then( () => {
                var notification = {
                        type: ConstantNotification.SUCCEED,
                        message: 'document.notification.consolidation.succeed',
                };
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
                this.sendToDocumentToConsolidate(null,null, ConstantAction.SAVE_DOCUMENT_CONTENT);
            });
        },
        /**
         * Recover the selection, if the selection contains a part of a  blockquote will recreate it
         */
        async onCopy(event){
            const lastSelection = window.getSelection().getRangeAt(0);
            this.sendCopyToConsolidate(lastSelection);
        },
        /**
         * Recreate the blockquote if she is selected in the selection and send the notification
         */
        sendCopyToConsolidate( selection ){
            let content = "";
            let notification = {
                type: ConstantNotification.SUCCEED,
                message: 'document.notification.consolidation.copy',
            };

            let childNodes = [...selection.cloneContents().childNodes];
            childNodes.forEach( child => {
                if ( child.tagName !== 'BLOCKQUOTE' && child.nodeName !== '#text' ) {
                    content += child.outerHTML;
                }
                else if ( child.tagName === 'BLOCKQUOTE' ) {
                    content += child.outerHTML;
                }
                else {
                    content += child.textContent;
                }
            })
            EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
            navigator.clipboard.writeText(content);
            this.sendToDocumentToConsolidate(null,null, ConstantAction.READY_TO_COPY_DOCUMENT_MODIFIER);
        },
        /**
         * Will set the clipboard with the selection
         */
        sendSelectionToConsolidate(selection){
            this.setupClipboard( selection.focusNode.outerHTML );
        },
        /**
         * Remove the document modifier from the document to consolidate's list
         */
        removeDocumentModifier(){
            axios.post(
                'document/modifier/unlinkDocuments',
                {
                    targetDocumentId: this.documentId,
                    modifierDocumentId: this.modificatorId
                }
            ).then( ({data}) => {
                var notification = {
                        type: ConstantNotification.SUCCEED,
                        message: 'document.notification.consolidation.remove',
                };
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
                this.sendToDocumentToConsolidate(data,null, ConstantAction.REMOVE_DOCUMENT_MODIFIER);
            });
        },
        /**
         * Will write the slected text in the clipboard
         * @param {String} content
         */
        async setupClipboard( content ) {
            try {
                await navigator.clipboard.writeText( content );
                EventBus.$emit( ConstantEvent.ADD_NOTIFICATION, {
                    type: ConstantNotification.SUCCEED,
                    message: 'document.notification.consolidation.onlineIsCopied',
                });
                this.sendToDocumentToConsolidate(null,null, ConstantAction.COPY_ONLINE_QUOTE);
            } catch (err) {
                EventBus.$emit( ConstantEvent.ADD_NOTIFICATION, {
                    type: ConstantNotification.ERROR,
                    message: 'document.notification.consolidation.onlineIsNotCopied',
                });
            }
                }
    },
    beforeDestroy(){
        this.consolidationChannel.close();
    }
}
</script>
<style>
#modificator{
    height:calc(100vh - 20px);
    overflow: auto;
    min-width:calc(100vw - 350px);
}
.title-container{
    height:calc(100vh + 20px) !important;
    max-width:350px;
    background-color:#355B7F;
}
.title-consolidation{
    margin-bottom:40px;
    margin-top:10px;
    text-align: left;
    margin-left:20px;
    color: #355B7F;
}
.tooltips{    
    z-index: 190;
    right:0;
    top:40px;
    margin-right:20px;
    position:absolute;
}
</style>
