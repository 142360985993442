<template>
    <v-layout align-end justify-center column >
        <v-flex  class="actions" >
            <span class="text_actions">{{ $t('pao.action.edit') }}</span>
            <v-btn color="white" small fab dark slot="activator"  :to="`/pao/manage/${idPao}`" >
                <v-icon color="primary">edit</v-icon>
            </v-btn>
        </v-flex>
        <v-flex class="actions">
            <span class="text_actions">{{ $t('pao.action.export') }}</span>
            <v-btn color="white" small fab dark slot="activator"  @click="exportPao">
                <v-icon color="primary">cloud_upload</v-icon>
            </v-btn>
        </v-flex>
        <v-flex class="actions">
            <span class="text_actions">{{ $t('pao.action.update-content') }}</span>
            <v-btn color="white" small fab dark slot="activator"  @click="openConfirmUpdateContent">
                <v-icon color="primary">update</v-icon>
            </v-btn>
        </v-flex>
        <dialog-confirmation :dialog.sync="openConfirmation" :message="confirmationMessage" @confirm="confirmUpdateContent"/>
    </v-layout>
</template>

<script>

import axios                            from 'axios';
import EventBus                         from '../../../utils/event-bus';
import ConstantEvent                    from '../../../shared/constant/constantEvent';
import ConstantNotification             from '../../../shared/constant/constantNotification';
import DialogConfirmation               from '../../shared/DialogConfirmation';

/**
 * 
 * Display all the action buttons for a pao
 * 
 * @author Cédric de BOISVILLIERS, SFEIR Benelux
 * @version 1.0
 * @since 24/09/2019
 */
export default {
    name: 'ActionPanelPao',
    components: { DialogConfirmation },
    data(){
        return {
            openConfirmation: false,
            confirmationMessage: 'pao.message.confirm-update-content-pao',
        }
    },
    props: {
        idPao: {
            default: null
        }
    },
    methods: {
        /**
         * Export a pao
         */
        exportPao() {
            axios.put(
                `pao/${this.idPao}/export`
            ).then(() => {
                const notification = {
                    type: ConstantNotification.INFO,
                    message: 'pao.notification.export.inProgress',
                };
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, notification);
            });
        },
        openConfirmUpdateContent(){
            this.openConfirmation = true;
        },
        /**
         * Update a Pao content
         */
        confirmUpdateContent() {
            axios.put(
                `pao/${this.idPao}/updateContent`
            ).then(() => {
                this.$router.push({path: '/pao'});
            });
        }
    }
}
</script>

<style scoped>
.actions{
    margin-bottom:20px;
}
.text_actions{
    color:white;
}
</style>
