<template>
    <div class="margins_content_tree">
        <v-container fluid>
            <v-layout column justify-start align-start >
                <v-flex id="#container-event">
                    <div :id="`container-tree-light`" class="container-class"
                        @click="clickOnNode">
                        <content-node-light 
                            class="node-content" 
                            :node="rootNode" 
                            :documentType="documentType"/>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
import ContentNodeLight                     from './ContentNodeLight';
import { nodeContentMixin }                 from '../../../../mixins/nodeContentMixin';
import ConstantTag                          from '../../../../shared/constant/constantTag.js';
import ConstantDocumentType                 from '../../../../shared/constant/constantDocumentType';

/**
 * Display the node tree and the content
 *
 * @author Gentilhomme Romain
 * @version 1.0
 * @since 2020-01-07
 */
export default {
    name: 'NodeContentTreeLight',
    mixins: [nodeContentMixin],
    props: {
        rootNode: {
            default: null
        },
        documentType: {
            default: null
        }
    },
    data: () => ({
        isHiddenSearch: false,
        globalAttributes: {
            nodeId: null,
        },
    }),
    components: {
        ContentNodeLight,
        nodeContentMixin
    },
    methods:{
        /**
         * Manage the event click somewhere inside the component.
         * Isolate the node where the user has clicked and update it has selected
         * @param event
         */
        clickOnNode( event ) {
            this.isHiddenSearch = false;
            let node = this.findClickedDocumentNode(event, this.rootNode);
            if (!!node && this.isNodeClickable(node)) {
                this.globalAttributes.nodeId = node.id;
                this.$emit("clickedOnResume", {"value": true, "idnode": node.id});
            }else{
                if (!!node) {
                    this.globalAttributes.nodeId = node.id;
                    this.$emit("clickedOnResume", {"value": false, "idnode":node.id});
                }
            }
        },
        /**
         * Return true if:
         *  * the document is a Jurisprudence and the node is a RESUME and the node have a list of keywords
         *  * the document is a Bibliography and the node is an ACT
         *  * the document is a Doctrine and the node is a RESUME
         */
        isNodeClickable(node){
            return (
                (this.documentType.code === ConstantDocumentType.JURISPRUDENCE && node.label === ConstantTag.RESUME && node.keywords.length > 0)
                    || (node.label === ConstantTag.ACT && this.documentType.code === ConstantDocumentType.BIBLIOGRAPHY)
                    || (this.documentType.code === ConstantDocumentType.DOCTRINE && node.label === ConstantTag.RESUME))
        }
    },
}
</script>
<style>
.bol-custom-list td:first-child{
    border: 1px dotted gray;
    padding: 10px !important;
    width: 75px;
    min-height: 30px !important;
}
.bol-custom-list td{
    border: 1px dotted gray;
    padding: 10px !important;
    min-height: 30px !important;
}
.bol-editable-table table{
    table-layout: fixed;
}
.bol-editable-table thead tr:first-of-type{
    height: 30px !important;
}
.bol-editable-table thead tr:first-of-type th{
    padding: 5px !important;
    width: 20px;
}
.bol-editable-table table tbody tr{
    border: none !important;
    background: none;
}
.bol-editable-table td{
    border: 1px solid black;
    padding: 5px !important;
    height: 30px !important;
    overflow-wrap: break-word;
}
.bol-editable-table table td div div{
    display: inline;
}
.bol-editable-table table td div div a{
    display: block;
}
</style>

<style scoped>
.container-class{
    height:calc(100vh - 85px);
    padding-bottom:20px;
    overflow-y:auto;
    text-align: left;
}
.margins_content_tree{
    margin-left:-30px;
    padding:0;
}

</style>