<template>
    <v-layout justify-space-around wrap>
        <bol-large-button icon="book" 
            :label="this.$t('navigation.administration.book')" 
            route="/administration/book"/>
        <bol-large-button icon="bookmarks" 
            :label="this.$t('navigation.administration.magazine')"
            route="/administration/magazine"/>
    </v-layout>
</template>
<script>
import BolLargeButton   from '../../shared/BolLargeButton'

export default {
    name:'BolPublicationAdministration',
    components: {
        BolLargeButton
    }
    
}
</script>
