const RESET_DATASHEET                   = 'reset_datasheet';
const RESET_METADATA                    = 'reset_metadata_datasheet';
const SET_DATASHEET                     = 'set_datasheet';
const SET_DATASHEET_ADDITIONAL_FIELDS   = 'set_datasheet_additional_fields';
const SET_DOCUMENT_MODIFICATOR          = 'set_document_modificator';
const SET_DOCUMENT_STEP_HISTORY         = 'set_document_step_history';
const SET_ID_DOCUMENT                   = 'set_id_document';
const SET_LABEL_ADDITIONAL_FIELDS       = 'set_label_additional_fields';
const SET_META_DOCUMENT                 = 'set_meta_document';
const SET_TITLE                         = 'set_title';
const SET_DOCUMENT_TYPE_CODE            = 'set_document_type_code';
const SET_DOCUMENT_TYPE_ID              = 'set_document_type_id';

/**
 * Constants for datasheetStore
 * @author Justin WILMET
 * @since 05/07/2019
 * @version 1.0
 */
export default class datasheetTypes{

    static get MUTATION(){
        return {
            RESET_DATASHEET,
            RESET_METADATA,
            SET_DATASHEET,
            SET_DATASHEET_ADDITIONAL_FIELDS,
            SET_DOCUMENT_MODIFICATOR,
            SET_DOCUMENT_STEP_HISTORY,
            SET_ID_DOCUMENT,
            SET_LABEL_ADDITIONAL_FIELDS,
            SET_META_DOCUMENT,
            SET_TITLE,
            SET_DOCUMENT_TYPE_CODE,
            SET_DOCUMENT_TYPE_ID
        }
    }

}