import axios                                from 'axios';
import ConstantTag                          from '../../shared/constant/constantTag.js';
import ConstantDocumentNode                 from '../../shared/constant/constantDocumentNode.js';
import ConstantClassName                    from '../../shared/constant/constantClassName.js';
import DomManipulatorService                from '../../service/html/domManipulatorService.js';
import ConstantLanguage                     from "../../shared/constant/constantLanguage";
/**
 * 
 * Manage all the specific operation related to a document tree
 * 
 * @author Sébastien DE SANTIS
 * @since 04/10/2018
 * @version 1.0
 * 
 */
class DocumentTreeService {

    constructor(){

    }
    /**
     * Flat the document content tree
     * 
     */
    flatTree(node){
        if(!node || 
            node.type === 'EXTERNAL-HYPERLINK' ||
            node.type === 'INTERNAL-HYPERLINK' ||
            node.type === 'CONTENT-HYPERLINK'
        ){
            return;
        }
        var flattedTree = [];
        flattedTree.push(node);
        if(!node.children){
            return flattedTree;
        }
        node.children.forEach(element => {
            var flattedTreePart = this.flatTree(element);
            if(!!flattedTreePart){
                flattedTree = flattedTree.concat(flattedTreePart);
            }
        });
        return flattedTree;
    }
    /**
     * Find a parent node 
     * @param {*} node 
     * @param {*} flattedTree 
     */
    findParentNode(node, flattedTree){
        if(node.technicalPath.length > 1 ){
            var parentId = node.technicalPath[node.technicalPath.length - 2];
            return flattedTree.find(flattedNode => {
                return flattedNode.id === parentId;
            })
        } else {
            return null;
        }
    }
    /**
     * Find a node
     * @param {*} flattedTree 
     * @param {*} nodeId
     */
    findNode(flattedTree, nodeId){
        return flattedTree.find(flattedNode => {
            return flattedNode.id === nodeId;
        });
    }
    /**
     * Find a node by id inside a tree.
     * This method is recursive
     * @param {*} node 
     * @param {*} id 
     */
    findNodeById(node, id){
        if(node.id === id ){
            return node;
        } else if(node.children) {
            for(var childNode of node.children ){
                var found = this.findNodeById(childNode, id);
                if(found){
                    return found;
                }
            }
        }
    }
    /**
     * Update the technical path and the path of a node regarding of its parent
     * if the parent is itself the path need to be updated as rootNode
     * @param {*} node 
     * @param {*} parentNode 
     */
    updateNodePath(node, parentNode){
        let createdPath = [];
        let createdTechnicalPath = [];
        if( !!parentNode.path ){
            parentNode.path.forEach( element => {
                createdPath.push(element);
            });
            createdPath.push(node.label);
            
            parentNode.technicalPath.forEach( element => {
                createdTechnicalPath.push(element);
            });
            createdTechnicalPath.push(node.id);
        } else {
            createdPath.push(parentNode.label);
            createdTechnicalPath.push(parentNode.id);
        }
    
        node.path = createdPath;
        node.technicalPath = createdTechnicalPath;
    }
    /**
     * Update all the node path for a specific node and all of his children
     * @param {*} node 
     * @param {*} parentNode 
     */
    updateRecursivelyNodePath(node, parentNode){
        this.updateNodePath(node,parentNode);
        if(!!node.children){
            if(node.children.length > 0){
                node.children.forEach(element => {
                    this.updateRecursivelyNodePath(element, node);
                });
            }
        }
    }
    /**
     * Create an empty content node ready to be inserted inside the document content node tree
     * @param {*} nodePattern 
     * @param {*} path 
     */
    createNode(nodePattern, parentNode){
        let id = this.$generateUid();

        let node = {
            id: id,
            label: nodePattern.label.toUpperCase(),
            type: nodePattern.type.toUpperCase(),
            selected: false,
            children: [],
            documentTypeId: nodePattern.documentTypeId,
            path: '',
            technicalPath: '',
            keywords: [] 
        };

        this.updateNodePath(node, parentNode);
        
        if( nodePattern.leaf ){
            node.children.push(this.createNode(this.createPatternNode('TEXT'), node));
        }
        return node;
    }
    deleteContentNodeInside(copiedNode){
        return this.deleteContentNode(copiedNode);
    }
    deleteContentNode(node){
        if(!!node.children){
            node.children.forEach(childNode => {
                childNode.content = '';
                this.deleteContentNodeInside(childNode);
            })
        }
        return {
            id: node.id,
            technicalPath: [],
            content: node.content,
            children: node.children,
            opened: false,
            label: node.label,
            type: node.type,
            selected:false,
            path: node.path,
            keywords: node.keywords
        };
    }
    changeNodeId(node, selectedNode){
        let children = [];
        let newID = this.$generateUid();

        if(!!node.children){
            node.children.forEach(childNode => {
                children.push(this.changeNodeId(childNode, selectedNode));
            })
        } else {
            node.content.replace(new RegExp("\(?<=id=\").*?(?=\")","gm"), this.$generateUid() );
        }
        return {
            id: newID,
            technicalPath: [],
            content: node.content,
            children: children,
            opened: false,
            label: node.label,
            type: node.type,
            selected:false,
            path: node.path,
            keywords: node.keywords 
        };
    }
    createPatternNode(label){
        var type = 'MANUAL'
        if(label === 'TEXT' ){
            type = 'TEXT'
        }
        
        return {
            children: [],
            content: "",
            id: this.$generateUid(),
            label: label.toUpperCase(),
            opened: false,
            path: '',
            selected: true,
            technicalPath: '',
            documentTypeId: '',
            type: type,
            keywords: []            
        };
    }
    /**
     * Generate a ( client ) uid use for node id generation
     * ( Note: we need a node id for finding / deleting node etc. )
     */
    $generateUid( ) {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
            function(c) {
                var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
                return v.toString(16);
            });
    }
    
    /**
     * Find a node in a document content tree node with its path
     * @param {*} path 
     * @param {*} rootNode 
     */
    $findNodeByPath(technicalPath, rootNode){
        var node = rootNode;
        technicalPath.forEach( (pathChunk, index) => {
            if(index !== 0){   
                node = node.children.find( child => {
                    return child.id === pathChunk;
                }); 
            }
        });
        return node;
    }

    findNodeInTree(node, id){
        if(node.id === id){
            return node;
        } else {
            if(node.children){
                for(var childNode of node.children){
                    var foundNode = this.findNodeInTree(childNode, id);
                    if(foundNode){
                        return foundNode;
                    }
                }
            }
        }
    }

    /**
     * Create a node structure depending on which structure type is chosen
     * @param {String} clipboardContent, content of clipboard
     * @param {Number} documentTypeId, id of document type
     * @param {String} structureType, type of node structure
     */
    createNodeStructure( { clipboardContent, documentTypeId, structureType } ){
        if( structureType === ConstantTag.PARAG ){
             
            return this.$createNodeParagStructure( { clipboardContent: clipboardContent, documentTypeId: documentTypeId } );
        } else {

            return this.$createDynamicNodeStructure( { clipboardContent: clipboardContent, documentTypeId: documentTypeId, structureType: structureType } );
        }
    }

    /**
     * Split the text of the clipboard according to a line feed 
     * and create the node parent, the node text and imbricate them
     * @param {String} clipboardContent, content of clipboard
     * @param {Number} documentTypeId, id of document type
     * @param {String} structureType, type of node structure
     */
    async $createDynamicNodeStructure( { clipboardContent, documentTypeId, structureType } ){
        let nodeList = clipboardContent.split(/^\r|\n/gm).filter(node => node.length > 0);
            
        let nodeTitlePattern = await this.$createNodePattern( structureType, documentTypeId );
        let nodeTextPattern = await this.$createNodePattern( ConstantTag.TEXT, documentTypeId );
        
        let nodeStructList = [];

        for( let i = 0; i < nodeList.length; i++){
            let contentNode = this.createPElement(nodeList[i]);
            
            let textNode = this.createClonePattern(nodeTextPattern);
            textNode.content = contentNode.outerHTML;

            let titleNode = this.createClonePattern(nodeTitlePattern);
            titleNode.children = [];
            titleNode.children.push(textNode);

            nodeStructList.push(titleNode);
        }
        return nodeStructList;
    };

    /**
     * Split the text of the clipboard according to the no-parag identifier 
     * and create the corresponding structure
     * @param {String} clipboardContent, content of clipboard
     * @param {Number} documentTypeId, id of document type
     */
    $createNodeParagStructure( {clipboardContent, documentTypeId} ){
        let noParag = clipboardContent.split(new RegExp( /(\([0-9]+?\))/gm ));
        
        let cleanNoParag = [];
        noParag.forEach( parag => {
            if( !!parag && parag !== ""){
                cleanNoParag.push(parag);
            }
        });
        
        if( this.$isFirstElementANoParag( cleanNoParag[0] ) ){
            let paragList = [];

            for(let index = 0; index < cleanNoParag.length; ){
                let paragStructure = { noParag: "", alineas: [] };

                paragStructure.noParag = cleanNoParag[index];

                if( !!cleanNoParag[index+1] ){
                    paragStructure.alineas = cleanNoParag[index+1].split(/^\r|\n/gm).filter(node => node.length > 0); 
                }else {
                    paragStructure.alineas = "";
                }
            
                paragList.push(paragStructure);
                index += 2;
            }

            return this.$createStructureParag( paragList, documentTypeId );

            
        } else {
            return {
                message: 'document.error.paragStructureError',
                type: 'WARNING'
            }
        }
    }

    /**
     * For a given parag list (parag -> no-parag -> text / parag -> alinea -> text )
     * create the JSON structrure corresponding
     * @param {Array} paragList, list of the parag structure
     * @param {Number} documentTypeId, type of the document 
     */
    async $createStructureParag( paragList, documentTypeId ){

        let nodeParagStructure = [];
        let nodeTextPattern = await this.$createNodePattern( ConstantTag.TEXT, documentTypeId );
        let nodeParagPattern = await this.$createNodePattern( ConstantTag.PARAG, documentTypeId );
        let nodeNoParagPattern = await this.$createNodePattern( ConstantTag.NO_PARAG, documentTypeId );
        let nodeAlineaPattern = await this.$createNodePattern( ConstantTag.ALINEA, documentTypeId );

        paragList.forEach( parag => {

            let nodeParag = this.createClonePattern( nodeParagPattern );
            nodeParag.children = [];        

            let nodeNoParag = this.createClonePattern( nodeNoParagPattern );
            nodeNoParag.children = [];
            
            let contentNodeNoParag = this.createPElement(parag.noParag);
            let textNodeNoParag = this.createClonePattern(nodeTextPattern);
            textNodeNoParag.content = contentNodeNoParag.outerHTML;

            nodeNoParag.children.push( textNodeNoParag );
            nodeParag.children.push( nodeNoParag );

            let nodeAlinea = this.createClonePattern( nodeAlineaPattern );
            nodeAlinea.children = [];
                
            let textNodeAlinea = this.createClonePattern(nodeTextPattern);
            

            for( let i = 0; i < parag.alineas.length; i++){        
                let contentNodeAlinea = this.createPElement(parag.alineas[i]);
        
                textNodeAlinea.content += contentNodeAlinea.outerHTML;                
            }
            nodeAlinea.children.push(textNodeAlinea);
            nodeParag.children.push(nodeAlinea);
            nodeParagStructure.push(nodeParag);
        });
        return nodeParagStructure;
    }

    /**
     * Check if the first element of the selection is realy a NO-PARAG : (X)
     * where  X  is a number between 0 and 99
     * @param {String} firstElement 
     */
    $isFirstElementANoParag( firstElement ){
        let noParagContent = firstElement.match(new RegExp( /\([0-9a-zA-Z]{1,2}\)/gm ));
        return !!noParagContent;
    }

    /**
     * Create a pattern node based on the node label
     * @param {*} node 
     * @param {*} documentTypeId 
     */
    $createNodePattern( node, documentTypeId){
        return axios.post(
            'structure/node',
            {
                documentTypeId: documentTypeId, 
                parentNodeLabel: node,
            }
        ).then( ({data}) => {
            return data;
        });
    }

    /**
     * Create a new node based on a given one
     * @param {*} node 
     */
    createClonePattern( node ){
        let clone = {};
        for ( let attribute in node) {
            clone[attribute] = node[attribute];
        } 
        return clone;
    }

    /**
     *
     * @param language code
     * @returns code for flags <- ISO 3166 to get EN flag use GB code because EN code not exist.
     */
    convertCodeForFlags( code ){
        if(!!code) {
            return code === ConstantLanguage.EN ? ConstantLanguage.GB : code;
        }else {
            return "";
        }
    }

    /**
     * Based on an html element, will create the corresponding JSON
     * @param {Node} domNode 
     */
    convertDomElementIntoStructuralNode(domNode){
        if(domNode.className === ConstantClassName.TEXTNODE || domNode.className === ConstantDocumentNode.TEXT_TYPE){
            let textNode = this.createPatternNode(ConstantDocumentNode.TEXT_TYPE);
            textNode.content = domNode.innerHTML;
            return textNode;
        }
        let children = [];
        if(!!domNode.childNodes){
            domNode.childNodes.forEach(childNode => {
                children.push(this.convertDomElementIntoStructuralNode(childNode));
            })
        }
        let structuralNode = this.createPatternNode(domNode.className);
        structuralNode.children = children;

        structuralNode.children.forEach( childNode => {
            this.updateNodePath( childNode, structuralNode);
        }); 

        return structuralNode;
    }

    /**
     * Create new block p 
     * @param {String} text, textContent of the p element
     */
    createPElement( text ){
        let p = document.createElement(ConstantTag.P);
        p.textContent = text;
        return p;
    }

    /**
     * Generic method to insert text as a P after the selection
     * Paste a new text from the clipboard after the selection
     * @param {String} textFromClipboard, content of the clipboard
     * @param {Object} node, the node where the user clicked
     * @param {Number} offset, the offset where to insert the html 
     */
    pasteTextAsAPElement( textFromClipboard, node, offset ){
        let nodeToPaste = this.createPElement( textFromClipboard ); 
        return this.$insertTextInSelectedNode( nodeToPaste.outerHTML, node, offset );
    }

    /**
     * Generic method to insert a structure of paragraph from the content
     * of the clipboard after the selection
     * @param {String} textFromClipboard, content of the clipboard
     * @param {Object} node, the node where the user clicked
     * @param {Number} offset, the offset where to insert the html 
     */
    pasteTextAsMultiplePElement( textFromClipboard, node, offset ){
        // Split the text on the carriage return
        let textSplitted = textFromClipboard.split(/\r|\n|\r\n/gm);
        // keep only the part of the text who are not empty
        const textFiltered = textSplitted.filter( text => text.length > 0);
        // Enrich the content with a P element for each carriage return
        let text = textFiltered
            .map(singleText => {
                return this.createPElement(singleText).outerHTML;
            })
            .reduce((previousText, currentValue) => {
                return previousText + currentValue
            });

        return this.$insertTextInSelectedNode( text, node, offset );
    }

    /**
     * Insert a text into a node
     * @param {String} contentToInsert, the text to insert in the node,
     * @param {Node} node, the node where the user clicked
     * @param {Number} offset, the offset in the node where to insert the text
     */
    $insertTextInSelectedNode( contentToInsert, node, offset ){
        let validateNode = DomManipulatorService.isPDFStructure( node );
        return DomManipulatorService.insertHTMLInNode(
            validateNode, 
            offset,
            contentToInsert,
            ConstantDocumentNode.TEXT_NODE_CLASS);
    }
}

export default new DocumentTreeService();