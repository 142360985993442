<template>
  <div class="container-structure">
    <v-layout class="container_filter_documents">
      <v-flex>
        <div v-if="rootNode" class="padding_bottom_20">
          <v-layout class="root_size">
            <v-flex @click="clickOnNode">
              <structural-node id="tree_container" v-if="rootNode" :node="rootNode"></structural-node>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
      <div v-if="!isModificator">
        <edition-action-panel @save="saveDocument" @cancel="goBack"></edition-action-panel>
      </div>
    </v-layout>
  </div>
</template>
<script>
import StructuralNode from './StructuralNode.vue'
import EventBus from '../../../../utils/event-bus';
import * as $ from 'jquery';
import {mapActions} from 'vuex';
import DocumentTreeService from '../../../../service/document/documentTreeService.js';
import ConstantEvent from '../../../../shared/constant/constantEvent.js';
import ConstantStoreName from '../../../../shared/constant/constantStoreName.js';
import EditionActionPanel from '../../../shared/EditionActionPanel';

/**
 *
 * Component that display the document tree as a simple tree without content
 *
 * @author Sébastien DE SANTIS
 * @since 2018-11-23
 * @version 1.0
 */
export default {
  name: 'StructuralNodeTree',
  props: {
    isModificator: {
      default: false,
    }
  },
  components: {
    StructuralNode,
    EditionActionPanel
  },
  computed: {
    rootNode() {
      return this.$store.state[`${ConstantStoreName.DOCUMENT_CONTENT}`].rootNode;
    }
  },
  mounted() {
    EventBus.$on(ConstantEvent.SELECT_NODE_FROM_CONTENT_TREE, this.listener);
    this.$nextTick(function () {
      this.openNode(this.rootNode);
    })
  },
  beforeDestroy() {
    EventBus.$off(ConstantEvent.SELECT_NODE_FROM_CONTENT_TREE, this.listener);
  },
  methods: {
    ...mapActions('documentContent', [
      'selectNode',
      'updateSelectedNodePath',
      'openNode',
      'toggleOpenNode'
    ]),
    /**
     * Listen the select node event from the node content tree component.
     * @param node the node selected by the user
     */
    listener: function (node) {
      node.technicalPath.forEach(id => {
        var parentNode = DocumentTreeService.findNodeById(this.rootNode, id);
        if (!!parentNode) {
          this.openNode(parentNode);
        }
      })
      this.$nextTick().then(() => {
        this.scrollToSelectedNode(node);
      });
    },
    /**
     * Manage event click somewhere inside the component.
     * Detect the node selected and send the information to the node content tree component.
     * Update the selected node information inside the store.
     * @param event
     */
    clickOnNode: function (event) {
      var elementNode = event.composedPath().find(chunk => {
        return chunk.className && chunk.className.includes('treeNode');
      });
      if (!!elementNode) {
        var nodeId = elementNode.id.substring(10);
        var node = DocumentTreeService.findNodeById(this.rootNode, nodeId);
        this.toggleOpenNode(node);
        EventBus.$emit(ConstantEvent.SELECT_NODE_FROM_TREE, node);
        this.selectNode(node);
        this.updateSelectedNodePath();
      }
    },
    /**
     * Scroll to the selected node
     */
    scrollToSelectedNode: function (selectedNode) {
      // Animate the scroll to focus on the selected node.
      // Here the selected node should be in the middle of a buffered list
      if (selectedNode) {
        let container = $('#tree_container');
        if (!!container && !!container.offset()) {
          var anchorReferenceElement = $(`#node_leaf-${selectedNode.id}`);
          if (!!anchorReferenceElement && !!anchorReferenceElement.offset()) {
            container.animate({
              scrollTop: anchorReferenceElement.offset().top - container.offset().top + container.scrollTop()
            });
          }
        }
      }
    },
    /**
     * Send to the Structure Editor an event to trigger the save of the document
     */
    saveDocument() {
      this.$emit('saveDocument');
      this.goBack();
    },
    goBack() {
      this.$router.go(-1);
    }
  }
}
</script>
<style scoped>
#tree_container {
  margin-left: -10px;
}

.container-structure {
  background-color: #355B7F;
  height: calc(100vh - 40px);
}

.container_filter_documents {
  max-width: 300px;
  height: calc(100vh - 240px);
  overflow-y: auto;
  background-color: #355B7F;
  padding-top: 5%;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: solid 1px white;
}

.root_size {
  width: 250px;
}

.padding_bottom_20 {
  padding-bottom: 60px;
}

</style>