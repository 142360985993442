<template>
    <v-layout
        row
        wrap
    >
        <!-- TITLE OF THE PAGE-->
        <h2 class="custom_author">
            {{ $t('administration.author.search.mainTitle') }}
        </h2>

        <!-- FORM WITH RESEARCH BUTTON -->
        <v-layout
            row
            wrap
            justify-center
            class="layout_author"
        >
            <v-flex
                xs5
                class="sizer_flex"
            >
                <v-text-field
                    v-model="filter.name"
                    dark
                    :label="$t('administration.author.anyName')"
                    clearable
                    @keyup.enter="filterAuthorsWithPaginationReset()"
                />
            </v-flex>
            <v-flex
                xs1
                justify-end
            >
                <v-btn
                    color="white"
                    small
                    fab
                >
                    <v-icon
                        color="primary"
                        dark
                        @keyup.enter="filterAuthorsWithPaginationReset()"
                        @click="filterAuthorsWithPaginationReset()"
                    >
                        search
                    </v-icon>
                </v-btn>
            </v-flex>
        </v-layout>

        <!-- PROGRESS BAR -->
        <v-layout
            v-if="displaySpinner"
            align-center
            justify-center
            row
        >
            <v-progress-circular
                :size="70"
                :indeterminate="true"
                color="primary"
            />
        </v-layout>

        <!-- DATA TABLES -->
        <v-flex v-if="!displaySpinner">
            <v-data-table
                :headers="headers"
                :items="authorList"
                hide-actions
                disable-initial-sort
                align-center
                justify-center
                class="elevation-1"
            >
                <template slot="no-data">
                    <td
                        colspan="6"
                        class="text-xs-center"
                    >
                        {{ $t('administration.author.search.none') }}
                    </td>
                </template>

                <template
                    slot="items"
                    slot-scope="props"
                >
                    <td class="align-items">
                        {{ props.item.firstName }}
                    </td>
                    <td class="align-items">
                        {{ props.item.lastName }}
                    </td>
                    <td class="align-items">
                        <router-link
                            tag="button"
                            :to="{name: 'AuthorEdit', params: { id: props.item.id }}"
                        >
                            <v-icon class="edit-icon">
                                edit
                            </v-icon>
                        </router-link>
                    </td>
                </template>
            </v-data-table>
        </v-flex>

        <!-- PAGINATION -->
        <v-flex
            v-if="!!authorList && authorList.length > 0"
            xs12
            class="author-list"
        >
            <v-pagination
                :length="pageable.totalPages"
                :total-visible="5"
                :value="pageable.page"
                circle
                @input="changePage"
            />
        </v-flex>

        <!-- FLOATING BUTTON (ADD) -->
        <v-tooltip top>
            <v-btn
                slot="activator"
                color="primary"
                small
                fab
                dark
                icon
                class="icon-add-custom"
                :to="{name: 'AuthorAdd'}"
            >
                <v-icon dark>
                    add
                </v-icon>
            </v-btn>
            <span>{{ $t('administration.author.action.add') }}</span>
        </v-tooltip>
    </v-layout>
</template>

<script>

import axios from 'axios';
import EventBus  from '../../../utils/event-bus.js';
import ConstantEvent    from '../../../shared/constant/constantEvent.js';
import { mapState }     from 'vuex';

export default {
    name:'Search',
    data () {
        return {
            authorList: [],
            displaySpinner: false,
            pageable: {page: 1, size: 15,  direction: 'ASC', property: "firstName"},
            filter: {name: ''},
            dialog: false,
            headers:[
                {
                    text: this.$t('administration.author.firstName'),
                    value:"firstName",
                    sortable: false,
                    align: 'center',
                },
                {
                    text: this.$t('administration.author.lastName'),
                    value:"lastName",
                    sortable: false,
                    align: 'center',
                },
                {
                    text: this.$t('administration.author.actions'),
                    value:"actions",
                    sortable: false,
                    align: 'center',
                }
            ],
        }
    },
    created(){
        if (this.login) this.filterAuthors();
    },
    computed: {
        ...mapState({
            login: state => state.userStore.userPreferences.login,
        }),
    },
    watch: {
        login () {
            this.filterAuthors();
        }
    },
    methods: {

        filterAuthorsWithPaginationReset() {
            this.pageable.page = 1;
            this.filterAuthors();
        },

        filterAuthors() {
            this.displaySpinner = true;
            axios.post(
                'administration/author/filter',
                this.filter,
                {params: this.pageable}
            ).then( (result) => {
                this.displaySpinner = false;
                this.authorList = result.data.content;
                this.pageable.totalElements = result.data.totalElements;
                this.pageable.totalPages = result.data.totalPages;
                this.pageable.page = result.data.page;
            });
        },
        changePage(page) {
            this.pageable.page = page;
            this.filterAuthors();
        },
        openConfirmationDialog(authorId) {
            this.dialog = true;
            this.countryIdToDelete = authorId;
        }
    }
}
</script>

<style scoped>
.custom_author{
  background-color: #355B7F;color:white;padding-top:25px;text-align: center;width:100vw;
}
.layout_author{
  background-color: #355B7F;color:white;padding-top:10px;
}
.sizer_flex{
  width:100vw;
}
.align-items{
  text-align: center;
}
.author-list{
  transform: translate(45%,0);margin-top:15px;
}
.icon-add-custom{
  position: fixed; right: 50px; bottom: 0;
}
</style>


