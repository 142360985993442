<template>
    <v-layout
        row
        wrap
    >
        <!-- TITLE OF THE PAGE-->
        <h2 class="custom_country">
            {{ $t('administration.country.search.mainTitle') }}
        </h2>

        <!-- FORM WITH RESEARCH BUTTON -->
        <v-layout
            row
            wrap
            justify-center
            class="layout_country"
        >
            <v-flex
                xs5
                class="sizer_flex"
            >
                <v-text-field
                    v-model="filter.label"
                    dark
                    :label="$t('administration.country.label.name')"
                    clearable
                    @keyup.enter="filterCountriesWithPaginationReset()"
                />
            </v-flex> 
            <v-flex
                xs1
                justify-end
            >
                <v-btn
                    color="white"
                    small
                    fab
                >
                    <v-icon
                        color="primary"
                        dark
                        @keyup.enter="filterCountriesWithPaginationReset()"
                        @click="filterCountriesWithPaginationReset()"
                    >
                        search
                    </v-icon>
                </v-btn>
            </v-flex>
        </v-layout>    

        <!-- PROGRESS BAR -->
        <v-layout
            v-if="displaySpinner"
            align-center
            justify-center
            row
        >
            <v-progress-circular
                :size="70"
                :indeterminate="true"
                color="primary"
            />
        </v-layout>

        <!-- DATA TABLES -->
        <v-flex v-if="!displaySpinner">
            <v-data-table
                :headers="headers"
                :items="countryList"
                hide-actions
                disable-initial-sort
                align-center
                justify-center
                class="elevation-1"
            >
                <template slot="no-data">
                    <td
                        colspan="6"
                        class="text-xs-center"
                    >
                        {{ $t('administration.country.search.none') }}
                    </td>
                </template>

                <template
                    slot="items"
                    slot-scope="props"
                >
                    <td class="align-items">
                        {{ props.item.label }}
                    </td>
                    <td class="align-items">
                        {{ props.item.code }}
                    </td>
                    <td class="align-items">
                        {{ props.item.description }}
                    </td>
                    <td class="align-items">
                        <router-link
                            tag="button"
                            :to="{name: 'CountryEdit', params: { id: props.item.id }}"
                        >
                            <v-icon class="edit-icon">
                                edit
                            </v-icon>
                        </router-link>
                        <v-icon
                            slot="activator"
                            class="edit-icon"
                            @click="openConfirmationDialog(props.item.id)"
                        >
                            delete
                        </v-icon>
                    </td>
                </template>
            </v-data-table>

            <v-dialog
                v-model="dialog"
                persistent
                max-width="350"
                icon
            >
                <v-card>
                    <v-card-title class="headline">
                        {{ $t('administration.country.manage.delete.title') }}
                    </v-card-title>
                    <v-divider />
                    <v-card-text>{{ $t('administration.country.manage.delete.message') }}</v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            color="primary"
                            small
                            flat
                            @click.native="dialog = false"
                        >
                            {{ $t('administration.country.manage.delete.cancel') }}
                        </v-btn>
                        <v-btn
                            color="primary"
                            small
                            flat
                            @click.native="deleteCountry()"
                        >
                            {{ $t('administration.country.manage.delete.confirm') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-flex>

        <!-- PAGINATION -->
        <v-flex
            v-if="!!countryList && countryList.length > 0"
            xs12
            class="country-list"
        >
            <v-pagination
                :length="pageable.totalPages"
                :total-visible="5"
                :value="pageable.page"
                circle
                @input="changePage"
            />
        </v-flex>

        <!-- FLOATING BUTTON (ADD) -->
        <v-tooltip top>
            <v-btn
                slot="activator"
                color="primary"
                small
                fab
                dark
                icon
                class="icon-add-custom"
                :to="{name: 'CountryAdd'}"
            >
                <v-icon dark>
                    add
                </v-icon>
            </v-btn>
            <span>{{ $t('administration.country.search.action.add') }}</span>
        </v-tooltip>
    </v-layout>
</template>

<script>

import axios from 'axios';
import EventBus  from '../../../utils/event-bus.js';
import ConstantEvent    from '../../../shared/constant/constantEvent.js';
import { mapState }     from 'vuex';
/**
 * 
 * Display a country after a search.
 * 
 * @author Ivan Sliepchenko, Legitech
 * @version 1.0
 * @since 18/05/2020
 */
export default {
    name:'Search',
    data () {
        return {
            countryList: [],
            displaySpinner: false,
            pageable: {page: 1, size: 10,  direction: 'ASC', property: "label"},
            filter: {},
            dialog: false,
            headers:[
                {  
                    text: this.$t('administration.country.label.name'), 
                    value:"label", 
                    sortable: false,
                    align: 'center',
                },
                {
                    text: this.$t('administration.country.label.abbreviation'), 
                    value:"code", 
                    sortable: false,
                    align: 'center',
                },
                {
                    text: this.$t('administration.country.label.description'), 
                    value:"description", 
                    sortable: false,
                    align: 'center',
                },
                {
                    text: this.$t('administration.country.label.actions'), 
                    value:"actions", 
                    sortable: false,
                    align: 'center',
                }
            ],
        }
    },
    created(){
        if (this.login) this.filterCountries();
    },
    computed: {
        ...mapState({
            login: state => state.userStore.userPreferences.login,
        }),
    },
    watch: {
        login () {
            this.filterCountries();
        }
    },
    methods: {
        /**
         * Search countries by filter, reset page index to 1
         */
        filterCountriesWithPaginationReset() {
            this.pageable.page = 1;
            this.filterCountries();
        },
        /**
         * Search countries by filter
         */
        filterCountries() {
            this.displaySpinner = true;
            axios.post(
                'administration/country/filter',
                this.filter,
                {params: this.pageable}
            ).then( (result) => {
                this.displaySpinner = false;
                this.countryList = result.data.content;
                this.pageable.totalElements = result.data.totalElements;
                this.pageable.totalPages = result.data.totalPages;
                this.pageable.page = result.data.page;
            });
        },
        /**
         * change page and navigate to the selected page
         * @param page
         */
        changePage(page) {
            this.pageable.page = page;
            this.filterCountries();
        },
        openConfirmationDialog(countryId) {
            this.dialog = true;
            this.countryIdToDelete = countryId;
        },
        deleteCountry() {
            this.dialog = false;
            axios.delete('administration/country/' + this.countryIdToDelete).then( () => {
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    message: 'administration.country.manage.delete.success',
                    type: 'SUCCEED'
                });
                if ( this.countryList.length <= 1 && this.pageable.page > 1) {
                    this.pageable.page = this.pageable.page - 1;
                }
                this.filterCountries();
            });
        }
    }
}
</script>

<style scoped>
    .custom_country{
        background-color: #355B7F;color:white;padding-top:25px;text-align: center;width:100vw;
    }
    .layout_country{
        background-color: #355B7F;color:white;padding-top:10px;
    }
    .sizer_flex{
        width:100vw;
    }
    .align-items{
        text-align: center;
    }
    .country-list{
        transform: translate(45%,0);margin-top:15px;
    }
    .icon-add-custom{
        position: fixed; right: 50px; bottom: 0;
    }
</style>


