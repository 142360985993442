<template>
    <v-layout column>
        <v-container>
            <modificator-document-filter @consolidationFilter="consolidationFilter" :removeReference="filterCancelled"/>
            <v-tabs
                v-model="active"
                color="primary"
                dark
                right
                slider-color="white"
            >
                <v-tab ripple>
                    {{ $t('document.documentModificator.searchDocument') }}
                </v-tab>
                <v-tab ripple>
                    {{ $t('document.documentModificator.linkedDocument') }}
                </v-tab>
                <v-tab-item>
                    <v-layout v-if="displaySpinner" align-center justify-center row>
                        <v-progress-circular
                            :size="70"
                            :indeterminate="true"
                            color="primary"
                        ></v-progress-circular>
                    </v-layout>
                    <modificator-document-list v-if="!displaySpinner" :documentList="documentList" :pageable="pageable" @changePage="changePage" @linkToDocument="linkToDocument"/>
                </v-tab-item>
                <v-tab-item>
                    <document-modificator-list :documentModificatorList="documentModificatorList" :isConsolidationMode="true" @unlinkDocument="unlinkDocument"/>
                </v-tab-item>
            </v-tabs>
        </v-container>
    </v-layout>
</template>

<script>

import DocumentModificatorList      from '../details/tabs/DocumentModificatorList.vue'
import ModificatorDocumentFilter    from './search/ModificatorDocumentFilter.vue'
import ModificatorDocumentList      from './search/ModificatorDocumentList.vue'
import { documentLockMixin }        from '../../../mixins/documentLockMixin';
import { mapState, mapActions }     from 'vuex';
import axios                        from 'axios';
import EventBus                     from '../../../utils/event-bus';
import ConstantEvent                from '../../../shared/constant/constantEvent.js';
/**
 * 
 * Manage the modificator link on the document
 * 
 * @author Justin WILMET, SFEIR Benelux
 * @version 1.0
 * @since 19/02/2019
 */
export default {
    name: 'ManageModificator',
    mixins: [documentLockMixin],
    data(){
        return{
            active: null,
            displaySpinner: false,
            pageable: { page: 1, size: 10, direction: 'ASC', property: "id" },
            searchFilters: {
                references: [],
                title: '',
                documentTypeId: '',
            }, 
            documentList: [],
            idDocument: null,
            documentModificatorList: [],
            filterCancelled: false
        }
    },
    created(){
        this.idDocument = this.$route.params.id;
        this.loadLinkedDocument();
        this.searchDocumentList(
            { 
                references: [],
                title: '',
                documentTypeId: ''
            },
            this.pageable);
    },
    methods: {
        /**
         * Get the filter for a search on modificator document
         * @param {*} filters
         */
        consolidationFilter( filters ){
            this.pageable = {};
            this.pageable = { page: 1, size: 10, direction: 'ASC', property: "id" };
            this.searchFilters.references = filters.references;
            this.searchFilters.title = filters.title;
            this.searchFilters.documentTypeId = filters.documentTypeId;
            this.searchDocumentList(this.searchFilters, this.pageable);
        },
        /**
         * Request to get the list of modificator document
         * @param {*} searchFilters
         * @param {*} pageable
         */
        searchDocumentList( searchFilters, pageable ){
            delete pageable.totalElements;
            delete pageable.totalPages;
            this.displaySpinner = true;
            searchFilters.idDocument = this.idDocument;
            axios.post(
                'document/modifier/filter',
                searchFilters,
                {params: pageable}
            ).then( ({data}) => {
                this.displaySpinner = false;
                this.documentList = data.content;
                this.pageable.totalElements = data.totalElements;
                this.pageable.totalPages = data.totalPages;
                this.pageable.page = data.page;
            }).catch((error) => {
                this.searchFilters = { 
                    references: [],
                    title: '',
                    documentTypeId: ''
                };
                this.filterCancelled = !this.filterCancelled;
                this.searchDocumentList(this.searchFilters, this.pageable);
            });
        },
        /**
         * Request to get next page of the list
         * @param {*} page
         */
        changePage( page ){
            this.pageable.page = page;
            this.searchDocumentList(this.searchFilters, this.pageable);
        },
        /**
         * Request to link the selected document modificator to the current document
         * @param {*} document
         */
        linkToDocument( document ){
            axios.post(
                'document/modifier/linkDocuments',
                {
                    targetDocumentId:  document.id,
                    modifierDocumentId: this.idDocument
                }
            ).then( () => {
                this.pageable = {};
                this.pageable = { page: 1, size: 10, direction: 'ASC', property: "id" };
                this.searchDocumentList(this.searchFilters, this.pageable);
                this.loadLinkedDocument();
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    message: 'document.documentModificator.linkDocument',
                    type: 'SUCCEED'
                });
            });
        },
        /**
         * Request to unlink the selected document modificator to the current document
         * @param {*} document
         */
        unlinkDocument( document ){
            axios.post(
                'document/modifier/unlinkDocuments',
                {
                    targetDocumentId:  document.id,
                    modifierDocumentId: this.idDocument
                }
            ).then( () => {
                this.pageable = {};
                this.pageable = { page: 1, size: 10, direction: 'ASC', property: "id" };
                this.searchDocumentList(this.searchFilters, this.pageable);
                this.loadLinkedDocument();
                EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
                    message: 'document.documentModificator.unlinkDocument',
                    type: 'SUCCEED'
                });
            });
        },
        /**
         * Load all the document modificator available for the current document
         */
        loadLinkedDocument(){
            axios.get(`document/modifier/${this.idDocument}/linkedDocuments`)
            .then( result => {
                this.documentModificatorList = result.data;
            }).catch(() => this.$router.back());
        }
    },
    components: {
        ModificatorDocumentFilter,
        ModificatorDocumentList,
        DocumentModificatorList
    }
}
</script>

<style>
.progress-bar{
    padding-bottom: 15px;
    padding-top: 15%;
}
</style>