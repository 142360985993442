const DOCUMENT_HYPERLINK_CLASS  = 'tech_bol_document';
const CONTENT_HYPERLINK_CLASS   = 'tech_bol_content';
const EXTERNAL_HYPERLINK_CLASS  = 'tech_bol_external';

const RESUME_TO_LINK            = 'pao_bol_resume';

const DOCUMENT_HYPERLINK_TYPE   = 1;
const CONTENT_HYPERLINK_TYPE    = 2;
const EXTERNAL_HYPERLINK_TYPE   = 3;

const EXTERNAL_HYPERLINK_TYPE_DIRECTIVE = 1;
const EXTERNAL_HYPERLINK_TYPE_PARLIAMENTARY_FILE = 2;
const EXTERNAL_HYPERLINK_TYPE_DEFAULT = 3;

export default class ConstantHyperLink{

    static get DOCUMENT_HYPERLINK_TYPE(){
        return DOCUMENT_HYPERLINK_TYPE;
    }
    static get RESUME_TO_LINK(){
        return RESUME_TO_LINK;
    }
    static get CONTENT_HYPERLINK_TYPE(){
        return CONTENT_HYPERLINK_TYPE;
    }
    static get EXTERNAL_HYPERLINK_TYPE(){
        return EXTERNAL_HYPERLINK_TYPE;
    }
    static get DOCUMENT_HYPERLINK_CLASS(){
        return DOCUMENT_HYPERLINK_CLASS;
    }
    static get CONTENT_HYPERLINK_CLASS(){
        return CONTENT_HYPERLINK_CLASS;
    }
    static get EXTERNAL_HYPERLINK_CLASS(){
        return EXTERNAL_HYPERLINK_CLASS;
    }
    static get EXTERNAL_HYPERLINK_TYPE_DIRECTIVE(){
        return EXTERNAL_HYPERLINK_TYPE_DIRECTIVE;
    }
    static get EXTERNAL_HYPERLINK_TYPE_PARLIAMENTARY_FILE(){
        return EXTERNAL_HYPERLINK_TYPE_PARLIAMENTARY_FILE;
    }
    static get EXTERNAL_HYPERLINK_TYPE_DEFAULT(){
        return EXTERNAL_HYPERLINK_TYPE_DEFAULT;
    }
}
