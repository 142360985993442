import axios from 'axios';
import DataSheetTypes from '../../shared/constant/store/module/datasheetTypes.js';
import DataSheetService from '../../service/document/dataSheetService';

export const buildDocumentTypeEmptyField = function(){
    return {
        documentTypeFieldLabel: null,
        documentTypeFieldId: null,
        value: null,
    };
}
// initial state
const state = {
    metaDocument: {
        additionalFields: [],
        currentVersion: { 
            version: null,
            documentId: null,
            startApplicabilityDate: null
        },
        isVisible: null,
        versions: [],
        causeEndApplicability: null,
        documentType: null,
        domains: [],
        markets: [],
        authors: [],
        endapplicabilitydate: null,
        indeterminate: false,
        otherWorkspace: [],
        partner: null,
        reference: null,
        remark: null,
        sourceEndApplicability: null,
        title: null,
        workspace: null,
        documentStatus: null,
        documentStep: null,
        startDocumentStep: null,
        oldDocumentStatus: null,
        hasBeenPublishedOnce: null,
        idDocument: null
    },
    documentModificatorList: [],
    documentHistoryList: [],
    idDocument: null,
    dataSheet: {
        id: null,
        idWorkspace: null,
        documentType: {
            id: null,
            code: null
        },
        title: null,
        idPartner: null,
        source: null,
        marketsId: [],
        authorsId: [],
        idOtherWorkspace: [],
        endDateOfApplicability: null,
        indeterminate: false,
        causeEndOfApplicability: null,
        sourceEndOfApplicability: null,
        reference: null,
        remark: null,
        basicAdditionalField: {
            baseDocument: buildDocumentTypeEmptyField(),
            dateDocument: buildDocumentTypeEmptyField(),
            datePublication: buildDocumentTypeEmptyField(),
            country: buildDocumentTypeEmptyField(),
            number: buildDocumentTypeEmptyField(),
            entryDate: buildDocumentTypeEmptyField(),
            baseAct: buildDocumentTypeEmptyField(),
            code: buildDocumentTypeEmptyField(),
            nature: buildDocumentTypeEmptyField(),
            territory: buildDocumentTypeEmptyField(),
            dateDecision: buildDocumentTypeEmptyField(),
            jurisdiction: buildDocumentTypeEmptyField(),
            judicialOrder: buildDocumentTypeEmptyField(),
            commercialCourt: buildDocumentTypeEmptyField(),
            dismissalType: buildDocumentTypeEmptyField(),
            damageType: buildDocumentTypeEmptyField(),
            moralAllowance: buildDocumentTypeEmptyField(),
            physicalAllowance: buildDocumentTypeEmptyField(),
            seniority: buildDocumentTypeEmptyField(),
            status: buildDocumentTypeEmptyField(),
            sector: buildDocumentTypeEmptyField(),
            gender: buildDocumentTypeEmptyField(),
            age: buildDocumentTypeEmptyField(),
            salary: buildDocumentTypeEmptyField(),
            language: buildDocumentTypeEmptyField(),
            startDateOfApplicability: {
                documentTypeFieldLabel: null,
                documentTypeFieldId: null,
                valueDate: null,
            },
            domain: {
                documentTypeFieldLabel: null,
                domainsId: [],
            },
            market: {
                documentTypeFieldLabel: null,
                domainsId: [],
            },
            source: buildDocumentTypeEmptyField(),
            sourceType: buildDocumentTypeEmptyField(),
            searchTag: buildDocumentTypeEmptyField(),
            isbn: buildDocumentTypeEmptyField(),
            partner: buildDocumentTypeEmptyField(),
            author: buildDocumentTypeEmptyField(),
            abbreviation: buildDocumentTypeEmptyField()
        }
    }
}

// mutations ==> Synchronous way
const mutations = {
    [DataSheetTypes.MUTATION.SET_META_DOCUMENT]: (state, data) => {
        state.metaDocument.currentVersion = data.version;
        state.metaDocument.additionalFields = data.additionalFields;
        state.metaDocument.versions = data.availableVersions;
        state.metaDocument.causeEndApplicability = data.causeEndApplicability;
        state.metaDocument.documentType = data.documentType;
        state.metaDocument.domains = data.domains;
        state.metaDocument.authors = data.authors;
        state.metaDocument.markets = data.markets;
        state.metaDocument.endapplicabilitydate = data.endapplicabilitydate;
        state.metaDocument.indeterminate = data.indeterminate;
        state.metaDocument.otherWorkspace = data.otherWorkspace;
        state.metaDocument.partner = data.partner;
        state.metaDocument.reference = data.reference;
        state.metaDocument.remark = data.remark;
        state.metaDocument.sourceEndApplicability = data.sourceEndApplicability;
        state.metaDocument.title = data.title; 
        state.metaDocument.workspace = data.workspace;
        state.metaDocument.documentStatus = data.documentStatus;
        state.metaDocument.documentStep = data.documentStep;
        state.metaDocument.startDocumentStep = data.startDocumentStep;
        state.metaDocument.oldDocumentStatus = data.documentStatus;
        state.metaDocument.hasBeenPublishedOnce = data.hasBeenPublishedOnce;
        state.metaDocument.idDocument = data.idDocument;
        state.metaDocument.isVisible = data.visible;
    },
    [DataSheetTypes.MUTATION.SET_DOCUMENT_MODIFICATOR]: (state, documentModificatorList) => {
        state.documentModificatorList = documentModificatorList;
    },
    [DataSheetTypes.MUTATION.RESET_METADATA]: ( state ) => {
        state.metaDocument.currentVersion.version = null;
        state.metaDocument.currentVersion.documentId = null;
        state.metaDocument.currentVersion.startApplicabilityDate = null;
        state.metaDocument.additionalFields = [];
        state.metaDocument.versions = [];
        state.metaDocument.causeEndApplicability = null;
        state.metaDocument.documentType = null;
        state.metaDocument.domains = [];
        state.metaDocument.markets = [];
        state.metaDocument.authors = [];
        state.metaDocument.endapplicabilitydate = null;
        state.metaDocument.indeterminate = null;
        state.metaDocument.otherWorkspace = [];
        state.metaDocument.partner = null;
        state.metaDocument.reference = null;
        state.metaDocument.remark = null;
        state.metaDocument.sourceEndApplicability = null;
        state.metaDocument.title = null; 
        state.metaDocument.workspace = null;
        state.documentModificatorList = [];
        state.documentStatus = null;
        state.metaDocument.documentStep = null;
        state.metaDocument.startDocumentStep = null;
        state.metaDocument.oldDocumentStatus = null;
        state.metaDocument.hasBeenPublishedOnce = null;
        state.metaDocument.idDocument = null;
    },
    [DataSheetTypes.MUTATION.SET_DOCUMENT_STEP_HISTORY]: ( state, documentHistoryList ) => {
        state.documentHistoryList = documentHistoryList
    },
    [DataSheetTypes.MUTATION.SET_DATASHEET]: (state, dataSheet) => {
        for( let field in dataSheet.basicAdditionalField ){
            if( !dataSheet.basicAdditionalField[field] ){
                delete dataSheet.basicAdditionalField[field];
            }
        }
        dataSheet.basicAdditionalField = {...state.dataSheet.basicAdditionalField, ...dataSheet.basicAdditionalField};
        state.dataSheet = {...state.dataSheet, ...dataSheet};
    },
    [DataSheetTypes.MUTATION.RESET_DATASHEET]: ( state ) => {
        state.dataSheet = DataSheetService.resetDataSheet(state.dataSheet);
        state.idDocument = null;
    },
    [DataSheetTypes.MUTATION.SET_DATASHEET_ADDITIONAL_FIELDS]: (state, additionalFields) => {
        for (const additionalField in additionalFields) {
            if( additionalField === 'title'){
                state.dataSheet.title = additionalFields[additionalField].value;
            } else {
                //We use a spread operator to destructure the first and second objects 
                //in order to add new fields from the second object to the first one
                //We return a new object 
                state.dataSheet.basicAdditionalField[additionalField] = {
                    ...state.dataSheet.basicAdditionalField[additionalField],
                    ...additionalFields[additionalField]
                };

                let field = state.dataSheet.basicAdditionalField[additionalField];
                if ( field.classType === "java.lang.Long" ){
                    field.value = Number(field.value)
                } else if ( field.classType === "java.lang.Boolean") {
                    field.value = Boolean(field.value)
                } else if ( field.classType === "java.lang.Double") {
                    field.value = parseFloat(field.value)
                }
            }
        }
    },
    /**
     * Instantiates the additional fields.
     * Convert the additional field receive from the list into camel case, then, dynamically, set each one.
     * 
     */
    [DataSheetTypes.MUTATION.SET_LABEL_ADDITIONAL_FIELDS]: ( state, fieldList ) => {
        fieldList.forEach(element => {
            var fieldName = element.field
                .toLowerCase()
                .replace( 
                    /_([a-z])/gi, 
                    g =>  g[1].toUpperCase() );
            state.dataSheet.basicAdditionalField[fieldName].documentTypeFieldLabel = element.field;
            state.dataSheet.basicAdditionalField[fieldName].documentTypeFieldId  = element.id;
        });    
    },
    [DataSheetTypes.MUTATION.SET_ID_DOCUMENT]: (state, idDocument) => {
        state.idDocument = idDocument;
    },
    [DataSheetTypes.MUTATION.SET_TITLE]: (state, title) => {
        state.dataSheet.title = title;
    },
    [DataSheetTypes.MUTATION.SET_DOCUMENT_TYPE_ID]: (state, idDocument) => {
        state.dataSheet.documentType.id = idDocument;
    },
    [DataSheetTypes.MUTATION.SET_DOCUMENT_TYPE_CODE]: (state, code) => {
        state.dataSheet.documentType.code = code;
    }
}

// actions ==> Asynchronous way
const actions = {
    loadDataSheetByReference: ({ commit }, reference) => {
        axios.get(`datasheet/display/${reference}`)
            .then( ({data}) => {
                commit(DataSheetTypes.MUTATION.SET_META_DOCUMENT, data);
            });
    },
    loadDataSheetByReferenceAndVersion: ({commit}, {reference, version}) =>{
        axios.get(`datasheet/display/${reference}/${version}`)
            .then( ({data}) => {
                commit(DataSheetTypes.MUTATION.SET_META_DOCUMENT, data);
            })
    },
    loadDataSheetById: ({commit}, id) => {
        axios.get(`datasheet/display`, 
            {params : {idDocument: id}})
            .then( ({data}) => {
                commit(DataSheetTypes.MUTATION.SET_META_DOCUMENT, data);
            })
    },
    loadDocumentModificatorList : ({ commit }, documentId) => {
        axios.get(
            "datasheet/modificator",
            {params: { idDocument: documentId}}
            
        ).then( result => {
            let orderedList = result.data.sort(function(a,b){
                // Turn your strings into dates and get the timestamp in order to to sort them
                return new Date(b.startDateOfApplicability).getTime() - new Date(a.startDateOfApplicability).getTime();
            });
            commit(DataSheetTypes.MUTATION.SET_DOCUMENT_MODIFICATOR, orderedList);
        });
    },
    loadDocumentStepHistoryList : ({ commit }, documentId) => {
        axios.get(
            "step/history/",
            {params: {idDocument: documentId}}
        ).then( ({data}) => {
            commit(DataSheetTypes.MUTATION.SET_DOCUMENT_STEP_HISTORY, data);
        });
    },
    resetMetadataDatasheet: ({ commit }) => {
        commit(DataSheetTypes.MUTATION.RESET_METADATA);
    },
    resetDatasheet: ({ commit }) => {
        commit(DataSheetTypes.MUTATION.RESET_DATASHEET);
    },
    setDatasheet: ({commit}, dataSheet) => {
        commit(DataSheetTypes.MUTATION.SET_DATASHEET, dataSheet);
    },
    setLabelAdditionalFields: ({commit}, fieldList) => {
        commit(DataSheetTypes.MUTATION.SET_LABEL_ADDITIONAL_FIELDS,fieldList);
    },
    setDatasheetAdditionalFields: ({commit}, additionalFields) => {
        commit(DataSheetTypes.MUTATION.SET_DATASHEET_ADDITIONAL_FIELDS, additionalFields);
    },
    setIdDocument: ({commit}, idDocument) => {
        commit(DataSheetTypes.MUTATION.SET_ID_DOCUMENT, idDocument);
    },
    setDocumentTypeId: ({commit}, idDocument) => {
        commit(DataSheetTypes.MUTATION.SET_DOCUMENT_TYPE_ID, idDocument);
    },
    setTitle: ({commit}, title) => {
        commit(DataSheetTypes.MUTATION.SET_TITLE, title);
    },
    setDocumentTypeCode: ({commit}, code) => {
        commit(DataSheetTypes.MUTATION.SET_DOCUMENT_TYPE_CODE, code);
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
