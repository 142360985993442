<template>
    <v-layout
        row
        wrap
    >
        <h2 class="custom_magazine_collection">
            {{ $t('administration.magazine.magazine') }}
        </h2>
        <!-- FORM WITH RESEARCH BUTTON -->
        <v-layout
            row
            wrap
            justify-center
            class="layout_magazine_collection"
        >
            <v-flex
                xs5
                class="sizer_flex"
            >
                <v-text-field
                    v-model="filter"
                    dark
                    :label="$t('administration.magazine.search.magazine')"
                    clearable
                    @keyup.enter="search(filter,pageable)"
                />
            </v-flex> 
            <v-flex
                xs1
                justify-end
            >
                <v-btn
                    color="white"
                    small
                    fab
                >
                    <v-icon
                        color="primary"
                        dark
                        @keyup.enter="search(filter,pageable)"
                        @click="search(filter,pageable)"
                    >
                        search
                    </v-icon>
                </v-btn>
            </v-flex>
        </v-layout>


        <!-- PROGRESS BAR -->
        <v-layout
            v-if="displaySpinner"
            align-center
            justify-center
            row
        >
            <v-progress-circular
                :size="70"
                :indeterminate="true"
                color="primary"
            />
        </v-layout>

        <!-- DATA TABLES -->
        <v-flex
            v-if="!displaySpinner"
            xs12
        >
            <v-data-table
                :headers="[]"
                :items="magazineList"
                hide-actions
                disable-initial-sort
                align-center
                justify-center
                class="elevation-1"
            >
                <template
                    slot="headers"
                    slot-scope="props"
                >
                    <th>{{ $t('administration.magazine.label') }}</th>
                    <th>{{ $t('administration.magazine.code') }}</th>
                    <th>{{ $t('administration.magazine.actions') }}</th>
                </template>

                <template slot="no-data">
                    <td
                        colspan="100%"
                        class="text-xs-center"
                    >
                        {{ $t('administration.magazine.none') }}
                    </td>
                </template>

                <template
                    slot="items"
                    slot-scope="props"
                >
                    <td class="align-items">
                        {{ props.item.label }}
                    </td>
                    <td class="align-items">
                        {{ props.item.code }}
                    </td>
                    <td class="justify-center layout px-0 icon-customer">
                        <router-link
                            tag="button"
                            :to="{name: 'MagazineEdit', params: { id: props.item.id }}"
                        >
                            <v-icon
                                class="edit-icon"
                                medium
                            >
                                edit
                            </v-icon>
                        </router-link>

                        <v-icon
                            slot="activator"
                            class="edit-icon"
                            medium
                            @click="deleteMagazine(props.item)"
                        >
                            delete_forever
                        </v-icon>
                    </td>
                </template>
            </v-data-table>

            <v-dialog
                v-model="dialog"
                persistent
                max-width="350"
                icon
            >
                <v-card>
                    <v-card-title class="headline">
                        {{ $t('administration.magazine.manage.delete.title') }}
                    </v-card-title>
                    <v-divider />
                    <v-card-text>{{ $t('administration.magazine.manage.delete.message') }}</v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            color="primary"
                            small
                            flat
                            @click.native="dialog = false"
                        >
                            {{ $t('administration.magazine.manage.delete.cancel') }}
                        </v-btn>
                        <v-btn
                            color="primary"
                            small
                            flat
                            @click.native="confirmDelete()"
                        >
                            {{ $t('administration.magazine.manage.delete.confirm') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-flex>

        <!-- FLOATING BUTTON (ADD) -->
        <v-flex
            xs12
            text-xs-right
        >
            <v-tooltip top>
                <v-btn
                    slot="activator"
                    color="primary"
                    small
                    fab
                    dark
                    icon
                    class="icon-add-custom"
                    :to="{name: 'MagazineAdd'}"
                >
                    <v-icon dark>
                        add
                    </v-icon>
                </v-btn>
                <span>{{ $t('administration.magazine.action.add') }}</span>
            </v-tooltip>
        </v-flex>
        <!-- PAGINATION -->
        <v-flex
            v-if="!!magazineList && magazineList.length > 0"
            xs12
            class="magazine-collection-list"
        >
            <v-pagination
                :length="pageable.totalPages"
                :total-visible="5"
                :value="pageable.page"
                circle
                @input="changePage"
            />
        </v-flex>
    </v-layout>
</template>

<script>
import axios from 'axios';

export default {
    name:'MagazineSearch',
    data () {
        return {
            displaySpinner: false,
            filter: null,
            pageable: {page: 1, size: 10, direction: 'ASC', property: "label"},
            magazineList: [],
            dialog: false,
        }
    },
    created(){
        this.filterMagazine(this.filter, this.pageable);
    },
    methods: {
        /**
         * Search a magazine
         * @param filter
         * @param pageable
         */
        search(filter, pageable) {
            this.filterMagazine(filter, pageable);
        },
        /**
         * filter the paginated keywords
         * @param filter
         * @param pageable
         */
        filterMagazine(filter, pageable) {
            this.displaySpinner = true;
            axios.post( 
                `administration/publication/magazine/filter`,
                {label: filter},
                {params: pageable}
            ).then( result => {
                this.displaySpinner = false;
                this.magazineList = result.data.content;
                this.pageable.totalPages = result.data.totalPages;
                this.pageable.totalElements = result.data.totalElements;
                this.pageable.page = result.data.page;
            });
        },
        /**
         * Open the delete modal to confirm deleting
         * @param item
         */
        deleteMagazine( magazine ) {
            this.magazineIdToDelete = magazine.id;
            this.dialog = true;
        },
        confirmDelete() {
            this.dialog = false;
            axios.delete(
                `administration/publication/magazine/${this.magazineIdToDelete}`
            ).then( () => {
                this.filterMagazine(this.filter, this.pageable);
            });
        },
        /**
         * change page and navigate to the selected page
         * @param page
         */
        changePage(page){
            this.pageable.page = page;
            this.filterMagazine(this.filter, this.pageable);
        }
    }
}

</script>
<style scoped>
.row-items-level{
    height: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
}
.custom_magazine_collection{
    background-color: #355B7F;color:white;padding-top:50px;text-align: center;width:100vw;
}
.layout_magazine_collection{
    background-color: #355B7F;color:white;padding-top:50px;
}
.sizer_flex{
    width:100vw;
}
.align-items{
    text-align: center;
}
.icon-customer{
    margin-left: 15px;
}
.magazine-collection-list{
    transform: translate(45%,0);margin-top:20px;
}
.icon-add-custom{
    position: fixed;bottom: 50px;right: 50px;
}
</style>
