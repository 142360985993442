<template>
  <v-layout
      row
      wrap
  >
    <h2 class="custom_keyword">
      {{ $t('administration.keyword.keyword') }}
    </h2>
    <!-- FORM WITH RESEARCH BUTTON -->
    <v-layout
        row
        wrap
        justify-center
        class="layout_keyword"
    >
      <v-flex
          xs5
          class="sizer_flex"
      >
        <v-text-field
            v-model="filter.label"
            dark
            :label="$t('administration.keyword.search.keyword')"
            clearable
            @keyup.enter="filterKeywordsWithPaginationReset()"
        />
      </v-flex>
      <v-flex
          xs1
          justify-end
      >
        <v-btn
            color="white"
            small
            fab
        >
          <v-icon
              color="primary"
              dark
              @keyup.enter="filterKeywordsWithPaginationReset()"
              @click="filterKeywordsWithPaginationReset()"
          >
            search
          </v-icon>
        </v-btn>
      </v-flex>
    </v-layout>


    <!-- PROGRESS BAR -->
    <v-layout
        v-if="displaySpinner"
        align-center
        justify-center
        row
    >
      <v-progress-circular
          :size="70"
          :indeterminate="true"
          color="primary"
      />
    </v-layout>

    <!-- DATA TABLES -->
    <v-flex
        v-if="!displaySpinner"
        xs12
    >
      <v-data-table
          :headers="[]"
          :items="keywordList"
          hide-actions
          disable-initial-sort
          align-center
          justify-center
          class="elevation-1"
      >
        <template
            slot="headers"
            slot-scope="props"
        >
          <th>{{ $t('administration.keyword.keyword') }}</th>
          <th>{{ $t('administration.keyword.level1') }}</th>
          <th>{{ $t('administration.keyword.level2') }}</th>
          <th>{{ $t('administration.keyword.level3') }}</th>
          <th>{{ $t('administration.keyword.actions') }}</th>
        </template>

        <template slot="no-data">
          <td
              colspan="4"
              class="text-xs-center"
          >
            {{ $t('administration.keyword.none') }}
          </td>
        </template>

        <template
            slot="items"
            slot-scope="props"
        >
          <tr v-on:dblclick="openDocumentKeywordDialog(props.item.id)">
            <td class="align-items">
              {{ props.item.label }}
            </td>
            <td>
              <v-layout class="row-items-level">
                <v-checkbox
                    :input-value="props.item.level === 1"
                    disabled
                />
              </v-layout>
            </td>
            <td>
              <v-layout class="row-items-level">
                <v-checkbox
                    :input-value="props.item.level === 2"
                    disabled
                />
              </v-layout>
            </td>
            <td>
              <v-layout class="row-items-level">
                <v-checkbox
                    :input-value="props.item.level === 3"
                    disabled
                />
              </v-layout>
            </td>
            <td class="justify-center layout px-0 icon-customer">
              <router-link
                  tag="button"
                  :to="{name: 'KeywordEdit', params: { id: props.item.id }}"
              >
                <v-icon
                    class="edit_icon"
                    medium
                >
                  edit
                </v-icon>
              </router-link>

              <v-icon
                  slot="activator"
                  class="edit_icon"
                  medium
                  @click="deleteKeyword(props.item)"
              >
                delete_forever
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-dialog
          v-model="dialog"
          persistent
          max-width="350"
          icon
      >
        <v-card>
          <v-card-title class="headline">
            {{ $t('administration.keyword.manage.delete.title') }}
          </v-card-title>
          <v-divider/>
          <v-card-text>{{ $t('administration.keyword.manage.delete.message') }}</v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn
                color="primary"
                small
                flat
                @click.native="dialog = false"
            >
              {{ $t('administration.keyword.manage.delete.cancel') }}
            </v-btn>
            <v-btn
                color="primary"
                small
                flat
                @click.native="confirmDelete()"
            >
              {{ $t('administration.keyword.manage.delete.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-flex>

    <!-- PAGINATION -->
    <v-flex
        v-if="!!keywordList && keywordList.length > 0"
        xs12
        class="keyword_list"
    >
      <v-pagination
          :length="pageable.totalPages"
          :total-visible="5"
          :value="pageable.page"
          circle
          @input="changePage"
      />
    </v-flex>

    <!-- FLOATING BUTTON (ADD) -->
    <v-flex
        xs12
        text-xs-right
    >
      <v-tooltip top>
        <v-btn
            slot="activator"
            color="primary"
            small
            fab
            dark
            icon
            class="icon_add_custom"
            :to="{name: 'KeywordAdd'}"
        >
          <v-icon dark>
            add
          </v-icon>
        </v-btn>
        <span>{{ $t('administration.keyword.action.add') }}</span>
      </v-tooltip>
    </v-flex>
    <dialog-document-keyword></dialog-document-keyword>
  </v-layout>
</template>

<script>
import axios from 'axios';
import EventBus from '../../../utils/event-bus.js';
import ConstantEvent from '../../../shared/constant/constantEvent.js';
import {mapState} from 'vuex';
import DialogDocumentKeyword from "@/components/administration/keyword/DialogDocumentKeyword.vue";

export default {
  name: 'KeywordSearch',
  data() {
    return {
      displaySpinner: false,
      filter: {},
      pageable: {page: 1, size: 10, direction: 'ASC', property: "label"},
      keywordList: [],
      dialog: false,
    }
  },
  created() {
    this.filterKeywords();
  },
  components: {
    DialogDocumentKeyword
  },
  computed: {
    ...mapState({
      login: state => state.userStore.userPreferences.login,
    }),
  },
  watch: {
    login(newValue, oldValue) {
      this.filterKeywords();
    }
  },
  methods: {

    openDocumentKeywordDialog(selectedItem) {
      EventBus.$emit(ConstantEvent.UPDATE_DOCUMENT_KEYWORD_DIALOG, selectedItem);
    },
    /**
     * Search keywords by filter, reset page index to 1
     */
    filterKeywordsWithPaginationReset() {
      this.pageable.page = 1;
      this.filterKeywords();
    },
    /**
     * Search Keywords by a filter
     */
    filterKeywords() {
      this.displaySpinner = true;
      axios.post(
          `administration/keyword/filter`,
          this.filter,
          {params: this.pageable}
      ).then(result => {
        this.displaySpinner = false;
        this.keywordList = result.data.content;
        this.pageable.totalPages = result.data.totalPages;
        this.pageable.totalElements = result.data.totalElements;
        this.pageable.page = result.data.page;
      });
    },
    /**
     * Open the delete modal to confirm deleting
     * @param item
     */
    deleteKeyword(keyword) {
      this.keywordIdToDelete = keyword.id;
      this.dialog = true;
    },
    /**
     * Sends request to delete a keyword from the database
     * and adapts the page number to the new value
     */
    confirmDelete() {
      this.dialog = false;
      axios.delete('administration/keyword/' + this.keywordIdToDelete).then(() => {
        EventBus.$emit(ConstantEvent.ADD_NOTIFICATION, {
          message: 'administration.keyword.manage.delete.success',
          type: 'SUCCEED'
        });
        if (this.keywordList.length <= 1 && this.pageable.page > 1) {
          this.pageable.page = this.pageable.page - 1;
        }
        this.filterKeywords();
      });
    },
    /**
     * change page and navigate to the selected page
     * @param page
     */
    changePage(page) {
      this.pageable.page = page;
      this.filterKeywords();
    }
  }
}

</script>
<style scoped>
.row-items-level {
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
}

.custom_keyword {
  background-color: #355B7F;
  color: white;
  padding-top: 50px;
  text-align: center;
  width: 100vw;
}

.layout_keyword {
  background-color: #355B7F;
  color: white;
  padding-top: 50px;
}

.sizer_flex {
  width: 100vw;
}

.align_items {
  text-align: center;
}

.icon_customer {
  margin-left: 15px;
}

.keyword_list {
  transform: translate(45%, 0);
  margin-top: 20px;
}

.icon_add_custom {
  position: fixed;
  bottom: 50px;
  right: 50px;
}
</style>
