/**
 * 
 */
const DATASHEET_STORE           = 'dataSheetStore';
const PAO_FILTER                = 'paoFilterStore';
const PAO_STRUCTURE             = 'paoStructureStore';
const DOCUMENT_CONTENT_STORE    = 'documentContent';

export default class Namespace {

    static get DATASHEET_STORE(){
        return DATASHEET_STORE;
    }

    static get PAO_FILTER(){
        return PAO_FILTER;
    }

    static get PAO_STRUCTURE(){
        return PAO_STRUCTURE;
    }

    static get DOCUMENT_CONTENT_STORE(){
        return DOCUMENT_CONTENT_STORE;
    }

}